import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biblioteca-inspecciones',
  templateUrl: './biblioteca-inspecciones.component.html',
  styleUrls: ['./biblioteca-inspecciones.component.css']
})
export class BibliotecaInspeccionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
