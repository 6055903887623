import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PvaService } from '../pva.service';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../../services/utils.service';
import { PruebaAportada, PruebaInspector, PruebaPracticada, PruebaSolicitada } from '../../../inspecciones.interface';
@Component({
  selector: 'app-pruebas-pva',
  templateUrl: './pruebas-pva.component.html',
  styleUrls: ['./pruebas-pva.component.css']
})
export class PruebasPvaComponent implements OnInit {
  suspendeAudienciaPva: boolean = false

  /** Información de otros componentes */
  @Input() pvaId: string;
  @Input() selectedHearing: any;
  @Input() isSelected: boolean;
  @Input() audienciasDisponibles: any[];
  /** Evento que se realiza al seleccionar una audiencia */
  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  caseId: string;

  oldHearing: boolean = false;
  newHearing: boolean = false;

  hearingIndex: number;

  audiencia = {
    fechaAudiencia: undefined,
    audienciaSuspendida: undefined,
    fechaReanudacion: undefined,
    horaReanudacion: undefined,
    pruebasAportadas: [],
    pruebasSolicitadas: [],
    pruebasInspector: [],
  }

  nuevasPruebasGeneralesAportadas: any[] = [];
  nuevasPruebasAportadas: any[] = [];
  pruebasGeneralesAportadas: PruebaAportada[] = [];
  pruebasPracticadas: PruebaPracticada[] = [];
  nuevasPruebasPracticadas: any[] = []

  pruebaSolicitadaOtroTipo: string;

  pruebaGeneralAportada: PruebaAportada = {
    nombrePersona: undefined,
    nombre: undefined,
    fechaAporte: null,
    url: undefined,
    tipo: undefined,
    aceptada: undefined,
    motivoRechazo: undefined,
    tipoPrueba: undefined
  }

  pruebaPracticada: PruebaPracticada = {
    nombre: undefined,
    fechaPractica: undefined,
    detalle: undefined,
    url: undefined,
    tipo: undefined,
    hora: undefined
  }

  pruebaAportada: PruebaAportada = {
    nombrePersona: undefined,
    nombre: undefined,
    fechaAporte: null,
    url: undefined,
    tipo: undefined,
    aceptada: undefined,
    tipoPrueba: undefined,
    motivoRechazo: undefined
  }

  pruebasSolicitada: PruebaSolicitada = {
    nombrePersona: undefined,
    otroTipo: undefined,
    nombre: undefined,
    fechaSolicitud: undefined,
    detalle: undefined,
    activo: undefined,
    aceptada: undefined,
    motivoRechazo: undefined
  };

  pruebaInspector: PruebaInspector = {
    nombre: undefined,
    fechaDecretada: undefined,
    detalle: undefined,
    activo: undefined
  }

  personas: any[];

  personasConfig = {
    displayFn: (item: any) => {
      return item.nombreCompleto;
    },
    search:true,
    noResultsFound: "No se encontraron resultados",
    placeholder:'Seleccione'
  }

  config = {
    search: true,
    noResultsFound: "No se encontraron resultados",
    placeholder: 'Seleccione'
  }

  pruebaSolicitada = {
    nombrePersona: undefined,
    otroTipo: null,
    nombre: undefined,
    detalle: undefined,
    fechaSolicitud: undefined,
    activo: null,
    aceptada: undefined,
    motivoRechazo: undefined
  }

  listaPruebas = [
    'Declaración',
    'Testimonio',
    'Entrevista',
    'Peritaje',
    'Inspección',
    'Documento',
    'Informe de policía',
    'Orden de comparendo'
  ]

  constructor(
    private route: ActivatedRoute,
    private pvaService: PvaService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getGeneralEvidences();
    this.getInfractors();
  }

  getGeneralEvidences(){
    this.pvaService.getGeneralEvidences(this.caseId).then(response => {
      console.log(response);
      this.pruebasGeneralesAportadas = response.pruebasGeneralesAportadas || [];
      this.pruebasPracticadas = response.pruebasPracticadas || [];
      console.log(this.pruebasPracticadas);
    })
  }


  getInfractors(){
    this.pvaService.getQuoted(this.caseId).then(quoted => {
      this.personas = quoted;
    })
  }

  editHearing(hearing){
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia.split("T")[0];
    this.audiencia.fechaReanudacion = hearing.fechaReanudacion.split('T')[0];
    // this.audiencia.pruebasInspector.forEach(prueba => prueba.fechaDecretada = prueba.fechaDecretada.split('T')[0]);
  }

  cancelHearing(){
    this.isSelected = false;
    this.oldHearing = false
    this.newHearing = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  decideRequestedEvidence(status){
    if(
      (status && !this.pruebaSolicitada.aceptada) ||
      (!status && this.pruebaSolicitada.aceptada) ||
      (status && this.pruebaSolicitada.aceptada === undefined) ||
      (!status && this.pruebaSolicitada.aceptada === undefined)
    ){
      this.pruebaSolicitada.aceptada = status;
    } else {
      this.pruebaSolicitada.aceptada = undefined;
    }
  }

  changeAccomplishmentSuspendeAudiencia(status){
    if(
      (status && !this.audiencia.audienciaSuspendida) ||
      (!status && this.audiencia.audienciaSuspendida) ||
      (status && this.audiencia.audienciaSuspendida === undefined) ||
      (!status && this.audiencia.audienciaSuspendida === undefined)
    ){
      this.audiencia.audienciaSuspendida = status;
    } else {
      this.audiencia.audienciaSuspendida = undefined;
    }
  }

  onUploadGeneralProvidedEvidence(event){
    console.log('entra',this.pruebaGeneralAportada.nombrePersona);
    const persona: any = this.pruebaGeneralAportada.nombrePersona;
    if(!Array.isArray(this.pruebaGeneralAportada.tipo) && this.pruebaGeneralAportada.nombrePersona && !Array.isArray(this.pruebaGeneralAportada.nombrePersona) && this.pruebaGeneralAportada.fechaAporte){
      const fechaArreglada = `${(<string>this.pruebaGeneralAportada.fechaAporte).split('-')[0]}-${(<string>this.pruebaGeneralAportada.fechaAporte).split('-')[1]}-${Number((<string>this.pruebaGeneralAportada.fechaAporte).split('-')[2]) + 1}`
      this.onFileChange(event).then((response: any) => {
        const updatedFiles: any[] = response.updatedFiles;
        const files: File[] = response.files;
        this.nuevasPruebasGeneralesAportadas = updatedFiles.map((updatedFile, index) => {
          return ({
            nombrePersona: persona.nombreCompleto,
            nombre: files[index].name.slice(0, files[index].name.lastIndexOf('.')),
            fechaAporte: new Date(fechaArreglada),
            url: updatedFile,
            tipo: files[index].type,
            tipoPrueba: this.pruebaGeneralAportada.tipoPrueba
          });
        });
        console.log(this.nuevasPruebasGeneralesAportadas);
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Se tiene que asignar una persona y una fecha de aporte primero',
        icon: 'error',
      })
    }
  }

  onUploadPracticedEvidence(event){
    if(this.pruebaPracticada.nombre && !Array.isArray(this.pruebaPracticada.nombre) && this.pruebaPracticada.fechaPractica && this.pruebaPracticada.detalle){
      const fechaArreglada = `${this.pruebaPracticada.fechaPractica.split('-')[0]}-${this.pruebaPracticada.fechaPractica.split('-')[1]}-${Number(this.pruebaPracticada.fechaPractica.split('-')[2]) + 1}`
      this.onFileChange(event).then((response: any) => {
        const updatedFiles: string[] = response.updatedFiles;
        const files: File[] = response.files;
        console.log(this.pruebaPracticada);
        this.nuevasPruebasPracticadas = updatedFiles.map((updatedFile, index) => {
          return ({
            nombre: this.pruebaPracticada.nombre,
            fechaPractica: new Date(fechaArreglada),
            hora: this.pruebaPracticada.hora,
            detalle: this.pruebaPracticada.detalle,
            url: updatedFile,
            tipo: files[index].type
          });
        })
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Se tiene que asignar un tipo de prueba practicada, fecha y detalle primero',
        icon: 'error',
      });
    }
  }

  addPracticedEvidence(){
    if(this.nuevasPruebasPracticadas.length === 0){
      Swal.fire({
        title: 'Error',
        text: 'No se han agregado nuevas pruebas aportadas',
        icon: 'error'
      })
      return
    }
    Swal.fire({
      title: '¡Espera!',
      html: '<p>¿Quiere agregar estas pruebas?, <bold>posteriormente no podrán ser modificadas ni eliminadas</bold></p>',
      icon: 'warning',
      confirmButtonText: 'Sí, quiero agregarlas',
      cancelButtonText: 'No, volver a revisar',
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if(response.isConfirmed){
        this.pruebasPracticadas = [...this.pruebasPracticadas, ...this.nuevasPruebasPracticadas];
        this.resetPracticedEvidence();
      }
    });
  }

  resetPracticedEvidence(){
    this.pruebaPracticada = {
      nombre: undefined,
      fechaPractica: undefined,
      detalle: undefined,
      url: undefined,
      tipo: undefined,
      hora: undefined
    }
    this.nuevasPruebasPracticadas = [];
  }

  formatDate(date: Date | string): string{
    return this.utilsService.formatDate(date);
  }

  onUpdateProvidedEvidence(event){
    const persona: any = this.pruebaAportada.nombrePersona;
    if(this.pruebaAportada.nombrePersona && !Array.isArray(this.pruebaAportada.tipoPrueba) && !Array.isArray(this.pruebaAportada.nombrePersona) && this.pruebaAportada.fechaAporte){;
      const fechaArreglada = `${(<string>this.pruebaAportada.fechaAporte).split('-')[0]}-${(<string>this.pruebaAportada.fechaAporte).split('-')[1]}-${Number((<string>this.pruebaAportada.fechaAporte).split('-')[2]) + 1}`
      this.onFileChange(event).then((response: any) => {
        const updatedFiles: any[] = response.updatedFiles;
        const files: File[] = response.files;
        this.nuevasPruebasAportadas = updatedFiles.map((updatedFile, index) => {
          return ({
            nombrePersona: persona.nombreCompleto,
            nombre: files[index].name.slice(0, files[index].name.lastIndexOf('.')),
            fechaAporte: new Date(fechaArreglada),
            url: updatedFile,
            tipo: files[index].type,
            tipoPrueba: this.pruebaAportada.tipoPrueba
          });
        });
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Se tiene que asignar una persona y una fecha de aporte primero',
        icon: 'error',
      })
    }
  }

  onFileChange(event){
    return new Promise((resolve, reject) => {
      const files: File[] = Object.values(event.target.files);
      const result = Promise.all(files.map((file) => this.pvaService.saveInS3(file)));
      result.then(res => {
        const updatedFiles = res;
        this.pvaService.showS3UploadSuccess();
        resolve({updatedFiles, files});
    })
    }).catch(err => {
      this.pvaService.showS3UploadError();
      console.error(err)
    })
  }

  addGeneralProvidedEvidence(){
    if(this.nuevasPruebasGeneralesAportadas.length === 0){
      Swal.fire({
        title: 'Error',
        text: 'No se han agregado nuevas pruebas aportadas',
        icon: 'error'
      })
      return
    }
    if(!this.nuevasPruebasGeneralesAportadas.every(pruebaAportada => pruebaAportada.aceptada === true || (pruebaAportada.aceptada === false && pruebaAportada.motivoRechazo != '' && pruebaAportada.motivoRechazo != undefined))){
      Swal.fire({
        title: 'Error',
        text: 'Se debe indicar si las pruebas son aceptadas o rechazadas',
        icon: 'error'
      });
      return
    }
    Swal.fire({
      title: '¡Espera!',
      html: '<p>¿Quiere agregar estas pruebas?, <bold>posteriormente no podrán ser modificadas ni eliminadas</bold></p>',
      icon: 'warning',
      confirmButtonText: 'Sí, quiero agregarlas',
      cancelButtonText: 'No, volver a revisar',
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if(response.isConfirmed){
        this.pruebasGeneralesAportadas = [...this.pruebasGeneralesAportadas, ...this.nuevasPruebasGeneralesAportadas];
        this.resetGeneralEvidenceProvided()
      }
    });
  }

  decideEvidence(index: number, accepted: boolean, tipoPrueba: string): void {
    switch (tipoPrueba) {
      case 'aportada-general':
        this.pruebasGeneralesAportadas[index].aceptada = accepted
        break;
      case 'nueva-aportada-general':
        this.nuevasPruebasGeneralesAportadas[index].aceptada = accepted;
        break;
      case 'nueva-aportada':
        this.nuevasPruebasAportadas[index].aceptada = accepted;
        break;
    }
  }

  addProvidedEvidence(){
    if(this.nuevasPruebasAportadas.length === 0){
      Swal.fire({
        title: 'Error',
        text: 'No se han agregado nuevas pruebas aportadas',
        icon: 'error'
      })
      return
    }
    if(!this.nuevasPruebasAportadas.every(pruebaAportada => pruebaAportada.aceptada === true || (pruebaAportada.aceptada === false && pruebaAportada.motivoRechazo != '' && pruebaAportada.motivoRechazo != undefined))){
      Swal.fire({
        title: 'Error',
        text: 'Se debe indicar si las pruebas son aceptadas o rechazadas',
        icon: 'error'
      });
      return
    }
    Swal.fire({
      title: '¡Espera!',
      html: '<p>¿Quiere agregar estas pruebas?, <bold>posteriormente no podrán ser modificadas ni eliminadas</bold></p>',
      icon: 'warning',
      confirmButtonText: 'Sí, quiero agregarlas',
      cancelButtonText: 'No, volver a revisar',
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if(response.isConfirmed){
        this.audiencia.pruebasAportadas = [...this.audiencia.pruebasAportadas, ...this.nuevasPruebasAportadas];
        this.resetEvidenceProvided();
      }
    });
  }

  resetGeneralEvidenceProvided(){
    this.pruebaGeneralAportada = {
      nombrePersona: undefined,
      nombre: undefined,
      fechaAporte: null,
      url: undefined,
      tipo: undefined,
      aceptada: undefined,
      motivoRechazo: undefined,
      tipoPrueba: undefined
    }
    this.nuevasPruebasGeneralesAportadas = [];
  }

  resetEvidenceProvided(){
    this.pruebaAportada = {
      nombrePersona: undefined,
      tipoPrueba: undefined,
      nombre: undefined,
      fechaAporte: null,
      url: undefined,
      tipo: undefined,
      aceptada: undefined,
      motivoRechazo: undefined
    }
    this.nuevasPruebasAportadas = [];
  }

  changeSolicitedEvidenceType(){
    if(this.pruebaSolicitada.otroTipo){
      this.pruebaSolicitada.nombre = undefined;
    } else if (!this.pruebaSolicitada.otroTipo && this.pruebaSolicitada.otroTipo !== undefined){
      this.pruebaSolicitadaOtroTipo = undefined;
    }
  }

  addRequestedEvidence(){
    if(
      this.pruebaSolicitada.nombrePersona && !Array.isArray(this.pruebaSolicitada.nombrePersona) &&
      (this.pruebaSolicitada.nombre || this.pruebaSolicitadaOtroTipo) &&
      this.pruebaSolicitada.detalle &&
      this.pruebaSolicitada.fechaSolicitud &&
      this.pruebaSolicitada.aceptada !== undefined
    ) {
      const fechaArreglada = `${this.pruebaSolicitada.fechaSolicitud.split('-')[0]}-${this.pruebaSolicitada.fechaSolicitud.split('-')[1]}-${Number(this.pruebaSolicitada.fechaSolicitud.split('-')[2]) + 1}`
      Swal.fire({
        title: '¡Espera!',
        html: '<p>¿Quiere agregar esta pruebas solicitada?, <bold>posteriormente no podrán ser modificadas ni eliminadas</bold></p>',
        icon: 'warning',
        confirmButtonText: 'Sí, quiero agregarla',
        cancelButtonText: 'No, volver a revisar',
        showConfirmButton: true,
        showCancelButton: true
      }).then(response => {
        if(response.isConfirmed){
          this.audiencia.pruebasSolicitadas.push({
            nombrePersona: this.pruebaSolicitada.nombrePersona.nombreCompleto,
            otroTipo: this.pruebaSolicitada.otroTipo,
            nombre: !Array.isArray(this.pruebaSolicitada.nombre) ? this.pruebaSolicitada.nombre : this.pruebaSolicitadaOtroTipo,
            fechaSolicitud: fechaArreglada,
            detalle: this.pruebaSolicitada.detalle,
            activo: true,
            aceptada: this.pruebaSolicitada.aceptada,
            motivoRechazo: this.pruebaSolicitada.motivoRechazo
          })
          Swal.fire({
            title: 'Éxito',
            text: 'La solicitud se ha agregado exitosamente',
            icon: 'success',
            footer: `<span class="red-text">¡No olvides guardar!</span>`
          });
          this.resetEvidenceRequested();
        }
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos requeridos',
        icon: 'error',
      })
    }
  }

  resetEvidenceRequested(){
    this.pruebaSolicitada = {
      nombrePersona: undefined,
      otroTipo: null,
      nombre: undefined,
      fechaSolicitud: undefined,
      detalle: undefined,
      activo: null,
      aceptada: undefined,
      motivoRechazo: undefined
    }
    this.pruebaSolicitadaOtroTipo = undefined;
  }

  addDecreedEvidence(){
    if(
      this.pruebaInspector.nombre && !Array.isArray(this.pruebaInspector.nombre) &&
      this.pruebaInspector.detalle &&
      this.pruebaInspector.fechaDecretada
    ) {
      Swal.fire({
        title: '¡Espera!',
        html: '<p>¿Quiere agregar estas pruebas?, <bold>posteriormente no podrán ser modificadas ni eliminadas</bold></p>',
        icon: 'warning',
        confirmButtonText: 'Sí, quiero agregarlas',
        cancelButtonText: 'No, volver a revisar',
        showConfirmButton: true,
        showCancelButton: true
      }).then(response => {
        if(response.isConfirmed){
          this.audiencia.pruebasInspector.push({
            nombre: this.pruebaInspector.nombre,
            detalle: this.pruebaInspector.detalle,
            fechaDecretada: this.pruebaInspector.fechaDecretada,
            activo: true
          });
          this.resetDecreedEvidence()
        }
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos',
        icon: 'error'
      });
    }
  }

  resetDecreedEvidence(){
    this.pruebaInspector = {
      nombre: undefined,
      detalle: undefined,
      fechaDecretada: undefined,
      activo: undefined
    }
  }

  onSaveInfo(){
    this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
    console.log(this.pruebasPracticadas);
    const pva = {
      pruebasGeneralesAportadas: this.pruebasGeneralesAportadas,
      pruebasPracticadas: this.pruebasPracticadas,
      audiencias: this.audienciasDisponibles
    }
    this.pvaService.saveInfo(this.pvaId, pva).catch(err => console.error(err));
  }

}
