<div class="main">
  <router-outlet></router-outlet>
  <!-- <div class="footer col-sm-12 fila row" *ngIf="buzon">
      <div class="col-sm-10"></div>
      <div class="col-sm-2 buzon" style="color:white" (click)="buzon = false">Buzón</div>
  </div>
  <div class="footer col-sm-12 fila row" *ngIf="!buzon">
      <div class="col-sm-8">
      </div>
      <div class="col-sm-4 buzon2" style="color:white">
          <div class="row">
              <div class="col-sm-10">
                  <h4>Contáctenos</h4>
              </div>
              <div class="col-sm-1">
                  <h4 style="color: red;cursor: pointer;" (click)="buzon = true">X</h4>
              </div>
              <div class="col-sm-12" style="text-align: right;">
                  <textarea placeholder="Escriba su mensaje" rows="5" style="width: 100%;color:black"
                      [(ngModel)]="mensaje"></textarea>
                  <button class="btn-enviar" (click)="enviarMensaje()">Enviar mensaje</button>
              </div>
          </div>
      </div>
  </div> -->
</div>