import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MecanismoIntermedioService } from '../mecanismo-intermedio.service';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import { UtilsService } from '../../../../services/utils.service';
import { Audiencia, Descargo } from '../../../inspecciones.interface';
@Component({
  selector: 'app-descargos',
  templateUrl: './descargos.component.html',
  styleUrls: ['./descargos.component.css']
})
export class DescargosComponent implements OnInit, OnChanges {
  caseId: string;

  audiencia: Audiencia = {};

  oldHearing: boolean = false;
  newHearing: boolean = false;

  hearingIndex: string | number;

  @Input() selectedHearing: any;
  @Input() mecIntId: string | number;
  @Input() isSelected: boolean;
  @Input() audienciasDisponibles: Audiencia[];
  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  citados: any[] = [];
  /** Si elige otro citado */
  anotherSelected: boolean = false;

  quotedConfig = {
    noResultsFound: "No se encontraron resultados",
    search: true,
    placeholder: "Seleccione",
    displayFn: (item) => { return item.nombreCompleto }
  }

  quotedFound: any = {};

  readonly config = {
    noResultsFound: "No se encontraron resultados",
    search: true,
    placeholder: "Seleccione"
  }

  readonly tiposAsistentes = [
    'Quejoso / peticionario',
    'Querellante',
    'Testigo',
    'Presunto infractor',
    'Otro declarante'
  ]

  readonly tiposIdentificacion = [
    "Registro civil de nacimiento",
    "Tarjeta de identidad",
    "Cédula de ciudadanía",
    "Cédula de extranjería",
    "Pasaporte",
    "Documento identificación extranjero",
    "Permiso especial",
    "NIF del extranjero",
    "NUIP",
    "NIT"
  ]

  descargos: Descargo[] = [];
  descargo: Descargo = {}

  wantEditStatement: boolean = false;

  finalY: number = 15;
  nombreOtroCitado: any;

  constructor(
    private utilsService: UtilsService,
    private mecIntService: MecanismoIntermedioService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.mecIntService.getQuoted(this.caseId).then(quoted => {
      for(let citado of quoted){
        this.citados = [...this.citados, citado]
      }
      this.citados = [...this.citados, {nombreCompleto: 'Otro'}];
    })
    this.mecIntService.getGeneralFileInfo(this.caseId).then(response => {
      console.log(response);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      this.descargos = this.audiencia.descargos;
      this.audiencia.horaAudiencia = this.audiencia.horaAudiencia.split('T')[0];
      this.oldHearing = true;
      this.hearingIndex = this.audienciasDisponibles.findIndex(audiencia => {
        return audiencia === changes['selectedHearing'].currentValue
      })
    }
  }

  reviewSelectedName(event){
    if(event.value.nombreCompleto === 'Otro'){
      this.anotherSelected = true;
      this.quotedFound = {};
    } else {
      this.anotherSelected = false;
      this.mecIntService.findQuoted(this.caseId, event.value.identificacion).then(quoted => {
        this.quotedFound = quoted;
      })
    }
  }

  async getAdditionalPDFFields(nombreCompleto: string, identificacion: string) {
    if(nombreCompleto === 'Otro'){
      return ({
        correo: 'No definido',
        direccion: 'No definido',
        telefono: 'No definido'
      })
    } else {
      const quoted = await this.mecIntService.findQuoted(this.caseId, identificacion)
      return ({
        correo: quoted.correo,
        direccion: quoted.direccion,
        telefono: quoted.telefono  
      })
    }
  }

  editHearing(hearing){
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia.split("T")[0];
  }

  cancelHearing(){
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  addStatement(){
    if(
      !this.anotherSelected &&
      (Array.isArray(this.descargo.tipoAsistente) ||
      this.descargo.declaracion == undefined ||
      Array.isArray(this.descargo.nombreCitado))
    ){
      Swal.fire(
        'Error',
        'No se han agregado todos los campos obligatorios',
        'warning'
      );
    } else if (
      this.anotherSelected &&
      (Array.isArray(this.descargo.tipoAsistente) ||
      this.descargo.declaracion == undefined ||
      Array.isArray(this.descargo.tipoIdentificacion) ||
      this.descargo.identificacion == undefined)
    ) {
      Swal.fire(
        'Error',
        'No se han agregado todos los campos obligatorios',
        'warning'
      );
    } else {
      if(this.anotherSelected){
        this.descargos.push({
          nombreCitado: this.nombreOtroCitado,
          otraPersona: true,
          tipoIdentificacion: this.descargo.tipoIdentificacion,
          identificacion: this.descargo.identificacion,
          tipoAsistente: this.descargo.tipoAsistente,
          declaracion: this.descargo.declaracion
        })
      } else {
        const { nombreCompleto, tipoIdentificacion, identificacion } = this.quotedFound;
        this.descargos.push({
          nombreCitado: nombreCompleto,
          otraPersona: false,
          tipoIdentificacion,
          identificacion,
          tipoAsistente: this.descargo.tipoAsistente,
          declaracion: this.descargo.declaracion
        })
      }
      console.log(this.descargos);
      this.resetForm();
    }
  }

  formatDate(date: Date | string, separator?: string){
    return this.utilsService.formatDate(date, separator);
  }

  resetForm(){
    this.quotedFound = {};
    this.descargo = {};
    this.anotherSelected = false;
  }

  editStatement(statement: Descargo){
    this.wantEditStatement = true;
    this.descargo.tipoAsistente = statement.tipoAsistente;
    this.descargo.declaracion = statement.declaracion;
    if(statement.otraPersona){
      this.anotherSelected = true;
      this.descargo.tipoIdentificacion = statement.tipoIdentificacion;
      this.descargo.identificacion = statement.identificacion;
      this.nombreOtroCitado = statement.nombreCitado;
    } else {
      this.anotherSelected = false;
      this.descargo.nombreCitado = statement.nombreCitado;
    }
  }

  cancelStatement(){
    this.wantEditStatement = false;
    this.anotherSelected = false;
    this.resetForm();
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  onSaveInfo(f: NgForm){
    Object.assign(this.audiencia, {
      descargos: this.descargos
    });
    this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
    const data = {
      audiencias: this.audienciasDisponibles
    }
    this.mecIntService.saveInfo(this.mecIntId, data).catch(err => {
      console.log(err);
    });
  }

  async downloadStatement(descargo: Descargo){
    let doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    const informacionAdicional = await this.getAdditionalPDFFields(descargo.nombreCitado, descargo.identificacion);
    const rows = [
      [
        {
          nombre: 'nombreCitado',
          nombreArreglado: 'Citado'
        },
        {
          nombre: 'tipoAsistente',
          nombreArreglado: 'Tipo de asistente'
        },
      ],
      [
        {
          nombre: 'nombre',
          nombreArreglado: 'Nombre'
        },
        {
          nombre: 'apellido',
          nombreArreglado: 'Apellido'
        },
      ],
      [
        {
          nombre: 'tipoIdentificacion',
          nombreArreglado: 'Tipo de identificación'
        },
        {
          nombre: 'Identificación',
          nombreArreglado: 'Identificación'
        },
      ],
      [
        {
          nombre: 'nivelEducativo',
          nombreArreglado: 'Nivel educativo'
        },
        {
          nombre: 'ocupacion',
          nombreArreglado: 'Ocupación'
        },
      ],
      [
        {
          nombre: 'tipoVulnerabilidad',
          nombreArreglado: 'Tipo de población vulnerable'
        },
        {
          nombre: 'direccion',
          nombreArreglado: 'Dirección'
        },
      ],
      [
        {
          nombre: 'telefono',
          nombreArreglado: 'Teléfono'
        },
        {
          nombre: 'correo',
          nombreArreglado: 'Correo electrónico'
        },
      ],
    ]

    doc = this.setTitleStyle(doc);
    doc.text(`Audiencia fecha ${this.formatDate(this.audiencia.fechaAudiencia)} y hora ${this.audiencia.horaAudiencia}`, 15, this.finalY);
    this.finalY += 15;

    doc.text(`Declaración realizada por ${descargo.nombreCitado}`, 15, this.finalY);
    this.finalY += 15;
    
    doc.text('Tipo de identificación', 15, this.finalY);
    doc.text('Identificación', 105, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;

    doc.text(descargo.tipoIdentificacion || 'No definido', 15, this.finalY);
    doc.text(descargo.identificacion || 'No definido', 105, this.finalY);
    this.finalY += 15;

    doc = this.setTitleStyle(doc);
    doc.text('Correo', 15, this.finalY, { maxWidth: 90 });
    doc.text('Dirección', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 10;
    doc = this.setNormalStyle(doc);
    doc.text(informacionAdicional.correo || 'No definido', 15, this.finalY, { maxWidth: 90 });
    doc.text(informacionAdicional.direccion || 'No definido', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 15;

    doc = this.setTitleStyle(doc);
    doc.text('Teléfono', 15, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    doc.text(String(informacionAdicional.telefono) || 'No definido', 15, this.finalY)
    this.finalY += 15;

    doc = this.setTitleStyle(doc);
    doc.text('Declaración', 15, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    doc.text(descargo.declaracion || 'No definido', 15, this.finalY, { maxWidth: 180 });

    doc.save(`Descargo audiencia ${this.formatDate(this.audiencia.fechaAudiencia, '_')} - ${descargo.nombreCitado}.pdf`);

  }

  /**
   * Set de los estilos negrillas del PDF
   */
  setTitleStyle(doc){
    doc.setFont('bold');
    doc.setFontSize(14);
    doc.setTextColor('#332F2E');
    return doc;
  }

  /**
   * Set de los estilos texto normal del PDF
   */
  setNormalStyle(doc){
    doc.setFont('normal');
    doc.setFontSize(13);
    doc.setTextColor('#332F2E');
    return doc;
  }

}
