<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="premisas-container">
    <div class="d-flex-between">
      <div class="left-side">
        <button class="go-back-button" routerLink="/biblioteca-inspecciones">
          <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
        </button>
        <h1 class="principal-title mb-30">Recursos adicionales</h1>
      </div>
    </div>

    <div class="gestion-principal">
      <div class="d-flex-between mb-20 pb-20 light-border">
        <h2 class="secondary-title">Histórico de casos</h2>
        <!-- <button class="button active padding-0" (click)="filtrarCasos()"><img class="filter-img" src="../../../assets/img/inspecciones/filtro.png" alt="filtro"></button> -->
      </div>
      <mat-form-field class="width-100">
        <mat-label>Filtrar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Escribe aquí el valor que quieres buscar" #input>
      </mat-form-field>
      <table mat-table aria-label="Tabla de recursos adicionales al módulo" [dataSource]="dataSource">
        <ng-container matColumnDef="tipoRecurso">
          <th scope="row" aria-label="Columna de tipo de recurso" mat-header-cell *matHeaderCellDef>Tipo de recurso</th>
          <td mat-cell *matCellDef="let element"> {{element.tipoRecurso}} </td>
        </ng-container>
        <ng-container matColumnDef="descripcionGeneral">
          <th scope="row" aria-label="Columna de descripción general" mat-header-cell *matHeaderCellDef>Descripción general</th>
          <td mat-cell *matCellDef="let element"> {{element.descripcionGeneral}} </td>
        </ng-container>
        <ng-container matColumnDef="enlaces">
          <th scope="row" aria-label="Columna de enlaces" mat-header-cell *matHeaderCellDef>Enlaces</th>
          <td mat-cell *matCellDef="let element" (click)="goToPage(element.enlaces)" class="link">{{ element.enlaces.join(', ') }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Selecciona una página de la lista de casos"
      ></mat-paginator>
    </div>

    <!-- <main class="main-prev-mec">
      <section>
        <table>
          <tr>
            <th>Tipo de recurso <input type = "text" placeholder="Referencia" class = "input inputRef"></th>
            <th>Descripción general <input type = "text" placeholder="Descripción general" class = "input inputGen"></th>
            <th>Enlace <input type = "text" placeholder="Enlace" class = "input inputEnl"></th>
          </tr>
          <tr>
            <td style="width: 30%; vertical-align:top;">Página web</td>
            <td style="width: 30%; vertical-align:top;">Fundación Ideas para la Paz FIP. Documentos del área de Seguridad y Política Criminal</td>
            <td style="width: 30%; vertical-align:top;"><a href = "">https://www.corteconstitucional.gov.co/relatoria/2017/C-225.17.htm</a></td>
          </tr>
          <tr>
            <td style="width: 30%; vertical-align:top;">Video Youtube</td>
            <td style="width: 30%; vertical-align:top;">Conferencia de Anibal Torres Rico sobre el comparendo de policía</td>
            <td style="width: 30%; vertical-align:top;"><a href = "">https://www.corteconstitucional.gov.co/relatoria/2017/C-225.17.htm</a></td>
          </tr>
          <tr>
            <td style="width: 30%; vertical-align:top;">Página web</td>
            <td style="width: 30%; vertical-align:top;">Sentencias de la Corte Constitucional relaciondas con el CNSCC</td>
            <td style="width: 30%; vertical-align:top;"><a href = "">https://www.corteconstitucional.gov.co/relatoria/2017/C-225.17.htm</a></td>
          </tr>
          <tr>
            <td style="width: 30%; vertical-align:top;">Página web</td>
            <td style="width: 30%; vertical-align:top;">Sentencias de la Corte Constitucional relaciondas con el CNSCC</td>
            <td style="width: 30%; vertical-align:top;"><a href = "">https://www.corteconstitucional.gov.co/relatoria/2017/C-225.17.htm</a></td>
          </tr>
        </table>
      </section>
    </main> -->

    <main class="main-prev-mec">
      <div class="gestion-principal">
        <!-- <ng2-smart-table [settings]="settings" [source]="data"></ng2-smart-table> -->
      </div>
    </main>
  </div>
</main>
