import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { MainService, SuccessResponse } from '../../services/main.service';
import { S3Service } from '../../services/s3.service';
import { FichaGeneral, FichaGeneralExtraData, Infractor, Inspector, Peticionario } from '../inspecciones.interface';

@Injectable()
export class GeneralFileService {

  bucket: S3;

  constructor(
    private mainService: MainService,
    private s3Service: S3Service
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  

  /**
   * Obtiene la inormación relacionada a la ficha general
   * @param caseId El id del caso
   * @returns {Promise<FichaGeneral> } Información de la ficha general
   */
  getGeneralInfo(caseId: string): Promise<FichaGeneral> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          resolve(response.result);
        } else {
          reject(response.error)
        }
      })
    })
  }

  /**
   * Obtiene la información relacionada a las personas involucradas en el caso
   * @param caseId El id del caso
   * @returns {Promise<{ infractores: Infractor[], peticionarios: Peticionario[], inspector: Inspector }>} Personas relacionadas
   */
  getRelatedPeople(caseId): Promise<{ infractores: Infractor[], peticionarios: Peticionario[], inspector: Inspector }> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const { infractores, peticionarios } = response.result;
          resolve({
            infractores,
            peticionarios,
            inspector: response.result.caso.inspector,
          });
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Guarda el archivo en el servidor de S3
   * @param file El archivo que se va a subir, tipo file
   * @returns el link donde se guardó el archivo
   */
  saveInS3 = (file): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (file !== undefined) {
        // Obtener la información necesaria
        let { params, options } = this.s3Service.getInfoForUpload('general',file);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if(err){
            reject(err);
          } else {
            const archivo = data.Location
            resolve(archivo);
          }
        });
      }
    });
  }

  saveInfo(idFicha: string, data: Partial<FichaGeneral>): Promise<FichaGeneral> {
    return new Promise((resolve, reject) => {
      this.mainService.put<FichaGeneral>(`api/ficha-general/${idFicha}`, data).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Muestra un mensaje de éxito al subir una imagen
   */
  showS3UploadSuccess(mensajeFooter?: string){
    this.s3Service.showSuccessUploading(mensajeFooter);
  }


  /**
   * Muestra un mensaje de error al subir una imagen
   */
  showS3UploadError(){
    this.s3Service.showErrorUploading();
  }
}
