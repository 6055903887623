import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PvaService } from './pva.service';

@Component({
  selector: 'app-pva-caso',
  templateUrl: './pva-caso.component.html',
  styleUrls: ['./pva-caso.component.css']
})
export class PvaCasoComponent implements OnInit {

  selectedPhase: number = 1;
  caseId: string = '';
  caseNumber: string = '';
  inspectionNumber: string = '';
  presuntosInfractores: any = [];
  cases: any = [];

  @Input() selectedHearing: any = {};

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  audienciasDisponibles: any[] = [];
  pvaId: string;
  correosEnviados: any[] = [];
  isSelected: boolean = false;

  constructor(
    private router: Router,
    private pvaService: PvaService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getCaseInfo();
    this.getPvaInfo();
  }

  goBackButton(){
    this.router.navigate([`/gestion-casos-inspecciones/ver-caso/${this.caseId}`]);
  }

  /**
   * Va a la pantalla de más información
   */
  goToMoreInfo(){
    this.router.navigateByUrl(
      this.router.createUrlTree(['/submodulos-inspecciones/pva'], { queryParams: { previousPage: 'pva', caseId: this.caseId } })
    );
  }

  getCaseInfo(){
    this.pvaService.getGeneralFileInfo(this.caseId).then(response => {
      this.presuntosInfractores = response.infractores;
      this.caseNumber = response.caso.numCaso;
      this.inspectionNumber = response.caso.numInspeccion;
      this.cases = response.CCC;
    })
  }

  // onUploadVideosInput(event){
  //   this.onUploadVideos(event).then((response: any) => {
  //     const updatedFiles: any[] = response.updatedFiles;
  //     const files: File[] = response.files;
  //     const respuestaConsola = updatedFiles.map((updatedFile, index) => {
  //       return ({
  //         nombre: files[index].name,
  //         url: updatedFile,
  //         tipo: files[index].type
  //       });
  //     });
  //     console.log(respuestaConsola);
  //   })
  // }

  onUploadVideos(event){
    return new Promise((resolve, reject) => {
      const files: File[] = Object.values(event.target.files);
      const result = Promise.all(files.map((file) => this.pvaService.saveVideosInS3(file)));
      result.then(res => {
        const updatedFiles = res;
        this.pvaService.showS3UploadSuccess();
        resolve({updatedFiles, files});
    })
    }).catch(err => {
      this.pvaService.showS3UploadError();
      console.error(err)
    })
  }

  getPvaInfo(){
    this.pvaService.getAudienceInfo(this.caseId).then(response => {
      console.log(response);
      this.audienciasDisponibles = response.audienciasDisponibles;
      this.pvaId = response.pvaId;
      this.correosEnviados = response.correosEnviados;
    })
  }

  hearingSelected({hearing, isSelected}){
    if(!isSelected){
      this.selectedHearing = {};
      this.isSelected = false;
    } else {
      this.selectedHearing = hearing;
      this.isSelected = isSelected
    }
  }
}
