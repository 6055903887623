import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { GeneralFileService } from '../../../form-ficha-general/general-file.service';
import { AudienciaPVA, FichaGeneral, FichaGeneralExtraData, MecanismoPrevio, Pva } from '../../../inspecciones.interface';
import { PvaService } from '../pva.service';
import { jsPDF } from 'jspdf';
import * as QRCode from 'qrcode';
import { MecanismoPrevioService } from '../../mecanismo-previo/mecanismo-previo.service';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-decision-pva',
  templateUrl: './decision-pva.component.html',
  styleUrls: ['./decision-pva.component.css'],
})
export class DecisionPvaComponent implements OnInit {
  todayDate = new Date().toISOString().split("T")[0];
  // Indica si interpone reposicion
  interponeReposicion: boolean = false
  // Indica si interpone apelación
  interponeApelacion: boolean = false
  // Indica si es de tipo presencial o virtual
  audiencia: boolean = false
  // Pva general
  pva: Partial<Pva> = {
    createdAt: undefined,
    caso: undefined,
    audiencias: [],
    pruebasGeneralesAportadasSolicitante: [],
    pruebasGeneralesAportadas: [],
    pruebasPracticadas: [],
    medidasCorrectivas: [{}],
    archivosAudiencia: [],
    firmaInspector: undefined,
    nombreAgente: '',
    firmaAgente: undefined,
    nombreAuxAdm: '',
    firmaAuxAdm: undefined,
    investigados: [],
    peticionarios: [],
    seguimientos: []
  };
  // Nombre del inspector
  inspectorNombre: string;
  // Configuración del selectable
  readonly config = {
    search: true,
    noResultsFound: "No se encontraron resultados",
    placeholder: 'Seleccione'
  }
  // Lista de las posibles decisiones
  readonly tiposDecisiones = [
    'Abstenerse de imponer medida correctiva',
    'Imponer medida correctiva'
  ];
  readonly decisionAbstener = 'Abstenerse de imponer medida correctiva';
  // Lista de las posibles medidas correctivas
  readonly tiposMedidasCorrectivas = [
    'Amonestación',
    'Participación en programa comunitario o actividad pedagógica de convivencia',
    'Disolución de reunión o actividad que involucra aglomeraciones de público no complejas',
    'Expulsión de domicilio',
    'Prohibición de ingreso a actividad que involucra aglomeraciones de público complejas o no complejas',
    'Decomiso',
    'Multa general tipo 1',
    'Multa general tipo 2',
    'Multa general tipo 3',
    'Multa general tipo 4',
    'Multa especial por organización de aglomeración compleja',
    'Multa especial por infracción urbanística',
    'Multa especial por contaminación visual',
    'Construcción, cerramiento, reparación o mantenimiento de inmueble',
    'Remoción de bienes',
    'Reparación de daños materiales de muebles o inmuebles',
    'Reparación de daños materiales por perturbación a la posesión y tenencia de inmuebles',
    'Restablecimiento del derecho de servidumbre y reparación de daños materiales',
    'Restitución y protección de bienes inmuebles',
    'Destrucción de bien',
    'Demolición de obra',
    'Suspensión de construcción o demolición',
    'Suspensión de actividad que involucre aglomeración de público compleja',
    'Suspensión temporal de actividad',
    'Suspensión definitiva de actividad',
    'Inutilización de bienes'
  ];

  tipoMedidaCorrectiva: string = '';
  // Detalle de la medida correctiva
  detalleMedidaCorrectiva: string = '';
  // Indica si se quiere editar la medida correctiva
  wantEditAction: boolean = false;
  // Índice de la medida correctiva
  actionIndex: number;

  // Lista de investigados
  investigados: string[];
  // Lista de investigados activos
  investigadosActivos: any[] = [];
  // Campos del investigado
  investigado: any = {
    nombreCompleto: '',
    firma: {
      url: undefined,
      tipo: undefined
    }
  }
  // Índice del investigado
  investidatedIndex: number = undefined;
  // Indica si se quiere editar el investigado
  wantEditInvestigated: boolean = false;
  // Lista de peticionarios
  peticionarios: string[];
  // Lista de peticionarios activos
  peticionariosActivos: any[] = [];
  // Objeto del peticionario
  peticionario: any = {
    nombreCompleto: '',
    firma: {
      url: undefined,
      tipo: undefined
    }
  }
  // Indice del peticionario
  petitionerIndex: number = undefined;
  // Indica si se quiere editar el peticionario
  wantEditPetitioner: boolean = false;
  /** Id del proceso verbal abreviado */
  @Input() pvaId: string;
  /** Id del caso */
  caseId: string;
  /** Numero de inspección */
  numInspeccion: string;
  /** Nombre del municipio */
  nombreMunicipio: string;

  doc: jsPDF;
  finalY: number = 15;
  fichaGeneral: FichaGeneral

  mecPrevio: MecanismoPrevio

  constructor(
    private pvaService: PvaService,
    private generalFileService: GeneralFileService,
    private route: ActivatedRoute,
    private mecPrevService: MecanismoPrevioService,
    private utilsService: UtilsService
  ) {

  }

  ngOnInit() {
    this.getData();
  }

  /**
   * Obtiene toda la información necesaria
   */
  getData(){
    this.caseId = this.route.snapshot.params['id'];
    this.getPvaInfo();
    this.getGeneralFileInfo();
  }

  /**
   * Obtiene la información del Proceso Verbal Abreviado
   */
  getPvaInfo(){
    this.pvaService.getAllInfo(this.pvaId).then(response => {
      const { fechaDecision, horaDecision, consideracionesInspector, tipoDecision, medidasCorrectivas, autoridadResuelve, autoridadResuelveSegundo, interponeReposicion, interponeApelacion, sustentacionRecurso,decisionSobreRecursos, tipoAudiencia, archivosAudiencia, firmaInspector, nombreAgente, firmaAgente, nombreAuxAdm, firmaAuxAdm, investigados, peticionarios, audiencias } = response;
      if(tipoAudiencia === 'presencial'){
        this.audiencia = true;
      } else if (tipoAudiencia === 'virtual'){
        this.audiencia = false;
      } else {
        this.audiencia = undefined;
      }
      this.pva = {
        audiencias,
        fechaDecision: fechaDecision ? (<string>fechaDecision).split("T")[0] : this.todayDate,
        horaDecision,
        consideracionesInspector,
        tipoDecision,
        medidasCorrectivas: medidasCorrectivas,
        autoridadResuelve,
        autoridadResuelveSegundo,
        interponeReposicion,
        interponeApelacion,
        sustentacionRecurso,
        decisionSobreRecursos,
        tipoAudiencia,
        archivosAudiencia,
        firmaInspector,
        nombreAgente,
        firmaAgente,
        nombreAuxAdm,
        firmaAuxAdm,
        investigados,
        peticionarios,
      }
      this.filterActiveInvestigated();
      this.filterActivePetitioners();
    }).catch(err => console.error(err));
  }

  /**
   * Obtiene los peticionarios, investigados y el inspector
   */
  getGeneralFileInfo(){
    this.generalFileService.getGeneralInfo(this.caseId).then(response => {

      this.inspectorNombre = `${response.caso.inspector ? response.caso.inspector.nombre : ''} ${response.caso.inspector ? response.caso.inspector.apellido : ''}`
      this.investigados = response.infractores.map(infractor => {
        return `${infractor.nombreInfractor ? infractor.nombreInfractor + ' ' : ''}${infractor.apellidoInfractor ? infractor.apellidoInfractor : ''}`;
      });
      this.nombreMunicipio = response.municipio;
      this.numInspeccion = response.caso.numInspeccion;
      this.peticionarios = response.peticionarios.map(peticionario => peticionario.nombrePeticionario);
    }).catch(error => console.error(error));
  }

  /**
   * Añade la medida correctiva a la lista de meiddas correctivas
   */
  addAction(){
    if(Array.isArray(this.tipoMedidaCorrectiva) || this.detalleMedidaCorrectiva == '' || this.detalleMedidaCorrectiva == undefined){
      Swal.fire({
        title: 'Error',
        text: 'La medida correctiva debe tener tipo y detalle',
        icon: 'error'
      });
    } else {
      this.pva.medidasCorrectivas.push({
        tipoMedidaCorrectiva: this.tipoMedidaCorrectiva,
        detalleMedidaCorrectiva: this.detalleMedidaCorrectiva
      });
      this.cleanActionFields();
      Swal.fire({
        title: 'Éxito',
        text: 'La medida correctiva se ha añadido exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      });
    }
  }

  /**
   * Limpia los campos de la medida correctiva
   */
  cleanActionFields(){
    this.tipoMedidaCorrectiva = '';
    this.detalleMedidaCorrectiva = '';
    this.actionIndex = undefined;
    this.wantEditAction = false;
  }

  /**
   * Le indica al programa que se quiere editar la medida correctiva
   * @param action La medida correctiva de la lista de medidas correctivas
   * @param index El índice de la medida correctiva
   */
  editAction(action, index){
    const selectedAction = action;
    this.tipoMedidaCorrectiva = selectedAction.tipoMedidaCorrectiva;
    this.detalleMedidaCorrectiva = selectedAction.detalleMedidaCorrectiva;
    this.wantEditAction = true;
    this.actionIndex = index;
  }

  /**
   * Guarda los cambios de la medida correctiva
   */
  saveAction(){
    if(Array.isArray(this.tipoMedidaCorrectiva) || this.detalleMedidaCorrectiva == '' || this.detalleMedidaCorrectiva == undefined){
      Swal.fire({
        title: 'Error',
        text: 'La medida correctiva debe tener tipo y detalle',
        icon: 'error'
      });
    } else {
      this.pva.medidasCorrectivas[this.actionIndex] = {
        tipoMedidaCorrectiva: this.tipoMedidaCorrectiva,
        detalleMedidaCorrectiva: this.detalleMedidaCorrectiva
      }
      this.cleanActionFields();
      Swal.fire({
        title: 'Éxito',
        text: 'La medida correctiva se ha actualizado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    }
  }

  /**
   * Cancela la edición de la medida correctiva
   */
  cancelAction(){
    this.cleanActionFields();
  }

  /**
   * Elimina la medida correctiva
   * @param index El indice de la medida correctiva
   */
  deleteAction(index){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar esta medida correctiva?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.pva.medidasCorrectivas.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'La medida correctiva se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Cambia el estatus para el campo de si interpone reposición
   * @param status El status al cual se quiere cambiar
   */
  changeAccomplishmentReposicion(status) {
    if (
      (status && !this.pva.interponeReposicion) ||
      (!status && this.pva.interponeReposicion) ||
      (status && this.pva.interponeReposicion === undefined) ||
      (!status && this.pva.interponeReposicion === undefined)
    ) {
      this.pva.interponeReposicion = status;
    } else {
      this.pva.interponeReposicion = undefined;
    }
  }

  /**
   * Cambia el estatus para el campo de si interpone apelación
   * @param status El status al cual se quiere cambiar
   */
  changeAccomplishmentApelacion(status){
    if (
      (status && !this.pva.interponeApelacion) ||
      (!status && this.pva.interponeApelacion) ||
      (status && this.pva.interponeApelacion === undefined) ||
      (!status && this.pva.interponeApelacion === undefined)
    ) {
      this.pva.interponeApelacion = status;
    } else {
      this.pva.interponeApelacion = undefined;
    }
  }

  /**
   * Cambia el tipo de audiencia
   * @param status true o false dependiendo de si es presencial o virtual, respectivamente.
   */
  changeAccomplishmentAudiencia(status){
    if (
      (status && !this.audiencia) ||
      (!status && this.audiencia) ||
      (status && this.audiencia === undefined) ||
      (!status && this.audiencia === undefined)
    ) {
      this.audiencia = status;
    } else {
      this.audiencia = undefined;
    }
  }

  /**
   * Sube múltiples archivos a s3 y añade las URLs resultantes a los archivos de la audiencia
   * @param event El evento del input de tipo archivo
   */
  onFileMultipleChange(event){
    const files: File[] = Object.values(event.target.files);
    const result = Promise.all(files.map((file) => this.pvaService.saveInS3(file)));
    result.then(res => {
      const photosLinks = res;
      this.pvaService.showS3UploadSuccess();
      photosLinks.forEach((url, index) => this.pva.archivosAudiencia.push({
        url,
        tipo: files[index].type,
        activo: true
      }));
    }).catch(err => {
      this.pvaService.showS3UploadError();
      console.error(err)
    })
  }

  /**
   * Sube un archivo a s3 y añade la URL resultante al objeto que corresponda
   * @param event El evento del input de tipo archivo
   * @param tipoFirma El lugar a donde se va a enviar la firma
   */
  onFileChange(event, tipoFirma){
    const file: File = event.target.files[0];
    this.pvaService.saveInS3(file).then(archivoSubido => {
      this.pvaService.showS3UploadSuccess();
      const objFirma = {
        url: archivoSubido,
        tipo: file.type
      }
      switch (tipoFirma) {
        case 'firma-inspector':
          this.pva.firmaInspector = objFirma;
          break;
        case 'firma-agente':
          this.pva.firmaAgente = objFirma;
          break;
        case 'firma-aux':
          this.pva.firmaAuxAdm = objFirma;
          break;
        case 'firma-investigado':
          this.investigado.firma = objFirma;
          break;
        case 'firma-peticionario':
          this.peticionario.firma = objFirma;
          break;
      }
    }).catch(err => {
      this.pvaService.showS3UploadError();
      console.error(err);
    })
  }

  /**
   * Elimina un registro de la audiencia a partir de su indice
   * @param index El indice del registro a partir de la lista de registros
   */
  deleteAudienceFile(index){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este registro?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.pva.archivosAudiencia[index].activo = false;
        Swal.fire({
          title: 'Éxito',
          text: 'El registro se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Filtra los investigados que tienen de estado activo
   */
  filterActiveInvestigated(){
    this.investigadosActivos = this.pva.investigados.filter(investigado => investigado.activo);
  }

  /**
   * Filtra los peticionarios que tienen de estado activo
   */
  filterActivePetitioners(){
    this.peticionariosActivos = this.pva.peticionarios.filter(peticionario => peticionario.activo);
  }

  /**
   * Añade un investigado a la lista de investigados
   */
  addInvestigated(){
    if(this.investigado.firma.url && this.investigado.nombreCompleto){
      this.pva.investigados.push({
        nombreCompleto: this.investigado.nombreCompleto,
        firma: this.investigado.firma,
        activo: true
      });
      this.filterActiveInvestigated();
      this.cleanInvestigatedFields();
      Swal.fire({
        title: 'Éxito',
        text: 'El investigado se ha agregado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos del investigado',
        icon: 'error'
      });
    }
  }

  /**
   * Limpia los campos del investigado
   */
  cleanInvestigatedFields(){
    this.investigado = {
      nombreCompleto: '',
      firma: {
        url: undefined,
        tipo: undefined
      }
    }
    this.investidatedIndex = undefined;
    this.wantEditInvestigated = false;
  }

  /**
   * Le indica al programa que se quiere editar un investigado para que se adecúe
   * @param investigado El investigado de la lista de peticionarios
   * @param index El indice del investigado
   */
  editInvestigated(investigado, index){
    this.investigado = investigado;
    this.investidatedIndex = index;
    this.wantEditInvestigated = true;
  }

  /**
   * Quita el activo del investigado que se quiere eliminar
   * @param index El indice del investigado a eliminar
   */
  deleteInvestigated(index){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este investigado?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.pva.investigados[index].activo = false;
        this.filterActiveInvestigated();
        this.cleanInvestigatedFields();
        Swal.fire({
          title: 'Éxito',
          text: 'El investigado se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Guarda la información del investigado
   */
  saveInvestigated(){
    if(Array.isArray(this.investigado.nombreCompleto) || this.investigado.nombreCompleto == '' || !this.investigado.firma.url){
      Swal.fire({
        title: 'Error',
        text: 'El investigado debe tener nombre y firma',
        icon: 'error'
      });
    } else {
      this.pva.investigados[this.investidatedIndex] = this.investigado
      this.cleanActionFields();
      Swal.fire({
        title: 'Éxito',
        text: 'La medida correctiva se ha actualizado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    }
  }

  /**
   * Cancela la edición del investigado
   */
  cancelInvestigated(){
    this.cleanInvestigatedFields();
  }

  // PETICIONARIO

  /**
   * Añade un peticionario a la lista de peticionarios
   */
  addPetitioner(){
    if(this.peticionario.firma.url && this.peticionario.nombreCompleto){
      this.pva.peticionarios.push({
        nombreCompleto: this.peticionario.nombreCompleto,
        firma: this.peticionario.firma,
        activo: true,
      });
      this.filterActivePetitioners();
      this.cleanPetitionerFields();
      Swal.fire({
        title: 'Éxito',
        text: 'El investigado se ha agregado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos del peticionario',
        icon: 'error'
      });
    }
  }

  /**
   * Le indica al programa que se quiere editar un peticionario para que se adecúe
   * @param petitioner El peticionario de la lista de peticionarios
   * @param index El indice del peticionario de la lista de peticionarios
   */
  editPetitioner(petitioner, index){
    this.peticionario = petitioner;
    this.petitionerIndex = index;
    this.wantEditPetitioner = true;
  }

  /**
   * Guarda los cambios del peticionario
   */
  savePetitioner(){
    if(Array.isArray(this.peticionario.nombreCompleto) || this.peticionario.nombreCompleto == '' || !this.peticionario.firma.url){
      Swal.fire({
        title: 'Error',
        text: 'El peticionario debe tener nombre y firma',
        icon: 'error'
      });
    } else {
      this.pva.peticionarios[this.petitionerIndex] = this.peticionario
      this.cleanActionFields();
      Swal.fire({
        title: 'Éxito',
        text: 'La medida correctiva se ha actualizado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    }
  }

  /**
   * Cancela la edición del peticionario
   */
  cancelPetitioner(){
    this.cleanPetitionerFields();
  }

  /**
   * Quita el activo a un peticionario de la lista de peticionarios
   * @param index El indice del peticionario dentro de la lista de peticionarios
   */
  deletePetitioner(index){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este peticionario?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.pva.peticionarios[index].activo = false;
        this.filterActivePetitioners();
        this.cleanPetitionerFields();
        Swal.fire({
          title: 'Éxito',
          text: 'El peticionario se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Limpia los campos del peticionario
   */
  cleanPetitionerFields(){
    this.peticionario = {
      nombreCompleto: undefined,
      firma: {
        url: undefined,
        tipo: undefined
      }
    }
    this.peticionario.nombreCompleto = "";
    this.petitionerIndex = undefined;
    this.wantEditPetitioner = false;
  }

  /**
  //  * Gestiona la descarga del PDF de todo lo relacionado a la decisión, descargos, y ficha general
   */
  async downloadPDF(){
    try {
      this.doc = new jsPDF('portrait','mm','a4');
      this.finalY = 15;
      this.fichaGeneral = await this.generalFileService.getGeneralInfo(this.caseId);
      this.mecPrevio = await this.mecPrevService.getAllInfo(this.caseId);
      this.setPrincipalTitle();
      this.doc.text(`Decisión y recursos proceso verbal abreviado - caso ${this.fichaGeneral.caso.numCaso}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 15;
      this.getCCCs();
      this.getGeneralFileInfo();
      console.log(this.pva);
      for(let sesion of this.pva.audiencias){
        let indice = this.pva.audiencias.indexOf(sesion);
        this.setPrincipalTitle();
        this.doc.text(`Sesión número ${indice+1}`, 15, this.finalY);
        this.finalY += 15;
        this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        this.setNormalStyle();
        this.getGeneralDataInfo(sesion);
        await this.getStatementsFromSesion(sesion);
      }
      await this.getDecision();
      this.doc.save(`Decisión proceso verbal abreviado - caso ${this.fichaGeneral.caso.numCaso}.pdf`);
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al descargar el PDF ' + err,
        icon: 'error'
      });
    }
  }

  /**
   * Obtiene los comportamientos contrario a la convivencia y los agrega al PDF
   */
  getCCCs(): void {
    this.setTitleStyle();
    const rows = [
      [
        {
          nombre: 'titulo',
          nombreArreglado: 'Título',
        },
        {
          nombre: 'capitulo',
          nombreArreglado: 'Capítulo',
        }
      ],
      [
        {
          nombre: 'articulo',
          nombreArreglado: 'Artículo',
        },
        {
          nombre: 'numeral',
          nombreArreglado: 'Numeral',
        }
      ],
      [
        {
          nombre: 'literal',
          nombreArreglado: 'Literal',
        },
      ],
      [
        {
          nombre: 'descripcionComportamiento',
          nombreArreglado: 'Descripción del comportamiento',
        }
      ],
    ]
    this.setPrincipalTitle();
    this.doc.text('Comportamientos contrarios a la convivencia relacionados', 15, this.finalY);
    this.finalY += 8;
    for(let ccc of this.fichaGeneral.CCC){
      this.setTitleStyle()
      this.doc.text(`Comportamiento contrario a la convivencia #${this.fichaGeneral.CCC.findIndex(c => c == ccc)+1}`, 15, this.finalY);
      this.setNormalStyle()
      for(let row of rows){
        let j = 0;
        this.finalY = this.verifyEndOfPage({doc: this.doc, value: this.finalY}) + 12;
        for(let field of row){
          let i = 0;
          this.setTitleStyle()
            this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
            i++;
            this.setNormalStyle()
            if(ccc[field.nombre]){
              if(ccc[field.nombre].length > 140){
                this.doc.text(String(ccc[field.nombre].substring(0, 140) + '...'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
              } else {
                this.doc.text(String(ccc[field.nombre].substring(0, ccc[field.nombre].length)), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
              }
            } else {
              this.doc.text(String('No asignado'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
            }
            j++;
        }
        this.finalY += this.getMoreHeightFromTextHeight(ccc, row);
      }
      this.finalY += 10;
    }
  }

  /**
   * Obtiene datos generales de la audiencia
   * @param {AudienciaPVA} sesion La audiencia/sesión que se está revisando
   */
  getGeneralDataInfo(sesion: AudienciaPVA): void {
    this.setTitleStyle();
    this.doc.text('Fecha', 15, this.finalY);
    this.doc.text('Hora', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.formatDate(sesion.fechaAudiencia) || 'No definido', 15, this.finalY);
    this.doc.text(sesion.horaAudiencia || 'No definido', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Desarrollo de la audiencia', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    const bigText = `En el municipio de ${this.fichaGeneral.municipio || 'NO DEFINIDO'} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha ${sesion.autoFecha ? this.formatDate(sesion.autoFecha) : 'NO DEFINIDO'} y previas las comunicaciones de rigor, el Inspector, ${this.fichaGeneral.caso.inspector.codigoInspector || 'NO DEFINIDO'} da inicio a la Audiencia Pública.`
    this.doc.text(bigText, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 20;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Observaciones (descripción detallada del caso)', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(sesion.observaciones || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('Otros medios utilizados por la Policía', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(sesion.otrosMediosUtilizados || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('¿Asiste el (los) citado?', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    for(let citado of sesion.citados){
      this.doc.text(`${citado.nombreCompleto || (citado.nombre || '' + citado.apellido || '')}: ${citado.asiste ? 'Sí' : 'No'}`, 15, this.finalY, { maxWidth: 180 });
      this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      if(citado.asiste === false){
        this.doc.text(citado.justificaInasistencia ? 'Sí justifica inasistencia' : 'No justifica inasistencia', 15, this.finalY);
        this.finalY += 7;
      }
    }
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle()
    this.doc.text('¿Asiste Agente del Ministerio Público?', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(sesion.asisteAgente ? `Sí, ${sesion.nombreAgente}` : 'No', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
  }

  /**
   * Obtiene los descargos de la audiencia
   * @param {AudienciaPVA} sesion La audiencia/sesión que se está revisando
   */
  async getStatementsFromSesion(sesion: AudienciaPVA){
    this.setTitleStyle();
    this.doc.text('Declaraciones realizadas en la audiencia', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    const informacionAdicional = await this.getAdditionalPDFFields(sesion.descargos.map(s => s.nombreCitado), sesion.descargos.map(s => s.identificacion));
    console.log(informacionAdicional);
    console.log(sesion.descargos);
    if(sesion.descargos.length === 0){
      this.doc.text('Esta audiencia no tiene declaraciones', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
    for(let descargo of sesion.descargos){
      let index = sesion.descargos.indexOf(descargo);
      console.log('indice', index);
      this.setTitleStyle();
      this.doc.text(`Declaración realizada por ${descargo.nombreCitado}`, 15, this.finalY);
      this.finalY += 15;
      this.setNormalStyle();
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Tipo de identificación', 15, this.finalY);
      this.doc.text('Identificación', 105, this.finalY);
      this.setNormalStyle()
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text(descargo.tipoIdentificacion || 'No definido', 15, this.finalY);
      this.doc.text(descargo.identificacion || 'No definido', 105, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Correo', 15, this.finalY, { maxWidth: 90 });
      this.doc.text('Dirección', 105, this.finalY, { maxWidth: 90 });
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(informacionAdicional[index] ? informacionAdicional[index].correo : 'No definido', 15, this.finalY, { maxWidth: 90 });
      this.doc.text(informacionAdicional[index] ? informacionAdicional[index].direccion : 'No definido', 105, this.finalY, { maxWidth: 90 });
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Teléfono', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(informacionAdicional[index] && informacionAdicional[index].telefono ? String(informacionAdicional[index].telefono) : 'No definido', 15, this.finalY)
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Declaración', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(descargo.declaracion || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += 25;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
  }

  /**
   * Obtiene información adicional de los implicados en la audiencia, para añadirlos al PDF
   * @param {string[]} nombresCompletos Los nombres de las personas implicadas
   * @param {string[]} identificaciones Las identificaciones de las personas implicadas
   * @returns {Promise<{ correo: string, direccion: string, telefono: string }[]>} Objeto con los datos de importancia de las personas
   */
  async getAdditionalPDFFields(nombresCompletos: string[], identificaciones: string[]): Promise<{ correo: string, direccion: string, telefono: string }[]> {
    console.log(nombresCompletos);
    console.log(identificaciones);
    const informacionOtros = nombresCompletos.filter(nombre => nombre === 'Otro').map(() => {
      return ({
        correo: 'No definido',
        direccion: 'No definido',
        telefono: 'No definido'
      })
    });
    console.log(informacionOtros);
    const quoted = await this.pvaService.findMultipleQuoted(this.caseId, identificaciones);
    const informacionEncontrados = quoted.map(q => {
      return ({
        correo: q.correo || 'No definido',
        direccion: q.direccion || 'No definido',
        telefono: q.telefono ? String(q.telefono) : 'No definido'
      })
    })
    console.log(informacionEncontrados);
    console.log([...informacionOtros, ...informacionEncontrados]);
    return(informacionOtros.concat(informacionEncontrados))
  }

  /**
   * Obtiene los campos relacionados a la decisión y los incluye en el PDF
   */
  async getDecision(){
    this.setPrincipalTitle();
    this.doc.text('Decisión y recursos', 15, this.finalY);
    this.setTitleStyle();
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Fecha', 15, this.finalY);
    this.doc.text('Hora', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.pva.fechaDecision.toString() || 'No definido', 15, this.finalY);
    this.doc.text(this.pva.horaDecision || 'No definido', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Teniendo en cuenta la anterior información, relativa a los datos generales, descargos y otras declaraciones, y previas las siguientes consideraciones de hecho y de derecho:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Razones del inspector, análisis de los hechos, las pruebas y las normas', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.consideracionesInspector || 'No definido', 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.pva.consideracionesInspector || 'No definido');
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Este Despacho resuelve:', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Tipo de decisión', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.tipoDecision || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.getCorrectiveMeasurements();

    this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Esta decisión queda notificada en estrados y se informa al destinatario que contra la misma procede recurso de reposición ante esta Inspección de Policía y de apelación ante:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 20;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Autoridad que resuelve el recurso', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.autoridadResuelve, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('recurso que debe sustentar en este momento', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text(`¿Interpone reposición? ${this.interponeReposicion === true ? 'Sí' : ''}${this.interponeReposicion === false ? 'No' : ''}${this.interponeReposicion === undefined ? 'No definido' : ''}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text(`¿Interpone reposición? ${this.interponeApelacion === true ? 'Sí' : ''}${this.interponeApelacion === false ? 'No' : ''}${this.interponeApelacion === undefined ? 'No definido' : ''}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text('Sustentación del recurso', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.sustentacionRecurso, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('Decisión sobre los recursos interpuestos', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.decisionSobreRecursos, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('La decisión sobre los recursos interpuestos queda notificada en estrados. Como quiera que se interpuso y se concedió apelación, se remite ante:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Autoridad que resuelve el recurso', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.autoridadResuelveSegundo || this.pva.autoridadResuelve || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text(`Audiencia de caracter: ${this.audiencia ? 'Presencial' : ''}${this.audiencia === false ? 'Virtual' : ''}${this.audiencia === undefined ? 'No definido' : ''}`, 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    await this.getRegisters();
    await this.getSignatures();

  }

  /**
   * Obtiene las medidas correctivas impuestas y las añade una a una en el PDF
   */
  getCorrectiveMeasurements(){
    this.setTitleStyle();
    this.doc.text('Medidas correctivas impuestas', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 20
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    for(let medida of this.pva.medidasCorrectivas){
      const index = this.pva.medidasCorrectivas.indexOf(medida);
      this.doc.text(`Medida correctiva ${index+1}`, 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Tipo de medida correctiva', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(medida.tipoMedidaCorrectiva || 'No definido', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Detalle de medida correctiva', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(medida.detalleMedidaCorrectiva || 'No definido', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
  }

  /**
   * Obtiene los registros de la audiencia, que se incluyen al final de la decisión, y los añade al PDF
   */
  async getRegisters(){
    this.setTitleStyle();
    this.doc.text('Registros', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    for(let archivo of this.pva.archivosAudiencia){
      if(archivo.activo && archivo.url){
        const index = this.pva.archivosAudiencia.indexOf(archivo);
        this.doc.text(`Registro número ${index+1}`, 15, this.finalY);
        this.finalY += 10;
        this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        const url = await QRCode.toDataURL(archivo.url);
        this.doc.addImage(url, 'JPEG', 15, this.finalY-5, 20, 20);
        this.finalY += 20;
      }
    }
  }

  /**
   * Obtiene las firmas de todos los participantes de la audiencia
   */
  async getSignatures(){
    this.setTitleStyle();
    this.doc.text('Nombre Inspector de Policía', 15, this.finalY, { maxWidth: 90 });
    this.doc.text('Firma Inspector de Policía', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;
    this.doc.text(`${this.inspectorNombre || 'No definido'}`, 15, this.finalY, { maxWidth: 90 });
    if(this.pva.firmaInspector){
      const url = await QRCode.toDataURL(this.pva.firmaInspector.url);
      this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      this.doc.text('No asignado', 105, this.finalY);
    }
    this.finalY += 15;

    this.setTitleStyle();
    this.doc.text('Nombre Agente Ministerio Público', 15, this.finalY, { maxWidth: 90 });
    this.doc.text('Firma Agente Ministerio Público', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;
    this.doc.text(`${this.pva.nombreAgente || 'No asignado'}`, 15, this.finalY, { maxWidth: 90 });
    if(this.pva.firmaAgente){
      console.log(this.pva.firmaAgente);
      const url = await QRCode.toDataURL(this.pva.firmaAgente.url);
      this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      this.doc.text('No asignado', 105, this.finalY);
    }
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    await this.getInvestigatedSignatures();
    await this.getPetitionersSignatures();

    this.doc.text('Nombre Auxiliar Administrativo', 15, this.finalY);
    this.doc.text('Firma Auxiliar Administrativo', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.pva.nombreAuxAdm || 'No definido', 15, this.finalY, { maxWidth: 90 });
    if(this.pva.firmaAuxAdm){
      const url = await QRCode.toDataURL(this.pva.firmaAuxAdm.url);
      this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      this.doc.text('No definido', 15, this.finalY);
    }
    this.finalY += 15;
  }

  /**
   * Obtiene las firmas de los investigados y las ingresa en el PDF
   */
  async getInvestigatedSignatures(){
    this.setTitleStyle();
    this.doc.text('Investigados', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    for(let investigado of this.pva.investigados){
      const index = this.pva.investigados.indexOf(investigado);
      this.setTitleStyle();
      this.doc.text(`Investigado ${index+1}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Nombre completo', 15, this.finalY);
      this.doc.text('Firma', 105, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(investigado.nombreCompleto || 'No definido', 15, this.finalY);
      if(investigado.firma){
        const url = await QRCode.toDataURL(investigado.firma.url);
        this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
        this.finalY += 5;
      } else {
        this.doc.text('No definido', 105, this.finalY);
      }
      this.finalY += 15;
    }
  }

  /**
   * Obtiene las firmas de los peticionarios y las ingresa en el PDF
   */
  async getPetitionersSignatures(){
    this.setTitleStyle();
    this.doc.text('Peticionarios', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    for(let peticionario of this.pva.peticionarios){
      const index = this.pva.peticionarios.indexOf(peticionario);
      this.setTitleStyle();
      this.doc.text(`Peticionario ${index+1}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Nombre completo', 15, this.finalY);
      this.doc.text('Firma', 105, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(peticionario.nombreCompleto || 'No definido', 15, this.finalY);
      if(peticionario.firma){
        const url = await QRCode.toDataURL(peticionario.firma.url);
        this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
        this.finalY += 5;
      } else {
        this.doc.text('No definido', 105, this.finalY);
      }
      this.finalY += 15;
    }
  }

  /**
   * Formatea una fecha para devolverla en un formato facilmente leíble
   * @param {Date | string} date La fecha
   * @param {string} separator Opcional. El separador que se usará
   * @returns La fecha formateada
   */
  formatDate(date: Date | string, separator?: string): string {
    return this.utilsService.formatDate(date, separator)
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  /**
   * Gets the height to add from certain numbers of characters, because with more characters, more lines, this works with half-width texts
   * @param {any} object The object that is being reviewed
   * @param {{ nombre: string, nombreArreglado: string }[]} row The current row (of the pdf)
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeight(object, row: { nombre: string, nombreArreglado: string }[]): number {
    const biggestChainLength = this.findLongestChainLength(row.map(r => object[r.nombre]));
    if(biggestChainLength > 140){
      return 18;
    } else if (biggestChainLength <= 140 && biggestChainLength > 100){
      return 15;
    } else if (biggestChainLength <= 100){
      return 12;
    }
    return 12;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    const length = string.length - 160;
    if(length/80 < 0){
      return 12;
    } else {
      return 12 + 3 * Math.ceil(length/80)
    }
  }

  /**
   * Get the longest string from an array of strings;
   * @param {string[]} strings El array
   * @returns {number} the string length
   */
  findLongestChainLength(strings: string[]): number {
    const longest = strings.reduce((longestString, currentString) => {
      return currentString && currentString.length > longestString.length ? currentString : longestString;
    });
    return longest ? longest.length : 0;
  }

  /**
   * Set del tipo de letra para el título principal
   */
  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set del tipo de letra para un título
   */
  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set del tipo de letra para el texto normal
   */
  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Guarda la información de la decisión
   */
  onSaveInfo(){
    let tipoAudiencia: string = null;
    if(this.audiencia){
      tipoAudiencia = 'presencial'
    } else if (!this.audiencia && this.audiencia !== undefined) {
      tipoAudiencia = 'virtual'
    }
    Object.assign(this.pva, {
      tipoAudiencia
    })
    console.log(this.pva);
    this.pvaService.saveInfo(this.pvaId, this.pva).catch(error => console.error(error));
  }
}
