<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="d-flex-start-center">
    <button class="go-back-button" routerLink="/biblioteca-inspecciones">
      <img src="/assets/img/flecha-izquierda.png" class="go-back-image" alt="boton atras" />
      Volver
    </button>
    <h1 class="principal-title mb-30">Foro: Preguntas y respuestas</h1>
  </div>
  <div>
    <div class="home-superior">
      <input type="search" class="input-search" name="" (keyup)="getRelatedForums($event.target.value)" placeholder="Ingresa tu búsqueda" />
    </div>
  </div>
  <section class="content">
    <div *ngFor="let foro of foros">
      <div class="card-foro">
        <figure>
          <img
          [src]="foro.imagen || 'https://blog.lemontech.com/wp-content/uploads/2021/01/frases-de-abogados.jpg'"
          alt="Imagen del foro"
          />
        </figure>
        <div class="card-info">
          <h2 class="foro-nombre">{{ foro.nombre }}</h2>
          <div class="foro-bottom">
            <h3 class="foro-fecha">{{ formatDate(foro.createdAt) }}</h3>
            <button class="foro-button" [routerLink]="'/foro-inspecciones/foro/' + foro._id">Participar</button>
          </div>
        </div>
      </div>
    </div>
  </section>



</main>
