<app-header-inspecciones  [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="prev-mec-container">
    <aside class="aside-prev-mec">
      <button class="go-back-button" (click)="goBackButton()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver</button>
      <h1 class="aside-title">Fase 3 <span class="info-text" (click)="goToMoreInfo()"><img class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> Conoce más <img class="info-image" src="/assets/img/inspecciones/flecha.png" alt="Fecha entrar"></span> <br>Proceso Verbal Abreviado</h1>
      <h2 class="aside-data">Datos generales</h2>
      <h3 class="aside-subtitle">Caso No.</h3>
      <p class="aside-p">Nº{{ caseNumber }}</p>
      <h3 class="aside-subtitle">Inspección de Policía No.</h3>
      <p class="aside-p">Nº{{ inspectionNumber }}</p>
      <h3 class="aside-subtitle">Presuntos infractores</h3>
      <p *ngFor="let infractor of presuntosInfractores" class="aside-p">
        {{ infractor.nombreInfractor }} {{ infractor.apellidoInfractor }}
      </p>
      <h3 class="aside-subtitle">Descripción del CCC</h3>
      <p *ngFor="let case of cases; index as i" class="aside-p">{{ (i+1) + ': ' + case.descripcionComportamiento }}</p>
      <!-- <label for="archivosPruebasGeneralesAportadas" class="file-container">
        <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-left">
        <p>Subir archivos</p>
        <input
          class="file-input"
          type="file"
          id="archivosPruebasGeneralesAportadas"
          name="archivosPruebasGeneralesAportadas"
          accept="video/*"
          multiple
          (change)="onUploadVideosInput($event)" >
      </label> -->
    </aside>
    <main class="main-prev-mec">
      <nav class="navigation">
        <ul class="navigation-list">
          <li class="navigation-item" [class]="selectedPhase === 1 ? 'selected navigation-item mr-10 mb-10': 'no-selected navigation-item mr-10 mb-10'" (click)="selectedPhase = 1">Citación</li>
          <div class="navigation-container">
            <h2 class="navigation-subtitle">Audiencia Pública</h2>
            <div class="items-container">
              <li class="navigation-item" [class]="selectedPhase === 2 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 2">Datos generales</li>
              <li class="navigation-item" [class]="selectedPhase === 3 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 3">Descargos, declaraciones y conciliación</li>
              <li class="navigation-item" [class]="selectedPhase === 4 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 4">Pruebas</li>
              <li class="navigation-item" [class]="selectedPhase === 5 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 5">Decisión</li>
            </div>
          </div>
          <li class="navigation-item" [class]="selectedPhase === 6 ? 'selected navigation-item mb-10': 'no-selected navigation-item mb-10'" (click)="selectedPhase = 6">Seguimiento</li>
        </ul>
      </nav>
      <app-citacion-pva
        *ngIf="selectedPhase === 1"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [pvaId]="pvaId"
        [correosEnviados]="correosEnviados"
        [selectedHearing]="selectedHearing"
        [isSelected]="isSelected"
        (onSelectHearing)="hearingSelected($event)"
      ></app-citacion-pva>
      <app-datos-generales-pva
        *ngIf="selectedPhase === 2"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [pvaId]="pvaId"
        [selectedHearing]="selectedHearing"
        (onSelectHearing)="hearingSelected($event)"
      ></app-datos-generales-pva>
      <app-descargos-pva
        *ngIf="selectedPhase === 3"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [pvaId]="pvaId"
        [selectedHearing]="selectedHearing"
        (onSelectHearing)="hearingSelected($event)"
      ></app-descargos-pva>
      <app-pruebas-pva
        *ngIf="selectedPhase === 4"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [pvaId]="pvaId"
        [selectedHearing]="selectedHearing"
        (onSelectHearing)="hearingSelected($event)"
      ></app-pruebas-pva>
      <app-decision-pva
        *ngIf="selectedPhase === 5"
        [pvaId]="pvaId"
      ></app-decision-pva>
      <app-seguimiento-pva
        *ngIf="selectedPhase === 6"
        [pvaId]="pvaId"
      ></app-seguimiento-pva>
    </main>
  </div>
</div>
