import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MecanismoIntermedioService } from '../mecanismo-intermedio.service';
import Swal from 'sweetalert2';
import { Caso, Seguimiento } from '../../../inspecciones.interface';
import { jsPDF } from 'jspdf';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.css']
})
export class SeguimientoComponent implements OnInit {
  todayDate = new Date().toISOString().split("T")[0]

  seguimientos: Seguimiento[] = [];

  seguimiento: Seguimiento = {};

  wantEditFollowUp: boolean = false;
  newFollowUp: boolean = false;
  oldFollowUp: boolean = false;

  followUpIndex: number;

  @Input() mecIntId: string;
  caseId: string;

  doc: jsPDF;
  finalY: number = 15;

  constructor(
    private mecIntService: MecanismoIntermedioService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.caseId = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.mecIntService.getAllInfo(this.mecIntId).then(response => {
      const { seguimientos } = response;
      console.log(response);
      this.seguimientos = seguimientos;
    })
  }

  startFollowUp(){
    this.newFollowUp = true;
  }

  changeAccomplishment(status: 'yes' | 'no' | 'partially'){
    if(
      (status === 'yes' && this.seguimiento.ordenYCompromisosCumplidos !== 'yes') ||
      (status === 'no' && this.seguimiento.ordenYCompromisosCumplidos !== 'no') ||
      (status === 'partially' && this.seguimiento.ordenYCompromisosCumplidos !== 'partially') ||
      (!status && this.seguimiento.ordenYCompromisosCumplidos === undefined)
    ){
      this.seguimiento.ordenYCompromisosCumplidos = status;
    } else {
      this.seguimiento.ordenYCompromisosCumplidos = undefined;
    }
  }

  cancelFollowUp(){
    this.wantEditFollowUp = false;
    this.followUpIndex = undefined;
    this.oldFollowUp = false;
    this.newFollowUp = false;
    this.resetForm();
  }

  editFollowUp(followUp: Seguimiento){
    this.wantEditFollowUp = true;
    this.oldFollowUp = true;
    this.seguimiento = followUp;
    this.seguimiento.fechaSeguimiento = this.seguimiento ? (<string>this.seguimiento.fechaSeguimiento).split("T")[0] : null;
  }

  deleteFollowUp(index: number){
    if(this.oldFollowUp){
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este seguimiento?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.seguimientos.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El seguimiento se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  addFollowUp(){
    if(
      this.seguimiento.fechaSeguimiento == undefined ||
      this.seguimiento.horaSeguimiento == undefined ||
      this.seguimiento.descripcionSeguimiento == undefined
    ){
      Swal.fire(
        'Error',
        'No se han completado todos los campos necesarios',
        'warning'
      );
    } else {
      Swal.fire(
        'Éxito',
        'Seguimiento agregado con éxito, no olvides guardar',
        'success'
      )
      this.seguimientos.push(this.seguimiento);
      this.resetForm();
      this.cancelFollowUp();
    }
  }

  updateFollowUp(){
    if(
      this.seguimiento.fechaSeguimiento == undefined ||
      this.seguimiento.horaSeguimiento == undefined ||
      this.seguimiento.descripcionSeguimiento == undefined
    ){
      Swal.fire(
        'Error',
        'No se han completado todos los campos necesarios',
        'warning'
      );
    } else {
      this.seguimientos[this.followUpIndex] = this.seguimiento;
      Swal.fire(
        'Éxito',
        'Seguimiento actualizado con éxito, no olvides guardar',
        'success'
      )
      this.resetForm();
      this.cancelFollowUp();
    }
  }

  resetForm(){
    this.seguimiento = {};
    this.seguimiento.ordenYCompromisosCumplidos = undefined;
  }

  saveInfo(){
    const data = {
      seguimientos: this.seguimientos
    }
    this.mecIntService.saveInfo(this.mecIntId, data).catch(err => {
      console.error(err);
    })
  }

  formatDate(date: Date | string): string {
    return this.utilsService.formatDate(date);
  }

  async downloadPDF(){
    this.doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    const caso: Caso = await this.mecIntService.getCaseInfo(this.caseId);
    this.setPrincipalTitle();
    this.doc.text(`Seguimientos mecanismo intermedio - caso ${caso.numCaso}`, 15, this.finalY);
    this.finalY += 20;
    for(let seguimiento of this.seguimientos){
      this.setTitleStyle();
      this.doc.text(`Seguimiento ${this.seguimientos.indexOf(seguimiento) + 1}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 18;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Fecha', 15, this.finalY);
      this.doc.text('Hora', 105, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.fechaSeguimiento ? this.formatDate(seguimiento.fechaSeguimiento) : 'No definido', 15, this.finalY);
      this.doc.text(seguimiento.horaSeguimiento || 'No definido', 105, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Descripción del seguimiento', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.descripcionSeguimiento || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.descripcionSeguimiento || 'No definido');
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      const cumplimientosTransformar = {
        yes: 'Totalmente',
        partially: 'Parcialmente',
        no: 'No se cumplió'
      }
      this.doc.text(`¿Se cumplió la orden de policía? ${seguimiento.ordenYCompromisosCumplidos ? cumplimientosTransformar[seguimiento.ordenYCompromisosCumplidos] : 'No definido'}`, 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.justificacionCumplimiento || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Observaciones adicionales', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.observaciones || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.observaciones || 'No definido');
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
    this.doc.save(`Seguimientos mecanismo intermedio - caso ${caso.numCaso}.pdf`);
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text 
   * @returns {number} the number that must increase the height from the top 
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    const length = string.length - 160;
    if(length/80 < 0){
      return 12;
    } else {
      return 12 + 3 * Math.ceil(length/80)
    }
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  goToFinalReport(){
    this.router.navigate(['gestion-casos-inspecciones/ver-caso/mecanismo-intermedio/informe-final/' + this.caseId]);
  }
}
