<div class="form-container" id="pdfData" #pdfData>
  <div class="form-header mb-20">
    <div class="left-side">
      <h1 class="form-title mb-0">Ficha general</h1>
      <div class="tooltip-container">
        <img class="more-info-img" src="/assets/img/inspecciones/conocer-mas.png" alt="Más información"><span class="tooltip-text">En esta ficha se anota la información general del caso, derivada de la petición, la querella u otros documentos. Aunque hay unos datos obligatorios señalados con un asterisco (*) los demás se pueden dejar en blanco si no se conocen y complementar posteriormente</span>
      </div>
    </div>
    <button class="download-button" (click)="descargarPDF()">
      <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar">
      <span>{{ downloadingPdf ? 'Loading...' : ''}}</span>
    </button>
  </div>
  <form class="general-file-form" name="fichaGeneralForm" #fichaGeneralForm="ngForm">
    <div class="form-row">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="createdAt">Fecha de creación del caso*</label>
        <input
          [disabled]="isEditing"
          class="inspections-input calendar-input"
          [ngClass]="{'noValidField': noValidFields.createdAt}"
          placeholder="dd/mm/aaaa"
          type="date"
          name="createdAt"
          id="createdAt"
          [value]="fichaGeneralData.createdAt"
          [(ngModel)]="fichaGeneralData.createdAt">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="documentoOrigen">Documento de origen de la información*</label>
          <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': noValidFields.documentoOrigen}" name="documentoOrigen" [disabled]="isEditing" [(ngModel)]="fichaGeneralData.documentoOrigen" [config]="config" [options]="documentosOrigen">
        </ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="archivoDocumentoOrigen">Archivos de origen de la información</label>
        <label for="archivoDocumentoOrigen" class="file-container mb-20">
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <p>Subir archivo</p>
          <input
            class="file-input"
            type="file"
            id="archivoDocumentoOrigen"
            name="archivoDocumentoOrigen"
            accept="image/*,application/*"
            multiple
            (change)="onFileChange($event, 'documento-origen')">
        </label>
      </div>
    </div>

    <div class="list mb-20">
      <div *ngIf="archivosDocumentoOrigen?.length > 0">
        <h4 class="font18 bold">Documentos de origen de la información</h4>
        <div class="p-relative-registros" *ngFor="let archivo of archivosDocumentoOrigen">
          <a [href]="archivo.url" target="_blank"><img class="uploaded-image" [src]="getImageFromTypeFile(archivo)" alt="Archivo origen"></a>
        </div>
      </div>
    </div>

    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="fechaDocumentoOrigen">Fecha del documento origen*</label>
        <input
          [disabled]="isEditing"
          class="inspections-input calendar-input"
          [ngClass]="{'noValidField': noValidFields.fechaDocumentoOrigen}"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaDocumentoOrigen"
          id="fechaDocumentoOrigen"
          [max]="todayDate"
          [(ngModel)]="fichaGeneralData.fechaDocumentoOrigen">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="radicado">Radicado</label>
        <input
          [disabled]="isEditing"
          class="inspections-input"
          placeholder="Nº"
          type="text"
          name="radicado"
          id="radicado"
          [(ngModel)]="fichaGeneralData.radicado">
      </div>
    </div>

    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="descripcionOtroDocumento">Descripción</label>
        <input
          class="inspections-input"
          placeholder="Ingrese la descripción"
          type="text"
          name="descripcionOtroDocumento"
          id="descripcionOtroDocumento"
          [(ngModel)]="descripcionOtroDocumento">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="otrosDocumentos">Subir documentos</label>
        <label for="otrosDocumentos" class="file-container file-container-45 font16 b-20 mb-0">
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <p>Subir archivo</p>
          <input
            class="file-input"
            type="file"
            id="otrosDocumentos"
            name="otrosDocumentos"
            accept="image/*,application/pdf"
            multiple
            (change)="onFileChange($event, 'otros-documentos')">
        </label>
      </div>
    </div>

    <div class="list mb-20">
      <div *ngIf="otrosDocumentos?.length > 0">
        <h4 class="font18 bold">Otros documentos</h4>
        <div class="list-row" *ngFor="let otroDocumento of otrosDocumentos">
          <div class="list-detail minh-45 min-100per">
            <span>{{ otroDocumento.descripcion | truncate:120 }} <a [href]="otroDocumento.url" target="_blank">Ver documento</a></span>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="departamento">Departamento*</label>
        <!-- <ngx-select-dropdown class="inspections-select" name="departamento" [(ngModel)]="fichaGeneralData.departamento" [config]="config" [options]="departamentos">
        </ngx-select-dropdown> -->
        <input
          [disabled]="true"
          class="inspections-input"
          type="text"
          name="departamento"
          id="departamento"
          [(ngModel)]="fichaGeneralData.departamento">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="municipio">Municipio/Ciudad*</label>
        <!-- <ngx-select-dropdown class="inspections-select" name="departamento" [(ngModel)]="fichaGeneralData.municipio" [config]="config" [options]="municipios">
        </ngx-select-dropdown> -->
        <input
          [disabled]="true"
          class="inspections-input"
          type="text"
          name="municipio"
          id="municipio"
          [(ngModel)]="fichaGeneralData.municipio">
      </div>
    </div>
    <div class="form-row d-flex-between mb-40" *ngIf="fichaGeneralData.caso">
      <div class="form-group half-width-group">
        <label class="inspections-label text-disabled" for="numInspeccion">Inspección de Policía No.*</label>
        <input
          [disabled]="true"
          class="inspections-input text-disabled"
          placeholder="Automático"
          type="text"
          name="numInspeccion"
          id="numInspeccion"
          [(ngModel)]="fichaGeneralData.caso.numInspeccion">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label text-disabled" for="numCaso">No. de caso*</label>
        <input
          [disabled]="true"
          class="inspections-input text-disabled"
          placeholder="Automático"
          type="text"
          name="numCaso"
          id="numCaso"
          [(ngModel)]="fichaGeneralData.caso.numCaso">
      </div>
    </div>
    <div class="form-row">
      <p class="inspections-label noAddedInfo" *ngIf="noValidFields.peticionarios" >Debe existir al menos un peticionario</p>
      <div class="form-group full-width-group">
        <label class="inspections-label" for="nombrePeticionario">Nombre completo del peticionario / quejoso / querellante / entidad*</label>
        <input
          class="inspections-input"
          [ngClass]="{'noValidField': noValidFields.nombrePeticionario}"
          placeholder="Escribe aquí"
          type="text"
          name="nombrePeticionario"
          id="nombrePeticionario"
          [(ngModel)]="peticionario.nombrePeticionario">
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tipoIdentificacionPeticionario">Tipo de identificación</label>
        <ngx-select-dropdown class="inspections-select" name="tipoIdentificacionPeticionario" [(ngModel)]="peticionario.tipoIdentificacionPeticionario" [config]="config" [options]="tiposIdentificacion">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="identificacionPeticionario">Número de identificación</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="number"
          name="identificacionPeticionario"
          field="id"
          inputNumber
          id="identificacionPeticionario"
          [(ngModel)]="peticionario.identificacionPeticionario">
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nivelEducativoPeticionario">Nivel educativo</label>
        <ngx-select-dropdown class="inspections-select" name="nivelEducativoPeticionario" [(ngModel)]="peticionario.nivelEducativoPeticionario" [config]="config" [options]="nivelesEducativos">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="ocupacionPeticionario">Ocupación</label>
        <ngx-select-dropdown name="ocupacionPeticionario" [(ngModel)]="peticionario.ocupacionPeticionario" class="inspections-select" [config]="config" [options]="ocupaciones"></ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tipoVulnerabilidadPeticionario">Tipo de población vulnerable</label>
        <ngx-select-dropdown class="inspections-select" name="tipoVulnerabilidadPeticionario" [(ngModel)]="peticionario.tipoVulnerabilidadPeticionario" [config]="config" [options]="tiposVulnerabilidad">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="edadPeticionario">Edad</label>
        <input
          #peticionarioEdadInput
          class="inspections-input"
          placeholder="Escribe aquí"
          type="number"
          field="age"
          inputNumber
          name="edadPeticionario"
          id="edadPeticionario"
          (ngModelChange)="onChangePeticionarioAge($event)"
          [(ngModel)]="peticionario.edadPeticionario">
      </div>
    </div>
    <div class="form-row d-flex-between-start">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="direccionPeticionario">Dirección</label>
        <input
          class="inspections-input"
          name="direccionPeticionario"
          id="direccionPeticionario"
          placeholder="Escribe aquí"
          type="text"
          [(ngModel)]="peticionario.direccionPeticionario">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="telefonoPeticionario">Teléfono</label>
        <input
          class="inspections-input"
          placeholder="300 123 4567"
          type="number"
          field="cellphone"
          inputNumber
          name="telefonoPeticionario"
          id="telefonoPeticionario"
          [(ngModel)]="peticionario.telefonoPeticionario">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="correoPeticionario">Correo electrónico*</label>
        <input
          class="inspections-input"
          [ngClass]="{'noValidField': noValidFields.correoPeticionario}"
          placeholder="peticionario@correo.com"
          type="email"
          name="correoPeticionario"
          #correoPeticionario="ngModel"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          id="correoPeticionario"
          [(ngModel)]="peticionario.correoPeticionario">
        <div *ngIf="correoPeticionario.invalid && (correoPeticionario.dirty || correoPeticionario.touched)" class="alert-field">
          <span *ngIf="correoPeticionario.errors?.pattern">El correo debe tener un formato adecuado</span>
        </div>
      </div>
    </div>
    <button *ngIf="!editingPetitioner" class="aggregate-button mb-20" (click)="onAggregatePetitioner(false)">+Agregar</button>
    <button *ngIf="editingPetitioner" class="aggregate-button mb-20" (click)="onAggregatePetitioner(true)">Modificar</button>

    <div class="list" *ngIf="peticionarios?.length > 0">
      <div class="list-row d-flex-between mb-10" *ngFor="let petitioner of peticionarios; index as i">
        <div class="list-detail min-100per">
          <span>{{ (petitioner.nombrePeticionario | truncate:150) || (petitioner.numIdentificacion | truncate:150) }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editPetitioner(petitioner)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
            <button class="selectable-button" (click)="deletePetitioner(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
          </div>
        </div>
      </div>
    </div>

    <h3 class="section-text mt-40">Presuntos infractores</h3>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nombreInfractor">Nombres</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="text"
          name="nombreInfractor"
          id="nombreInfractor"
          [(ngModel)]="infractor.nombreInfractor">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="apellidoInfractor">Apellidos</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="text"
          name="apellidoInfractor"
          id="apellidoInfractor"
          [(ngModel)]="infractor.apellidoInfractor">
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tipoIdentificacionInfractor">Tipo de identificación</label>
        <ngx-select-dropdown class="inspections-select" name="tipoIdentificacionInfractor" [(ngModel)]="infractor.tipoIdentificacionInfractor" [config]="config" [options]="tiposIdentificacion">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="identificacionInfractor">Número de identificación</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="number"
          name="identificacionInfractor"
          minlength="1"
          maxlength="12"
          id="identificacionInfractor"
          field="id"
          inputNumber
          [(ngModel)]="infractor.identificacionInfractor">
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nivelEducativoInfractor">Nivel educativo</label>
        <ngx-select-dropdown class="inspections-select" name="nivelEducativoInfractor" [(ngModel)]="infractor.nivelEducativoInfractor" [config]="config" [options]="nivelesEducativos">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="ocupacionInfractor">Ocupación</label>
        <ngx-select-dropdown name="ocupacionInfractor" [(ngModel)]="infractor.ocupacionInfractor" class="inspections-select" [config]="config" [options]="ocupaciones"></ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tipoVulnerabilidadInfractor">Tipo de población vulnerable</label>
        <ngx-select-dropdown class="inspections-select" name="tipoVulnerabilidadInfractor" [(ngModel)]="infractor.tipoVulnerabilidadInfractor" [config]="config" [options]="tiposVulnerabilidad">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="edadInfractor">Edad</label>
        <input
          #infractorEdadInput
          class="inspections-input"
          placeholder="Escribe aquí"
          type="number"
          name="edadInfractor"
          id="edadInfractor"
          (ngModelChange)="onChangeInfractorAge($event)"
          [(ngModel)]="infractor.edadInfractor">
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="direccionInfractor">Dirección residencia (notificación)</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="text"
          name="direccionInfractor"
          id="direccionInfractor"
          [(ngModel)]="infractor.direccionInfractor">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="telefonoInfractor">Teléfono (celular)</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="number"
          field="cellphone"
          inputNumber
          name="telefonoInfractor"
          id="telefonoInfractor"
          [(ngModel)]="infractor.telefonoInfractor">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="correoInfractor">Correo electrónico</label>
        <input
          class="inspections-input"
          placeholder="infractor@correo.com"
          type="email"
          name="correoInfractor"
          #correoInfractor="ngModel"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          id="correoInfractor"
          [(ngModel)]="infractor.correoInfractor">
        <div *ngIf="correoInfractor.invalid && (correoInfractor.dirty || correoInfractor.touched)" class="alert-field">
          <span *ngIf="correoInfractor.errors?.pattern">El correo debe tener un formato adecuado</span>
        </div>
      </div>
    </div>
    <button *ngIf="!editingInfractor" class="aggregate-button mb-20" (click)="onAggregateViolator(false)">+Agregar</button>
    <button *ngIf="editingInfractor" class="aggregate-button mb-20" (click)="onAggregateViolator(true)">Modificar</button>

    <div class="list" *ngIf="infractores?.length > 0">
      <div class="list-row d-flex-between mb-10" *ngFor="let infractor of infractores; index as i">
        <div class="list-detail min-100per">
          <span>{{ infractor.nombreInfractor && infractor.apellidoInfractor ? (infractor.nombreInfractor + ' ' + infractor.apellidoInfractor | truncate:150) : (infractor.nombrePeticionario | truncate:150) || (infractor.nombrePeticionario | truncate:150) || (infractor.numIdentificacion | truncate:150) }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editInfractor(infractor)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
            <button class="selectable-button" (click)="deleteInfractor(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mb-10">
      <label class="inspections-label mt-40" for="resumenHechos">Resumen de los hechos*</label>
      <textarea class="textarea" [ngClass]="{'noValidField': noValidFields.resumenHechos}" name="resumenHechos" id="resumenHechos" placeholder="Escribe aquí el detalle de lo que sucedió" [(ngModel)]="fichaGeneralData.resumenHechos" cols="20" rows="10"></textarea>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="fechaHechos">Fecha*</label>
        <input
          class="inspections-input calendar-input"
          [ngClass]="{'noValidField': noValidFields.fechaHechos}"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaHechos"
          id="fechaHechos"
          [max]="todayDate"
          [(ngModel)]="fichaGeneralData.fechaHechos">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="horaHechos">Hora</label>
        <input
          class="inspections-input time-input"
          placeholder="00:00"
          type="time"
          name="horaHechos"
          id="horaHechos"
          [(ngModel)]="fichaGeneralData.horaHechos">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="nombreLugarHechos">Localidad / comuna / vereda / barrio de los hechos*</label>
        <input
          class="inspections-input"
          [ngClass]="{'noValidField': noValidFields.nombreLugarHechos}"
          placeholder="Escribe aquí"
          type="text"
          name="nombreLugarHechos"
          id="nombreLugarHechos"
          [(ngModel)]="fichaGeneralData.nombreLugarHechos">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <p class="inspections-label noAddedInfo" *ngIf="noValidFields.localidadHechos" >Debe seleccionar una localidad</p>
        <label class="inspections-label mb-20" for="tipoLocalidadHechos">Tipo de localidad de los hechos*</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="tipoLocalidadHechosUrbana" [disabled]="isEditing" [checked]="tipoLocalidadHechosUrbana === true" [(ngModel)]="tipoLocalidadHechosUrbana" (change)="changeLocationType('urbana')" type="checkbox">
            <label class="inspections-label" for="tipoLocalidadHechosUrbana">Urbana</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="tipoLocalidadHechosRural" [disabled]="isEditing" [checked]="tipoLocalidadHechosRural === true" [(ngModel)]="tipoLocalidadHechosRural" (change)="changeLocationType('rural')" type="checkbox">
            <label class="inspections-label" for="tipoLocalidadHechosRural">Rural</label>
          </div>
        </section>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="lugarHechos">Lugar de los hechos*</label>
        <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': noValidFields.lugarHechos}" name="lugarHechos" [(ngModel)]="fichaGeneralData.lugarHechos" [config]="config" [options]="lugaresHechos">
        </ngx-select-dropdown>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="direccionHechos">Dirección de los hechos*</label>
        <input
          class="inspections-input"
          [ngClass]="{'noValidField': noValidFields.direccionHechos}"
          placeholder="Escribe aquí la dirección de los hechos"
          type="text"
          name="direccionHechos"
          id="direccionHechos"
          [(ngModel)]="fichaGeneralData.direccionHechos">
      </div>
    </div>

    <div class="form-row d-flex-between" *ngIf="fichaGeneralData.lugarHechos === 'Establecimiento de comercio'">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nombreEstablecimientoHechos">Nombre o razón social</label>
        <input
          class="inspections-input calendar-input"
          placeholder="Escribe aquí"
          type="text"
          name="nombreEstablecimientoHechos"
          id="nombreEstablecimientoHechos"
          [(ngModel)]="fichaGeneralData.nombreEstablecimientoHechos">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nitEstablecimientoHechos">NIT</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="text"
          name="nitEstablecimientoHechos"
          id="nitEstablecimientoHechos"
          [(ngModel)]="fichaGeneralData.nitEstablecimientoHechos">
      </div>
    </div>
    <div class="form-row" *ngIf="fichaGeneralData.lugarHechos === 'Establecimiento de comercio'">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="actividadEconomicaEstablecimientoHechos">Actividad económica</label>
        <input
          class="inspections-input"
          placeholder="Escribe aquí"
          type="text"
          name="actividadEconomicaEstablecimientoHechos"
          id="actividadEconomicaEstablecimientoHechos"
          [(ngModel)]="fichaGeneralData.actividadEconomicaEstablecimientoHechos">
      </div>
    </div>
    <div class="form-row mb-40">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="detalleLugarHechos">Detalle del lugar de los hechos*</label>
        <textarea class="textarea" [ngClass]="{'noValidField': noValidFields.detalleLugarHechos}" name="detalleLugarHechos" id="detalleLugarHechos" placeholder="Escribe aquí cómo es el lugar donde se presentaron los hechos. La dirección también se puede escribir aquí" [(ngModel)]="fichaGeneralData.detalleLugarHechos" cols="30"></textarea>
      </div>
    </div>
    <p class="inspections-label noAddedInfo" *ngIf="noValidFields.cccsForm" >Debe existir al menos un Comportamiento contrario a la convivencia</p>
    <h3 class="section-text bold">Comportamiento contrario a la convivencia (según CNSCC)*</h3>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tituloCCC">Título*</label>
        <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': cccNoValidFields.tituloCCC}" name="tituloCCC" [(ngModel)]="ccc.tituloCCC" [config]="config" [options]="titulosDisponiblesCCC" (change)="selectedTitle()">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="capituloCCC">Capítulo*</label>
        <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': cccNoValidFields.capituloCCC}" name="capituloCCC" [(ngModel)]="ccc.capituloCCC" [config]="config" [options]="capitulosDisponiblesCCC" (change)="selectedChapter()">
        </ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="articuloCCC">Artículo*</label>
        <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': cccNoValidFields.articuloCCC}" name="articuloCCC" [(ngModel)]="ccc.articuloCCC" [config]="config" [options]="articulosDisponiblesCCC" (change)="selectedArticle()">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="numeralCCC">Numeral*</label>
        <ngx-select-dropdown class="inspections-select" [ngClass]="{'noValidField': cccNoValidFields.numeralCCC}" name="numeralCCC" [(ngModel)]="ccc.numeralCCC" [config]="config" [options]="numeralesDisponiblesCCC" (change)="selectedNumeral()">
        </ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="literalCCC">Literal</label>
        <ngx-select-dropdown class="inspections-select" name="literalCCC" [(ngModel)]="ccc.literalCCC" [config]="config" [options]="literalesDisponiblesCCC" (change)="selectedSubsection()">
        </ngx-select-dropdown>
      </div>
    </div>
    <section *ngIf="isCaseFound">
      <h4 class="ccc-title">Tipo de comportamiento</h4>
      <p class="ccc-p">{{ tipoComportamientoEncontrado }}</p>
      <h4 class="ccc-title">Descripción del comportamiento</h4>
      <p class="ccc-p">{{ descripcionComportamientoEncontrado }}</p>
      <h4 class="ccc-title">Medida correctiva</h4>
      <p class="ccc-p">{{ medidaCorrectivaEncontrada }}</p>
    </section>
    <button class="aggregate-button mb-20" (click)="onAggregateCCC()">+Agregar</button>
    <div *ngFor="let caso of cccsSelectedInfo; index as i" class="mb-10">
      <h3 class="font18 bold mt-0">Comportamiento contrario a la convivencia #{{i+1}}</h3>
      <div class="form-row d-flex-between align-start mb-0">
        <div class="form-group half-width-group">
          <h4 class="font18 mt-0">Título</h4>
          <p class="font16">{{ caso.titulo }}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="font18 mt-0">Capítulo</h4>
          <p class="font16">{{ caso.capitulo }}</p>
        </div>
      </div>
      <div class="form-row d-flex-between ">
        <div class="form-group half-width-group">
          <h4 class="font18 mt-0">Artículo</h4>
          <p class="font16">{{ caso.articulo }}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="font18 mt-0">Numeral</h4>
          <p class="font16">{{ caso.numeral || 'No asignado' }}</p>
        </div>
      </div>
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="font18 mt-0 mb-0">Literal</h4>
          <p class="font16">{{ caso.literal || 'No asignado' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group full-width-group">
          <h4 class="font18 mt-0">Descripción del comportamiento</h4>
          <p class="font16">{{ caso.descripcionComportamiento }}</p>
        </div>
      </div>
    </div>
    <div class="mb-20">
      <div class="form-group full-width-group d-flex-start">
        <label for="esQuerellaCivil" class="font18 bold mt-0">Este asunto corresponde a una querella civil</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" id="esQuerellaCivil" name="esQuerellaCivil" [checked]="fichaGeneralData.esQuerellaCivil === true" (change)="changeCivilComplaint(true)" type="checkbox">
            <label class="inspections-label" for="esQuerellaCivil">Sí</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="tipoLocalidadHechosRural" [checked]="fichaGeneralData.esQuerellaCivil === false" (change)="changeCivilComplaint(false)" type="checkbox">
            <label class="inspections-label" for="tipoLocalidadHechosRural">No</label>
          </div>
        </section>
      </div>
    </div>
  </form>
  <button class="submit-button" *ngIf="!isEditing" (click)="createCase(fichaGeneralForm)">Crear caso</button>
  <button class="submit-button save-button" *ngIf="isEditing" (click)="saveGeneralFile(fichaGeneralForm)">Guardar cambios</button>
</div>
