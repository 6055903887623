<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title">Pruebas</h2>
    </div>
    <div class="d-flex-start">
      <button *ngIf="oldHearing || newHearing" class="cancel-button mr-10" (click)="cancelHearing()">Salir de audiencia</button>
      <button class="download-button">
        <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
      </button>
    </div>
  </div>

  <details>
    <summary class="font18 bold">Pruebas aportadas por fuera de la audiencia</summary>
    <div class="list">
      <div *ngIf="pruebasGeneralesAportadas.length > 0; else noPruebasAportadas">
        <div class="list-row" *ngFor="let pruebaAportada of pruebasGeneralesAportadas; index as i">
          <div class="list-detail minh-45" [class.pruebaAceptada]="pruebaAportada.aceptada" [class.pruebaRechazada]="pruebaAportada.aceptada === false">
            <span>{{(pruebaAportada.tipoPrueba ? pruebaAportada.tipoPrueba + ' / ' : '') + pruebaAportada.nombrePersona + ' / ' + formatDate(pruebaAportada.fechaAporte) + ': ' + pruebaAportada.nombre }} <a [href]="pruebaAportada.url" target="_blank">Ver prueba</a></span>
          </div>
          <div class="form-row" *ngIf="pruebaAportada.aceptada === false">
            <div class="form-group full-width-group mb-20">
              <label class="inspections-label" for="motivoRechazoPruebaAportadaGeneral">Motivo de rechazo de la prueba aportada por <em>{{ pruebaAportada.nombrePersona }}</em></label>
              <p class="font16">{{ pruebaAportada.motivoRechazo }}</p>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noPruebasAportadas>
        <div class="width-70">
          <p class="text">
            No existen pruebas aportadas por fuera de la audiencia.
          </p>
        </div>
      </ng-template>
    </div>
  
    <section class="form-row bottom-line">
      <h3 class="font18 bold mb-10">Añadir prueba aportada por fuera de la audiencia</h3>
      <div class="form-row">
        <div class="form-group half-width-group">
          <label class="inspections-label" for="tipoPruebaPracticadaPorFuera">Tipo de prueba aportada</label>
          <ngx-select-dropdown
            class="inspections-select min-150"
            name="tipoPruebaPracticadaPorFuera"
            [options]="listaPruebas"
            [(ngModel)]="pruebaGeneralAportada.tipoPrueba"
            [config]="config"
          ></ngx-select-dropdown>
        </div>
      </div>
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <label class="inspections-label" for="nombreGeneralPersona">Persona que aportó la prueba</label>
          <ngx-select-dropdown class="inspections-select" name="nombreGeneralPersona" [(ngModel)]="pruebaGeneralAportada.nombrePersona" [config]="personasConfig" [options]="personas">
          </ngx-select-dropdown>
        </div>
        <div class="form-group half-width-group">
          <label class="inspections-label" for="fechaPruebaGeneral">Fecha del aporte</label>
          <input
            class="inspections-input calendar-input"
            placeholder="dd/mm/aaaa"
            type="date"
            name="fechaPruebaGeneral"
            id="fechaPruebaGeneral"
            [(ngModel)]="pruebaGeneralAportada.fechaAporte"
          >
        </div>
      </div>
      <h4 class="font16 bold">Archivos aportados</h4>
      <h6>*El aporte será guardado con el mismo nombre del archivo, le recomendamos cambiar el nombre al archivo para reconocerlo más facilmente.</h6>
      <label for="archivosPruebasGeneralesAportadas" class="file-container">
        <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-left">
        <p>Subir archivos</p>
        <input
          class="file-input"
          type="file"
          id="archivosPruebasGeneralesAportadas"
          name="archivosPruebasGeneralesAportadas"
          accept="image/*, video/*, .pdf, .doc, .docx, .xlsx"
          multiple
          (change)="onUploadGeneralProvidedEvidence($event)" >
      </label>
      <div class="list">
        <div *ngIf="nuevasPruebasGeneralesAportadas.length > 0">
          <h4 class="font18 bold">Agregar las siguientes pruebas</h4>
          <p class="font16">A continuación verás las pruebas que se quieren agregar y que fueron realizadas por fuera de la audiencia, por favor indica si estas son aceptadas o rechazadas.</p>
          <div class="list-row" *ngFor="let pruebaAportada of nuevasPruebasGeneralesAportadas; index as i">
            <div class="list-detail minh-45" [class.pruebaAceptada]="pruebaAportada.aceptada" [class.pruebaRechazada]="pruebaAportada.aceptada === false">
              <span>{{ pruebaAportada.tipoPrueba + ' / ' + pruebaAportada.nombrePersona + ' / ' + formatDate(pruebaAportada.fechaAporte) + ': ' + pruebaAportada.nombre }} <a [href]="pruebaAportada.url" target="_blank">Ver prueba</a></span>
              <div class="list-buttons right-double">
                <button class="selectable-button" (click)="decideEvidence(i, true, 'nueva-aportada-general')"><img class="accept-icon" src="assets/img/inspecciones/correcto.png" alt=""></button>
                <button class="selectable-button" (click)="decideEvidence(i, false, 'nueva-aportada-general')"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
              </div>
            </div>
            <div class="form-row" *ngIf="pruebaAportada.aceptada === false">
              <div class="form-group full-width-group mb-20">
                <label class="inspections-label" for="motivoRechazoPruebaAportadaGeneral">Motivo de rechazo de la prueba aportada por {{ pruebaAportada.nombrePersona }}</label>
                <input
                  placeholder="Escribe aquí las razones del rechazo"
                  class="inspections-input width-70"
                  name="motivoRechazoPruebaAportadaGeneral"
                  [(ngModel)]="pruebaAportada.motivoRechazo"
                  cols="5"
                  rows="5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="aggregate-button mb-20" (click)="addGeneralProvidedEvidence()" *ngIf="nuevasPruebasGeneralesAportadas.length > 0">+Agregar pruebas aportadas por fuera de la audiencia</button>
    </section>
  </details>

  <!-- PRUEBAS PRÁCTICADAS POR LA INSPECCIÓN -->
  <details>
    <summary class="font18 bold">Pruebas prácticadas por la inspección de policía</summary>
    <section class="form-row bottom-line">
      <div class="list">
        <div *ngIf="pruebasPracticadas.length > 0; else noPruebasPracticadas">
          <div class="list-row" *ngFor="let pruebaPracticada of pruebasPracticadas; index as i">
            <div class="list-detail minh-45">
              <span>{{ pruebaPracticada.nombre + ' / ' + formatDate(pruebaPracticada.fechaPractica) + ' ' + pruebaPracticada.hora + ': ' + pruebaPracticada.detalle }} <a [href]="pruebaPracticada.url" target="_blank">Ver prueba</a></span>
            </div>
          </div>
        </div>
        <ng-template #noPruebasPracticadas>
          <div class="width-70">
            <p class="text">
              No existen pruebas practicadas por la inspección.
            </p>
          </div>
        </ng-template>
      </div>
  
      <!-- añadir una prueba practicada -->
      <div>
        <h3 class="font18 bold mb-10">Añadir prueba practicada por la inspección de policía</h3>
        <div class="form-row">
          <div class="form-group half-width-group">
            <label class="inspections-label" for="tipoPruebaPracticada">Tipo de prueba practicada {{ pruebaPracticada.nombre }}</label>
            <ngx-select-dropdown
              class="inspections-select min-150"
              name="tipoPruebaPracticada"
              [options]="listaPruebas"
              [(ngModel)]="pruebaPracticada.nombre"
              [config]="config"
            ></ngx-select-dropdown>
          </div>
        </div>
        <div class="form-row d-flex-between">
          <div class="form group half-width-group">
            <label class="inspections-label" for="fechaPruebaPracticada">Fecha de la práctica</label>
            <input
              class="inspections-input calendar-input"
              placeholder="dd/mm/aaaa"
              type="date"
              name="fechaPruebaPracticada"
              id="fechaPruebaPracticada"
              [(ngModel)]="pruebaPracticada.fechaPractica"
            >
          </div>
          <div class="form group half-width-group">
            <label class="inspections-label" for="horaPruebaPracticada">Hora de la práctica</label>
            <input
              class="inspections-input time-input"
              placeholder="00:00"
              type="time"
              name="horaPruebaPracticada"
              id="horaPruebaPracticada"
              [(ngModel)]="pruebaPracticada.hora"
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <label class="inspections-label" for="detallePruebaPracticada">Detalle</label>
            <textarea
              class="textarea"
              name="detallePruebaPracticada"
              [(ngModel)]="pruebaPracticada.detalle"
              cols="5"
              rows="5"
            ></textarea>
          </div>
        </div>
        <h4 class="font16 bold">Archivos aportados</h4>
        <h6>*El aporte será guardado con el mismo nombre del archivo, le recomendamos cambiar el nombre al archivo para reconocerlo más facilmente.</h6>
        <label for="archivosPruebasPracticadas" class="file-container">
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-left">
          <p>Subir archivos</p>
          <input
            class="file-input"
            type="file"
            id="archivosPruebasPracticadas"
            name="archivosPruebasPracticadas"
            accept="image/*, video/*, .pdf, .doc, .docx, .xlsx"
            multiple
            (change)="onUploadPracticedEvidence($event)" >
        </label>
        <div class="list">
          <div *ngIf="nuevasPruebasPracticadas.length > 0; else noNuevasPruebasPracticadas">
            <h4 class="font18 bold">Agregar las siguientes pruebas</h4>
            <p class="font16">A continuación verás las pruebas prácticadas que se quieren agregar y que fueron realizadas por la inspección.</p>
            <div class="list-row" *ngFor="let pruebaPracticada of nuevasPruebasPracticadas; index as i">
              <div class="list-detail minh-45">
                <span>{{ pruebaPracticada.nombre + ' / ' + formatDate(pruebaPracticada.fechaPractica) + ': ' + pruebaPracticada.detalle }} <a [href]="pruebaPracticada.url" target="_blank">Ver prueba</a></span>
              </div>
            </div>
          </div>
          <ng-template #noNuevasPruebasPracticadas>
            No tienes pruebas practicadas
          </ng-template>
        </div>
        <button type="button" class="aggregate-button mb-20" (click)="addPracticedEvidence()" *ngIf="nuevasPruebasPracticadas.length > 0">+Agregar pruebas practicadas por la inspección</button>
      </div>
    </section>
  </details>

  <!-- LISTA DE AUDIENCIAS -->
  <h3 class="font18 bold">Para incorporar pruebas en esta audiencia, seleccione la audiencia respectiva</h3>
  <div *ngIf="audienciasDisponibles?.length > 0; else noAudiencias" class="form-group d-flex-l">
    <button class="selectable-button" *ngFor="let audiencia of audienciasDisponibles; index as i" (click)="editHearing(audiencia)">
      <div *ngIf="audiencia.activo !== false">
        {{ 'Audiencia ' + (i+1) + ' / ' + formatDate(audiencia?.fechaAudiencia) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar">
      </div>
    </button>
  </div>
  <ng-template #noAudiencias>
    <div class="width-70">
      <p class="text">
        No tienes ninguna citación creada, crea una para acceder a todas las funcionalidades del proceso verbal abreviado.
      </p>
    </div>
  </ng-template>

  <form *ngIf="oldHearing || newHearing" class="phase-form">

    <details>
      <summary class="font18 bold">Pruebas aportadas en la audiencia</summary>
      <section class="bottom-line">
        <div class="list">
          <div *ngIf="audiencia.pruebasAportadas.length > 0; else noPruebasAportadas">
            <div class="list-row" *ngFor="let pruebaAportada of audiencia.pruebasAportadas; index as i">
              <div class="list-detail min-100p minh-45" [class.pruebaAceptada]="pruebaAportada.aceptada" [class.pruebaRechazada]="pruebaAportada.aceptada === false">
                <span>{{(pruebaAportada.tipoPrueba ? pruebaAportada.tipoPrueba + ' / ' : '') + pruebaAportada.nombrePersona + ' / ' + formatDate(pruebaAportada.fechaAporte) + ': ' + pruebaAportada.nombre }} <a [href]="pruebaAportada.url" target="_blank">Ver prueba</a></span>
              </div>
              <div class="form-row" *ngIf="pruebaAportada.aceptada === false">
                <div class="form-group full-width-group mb-20">
                  <h5 class="font16 bold" for="motivoRechazoPruebaAportadaGeneral">Motivo de rechazo de la prueba aportada por <em>{{ pruebaAportada.nombrePersona }}</em></h5>
                  <p class="font16 mb-0">{{ pruebaAportada.motivoRechazo }}</p>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noPruebasAportadas>
            <div class="width-70">
              <p class="text">
                No existen pruebas aportadas.
              </p>
            </div>
          </ng-template>
        </div>
  
        <div class="form-row">
          <h3 class="font18 bold">Añadir prueba aportada en la audiencia</h3>
          <div class="form-row">
            <div class="form-group half-width-group">
              <label class="inspections-label" for="tipoPruebaAportada">Tipo de prueba aportada</label>
              <ngx-select-dropdown
                class="inspections-select min-150"
                name="tipoPruebaAportada"
                [options]="listaPruebas"
                [(ngModel)]="pruebaAportada.tipoPrueba"
                [config]="config"
              ></ngx-select-dropdown>
            </div>
          </div>
          <div class="form-row d-flex-between">
            <div class="form-group half-width-group">
              <label class="inspections-label" for="nombrePersona">Persona que aportó la prueba</label>
              <ngx-select-dropdown
                class="inspections-select"
                name="nombrePersona"
                [(ngModel)]="pruebaAportada.nombrePersona"
                [config]="personasConfig"
                [options]="personas"
              >
              </ngx-select-dropdown>
            </div>
            <div class="form-group half-width-group">
              <label class="inspections-label" for="fechaAporte">Fecha del aporte</label>
              <input
                class="inspections-input calendar-input"
                placeholder="dd/mm/aaaa"
                type="date"
                name="fechaAporte"
                id="fechaAporte"
                [(ngModel)]="pruebaAportada.fechaAporte"
              >
            </div>
          </div>
          <h4 class="font16 bold">Archivos aportados</h4>
          <h6>*El aporte será guardado con el mismo nombre del archivo, le recomendamos cambiar el nombre al archivo para reconocerlo más facilmente.</h6>
          <label for="archivosPruebasAportadas" class="file-container">
            <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-left">
            <p>Subir archivos</p>
            <input
              class="file-input"
              type="file"
              id="archivosPruebasAportadas"
              name="archivosPruebasAportadas"
              accept="image/*, video/*, .pdf, .doc, .docx, .xlsx"
              multiple
              (change)="onUpdateProvidedEvidence($event)" >
          </label>
        </div>
        <div class="list">
          <div *ngIf="nuevasPruebasAportadas.length > 0; else noPruebasNuevasAportadas">
            <h4 class="font18 bold">Agregar las siguientes pruebas</h4>
            <p class="font16">A continuación verás las pruebas que se quieren agregar y que fueron aportadas en desarrollo de la audiencia, por favor indica si estas son aceptadas o rechazadas.</p>
            <div class="list-row min-100p minh-45" *ngFor="let pruebaAportada of nuevasPruebasAportadas; index as i">
              <div class="list-detail min-100p minh-45" [class.pruebaAceptada]="pruebaAportada.aceptada" [class.pruebaRechazada]="pruebaAportada.aceptada === false">
                <span>{{(pruebaAportada.tipoPrueba ? pruebaAportada.tipoPrueba + ' / ' : '') + pruebaAportada.nombrePersona + ' / ' + formatDate(pruebaAportada.fechaAporte) + ': ' + pruebaAportada.nombre }} <a [href]="pruebaAportada.url" target="_blank">Ver prueba</a></span>
                <div class="list-buttons right-double">
                  <button class="selectable-button" (click)="decideEvidence(i, true, 'nueva-aportada')"><img class="accept-icon" src="assets/img/inspecciones/correcto.png" alt="icon accept"></button>
                  <button class="selectable-button" (click)="decideEvidence(i, false, 'nueva-aportada')"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
                </div>
              </div>
              <div class="form-row" *ngIf="pruebaAportada.aceptada === false">
                <div class="form-group full-width-group mb-20">
                  <label class="inspections-label" for="motivoRechazoPruebaAportadaGeneral">Motivo de rechazo de la prueba aportada en la audiencia por <em>{{ pruebaAportada.nombrePersona }}</em></label>
                  <input
                    placeholder="Escribe aquí las razones del rechazo"
                    class="inspections-input width-70"
                    name="motivoRechazoPruebaAportadaGeneral"
                    [(ngModel)]="pruebaAportada.motivoRechazo"
                    cols="10"
                    rows="10"
                  />
                </div>
              </div>
            </div>
            <ng-template #noPruebasNuevasAportadas>
              <div class="width-70">
                <p class="text">
                  No existen pruebas aportadas.
                </p>
              </div>
            </ng-template>
          </div>
        </div>
  
        <button type="button" class="aggregate-button mb-20" (click)="addProvidedEvidence()" *ngIf="nuevasPruebasAportadas.length > 0">+Agregar prueba aportada</button>
      </section>
    </details>

    <details>
      <summary class="font18 bold">Pruebas solicitadas</summary>
      <section class="bottom-line">
        <div class="list">
          <div *ngIf="audiencia.pruebasSolicitadas.length > 0; else noPruebasSolicitadas">
            <div class="list-row" *ngFor="let pruebaSolicitada of audiencia.pruebasSolicitadas; index as i">
              <div class="list-detail min-100p minh-45" [class.pruebaAceptada]="pruebaSolicitada.aceptada" [class.pruebaRechazada]="pruebaSolicitada.aceptada === false">
                <span>{{ pruebaSolicitada.nombrePersona + ' (' + pruebaSolicitada.nombre + ') ' + ' / ' + formatDate(pruebaSolicitada.fechaSolicitud) + ': ' + pruebaSolicitada.detalle }}</span>
              </div>
              <div class="form-row" *ngIf="pruebaSolicitada.aceptada === false">
                <div class="form-group full-width-group mb-20">
                  <h5 class="font16 bold" for="motivoRechazopruebaSolicitadaGeneral">Motivo de rechazo de la prueba aportada por <em>{{ pruebaSolicitada.nombrePersona }}</em></h5>
                  <p class="font16 mb-0">{{ pruebaSolicitada.motivoRechazo }}</p>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noPruebasSolicitadas>
            <div class="width-70">
              <p class="text">
                No existen pruebas solicitadas.
              </p>
            </div>
          </ng-template>
        </div>
        <h3 class="font18 bold">Añadir prueba solicitada</h3>
        <div class="form-row d-flex-between ">
          <div class="form-group half-width-group">
            <label class="inspections-label" for="tipoPruebaSolicitada">Tipo de prueba</label>
            <ngx-select-dropdown
              class="inspections-select min-150"
              name="tipoPruebaSolicitada"
              [options]="listaPruebas"
              [(ngModel)]="pruebaSolicitada.nombre"
              [config]="config"
              [disabled]="pruebaSolicitada.otroTipo">
            </ngx-select-dropdown>
          </div>
          <div class="checkbox-cont">
            <input type="checkbox" class="checkbox" name="otroTipoPrueba" id="otroTipoPrueba" [(ngModel)]="pruebaSolicitada.otroTipo" (change)="changeSolicitedEvidenceType()"><label for="otroTipoPrueba" class="width-50">¿Otra cuál?</label>
          </div>
          <div class="form-group half-width-group">
            <label class="inspections-label" for="otroTipoPruebaSolicitada">Otro tipo</label>
            <input
              class="inspections-input"
              placeholder="Cúal"
              type="text"
              name="otroTipoPruebaSolicitada"
              id="otroTipoPruebaSolicitada"
              [(ngModel)]="pruebaSolicitadaOtroTipo"
              [disabled]="!pruebaSolicitada.otroTipo && pruebaSolicitada.otroTipo !== undefined"
            >
          </div>
        </div>
  
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label class="inspections-label" for="nombreGeneralInfractor">Hace la solicitud:</label>
            <ngx-select-dropdown class="inspections-select mb-20" name="nombreGeneralInfractor" [(ngModel)]="pruebaSolicitada.nombrePersona" [config]="personasConfig" [options]="personas">
            </ngx-select-dropdown>
          </div>
          <div class="form-group half-width-group">
            <label class="inspections-label" for="fechaPruebaSolicitada">Fecha de la solicitud</label>
            <input
              class="inspections-input calendar-input"
              placeholder="dd/mm/aaaa"
              type="date"
              name="fechaPruebaSolicitada"
              id="fechaPruebaSolicitada"
              [(ngModel)]="pruebaSolicitada.fechaSolicitud"
            >
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group full-width-group " >
            <label class="inspections-label mb-0" for="detallePruebaSolicitada">Detalle de la prueba solicitada y su objeto</label>
            <textarea class="textarea" name="detallePruebaSolicitada" id="detallePruebaSolicitada" [(ngModel)]="pruebaSolicitada.detalle" placeholder="Escribe aquí…" cols="5" rows="5"></textarea>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group full-width-group d-flex-start mb-10">
            <label class="inspections-label bold" for="aceptaSolicitud">¿Se acepta la solicitud?</label>
            <section class="d-flex-between">
              <div class="d-flex-start checkbox-container">
                <input class="checkbox" name="si"  type="checkbox" [checked]="pruebaSolicitada.aceptada" (change)="decideRequestedEvidence(true)">
                <label class="inspections-label" for="si">Si</label>
              </div>
              <div class="d-flex-start checkbox-container">
                <input class="checkbox" name="no"  type="checkbox" [checked]="!pruebaSolicitada.aceptada && pruebaSolicitada.aceptada !== undefined" (change)="decideRequestedEvidence(false)" >
                <label class="inspections-label" for="no">No</label>
              </div>
            </section>
          </div>
        </div>
  
  
        <div class="form-row" *ngIf="pruebaSolicitada.aceptada === false">
          <div class="form-group full-width-group">
            <label class="inspections-label" for="motivoRechazoSolicitud">Motivo de rechazo</label>
            <input
              placeholder="Escribe aquí las razones del rechazo"
              class="inspections-input width-70"
              name="motivoRechazoSolicitud"
              [(ngModel)]="pruebaSolicitada.motivoRechazo"
              cols="5"
              rows="5"
            />
          </div>
        </div>
  
        <button type="button" class="aggregate-button" (click)="addRequestedEvidence()">+Agregar otra prueba solicitada</button>
      </section>
    </details>

    <details>
      <summary class="font18 bold">Pruebas decretadas de oficio</summary>
      <section class="bottom-line">
        <div class="list">
          <div *ngIf="audiencia.pruebasInspector.length > 0; else noPruebasInspector">
            <div class="list-row" *ngFor="let pruebaInspector of audiencia.pruebasInspector; index as i">
              <div class="list-detail min-100p">
                <span>{{ pruebaInspector.nombre + ' / ' + formatDate(pruebaInspector.fechaDecretada) + ': ' + pruebaInspector.detalle }}</span>
              </div>
            </div>
          </div>
          <ng-template #noPruebasInspector>
            <div class="width-70">
              <p class="text">
                No existen pruebas decretadas de oficio.
              </p>
            </div>
          </ng-template>
        </div>
        <h3 class="font18 bold">Añadir prueba decretada de oficio</h3>
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label class="inspections-label" for="tipoPruebaDecretada">Tipo de prueba</label>
            <ngx-select-dropdown class="inspections-select" name="tipoPruebaDecretada" [(ngModel)]="pruebaInspector.nombre" [config]="config" [options]="listaPruebas">
            </ngx-select-dropdown>
          </div>
          <div class="form-group half-width-group">
            <label class="inspections-label" for="fechaPruebaDecretada">Fecha de practica</label>
            <input
              class="inspections-input calendar-input"
              placeholder="dd/mm/aaaa"
              type="date"
              name="fechaPruebaDecretada"
              id="fechaPruebaDecretada"
              [(ngModel)]="pruebaInspector.fechaDecretada"
            >
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group " >
            <label class="inspections-label" for="detallePruebaDecretada">Detalle prueba decretada de oficio</label>
            <textarea class="textarea" name="detallePruebaDecretada" id="detallePruebaDecretada" placeholder="Escribe aquí…" [(ngModel)]="pruebaInspector.detalle" cols="5" rows="5"></textarea>
          </div>
          <button class="aggregate-button" (click)="addDecreedEvidence()">+Agregar otra prueba decretada de oficio</button>
        </div>
      </section>
    </details>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="suspendeAudienciaPva">¿Se suspende la audiencia para práctica de pruebas?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox" [checked]="audiencia.audienciaSuspendida" (change)="changeAccomplishmentSuspendeAudiencia(true)">
            <label class="inspections-label" for="si">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="!audiencia.audienciaSuspendida && audiencia.audienciaSuspendida !== undefined" (change)="changeAccomplishmentSuspendeAudiencia(false)" >
            <label class="inspections-label" for="no">No</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row d-flex-between" *ngIf="audiencia.audienciaSuspendida">
      <div class="form-group half-width-group">
        <label for="fechaReanudacionPva" class="inspections-label">Fecha reanudación</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaReanudacionPva"
          id="fechaReanudacionPva"
          [(ngModel)]="audiencia.fechaReanudacion"
        >
      </div>
      <div class="form-group half-width-group">
        <label for="horaReanudacionPva" class="inspections-label">Hora</label>
        <input
          class="inspections-input time-input"
          placeholder="00:00"
          type="time"
          name="horaReanudacionPva"
          id="horaReanudacionPva"
          [(ngModel)]="audiencia.horaReanudacion"
        >
      </div>
    </div>
  </form>
  <button class="submit-button" (click)="onSaveInfo()">Guardar</button>
</div>
