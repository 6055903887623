<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="d-flex-between">
    <div class="left-side">
      <button class="go-back-button" (click)="goToPreviousPage()">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title mb-30">Mecanismos intermedios</h1>
    </div>
    <!-- <button class="button active" routerLink="/gestion-casos-inspecciones/nuevo-caso">Nuevo caso +</button> -->
  </div>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(1)">
    <h2 class="previous-mechanism-title">¿Qué es una Orden de Policía?</h2>
    <img [class]="firstPreviousMechanismIntermediate === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="firstPreviousMechanismIntermediate">
    <p class="previous-mechanism-p">
      Como se ha precisado en el Glosario incluido como parte del módulo Premisas Fundamentales, son tres los tipos de órdenes de policía: la que se impone como resultado de un PVI, la que se impone como resultado de un PVA, y la conminación, para cumplirla en el término señalado en por la autoridad. El requisito para que proceda la conminación es que se trate de una orden que no se pueda cumplir inmediatamente, pues de ser ello posible, no sería viable realizarla.
      <br>
      <br>
      La orden va orientada a que los responsables se abstengan de seguir desarrollando las actividades o acciones que implican un comportamiento contrario a la convivencia. Por ejemplo, que se abstengan de seguir desarrollando la actividad de cría de cerdos o que se trasladen a un lugar del municipio donde la actividad pueda ser desarrollada cumpliendo las normas sanitarias, uso del suelo y otras. Para ello, se debe otorgar un plazo razonable, tomando en consideración aspectos como la magnitud de la actividad, el número de personas involucradas, la logística implementada, etc. En ese sentido, tanto la orden como el plazo que se otorgue para cumplirla, deben estar debidamente motivados.
      <br>
      <br>
      Según el CNSCC la orden debe ser clara, precisa y concisa, es decir, identificar bien a la persona y el lugar, señalar cuál es la actividad que no se puede seguir desarrollando y las normas en que se fundamenta esa prohibición. Advertir a los responsables que si no se cumple la orden, se puede adelantar un PVI o un PVA para imponer una medida correctiva.
      <br>
      <br>
      Para imponer una orden de policía se requiere hacer una citación a la(s) persona(s) involucrada(s) en el hecho contrario a la convivencia, y adelantar una audiencia pública con los mismos pasos señalados en el artículo 223 del CNSCC.
      <br>
      <br>
      * A una persona o vehículo para que deje de circular o movilizarse en una vía dónde está prohibido o restringido (pasos prohibidos). <br> <br>
      * A una persona o vehículo para que deje de circular o movilizarse en un horario en el que está prohibido o restringido hacerlo (ley seca, toque de queda, pico y placa). <br><br>
      * A una persona para que deje de realizar actividades no permitidas en el espacio público (ventas informales estacionarias: hacer necesidades fisiológicas; instalar rejas, talanqueras, controles viales). <br><br>
      * A un comerciante para que deje de expender licores para el consumo en el establecimiento o desarrollar cualquier actividad no permitida en las normas de uso del suelo del municipio.
    </p>

  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(2)">
    <h2 class="previous-mechanism-title">¿Qué es una Mediación Policial?</h2>
    <img [class]="secondPreviousMechanismIntermediate === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="secondPreviousMechanismIntermediate">
    <p class="previous-mechanism-p">
      Es un instrumento que no existía antes en el régimen de policía y que brinda la posibilidad de que muchos conflictos puedan ser abordados sin necesidad de iniciar un PVI o un PVA. Consiste en la intervención de la autoridad de policía para que se llegue a un acuerdo entre dos o más partes en conflicto. Aparentemente este medio se encuentra limitado a ciertos casos y sólo podría ser aplicado por la policía uniformada, presunción que se desprende del parágrafo 2 del artículo 27 del CNSCC, que es el único caso en el que se menciona expresamente la aplicación de la mediación policial y casos de CCC que ponen en riesgo la vida y la integridad. Sin embargo, una lectura integral del CNSCC nos permite afirmar que sí puede ser aplicado en una variedad de casos por otras autoridades. Veamos:<br><br>
      Según el artículo 154 la mediación policial “Es el instrumento que nace de la naturaleza de la función policial, cuyas principales cualidades son la comunitariedad y la proximidad, a través del cual la autoridad es el canal para que las personas en conflicto decidan voluntariamente resolver sus desacuerdos armónicamente.”<br><br>
      Es decir, por definición legal, la mediación se fundamenta en la función de policía que, como se puede ver en el Módulo de Premisas Fundamentales, es un concepto que aplica a todas las autoridades cuando ejercen sus competencias, imponen medios y medidas correctivas, sean civiles o uniformadas.<br><br>
      El artículo 233 contempla la mediación como Mecanismo alternativo de solución de desacuerdos y conflictos y señala en el parágrafo que en los procedimientos a que hace referencia el Título VII del Libro II, será obligatorio la invitación a conciliar. Lo que esto indica es que en todos los demás casos se puede intentar la mediación sin que sea obligatorio, y ello no se restringe a la autoridad uniformada.<br><br>

      Esta lectura es compatible con el principio de necesidad mencionado a lo largo de este módulo, conforme al cual “las autoridades de Policía solo podrán adoptar los medios y medidas rigurosamente necesarias e idóneas para la preservación y restablecimiento del orden público cuando la aplicación de otros mecanismos de protección, restauración, educación o de prevención resulte ineficaz para alcanzar el fin propuesto”. Claramente la mediación encaja en el concepto de ser otro mecanismo de prevención.<br><br>

      De otro lado, el artículo 222 del CNSCC, relativo al trámite del PVI por parte de los agentes uniformados, señala en el inciso 4 que “la autoridad de Policía hará una primera ponderación de los hechos y procurará una mediación policial entre las partes en conflicto. De no lograr la mediación, impondrá la medida correctiva a través de la orden de Policía“. Esta disposición permite entender que la mediación policial es viable en todos aquéllos casos en los que la alteración de las condiciones de convivencia se produzca cuando haya dos o más partes en conflicto, y esto puede ocurrir no sólo en los casos del artículo 27, sino en otros como los siguientes CCC:<br><br>

      Los que afectan la tranquilidad y relaciones respetuosas entre las personas, art. 33.<br><br>
      Establecimientos educativos, art. 34<br><br>
      Algunos CCC contra grupos de especial protección constitucional<br><br>
      Tenencia de animales domésticos o mascotas y convivencia de las personas con animales, arts. 117 al 124.<br><br>
      Convivencia en ciclorrutas y carriles exclusivos para bicicletas, art. 144.<br><br>
      Convivencia en los sistemas de transporte motorizados, art. 146.<br><br>
      Todas aquéllas conductas que se desarrollan en lugares privados y trascienden a lo público, que involucren a varias partes.<br><br>

      Para realizar una mediación se requiere hacer una citación a la(s) persona(s) involucrada(s) en el hecho contrario a la convivencia, y adelantar una audiencia pública en la que se escuche a ambas partes, se les invite a proponer fórmulas de arreglo y llegar a un acuerdo del cual se levanta un acta. <br><br>

    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(3)">
    <h2 class="previous-mechanism-title">¿Qué es una Suspensión inmediata de actividad ?</h2>
    <img [class]="thirdPreviousMechanismIntermediate === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="thirdPreviousMechanismIntermediate">
    <p class="previous-mechanism-p">
      Es un medio de policía que se aplica a una actividad que implique riesgo inminente para sus participantes y la comunidad en general. La norma no discrimina el tipo de actividad; sin embargo, se debe entender que las autoridades de policía sólo pueden intervenir cuando se trata de actividades que se desarrollan en el espacio público, en establecimientos abiertos al público o que siendo desarrolladas en lugares privados trascienden al orden público. Ahora bien, una cosa es la suspensión inmediata de actividad como medio de policía, y otra la suspensión de actividad que involucre aglomeración de público compleja, que es una medida correctiva aplicable específicamente a eventos públicos o privados por incumplir las condiciones del permiso o cuando el recinto no cumpla con los requisitos exigidos legalmente.Tampoco se debe confundir con las medidas correctivas de suspensión temporal y definitiva de actividad, aplicables a actividades económicas. Teniendo en cuenta lo anterior, la suspensión inmediata de actividad puede ser entendida como una especie de orden de policía, y para aplicarla se requiere adelantar un PVI por parte de la policía uniformada, que se resume en una actuación en el sitio donde se de desarrolla la actividad; allí mismo se toman los datos de las personas responsables, se escuchan sus descargos y se toma la decisión allí mismo de imponer o no el medio de policía, dadas las características de inmediatez. Contra la decisión de imponer el medio de policía, es decir, la orden de suspender inmediatamente la actividad, procede una apelación ante el Inspector de Policía, que se concede en el efecto devolutivo, es decir, que la orden se cumple mientras el superior resuelve el recurso. <br><br>
      Casos en lo que procede una suspensión inmediata de actividad: <br>
      * A un grupo de personas para que deje de hacer ruidos o escándalos en parques o zonas verdes y que generan intranquilidad o inseguridad entre los vecinos. <br><br>
      * A personas que se organizan para hacer reuniones o realizar eventos masivos de cualquier índole (deportivo, político, religioso, académico, social, familiar) o con público en lugares públicos o privados sin permiso y sin reunir las condiciones de seguridad, tranquilidad, salubridad.. <br><br>
      * Es posible que la imposición del medio de policía en algunos de esos casos implique el uso de la fuerza por parte de la autoridad uniformada, que es otro medio de policía y tiene unas condiciones para su aplicación, señaladas en el artículo 166 del CNSCC.
    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(4)">
    <h2 class="previous-mechanism-title">¿Qué es una Incautación?</h2>
    <img [class]="fourthPreviousMechanismIntermediate === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="fourthPreviousMechanismIntermediate">
    <p class="previous-mechanism-p">
      Consiste en la aprehensión material transitoria de bienes, semovientes, cuya tenencia constituye un comportamiento contrario a la convivencia. Este medio de policía sólo puede ser impuesto por la policía uniformada.<br><br>
      Este es un medio de policía que por sus características es de tipo complementario, es decir, normalmente acompaña a otro medio de policía que es el principal, o a una medida correctiva. En el caso de los medios de policía, puede acompañar a una orden de policía o a una suspensión inmediata de actividad. <br><br>
      El procedimiento de incautación debe constar en un acta y debe tenerse mucho cuidado con anotar las características, identificación y condiciones de los bienes o semovientes incautados, para evitar posibles futuras reclamaciones de los actuales tenedores, poseedores o propietarios.<br><br>
      En el ejemplo de la tenencia de cerdos para su cría y posterior comercialización, esta actividad constituye un comportamiento contrario a la convivencia relativo a la actividad económica, o a la tenencia de animales. Los animales deben ser llevados a un lugar establecido para tal efecto, hoy en día denominados Albergues municipales para fauna, según la Ley 2074-20. De no existir este tipo de albergues en los municipios, es aconsejable abstenerse la autoridad de imponer este medio de policía pues una vez entre en custodia de los bienes y semovientes, asume la responsabilidad de su cuidado y preservación.<br><br>

    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(5)">
    <h2 class="previous-mechanism-title">¿Qué es una Retiro del sitio?</h2>
    <img [class]="fifthPreviousMechanismIntermediate === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="fifthPreviousMechanismIntermediate">
    <p class="previous-mechanism-p">
      De acuerdo con el artículo 156 del CNSCC, consiste en apartar de un lugar público o abierto al público, o que siendo privado preste servicios al público, a la persona que altere la convivencia y desacate una orden de policía dada para cesar su comportamiento, e impedir el retorno inmediato al mismo.<br><br>
      Nótese que en la forma en que está redactado el texto legal, este medio se encuentra ligado a la orden de policía, sin que pueda considerarse, per se, una orden, puesto que el retiro se concibe como una consecuencia del incumplimiento de aquélla. Lo que esto indica es que la orden de policía es un requisito previo para poder imponer el retiro del sitio, y debe agotarse un PVI independiente, es decir, un PVI para la orden de policía de inmediato cumplimiento, y un PVI para imponer el retiro del sitio.<br><br>
      Un ejemplo de este medio puede ser el retiro de un vendedor ambulante del espacio público.<br><br>
      En algunos casos, resulta más adecuada la utilización de otro medio como el traslado por protección. Por ejemplo, cuando se trate de una persona, cliente, que se encuentre al interior de un establecimiento de comercio, se encuentre en avanzado estado de embriaguez o en alto grado de exaltación y con su conducta ponga en riesgo la seguridad o la tranquilidad de otras personas o de los bienes. Es posible que darle la orden de retirarse y retirarlo no sea idóneo si persiste en su comportamiento, mientras que trasladarlo para su protección y la de otras personas a su propio lugar de habitación o a un centro asistencial puede resultar más adecuado (art. 155).<br><br>
    </p>
  </article>
</main>
