import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevo-caso',
  templateUrl: './nuevo-caso.component.html',
  styleUrls: ['./nuevo-caso.component.css']
})
export class NuevoCasoComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  volver(){
    this.router.navigate(['gestion-casos-inspecciones']);
  }

}
