import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-mecanismos-previos',
  templateUrl: './mecanismos-previos.component.html',
  styleUrls: ['./mecanismos-previos.component.css']
})
export class MecanismosPreviosComponent implements OnInit {

  firstPreviousMechanism = false;
  secondPreviousMechanism = false;
  thirdPreviousMechanism = false;
  fourthPreviousMechanism = false;
  fifthPreviousMechanism = false;

  private queryParams: Params;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  /**
   * Va a la pantalla anterior de acuerdo de donde haya llegado
   */
  goToPreviousPage(){
    if(this.queryParams['previousPage'] && this.queryParams['caseId']){
      this.router.navigateByUrl(
        this.router.createUrlTree([`/gestion-casos-inspecciones/ver-caso/mecanismo-previo/${this.queryParams['caseId']}`], { queryParams: {previousPage: 'ficha-general'}})
      );
    } else {
      this.router.navigateByUrl('/submodulos-inspecciones');
    }
  }

  showPreviousMechanism(number){
    switch (number) {
      case 1:
        this.firstPreviousMechanism = !this.firstPreviousMechanism;
        break;
      case 2:
        this.secondPreviousMechanism = !this.secondPreviousMechanism;
        break;
      case 3:
        this.thirdPreviousMechanism = !this.thirdPreviousMechanism;
        break;
      case 4:
        this.fourthPreviousMechanism = !this.fourthPreviousMechanism;
        break;
      case 5:
        this.fifthPreviousMechanism = !this.fifthPreviousMechanism;
    }
  }

}
