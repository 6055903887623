<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title text-left">Seguimiento al cumplimiento de la(s) medida(s) correctiva(s)</h2>
    </div>
    <div class="d-flex-start min-width">
      <button *ngIf="oldFollow || newFollow" class="cancel-button mr-10" (click)="cancelFollowUp()">Salir de {{ oldFollow ? 'edición' : 'creación' }}</button>
      <button class="download-button" (click)="downloadPDF()">
        <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
      </button>
    </div>
  </div>
  <h4 class="font18 bold">Seguimientos</h4>
  <div class="form-group d-flex-l" *ngIf="seguimientos.length > 0; else noFollowUps">
    <button class="selectable-button" *ngFor="let seguimiento of seguimientos; index as i" (click)="editFollowUp(seguimiento, index)">{{ 'Seguimiento ' + (i+1) + ' / ' + formatDate(seguimiento?.fecha) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar"></button>
  </div>
  <ng-template #noFollowUps>
    <div class="width-70">
      <p class="text">
        No tienes ningun seguimiento creado.
      </p>
    </div>
  </ng-template>

  <form *ngIf="oldFollow || newFollow">
    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="fecha" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          [(ngModel)]="seguimiento.fecha"
          type="date"
          name="fecha"
          id="fecha" />
      </div>
      <div class="form-group half-width-group">
        <label for="horaSeguimientoPva" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          [(ngModel)]="seguimiento.hora"
          name="horaSeguimientoPva"
          class="inspections-input time-input"
          id="horaSeguimientoPva" />
      </div>
    </div>
    <br />

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="descripcion">Descripción del seguimiento</label>
        <textarea
          class="textarea"
          name="descripcion"
          id="descripcion"
          [(ngModel)]="seguimiento.descripcion"
          placeholder="Escribe aquí…"
          cols="30"rows="10"
        ></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="verificacionCumplimiento">Verificación del cumplimiento de la Medida correctiva impuesta y demás órdenes libradas</label>
        <textarea
          class="textarea"
          name="verificacionCumplimiento"
          [(ngModel)]="seguimiento.verificacionCumplimiento"
          id="verificacionCumplimiento"
          placeholder="Escribe aquí…"
          cols="30" rows="10"
        ></textarea>
      </div>
    </div>

    <div class="form-row ">
      <div class="form-group full-width-group width">
        <label class="inspections-label" for="responsable">Entidad / dependencia / persona responsable de ejecución</label>
        <input
          class="inspections-input"
          type="text"
          [(ngModel)]="seguimiento.responsable"
          name="responsable"
          id="responsable"
          placeholder="Escribe aquí">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="cumplioMedidaPva">¿Se cumplió medida ?</label>
        <div class="d-flex-between">
          <div class="d-flex-start checkbox-container min-width-40">
            <input class="checkbox" name="si"  type="checkbox" [checked]="seguimiento.cumpleMedida === 'yes'" (change)="changeAccomplishment('yes')">
            <label class="inspections-label" for="si">Si</label>
          </div>
          <div class="d-flex-start checkbox-container min-width-40">
            <input class="checkbox" name="si"  type="checkbox" [checked]="seguimiento.cumpleMedida === 'partially'" (change)="changeAccomplishment('partially')">
            <label class="inspections-label" for="si">Parcialmente</label>
          </div>
          <div class="d-flex-start checkbox-container min-width-40">
            <input class="checkbox" name="no"  type="checkbox" [checked]="seguimiento.cumpleMedida === 'no'" (change)="changeAccomplishment('no')" >
            <label class="inspections-label" for="no">No</label>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row" *ngIf="seguimiento.cumpleMedida === 'partially' || seguimiento.cumpleMedida === 'no'">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="razonIncumplimiento">Razón del incumplimiento</label>
        <textarea
          class="textarea"
          name="razonIncumplimiento"
          id="razonIncumplimiento"
          [(ngModel)]="seguimiento.razonIncumplimiento"
          placeholder="Escribe aquí…"
          cols="30" rows="10"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="observaciones">Observaciones</label>
        <textarea
        class="textarea"
        name="observaciones"
        id="observaciones"
        [(ngModel)]="seguimiento.observaciones"
        placeholder="Escribe aquí…"
        cols="30" rows="10"
      ></textarea>
      </div>
    </div>
    <button class="aggregate-button mb-20" *ngIf="oldFollow" (click)="saveFollowUp()">Guardar cambios</button>
    <button class="aggregate-button mb-20" *ngIf="newFollow" (click)="addFollowUp()">+Agregar seguimiento</button>
  </form>

  <div>
    <button class="aggregate-button mb-20" *ngIf="!newFollow && !oldFollow" (click)="newFollowUp()">Nuevo seguimiento</button>
  </div>
  <button class="submit-button" (click)="onSaveInfo()">Guardar</button>
</div>
