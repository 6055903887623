import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MainService } from 'src/app/services/main.service';
import Swal from 'sweetalert2';
import { CCC, ConsultaExternaTableData, FichaGeneral, MecanismoIntermedio, MecanismoPrevio, Pva } from '../inspecciones.interface';

type request = {
  fichasGenerales: FichaGeneral[],
  mecPrevios: MecanismoPrevio[],
  mecIntermedios: MecanismoIntermedio[],
  pvas: Pva[]
}

@Component({
  selector: 'app-vista-consulta',
  templateUrl: './vista-consulta.component.html',
  styleUrls: ['./vista-consulta.component.css']
})
export class VistaConsultaComponent {

  form: FormGroup;
  listaLength: number = 0;
  mecPrevios: MecanismoPrevio[] = [];
  mecIntermedios: MecanismoIntermedio[] = [];
  pvas: Pva[] = [];

  dataSource: MatTableDataSource<ConsultaExternaTableData, any>
  displayedColumns: string[] = ['fecha','numCaso', 'peticionarios', 'presuntosInfractores', 'comportamientosDetalle', 'accionesAdelantadas'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private mainService: MainService
  ){
    this.form = new FormGroup({
      numCaso: new FormControl(),
      cedula: new FormControl(),
    })
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Realiza la búsqueda por medios de unos criterios específicos
   */
  onSearch(){
    const numCaso = this.form.get('numCaso').value;
    const cedula = this.form.get('cedula').value;
    this.listaLength = 0;
    if(!numCaso && !cedula) {
      Swal.fire('Error', 'Debes rellenar al menos un campo', 'error');
      return;
    };
    this.mainService.post<request>('api/ficha-general/consulta-externa', { numCaso, cedula }).subscribe(
      (response) => {
        if(this.mainService.isSuccess<request>(response)){
          this.listaLength = response.result.fichasGenerales.length;
          if(this.listaLength === 0){
            Swal.fire('Ay...', 'No hemos encontrado un caso con estos datos', 'error');
          }
          this.mecPrevios = response.result.mecPrevios;
          this.mecIntermedios = response.result.mecIntermedios;
          this.pvas = response.result.pvas;
          const fichasGeneralesEncontradas: FichaGeneral[] = response.result.fichasGenerales;
          const fixed: ConsultaExternaTableData[] = fichasGeneralesEncontradas.map(ficha => {
            const { nombresCompletosPeticionarios } = this.getPetitioners(ficha.peticionarios);
            const { nombresCompletosInfractores } = this.getInfractors(ficha.infractores);
            const comportamientosDetalle = this.getCCCDetail(ficha.CCC);
            return ({
              fecha: moment(ficha.fechaHechos).locale('es-CO').format('LL'),
              numCaso: ficha.caso.numCaso,
              peticionarios: nombresCompletosPeticionarios,
              presuntosInfractores: nombresCompletosInfractores,
              comportamientosDetalle: comportamientosDetalle,
              accionesAdelantadas: this.getActions(ficha)
            })
          });
          this.dataSource = new MatTableDataSource(fixed);
          this.dataSource.paginator = this.paginator;
        }
      }
    )
  }


  /**
   * Aplica un filtro en la tabla que hace una búsqueda en todos los casos
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Devuelve la información de los peticionarios para una ficha general
   * @param infractores Lista de infractores asociados a una ficha general
   * @returns {string} nombresCompletosPeticionarios
   */
  getPetitioners(peticionarios: any[]): { nombresCompletosPeticionarios: string } {
    const nombresCompletosPeticionarios = peticionarios.map(petitioner => petitioner.nombrePeticionario).join(', ');
    return { nombresCompletosPeticionarios }
  }

  /**
   * Devuelve la información de los infractores para una ficha general
   * @param infractores Lista de infractores asociados a una ficha general
   * @returns {string} nombresCompletosInfractores
   */
  getInfractors(infractores: any[]): { nombresCompletosInfractores: string } {
    const infractors = []
    for(let infractor of infractores){
      let nombreCompleto
      if(infractor.nombreInfractor && infractor.apellidoInfractor){
        nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
      } else if (infractor.nombreInfractor){
        nombreCompleto = infractor.nombreInfractor;
      } else if (infractor.apellidoInfractor){
        nombreCompleto = infractor.apellidoInfractor;
      } else continue
      infractors.push({
        nombreCompleto,
        tipoIdentificacion: infractor.tipoIdentificacionInfractor,
        identificacion: infractor.identificacionInfractor,
        correo: infractor.correoInfractor,
        telefono: infractor.telefonoInfractor,
        direccion: infractor.direccionInfractor,
        nombre: infractor.nombreInfractor,
        apellido: infractor.apellidoInfractor,
        tipo: 'infractor'
      })
    }
    const nombresCompletosInfractores = infractors.map(infractor => infractor.nombreCompleto).join(', ');
    return { nombresCompletosInfractores };
  }

  /**
   * Obtiene el campo detalle de los CCCs y los agrupa en un texto
   * @param CCCs la lista
   * @returns todas las descripciones de los comportamientos
   */
  getCCCDetail(CCCs: CCC[]): string {
    return CCCs.reduce((prev, curr) => `${prev}${curr.descripcionComportamiento ? curr.descripcionComportamiento+'; ' : ''}`, '');
  }

  /**
   * Obtiene las acciones realizadas de los casos
   * @param ficha la ficha
   * @returns texto con las acciones realizadas, separadas por comas.
   */
  getActions(ficha: FichaGeneral): string {
    const mecPrevioEncontrado = this.mecPrevios.find(mecPrevio => String(mecPrevio.caso) === String(ficha.caso));
    const mecIntermedioEncontrado = this.mecIntermedios.find(mecIntermedio => String(mecIntermedio.caso) === String(ficha.caso));
    const pvaEncontrado = this.pvas.find(pva => pva.caso === ficha.caso);
    let acciones = '';
    if(mecPrevioEncontrado){
      if(mecPrevioEncontrado.faseIntervencion.actividades.length > 0){
        acciones += ' Mecanismo previo,'
      }
    }
    if(mecIntermedioEncontrado){
      if(mecIntermedioEncontrado.audiencias.length > 0){
        acciones += ' Mecanismo intermedio,'
      }
    }
    if(pvaEncontrado){
      if(pvaEncontrado.audiencias.length > 0){
        acciones += ' Proceso verbal abreviado'
      }
    }
    return acciones;
  }
}
