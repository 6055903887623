<header class="header">
  <a routerLink="/inicio-inspecciones">
    <figure class="imagenes">
      <!-- <img src="" alt="" srcset=""> -->
      <img class="logo-img" src="../../../assets/img/inspecciones/logo-fip.webp" alt="Logo FIP - Microsoft">
    </figure>
  </a>
  <nav class="nav-opciones">
    <ul class="opciones">
      <a routerLink="/inicio-inspecciones" class="text-decoration-none"><li [class.elegida]="opcionElegida === 1" class="opcion">Inicio</li></a>
      <a routerLink="/gestion-casos-inspecciones" class="text-decoration-none"><li [class.elegida]="opcionElegida === 2" class="opcion">Gestión de casos</li></a>
      <a routerLink="/biblioteca-inspecciones" class="text-decoration-none"><li [class.elegida]="opcionElegida === 3" class="opcion">Biblioteca</li></a>
      <a routerLink="/estadisticas-inspecciones" class="text-decoration-none"><li [class.elegida]="opcionElegida === 4" class="opcion">Estadísticas</li></a>
    </ul>
  </nav>
</header>
