import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-pva',
  templateUrl: './pva.component.html',
  styleUrls: ['./pva.component.css']
})
export class PvaComponent implements OnInit {
  firstPreviousMechanismVerbal = false;
  secondPreviousMechanismVerbal = false;
  thirdPreviousMechanismVerbal = false;
  fourthPreviousMechanismVerbal = false;
  fifthPreviousMechanismVerbal = false;
  sixPreviousMechanismVerbal = false;
  sevenPreviousMechanismVerbal = false;
  eightPreviousMechanismVerbal = false;

  private queryParams: Params
  
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  showPreviousMechanism(number: number){
    switch (number) {
      case 1:
        this.firstPreviousMechanismVerbal = !this.firstPreviousMechanismVerbal;
        break;
      case 2:
        this.secondPreviousMechanismVerbal = !this.secondPreviousMechanismVerbal;
        break;
      case 3:
        this.thirdPreviousMechanismVerbal = !this.thirdPreviousMechanismVerbal;
        break;
      case 4:
        this.fourthPreviousMechanismVerbal = !this.fourthPreviousMechanismVerbal;
        break;
      case 5:
        this.fifthPreviousMechanismVerbal = !this.fifthPreviousMechanismVerbal;
        break;
      case 6:
        this.sixPreviousMechanismVerbal = !this.sixPreviousMechanismVerbal;
        break;
      case 7:
        this.sevenPreviousMechanismVerbal = !this.sevenPreviousMechanismVerbal;
        break;
      case 8:
        this.eightPreviousMechanismVerbal = !this.eightPreviousMechanismVerbal;
        break;
    }
  }
  
  /**
   * Va a la pantalla anterior de acuerdo de donde haya llegado
   */
  goToPreviousPage(){
    if(this.queryParams['previousPage'] && this.queryParams['caseId']){
      this.router.navigateByUrl(
        this.router.createUrlTree([`/gestion-casos-inspecciones/ver-caso/pva/${this.queryParams['caseId']}`], { queryParams: {previousPage: 'ficha-general'}})
      );
    } else {
      this.router.navigateByUrl('/submodulos-inspecciones');
    }
  }
}
