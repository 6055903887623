import { Directive } from '@angular/core';

@Directive({
  selector: '[appInputNumber]'
})
export class InputNumberDirective {

  constructor() { }

}
