import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MainService } from '../services/main.service';
import Swal from 'sweetalert2';;
@Component({
  selector: 'app-forgot-inspecciones',
  templateUrl: './forgot-inspecciones.component.html',
  styleUrls: ['./forgot-inspecciones.component.css']
})
export class ForgotInspeccionesComponent implements OnInit {
  data: any = {};

  constructor(
    private mainService: MainService
  ) { }

  ngOnInit() {
  }

  public onForgot(form: NgForm) {
    console.log(form.value, form.valid)
    if(!form.valid){
      Swal.fire(
        "Error",
        "No se han completado los campos adecuadamente",
        "warning"
      );
      return
    }
    this.mainService.post('users/forgot', {email: form.value.email, isInspector: true})
      .subscribe(result => {
        if(result) {
          Swal.fire(
            'Exito',
            'Hemos enviado un correo a su dirección ' + form.value.email + ' con instrucciones para reestablecer su contraseña.',
            'success'
          )
        } else {
          Swal.fire(
            'Error',
            'Hubo un error al reestablecer su contraseña, por favor intente de nuevo.',
            'warning'
          )
        }
      }, err => {
        Swal.fire(
          'Error',
          'Hubo un error al reestablecer su contraseña, por favor intente de nuevo.',
          'warning'
        )
      })
  }
}
