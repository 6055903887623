<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="new-case-container">
    <aside class="aside-new-case">
      <h2 class="header-biblioteca-titulo">
        <button class="volver-button" (click)="goBackButton()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda"> Volver</button>
      </h2>
      <h1 class="aside-title">Detalle Caso Nº{{ numCaso }}</h1>
      <div class="phases-container">
        <div class="phase-container" (click)="goToPhase('mecanismo-previo')">
          <p class="phase-title">Fase 1</p>
          <p class="phase-description">Mecanismos previos</p>
        </div>
        <div class="phase-container" (click)="goToPhase('mecanismo-intermedio')">
          <p class="phase-title">Fase 2</p>
          <p class="phase-description">Mecanismo intermedio</p>
          <p class="phase-subtitle">(Orden de policía)</p>
        </div>
        <div class="phase-container" (click)="goToPhase('pva')">
          <p class="phase-title">Fase 3</p>
          <p class="phase-description">Proceso verbal abreviado</p>
        </div>
        <div class="phase-container" (click)="goToPhase('seguimiento-evaluacion')">
          <p class="phase-title">Fase 4</p>
          <p class="phase-description">Seguimiento y evaluación</p>
        </div>
      </div>
    </aside>
    <main class="main-new-case">
      <app-form-ficha-general *ngIf="informationReceived" [isEditing]="true" [numCaso]="numCaso" [numInspeccion]="numInspeccion"></app-form-ficha-general>
    </main>
  </div>
</div>
