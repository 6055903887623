import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate() {
    if (this.authService.loggedIn()) {
      console.log('entra por aqui1');
      let user = JSON.parse(localStorage.getItem("user"));
      if (user && user.cargo === "Comisario") {
        this.router.navigate(["inicio-comisarias"]);
        return false;
      }
      this.router.navigate(["piscc-inicio"]);
      return false;
    } else if (this.authService.loggedInInspections()){
      console.log('entra por aqui');
      let user = JSON.parse(localStorage.getItem("inspector"));
      if(user && user.cargo === "Inspector") {
        this.router.navigate(["inicio-inspecciones"]);
        return false;
      }
    }
    return true;
  }

}
