import { Injectable } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { Inspector } from '../inspecciones.interface';
@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  constructor(
    private mainService: MainService
  ) { }

  getInfo(inspector: Inspector, dates: { startDate: Date, endDate: Date }): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.mainService.post('api/ficha-general/estadisticas', { inspector, dates }).subscribe(response => {
        if(this.mainService.isSuccess(response)){
          resolve(response.result as any[]);
        } else {
          reject(response.error);
        }
      });
    })
  }
}
