<div class="form-container p-relative">
  <div class="form-header">
    <div class="d-flex-between width-50">
      <div class="step-container" *ngFor="let paso of pasos">
        <div [class]="step >= paso ? 'orange-button orange-button-selected': 'orange-button'">{{ paso }}</div>
        <span>Paso {{ paso }}</span>
      </div>
    </div>
    <div class="line"></div>
    <div>
      <button *ngIf="oldHearing || newHearing" class="cancel-button mr-10" (click)="cancelHearing()">Salir de {{ newHearing ? 'creación' : 'edición' }}</button>
      <button class="download-button" *ngIf="step === 3" (click)="downloadPDF()"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar"></button>
    </div>
  </div>
  <div class="left-side mb-20" *ngIf="step === 1">
    <h2 class="form-title">Citación caso Nº{{ caso?.numCaso }}</h2>
  </div>
  <div class="left-side mb-20" *ngIf="step === 2">
    <h2 class="form-title">Constancia de entrega o de envío a correo</h2>
  </div>
  <div class="left-side mb-20" *ngIf="step === 3">
    <h2 class="form-title">Constancia de asistencia</h2>
  </div>
  <p class="font16 bold" *ngIf="oldHearing">Estás viendo la citación con fecha {{ formatDate(audiencia.fechaAudiencia) }}</p>

  <!-- LISTA DE CITACIONES -->
  <div class="bottom-line">
    <h3 class="font18 bold">Selecciona una citación</h3>
    <div class="list width-70" *ngIf="audienciasDisponibles?.length > 0; else noAudiencias">
      <div class="list-row d-flex-between mb-10" *ngFor="let audiencia of audienciasDisponibles; index as i">
        <div class="list-detail min-100per" *ngIf="audiencia.activo !== false">
          <span>{{ 'Citación ' + (i+1) + ' / ' + formatDate(audiencia.fechaAudiencia) }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editHearing(audiencia)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
            <button class="selectable-button" (click)="deleteHearing(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noAudiencias>
      <div class="width-70">
        <p class="text">
          No tienes ninguna citación creada, crea una para acceder a todas las funcionalidades del mecanismo intermedio.
        </p>
      </div>
    </ng-template>
    <button *ngIf="!oldHearing && !newHearing" class="aggregate-button mb-20" (click)="startHearing()">Nueva citación</button>
  </div>

  <!-- LISTA DE CORREOS -->
  <div class="bottom-line" *ngIf="correosEnviados?.length > 0 && step === 2; else noCorreos" class="form-group d-flex-l">
    <button  *ngFor="let correo of correosEnviados; index as i" (click)="editMail(correo)">{{ 'Correo ' + (i+1) + ' - ' + correo.fechaEnvioCorreo.split("T")[0] }}</button>
  </div>

  <ng-template #noCorreos >
    <ng-container *ngIf="step === 2">
      <div class="width-70">
        <p class="text">
          No tienes ningun correo envíado, envía uno para acceder a estas funcionalidades.
        </p>
      </div>
    </ng-container>
  </ng-template>

  <!-- FORM PASO 1 -->
  <form class="phase-form" #citacion="ngForm" *ngIf="(newHearing || oldHearing) && step === 1">
    <p class="font16 bold" *ngIf="audiencia.autoFecha">Esta audiencia ha sido suspendida, cree una nueva audiencia con la fecha de reanudación si aún no lo ha hecho. La fecha de reanudación es {{ formatDate(audiencia.fechaReanudacion) }}</p>
    <div class="form-row">
      <div class="form-group full-width-group">
        <h4 class="font16">Localidad / comuna / vereda / barrio</h4>
        <p>{{ fichaGeneral.nombreLugarHechos }}</p>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="citado">Citados</label>
        <ngx-select-dropdown [disabled]="false" class="inspections-select" name="citado" [(ngModel)]="citado" [config]="config" [options]="posiblesCitados">
        </ngx-select-dropdown>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Nombre</h4>
        <p>{{ citado.nombre || citado.nombreCompleto || 'No asignado' }}</p>
      </div>
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Apellido</h4>
        <p>{{ citado.apellido || 'No asignado' }}</p>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Dirección</h4>
        <p>{{ citado.direccion || 'No asignada' }}</p>
      </div>
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Teléfono</h4>
        <p>{{ citado.telefono || 'No asignado' }}</p>
      </div>
    </div>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Correo</h4>
        <p>{{ citado.correo || 'No asignado' }}</p>
      </div>
      <div class="form-group half-width-group">
        <h4 class="inspections-label" for="citado">Identificación</h4>
        <p>{{ citado.tipoIdentificacion  && citado.identificacion ? (citado.tipoIdentificacion ? citado.tipoIdentificacion + ' ' : '') + (citado.identificacion || '') : 'No asignado' }}</p>
      </div>
    </div>
    <button *ngIf="!wantEditQuoted" class="aggregate-button mb-20" (click)="onAggregateQuoted()">Agregar</button>
    <button *ngIf="wantEditQuoted" class="aggregate-button mb-20 mr-20" (click)="onModifyQuoted()">Modificar</button>
    <button *ngIf="wantEditQuoted" class="cancel-small-button mb-20" (click)="cancelQuoted()">Cancelar</button>
    <div class="list" *ngIf="audiencia?.citados?.length > 0">
      <div class="list-row d-flex-between mb-10" *ngFor="let citado of audiencia?.citados; index as i">
        <div class="list-detail min-100per">
          <span>{{ (citado.nombreCompleto | truncate:150) || (citado.nombre | truncate:150) }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editQuoted(citado)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
            <button class="selectable-button" (click)="deleteQuoted(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
          </div>
        </div>
      </div>
    </div>
    <p class="font16 pt-30 border-top mb-20">Con el ánimo de adelantar una diligencia de audiencia pública en la que tendrá la oportunidad de expresar sus opiniones y aportar sus pruebas respecto del siguiente asunto:</p>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="citado">Descripción del Comportamiento Contrario a la Convivencia</label>
        <p *ngFor="let ccc of cccs; index as i" class="font16">{{(i+1) + ': ' + ccc.descripcionComportamiento }}</p>
      </div>
    </div>
    <h3 class="inspections-subtitle bold">Para lo cual debe presentarse el día:</h3>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaAudiencia" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaAudiencia"
          id="fechaAudiencia"
          [min]="todayDate"
          [(ngModel)]="audiencia.fechaAudiencia">
      </div>
      <div class="form-group half-width-group">
        <label for="horaAudiencia" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaAudiencia"
          class="inspections-input time-input"
          id="horaAudiencia"
          [(ngModel)]="audiencia.horaAudiencia">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="direccionAudiencia" class="inspections-label">En la siguiente dirección</label>
        <input
        type="text"
        placeholder="Escribe aquí"
        name="direccionAudiencia"
        class="inspections-input"
        id="direccionAudiencia"
        [(ngModel)]="audiencia.direccionAudiencia">
      </div>
    </div>
    <p class="mb-0 font16">En caso de no presentarse o de no justificar su inasistencia dentro de los tres días hábiles siguientes a la fecha señalada, la audiencia será realizada sin su presencia y se tomarán las decisiones a que haya lugar, según lo señalado en el artículo 223 de la Ley 1801 de 2016 (Código Nacional de Seguridad y Convivencia Ciudadana).<br><br>Atentamente,</p>
    <div class="form-row d-flex-between d-start-top">
      <div class="form-group half-width-group d-flex d-start-top">
        <div class="form-group half-width-group">
          <h4 class="inspections-label bold">Municipio</h4>
          <p class="font16 mt-10">{{ fichaGeneral.municipio }}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label bold">Inspeccion No.</h4>
          <p class="font16 mt-10">{{ caso.numInspeccion }}</p>
        </div>
      </div>
      <div class="form-group half-width-group">
        <h4 class="inspections-label bold">Dirección</h4>
        <p class="font16 mt-10">{{ inspector.direccionInspeccion }}</p>
      </div>
    </div>
    <div class="form-row d-flex-between d-start-top">
      <div class="form-group half-width-group">
        <h4 class="inspections-label bold mt-0">Nombre Inspector de policía</h4>
        <p class="font16 mt-10">{{ ((inspector.nombre ? inspector.nombre + ' ' : '') + (inspector.apellido ? inspector.apellido : '')) || 'No asignado' }}</p>
      </div>
      <div class="form-group half-width-group">
        <label for="firmaInspectorPolicia" class="inspections-label bold">Firma Inspector de policía</label>
        <label for="firmaInspectorPolicia" class="file-container file-container-45 font16 b-20 d-flex">
          <p class="font16">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <input
            class="file-input"
            type="file"
            id="firmaInspectorPolicia"
            name="firmaInspectorPolicia"
            (change)="onFileChange($event, 'firma-inspector')">
        </label>
        <div>
          <a *ngIf="audiencia.firmaInspectorPolicia" [href]="audiencia.firmaInspectorPolicia" target="_blank">Ver</a>
          <button *ngIf="audiencia.firmaInspectorPolicia" (click)="deleteSignature('inspector')" class="delete-inline-button">Borrar</button>
        </div>
      </div>
    </div>
    <div class="form-row d-flex-between-start">
      <div class="form-group half-width-group">
        <label for="nombreFuncionario" class="inspections-label bold">Nombre Funcionario</label>
        <input
          type="text"
          placeholder="Escribe aquí"
          name="nombreFuncionario"
          class="inspections-input"
          id="nombreFuncionario"
          [(ngModel)]="audiencia.nombreFuncionario">
      </div>
      <div class="form-group half-width-group">
        <label for="firmaFuncionario" class="inspections-label bold">Firma Funcionario</label>
        <label for="firmaFuncionario" class="file-container file-container-45 font16 b-20 d-flex">
          <p class="font16">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <input
            class="file-input"
            type="file"
            id="firmaFuncionario"
            name="firmaFuncionario"
            (change)="onFileChange($event, 'firma-funcionario')">
        </label>
        <div>
          <a *ngIf="audiencia.firmaFuncionario" [href]="audiencia.firmaFuncionario" target="_blank">Ver</a>
          <button *ngIf="audiencia.firmaFuncionario" (click)="deleteSignature('funcionario')" class="delete-inline-button">Borrar</button>
        </div>
      </div>
    </div>
  </form>
  <!-- FORM PASO 2 -->
  <form class="phase-form" #citacion="ngForm" *ngIf="(newHearing || oldHearing) && step === 2">
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="registroCorreo" class="inspections-label bold">Registro</label>
        <label for="registroCorreo" class="file-container font16 b-20 d-flex">
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <p class="font16">Subir archivo</p>
          <input
            class="file-input"
            type="file"
            id="registroCorreo"
            name="registroCorreo"
            (change)="onFileChange($event, 'registro-correo')">
        </label>
        <div class="p-relative-registros" *ngIf="correo.registroCorreo">
          <a [href]="correo.registroCorreo" target="_blank"><img [src]="getImageFromTypeFile(correo.registroCorreo)" class="uploaded-image" alt="Imagen de registro del correo"></a>
          <img class="delete-uploaded-image" src="assets/img/inspecciones/arrow-down.png" alt="X" (click)="deleteRegister()">
        </div>
      </div>
    </div>
    <h4 class="font18 bold">Envío</h4>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaEnvioCorreo" class="inspections-label">Fecha</label>
        <input
          type="date"
          placeholder="Escribe aquí"
          name="fechaEnvioCorreo"
          class="inspections-input calendar-input"
          id="fechaEnvioCorreo"
          [(ngModel)]="correo.fechaEnvioCorreo">
      </div>
      <div class="form-group half-width-group">
        <label for="horaEnvioCorreo" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaEnvioCorreo"
          class="inspections-input time-input"
          id="horaEnvioCorreo"
          [(ngModel)]="correo.horaEnvioCorreo">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="notaRecibidoCorreo" class="inspections-label bold">Nota de recibido</label>
        <textarea class="textarea" name="notaRecibidoCorreo" id="notaRecibidoCorreo" [(ngModel)]="correo.notaRecibidoCorreo" cols="30" rows="10"></textarea>
      </div>
    </div>
    <div class="form-row d-flex-between-start">
      <div class="form-group half-width-group">
        <label for="nombreFuncionarioResponsable" class="inspections-label bold">Nombre funcionario responsable</label>
        <input
          type="text"
          placeholder="Escribe aquí"
          name="nombreFuncionarioResponsable"
          class="inspections-input"
          id="nombreFuncionarioResponsable"
          [(ngModel)]="correo.nombreFuncionarioResponsable">
      </div>
      <div class="form-group half-width-group">
        <label for="firmaFuncionarioResponsable" class="inspections-label bold">Firma funcionario responsable</label>
        <label for="firmaFuncionarioResponsable" class="file-container file-container-45 nt16 b-20 d-flex">
          <p class="font16">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image mr-0">
          <input
            class="file-input"
            type="file"
            id="firmaFuncionarioResponsable"
            name="firmaFuncionarioResponsable"
            (change)="onFileChange($event, 'firma-funcionario-responsable')">
        </label>
        <div>
          <a *ngIf="correo.firmaFuncionarioResponsable" [href]="correo.firmaFuncionarioResponsable" target="_blank">Ver</a>
          <button *ngIf="correo.firmaFuncionarioResponsable" (click)="deleteSignature('funcionario-responsable')" class="delete-inline-button">Borrar</button>
        </div>
      </div>
    </div>
  </form>
  <!-- FORM PASO 3 -->
  <form class="phase-form" #citacion="ngForm" *ngIf="(newHearing || oldHearing) && step === 3">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaConstanciaAsistencia" class="inspections-label">Fecha de constancia</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaConstanciaAsistencia"
          id="fechaConstanciaAsistencia"
          [(ngModel)]="audiencia.fechaConstanciaAsistencia">
      </div>
      <div class="form-group half-width-group">
        <label for="horaConstanciaAsistencia" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaConstanciaAsistencia"
          class="inspections-input time-input"
          id="horaConstanciaAsistencia"
          [(ngModel)]="audiencia.horaConstanciaAsistencia">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="lugarConstanciaAsistencia" class="inspections-label">Lugar</label>
        <input
          type="text"
          placeholder="Escribe aquí"
          name="lugarConstanciaAsistencia"
          class="inspections-input"
          id="lugarConstanciaAsistencia"
          [(ngModel)]="audiencia.lugarConstanciaAsistencia">
      </div>
    </div>
    <h4 class="font18 bold">Desarrollo de la audiencia</h4>
    <p>En el municipio de {{ fichaGeneral.municipio }} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha <input type="date" placeholder="dd/mm/aaaa" name="autoFecha" class="inspections-input calendar-input" id="autoFecha" [(ngModel)]="audiencia.autoFecha"> y previas las comunicaciones de rigor, el Inspector, {{ inspector.codigoInspector }} da inicio a la Audiencia Pública.</p>
    <h4 class="font18 bold">Asiste el (los) citado(s)</h4>

    <!-- LISTA DE CITADOS CON CHECKBOXES PARA MARCAR SÍ Y NO -->
    <div class="list" *ngIf="audiencia?.citados?.length > 0; else sinCitados">
      <div class="list-row d-flex-between mb-10" *ngFor="let citado of audiencia?.citados; index as i">
        <span class="font16">{{ citado.nombreCompleto | truncate:150 }}</span>
        <div class="checkslist">
          <div class="d-flex-around ml-20">
            <input class="checkbox width-30" name="asiste" [checked]="citado.asiste" (change)="changeAssistanceStatus(citado, true, i)" type="checkbox">
            <label class="inspections-label mb-0" for="asiste">Sí</label>
          </div>
          <div class="d-flex-around ml-10">
            <input class="checkbox width-30" name="noAsiste" [checked]="!citado.asiste && citado.asiste != undefined" (change)="changeAssistanceStatus(citado, false, i)" type="checkbox">
            <label class="inspections-label mb-0" for="noAsiste">No</label>
          </div>
        </div>
      </div>
    </div>
    <ng-template #sinCitados>
      <div>
        <p class="font16">No se han agregado citados</p>
      </div>
    </ng-template>
    <div class="form-row" *ngIf="showContinueOption">
      <div class="form-group full-width-group">
        <label for="continuaSinAsistencia" class="inspections-label bold">Se continúa la audiencia sin la asistencia de el (los) citado(s)</label>
        <div class="checkslist">
          <div class="d-flex-around">
            <input class="checkbox width-30" name="asiste" [checked]="!audiencia?.suspendida && audiencia?.suspendida != undefined" (change)="changeContinuationStatus(false)" type="checkbox">
            <label class="inspections-label mb-0" for="asiste">Sí</label>
          </div>
          <div class="d-flex-around ml-10">
            <input class="checkbox width-30" name="noAsiste" [checked]="audiencia?.suspendida" (change)="changeContinuationStatus(true)" type="checkbox">
            <label class="inspections-label mb-0" for="noAsiste">No</label>
          </div>
        </div>
      </div>
    </div>
    <p class="font16" *ngIf="audiencia?.suspendida">Teniendo en cuenta que no asistió el presunto infractor se suspende audiencia para que POR UNA SÓLA VEZ se justifique la inasistencia dentro de los 3 días hábiles siguientes a la fecha arriba anotada.</p>
    <div class="form-row d-flex-between" *ngIf="audiencia?.suspendida">
      <div class="form-group half-width-group">
        <label for="fechaReanudacion" class="inspections-label">Fecha reanudación</label>
        <input
          type="date"
          name="fechaReanudacion"
          class="inspections-input calendar-input"
          id="fechaReanudacion"
          [(ngModel)]="audiencia.fechaReanudacion">
      </div>
      <div class="form-group half-width-group">
        <label for="horaAudiencia" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaAudiencia"
          class="inspections-input time-input"
          id="horaAudiencia"
          [(ngModel)]="audiencia.horaReanudacion">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="asisteAgente" class="inspections-label bold">¿Asiste Agente del Ministerio Público?</label>
        <div class="d-flex-start">
          <div class="d-flex-around mr-10">
            <input class="checkbox width-30" name="asisteAgente" [checked]="audiencia?.asisteAgente" (change)="changeAgentAssistance(true)" type="checkbox">
            <label class="inspections-label mb-0" for="asisteAgente">Sí</label>
          </div>
          <input
            type="text"
            [disabled]="!audiencia?.asisteAgente"
            placeholder="Escribe aquí"
            name="asisteAgente"
            class="inspections-input"
            id="asisteAgente"
            [(ngModel)]="audiencia.nombreAgente">
          <div class="d-flex-around ml-10">
            <input class="checkbox width-30" name="noAsisteAgente" [checked]="!audiencia?.asisteAgente" (change)="changeAgentAssistance(false)" type="checkbox">
            <label class="inspections-label mb-0" for="noAsisteAgente">No</label>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row d-flex-between d-start-top">
      <div class="form-group half-width-group d-flex d-start-top">
        <div class="form-group half-width-group">
          <h4 class="inspections-label bold">Municipio</h4>
          <p class="font16 mt-10">{{ fichaGeneral.municipio }}</p>
        </div>
        <div class="form-group half-width-group mb-0">
          <h4 class="inspections-label bold">Inspeccion No.</h4>
          <p class="font16 mt-10">{{ caso.numInspeccion }}</p>
        </div>
      </div>
    </div>
    <div class="form-row d-flex-between d-start-top">
      <div class="form-group half-width-group">
        <h4 class="inspections-label bold mt-0">Nombre Inspector de policía</h4>
        <p class="font16 mt-10">{{ ((inspector.nombre ? inspector.nombre + ' ' : '') + (inspector.apellido ? inspector.apellido : '')) || 'No asignado' }}</p>
      </div>
      <div class="form-group half-width-group">
        <label for="firmaInspectorPolicia" class="inspections-label bold">Firma Inspector de policía</label>
        <label for="firmaInspectorPolicia" class="file-container file-container-45 font16 b-20 d-flex">
          <p class="font16">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <input
            class="file-input"
            type="file"
            id="firmaInspectorPolicia"
            name="firmaInspectorPolicia"
            accept="image/*,application/pdf"
            (change)="onFileChange($event, 'firma-inspector')">
        </label>
        <a *ngIf="audiencia.firmaInspectorPolicia" [href]="audiencia.firmaInspectorPolicia" target="_blank">Ver</a>
      </div>
    </div>
    <div class="form-row d-flex-between-start">
      <div class="form-group half-width-group">
        <label for="nombreFuncionario" class="inspections-label bold">Nombre Funcionario</label>
        <input
          type="text"
          placeholder="Escribe aquí"
          name="nombreFuncionario"
          class="inspections-input"
          id="nombreFuncionario"
          [(ngModel)]="audiencia.nombreFuncionario">
      </div>
      <div class="form-group half-width-group">
        <label for="firmaFuncionario" class="inspections-label bold">Firma Funcionario</label>
        <label for="firmaFuncionario" class="file-container file-container-45 font16 b-20 d-flex">
          <p class="font16">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <input
            class="file-input"
            type="file"
            id="firmaFuncionario"
            name="firmaFuncionario"
            accept="image/*,application/pdf"
            (change)="onFileChange($event, 'firma-funcionario')">
        </label>
        <a *ngIf="audiencia.firmaFuncionario" [href]="audiencia.firmaFuncionario" target="_blank">Ver</a>
      </div>
    </div>
  </form>

  <!-- BOTONES -->
  <div class="buttons-container" *ngIf="newHearing || oldHearing">
    <div>
      <button class="submit-button mr-20" (click)="saveInfo()">Guardar</button>
      <!-- <button class="email-button">Enviar correo</button> -->
    </div>
    <div>
      <button class="arrow-button" *ngIf="step !== 1" (click)="changeStep(step-1)"><img class="arrow mr-10" src="/assets/img/inspecciones/arrow-left.png" alt="arrow página anterior">Anterior</button>
      <button class="arrow-button" *ngIf="step !== 3" (click)="changeStep(step+1)">Siguiente<img class="ml-10 arrow rotate180" src="/assets/img/inspecciones/arrow-left.png" alt="arrow página anterior"></button>
    </div>
  </div>
  <div *ngIf="!newHearing && !oldHearing">
    <button class="submit-button mr-20" (click)="saveToDB()">Guardar</button>
  </div>
</div>
