import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premisas-fundamentales',
  templateUrl: './premisas-fundamentales.component.html',
  styleUrls: ['./premisas-fundamentales.component.css'],
})
export class PremisasFundamentalesComponent {
  public alphabet = [
    { letter: 'a', selected: false },
    { letter: 'b', selected: false },
    { letter: 'c', selected: false },
    { letter: 'd', selected: false },
    { letter: 'e', selected: false },
    { letter: 'f', selected: false },
    { letter: 'g', selected: false },
    { letter: 'h', selected: false },
    { letter: 'i', selected: false },
    { letter: 'j', selected: false },
    { letter: 'k', selected: false },
    { letter: 'l', selected: false },
    { letter: 'm', selected: false },
    { letter: 'n', selected: false },
    { letter: 'ñ', selected: false },
    { letter: 'o', selected: false },
    { letter: 'p', selected: false },
    { letter: 'q', selected: false },
    { letter: 'r', selected: false },
    { letter: 's', selected: false },
    { letter: 't', selected: false },
    { letter: 'u', selected: false },
    { letter: 'v', selected: false },
    { letter: 'w', selected: false },
    { letter: 'x', selected: false },
    { letter: 'y', selected: false },
    { letter: 'z', selected: false },
  ];

  public glosario = [
    {
      letter: 'A',
      show: true,
      definitions: [
        {
          title: 'Actividad de policía',
          definition: `<p>
          Consiste en la materialización de las decisiones adoptadas por la autoridad cuando ejerce
          función de policía. Como quiera que se trata de acciones materiales, no implica la
          elaboración de consideraciones jurídicas. Ejemplos de actividad de policía:
          </p>
          <ul class="list-definition">
            <li>
              Imposición de un sellamiento a un establecimiento de comercio como
              materialización de una suspensión temporal o definitiva de la actividad.
            </li>
            <li>
              Demolición de una parte de un inmueble que no se ajusta a las normas
              urbanísticas, por orden del Inspector de Policía.
            </li>
            <li>
              Incautación de elementos, mercancías, alimentos, con los que se esté
              ocupando el espacio público, por parte de la policía uniformada.
            </li>
          </ul>
          <p>
          Desde el punto de vista de la doctrina de algunos autores y de la jurisprudencia, la
          actividad de policía sólo compete a la policía uniformada. Sin embargo, esta es una
          distinción entre función y actividad es más de tipo académico que legal y no comporta
          mayores efectos prácticos; tampoco implica subordinación jerárquica, pues en algunas
          ocasiones la policía uniformada ejerce función de policía, como cuando aplica medios de
          policía sin la intervención de autoridad civil (ej. Incautación) o cuando impone medidas
          correctivas (amonestación, suspensión temporal de actividad), mientras que en otros
          casos ejerce actividad de policía, como cuando materializa la orden de suspensión
          definitiva de actividad proferida por un Inspector de Policía, o cuando acompaña una
          diligencia de restitución de bien de uso público. Así mismo, en algunos casos la autoridad
          civil ejerce función de policía, como cuando impone la medida correctiva de multa, y en
          otros ejerce actividad de policía, como cuando se encarga del cobro, o cuando, a través de
          contratistas, ejecuta una orden de demolición.
          </p>`,
          show: false,
        },
        {
          title: 'Asuntos civiles de policía',
          definition: `<p>Son aquéllos conflictos relacionados con la tenencia y posesión de bienes inmuebles, y con la protección del domicilio, como la perturbación y la ocupación.</p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'C',
      show: true,
      definitions: [
        {
          title: 'Convivencia',
          definition: `<p>La convivencia tiene un alcance bastante amplio, que no se restringe al campo jurídico
          legal, y se debe comprender según el contexto en que se utilice. Pero para efectos del
          régimen de policía, es la noción que reemplaza el concepto clásico de orden público. De
          modo que se la puede definir como el conjunto de condiciones que hacen posible una
          interacción pacífica entre las personas con los bienes y con el ambiente, y comprende, al
          igual que el orden público, las categorías de seguridad, tranquilidad, salud pública y
          ambiente.</p>`,
          show: false,
        },
        {
          title: 'Comportamiento contrario a la convivencia',
          definition: `<p>
          Dado que la convivencia es definida como el conjunto de condiciones de seguridad,
          tranquilidad, salud pública y ambiente, que hacen posible la interacción pacífica entre las
          personas, los CCC son aquéllas conductas que alteran o pueden alterar esas condiciones.
          También son conocidos como motivos de policía, pues son los casos que dan lugar a la
          intervención de las autoridades de policía.
          Los CCC se clasifican y agrupan en el CNSCC de acuerdo con el bien jurídico que se
          pretende proteger:
          </p>
          <ul class="list-definition">
            <li>Vida e integridad de las personas</li>
            <li>Tranquilidad, relaciones respetuosas y privacidad</li>
            <li>
              Relaciones respetuosas con grupos sociales específicos
              <ul class="list-definition">
                <li>Niños, niñas, adolescentes</li>
                <li>Grupos de especial protección constitucional</li>
                <li>Personas que ejercen la prostitución</li>
              </ul>
            </li>
            <li>Protección de bienes inmuebles</li>
            <li>Actividad económica</li>
            <li>Ambiente y minería</li>
            <li>Salud pública</li>
            <li>Patrimonio cultural</li>
            <li>Relación con los animales</li>
            <li>Urbanismo</li>
            <li>Libertad de movilidad y circulación</li>
          </ul>`,
          show: false,
        },
        {
          title: 'Comparendo',
          definition: `<p>
          Es la herramienta documental a través de la cual se deja constancia escrita de la
          ocurrencia de un hecho que puede configurar un CCC y se cita al presunto responsable
          para que comparezca ante la autoridad competente. En el formato de comparendo se
          incorporan datos como los siguientes:
          </p>
          <ul class="list-definition">
            <li>Hecho o situación presentada: fecha, hora, dirección, descripción.</li>
            <li>Identificación y datos de ubicación del presunto responsable; razón social y actividad si se trata
              de establecimiento abierto al público.</li>
            <li> Comportamiento o comportamientos contrarios a la convivencia en los que presuntamente se incurrió,
              señalando las disposiciones normativas vulneradas.</li>
            <li>Medio o medios de policía utilizados</li>
            <li>Medida o medidas correctivas a las que puede dar lugar el CCC</li>
            <li>Declaración o versión del presunto responsable</li>
            <li>Constancia de si se objeta o no el comparendo</li>
            <li>Autoridad a la que se debe dirigir con posible fecha y hora de la citación</li>
            <li>Firma e identificación del agente uniformado y firma del citado</li>
          </ul>
          <p>
            Tratándose de CCC de competencia de las autoridades uniformadas de policía, la OC se comporta como un
            acta en la que se deja
            constancia de la situación presentada, datos del presunto responsable, descargos, medios de policía y
            medida correctiva aplicable.
            Si el asunto es de competencia del agente uniformado que elabora la orden, también puede dejarse
            constancia de los recursos interpuestos.
          </p>
          <p>
            Si el CCC es de competencia de otra autoridad uniformada o de las autoridades civiles de
            policía (Inspectores, Alcaldes), la OC constituye una especie de citación para que la
            persona comparezca ante el funcionario competente. En estos casos, cuando la medida
            correctiva señalada es multa, procede objeción ante el competente dentro de los 3 días
            siguientes. La ley 2197 de 2022 atribuye al señalamiento de multa hecho en la OC el
            alcance de medida correctiva impuesta y en firme cuando no se objeta en ese término.
            Por estas razones, el formato de comparendo debe ser claro, legible, y debe explicarse
            bien a la persona cuál es su propósito o alcance.
          </p>
          <p>No procede OC cuando se trate de CCC contra la integridad urbanística.</p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'F',
      show: true,
      definitions: [
        {
          title: 'Función de policía',
          definition: `<p>
          Es la facultad que ostentan las autoridades de policía para adoptar mecanismos que
          garanticen el ejercicio de las libertades derechos y deberes en condiciones de convivencia
          pacífica, en cumplimiento de lo señalado en las normas dictadas en ejercicio del poder de
          policía. Pueden ser de tipo reglamentario, educativo, de protección, de prevención, o de
          tipo correctivo o sancionatorio. Ejemplos del ejercicio de la función de policía son:
          </p>
          <ul class="list-definition">
            <li>La expedición de un acuerdo del concejo municipal reglamentando el uso delsuelo para la actividad de
              cría de cerdos, bares, carpinterías, etc.</li>
            <li>La realización de una brigada o un taller de forma conjunta entre el Inspector de policía, agentes
              de policía y una Junta de Acción Comunal para instruir sobre el
              adecuado uso de un parque u otra zona de uso público.
            </li>
            <li>La emisión de una orden de policía para que se retire un elemento que ocupa el espacio público.</li>
            <li>La imposición de una multa por incumplimiento de requisitos por parte de un establecimiento de
              comercio.</li>
          </ul>
          <p>
            La potestad reglamentaria también es un desarrollo de la función de policía. En su
            ejercicio, el presidente, los Gobernadores y los Alcaldes pueden expedir reglamentos de
            policía para dar alcance a las normas dictadas por virtud del poder de policía principal,
            subsidiario y excepcional. Ejemplo de reglamentos son las normas sobre pico y placa,
            horarios de establecimientos de comercio, restricciones a la movilidad en determinados
            horarios (toque de queda, aislamientos), medidas de bioseguirdad, etc.
          </p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'M',
      show: true,
      definitions: [
        {
          title: 'Mecanismos de policía',
          definition: `<p>
          Son las diferentes herramientas con que cuentan las autoridades de policía con el
          propósito de proteger las condiciones que hacen posible la convivencia. No existe en el
          régimen de policía una enunciación taxativa ni rigurosa de los mecanismos ni de su
          tipología. Sin embargo, en los artículos 8 Núm. 13, 172 y 231 encontramos pistas de los
          tipos de acciones y mecanismos según su propósito:
          </p>

          <ul class="list-definition">
            <li>Art. 8, núm. 13. Mecanismos de protección, restauración, educación, prevención.
              Estas buscan evitar que se cometa el CCC, que genere daños o que se devuelvan
              las cosas al estado anterior. Entre estos se incluyen los medios de policía que no
              implican restricción directa o inmediata de derechos o libertades (ej. La orden de
              policía, el permiso excepcional, los reglamentos, la autorización, la mediación
              policial y otro tipo de acciones no mencionadas expresamente (ej. Talleres,
              brigadas, exaltación o reconocimiento de comportamientos favorables, actas de
              compromiso ciudadano, etc.).
            </li>

            <li>Art. 172. Mecanismos correctivos. Son respuestas coercitivas o punitivas en
              contra de la persona que cometa un CCC. El concepto de prevención aquí, es el
              mismo del ámbito penal, mediante la disuasión por el temor que produce la
              sanción en el mismo infractor o en el resto de la sociedad. Aquí ubicamos todas
              las medidas correctivas y los medios de policía que implican restricción inmediata
              de derechos (retiró del sitio, traslado para procedimiento policivo, registro,
              suspensión de actividad, ingreso a inmueble, incautación, uso de la fuerza,
              aprehensión con fin judicial).
            </li>

            <li>Art. 231. Mecanismos alternativos de solución de desacuerdos y conflictos.
              Buscan que determinados conflictos se resuelvan por la vía del diálogo y la
              concertación. Están restringidos a conflictos relacionados con derechos
              renunciables y transigibles, es decir, cuando se trate de asuntos civiles y conflictos
              entre dos o más personas. Se mencionan la mediación y la conciliación.
              Aun cuando existe una amplitud y diversidad de mecanismos, según el texto del artículo
              8 numeral 13, siempre se debe intentar primero implementar mecanismos de prevención,
              restauración, educación y prevención, y los alternativos, antes que los de tipo correctivo.
            </li>
          </ul>`,
          show: false,
        },
        {
          title: 'Medios de policía',
          definition: `<p>
            Son mecanismos con los que cuentan las autoridades de policía para garantizar las
            condiciones de seguridad, tranquilidad, salud pública y ambiente. Algunos de estos
            mecanismos no implican coerción ni restricción inmediata de derechos o libertades (ej. La
            orden de policía, el permiso excepcional, los reglamentos, la autorización, la mediación
            policial) mientras que otros sí (retiro del sitio, traslado para procedimiento policivo,
            registro, suspensión de actividad, ingreso a inmueble, incautación, uso de la fuerza,
            aprehensión con fin judicial). Se hace énfasis en la palabra ‘inmediata’ porque estos
            medios también pueden implicar restricciones a derechos y coerción, pero siempre que se
            cumplan algunas condiciones: por ejemplo, el reglamento que regula el pico y placa
            conlleva una restricción a la movilidad de vehículos automotores en abstracto, pero para
            que se haga efectiva, una persona tiene que tener un vehículo con determinada placa y
            que se encuentre en el horario y día en que aplica la restricción.
          </p>
          <p>
            Para la imposición de los medios de policía no existe un procedimiento único o estándar,
            sino que deben atenderse las formalidades señaladas en el mismo CNSCC dependiendo
            del medio de que se trate. Por ejemplo, para proferir una orden de policía, un permiso,
            una autorización o un reglamento debe expedirse un acto motivado. En el caso de la
            mediación, debe elaborarse un acta donde conste la reunión y el eventual arreglo. Igual
            sucede con los medios coercitivos.
          </p>`,
          show: false,
        },
        {
          title: 'Medidas correctivas',
          definition: `<p>
            Son mecanismos que implican coerción y, en algunos casos, sanción, en cuanto conllevan
            la restricción de derechos y libertades y el uso de la fuerza estatal para hacerlos cumplir
            aun contra la voluntad del destinatario.
          </p>
          <p>
            Una medida correctiva es sancionatoria cuando genera una afectación mayor a los
            derechos y bienes de la persona, es decir, un castigo. Este es el caso de la multa, que
            implica una afectación al patrimonio.
          </p>
          <p>
            El CNSCC señala en varios artículos que las medidas correctivas no son sanciones. Sin
            embargo, el carácter sancionador de una medida no se deriva de su elaboración teórica y
            del texto legal, sino de sus implicaciones prácticas. Por ejemplo, la suspensión definitiva
            de una actividad de comercio, por mucho que la norma diga que es una medida
            preventiva, implica una afectación a los bienes y a los ingresos de su propietario,
            poseedor o tenedor, más allá de la imposibilidad de seguir desarrollando la actividad, y
            como tal comporta un carácter sancionador.
          </p>
          <p>
            Es por eso que para imponer una medida correctiva se requiere adelantar un
            procedimiento con unas etapas previamente establecidas, en las que se respete el debido
            proceso y el derecho de defensa de la persona investigada. Además, debe contemplar una
            segunda instancia y le son aplicables principios típicos del derecho penal, como el non bis
            in ídem (derecho a no ser juzgado dos veces por el mismo hecho), la presunción de
            inocencia y la no reformatio in pejus (el superior no puede agravar la sanción cuando el
            apelante es único).
          </p>`,
          show: false,
        },
        {
          title: 'Mecanismos alternativos',
          definition: `<p>De acuerdo con el CNSCC, son mecanismos alternativos de solución de desacuerdos y
          conflictos, la conciliación y la mediación. Están restringidos a derechos renunciables y
          transigibles, como es el caso de los asuntos civiles de policía. Se pueden intentar en
          cualquier momento de la actuación de la autoridad de policía.</p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'O',
      show: true,
      definitions: [
        {
          title: 'Orden público',
          definition: `<p>En la doctrina clásica del derecho de policía, el orden público es el conjunto de
          condiciones que se deben reunir para que exista una convivencia pacífica, siendo las
          principales la seguridad, la tranquilidad y la salubridad; a estos tres se sumaron por vía de
          doctrina otros elementos, como el urbanismo y el medio ambiente.
          Sin embargo, con la entrada en vigencia del CNSCC, se quiso cambiar esa noción
          sustituyéndola por la de convivencia por estimarse que generaba menos prevención pues
          al orden público se le relacionaba con las zonas del territorio nacional donde se producían
          enfrentamientos con los grupos armados ilegales. De modo que dicha expresión es
          utilizada en el mencionado código en muy pocos casos.</p>`,
          show: false,
        },
        {
          title: 'Orden de policía',
          definition: `<p>
            La orden de policía es, en esencia, un medio de policía que consiste en un mandato claro
            y preciso que se utiliza para prevenir o superar comportamientos o hechos contrarios a la
            convivencia o para restablecerla. No es, en principio, un medio coercitivo en tanto no
            implica necesariamente la restricción de derechos o libertades.
            La orden puede ser verbal, escrita o, en algunos casos, gestual, como cuando un agente
            uniformado le indica a una persona o un vehículo que se detenga solo señalando con su
            brazo y mano.
          </p>
          <p>
            A pesar de ser un mecanismo muy útil y práctico, es poco utilizado, porque existe la
            tendencia a considerar que la imposición directa de medidas correctivas y sanciones es un
            método más eficaz para garantizar la convivencia, aunque esto no esté demostrado.
            El CNSCC contiene textos que generan confusión sobre las diferencia entre una orden
            como medio de policía y una medida correctiva. Es el caso del parágrafo del artículo 150
            en el que se señala que “el incumplimiento de la orden de Policía mediante la cual se
            imponen medidas correctivas configura el tipo penal establecido para el fraude a
            resolución judicial o administrativa de Policía”.
          </p>
          <p>
            Al respecto es importante precisar, como ha hecho la Corte Constitucional, que existen
            tres tipos de órdenes de policía dependiendo de la forma de su cumplimiento: la orden
            de policía de cumplimiento inmediato, producto de un PVI; la orden producto de un
            PVA, que se debe cumplir 5 días después de quedar en firme la decisión de fondo; y la
            orden con conminación, en la que se debe señalar un plazo determinado ante la
            imposibilidad de su cumplimiento inmediato. Según lo anterior, la conminación puede
            acompañar a la orden tanto en el PVI como en el PVA [1] ; este último tipo de orden se
            deriva del siguiente texto (subrayado) del segundo inciso del artículo 150 del CNSCC,
            que fue declarado exequible por el alto tribunal:
          </p>
          <p>
            Las órdenes de Policía son de obligatorio cumplimiento. Las personas que las desobedezcan
            serán obligadas a cumplirlas a través, si es necesario, de los medios, medidas y
            procedimientos establecidos en este Código. Si la orden no fuere de inmediato
            cumplimiento, la autoridad conminará a la persona para que la cumpla en un plazo
            determinado, sin perjuicio de las acciones legales pertinentes.
          </p>
          <p>
            La anterior precisión es importante porque existe un CCC relativo al incumplimiento de
            la orden de policía que da lugar a la imposición de medidas correctivas, consagrado en el
            artículo 35 número 2 del CNSCC. Entonces, una cosa es la orden de policía (que se
            puede incumplir) y otra cosa la medida correctiva (que se puede imponer por el
            incumplimiento de aquélla).
          </p>
          <p>[1] Cfr. Sentencia C-391-17.</p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'P',
      show: true,
      definitions: [
        {
          title: 'Poder de policía',
          definition: `<p>
            Es la facultad de expedir normas obligatorias, generales y abstractas para regular el
            ejercicio de las libertades, los deberes y derechos para la convivencia, y para establecer
            los comportamientos contrarios a la convivencia, los medios y las medidas que se pueden
            imponer cuando resulte alterada.
          </p>
          <p>El poder de policía principal lo ostenta el Congreso de la República, siendo la única
            autoridad que puede crear medios y medidas que tengan un carácter correctivo o
            sancionatorio. Un ejemplo de norma expedida en desarrollo de este poder es el CNSCC.
            Las asambleas departamentales y el Concejo de Bogotá ejercen un poder de policía
            subsidiario, mientras que los concejos municipales ejercen un poder residual. Pueden
            regular el ejercicio de libertades, deberes y derechos en el ámbito territorial, siempre que
            no exista reserva legal y no lo haya hecho el Congreso de la República. En el caso de los
            concejos municipales, deben ceñirse a lo establecido en la ley y en las ordenanzas. Un
            ejemplo de norma expedida por vía del poder subsidiario son las ordenanzas
            departamentales que adoptan los PISCC, algunas se refieren a normas de convivencia
            sobre el espacio público. Y un ejemplo de norma expedida por poder residual son los
            Acuerdos o decretos que adoptan los planes de ordenamiento territorial.
          </p>
          <p>
            El Presidente de la República ejerce un poder de policía excepcional, cuando expide
            decretos legislativos por virtud de los estados de excepción. Un ejemplo de este poder
            excepcional fue el Decreto por el cual se declaró el estado de emergencia económica,
            social y ecológica en el año 2020 para conjurar la crisis que afectó al país por causa del
            coronavirus COVID-19.
          </p>`,
          show: false,
        },
        {
          title: 'Procedimiento Verbal Inmediato',
          definition: `<p>
            Es la acción de policía que se adelanta para la imposición de órdenes de policía y medidas
            correctivas en caso de CCC de competencia de las autoridades uniformadas de policía
            (comandantes de estación, subestación y de centro de atención inmediata de Policía y
            demás personal uniformado de la Policía Nacional).
          </p>
          <p>
            Durante el procedimiento se debe identificar al presunto infractor, escucharlo en
            descargos, e intentar una mediación policial.
            Contra la decisión tomada, procede recurso de apelación en el efecto devolutivo ante el
            Inspector de Policía.
          </p>`,
          show: false,
        },
        {
          title: 'Procedimiento Verbal Abreviado',
          definition: `<p>Es la acción de policía que se adelanta para la imposición de medidas correctivas en caso
          de CCC de competencia de los Inspectores de Policía.
          Durante el procedimiento se debe identificar al presunto infractor, practicar una
          audiencia pública en la que se le escuche y se practiquen pruebas.
          Contra la decisión tomada, procede recurso de apelación en el efecto devolutivo ante la
          autoridad administrativa especial de policía o ante el Alcalde municipal en su defecto. En
          el caso de CCC en materia de integridad urbanística, el recurso se concede en efecto
          suspensivo.</p>`,
          show: false,
        },
      ],
    },
    {
      letter: 'R',
      show: true,
      definitions: [
        {
          title: 'Régimen de Policía',
          definition: `<p>La expresión hace referencia al conjunto de disposiciones normativas que regulan las
          condiciones que hacen posible la convivencia, así como los mecanismos y acciones que
          pueden adelantar las autoridades de policía en el ejercicio de su labor.
          Para los fines del presente módulo, se prefiere la noción de Régimen de Policía y no la de
          Derecho de policía, pues esta suele confundirse con un campo del conocimiento jurídico
          que tiene una pretensión de autonomía, cuestión que es polémica y muy discutida en la
          doctrina pues para algunos es una rama autónoma del derecho, mientras que para otros
          hace parte del derecho administrativo general y sancionador.</p>`,
          show: false,
        },
      ],
    },
  ];

  selectedPhase = 1;
  resultSearch = [];
  constructor() {}

  showFloatingCard() {
    let floatingCard = document.getElementById('floating-card');
    // floatingCard.style.display = "block";
    // floatingCard.style.transition = "top 5s ease";
  }

  hideFloatingCard() {
    let floatingCard = document.getElementById('floating-card');
    // floatingCard.style.display = "none";
  }

  /**
   * Busca a partir de la letra en la lista del glosario y se encarga de modificar el estado de los estilos de la letra seleccionada, no permite seleccionar más de una letra.
   * @param index El índice de la letra en la lista de letras
   */
  searchByLetter(index: number) {
    this.alphabet[index].selected = !this.alphabet[index].selected;
    if (this.alphabet[index].selected) {
      this.alphabet.forEach((letter, i) => {
        if (i !== index) {
          letter.selected = false;
        }
      });
      const indexFound = this.glosario.findIndex(
        (g) => g.letter.toLowerCase() == this.alphabet[index].letter
      );
      if (indexFound != -1) {
        this.glosario.forEach((g) => (g.show = false));
        this.glosario[indexFound].show = true;
      } else {
      }
    } else {
      this.glosario.forEach((g) => (g.show = true));
    }
  }

  /**
   * Se encarga de hacer una búsqueda por las definiciones a partir del texto que el usuario deje
   * @param {string} search El texto de búsqueda
   */
  searchFromText(search: string) {
    this.glosario.forEach((g) => {
      const found = g.definitions.some((def) =>
        def.title.toLowerCase().includes(search.toLowerCase())
      );
      g.show = found;
    });
  }

  openModal(id) {
    let pdf = document.getElementById('pdf');
    let txt = '';
    pdf.innerHTML = txt;
    if (id == 'A') {
      txt = `<h4 style = "font-weight: bold;">Asertividad
      </h4><br><video controls style = "width: 60%;" preload="none" poster="/assets/img/inspecciones/Asertividad Background.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/NXGSiRYRJDOigfq"  type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;"><span>¡Un gusto verte de nuevo!</span> Este video explicará brevemente qué es la asertividad, por qué es importante para tu quehacer, y te dará algunas recomendaciones prácticas para fortalecerla en tu día a día. </p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">La empatía es la capacidad para sentir lo que otras personas sienten, o sentir algo compatible con la situación que otra persona esté viviendo. Por ejemplo, alguien demuestra empatía si se siente mal cuando ve sufrir a otras personas o si se alegra con lo bueno que le pasa a alguien. En cambio, alguien demuestra falta de empatía cuando no siente nada, o incluso se alegra, al ver a otras personas sufrir.</p>
      `;
      pdf.innerHTML = txt;
    }
    if (id == 'B') {
      txt = `<h4 style = "font-weight: bold;">Empatía
      </h4><br><video controls style = "width: 60%;" preload="none" poster="/assets/img/inspecciones/portada-empatia.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/Ndki7fvOgn6yBf1" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left; font-size:15px;"><span>¡Hola!</span> En este video te explicaremos qué es la empatía y por qué es relevante para tu labor, y brindaremos algunas recomendaciones sencillas para fortalecerla en tu actividad como inspector.</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">La empatía es la capacidad para sentir lo que otras personas sienten, o sentir algo compatible con la situación que otra persona esté viviendo. Por ejemplo, alguien demuestra empatía si se siente mal cuando ve sufrir a otras personas o si se alegra con lo bueno que le pasa a alguien. En cambio, alguien demuestra falta de empatía cuando no siente nada, o incluso se alegra, al ver a otras personas sufrir.</p>
      `;
      pdf.innerHTML = txt;
    }
    if (id == 'C') {
      txt = `<h4 style = "font-weight: bold;">Trabajo en equipo
      </h4><br><video controls style = "width: 60%;" preload="none" poster="/assets/img/inspecciones/portada-trabajo-en-equipo.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/wZMT2HPGtJ2f4U0" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left; font-size:15px;"><br><span>¡Hola!</span> Aquí estaremos explicando qué es el trabajo en equipo, su importancia para tu labor, y algunas recomendaciones sencillas que puedes seguir en tu día a día para fortalecerlo.</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">El trabajo en equipo es la capacidad de trabajar de manera constructiva con otras personas, partiendo de interesarse genuinamente por conocer las diferentes formas de trabajo, posiciones y visiones para realizar una tarea, reconociendo la diversidad y teniendo la disposición de formular una acción conjunta.</p>
      `;
      pdf.innerHTML = txt;
    }
    if (id == 'D') {
      txt = `<h4 style = "font-weight: bold;">Toma de decisiones
      </h4><br><video controls style = "width: 60%;" preload="none" poster="/assets/img/inspecciones/portada-toma-de-decisiones.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/6edbMqtZkr19CAd" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><p style = "text-align: left; font-size:15px;"><span>¡Saludos!</span> Este video te guiará sobre la toma de decisiones, empezando por explicar qué es, y luego mostrando su importancia para la labor de inspector de policía, como también algunas recomendaciones prácticas para ejercer una mejor toma de decisiones.</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">a toma de decisiones es la habilidad para elegir dentro de diferentes opciones, definiendo y analizando la situación, tomando perspectiva, generando opciones y considerando consecuencias, para actuar y luego evaluar lo ocurrido.</p>
      `;
      pdf.innerHTML = txt;
    }
    if (id == 'E') {
      txt = `<h4 style = "font-weight: bold;">Liderazgo
      </h4><br><video controls style = "width: 60%;" preload="none" poster="/assets/img/inspecciones/portada-liderazgo.png"><source src="https://fip-recursos.s3.amazonaws.com/videos/ShP0VPLIG0cjNHh" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><p style = "text-align: left; font-size:15px;"><span>¡Un gusto verte!</span> En este video explicaremos cómo mejor definir el liderazgo, por qué es importante para un inspector de policía, y cómo mejorarlo en tu día a día.</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">El liderazgo es la influencia interpersonal ejercida en una situación, dirigida a través del proceso de comunicación humana, a la consecución de uno o diversos objetivos específicos. Aunque ha sido definido de diversas maneras, se destaca  la  capacidad del  líder de guiar  <br> de forma natural a su equipo,  así como de conseguir los objetivos comunes o mejorar el rendimiento de las personas.</p>
      `;
      pdf.innerHTML = txt;
    }

    if (id == 1) {
      txt = `<h4 style = "font-weight: bold;">Comunicación eficaz (oral y escrita), sostenibilidad y compromiso social
      </h4><br><video controls style = "width: 60%;" preload = "none"  poster="/assets/img/inspecciones/portada-comunicacion-eficaz.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/k0CM5jptihqoWyu" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;"><span> ¡Hola! </span> A través del siguiente video, exploraremos brevemente dos habilidades técnicas cruciales para desempeñar la labor de inspector de policía.</p>
      <p style = "text-align: left; font-size:15px;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">La primera de estas habilidades es la comunicación eficaz. Ésta es la capacidad de comunicación efectiva tanto oral  como escrita, en expresión y comprensión, con especial énfasis en la redacción de documentación técnica. Esta habilidad implica poder comunicarse efectivamente con el usuario en un lenguaje no técnico, comprender sus necesidades, y argumentar y justificar lógicamente las decisiones tomadas y las opiniones.</p>
      <p style = "text-align: left; font-size:15px; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Por otro lado, tenemos la sostenibilidad y el compromiso social. Esto incluye la capacidad para actuar en el desarrollo profesional con responsabilidad y ética profesional y de acuerdo con la legislación vigente. También incluye una consideración del contexto económico y social en las soluciones, siendo consciente de la diversidad y la multiculturalidad, y garantizando la sostenibilidad y el respeto a los derechos humanos.</p>
      <p style = "text-align: left; font-size:15px; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">En el resto de esta sección de habilidades técnicas, encontrarás otros recursos útiles para comprender términos y conceptos esenciales para la labor de los inspectores e inspectoras de policía. ¡No olvides consultarlos!</p></div>
      `;
      pdf.innerHTML = txt;
    } else if (id == 2) {
      txt = `<h4 style = "font-weight: bold;">Objetivos, principios, deberes de autoridades de policía y atribuciones de inspectores
      </h4><br><video controls style = "width: 60%;" preload = "none"  poster="/assets/img/inspecciones/portada-objetivos-principios.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/JZ1tkqPtBhSFha5" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;"><span>¡Bienvenidos! Empezaremos por delinear los objetivos y principios consignados en el Código Nacional de Seguridad y Convivencia Ciudadana, como también los deberes de las autoridades de policía y las atribuciones de los inspectores urbanos, rurales y corregidores.</p>
      <p style = "text-align: left; font-size:15px;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Según el artículo 1 del Código, sus disposiciones son de carácter preventivo y buscan establecer las condiciones para la convivencia en el territorio nacional, como también determinar el ejercicio del poder, la función y la actividad de Policía, conforme a la Constitución Política y el ordenamiento jurídico vigente. Enseguida, se plantean objetivos específicos relacionados a favorecer la convivencia, promover el respeto y ejercicio responsable de los derechos, la conciliación y solución pacífica de desacuerdos, y establecer la competencia y debido proceso de las autoridades de policía y sus medidas y procedimientos.</p>
      <p style = "text-align: left; font-size:15px; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">El Código consagra unos principios importantes, como la protección de la vida y los derechos humanos, la igualdad ante la ley, el reconocimiento y respeto a la diversidad y la no discriminación, el debido proceso, la solución pacífica de los conflictos y el respeto al ordenamiento jurídico y las autoridades legales. La adopción de medios de policía y medidas correctivas debe ser proporcional y razonable según las circunstancias de cada caso y la finalidad de la norma, y se deben aplicar cuando otros mecanismos resulten ineficaces para alcanzar el fin propuesto.</p>
      <p style = "text-align: left; font-size:15px; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Entre los múltiples deberes de las autoridades de policía que lista el Código, se destacan el respetar y hacer cumplir la Constitución Política, prevenir situaciones y comportamientos que pongan en riesgo la convivencia y promover los mecanismos alternativos de resolución de conflictos y propiciar el diálogo. También se incluye recibir y atender de manera oportuna y eficiente las quejas, peticiones y reclamos, dando el mismo trato a todas las personas, colaborando con las autoridades judiciales, observando los procedimientos del Código y aplicando las normas de policía con transparencia, eficacia, celeridad y públicamente.</p>
      <p style = "text-align: left; font-size:15px; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">El Código también plantea las atribuciones de los inspectores de policía urbanos, rurales y corregidores. La primera es conciliar para solucionar conflictos de convivencia, seguida por conocer los comportamientos contrarios a la convivencia, así como ejecutar la orden de restitución en casos de tierras comunales.</p></div>
      <p style = "text-align: left;  font-size:15px;height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">El Código llama a los inspectores a conocer de la aplicación de medidas correctivas como reparación de daños materiales de muebles o inmuebles, expulsiones de domicilio, prohibición de ingreso a actividades que involucran aglomeraciones de público y decomisos. Los inspectores también deben conocer en primera instancia de la aplicación de medidas como suspensión o demolición de construcciones, reparación de daños materiales por perturbación a la posesión y tenencia de inmuebles y su restitución y protección, el restablecimiento del derecho de servidumbre, remoción de bienes en infracciones urbanísticas, multas o suspensiones definitivas de actividad. </p></div>
      `;
      pdf.innerHTML = txt;
    } else if (id == 3) {
      txt = `<h4 style = "font-weight: bold;">Régimen, poder, función y actividad de policía
      </h4><br><video controls style = "width: 60%;" preload = "none"  poster="/assets/img/inspecciones/portada-regimen-poder-funcion.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/JZ1tkqPtBhSFha5" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">¡Bienvenidos nuevamente! En este video, introduciremos las expresiones de régimen, poder, función y actividad de policía.</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Régimen de policía se refiere a las disposiciones normativas que regulan las condiciones que posibilitan la convivencia, como los mecanismos y acciones que pueden adelantar las autoridades de policía al ejercer su labor.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">El poder de policía es la facultad de expedir normas para regular el ejercicio de libertades, deberes y derechos para la convivencia, y para establecer tanto los comportamientos contrarios a la convivencia como los medios y medidas que se pueden imponer cuando ésta resulta alterada. El poder de policía principal lo ostenta el Congreso de la República, ya que es la única autoridad que puede crear medios o medidas con carácter correctivo o sancionatorio, como es el caso del Código Nacional de Seguridad y Convivencia Ciudadana.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Las asambleas departamentales ejercen un poder subsidiario y los concejos municipales tienen un poder residual, por lo que deben ceñirse a lo establecido por la ley para expedir ordenanzas departamentales, acuerdos o decretos. El Presidente de la República ejerce un poder de policía excepcional al expedir decretos legislativos en estados de excepción, como por ejemplo, el decreto por el cual se declaró el estado de emergencia económica, social y ecológica en 2020 para responder a la crisis causada por el COVID-19. </p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">La función de policía es la facultad de autoridades de policía para adoptar mecanismos reglamentarios, educativos, de protección y prevención que garanticen la convivencia, como acuerdos del concejo municipal, brigadas o talleres conjuntos, órdenes de policía, o multas. Las fuentes que tienen este poder suelen expedir reglamentos como normas sobre pico y placa, horarios de establecimientos comerciales, restricciones a la movilidad y medidas de bioseguridad, que también hacen parte de la función de policía.</p></div>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">La actividad de policía es cuando se realizan acciones para materializar las decisiones que se adoptan cuando una autoridad ejerce la función de policía. Por ejemplo, al imponer sellamientos de establecimientos comerciales para materializar la suspensión de una actividad, la demolición de una parte de un inmueble que no se ajusta a normas urbanísticas, o la incautación de elementos con los que se esté ocupando el espacio público por parte de la policía uniformada.</p></div>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">La actividad de policía no compete únicamente a la policía uniformada. Las autoridades civiles como los inspectores de policía pueden ejercer esta actividad, por ejemplo, al encargarse de cobros o ejecutar una orden de demolición a través de contratistas.</p></div>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">En ocasiones la policía uniformada puede ejercer la actividad de policía sin intervención de la autoridad civil, como en el caso de incautaciones, y en otras ocasiones, puede ejercer esta actividad con intervención de la autoridad civil, como al materializar una orden de suspensión definitiva de actividad emitida por un inspector o acompañar una diligencia de restitución de bien de uso público.</p></div>
      `;
      pdf.innerHTML = txt;
    } else if (id == 4) {
      txt = `<h4 style = "font-weight: bold;">Orden público, convivencia, comportamientos contrarios a la convivencia y asuntos civiles de policía.
      </h4><br><video controls style = "width: 60%;" preload = "none"  poster="/assets/img/inspecciones/portada-orden-publico.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/sf18YAttCcCp0Qu" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">¡Bienvenidos! Explicaremos brevemente qué es orden público, convivencia, y qué son los comportamientos contrarios a la convivencia y los asuntos civiles de policía!</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">El orden público es el conjunto de condiciones que se deben reunir para que exista una convivencia pacífica, incluyendo la seguridad, tranquilidad, salubridad, y recientemente, el urbanismo y medio ambiente. Con la entrada en vigencia del Código Nacional de Seguridad y Convivencia Ciudadana se quiso cambiar esta noción y sustituirla por la de “convivencia”, de modo que la expresión de “orden público” se utiliza en pocos casos dentro del Código.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Así, la convivencia, para efectos del régimen de policía, es el conjunto de condiciones que posibilitan una interacción pacífica entre las personas con los bienes y el entorno, comprendiendo las categorías de seguridad, tranquilidad, salud pública y ambiente. Los asuntos civiles de policía son los conflictos relacionados con la tenencia y posesión de bienes inmuebles, y con la protección del domicilio.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Los comportamientos contrarios a la convivencia son conductas que alteran o pueden alterar esas condiciones. En algunos casos también se conocen como motivos de policía, pues dan lugar a la intervención de las autoridades de policía. </p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">Estas conductas se agrupan en el Código según el bien jurídico que se pretende proteger. Incluyen aspectos como la vida e integridad de las personas; la tranquilidad, relaciones respetuosas y privacidad, incluyendo con grupos de especial protección constitucional; la protección de bienes inmuebles; la actividad económica; la salud pública y el medio ambiente; y la libertad de movilidad y circulación.</p></div>
      `;
      pdf.innerHTML = txt;
    } else if (id == 5) {
      txt = `<h4 style = "font-weight: bold;">Mecanismos, acción, medios y orden de policía
      </h4><br><video controls style = "width: 60%;"  preload = "none"  poster="/assets/img/inspecciones/portada-mecanismos-accion.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/Ds1PJxDbfK0Z0c6" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">¡Un gusto verlos de nuevo! Estaremos explorando brevemente qué son los mecanismos, acción, medios y orden de policía.</p><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Los mecanismos de policía son las herramientas con las que cuentan las autoridades de policía para proteger la convivencia. Aunque no existe en el régimen de policía una enunciación rigurosa de estos mecanismos, el Código Nacional de Seguridad y Convivencia Ciudadana ofrece algunas ejemplos, como, por ejemplo: los mecanismos de protección, restauración, educación y prevención; los mecanismos correctivos; y los mecanismos alternativos de solución de conflictos. </p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">La acción de policía es un tipo de mecanismo de policía, que consiste en adelantar un procedimiento formal para la imposición de medidas correctivas y medios de policía coercitivos. Por su parte, los medios de policía son mecanismos con los que cuentan las autoridades de policía para garantizar las condiciones de seguridad, tranquilidad, salud pública y ambiente. Algunos de estos medios no implican coerción ni restricción inmediata de derechos o libertades, mientras que otros sí.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Para la imposición de los medios de policía, deben atenderse las formalidades señaladas en el Código Nacional de Seguridad y Convivencia Ciudadana dependiendo del medio de que se trate. Por ejemplo, para proferir una orden de policía, un permiso, una autorización o un reglamento debe expedirse un acto motivado, mientras que en el caso de una mediación, debe elaborarse un acta donde conste la reunión y el eventual arreglo.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">La orden de policía es un medio de policía que consiste en un mandato claro y preciso que se utiliza para prevenir o superar comportamientos contrarios a la convivencia. Esta orden puede ser verbal, escrito, o en algunos casos gestual (como cuando un agente uniformado le indica a una persona o vehículo que se detenga, señalando con su brazo y mano). A pesar de ser un medio útil y práctico es poco utilizado, porque se considera que la imposición directa de medidas correctivas y sanciones es un método más eficaz para garantizar la convivencia, aunque esto no esté demostrado.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">El Código contiene textos que generan confusión sobre la diferencia entre una orden de policía como medio de policía y como medida correctiva. La Corte Constitucional ha aclarado que existen tres tipos de órdenes de policía: la orden de policía de cumplimiento inmediato, producto de un Procedimiento Verbal Inmediato; la orden producto de un Procedimiento Verbal Abreviado, que se debe cumplir 5 días después de quedar en firma la decisión de fondo; y la orden con conminación, que puede acompañar a la orden en ambos tipos de Procedimiento, en la que se debe señalar un plazo determinado ante la imposibilidad de su cumplimiento inmediato. Así, una cosa es la orden de policía y otra cosa es la medida correctiva que se puede imponer al incumplimiento de dicha orden. </p></div>
      `;
      pdf.innerHTML = txt;
    } else if (id == 6) {
      txt = `<h4 style = "font-weight: bold;">Medidas correctivas, orden de comparendo, Procedimiento Verbal Inmediato, Procedimiento Verbal Abreviado y mecanismos alternativos
      </h4><br><video controls style = "width: 60%;" preload = "none"  poster="/assets/img/inspecciones/portada-medidas-correctivas.png"> <source src="https://fip-recursos.s3.amazonaws.com/videos/XdRhpNMEeYjC5CM" type="video/mp4"> </video>
      <div style="overflow-y: scroll; height:200px; margin-top: 2%; margin-bottom: 2%;"><br><p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">¡Hola! Estaremos revisando qué son las medidas correctivas, órdenes de comparendo y los mecanismos alternativos, como también contrastando el Procedimiento Verbal Inmediato y el Procedimiento Verbal Abreviado</p>
      <p style = "text-align: left;  height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Las medidas correctivas implican coerción y en algunos casos sanción, ya que pueden restringir derechos y libertades y utilizar la fuerza estatal, aún contra la voluntad del destinatario. Éstas son sancionatorias cuando generan una afectación a los derechos y bienes de una persona.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Para imponer una medida correctiva se debe adelantar un procedimiento, con unas etapas previamente establecidas, donde se respete el debido proceso y el derecho a la defensa de la persona investigada. También se debe contemplar una segunda instancia y aplicar principios típicos del derecho penal, como el derecho a no ser juzgado dos veces por el mismo hecho, la presunción de inocencia y que el superior no puede agravar la sanción cuando el apelante es único.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px;">Una orden de comparendo es la herramienta documental a través de la cual se deja constancia escrita de la ocurrencia de un hecho que puede configurar un comportamiento contrario a la convivencia, y que cita al presunto responsable a comparecer ante la autoridad competente. Cuando el comportamiento es de competencia de las autoridades uniformadas de policía, la orden de comparendo se comporta como un acta en la que se deja constancia de la situación presentada con los datos correspondientes. Si el comportamiento es competencia de otra autoridad uniformada o de las autoridades civiles de policía (como los inspectores de policía o alcaldes), la orden constituye una citación para que el responsable comparezca ante el funcionario competente. Por estas razones, el formato de la orden debe ser claro, legible, y se debe explicar bien al responsable cuál es su propósito o alcance.</p>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">El Procedimiento Verbal Inmediato es la acción de policía que se adelanta para imponer ordenes de policía y medidas correctivas cuando el comportamiento contrario a la convivencia es competencia de las autoridades uniformadas de policía, incluyendo comandantes de estación, subestación y Centros de Atención Inmediata de la Policía Nacional. En contraste, el Procedimiento Verbal Abreviado es la acción de policía que se adelanta para imponer medidas correctivas en caso que el comportamiento contrario a la convivencia sea competencia de los inspectores de policía. En el Procedimiento Verbal Abreviado, se debe identificar al presunto infractor, practicar una audiencia pública donde se le escuche y se practiquen pruebas. Contra la decisión tomada, se puede generar un recurso de apelación.</p></div>
      <p style = "text-align: left; height: auto; word-wrap: break-word; font-family: Helvetica; font-size: 15px; margin-bottom: 8%;">Los mecanismos alternativos incluyen la conciliación y mediación para la solución de desacuerdos y conflictos, y aunque están restringidos a derechos renunciables y transigibles, se pueden intentar en cualquier momento de la actuación de la autoridad de policía.</p></div>
      `;
      pdf.innerHTML = txt;
    }
    let modal_container = document.getElementById(
      'modal-bibliografy-container'
    );
    let close = document.getElementById('close');

    modal_container.classList.add('show');
  }

  closeModal() {
    let modal_container = document.getElementById(
      'modal-bibliografy-container'
    );
    let close = document.getElementById('close');

    modal_container.classList.remove('show');
  }
}
