<div class="form-container">
  <div class="form-header go-top">
    <div class="left-side mr-10">
      <h2 class="form-title align-left">Diagnóstico</h2>
    </div>
    <div class="d-flex-start">
      <button *ngIf="wantEditActivity || isNewActivity" class="cancel-button mr-10" (click)="cancelEdit()">Salir de {{ isNewActivity ? 'creación' : 'edición' }}</button>
      <!-- <button class="download-button">
        <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
      </button> -->
    </div>
  </div>

  <h3 class="form-description">A continuación incluya las actividades desarrolladas por la Inspección de Policía para determinar las posibles causas del comportamiento que ha sido puesto en conocimiento</h3>
  <h3 class="font18 bold mt-0">Lista de actividades</h3>
  <div class="activities-list width-70">
    <div class="list" *ngIf="activities?.length > 0; else noActividades">
      <div class="list-row d-flex-between mb-10" *ngFor="let act of activities; index as i">
        <div class="list-detail min-100per">
          <span>{{ 'Actividad ' + (i+1) + ' / ' + formatDate(act.fecha) }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editActivity(act)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
            <button class="selectable-button" (click)="deleteActivity(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noActividades>
      <div class="width-70">
        <p class="text">
          No tienes ninguna actividad creada
        </p>
      </div>
    </ng-template>
  </div>
  <div>
    <button *ngIf="!isNewActivity && !wantEditActivity" class="aggregate-button mb-20" (click)="newActivity()">+Nueva actividad</button>
  </div>

  <form class="phase-form" #diagnostic="ngForm">
    <div *ngIf="wantEditActivity || isNewActivity" class="top-line">
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <label class="inspections-label" for="tipoCaso">Tipo de caso</label>
          <ngx-select-dropdown class="inspections-select" name="tipoCaso" [(ngModel)]="tipoCaso" [config]="config" [options]="tiposCasos">
          </ngx-select-dropdown>
        </div>
        <div class="form-group half-width-group">
          <label for="casosAsociados" class="inspections-label">Casos asociados</label>
          <ngx-select-dropdown class="inspections-select" name="casosAsociados" multiple="true" [(ngModel)]="casosAsociadosSeleccionados" [config]="configCasosAsociados" [options]="casosAsociados">
          </ngx-select-dropdown>
        </div>
      </div>
      <section *ngIf="infractors.length > 0">
        <h3 class="section-text">Presunto(s) infractor(es)</h3>
        <div class="form-row d-flex-between">
          <p class="half-width-group font16">Nombre</p>
          <p class="half-width-group font16">Apellido</p>
        </div>
        <div *ngFor="let infractor of infractors" class="form-row d-flex-between">
          <p class="half-width-group font16 gray-text">{{ infractor.nombreInfractor }}</p>
          <p class="half-width-group font16 gray-text">{{ infractor.apellidoInfractor }}</p>
        </div>
      </section>
      <h3 class="section-text">Actividad desarrollada</h3>
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <label for="fechaActividadDesarrollada" class="inspections-label">Fecha*</label>
          <input
            class="inspections-input calendar-input"
            placeholder="dd/mm/aaaa"
            type="date"
            name="fechaActividadDesarrollada"
            id="fechaActividadDesarrollada"
            [(ngModel)]="activity.fecha">
        </div>
        <div class="form-group half-width-group">
          <label for="horaActividadDesarrollada" class="inspections-label">Hora</label>
          <input
            type="time"
            name="horaActividadDesarrollada"
            class="inspections-input time-input"
            id="horaActividadDesarrollada"
            [(ngModel)]="activity.hora">
        </div>
      </div>
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <label for="direccionActividadDesarrollada" class="inspections-label">Dirección</label>
          <input
            type="text"
            placeholder="Escribe aquí"
            name="direccionActividadDesarrollada"
            class="inspections-input"
            id="direccionActividadDesarrollada"
            [(ngModel)]="activity.direccion">
        </div>
        <div class="form-group half-width-group">
          <label for="barrioActividadDesarrollada" class="inspections-label">Barrio</label>
          <input
            type="text"
            placeholder="Escribe aquí"
            name="barrioActividadDesarrollada"
            class="inspections-input"
            id="barrioActividadDesarrollada"
            [(ngModel)]="activity.barrio">
        </div>
      </div>
      <div class="form-row">
        <label for="observacionesActividadDesarrollada" class="inspections-label">Observaciones*</label>
        <textarea class="textarea mb-20" placeholder="Escribe aquí..." name="observacionesActividadDesarrollada" id="observacionesActividadDesarrollada" [(ngModel)]="activity.observaciones" cols="30" rows="10"></textarea>
      </div>
      <div class="form-row">
        <label for="registroFotograficoActividadDesarrollada" class="inspections-label">Registro fotográfico</label>
        <label for="registroFotograficoActividadDesarrollada" class="file-container mb-20">
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
          <p>Subir archivo</p>
          <input
            class="file-input"
            type="file"
            id="registroFotograficoActividadDesarrollada"
            name="registroFotograficoActividadDesarrollada"
            accept="image/*,application/*"  
            multiple
            (change)="onFileChange($event)" >
        </label>
        <div *ngIf="activity.registroFotografico.length > 0" class="mb-20">
          <h4>Registros</h4>
          <div class="p-relative-registros mr-20" *ngFor="let photoLink of activity.registroFotografico; index as i">
            <a [href]="photoLink" target="_blank"><img class="uploaded-image" [src]="getImageFromTypeFile(photoLink)" [alt]="'Registro número ' + (i+1)"></a>
            <img class="delete-uploaded-image" src="assets/img/inspecciones/arrow-down.png" alt="X" (click)="deletePhoto(i)">
          </div>
        </div>
      </div>
      <button *ngIf="wantEditActivity" class="aggregate-button mb-20" (click)="saveActivity(diagnostic)">Guardar actividad</button>
      <button *ngIf="!wantEditActivity" class="aggregate-button mb-20" (click)="addActivity(diagnostic)">+Agregar actividad</button>
    </div>
    <button class="submit-button" (click)="updateDiagnostic(diagnostic)">Guardar</button>
  </form>
</div>


