<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title">Seguimiento y evaluación</h2>
    </div>
    <button class="download-button" (click)="downloadPDF()">
      <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
    </button>
  </div>

  <div class="list width-70" *ngIf="!newFollowUp">
    <div class="list-row d-flex-between mb-10" *ngFor="let seguimiento of seguimientos; index as i">
      <div class="list-detail min-100per">
        <span>{{ seguimiento.fechaSeguimiento.split("T")[0] + ': ' + seguimiento.descripcionSeguimiento | truncate:150 }}</span>
        <div class="list-buttons right-double">
          <button class="selectable-button" (click)="editFollowUp(seguimiento)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
          <button class="selectable-button" (click)="deleteFollowUp(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
        </div>
      </div>
    </div>
    <div *ngIf="!oldFollowUp">
      <button class="cancel-button mb-20" *ngIf="newFollowUp" (click)="cancelFollowUp()">Cancelar</button>
      <button class="aggregate-button mb-20" *ngIf="!newFollowUp" (click)="startFollowUp()">Nuevo seguimiento</button>
    </div>
  </div>
  <form class="phase-form" *ngIf="newFollowUp || oldFollowUp">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaSeguimiento" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaSeguimiento"
          id="fechaSeguimiento"
          [(ngModel)]="seguimiento.fechaSeguimiento"
          [min]="todayDate"/>
      </div>
      <div class="form-group half-width-group">
        <label for="horaSeguimiento" class="inspections-label">Hora</label>
        <input type="time" placeholder="00:00" name="horaSeguimiento" id="horaSeguimiento" class="inspections-input time-input" id="horaSeguimiento" [(ngModel)]="seguimiento.horaSeguimiento"/>
      </div>
    </div>

    <div class="form-row mb-20">
      <div class="form-group full-width-group" >
        <label class="inspections-label" for="descripcionSeguimiento">Descripción del seguimiento</label>
        <textarea class="textarea textarea-modified" name="descripcionSeguimiento" [(ngModel)]="seguimiento.descripcionSeguimiento" id="descripcionSeguimiento" placeholder="Escribe aquí…" cols="10" rows="10"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="verificacionCumplimiento">¿Se cumplió la orden de policía?</label>
        <section class="d-flex-between-check">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox"  [checked]="seguimiento.ordenYCompromisosCumplidos === 'yes'" (change)="changeAccomplishment('yes')">
            <label class="inspections-label" for="si">Totalmente</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="parcialmente"  type="checkbox" [checked]="seguimiento.ordenYCompromisosCumplidos === 'partially'" (change)="changeAccomplishment('partially')">
            <label class="inspections-label" for="parcialmente">Parcialmente</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="seguimiento.ordenYCompromisosCumplidos === 'no'" (change)="changeAccomplishment('no')">
            <label class="inspections-label" for="no">No se cumplió</label>
          </div>
        </section>
        <textarea class="textarea textarea-modified" name="justificacionCumplimiento" id="justificacionCumplimiento" [(ngModel)]="seguimiento.justificacionCumplimiento" placeholder="Escribe aquí la justificacion" cols="20" rows="10"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <label class="inspections-label" for="observaciones">Observaciones adicionales</label>
        <textarea class="textarea textarea-modified" name="observaciones" [(ngModel)]="seguimiento.observaciones" id="observaciones"
        placeholder="Escribe aquí…" cols="20" rows="10"></textarea>
      </div>
    </div>
  </form>
  <div>
    <button class="aggregate-button mb-20" (click)="addFollowUp()" *ngIf="newFollowUp">+Agregar seguimiento</button>
    <button class="aggregate-button mb-20" (click)="updateFollowUp()" *ngIf="oldFollowUp">Actualizar seguimiento</button>
  </div>
  <div>
    <button class="submit-button" (click)="saveInfo()">Guardar</button>
    <button class="submit-button-generar" (click)="goToFinalReport()">Generar Informe final</button>
  </div>
</div>
