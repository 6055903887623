import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../services/main.service';
import { GeneralFileService } from '../../form-ficha-general/general-file.service';
import { SeguimientoEvaluacionService } from './seguimiento-evaluacion.service';

@Component({
  selector: 'app-seguimiento-evaluacion',
  templateUrl: './seguimiento-evaluacion.component.html',
  styleUrls: ['./seguimiento-evaluacion.component.css']
})
export class SeguimientoEvaluacionComponent implements OnInit {
  caseId: string;
  presuntosInfractores: any[] = [];
  caseNumber: number | string;
  inspectionNumber: number | string;
  cases: any[];

  constructor(
    private seguimientoEvaluacionService: SeguimientoEvaluacionService,
    private generalFileService: GeneralFileService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCaseInfo();
  }

  getCaseInfo(){
    this.caseId = this.route.snapshot.params['id'];
    this.generalFileService.getGeneralInfo(this.caseId).then(response => {
      this.presuntosInfractores = response.infractores;
      this.cases = response.CCC;
      this.inspectionNumber = response.caso.numInspeccion;
      this.caseNumber = response.caso.numCaso;
    })
  }

  goBackButton(){
    this.router.navigate([`/gestion-casos-inspecciones/ver-caso/${this.caseId}`]);
  }

}
