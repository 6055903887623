<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title">Decisión y recursos</h2>
    </div>
    <button class="download-button" (click)="downloadPDF()">
      <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
    </button>
  </div>

  <form>
    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="fechaDecision" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaDecision"
          [(ngModel)]="pva.fechaDecision"
          id="fechaDecision" />
      </div>
      <div class="form-group half-width-group">
        <label for="horaDecision" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaDecision"
          class="inspections-input time-input"
          [(ngModel)]="pva.horaDecision"
          id="horaDecision"
        />
      </div>
    </div>
    <br />

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <p class="font16" for="cuenta">
          Teniendo en cuenta lo anterior, y previas las siguientes consideraciones de hecho y de derecho:
        </p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="consideracionesInspector">Razones del inspector, análisis de los hechos, las pruebas y las normas</label>
        <textarea
          class="textarea"
          name="consideracionesInspector"
          id="consideracionesInspector"
          [(ngModel)]="pva.consideracionesInspector"
          placeholder="Escribe aquí…" cols="20"
          rows="5"
        ></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <p class="despacho">Este Despacho resuelve:</p>
        <label class="inspections-label" for="tipoDecision">Tipo de decisión</label>
        <ngx-select-dropdown
          class="inspections-select"
          name="tipoDecision"
          [(ngModel)]="pva.tipoDecision"
          [config]="config"
          [options]="tiposDecisiones"
        >
        </ngx-select-dropdown>
      </div>
    </div>

    <section *ngIf="pva.tipoDecision !== decisionAbstener">
      <div class="list">
        <h4 class="subtitle-text bold">Medidas correctivas impuestas</h4>
        <div *ngIf="pva.medidasCorrectivas?.length > 0; else noMedidas">
          <div class="list-row" *ngFor="let medidaCorrectiva of pva.medidasCorrectivas; index as i">
            <div class="list-detail">
              <span>{{ medidaCorrectiva.tipoMedidaCorrectiva + ': ' + medidaCorrectiva.detalleMedidaCorrectiva | truncate:40 }}</span>
              <div class="list-buttons right-double">
                <button class="selectable-button" (click)="editAction(medidaCorrectiva, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
                <button class="selectable-button" (click)="deleteAction(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noMedidas>
          <div class="width-70">
            <p class="font16">
              No tienes medidas correctivas.
            </p>
          </div>
        </ng-template>
      </div>
  
      <h4 class="bold font16">Añadir medida correctiva</h4>
      <div class="form-row">
        <div class="form-group full-width-group width" >
          <label class="inspections-label" for="tipoMedida">Tipo de medida correctiva</label>
          <ngx-select-dropdown
            class="inspections-select"
            name="tipoMedida"
            [(ngModel)]="tipoMedidaCorrectiva"
            [config]="config"
            [options]="tiposMedidasCorrectivas"
          >
          </ngx-select-dropdown>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group full-width-group width" >
          <label class="inspections-label" for="detalleMedidaCorrectiva">Detalle de la medida correctiva</label>
          <textarea class="textarea" name="detalleMedidaCorrectiva" id="detalleMedidaCorrectiva" placeholder="Escribe aquí…" cols="30" rows="5" [(ngModel)]="detalleMedidaCorrectiva"></textarea>
        </div>
        <button *ngIf="!wantEditAction" class="aggregate-button mb-20" (click)="addAction()">+Agregar medida correctiva</button>
        <button *ngIf="wantEditAction" class="aggregate-button mb-20" (click)="saveAction()">Guardar cambios</button>
        <button *ngIf="wantEditAction" class="cancel-small-button" (click)="cancelAction()">Cancelar</button>
      </div>
    </section>

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <p class="font16" for="decision">
          Esta decisión queda notificada en estrados y se informa al destinatario que contra la misma procede recurso de reposición ante esta Inspección de Policía y de apelación ante:
        </p>
      </div>
    </div>
    <br>

    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group width">
        <label class="inspections-label width" for="autoridad">Autoridad que resuelve el recurso</label>
        <input
          class="inspections-input"
          type="text"
          [(ngModel)]="pva.autoridadResuelve"
          name="autoridad"
          id="autoridad"
          placeholder="Escribe aquí">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group width" >
        <p class="font16" for="recurso">
          recurso que debe sustentar en este momento
        </p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="interponeReposicion">¿Interpone reposición?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox" [checked]="pva.interponeReposicion" (change)="changeAccomplishmentReposicion(true)">
            <label class="inspections-label" for="siReposicion">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="!pva.interponeReposicion && pva.interponeReposicion != undefined" (change)="changeAccomplishmentReposicion(false)" >
            <label class="inspections-label" for="noReposicion">No</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="interponeApelacion">¿Interpone apelación?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox" [checked]="pva.interponeApelacion" (change)="changeAccomplishmentApelacion(true)">
            <label class="inspections-label" for="siApelacion">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="!pva.interponeApelacion && pva.interponeApelacion != undefined" (change)="changeAccomplishmentApelacion(false)" >
            <label class="inspections-label" for="noApelacion">No</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row" *ngIf="pva.interponeApelacion || pva.interponeReposicion">
      <div class="form-group full-width-group width" >
        <label class="inspections-label " for="sustentacionRecurso">Sustentación del recurso</label>
        <textarea class="textarea" name="sustentacionRecurso" id="sustentacionRecurso" [(ngModel)]="pva.sustentacionRecurso" placeholder="Escribe aquí…" cols="30" rows="5"></textarea>
      </div>
    </div>

    <div class="form-row" *ngIf="!pva.interponeApelacion && !pva.interponeReposicion">
      <div class="form-group full-width-group width" >
        <p class="font16" for="decisionInfo">
          Teniendo en cuenta que no se interpusieron recursos, la decisión queda en firme.
        </p>
      </div>
    </div>

    <div class="form-row" *ngIf="pva.interponeApelacion || pva.interponeReposicion">
      <div class="form-group full-width-group width" >
        <label class="inspections-label" for="decisionRecurso">Decisión sobre los recursos interpuestos</label>
        <textarea class="textarea" name="decisionRecurso" [(ngModel)]="pva.decisionSobreRecursos" id="decisionRecurso"
        placeholder="Escribe aquí…" cols="30" rows="5"></textarea>
      </div>
    </div>

    <div class="form-row mb-10" *ngIf="pva.interponeApelacion || pva.interponeReposicion">
      <div class="form-group full-width-group width" >
        <p class="font16 mb-0" for="decisionInterpuesta">
          La decisión sobre los recursos interpuestos queda notificada en estrados. Como quiera que se interpuso y se concedió apelación, se remite ante:
        </p>
      </div>
    </div>

    <div class="form-row d-flex-between width" *ngIf="pva.interponeApelacion || pva.interponeReposicion">
      <div class="form-group half-width-group width">
        <label class="inspections-label" for="autoridadResuelve">Autoridad que resuelve el recurso</label>
        <input
          class="inspections-input"
          type="text"
          name="autoridadResuelve"
          id="autoridadResuelve"
          [(ngModel)]="pva.autoridadResuelve"
          placeholder="Escribe aquí">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="audiencia">Audiencia</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="presencial"  type="checkbox" [checked]="audiencia" (change)="changeAccomplishmentAudiencia(true)">
            <label class="inspections-label" for="presencial">Presencial</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="vitual"  type="checkbox" [checked]="!audiencia && audiencia !== undefined" (change)="changeAccomplishmentAudiencia(false)" >
            <label class="inspections-label" for="vitual">Virtual</label>
          </div>
        </section>
      </div>
    </div>

    <div class="list">
      <h4 class="subtitle-text bold">Registros</h4>
      <div *ngIf="pva.archivosAudiencia?.length > 0; else noRegistros">
        <div class="list-row" *ngFor="let archivo of pva.archivosAudiencia; index as i">
          <div *ngIf="archivo.activo">
            <div class="list-detail">
              <span>{{ 'Registro número ' + (i+1) + ': ' }}<a [href]="archivo.url" target="_blank">Ver</a></span>
              <div class="list-buttons">
                <button class="selectable-button" (click)="deleteAudienceFile(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noRegistros>
        <div class="width-70">
          <p class="font16">
            No tienes registros activos.
          </p>
        </div>
      </ng-template>
    </div>

    <h4 class="font16 bold">Añadir registros</h4>

    <div class="form-row">
      <label for="registroAudiencia" class="file-container mb-20">
        <p>Subir archivo</p>
        <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
        <input
          class="file-input"
          type="file"
          id="registroAudiencia"
          name="registroAudiencia"
          accept=".pdf, image/*"
          multiple="true"
          (change)="onFileMultipleChange($event)" >
      </label>
    </div>

    <div class="form-row d-flex-between width-decision">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="municipio">Municipio</label>
        <p class="font16" for="municipio">
          {{ nombreMunicipio || 'No definido' }}
        </p>
      </div>
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="inspeccion">Inspección No.</label>
        <p class="font16" for="inspeccion">
          {{ numInspeccion || 'No definido' }}
        </p>
      </div>
    </div>

    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="nombreInspeccionPva " class="inspections-label bold mb-20">Nombre Inspector campo</label>
        <p class="font16 mt-10">{{ inspectorNombre }}</p>
      </div>
      <div class="form-group half-width-group">
        <label for="firmaInspector" class="inspections-label bold mb-20">Firma Inspector campo</label>
        <label for="firmaInspector" class="file-container-siging calendar-input">
          <p class="p-siging">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-siging">
          <input
            class="file-input input-siging"
            type="file"
            id="firmaInspector"
            name="firmaInspector"
            accept=".pdf, .jpg"
            (change)="onFileChange($event, 'firma-inspector')" >
        </label>
        <div *ngIf="pva.firmaInspector?.url" class="block">
          <a class="firma" [href]="pva.firmaInspector.url" target="_blank">Ver firma</a>
        </div>
      </div>
    </div>

    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="agenteMinisterioPva " class="inspections-label bold mb-20">Nombre Agente Ministerio Público</label>
        <input
          class="inspections-input calendar-input"
          placeholder="Escribe aquí"
          type="text"
          [(ngModel)]="pva.nombreAgente"
          name="agenteMinisterio"
          id="agenteMinisterio" />
      </div>
      <div class="form-group half-width-group">
        <label for="firmaAgenteMinisterio" class="inspections-label bold mb-20">Firma Agente Ministerio Público</label>
        <label for="firmaAgenteMinisterio" class="file-container-siging calendar-input">
          <p class="p-siging">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-siging">
          <input
            class="file-input input-siging"
            type="file"
            id="firmaAgenteMinisterio"
            name="firmaAgenteMinisterio"
            accept=".pdf, .jpg"
            (change)="onFileChange($event, 'firma-agente')" >
        </label>
        <div *ngIf="pva.firmaAgente?.url" class="block">
          <a class="firma" [href]="peticionario.firma.url" target="_blank">Ver firma</a>
        </div>
      </div>
    </div>

    <div class="list" *ngIf="pva.investigados?.length > 0">
      <h4 class="subtitle-text bold">Investigados</h4>
      <div>
        <div *ngIf="investigadosActivos.length > 0; else noInvestigados">
          <div class="list-row" *ngFor="let investigado of pva.investigados; index as i">
            <div *ngIf="investigado.activo">
              <div class="list-detail">
                <span>{{ investigado.nombreCompleto }}: <a [href]="investigado.firma?.url" target="_blank">Ver firma</a></span>
                <div class="list-buttons right-double">
                  <button class="selectable-button" (click)="editInvestigated(investigado, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
                  <button class="selectable-button" (click)="deleteInvestigated(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noInvestigados>
        <div class="width-70">
          <p class="font16">
            No has añadido investigados.
          </p>
        </div>
      </ng-template>
    </div>
    <h4 class="font16 bold">Añadir investigado</h4>
    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="nombreInvestigadoPva " class="inspections-label bold mb-20">Nombre Investigado</label>
        <ngx-select-dropdown
          class="inspections-select"
          [(ngModel)]="investigado.nombreCompleto"
          name="tipoDocumento"
          [config]="config"
          [options]="investigados"
        ></ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label for="firmaNombreInvestigado" class="inspections-label bold mb-20">Firma Investigado</label>
        <label for="firmaInvestigado" class="file-container-siging calendar-input">
          <p class="p-siging">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-siging">
          <input
            class="file-input input-siging"
            type="file"
            id="firmaInvestigado"
            name="firmaInvestigado"
            accept=".pdf, .jpg"
            (change)="onFileChange($event, 'firma-investigado')"
          >
        </label>
        <div *ngIf="investigado.firma?.url" class="block">
          <a class="firma" [href]="investigado.firma.url" target="_blank">Ver firma</a>
        </div>
      </div>
    </div>

    <div>
      <button *ngIf="!wantEditInvestigated" class="aggregate-button mb-20 siging-but" (click)="addInvestigated()">+Agregar datos de otro Investigado(a)</button>
        <button *ngIf="wantEditInvestigated" class="aggregate-button mb-20 siging-but" (click)="saveInvestigated()">Guardar datos del investigado(a)</button>
        <button *ngIf="wantEditInvestigated" class="cancel-small-button" (click)="cancelInvestigated()">Cancelar</button>
    </div>

    <div class="list" *ngIf="pva.peticionarios?.length > 0">
      <h4 class="subtitle-text bold">Peticionarios</h4>
      <div>
        <div *ngIf="peticionariosActivos.length > 0; else noPeticionarios">
          <div class="list-row" *ngFor="let peticionario of pva.peticionarios; index as i">
            <div *ngIf="peticionario.activo">
              <div class="list-detail">
                <span>{{ peticionario.nombreCompleto }}: <a [href]="peticionario.firma.url" target="_blank">Ver firma</a></span>
                <div class="list-buttons right-double">
                  <button class="selectable-button" (click)="editPetitioner(peticionario, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="icon edit"></button>
                  <button class="selectable-button" (click)="deletePetitioner(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon delete"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noPeticionarios>
          <div class="width-70">
            <p class="font16">
              No has añadido peticionarios.
            </p>
          </div>
        </ng-template>
      </div>
    </div>
    <h4 class="font16 bold">Añadir peticionario</h4>
    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="nombrePeticionarioPva " class="inspections-label bold mb-20">Nombre peticionario{{ peticionario.nombreCompleto }}</label>
        <ngx-select-dropdown
          class="inspections-select"
          [(ngModel)]="peticionario.nombreCompleto"
          name="tipoDocumento"
          [config]="config"
          [options]="peticionarios"
        ></ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label for="firmaPeticionario" class="inspections-label bold mb-20">Firma Peticionario</label>
        <label for="firmaPeticionario" class="file-container-siging calendar-input">
          <p class="p-siging">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-siging">
          <input
            class="file-input input-siging"
            type="file"
            id="firmaPeticionario"
            name="firmaPeticionario"
            accept=".pdf, .jpg"
            (change)="onFileChange($event, 'firma-peticionario')" >
        </label>
        <div *ngIf="peticionario.firma?.url" class="block">
          <a class="firma" [href]="peticionario.firma.url" target="_blank">Ver firma</a>
        </div>
      </div>
    </div>

    <div>
      <button *ngIf="!wantEditPetitioner" (click)="addPetitioner()" class="aggregate-button mb-20" >+Agregar datos de otro peticionario</button>
      <button *ngIf="wantEditPetitioner" class="aggregate-button mb-20" (click)="savePetitioner()">Guardar cambios del peticionario</button>
      <button *ngIf="wantEditPetitioner" class="cancel-small-button" (click)="cancelPetitioner()">Cancelar</button>
    </div>

    <div class="form-row d-flex-between width">
      <div class="form-group half-width-group">
        <label for="auxiliarAdministrativoPva " class="inspections-label bold mb-20">Nombre Auxiliar Administrativo</label>
        <input
          class="inspections-input calendar-input"
          placeholder="Escribe aquí"
          [(ngModel)]="pva.nombreAuxAdm"
          type="text"
          name="auxiliarAdministrativo"
          id="auxiliarAdministrativo" />
      </div>
      <div class="form-group half-width-group">
        <label for="firmaAuxiliarAdministrativo" class="inspections-label bold mb-20">Firma Auxiliar Administrativo</label>
        <label for="firmaAuxiliarAdministrativo" class="file-container-siging calendar-input">
          <p class="p-siging">Adjuntar firma</p>
          <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image-pva-siging">
          <input
            class="file-input input-siging"
            type="file"
            id="firmaAuxiliarAdministrativo"
            name="firmaAuxiliarAdministrativo"
            accept="image/*, application/pdf"
            (change)="onFileChange($event, 'firma-aux')" >
        </label>
        <div *ngIf="pva.firmaAuxAdm?.url" class="block">
          <a class="firma" [href]="pva.firmaAuxAdm.url" target="_blank">Ver firma</a>
        </div>
      </div>
    </div>
  </form>
  <button class="submit-button" (click)="onSaveInfo()">Guardar</button>
</div>
