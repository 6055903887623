<div class="main">
  <div mat-dialog-content class="main-content">
    <h1 mat-dialog-title class="mb-20">Selecciona dos fechas</h1>
    <div class="fields">
      <div class="date-container">
        <label for="start">Fecha inicial</label>
        <input formControlName="startDate" name="start" (change)="onDateChanged($event, 'start')" type="date">
      </div>
      <div class="date-container">
        <label for="end">Fecha final</label>
        <input formControlName="endDate" name="end" (change)="onDateChanged($event, 'end')" type="date">
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex-between">
    <button mat-button (click)="onNoClick()" class="cancel-gray">Cancelar</button>
    <button mat-button mat-dialog-close (click)="saveInfo()" class="submit-button" cdkFocusInitial>Buscar</button>
  </div>

</div>
