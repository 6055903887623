import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MainService } from '../../../services/main.service';
import { Caso } from '../../inspecciones.interface';

@Component({
  selector: 'app-ver-caso',
  templateUrl: './ver-caso.component.html',
  styleUrls: ['./ver-caso.component.css']
})
export class VerCasoComponent implements OnInit {
  idCaso = '';
  numCaso = '';
  numInspeccion = '';
  informationReceived: boolean = false
  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.idCaso = this.route.snapshot.params['id'];
    this.mainService.get<Caso>(`api/caso/${this.idCaso}`).subscribe(response => {
      if(this.mainService.isSuccess<Caso>(response)){
        this.numCaso = response.result.numCaso;
        this.numInspeccion = response.result.numInspeccion
        this.informationReceived = true;
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Se produjo el siguiente error: ' + response.error,
          icon: 'error'
        });
      }
    })
  }

  goBackButton(){
    this.router.navigateByUrl('/gestion-casos-inspecciones');
  }

  /**
   * Redirecciona a una fase
   * @param phase La fase a la que va
   */
  goToPhase(phase: 'mecanismo-previo' | 'mecanismo-intermedio' | 'pva' | 'seguimiento-evaluacion'){
    this.router.navigateByUrl(
      this.router.createUrlTree(
        [`/gestion-casos-inspecciones/ver-caso/${phase}/${this.idCaso}`], { queryParams: { previousPage: "ficha-general" } }
      )
    );
  }
}
