import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeguimientoEvaluacionService } from '../seguimiento-evaluacion.service';
import { SeguimientoEvaluacion } from '../../../inspecciones.interface';
import { UtilsService } from '../../../../services/utils.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {
  cumplieronObjetivos: boolean = false
  utilPlataforma: boolean = false
  caseId: string = '';
  segEvaId: string = ''
  seguimientoEvaluacion: SeguimientoEvaluacion;
  /** ngx-select-dropdown */
  readonly configMecanismos = {
    displayKey:'Mecanismos utilizados',
    search:true,
    placeholder:'Seleccione uno'
  };
  readonly config = {
    noResultsFound: "No se encontraron resultados",
    placeholder: 'Brindenos su calificación'
  }
  readonly mecanismosUtilizados: string[] = [
    'Mecanismos previos',
    'Mecanismos intermedios',
    'Proceso verbal abreviado',
    'Otro'
  ];
  editingMechanism: boolean = false;
  mechanismId: number;
  mecanismoImplementado: string | any[];
  detalleMecanismoImplementado: string;
  estrellasPlataforma: number[] = [1,2,3,4,5];
  /******/
  constructor(
    private seguimientoEvaluacionService: SeguimientoEvaluacionService,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.seguimientoEvaluacionService.getAllInfo(this.caseId).then((response) => {
      this.seguimientoEvaluacion = response;
      this.segEvaId = response._id;
    });
  }

  /**
   * Cambia el estado del campo culminado
   * @param {boolean} status a cambiar, true o false
   */
  changeAccomplishmentCasoCulminado(status: boolean): void {
    if (
      (status && !this.seguimientoEvaluacion.culminado) ||
      (!status && this.seguimientoEvaluacion.culminado) ||
      (status && this.seguimientoEvaluacion.culminado === undefined) ||
      (!status && this.seguimientoEvaluacion.culminado === undefined)
    ) {
      this.seguimientoEvaluacion.culminado = status;
    } else {
      this.seguimientoEvaluacion.culminado = undefined;
    }
  }

  /**
   * Cambia el estado del campo cumplimientoConvivencia
   * @param {boolean} status a cambiar, sí, no o parcialmente
   */
  changeAccomplishmentCumplieronObjetivos(status: 'yes' | 'no' | 'partially'){
    if(
      (status === 'yes' && this.seguimientoEvaluacion.cumplimientoConvivencia !== 'yes') ||
      (status === 'no' && this.seguimientoEvaluacion.cumplimientoConvivencia !== 'no') ||
      (status === 'partially' && this.seguimientoEvaluacion.cumplimientoConvivencia !== 'partially') ||
      (!status && this.seguimientoEvaluacion.cumplimientoConvivencia === undefined)
    ){
      this.seguimientoEvaluacion.cumplimientoConvivencia = status;
    } else {
      this.seguimientoEvaluacion.cumplimientoConvivencia = undefined;
    }
  }

  /**
   * Cambia el estado del campo plataformaUtil
   * @param {boolean} status a cambiar, true o false
   */
  changeAccomplishmentUtilPlataforma(status: boolean) {
    if (
      (status && !this.seguimientoEvaluacion.plataformaUtil) ||
      (!status && this.seguimientoEvaluacion.plataformaUtil) ||
      (status && this.seguimientoEvaluacion.plataformaUtil === undefined) ||
      (!status && this.seguimientoEvaluacion.plataformaUtil === undefined)
    ) {
      this.seguimientoEvaluacion.plataformaUtil = status;
    } else {
      this.seguimientoEvaluacion.plataformaUtil = undefined;
    }
  }

  /**
   * Agrega un mecanismo implementado si se cumplen con sus campos
   */
  onAggregateMechanism(){
    if(Array.isArray(this.mecanismoImplementado) || this.detalleMecanismoImplementado == undefined || this.detalleMecanismoImplementado == ''){
      Swal.fire({
        title: 'Error',
        text: 'No se puede añadir el mecanismo implementado',
        icon: 'error'
      });
      return;
    }
    this.seguimientoEvaluacion.mecanismosImplementados.push({
      nombre: this.mecanismoImplementado,
      detalle: this.detalleMecanismoImplementado
    });
    Swal.fire({
      title: 'Éxito',
      text: 'Mecanismo implementado agregado',
      icon: 'success'
    });
    this.resetMechanismFields();
  }

  deleteMechanism(index: number){
    if(this.editingMechanism){
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este mecanismo implementado?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.seguimientoEvaluacion.mecanismosImplementados.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El mecanismo se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Edita un mecanismo implementado
   * @param index El índice del mecanismo implementado
   */
  editMechanism(index: number){
    const seguimientoElegido = this.seguimientoEvaluacion.mecanismosImplementados[index];
    this.mecanismoImplementado = seguimientoElegido.nombre;
    this.detalleMecanismoImplementado = seguimientoElegido.detalle;
    this.mechanismId = index;
    this.editingMechanism = true;
  }

  /**
   * Guarda los cambios de un mecanismo implementado
   */
  onSaveMechanism(){
    this.seguimientoEvaluacion.mecanismosImplementados[this.mechanismId] = {
      nombre: <string>this.mecanismoImplementado,
      detalle: this.detalleMecanismoImplementado
    }
    this.resetMechanismFields();
  }

  /**
   * Resetea los campos de un mecanismo implementado
   */
  resetMechanismFields(){
    this.mecanismoImplementado = '';
    this.detalleMecanismoImplementado = '';
    this.mechanismId = undefined;
    this.editingMechanism = false;
  }

  formatSentDate(event){
    this.seguimientoEvaluacion.fechaDiligenciamiento = this.utilsService.formatInputDate(event);
  }

  /**
   * Guarda la información en la BD
   */
  saveInfo(): void {
    this.seguimientoEvaluacionService.saveInfo(this.segEvaId, this.seguimientoEvaluacion).catch(err => {
      console.error(err);
    })
  }

  /**
   * Finaliza el caso
   * @returns void
   */
  finishCase(){
    return
  }
}
