import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { MainService } from '../../../services/main.service';
import { S3Service } from '../../../services/s3.service';
import { MecanismoPrevio } from '../../inspecciones.interface';

@Injectable()
export class MecanismoPrevioService {

  bucket: S3;

  constructor(
    private mainService: MainService,
    private s3Service: S3Service
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  getAllInfo(caseId): Promise<MecanismoPrevio> {
    return new Promise((resolve, reject) => {
      this.mainService.get<MecanismoPrevio>(`api/mec-previo-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<MecanismoPrevio>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Guarda el archivo en el servidor de S3
   * @param file El archivo que se va a subir, tipo file
   * @returns el link donde se guardó el archivo
   */
  saveInS3 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (file !== undefined) {
        // Obtener la información necesaria
        let { params, options } = this.s3Service.getInfoForUpload('mecanismo-previo',file);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if(err){
            reject(err);
          } else {
            const archivo = data.Location
            resolve(archivo);
          }
        });
      }
    });
  }

  showS3UploadError(){
    this.s3Service.showErrorUploading();
  }

  showS3UploadSuccess(){
    this.s3Service.showSuccessUploading();
  }
}
