import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PvaService } from '../pva.service';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../../services/utils.service';
import { Descargo, FichaGeneral } from '../../../inspecciones.interface';
import { GeneralFileService } from 'src/app/inspecciones/form-ficha-general/general-file.service';
import { PdfService } from 'src/app/services/pdf.service';
@Component({
  selector: 'app-descargos-pva',
  templateUrl: './descargos-pva.component.html',
  styleUrls: ['./descargos-pva.component.css']
})
export class DescargosPvaComponent implements OnInit {
  /** Id del caso */
  caseId: string;
  /** Objeto de la audiencia seleccionad */
  audiencia: any = {};
  /** Indica si está creando una nueva audiencia o está editando una vieja */
  oldHearing: boolean = false;
  newHearing: boolean = false;
  /** Index de la audiencia dentro del array de audiencias */
  hearingIndex: string | number;
  /** Información de otros componentes */
  @Input() pvaId: string;
  @Input() selectedHearing: any;
  @Input() isSelected: boolean;
  @Input() audienciasDisponibles: any[];
  /** Evento que se realiza al seleccionar una audiencia */
  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  /** Lista de citados a la audiencia */
  citados: any[] = [];
  /** Si elige otro citado */
  anotherSelected: boolean = false;

  /** Configuración de la lista desplegable de los citados */
  readonly quotedConfig = {
    search: true,
    noResultsFound: "No se encontraron resultados",
    placeholder: "Seleccione",
    displayFn: (item) => { return item.nombreCompleto }
  }

  /** Información del citado encontrado */
  quotedFound: any = {};

  /** Configuración de la lista desplegable */
  readonly config = {
    search: true,
    noResultsFound: "No se encontraron resultados",
    placeholder: "Seleccione"
  }

  /** Posibles opciones de los tipos de asistente */
  readonly tiposAsistentes = [
    'Quejoso / peticionario',
    'Querellante',
    'Testigo',
    'Presunto infractor',
    'Otro declarante'
  ]

  /** Posibles opciones de los tipos de identificacion */
  readonly tiposIdentificacion = [
    "Registro civil de nacimiento",
    "Tarjeta de identidad",
    "Cédula de ciudadanía",
    "Cédula de extranjería",
    "Pasaporte",
    "Documento identificación extranjero",
    "Permiso especial",
    "NIF del extranjero",
    "NUIP",
    "NIT"
  ]

  /** Lista de descargos de la audiencia */
  descargos: Descargo[] = [];
  /** Información del descargo seleccionado */
  descargo: Descargo = {}

  /** Indica si se está editando un descargo */
  wantEditStatement: boolean = false;

  /** Indica el indice del descargo */
  statementIndex: number = undefined;

  nombreOtroCitado: string;

  public fichaGeneralData: FichaGeneral = {
    caso: undefined,
    createdAt: '',
    documentoOrigen: '',
    fechaDocumentoOrigen: '',
    archivosDocumentoOrigen: [],
    otrosDocumentos: [],
    radicado: '',
    departamento: '',
    municipio: '',
    modeloMunicipio: '',
    peticionarios: [],
    infractores: [],
    resumenHechos: '',
    fechaHechos: '',
    horaHechos: '',
    nombreLugarHechos: '',
    tipoLocalidadHechos: '',
    lugarHechos: '',
    detalleLugarHechos: '',
    esQuerellaCivil: false
  }

  doc: jsPDF;
  finalY: number = 15;

  constructor(
    private route: ActivatedRoute,
    private pvaService: PvaService,
    private pdfService: PdfService,
    private generalFileService: GeneralFileService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.pvaService.getQuoted(this.caseId).then(quoted => {
      for(let citado of quoted){
        this.citados = [...this.citados, citado]
      }
      this.citados = [...this.citados, {nombreCompleto: 'Otro'}];
    });
    this.generalFileService.getGeneralInfo(this.caseId).then(fichaGeneral => {
      this.fichaGeneralData = fichaGeneral;
      console.log(this.fichaGeneralData);
    })
  }

  /**
   * Está constantemente buscando cambios en las selecciones que realice el usuario en otros componentes.
   * @param changes Cambios de otros componente
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      this.descargos = this.audiencia.descargos;
      this.audiencia.horaAudiencia = this.audiencia.horaAudiencia.split('T')[0];
      this.oldHearing = true;
      this.hearingIndex = this.audienciasDisponibles.findIndex(audiencia => {
        return audiencia === changes['selectedHearing'].currentValue
      })
    }
  }

  /**
   * Abre los campos para añadir la información de la persona adicional
   * @param event
   */
  reviewSelectedName(event){
    if(event.value.nombreCompleto === 'Otro'){
      this.anotherSelected = true;
      this.quotedFound = {};
    } else {
      this.anotherSelected = false;
      this.getQuotedInfo(event.value.identificacion);
    }
  }

  /**
   * Obtiene la información de un citado a partir de su número de identificación
   * @param identificacion
   */
  getQuotedInfo(identificacion){
    this.pvaService.findQuoted(this.caseId, identificacion).then(quoted => {
      this.quotedFound = quoted;
    })
  }

  /**
   * Muestra la información para editar una auiencia de la lista de audiencias.
   * @param hearing La audiencia seleccionada.
   */
  editHearing(hearing){
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia.split("T")[0];
  }

  /**
   * Cancela la edición de una audiencia
   */
  cancelHearing(){
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  saveHearing(){
    this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
    Swal.fire({
      title: 'Exito',
      text: 'Audiencia guardada localmente',
      icon: 'success',
      footer: `<span class="red-text">¡No olvides guardar!</span>`
    });
  }

  /**
   * Formatea la fecha para traerlo en un formato facilmente leible
   * @param date La fecha
   * @param separator El separador, de lo contrario se usa (-)
   * @returns La fecha formateada
   */
  formatDate(date: Date | string, separator?: string){
    return this.utilsService.formatDate(date, separator);
  }

  /**
   * Añade un descargo de una u otra forma dependendiendo de si se trata de un citado de la lista o de una persona adicional
   * @param newStatement indica si se trata de un nuevo descargo, si es false es porque se está editando
   */
  addStatement(newStatement){
    if(
      !this.anotherSelected &&
      (Array.isArray(this.descargo.tipoAsistente) ||
      this.descargo.declaracion == undefined ||
      Array.isArray(this.descargo.nombreCitado))
    ){
      Swal.fire({
        title: 'Error',
        text: 'No se han agregado todos los campos obligatorios',
        icon: 'error'
      });
    } else if (
      this.anotherSelected &&
      (Array.isArray(this.descargo.tipoAsistente) ||
      this.descargo.declaracion == undefined ||
      Array.isArray(this.descargo.tipoIdentificacion) ||
      this.descargo.identificacion == undefined)
    ) {
      Swal.fire({
        title: 'Error',
        text: 'No se han agregado todos los campos obligatorios',
        icon: 'error'
      });
    } else {
      if(newStatement){
        this.createStatement();
      } else {
        this.saveStatement();
      }
    }
  }

  /**
   * Añade una declaración nueva a la lista de declaraciones
   */
  createStatement(){
    if(this.anotherSelected){
      this.descargos.push({
        nombreCitado: this.nombreOtroCitado,
        otraPersona: true,
        tipoIdentificacion: this.descargo.tipoIdentificacion,
        identificacion: this.descargo.identificacion,
        tipoAsistente: this.descargo.tipoAsistente,
        declaracion: this.descargo.declaracion
      })
    } else {
      const { nombreCompleto, tipoIdentificacion, identificacion } = this.quotedFound;
      this.descargos.push({
        nombreCitado: nombreCompleto,
        otraPersona: false,
        tipoIdentificacion,
        identificacion,
        tipoAsistente: this.descargo.tipoAsistente,
        declaracion: this.descargo.declaracion
      });
    }
    Swal.fire({
      title: 'Éxito',
      text: 'Se ha agregado la declaración exitosamente',
      icon: 'success',
      footer: `<span class="red-text">¡No olvides guardar!</span>`
    });
    this.resetForm();
  }

  /**
   * Actualiza una declaración
   */
  saveStatement(){
    if(this.anotherSelected){
      this.descargos[this.statementIndex] = {
        nombreCitado: this.nombreOtroCitado,
        otraPersona: true,
        tipoIdentificacion: this.descargo.tipoIdentificacion,
        identificacion: this.descargo.identificacion,
        tipoAsistente: this.descargo.tipoAsistente,
        declaracion: this.descargo.declaracion
      }
    } else {
      const { nombreCompleto, tipoIdentificacion, identificacion } = this.quotedFound;
      this.descargos[this.statementIndex] = {
        nombreCitado: nombreCompleto,
        otraPersona: false,
        tipoIdentificacion,
        identificacion,
        tipoAsistente: this.descargo.tipoAsistente,
        declaracion: this.descargo.declaracion
      };
    }
    Swal.fire({
      title: 'Éxito',
      text: 'Se ha modificado la declaración exitosamente',
      icon: 'success',
      footer: `<span class="red-text">¡No olvides guardar!</span>`
    });
    this.resetForm();
  }

  /**
   * Deja todos los campos vacíos de los objetos
   */
  resetForm(){
    this.wantEditStatement = false;
    this.anotherSelected = false;
    this.quotedFound = {};
    this.descargo = {};
  }

  /**
   * Edita un descargo / declaración seleccionado por el usuario
   * @param statement El descargo seleccionado de la lista de descargos
   * @param index el indice del descargo en función de su lista
   */
  editStatement(statement, index){
    this.wantEditStatement = true;
    this.statementIndex = index;
    this.descargo.tipoAsistente = statement.tipoAsistente;
    this.descargo.declaracion = statement.declaracion;
    if(statement.otraPersona){
      this.anotherSelected = true;
      this.descargo.tipoIdentificacion = statement.tipoIdentificacion;
      this.descargo.identificacion = statement.identificacion;
      this.nombreOtroCitado = statement.nombreCitado
    } else {
      this.getQuotedInfo(statement.identificacion);
      this.anotherSelected = false;
      this.descargo.nombreCitado = statement.nombreCitado;
    }
  }

  /**
   * Cancela la edición de un descargo / declaración
   */
  cancelStatement(){
    this.wantEditStatement = false;
    this.anotherSelected = false;
    this.resetForm();
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  /**
   * Cambia el estado de si desea conciliación
   * @param wantsReconcile El nuevo estado de conciliación
   */
  changeReconcile(wantsReconcile: boolean): void {
    if (
      (wantsReconcile && !this.fichaGeneralData.haceConciliacion) ||
      (!wantsReconcile && this.fichaGeneralData.haceConciliacion) ||
      (wantsReconcile && this.fichaGeneralData.haceConciliacion === undefined) ||
      (!wantsReconcile && this.fichaGeneralData.haceConciliacion === undefined)
    ) {
      this.fichaGeneralData.haceConciliacion = wantsReconcile;
    } else {
      this.fichaGeneralData.haceConciliacion = undefined;
    }
  }

  /**
   * Descarga una declaración en formato PDF
   * @param descargo El descargo que se quiere descargar
   */
  async downloadStatement(descargo: Descargo): Promise<void> {
    this.doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    const informacionAdicional = await this.getAdditionalPDFFields(descargo.nombreCitado, descargo.identificacion);
    this.setPrincipalTitle();
    this.doc.text('Descargo y declaración proceso verbal abreviado', 15, this.finalY);
    this.finalY += 20;
    this.setTitleStyle();
    this.doc.text(`Audiencia fecha ${this.formatDate(this.audiencia.fechaAudiencia)} y hora ${this.audiencia.horaAudiencia}`, 15, this.finalY);
    this.finalY += 15;

    this.doc.text(`Declaración realizada por ${descargo.nombreCitado}`, 15, this.finalY);
    this.finalY += 15;
    
    this.doc.text('Tipo de identificación', 15, this.finalY);
    this.doc.text('Identificación', 105, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;

    this.doc.text(descargo.tipoIdentificacion || 'No definido', 15, this.finalY);
    this.doc.text(descargo.identificacion || 'No definido', 105, this.finalY);
    this.finalY += 15;

    this.setTitleStyle();
    this.doc.text('Correo', 15, this.finalY, { maxWidth: 90 });
    this.doc.text('Dirección', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 10;
    this.setNormalStyle();
    this.doc.text(informacionAdicional.correo || 'No definido', 15, this.finalY, { maxWidth: 90 });
    this.doc.text(informacionAdicional.direccion || 'No definido', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 15;

    this.setTitleStyle();
    this.doc.text('Teléfono', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.doc.text(informacionAdicional.telefono ? String(informacionAdicional.telefono) : 'No definido', 15, this.finalY)
    this.finalY += 15;

    this.setTitleStyle();
    this.doc.text('Declaración', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.doc.text(descargo.declaracion || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.doc.save(`Descargo audiencia ${this.formatDate(this.audiencia.fechaAudiencia, '_')} - ${descargo.nombreCitado}.pdf`);

  }

  /**
   * Obtiene la información extra de la persona para añadirla al PDF
   * @param nombreCompleto El nombre completo de la persona
   * @param identificacion La identificación de la persona que quiere buscar los datos
   * @returns La información adicional de la persona
   */
  async getAdditionalPDFFields(nombreCompleto: string, identificacion: string) {
    if(nombreCompleto === 'Otro'){
      return ({
        correo: 'No definido',
        direccion: 'No definido',
        telefono: 'No definido'
      })
    } else {
      console.log('he', this.caseId, identificacion);
      const quoted = await this.pvaService.findQuoted(this.caseId, identificacion);
      console.log('ha');
      return ({
        correo: quoted.correo,
        direccion: quoted.direccion,
        telefono: quoted.telefono  
      })
    }
  }

  /**
   * Set de los estilos para titulo principal del PDF
   */
  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set de los estilos negrillas del PDF
   */
  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set de los estilos para texto normal del PDF
   */
  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Guarda los cambios de los descargos del PVA
   * @param f El formulario
   */
  onSaveInfo(f: NgForm){
    Object.assign(this.audiencia, {
      descargos: this.descargos
    });
    this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
    const data = {
      audiencias: this.audienciasDisponibles
    }
    const fichaGeneral: Partial<FichaGeneral> = {
      manifestacionesQuerellantes: this.fichaGeneralData.manifestacionesQuerellantes,
      manifestacionesQuerellados: this.fichaGeneralData.manifestacionesQuerellados,
      acuerdo: this.fichaGeneralData.acuerdo,
      haceConciliacion: this.fichaGeneralData.haceConciliacion
    }
    Promise.all(
      [
        this.generalFileService.saveInfo(this.fichaGeneralData._id, fichaGeneral),
        this.pvaService.saveInfo(this.pvaId, data)
      ]
    ).then(results => {
        console.log(results); 
        Swal.fire({
          title: 'Exito',
          text: 'Información actualizada correctamente',
          icon: 'success'
        });
      })
      .catch(err => {
        console.error(err)
      })
  }

  /**
   * Descarga el PDF incluyendo la información del PVA
   */
  async descargarPDFConPVA(){
    this.doc = new jsPDF('portrait', 'mm', 'a4');

    this.finalY = 40;

    this.doc = await this.utilsService.getPDFHeader({
      doc: this.doc,
      numCaso: this.fichaGeneralData.caso.numCaso,
      numInspeccion: this.fichaGeneralData.caso.numInspeccion
    });

    let { doc, finalY } = await this.pdfService.getHeadingInfo({
      doc: this.doc,
      fichaGeneralData: this.fichaGeneralData,
      finalY: this.finalY
    });
    this.doc = doc;
    this.finalY = finalY;

    ({ doc, finalY } = await this.pdfService.getPeopleInfo({
      doc: this.doc,
      fichaGeneralData: this.fichaGeneralData,
      finalY: this.finalY
    }));
    this.doc = doc;
    this.finalY = finalY;

    ({ doc, finalY } = await this.pdfService.getLastGeneralFileInfo({
      doc: this.doc,
      fichaGeneralData: this.fichaGeneralData,
      finalY: this.finalY
    }));
    this.doc = doc;
    this.finalY = finalY;

    ({ doc, finalY } = await this.pdfService.getCCCsInfo({
      doc: this.doc,
      fichaGeneralData: this.fichaGeneralData,
      finalY: this.finalY
    }));
    this.doc = doc;
    this.finalY = finalY;

    ({ doc, finalY } = await this.pdfService.getPVAAudience({
      doc: this.doc,
      idCaso: this.caseId,
      fichaGeneral: this.fichaGeneralData,
      inspector: this.fichaGeneralData.caso.inspector,
      numCaso: this.fichaGeneralData.caso.numCaso,
      numInspeccion: this.fichaGeneralData.caso.numInspeccion,
      finalY: this.finalY
    }));
    this.doc = doc;
    this.finalY = finalY;

    this.doc.save(`Ficha general y PVA caso ${this.fichaGeneralData.caso.numCaso} ${this.formatDate(new Date())}.pdf`);
  }
  
}
