import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import Swal from 'sweetalert2';
import { GeneralFileService } from './general-file.service';
import { Archivo, ArchivoDescripcion, Caso, CCC, FichaGeneral, Infractor, MecanismoIntermedio, MecanismoPrevio, Municipio, Peticionario, Pva, SeguimientoEvaluacion } from '../inspecciones.interface';
import { UtilsService } from '../../services/utils.service';
import { jsPDF } from 'jspdf';
import * as _html2canvas from 'html2canvas';
import * as QRCode from 'qrcode';
import { PvaService } from '../gestion-casos-inspecciones/pva/pva.service';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-form-ficha-general',
  templateUrl: './form-ficha-general.component.html',
  styleUrls: ['./form-ficha-general.component.css']
})
export class FormFichaGeneralComponent implements OnInit {
  @Input('isEditing') isEditing: boolean;
  @Input('numCaso') numCaso: string;
  @Input('numInspeccion') numInspeccion: string;

  @ViewChild('pdfData') pdfData: ElementRef;
  @ViewChild('infractorEdadInput', { static: false }) infractorEdadInput: ElementRef<HTMLInputElement>;
  @ViewChild('peticionarioEdadInput', { static: false }) peticionarioEdadInput: ElementRef<HTMLInputElement>;

  finalY: number = 15;
  doc: jsPDF;

  todayDate: any;

  idCaso: string = '';
  idFichaGeneral: string = '';

  downloadingPdf: boolean = false;
  ocupaciones: string[] = [
    'Empleado',
    'Contratista',
    'Trabajador independiente',
    'Trabajado del hogar',
    'Empresario / comerciante',
    'Estudiante',
    'Pensionado / retirado',
    'Otras ocupaciones',
    'Sin ocupación definida'
  ];

  public fichaGeneralData: FichaGeneral = {
    createdAt: undefined,
    departamento: '',
    municipio: '',
    modeloMunicipio: '',
    lugarHechos: '',
    fechaHechos: undefined,
    fechaDocumentoOrigen: '',
    caso: undefined,
    documentoOrigen: '',
    archivosDocumentoOrigen: [],
    otrosDocumentos: [],
    radicado: '',
    peticionarios: [],
    infractores: [],
    resumenHechos: '',
    horaHechos: '',
    nombreLugarHechos: '',
    tipoLocalidadHechos: '',
    detalleLugarHechos: '',
    esQuerellaCivil: undefined,
    manifestacionesQuerellantes: '',
    manifestacionesQuerellados: '',
    haceConciliacion: undefined
  }

  public noValidFields = {
    createdAt: false,
    documentoOrigen: false,
    fechaDocumentoOrigen: false,
    resumenHechos: false,
    departamento: false,
    municipio: false,
    fechaHechos: false,
    lugarHechos: false,
    nombreLugarHechos: false,
    direccionHechos: false,
    detalleLugarHechos: false,
    peticionarios: false,
    nombrePeticionario: false,
    correoPeticionario: false,
    localidadHechos: false,
    cccsForm: false,
  }
  
  public cccNoValidFields = {
    tituloCCC: false,
    capituloCCC: false,
    articuloCCC: false,
    numeralCCC: false,
    literalCCC: false
  }

  private validationsForm = [
    "createdAt",
    "documentoOrigen",
    "fechaDocumentoOrigen",
    "resumenHechos",
    "departamento",
    "municipio", 
    "fechaHechos",
    "lugarHechos",
    "nombreLugarHechos",
    "direccionHechos",
    "detalleLugarHechos"
  ]

  readonly documentosOrigen = [
    "Derecho de petición",
    "Querella civil",
    "Oficio de otra entidad pública",
    "Comparendo de policía",
    "Memorando",
    "Informe de policía",
    "Otro"
  ]

  readonly config = {
    displayKey: 'nombre',
    search: true,
    placeholder: 'Seleccione',
    noResultsFound: "No se encontraron resultados",
  };

  readonly tiposIdentificacion = [
    "Registro civil de nacimiento",
    "Tarjeta de identidad",
    "Cédula de ciudadanía",
    "Cédula de extranjería",
    "Pasaporte",
    "Documento identificación extranjero",
    "Permiso especial",
    "NIF del extranjero",
    "NUIP",
    "NIT",
    "Anónimo"
  ]

  readonly nivelesEducativos = [
    "Primaria",
    "Bachillerato",
    "Técnico/tecnológico",
    "Universidad",
    "Postgrado",
    "Otro",
    "No aplica"
  ]

  readonly tiposVulnerabilidad = [
    "Persona en condición de discapacidad",
    "Mujer",
    "Adulto mayor",
    "Niño, niña, adolescente",
    "LGBTIQ+",
    "ROM/gitanos",
    "Grupo étnico/indígena/afrodescendiente/raizal",
    "Víctima del conflicto armado",
    "Habitante de calle",
    "Otra"
  ]

  readonly lugaresHechos = [
    "Bien de uso público/vía pública",
    "Bien privado afectado a espacio público",
    "Recinto privado/propiedad privada",
    "Bien fiscal",
    "Establecimiento de comercio",
    "Institución educativa",
    "Bien de interés cultural/patrimonio",
    "Vehículo de transporte público",
    "Vehículo particular",
    "Zona común en propiedad horizontal",
    "Otro"
  ]

  tipoLocalidadHechosUrbana: boolean = false;
  tipoLocalidadHechosRural: boolean = false;

  // PETICIONARIO
  peticionarios: Peticionario[] = []
  peticionario: Peticionario = {
    nombrePeticionario: '',
    tipoIdentificacionPeticionario: '',
    identificacionPeticionario: '',
    correoPeticionario: '',
    ocupacionPeticionario: '',
    telefonoPeticionario: undefined,
    direccionPeticionario: '',
  }
  editingPetitioner: boolean = false;
  wantDeletePetitioner: boolean = false;

  // INFRACTOR
  infractores: Infractor[] = []
  infractor: Infractor = {
    nombreInfractor: '',
    apellidoInfractor: '',
    tipoIdentificacionInfractor: '',
    identificacionInfractor: '',
    nivelEducativoInfractor: '',
    ocupacionInfractor: '',
    tipoVulnerabilidadInfractor: '',
    edadInfractor: undefined,
    direccionInfractor: '',
    telefonoInfractor: '',
    correoInfractor: '',
  }
  editingInfractor: boolean = false;

  // CASO CONTRARIO A LA CONVIVENCIA
  ccc = {
    tituloCCC: undefined,
    capituloCCC: undefined,
    articuloCCC: undefined,
    numeralCCC: undefined,
    literalCCC: undefined
  }
  cccs: CCC[] = []
  cccsSelectedInfo: any[] = [];
  titulosDisponiblesCCC = []
  capitulosDisponiblesCCC = []
  articulosDisponiblesCCC = []
  numeralesDisponiblesCCC = []
  literalesDisponiblesCCC = []

  tipoComportamientoEncontrado: string = '';
  descripcionComportamientoEncontrado: string = '';
  medidaCorrectivaEncontrada: string = '';
  idCCC: string = ''
  isCaseFound: boolean = false;
  cccsForm = [];

  descripcionOtroDocumento: string = '';

  archivosDocumentoOrigen: Archivo[] = [];
  otrosDocumentos: ArchivoDescripcion[] = [];

  constructor(
    private mainService: MainService,
    private generalFileService: GeneralFileService,
    private pdfService: PdfService,
    private utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getAllCCC();
    this.formatCurrentDate();
    this.getDepartamentMunicipality();
    if (this.isEditing) {
      this.getGeneralFileInfo();
    }
  }

  getImageFromTypeFile(archivo: Archivo): string {
    return this.utilsService.getImageFromFileType(archivo);
  }

  getAllCCC() {
    this.mainService.get<CCC[]>('api/ccc').subscribe(response => {
      if (this.mainService.isSuccess<CCC[]>(response)) {
        this.cccs = response.result;
        for (let ccc of response.result) {
          this.titulosDisponiblesCCC = [...this.titulosDisponiblesCCC, ccc.titulo];
        }
        this.titulosDisponiblesCCC = this.titulosDisponiblesCCC.filter((value, index) => {
          return this.titulosDisponiblesCCC.indexOf(value) === index;
        });
      }
    })
  }

  getGeneralFileInfo() {
    this.idCaso = this.route.snapshot.params['id'];
    this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.idCaso}`).subscribe(response => {
      if (this.mainService.isSuccess<FichaGeneral>(response)) {
        const bd: FichaGeneral = response.result;
        console.log(bd);
        this.idFichaGeneral = bd._id;
        this.fichaGeneralData = bd;
        this.fichaGeneralData.createdAt = bd.createdAt.toString().split("T")[0];
        this.fichaGeneralData.fechaHechos = bd.fechaHechos.toString().split("T")[0];
        this.fichaGeneralData.fechaDocumentoOrigen = bd.fechaDocumentoOrigen.split("T")[0];
        this.peticionarios = bd.peticionarios;
        this.infractores = bd.infractores;
        this.cccsSelectedInfo = bd.CCC || [];
        this.cccsForm = this.cccsSelectedInfo.map(ccc => ccc._id);
        this.tipoLocalidadHechosUrbana = false;
        this.tipoLocalidadHechosRural = false;
        this.otrosDocumentos = bd.otrosDocumentos || [];
        this.archivosDocumentoOrigen = bd.archivosDocumentoOrigen || [];
        bd.tipoLocalidadHechos === 'Urbana' ? this.tipoLocalidadHechosUrbana = true : this.tipoLocalidadHechosRural = true;
      } else {
        Swal.fire(
          'Error',
          'Algo ha salido mal',
          'error'
        )
      }
    })
  }

  getDepartamentMunicipality() {
    const inspector = JSON.parse(localStorage.getItem('inspector'));
    const inspectorNumbers = inspector.codigoInspector.split("IN");
    this.mainService.get<Municipio>(`api/municipio/codigo/${inspectorNumbers[0]}`).subscribe((response: any) => {
      this.fichaGeneralData.departamento = response.departamento
      this.fichaGeneralData.municipio = response.nombre
      this.fichaGeneralData.modeloMunicipio = response._id
    });
  }

  formatCurrentDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm: any = today.getMonth() + 1;
    let dd: any = today.getDate();

    if (dd < 10) {
      dd = '0' + dd
    };
    if (mm < 10) {
      mm = '0' + mm
    };

    this.fichaGeneralData.createdAt = yyyy + '-' + mm + '-' + dd;

    this.todayDate = new Date().toISOString().split("T")[0]
  }

  /**
   * Sube archivos a S3 y los manda al campo correspondiente dependiendo del parámetro tipo
   * @param event El evento del input file
   * @param tipo El campo al que se quiere añadir
   */
  onFileChange(event, tipo: string): void {
    if (tipo === 'otros-documentos' && !this.descripcionOtroDocumento) {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos',
        icon: 'error'
      });
      return
    }
    const files: File[] = Object.values(event.target.files);
    const result: Promise<string[]> = Promise.all(files.map((file) => this.generalFileService.saveInS3(file)));
    result.then((urls: string[]) => {
      if (tipo === 'documento-origen') {
        urls.forEach((url, index) => {
          this.archivosDocumentoOrigen.push({
            url,
            tipo: files[index].type
          });
        });
        return;
      }
      urls.forEach((url, index) => {
        this.otrosDocumentos.push({
          url,
          tipo: files[index].type,
          descripcion: this.descripcionOtroDocumento
        });
      });
      this.descripcionOtroDocumento = undefined;
    }).catch(err => {
      this.generalFileService.showS3UploadError();
      console.error(err)
    })
  }

  onAggregatePetitioner(isEditing) {
    // ONLY NAME IS REQUIRED
    this.peticionario.tipoIdentificacionPeticionario = Array.isArray(this.peticionario.tipoIdentificacionPeticionario) ? '' : this.peticionario.tipoIdentificacionPeticionario;
    this.peticionario.nivelEducativoPeticionario = Array.isArray(this.peticionario.nivelEducativoPeticionario) ? '' : this.peticionario.nivelEducativoPeticionario;
    this.peticionario.ocupacionPeticionario = Array.isArray(this.peticionario.ocupacionPeticionario) ? '' : this.peticionario.ocupacionPeticionario;
    this.peticionario.tipoVulnerabilidadPeticionario = Array.isArray(this.peticionario.tipoVulnerabilidadPeticionario) ? '' : this.peticionario.tipoVulnerabilidadPeticionario;
    if (
      this.peticionario.nombrePeticionario == undefined || this.peticionario.nombrePeticionario == ''
    ) {
      this.highlightNoValidFields("nombrePeticionario", true)
      Swal.fire(
        'Error',
        'Debe agregarle un nombre al peticionario',
        'error'
      )
      return
    }
    this.highlightNoValidFields("nombrePeticionario", false)

    if (!this.utilsService.checkEmailValidation(this.peticionario.correoPeticionario)) {
      this.highlightNoValidFields("correoPeticionario", true)
      Swal.fire(
        'Error',
        'No es un correo válido',
        'error'
      )
      return;
    }
    this.highlightNoValidFields("correoPeticionario", false)

    if (this.peticionario.correoPeticionario != undefined && this.peticionario.correoPeticionario != '') {
      if (!isEditing) {
        this.peticionarios.push(this.peticionario);
        Swal.fire(
          'Éxito',
          'Peticionario agregado con éxito',
          'success'
        )
        this.resetPetitionerInfo();
      } else {
        this.onChangePetitioner();
      }
    }
  }

  resetPetitionerInfo() {
    this.peticionario = {
      nombrePeticionario: '',
      tipoIdentificacionPeticionario: '',
      identificacionPeticionario: '',
      correoPeticionario: '',
      telefonoPeticionario: undefined,
      direccionPeticionario: '',
    }
  }

  editPetitioner(petitioner) {
    this.peticionario = petitioner;
    this.editingPetitioner = true;
  }

  deletePetitioner(index: number) {
    if (this.editingPetitioner) {
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este peticionario?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if (response.isConfirmed) {
        this.peticionarios.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El peticionario se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  onChangePetitioner() {
    try {
      const index = this.peticionarios.findIndex(petitioner => {
        return (petitioner.nombrePeticionario === this.peticionario.nombrePeticionario ||
          petitioner.identificacionPeticionario === this.peticionario.identificacionPeticionario)
      });
      this.peticionarios[index] = this.peticionario;
      Swal.fire(
        'Éxito',
        'Peticionario modificado con éxito',
        'success'
      )
      this.editingPetitioner = false;
      this.resetPetitionerInfo();
    } catch (error) {
      Swal.fire(
        'Error',
        `${error}`,
        'error'
      );
    }
  }

  onAggregateViolator(isEditing) {
    // ANY FIELD IS REQUIRED
    this.infractor.tipoIdentificacionInfractor = Array.isArray(this.infractor.tipoIdentificacionInfractor) ? '' : this.infractor.tipoIdentificacionInfractor;
    this.infractor.ocupacionInfractor = Array.isArray(this.infractor.ocupacionInfractor) ? '' : this.infractor.ocupacionInfractor;
    this.infractor.nivelEducativoInfractor = Array.isArray(this.infractor.nivelEducativoInfractor) ? '' : this.infractor.nivelEducativoInfractor;
    this.infractor.tipoVulnerabilidadInfractor = Array.isArray(this.infractor.tipoVulnerabilidadInfractor) ? '' : this.infractor.tipoVulnerabilidadInfractor;
    if (
      this.infractor.nombreInfractor == '' &&
      this.infractor.apellidoInfractor == '' &&
      this.infractor.tipoIdentificacionInfractor == '' &&
      this.infractor.identificacionInfractor == '' &&
      this.infractor.nivelEducativoInfractor == '' &&
      this.infractor.ocupacionInfractor == '' &&
      this.infractor.tipoVulnerabilidadInfractor == '' &&
      this.infractor.edadInfractor == undefined &&
      this.infractor.direccionInfractor == '' &&
      this.infractor.telefonoInfractor == '' &&
      this.infractor.correoInfractor == ''
    ) {
      Swal.fire(
        'Error',
        'No se puede agregar un infractor si no se le asigna ningún campo',
        'error'
      )
      return;
    }
    if (this.infractor.correoInfractor != undefined && this.infractor.correoInfractor != '') {
      if ((/^\w{2,30}([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.infractor.correoInfractor))) {
        if (!isEditing) {
          this.infractores.push(this.infractor)
          Swal.fire(
            'Éxito',
            'Infractor agregado con éxito',
            'success'
          )
          this.resetViolatorInfo()
        } else {
          this.onChangeViolator();
        }
      } else {
        Swal.fire(
          'Error',
          'No es un correo válido',
          'error'
        )
      }
    } else {
      if (!isEditing) {
        this.infractores.push(this.infractor)
        Swal.fire(
          'Éxito',
          'Infractor agregado con éxito',
          'success'
        )
        this.resetViolatorInfo()
      } else {
        this.onChangeViolator();
      }
    }
  }

  editInfractor(infractor) {
    this.infractor = infractor;
    this.editingInfractor = true;
  }

  deleteInfractor(index) {
    if (this.editingInfractor) {
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este infractor?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if (response.isConfirmed) {
        this.infractores.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El infractor se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  onChangeViolator() {
    try {
      const index = this.infractores.findIndex(infractor => {
        return ((infractor.nombreInfractor === this.infractor.nombreInfractor &&
          infractor.apellidoInfractor === this.infractor.apellidoInfractor) || (infractor.nombreInfractor === this.infractor.nombreInfractor) || (infractor.identificacionInfractor === this.infractor.identificacionInfractor))
      });
      this.infractores[index] = this.infractor;
      Swal.fire(
        'Éxito',
        'Infractor modificado con éxito',
        'success'
      )
      this.editingInfractor = false;
      this.resetViolatorInfo();
    } catch (error) {
      Swal.fire(
        'Error',
        `${error}`,
        'error'
      );
    }
  }

  resetViolatorInfo() {
    this.infractor = {
      nombreInfractor: '',
      apellidoInfractor: '',
      tipoIdentificacionInfractor: '',
      identificacionInfractor: '',
      nivelEducativoInfractor: '',
      ocupacionInfractor: '',
      tipoVulnerabilidadInfractor: '',
      edadInfractor: undefined,
      direccionInfractor: '',
      telefonoInfractor: '',
      correoInfractor: '',
    }
  }

  changeLocationType(select) {
    if (select === 'urbana') {
      this.tipoLocalidadHechosUrbana = true;
      this.tipoLocalidadHechosRural = false;
    } else if (select === 'rural') {
      this.tipoLocalidadHechosUrbana = false;
      this.tipoLocalidadHechosRural = true;
    }
  }

  onAggregateCCC() {
    this.validateCCCFields()
    if (!this.isCaseFound) {
      Swal.fire(
        'Error',
        'Aún no ha sido encontrado un caso contrario a la convivencia',
        'error'
      );
      return
    }
    this.cccsForm.push(this.idCCC);
    const cccEncontrado = this.cccs.find(ccc => ccc._id === this.idCCC);
    this.cccsSelectedInfo.push(cccEncontrado);
    this.resetCCCs();
  }

  resetCCCs() {
    this.capitulosDisponiblesCCC = [];
    this.articulosDisponiblesCCC = [];
    this.numeralesDisponiblesCCC = [];
    this.literalesDisponiblesCCC = [];
    this.ccc.capituloCCC = '';
    this.ccc.articuloCCC = '';
    this.ccc.numeralCCC = '';
    this.ccc.literalCCC = '';
    this.tipoComportamientoEncontrado = '';
    this.descripcionComportamientoEncontrado = '';
    this.medidaCorrectivaEncontrada = '';
    this.idCCC = '';
    this.isCaseFound = false;
  }

  validateCCCFields () {
    Object.keys(this.cccNoValidFields).forEach(noValidField => {
      this.cccNoValidFields[noValidField] = (
        Array.isArray(this.ccc[noValidField]) || this.ccc[noValidField] === undefined
      )
    })
  }

  changeCivilComplaint(isCivil: boolean): void {
    if (
      (isCivil && !this.fichaGeneralData.esQuerellaCivil) ||
      (!isCivil && this.fichaGeneralData.esQuerellaCivil) ||
      (isCivil && this.fichaGeneralData.esQuerellaCivil === undefined) ||
      (!isCivil && this.fichaGeneralData.esQuerellaCivil === undefined)
    ) {
      this.fichaGeneralData.esQuerellaCivil = isCivil;
    } else {
      this.fichaGeneralData.esQuerellaCivil = undefined;
    }
  }

  validateGeneralForm(): boolean {
    this.validationsForm.forEach(
      validationForm => {
        if (Array.isArray(this.fichaGeneralData[validationForm])) {
          this.highlightNoValidFields(validationForm, this.fichaGeneralData[validationForm].length === 0)
          return
        }

        this.highlightNoValidFields(validationForm,
          this.fichaGeneralData[validationForm] === undefined || 
          this.fichaGeneralData[validationForm] === ''
        )
      }
    );

    ['cccsForm', 'peticionarios'].forEach(validationForm => {
      this.highlightNoValidFields(validationForm, this[validationForm].length === 0)
    })

    this.highlightNoValidFields("localidadHechos",
      !(this.tipoLocalidadHechosUrbana || this.tipoLocalidadHechosRural)
    )

    return Object.values(this.noValidFields).every(noValidForm => noValidForm === false)
  }

  highlightNoValidFields(noValidField: string, valid: boolean) {
    this.noValidFields[noValidField] = valid;
  }

  createCase(f: NgForm) {
    const form = f.value

    if (this.validateGeneralForm()) {
      this.creationCaseProccess()
        .then(caseId => {
          this.creationGeneralFileProccess(caseId, form);
          this.creationPhasesProccess(caseId)
            .then(success => {
              this.navigationToViewProccess(success)
            })
        });
    } else {
      Swal.fire(
        'Error',
        'Faltan campos obligatorios por llenar',
        'error'
      )
    }
  }

  creationCaseProccess() {
    return new Promise((resolve, reject) => {
      this.mainService.get<Caso[]>('api/caso').subscribe(response => {
        if (this.mainService.isSuccess<Caso[]>(response)) {
          const inspector = JSON.parse(localStorage.getItem('inspector'));
          if (inspector.cargo !== 'Inspector') {
            return
          }
          const inspectorId = inspector._id;
          const inspectorCode = inspector.codigoInspector
          const inspectorNumber = inspectorCode.split("IN")[1];

          const inspectorCases = response.result.filter(caso => caso.inspector == inspectorId);
          const newInspectorCaseNumber = inspectorCases.length + 1;
          const currentYear = new Date().getFullYear()

          const locationType = this.tipoLocalidadHechosUrbana ? 'U' : 'R';

          // WHEN WE HAVE ALL THE INFORMATION FOR CREATING THE CODES, WE ASSIGN THEM
          const numCaso = inspectorCode + 'A' + currentYear + 'C' + newInspectorCaseNumber + locationType;
          const numInspeccion = 'IN' + inspectorNumber + locationType;
          const newCaseObj = {
            inspector: inspectorId,
            numCaso,
            numInspeccion
          }
          this.mainService.post<Caso>('api/caso', newCaseObj).subscribe(responseCase => {
            if (this.mainService.isSuccess<Caso>(responseCase)) {
              this.idCaso = responseCase.result._id;
              resolve(responseCase.result._id);
            } else {
              reject(responseCase.error);
            }
          })
        }
      })
    })
  }

  creationGeneralFileProccess(caseId, form) {
    const generalFileObject: FichaGeneral = {
      _id: form._id,
      caso: caseId,
      createdAt: form.createdAt,
      documentoOrigen: form.documentoOrigen,
      fechaDocumentoOrigen: form.fechaDocumentoOrigen,
      archivosDocumentoOrigen: this.archivosDocumentoOrigen,
      otrosDocumentos: this.otrosDocumentos,
      radicado: form.radicado,
      departamento: this.fichaGeneralData.departamento,
      municipio: this.fichaGeneralData.municipio,
      modeloMunicipio: this.fichaGeneralData.modeloMunicipio,
      peticionarios: this.peticionarios,
      infractores: this.infractores,
      resumenHechos: form.resumenHechos,
      fechaHechos: form.fechaHechos,
      horaHechos: form.horaHechos,
      lugarHechos: form.lugarHechos,
      direccionHechos: form.direccionHechos,
      nombreLugarHechos: form.nombreLugarHechos,
      tipoLocalidadHechos: form.tipoLocalidadHechosUrbana ? 'Urbana' : 'Rural',
      detalleLugarHechos: form.detalleLugarHechos,
      CCC: this.cccsForm,
      esQuerellaCivil: form.esQuerellaCivil || false
    }
    if (this.fichaGeneralData.lugarHechos === 'Establecimiento de comercio') {
      generalFileObject.nombreEstablecimientoHechos = form.nombreEstablecimientoHechos;
      generalFileObject.nitEstablecimientoHechos = form.nitEstablecimientoHechos;
      generalFileObject.actividadEconomicaEstablecimientoHechos = form.actividadEconomicaEstablecimientoHechos
    }
    this.mainService.post('api/ficha-general', generalFileObject).subscribe(response => {
      Swal.fire(
        'Éxito',
        'Se ha creado la ficha general',
        'success'
      )
    });
  }

  creationPhasesProccess(caseId) {
    return new Promise((resolve, reject) => {
      const mecPrevObj = {
        caso: caseId
      }
      const mecIntObj = {
        caso: caseId
      }
      const pvaObj = {
        caso: caseId
      }
      const segEvaObj = {
        caso: caseId
      }
      const createMecPrev = this.mainService.post<any>('api/mec-previo-caso', mecPrevObj);
      const createMecInt = this.mainService.post<any>('api/mec-intermedio-caso', mecIntObj);
      const createPVA = this.mainService.post<any>('api/pva-caso', pvaObj);
      const createSegEv = this.mainService.post<any>('api/seg-eva-caso', segEvaObj);
      Promise.all([
        createMecPrev,
        createMecInt,
        createPVA,
        createSegEv
      ]).then(values => {
        let successful = true;
        for (let creado of values) {
          creado.subscribe(response => {
            if (!this.mainService.isSuccess<any>(response)) {
              successful = false;
            }
          })
        }
        if (!successful) {
          reject({ success: false, error: 'La creación de las fases no fue exitosa' });
        } else {
          resolve(true)
        }
      })
    })
  }

  navigationToViewProccess(success) {
    if (success) {
      this.router.navigate(['gestion-casos-inspecciones/ver-caso/' + this.idCaso]);
    }
  }

  selectedTitle() {
    // PUT EVERYTHING IN 0, IN CASE THE INSPECTOR BACKS OFF
    this.capitulosDisponiblesCCC = [];
    this.articulosDisponiblesCCC = [];
    this.numeralesDisponiblesCCC = [];
    this.literalesDisponiblesCCC = [];
    this.ccc.capituloCCC = '';
    this.ccc.articuloCCC = '';
    this.ccc.numeralCCC = '';
    this.ccc.literalCCC = '';
    this.tipoComportamientoEncontrado = '';
    this.descripcionComportamientoEncontrado = '';
    this.medidaCorrectivaEncontrada = '';
    this.idCCC = '';
    this.isCaseFound = false;

    let cccsTitulosEncontrados = this.cccs.filter(ccc => {
      return (
        ccc.titulo === this.ccc.tituloCCC
      )
    });
    for (let caso of cccsTitulosEncontrados) {
      this.capitulosDisponiblesCCC = [...this.capitulosDisponiblesCCC, caso.capitulo];
    }
    this.capitulosDisponiblesCCC = this.capitulosDisponiblesCCC.filter((value, index) => {
      return this.capitulosDisponiblesCCC.indexOf(value) === index;
    })
  }

  selectedChapter() {
    // PUT EVERYTHING IN 0, IN CASE THE INSPECTOR BACKS OFF
    this.articulosDisponiblesCCC = [];
    this.numeralesDisponiblesCCC = [];
    this.literalesDisponiblesCCC = [];
    this.ccc.articuloCCC = '';
    this.ccc.numeralCCC = '';
    this.ccc.literalCCC = '';
    this.tipoComportamientoEncontrado = '';
    this.descripcionComportamientoEncontrado = '';
    this.medidaCorrectivaEncontrada = '';
    this.idCCC = '';
    this.isCaseFound = false;

    // NOW WE CREATE THE SELECTABLES OF THE NEXT OPTION
    let cccsCapitulosEncontrados = this.cccs.filter(ccc => {
      return (
        ccc.titulo === this.ccc.tituloCCC &&
        ccc.capitulo === this.ccc.capituloCCC
      )
    });
    for (let caso of cccsCapitulosEncontrados) {
      this.articulosDisponiblesCCC = [...this.articulosDisponiblesCCC, caso.articulo];
    }
    this.articulosDisponiblesCCC = this.articulosDisponiblesCCC.filter((value, index) => {
      return this.articulosDisponiblesCCC.indexOf(value) === index;
    });
  }

  selectedArticle() {
    // PUT EVERYTHING IN 0, IN CASE THE INSPECTOR BACKS OFF
    this.numeralesDisponiblesCCC = [];
    this.literalesDisponiblesCCC = [];
    this.ccc.numeralCCC = '';
    this.ccc.literalCCC = '';
    this.tipoComportamientoEncontrado = '';
    this.descripcionComportamientoEncontrado = '';
    this.medidaCorrectivaEncontrada = '';
    this.idCCC = '';
    this.isCaseFound = false;

    // NOW WE CREATE THE SELECTABLES OF THE NEXT OPTION
    let cccsArticulosEncontrados = this.cccs.filter(ccc => {
      return (
        ccc.titulo === this.ccc.tituloCCC &&
        ccc.capitulo === this.ccc.capituloCCC &&
        ccc.articulo === this.ccc.articuloCCC
      )
    });
    for (let caso of cccsArticulosEncontrados) {
      if (caso.numeral) {
        this.numeralesDisponiblesCCC = [...this.numeralesDisponiblesCCC, caso.numeral];
      }
    }
    this.numeralesDisponiblesCCC = this.numeralesDisponiblesCCC.filter((value, index) => {
      return this.numeralesDisponiblesCCC.indexOf(value) === index;
    });
    this.findCCC();
  }

  selectedNumeral() {
    // PUT EVERYTHING IN 0, IN CASE THE INSPECTOR BACKS OFF
    this.literalesDisponiblesCCC = [];
    this.ccc.literalCCC = '';
    this.tipoComportamientoEncontrado = '';
    this.descripcionComportamientoEncontrado = '';
    this.medidaCorrectivaEncontrada = '';
    this.idCCC = '';
    this.isCaseFound = false;

    // NOW WE CREATE THE SELECTABLES OF THE NEXT OPTION
    let cccsNumeralesEncontrados = this.cccs.filter(ccc => {
      return (
        ccc.titulo === this.ccc.tituloCCC &&
        ccc.capitulo === this.ccc.capituloCCC &&
        ccc.articulo === this.ccc.articuloCCC &&
        ccc.numeral === this.ccc.numeralCCC
      )
    });
    for (let caso of cccsNumeralesEncontrados) {
      if (caso.inciso) {
        this.literalesDisponiblesCCC = [...this.literalesDisponiblesCCC, caso.inciso];
      }
    }
    this.literalesDisponiblesCCC = this.literalesDisponiblesCCC.filter((value, index) => {
      return this.literalesDisponiblesCCC.indexOf(value) === index;
    });
    this.findCCC();
  }

  selectedSubsection() {
    this.findCCC();
  }

  findCCC() {
    // FIRST WE FIX THE TYPES FOR SEARCHING THEM CORRECTLY
    const tituloAreglado = Array.isArray(this.ccc.tituloCCC) ? '' : this.ccc.tituloCCC;
    const capituloArreglado = Array.isArray(this.ccc.capituloCCC) ? '' : this.ccc.capituloCCC;
    const articuloArreglado = Array.isArray(this.ccc.articuloCCC) ? '' : this.ccc.articuloCCC;
    const numeralArreglado = Array.isArray(this.ccc.numeralCCC) ? '' : this.ccc.numeralCCC;
    const literalArreglado = Array.isArray(this.ccc.literalCCC) ? '' : this.ccc.literalCCC;

    let cccEncontrado = this.cccs.filter(ccc => {
      return (
        (ccc.titulo || '') == (tituloAreglado || '') &&
        (ccc.capitulo || '') == (capituloArreglado || '') &&
        (ccc.articulo || '') == (articuloArreglado || '') &&
        (ccc.numeral || '') == (numeralArreglado || '') &&
        (ccc.inciso || '') == (literalArreglado || '')
      )
    })

    // IF WE FIND ONLY 1 RESULT, WE PUT IT IN THE SCREEN
    if (cccEncontrado.length > 1 || cccEncontrado.length === 0) {
      return
    } else {
      this.tipoComportamientoEncontrado = cccEncontrado[0].tipo;
      this.descripcionComportamientoEncontrado = cccEncontrado[0].descripcionComportamiento;
      this.medidaCorrectivaEncontrada = cccEncontrado[0].medidaCorrectiva;
      this.isCaseFound = true;
      this.idCCC = cccEncontrado[0]._id;
    }
  }

  saveGeneralFile(f: NgForm) {
    const form = f.value;
    this.todayDate = new Date().toISOString().split("T")[0]
    if (form.fechaHechos > this.todayDate) {
      Swal.fire(
        'Error',
        'Las fechas no pueden ser mayores a la fecha actual',
        'error'
      )
      return;
    }
    console.log('el formulario', form, this.fichaGeneralData);
    const updateObj: Partial<FichaGeneral> = {
      peticionarios: this.peticionarios,
      infractores: this.infractores,
      resumenHechos: form.resumenHechos,
      fechaHechos: form.fechaHechos,
      horaHechos: form.horaHechos,
      lugarHechos: form.lugarHechos,
      nombreLugarHechos: form.nombreLugarHechos,
      tipoLocalidadHechos: form.tipoLocalidadHechosUrbana ? 'Urbana' : 'Rural',
      detalleLugarHechos: form.detalleLugarHechos,
      CCC: this.cccsForm,
      archivosDocumentoOrigen: this.archivosDocumentoOrigen,
      otrosDocumentos: this.otrosDocumentos,
      direccionHechos: form.direccionHechos,
      nombreEstablecimientoHechos: '',
      nitEstablecimientoHechos: '',
      actividadEconomicaEstablecimientoHechos: '',
      esQuerellaCivil: this.fichaGeneralData.esQuerellaCivil
    }
    if (form.lugarHechos === 'Establecimiento de comercio') {
      updateObj.nombreEstablecimientoHechos = form.nombreEstablecimientoHechos;
      updateObj.nitEstablecimientoHechos = form.nitEstablecimientoHechos;
      updateObj.actividadEconomicaEstablecimientoHechos = form.actividadEconomicaEstablecimientoHechos
    }
    this.mainService.put<FichaGeneral>(`api/ficha-general/${this.idFichaGeneral}`, updateObj).subscribe(response => {
      if (this.mainService.isSuccess<FichaGeneral>(response)) {
        console.log(response.result);
        Swal.fire(
          'Éxito',
          'Ficha general guardada con éxito',
          'success'
        )
      } else {
        Swal.fire(
          'Error',
          `${response.error}`,
          'error'
        );
      }
    })
  }

  formatDate(date: Date): string {
    return this.utilsService.formatDate(date, '_');
  }

  /**
   * Descarga un PDF de la información del recuadro general
   */
  async descargarPDF() {
    this.downloadingPdf = true;
    try {
      this.doc = new jsPDF('portrait', 'mm', 'a4');
      this.finalY = 40;

      this.doc = await this.utilsService.getPDFHeader({ doc: this.doc, numCaso: this.fichaGeneralData.caso.numCaso, numInspeccion: this.fichaGeneralData.caso.numInspeccion });

      await this.getHeadingInfo();

      await this.getPeopleInfo();

      this.getLastInfo();

      this.getCCCsInfo()

      this.doc.save(`Ficha general caso ${this.numCaso} ${this.formatDate(new Date())}.pdf`);
    } catch (error: any) {
      console.error(error);
    }
    this.downloadingPdf = false;
  }


  async getHeadingInfo() {
    const rows = [
      [{
        nombre: 'createdAt',
        nombreArreglado: 'Fecha de creación del caso:',
        tipo: 'NORMAL'
      }
      ], [
        {
          nombre: 'documentoOrigen',
          nombreArreglado: 'Documento de origen de la información:',
          tipo: 'NORMAL'
        }
      ],
      [
        {
          nombre: 'archivosDocumentoOrigen',
          nombreArreglado: 'Archivos de origen de la información',
          tipo: 'MULTIPLEQR'
        }
      ], [
        {
          nombre: 'fechaDocumentoOrigen',
          nombreArreglado: 'Fecha del documento de origen',
          tipo: 'NORMAL'
        },
        {
          nombre: 'radicado',
          nombreArreglado: 'Radicado',
          tipo: 'NORMAL'
        }
      ], [
        {
          nombre: 'otrosDocumentos',
          nombreArreglado: 'Otros documentos',
          tipo: 'MULTIPLEQR'
        }
      ], [
        {
          nombre: 'departamento',
          nombreArreglado: 'Departamento',
          tipo: 'NORMAL'
        },
        {
          nombre: 'municipio',
          nombreArreglado: 'Municipio/Ciudad',
          tipo: 'NORMAL'
        }
      ]
    ];

    for (let row of rows) {
      let j = 0;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      for (let field of row) {
        let i = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        if (field.tipo === 'NORMAL') {
          this.setSubtitleStyle();
          this.doc.text(field.nombreArreglado, 15 + (j * 90), this.finalY + (i * 10));
          i++;
          this.setNormalStyle();
          this.doc.text(this.fichaGeneralData[field.nombre], 15 + (j * 90), this.finalY + (i * 10) - 3);
          j++;
        } else if (field.tipo === 'MULTIPLEQR') {
          this.setSubtitleStyle();
          this.doc.text(field.nombreArreglado, 15, this.finalY + (i * 10));
          this.setNormalStyle();
          const multipleFileArray: Archivo[] = this.fichaGeneralData[field.nombre];
          let right = 0;
          for (let file of multipleFileArray) {
            const url = await QRCode.toDataURL(file.url);
            if (right > 6) {
              right = 0;
              this.finalY += 20;
              this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
              this.doc.addImage(url, 'JPEG', 15 + (right * 25), this.finalY + 2, 20, 20);
            } else {
              this.doc.addImage(url, 'JPEG', 15 + (right * 25), this.finalY + 2, 20, 20);
            }
            right++;
          }
          this.finalY += 15;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        }
      }
      this.finalY += 18;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
  }

  async getPeopleInfo() {
    const rowsInfractors = [
      [
        {
          nombre: 'nombreInfractor',
          nombreArreglado: 'Nombres',
        },
        {
          nombre: 'apellidoInfractor',
          nombreArreglado: 'Apellidos',
        },
      ],
      [
        {
          nombre: 'tipoIdentificacionInfractor',
          nombreArreglado: 'Tipo de identificación',
        },
        {
          nombre: 'identificacionInfractor',
          nombreArreglado: 'Identificación',
        },
      ],
      [
        {
          nombre: 'nivelEducativoInfractor',
          nombreArreglado: 'Nivel educativo',
        },
        {
          nombre: 'ocupacionInfractor',
          nombreArreglado: 'Ocupación',
        },
      ],
      [
        {
          nombre: 'tipoVulnerabilidadInfractor',
          nombreArreglado: 'Tipo de población vulnerable',
        },
        {
          nombre: 'edadInfractor',
          nombreArreglado: 'Edad',
        },
      ],
      [
        {
          nombre: 'direccionInfractor',
          nombreArreglado: 'Dirección residencia',
        },
        {
          nombre: 'telefonoInfractor',
          nombreArreglado: 'Teléfono',
        },
      ],
      [
        {
          nombre: 'correoInfractor',
          nombreArreglado: 'Correo'
        }
      ]
    ]
    const rowsPetitioners = [
      [
        {
          nombre: 'nombrePeticionario',
          nombreArreglado: 'Nombres',
        },
        {
          nombre: 'apellidoPeticionario',
          nombreArreglado: 'Apellidos',
        },
      ],
      [
        {
          nombre: 'tipoIdentificacionPeticionario',
          nombreArreglado: 'Tipo de identificación',
        },
        {
          nombre: 'identificacionPeticionario',
          nombreArreglado: 'Identificación',
        },
      ],
      [
        {
          nombre: 'nivelEducativoPeticionario',
          nombreArreglado: 'Nivel educativo',
        },
        {
          nombre: 'ocupacionPeticionario',
          nombreArreglado: 'Ocupación',
        },
      ],
      [
        {
          nombre: 'tipoVulnerabilidadPeticionario',
          nombreArreglado: 'Tipo de población vulnerable',
        },
        {
          nombre: 'edadPeticionario',
          nombreArreglado: 'Edad',
        },
      ],
      [
        {
          nombre: 'direccionPeticionario',
          nombreArreglado: 'Dirección residencia',
        },
        {
          nombre: 'telefonoPeticionario',
          nombreArreglado: 'Teléfono',
        },
      ],
      [
        {
          nombre: 'correoPeticionario',
          nombreArreglado: 'Correo'
        }
      ]
    ]
    for (let infractor of this.fichaGeneralData.infractores) {
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.setTitleStyle();
      this.doc.line(15, this.finalY - 8, 200, this.finalY - 8);
      this.doc.text(`Información del infractor ${this.fichaGeneralData.infractores.indexOf(infractor) + 1}`, 15, this.finalY);
      this.finalY += 10;
      this.setNormalStyle();
      for (let row of rowsInfractors) {
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        for (let field of row) {
          let i = 0;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
          this.setSubtitleStyle();
          this.doc.text(field.nombreArreglado, 15 + (j * 90), this.finalY + (i * 10));
          i++;
          this.setNormalStyle();
          this.doc.text(infractor[field.nombre] ? String(infractor[field.nombre]) : 'No definido', 15 + (j * 90), this.finalY + (i * 10) - 3);
          j++;
        }
        this.finalY += 18;
      }
    }

    for (let peticionario of this.fichaGeneralData.peticionarios) {
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.setTitleStyle();
      this.doc.line(15, this.finalY - 8, 200, this.finalY - 8);
      this.doc.text(`Información del peticionario ${this.fichaGeneralData.peticionarios.indexOf(peticionario) + 1}`, 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.setNormalStyle();
      for (let row of rowsPetitioners) {
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        for (let field of row) {
          let i = 0;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
          this.setSubtitleStyle();
          this.doc.text(field.nombreArreglado, 15 + (j * 90), this.finalY + (i * 10));
          i++;
          this.setNormalStyle();
          this.doc.text(peticionario[field.nombre] ? String(peticionario[field.nombre]) : 'No definido', 15 + (j * 90), this.finalY + (i * 10) - 3);
          j++;
        }
        this.finalY += 18;
      }
    }
  }

  async getLastInfo() {
    this.setTitleStyle();
    this.doc.text('Resumen de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.resumenHechos, 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.fichaGeneralData.resumenHechos);
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text('Fecha', 15, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.fechaHechos.toString() || 'No definido', 15, this.finalY + 10, { maxWidth: 90 });
    this.setTitleStyle();
    this.doc.text('Hora', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.horaHechos, 105, this.finalY + 10, { maxWidth: 90 });
    this.finalY += 20;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text(`Tipo de localidad de los hechos: ${this.fichaGeneralData.tipoLocalidadHechos}`, 15, this.finalY);
    this.setNormalStyle();

    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text('Lugar de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.lugarHechos, 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text('Dirección de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.direccionHechos, 15, this.finalY);
    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text('Detalle del lugar de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.fichaGeneralData.detalleLugarHechos, 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.fichaGeneralData.detalleLugarHechos);
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
  }

  async getCCCsInfo() {

    const rows = [
      [
        {
          nombre: 'titulo',
          nombreArreglado: 'Título',
        },
        {
          nombre: 'capitulo',
          nombreArreglado: 'Capítulo',
        }
      ],
      [
        {
          nombre: 'articulo',
          nombreArreglado: 'Artículo',
        },
        {
          nombre: 'numeral',
          nombreArreglado: 'Numeral',
        }
      ],
      [
        {
          nombre: 'literal',
          nombreArreglado: 'Literal',
        },
      ],
      [
        {
          nombre: 'descripcionComportamiento',
          nombreArreglado: 'Descripción del comportamiento',
        }
      ],
    ]

    for (let ccc of this.fichaGeneralData.CCC) {
      this.setTitleStyle();
      this.doc.text(`Comportamiento contrario a la convivencia #${this.fichaGeneralData.CCC.findIndex(c => c == ccc) + 1}`, 15, this.finalY);
      this.setNormalStyle();
      for (let row of rows) {
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY }) + 15;
        for (let field of row) {
          let i = 0;
          this.setTitleStyle();
            this.doc.text(field.nombreArreglado, 15 + (j * 90), this.finalY + (i * 10));
            i++;
            this.setNormalStyle();
            if (ccc[field.nombre]) {
              if (ccc[field.nombre].length > 140) {
                this.doc.text(String(ccc[field.nombre].substring(0, 140) + '...'), 15 + (j * 90), this.finalY + (i * 10), { maxWidth: 90 });
              } else {
                this.doc.text(String(ccc[field.nombre].substring(0, ccc[field.nombre].length)), 15 + (j * 90), this.finalY + (i * 10), { maxWidth: 90 });
              }
            } else {
              this.doc.text(String('No asignado'), 15 + (j * 90), this.finalY + (i * 10), { maxWidth: 90 });
            }
            j++;
        }
        this.finalY += this.getMoreHeightFromTextHeight(ccc, row);
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      }
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
  }

  async getPVAGeneralInfo() {
  }


  /**
   * Gets the height to add from certain numbers of characters, because with more characters, more lines, this works with half-width texts
   * @param {any} object The object that is being reviewed
   * @param {{ nombre: string, nombreArreglado: string }[]} row The current row (of the pdf)
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeight(object, row: { nombre: string, nombreArreglado: string }[]): number {
    const biggestChainLength = this.findLongestChainLength(row.map(r => object[r.nombre]));
    if (biggestChainLength > 140) {
      return 18;
    } else if (biggestChainLength <= 140 && biggestChainLength > 100) {
      return 15;
    } else if (biggestChainLength <= 100) {
      return 12;
    }
    return 12;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    if (string.length > 240) {
      return 18;
    } else if (string.length <= 240 && string.length > 160) {
      return 15;
    } else if (string.length <= 160) {
      return 12;
    }
    return 12;
  }

  /**
   * Get the longest string from an array of strings;
   * @param {string[]} strings El array
   * @returns {number} the string length
   */
  findLongestChainLength(strings: string[]): number {
    const longest = strings.reduce((longestString, currentString) => {
      return currentString && currentString.length > longestString.length ? currentString : longestString;
    });
    return longest ? longest.length : 0;
  }

  /**
   * Le da estilo al texto del PDF para títulos principales
   */
  setTitleStyle() {
    this.doc.setFont('Helvetica', 'bold');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Le da estilo al texto del PDF para subtitulos
   */
  setSubtitleStyle() {
    this.doc.setFont('Helvetica', 'bold');
    this.doc.setFontSize(11);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Le da estilo al texto del PDF para texto normal
   */
  setNormalStyle() {
    this.doc.setFont('Helvetica', 'normal');
    this.doc.setFontSize(12);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  async verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): Promise<number> {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      this.doc.addPage();
      this.doc = await this.utilsService.getPDFHeader({ doc, numCaso: this.fichaGeneralData.caso.numCaso, numInspeccion: this.fichaGeneralData.caso.numInspeccion });
      console.log('el documento aqui', this.doc);
      return 40;
    } else {
      return value;
    }
  }

  /**
   * Cut the infractor.edadInfractor ngModel value when detects changes
   * @param value Input event value
   */
  onChangeInfractorAge(value: string) {
    this.infractor.edadInfractor = Number(this.limitValueCharactersLength(value, 2));
    this.infractorEdadInput.nativeElement.value = `${this.infractor.edadInfractor}`;
  }

  /**
   * Cut the peticionario.edadPeticionario ngModel value when detects changes
   * @param value Input event value
   */
  onChangePeticionarioAge(value: string) {
    this.peticionario.edadPeticionario = Number(this.limitValueCharactersLength(value, 2));
    this.peticionarioEdadInput.nativeElement.value = `${this.peticionario.edadPeticionario}`;
  }

  /**
  * Cut an string or number value to a limit length and return the new value
  * @param value Target value to cut
  * @param limit Characters length limit
  */
  limitValueCharactersLength(value: number | string, limit: number) {
    const newValue = `${value}`.substring(0, limit).toUpperCase();
    return newValue;
  }
}
