import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../services/utils.service';
import { Comentario, Foro, Inspector, RespuestaComentario } from '../../inspecciones.interface';
import { ForoService } from '../foro.service';

@Component({
  selector: 'app-foro-ver',
  templateUrl: './foro-ver.component.html',
  styleUrls: ['./foro-ver.component.css']
})
export class ForoVerComponent implements OnInit {

  constructor(
    private utilsService: UtilsService,
    private forumService: ForoService,
    private route: ActivatedRoute,
  ) { }

  forumId: string;

  comentariosVisibles: boolean[] = [];
  answerCommentIndex: number;
  wantAnswer: boolean = false;
  savedComment: Comentario;

  comentario: Comentario = {
    descripcion: '',
    autor: undefined,
    respuestas: [],
  }

  foros: Foro[] = [];

  foro: Foro = {
    nombre: '',
    descripcion: '',
    preguntaOrientadora: '',
    autor: undefined,
    comentarios: []
  }

  ngOnInit() {
    this.forumId = this.route.snapshot.params['id'];
    this.getAllForums();
    this.getForum();
  }

  /**
   * Obtiene todos los foros
   */
  getAllForums(): void {
    this.forumService.getAllForums().then(foros => {
      this.foros = foros.filter(foro => foro._id !== this.forumId);
    })
  }

  /**
   * Obtiene el foro relacionado al id de la URL
   */
  getForum(): void {
    this.forumService.getForumById(this.forumId).then(foro => {
      this.foro = foro;
    })
  }

  /**
   * Formatea la fecha en DD/MM/AAAA
   * @param {Date} date La fecha en formato Date
   * @returns {string} con la fecha formateada
   */
  formatDate(date: Date): string {
    return this.utilsService.formatDate(date, '/');
  }

  /**
   * Añade un comentario a la base de datos
   */
  addComment(){
    const inspector: Inspector = JSON.parse(localStorage.getItem('inspector'));
    this.comentario.autor = inspector._id;
    if(this.comentario.descripcion != '' && this.comentario.descripcion != undefined && this.comentario.autor != undefined){
      this.forumService.postComment(this.foro._id, this.comentario).then((foro) => {
        this.foro = foro;
        this.comentario.descripcion = '';

        Swal.fire({
          title: 'Éxito',
          text: 'Comentario publicado exitosamente',
          icon: 'success'
        });
        this.getForum();
      })
    }
  }

  /**
   * Indica si quiere ver las respuestas de un comentario en partícular o no
   * @param index El indice del comentario en la lista de comentarios
   */
  seeCommentAnswers(index: number): void {
    if(!this.comentariosVisibles[index]){
      this.comentariosVisibles[index] = true;
    } else {
      this.comentariosVisibles[index] = false;
    }
  }

  /**
   * Indica que quiere agregar una nueva respuesta
   * @param index El indice del comentario en la lista de comentarios
   * @param comentario El comentario
   */
  addAnswer(index: number, comentario: Comentario): void {
    this.answerCommentIndex = index;
    this.savedComment = comentario;
    this.wantAnswer = true;
  }

  /**
   * Envia una respuesta a un comentario del foro
   */
  createAnswer(){
    const inspector: Inspector = JSON.parse(localStorage.getItem('inspector'));
    this.comentario.autor = inspector._id;
    if(this.comentario.descripcion != '' && this.comentario.descripcion != undefined && this.comentario.autor != undefined){
      this.forumService.postAnswer(this.foro._id, this.savedComment._id, this.comentario).then((foro) => {
        this.foro = foro;
        this.comentario.descripcion = '';
        this.wantAnswer = false;
        this.savedComment = undefined;
        this.answerCommentIndex = undefined;
        Swal.fire({
          title: 'Éxito',
          text: 'Comentario publicado exitosamente',
          icon: 'success'
        });
      })
    }
  }

  /**
   * Obtiene la fecha de la última respuesta
   * @param {RespuestaComentario[]} respuestas lista de respuestas de un comentario
   * @returns {string} hace cuánto fue la última fecha
   */
  getLastResponseTime(respuestas: RespuestaComentario[]): string {
    const fechaAntigua = respuestas.map(respuesta => respuesta.createdAt).sort().reverse()[0];
    return moment(fechaAntigua).locale('ES-co').fromNow();
  }

  /**
   * Obtiene la fecha de la respuesta
   * @param {RespuestaComentario} respuesta la respuesta del comentario
   * @returns hace cuánto fue la fecha
   */
  getResponseTime(respuesta: RespuestaComentario): string {
    return moment(respuesta.createdAt).utc(false).locale('ES-co').fromNow();
  }
}
