import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotInspeccionesComponent } from './forgot-inspecciones/forgot-inspecciones.component';
import { AuthGuard } from './guards/auth.guard';
import { BancoEstrategiasComponent } from './inspecciones/banco-estrategias/banco-estrategias.component';
import { BibliografiaInspeccionesComponent } from './inspecciones/bibliografia-inspecciones/bibliografia-inspecciones.component';
import { BibliotecaInspeccionesComponent } from './inspecciones/biblioteca-inspecciones/biblioteca-inspecciones.component';
import { CalendarioComponent } from './inspecciones/calendario/calendario.component';
import { EstadisticasInspeccionesComponent } from './inspecciones/estadisticas-inspecciones/estadisticas-inspecciones.component';
import { ForoInspeccionesComponent } from './inspecciones/foro-inspecciones/foro-inspecciones.component';
import { ForoVerComponent } from './inspecciones/foro-inspecciones/foro-ver/foro-ver.component';
import { GestionCasosInspeccionesComponent } from './inspecciones/gestion-casos-inspecciones/gestion-casos-inspecciones.component';
import { MecanismoIntermedioComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/mecanismo-intermedio.component';
import { MecanismoPrevioComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/mecanismo-previo.component';
import { NuevoCasoComponent } from './inspecciones/gestion-casos-inspecciones/nuevo-caso/nuevo-caso.component';
import { PvaCasoComponent } from './inspecciones/gestion-casos-inspecciones/pva/pva-caso.component';
import { ReporteFinalIntermedioComponent } from './inspecciones/gestion-casos-inspecciones/reporte-final-intermedio/reporte-final-intermedio.component';
import { ReporteFinalPrevioComponent } from './inspecciones/gestion-casos-inspecciones/reporte-final-previo/reporte-final-previo.component';
import { SeguimientoEvaluacionComponent } from './inspecciones/gestion-casos-inspecciones/seguimiento-evaluacion/seguimiento-evaluacion.component';
import { VerCasoComponent } from './inspecciones/gestion-casos-inspecciones/ver-caso/ver-caso.component';
import { InspeccionesInicioComponent } from './inspecciones/inspecciones-inicio/inspecciones-inicio.component';
import { JurisprudenciaInspeccionesComponent } from './inspecciones/jurisprudencia-inspecciones/jurisprudencia-inspecciones.component';
import { LegislacionInspeccionesComponent } from './inspecciones/legislacion-inspecciones/legislacion-inspecciones.component';
import { RecursosAdicionalesInspeccionesComponent } from './inspecciones/recursos-adicionales-inspecciones/recursos-adicionales-inspecciones.component';
import { MecanismosIntermediosComponent } from './inspecciones/submodulos-inspecciones/mecanismos-intermedios/mecanismos-intermedios.component';
import { MecanismosPreviosComponent } from './inspecciones/submodulos-inspecciones/mecanismos-previos/mecanismos-previos.component';
import { PremisasFundamentalesComponent } from './inspecciones/submodulos-inspecciones/premisas-fundamentales/premisas-fundamentales.component';
import { PvaComponent } from './inspecciones/submodulos-inspecciones/pva/pva.component';
import { SubmodulosInspeccionesComponent } from './inspecciones/submodulos-inspecciones/submodulos-inspecciones.component';
import { VistaConsultaComponent } from './inspecciones/vista-consulta/vista-consulta.component';
import { LoginInspeccionesComponent } from './login-inspecciones/login-inspecciones.component';
import {ResetInspectorComponent} from "./reset-inspector/reset-inspector.component";

const routes: Routes = [
  {
    path: 'login-inspecciones',
    component: LoginInspeccionesComponent,
    //canActivate: [LoggedInGuard]
  },
  {
    path: 'recovery-password',
    component: ResetInspectorComponent,
  },
  {
    path: 'contrasena-olvidada-inspecciones',
    component: ForgotInspeccionesComponent,
  },
  {
    path: 'inicio-inspecciones',
    component: InspeccionesInicioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones',
    component: GestionCasosInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/nuevo-caso',
    component: NuevoCasoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/:id',
    component: VerCasoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/mecanismo-previo/:id',
    component: MecanismoPrevioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/mecanismo-intermedio/:id',
    component: MecanismoIntermedioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/pva/:id',
    component: PvaCasoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/seguimiento-evaluacion/:id',
    component: SeguimientoEvaluacionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/mecanismo-previo/informe-final/:id',
    component: ReporteFinalPrevioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion-casos-inspecciones/ver-caso/mecanismo-intermedio/informe-final/:id',
    component: ReporteFinalIntermedioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'biblioteca-inspecciones',
    component: BibliotecaInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'biblioteca-inspecciones/banco-estrategias',
    component: BancoEstrategiasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submodulos-inspecciones',
    component: SubmodulosInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submodulos-inspecciones/premisas-fundamentales',
    component: PremisasFundamentalesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submodulos-inspecciones/mecanismos-previos',
    component: MecanismosPreviosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submodulos-inspecciones/mecanismos-intermedios',
    component: MecanismosIntermediosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submodulos-inspecciones/pva',
    component: PvaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jurisprudencia-inspecciones',
    component: JurisprudenciaInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'legislacion-inspecciones',
    component: LegislacionInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'recursos-adicionales-inspecciones',
    component: RecursosAdicionalesInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bibliografia-inspecciones',
    component: BibliografiaInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'foro-inspecciones',
    component: ForoInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'foro-inspecciones/foro/:id',
    component: ForoVerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'calendario-inspecciones',
    component: CalendarioComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'estadisticas-inspecciones',
    component: EstadisticasInspeccionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'consulta-externa',
    component: VistaConsultaComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login-inspecciones'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
