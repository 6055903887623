import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../services/main.service';
import Swal from 'sweetalert2';
import * as QRCode from 'qrcode';
import { jsPDF } from 'jspdf';
import { ActividadIntervencionMecanismoPrevio, Caso, Compromiso, FichaGeneral, Infractor, Inspector, MecanismoPrevio, Seguimiento, SeguimientoMecanismoPrevio } from '../../inspecciones.interface';
import { FaseDiagnosticaComponent } from '../mecanismo-previo/fase-diagnostica/fase-diagnostica.component';
import { UtilsService } from '../../../services/utils.service';
import { MecanismoPrevioService } from '../mecanismo-previo/mecanismo-previo.service';

@Component({
  selector: 'app-reporte-final-previo',
  templateUrl: './reporte-final-previo.component.html',
  styleUrls: ['./reporte-final-previo.component.css']
})
export class ReporteFinalPrevioComponent implements OnInit {

  /** Ids */
  caseId: string = '';
  mecPrevId: string = '';
  generalFileId: string = '';

  finalY: number = 15;

  diagnostico: Pick<FaseDiagnosticaComponent, 'tipoCaso' | 'casosAsociados'> = {
    tipoCaso: '',
    casosAsociados: []
  };
  seguimiento: Seguimiento = {
    observaciones: '',
  };
  intervencion: any = {};
  inspector: Inspector = {
    nombre: '',
    apellido: '',
    email: '',
    password: '',
    telefonoInspector: '',
    telefonoOficina: '',
    aceptado: false,
    tipoDocumento: '',
    numDocumento: 0,
    codigoInspector: '',
    municipio: undefined
  }
  infractores: Infractor[] = [];
  fichaGeneral = {
    municipio: '',
    numCaso: ''
  }
  mecPrevio: MecanismoPrevio = {
    firmaInspector: undefined,
    razonIncumplimientoObjetivos: '',
    cumplimientoObjetivos: undefined,
    nombreAuxAdm: '',
    firmaAuxAdm: undefined,
    createdAt: undefined,
    caso: '',
    compromisos: []
  }
  tiposActividades = [];
  actividades: ActividadIntervencionMecanismoPrevio[] = [];
  seguimientos: SeguimientoMecanismoPrevio[] = [];
  compromisos: Compromiso[] = [];
  observaciones: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private mecPrevService: MecanismoPrevioService,
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getFullData()
  }


  async getFullData() {
    const inspector = JSON.parse(localStorage.getItem('inspector'));
    const fichaGeneral = await this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.caseId}`);
    const mecanismoPrev = await this.mainService.get<MecanismoPrevio>(`api/mec-previo-caso/caso/${this.caseId}`);
    fichaGeneral.subscribe(response => {
      if(this.mainService.isSuccess<FichaGeneral>(response)){
        const bd = response.result
        this.generalFileId = bd._id;
        this.inspector.nombre = `${inspector.nombre} ${inspector.apellido}`;
        this.infractores = bd.infractores;
        this.fichaGeneral.municipio = bd.municipio;
        this.fichaGeneral.numCaso = bd.caso.numCaso;
      } else {
        console.error(response.error);
      }
    });
    mecanismoPrev.subscribe(response => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        const bd = response.result;
        this.mecPrevId = bd._id;
        this.mecPrevio.cumplimientoObjetivos = bd.cumplimientoObjetivos;
        this.mecPrevio.razonIncumplimientoObjetivos = bd.razonIncumplimientoObjetivos;
        this.mecPrevio.firmaInspector = bd.firmaInspector;
        this.mecPrevio.firmaAuxAdm = bd.firmaAuxAdm;
        this.mecPrevio.nombreAuxAdm = bd.nombreAuxAdm;
        this.mecPrevio.comentariosRecomendaciones = bd.comentariosRecomendaciones;
        this.diagnostico.tipoCaso = bd.faseDiagnostica.tipoCaso;
        this.diagnostico.casosAsociados = bd.faseDiagnostica.casosAsociados;
        this.actividades = bd.faseIntervencion.actividades;
        this.tiposActividades = bd.faseIntervencion.actividades.map(act => act.tipoActividad);
        this.tiposActividades = this.tiposActividades.filter((value, index) => {
          return this.tiposActividades.indexOf(value) === index;
        })
        this.seguimientos = bd.faseSeguimiento.seguimientos;
        this.observaciones = bd.faseSeguimiento.observaciones
        this.compromisos = bd.compromisos;
      } else {
        console.error(response.error);
      }
    });
  }

  /**
   * Gets the case numbers of a list of cases
   * @param {Caso[]} casosAsociados The list of cases
   * @returns {string} the case numbers
   */
  getAssociatedCases(casosAsociados: Caso[]){
    return casosAsociados.map(caso => caso.numCaso).join(', ').toString();
  }

  changeAccomplishment(status: 'yes' | 'no' | 'partially'){
    if(
      (status === 'yes' && this.mecPrevio.cumplimientoObjetivos !== 'yes') ||
      (status === 'no' && this.mecPrevio.cumplimientoObjetivos !== 'no') ||
      (status === 'partially' && this.mecPrevio.cumplimientoObjetivos !== 'partially')
    ) {
      this.mecPrevio.cumplimientoObjetivos = status;
    } else {
      this.mecPrevio.cumplimientoObjetivos = undefined
    }
  }

  saveFinalReport(f: NgForm){
    const mecPrevUpdate = {
      firmaInspector: this.mecPrevio.firmaInspector,
      razonIncumplimientoObjetivos: this.mecPrevio.razonIncumplimientoObjetivos,
      cumplimientoObjetivos: this.mecPrevio.cumplimientoObjetivos,
      nombreAuxAdm: this.mecPrevio.nombreAuxAdm,
      firmaAuxAdm: this.mecPrevio.firmaInspector,
      comentariosRecomendaciones: this.mecPrevio.comentariosRecomendaciones
    }
    this.mainService.put<MecanismoPrevio>(`api/mec-previo-caso/${this.mecPrevId}`, mecPrevUpdate).subscribe(response => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        Swal.fire(
          'Éxito',
          'Reporte final actualizado con éxito',
          'success'
        )
      } else {
        Swal.fire(
          'Error',
          'No se pudo guardar exitosamente el reporte final',
          'warning'
        );
      }
    });
  }

  async descargarPDF(){
    const cumplimientoObjetivos = {
      yes: 'Sí',
      no: 'No',
      partially: 'Parcialmente'
    }
    let doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = this.setTitleStyle(doc);
    doc.text('Informe final', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc.text('Tipo de caso', 15, this.finalY);
    doc.text('Casos asociados', 105, this.finalY);
    doc = this.setNormalStyle(doc);
    doc.text(this.diagnostico.tipoCaso || 'No definido', 15, this.finalY+10);
    doc.text(this.getAssociatedCases(this.diagnostico.casosAsociados) || 'No definido', 105, this.finalY+10);
    this.finalY += 30;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc = this.getInfractosInfo(doc);
    doc = this.getActivitiesTypes(doc);
    doc = this.getActivitiesSummary(doc)
    doc = this.getFollowUpsDescription(doc);
    doc = this.getCompromisesVerification(doc);

    doc = this.setTitleStyle(doc);
    doc.text(`¿Acciones cumplieron con su objetivo? ${cumplimientoObjetivos[this.mecPrevio.cumplimientoObjetivos] || 'No definido'}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc.text('Observaciones', 15, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc.text(this.observaciones || 'No definidas', 15, this.finalY)
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.observaciones || 'No definidas');
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc = this.setTitleStyle(doc);
    doc.text('Comentarios y recomendaciones', 15, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc.text(this.mecPrevio.comentariosRecomendaciones || 'No definidos', 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.mecPrevio.comentariosRecomendaciones || 'No definidos');
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc = this.setTitleStyle(doc);
    doc.text('Municipio', 15, this.finalY);
    doc.text('Inspección No.', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = this.setNormalStyle(doc);

    doc.text(this.fichaGeneral.municipio || 'No definido', 15, this.finalY);
    doc.text(this.fichaGeneral.numCaso || 'No definido', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = await this.getSignatures(doc);

    doc.save(`Informe final mecanismo previo caso ${this.fichaGeneral.numCaso} ${this.formatDate(new Date())}.pdf`);
  }

  getInfractosInfo(doc){
    const row = [
      {
        nombre: 'nombreInfractor',
      },
      {
        nombre: 'apellidoInfractor'
      }
    ];
    doc = this.setTitleStyle(doc);
    doc.text('Presunto(s) infractor(es)', 15, this.finalY);
    this.finalY += 10;
    doc.text('Nombre', 15, this.finalY);
    doc.text('Apellido', 105, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    for(let infractor of this.infractores){
      let j = 0;
      for(let field of row){
        doc.text(infractor[field.nombre], 15+(j*90), this.finalY);
        j++;
      }
      this.finalY += 5;
    }
    this.finalY += 10;
    return doc;
  }

  getActivitiesTypes(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Tipos de actividades realizadas', 15, this.finalY);
    this.finalY += 10;
    doc = this.setNormalStyle(doc);
    for(let actividad of this.tiposActividades){
      doc.text(actividad, 15, this.finalY);
      this.finalY += 5;
    }
    this.finalY += 10;
    return doc;
  }

  getActivitiesSummary(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Resumen de la actividad desarrollada', 15, this.finalY);
    this.finalY += 10;
    doc = this.setNormalStyle(doc);
    for(let actividad of this.actividades){
      doc.text(`${this.formatDate(actividad.fechaActividad)}: ${actividad.resumenActividad || 'No existe un resumen para esta actividad'}`, 15, this.finalY);
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(`${this.formatDate(actividad.fechaActividad)}: ${actividad.resumenActividad || 'No existe un resumen para esta actividad'}`);
    }
    this.finalY += 10;
    return doc;
  }

  getFollowUpsDescription(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Descripción del seguimiento', 15, this.finalY);
    this.finalY += 10;
    doc = this.setNormalStyle(doc);
    for(let seguimiento of this.seguimientos){
      doc.text(`${this.formatDate(seguimiento.fechaSeguimiento)}: ${seguimiento.descripcionSeguimiento || 'No existe una descripción para este seguimiento'}`, 15, this.finalY);
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(`${this.formatDate(seguimiento.fechaSeguimiento)}: ${seguimiento.descripcionSeguimiento || 'No existe una descripción para este seguimiento'}`);
    }
    this.finalY += 10;
    return doc
  }

  getCompromisesVerification(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Verificación del cumplimiento de objetivos', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = this.setNormalStyle(doc);
    for(let compromiso of this.compromisos){
      doc.text(`${compromiso.descripcion}: ${compromiso.cumplido ? 'Sí' : 'No'}`, 15, this.finalY);
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(`${compromiso.descripcion}: ${compromiso.cumplido ? 'Sí' : 'No'}`);
    }
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    return doc;
  }

  async getSignatures(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Nombre auxiliar administrativo', 15, this.finalY);
    doc.text('Firma auxiliar administrativo', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = this.setNormalStyle(doc);
    doc.text(this.mecPrevio.nombreAuxAdm || 'No definido', 15, this.finalY);

    if(this.mecPrevio.firmaAuxAdm){
      const url = await QRCode.toDataURL(this.mecPrevio.firmaAuxAdm.url);
      doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 20;
    } else {
      doc.text('No definido', 105, this.finalY);
      this.finalY += 15;
    }
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    doc = this.setTitleStyle(doc);
    doc.text('Nombre iInspector de Policía', 15, this.finalY);
    doc.text('Firma Inspector de Policía', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    doc = this.setNormalStyle(doc);
    doc.text(`${this.inspector.nombre || ''} ${this.inspector.apellido || ''}` || 'No definido', 15, this.finalY);

    if(this.mecPrevio.firmaInspector){
      const url = await QRCode.toDataURL(this.mecPrevio.firmaInspector.url);
      doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 20;
    } else {
      doc.text('No definido', 105, this.finalY);
      this.finalY += 15;
    }
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    return doc;
  }

  setTitleStyle(doc){
    doc.setFont('bold');
    doc.setFontSize(14);
    doc.setTextColor('#332F2E');
    return doc;
  }

  setNormalStyle(doc){
    doc.setFont('normal');
    doc.setFontSize(13);
    doc.setTextColor('#332F2E');
    return doc;
  }

  /**
   * Formatea una fecha en formato DD/Ene/AAAA
   * @param {Date} date La fecha a formatear
   * @returns {string} el texto formateado
   */
  formatDate(date: Date | string){
    return this.utilsService.formatDate(date);
  }

  /**
   * Procesa el evento de cargue de foto a s3 y devuelve el string con el link del archivo
   * @param event El evento del input
   * @param {'firma-aux-adm' | 'firma-inspector'} tipo el tipo de campo que se va a modificar
   */
  onFileChange(event, tipo: 'firma-aux-adm' | 'firma-inspector'): void {
    const file: File = event.target.files[0];
    this.mecPrevService.saveInS3(file).then((url: string) => {
      if(tipo === 'firma-aux-adm'){
        this.mecPrevio.firmaAuxAdm = {
          tipo: file.type,
          url
        };
      } else if(tipo === 'firma-inspector'){
        this.mecPrevio.firmaInspector = {
          tipo: file.type,
          url
        }
      }
    })
  }

  onGoBack(){
    this.router.navigateByUrl(`/gestion-casos-inspecciones/ver-caso/mecanismo-previo/${this.caseId}`);
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    if(string.length > 240){
      return 18;
    } else if (string.length <= 240 && string.length > 160){
      return 15;
    } else if (string.length <= 160){
      return 12;
    }
    return 12;
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }
}
