import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { MainService } from '../../../services/main.service';
import { S3Service } from '../../../services/s3.service';
import Swal from 'sweetalert2';
import { Caso, CitadoInfo, FichaGeneral, Pva } from '../../inspecciones.interface';

@Injectable()
export class PvaService {

  bucket: S3;

  constructor(
    private mainService: MainService,
    private s3Service: S3Service
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  /**
   * Obtiene toda la información del proceso verbal abreviado a partir de su id
   * @param id El id del proceso verbal abreviado
   * @returns La información general del PVA
   */
  getAllInfo(id): Promise<Pva> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Pva>(`api/pva-caso/${id}`).subscribe(response => {
        if(this.mainService.isSuccess<Pva>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene toda la información del proceso verbal abreviado a partir del id de su caso relacionado
   * @param caseId El id del caso
   * @returns La información general del PVA
   */
  getAllInfoByCase(caseId: string): Promise<Pva> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Pva>(`api/pva-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<Pva>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene la información de un caso a partir de su id
   * @param {string} caseId El id del caso
   * @returns {Promise<Caso>} El caso encontrado
   */
  getCaseInfo(caseId: string): Promise<Caso> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Caso>(`api/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<Caso>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }


  /**
   * Obtiene la información de la ficha general
   * @param {string} caseId El id del caso 
   * @returns {Promise<any>} 
   */
  getGeneralFileInfo(caseId: string): Promise<FichaGeneral> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          resolve(bd);
        }
      })
    })
  }

  /**
   * Obtiene las pruebas generales relacionadas a un caso, no a una audiencia en partícular
   * @param {string} caseId El id del caso 
   * @returns {Promise<any>} Las pruebas generales y practicadas
   */
  getGeneralEvidences(caseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Pva>(`api/pva-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<Pva>(response)){
          const bd = response.result;
          const { pruebasGeneralesAportadas, pruebasPracticadas } = bd;
          console.log(bd)
          resolve({
            pruebasGeneralesAportadas,
            pruebasPracticadas
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Hubo un error al obtener las pruebas:' + response.error,
            icon: 'error'
          });
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene la información adicional de los citados a una audiencia
   * @param {string} caseId El id del caso 
   * @param {string} identificacion Identificacion del citado 
   * @returns {Promise<CitadoInfo>} Información extra del citado
   */
  findQuoted(caseId: string, identificacion: string): Promise<CitadoInfo>{
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          const { infractores, peticionarios } = bd;
          for(let infractor of infractores){
            if(infractor.identificacionInfractor === identificacion){
              let nombreCompleto
              if(infractor.nombreInfractor && infractor.apellidoInfractor){
                nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
              } else if (infractor.nombreInfractor){
                nombreCompleto = infractor.nombreInfractor;
              } else if (infractor.apellidoInfractor){
                nombreCompleto = infractor.apellidoInfractor;
              } else {
                return
              }
              resolve({
                nombreCompleto,
                nombre: infractor.nombreInfractor,
                apellido: infractor.apellidoInfractor,
                tipoIdentificacion: infractor.tipoIdentificacionInfractor,
                identificacion: infractor.identificacionInfractor,
                nivelEducativo: infractor.nivelEducativoInfractor,
                ocupacion: infractor.ocupacionInfractor,
                tipoVulnerabilidad: infractor.tipoVulnerabilidadInfractor,
                direccion: infractor.direccionInfractor,
                telefono: infractor.telefonoInfractor,
                correo: infractor.correoInfractor
              });
            }
          }
          for(let peticionario of peticionarios){
            if(peticionario.identificacionPeticionario === identificacion){
              resolve({
                nombreCompleto: peticionario.nombrePeticionario,
                nombre: peticionario.nombrePeticionario,
                tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
                identificacion: peticionario.identificacionPeticionario,
                direccion: peticionario.direccionPeticionario,
                telefono: peticionario.telefonoPeticionario,
                correo: peticionario.correoPeticionario,
              });
            }
          }
        }
      })
    })
  }

  /**
   * Obtiene la información adicional de los citados a una audiencia
   * @param {string} caseId El id del caso 
   * @param {string[]} identificaciones Lista de identificaciones de los citados 
   * @returns {Promise<CitadoInfo[]>} Lista de la información extra de los citados
   */
  findMultipleQuoted(caseId: string, identificaciones: string[]): Promise<CitadoInfo[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          const { infractores, peticionarios } = bd;
          const citados: CitadoInfo[] = [];
          for(let identificacion of identificaciones){
            for(let infractor of infractores){
              if(infractor.identificacionInfractor === identificacion){
                let nombreCompleto;
                if(infractor.nombreInfractor && infractor.apellidoInfractor){
                  nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
                } else if (infractor.nombreInfractor){
                  nombreCompleto = infractor.nombreInfractor;
                } else if (infractor.apellidoInfractor){
                  nombreCompleto = infractor.apellidoInfractor;
                } else {
                  return
                }
                citados.push({
                  nombreCompleto,
                  nombre: infractor.nombreInfractor,
                  apellido: infractor.apellidoInfractor,
                  tipoIdentificacion: infractor.tipoIdentificacionInfractor,
                  identificacion: infractor.identificacionInfractor,
                  nivelEducativo: infractor.nivelEducativoInfractor,
                  ocupacion: infractor.ocupacionInfractor,
                  tipoVulnerabilidad: infractor.tipoVulnerabilidadInfractor,
                  direccion: infractor.direccionInfractor,
                  telefono: infractor.telefonoInfractor,
                  correo: infractor.correoInfractor
                });
              }
            }
            for(let peticionario of peticionarios){
              if(peticionario.identificacionPeticionario === identificacion){
                citados.push({
                  nombreCompleto: peticionario.nombrePeticionario,
                  nombre: peticionario.nombrePeticionario,
                  tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
                  identificacion: peticionario.identificacionPeticionario,
                  direccion: peticionario.direccionPeticionario,
                  telefono: peticionario.telefonoPeticionario,
                  correo: peticionario.correoPeticionario,
                });
              }
            }
          }
          resolve(citados);
        }
      })
    })
  }

  getAudienceInfo(caseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Pva>(`api/pva-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<Pva>(response)){
          const bd = response.result;
          const audienciasDisponibles = bd.audiencias;
          const pvaId = bd._id;
          // const correosEnviados = bd.correo;
          resolve({
            audienciasDisponibles,
            pvaId,
            // correosEnviados
          })
        } else {
          reject(response.error);
        }
      })
    })
  }

  getQuoted(caseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const quoted = [];
          const { peticionarios, infractores } = response.result
          console.log('los peticionarios son', peticionarios);
          for(let peticionario of peticionarios){
            quoted.push({
              nombreCompleto: peticionario.nombrePeticionario,
              tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
              identificacion: peticionario.identificacionPeticionario,
              correo: peticionario.correoPeticionario,
              telefono: peticionario.telefonoPeticionario,
              direccion: peticionario.direccionPeticionario,
              tipo: 'peticionario'
            });
          }
          for(let infractor of infractores){
            let nombreCompleto
            if(infractor.nombreInfractor && infractor.apellidoInfractor){
              nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
            } else if (infractor.nombreInfractor){
              nombreCompleto = infractor.nombreInfractor;
            } else if (infractor.apellidoInfractor){
              nombreCompleto = infractor.apellidoInfractor;
            } else {
              return
            }
            quoted.push({
              nombreCompleto,
              tipoIdentificacion: infractor.tipoIdentificacionInfractor,
              identificacion: infractor.identificacionInfractor,
              correo: infractor.correoInfractor,
              telefono: infractor.telefonoInfractor,
              direccion: infractor.direccionInfractor,
              nombre: infractor.nombreInfractor,
              apellido: infractor.apellidoInfractor,
              tipo: 'infractor'
            })
          }
          resolve(quoted)
        } else {
          throw response.error;
        }
      })
    })
  }

  saveInfo(id, data): Promise<Pva> {
    return new Promise((resolve, reject) => {
      this.mainService.put<Pva>(`api/pva-caso/${id}`, data).subscribe(response => {
        if(this.mainService.isSuccess<Pva>(response)){
          Swal.fire({
            title: 'Éxito',
            text: 'Proceso verbal abreviado actualizado con éxito',
            icon: 'success'
          });
          resolve(response.result);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo actualizar el proceso verbal abreviado',
            icon: 'error'
          })
          reject(response.error);
        }
      })
    })
  }

  /**
   * Guarda el archivo en el servidor de S3
   * @param file El archivo que se va a subir, tipo file
   * @returns el link donde se guardó el archivo
   */
  saveInS3 = (file): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (file !== undefined) {
        // Obtener la información necesaria
        let { params, options } = this.s3Service.getInfoForUpload('pva',file);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if(err){
            reject(err);
          } else {
            const archivo = data.Location
            resolve(archivo);
          }
        });
      }
    });
  }

  /**
   * Guarda el archivo en el servidor de S3
   * @param file El archivo que se va a subir, tipo file
   * @returns el link donde se guardó el archivo
   */
  saveVideosInS3 = (file) => {
    return new Promise((resolve, reject) => {
      if (file !== undefined) {
        // Obtener la información necesaria
        let { params, options } = this.s3Service.getInfoForUpload('videos',file);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if(err){
            reject(err);
          } else {
            const archivo = data.Location
            resolve(archivo);
          }
        });
      }
    });
  }

  /**
   * Muestra un mensaje de éxito al subir una imagen
   */
  showS3UploadError(){
    this.s3Service.showErrorUploading();
  }

  /**
   * Muestra un mensaje de error al subir una imagen
   */
  showS3UploadSuccess(mensajeFooter?: string){
    this.s3Service.showSuccessUploading(mensajeFooter);
  }


}
