import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgChartsModule } from 'ng2-charts';

import { AuthService } from './services/auth.service';
import { MainService } from './services/main.service';
import { AuthGuard } from './guards/auth.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { IpServiceService } from './services/ip-service.service';
import { CaptchaService } from './services/captcha.service';

import { LoginInspeccionesComponent } from './login-inspecciones/login-inspecciones.component';
import { InspeccionesInicioComponent } from './inspecciones/inspecciones-inicio/inspecciones-inicio.component';
import { GestionCasosInspeccionesComponent } from './inspecciones/gestion-casos-inspecciones/gestion-casos-inspecciones.component';
import { BibliotecaInspeccionesComponent } from './inspecciones/biblioteca-inspecciones/biblioteca-inspecciones.component';
import { SubmodulosInspeccionesComponent } from './inspecciones/submodulos-inspecciones/submodulos-inspecciones.component';
import { EstadisticasInspeccionesComponent } from './inspecciones/estadisticas-inspecciones/estadisticas-inspecciones.component';
import { HeaderInspeccionesComponent } from './inspecciones/header-inspecciones/header-inspecciones.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PremisasFundamentalesComponent } from './inspecciones/submodulos-inspecciones/premisas-fundamentales/premisas-fundamentales.component';
import { JurisprudenciaInspeccionesComponent } from './inspecciones/jurisprudencia-inspecciones/jurisprudencia-inspecciones.component';
import { LegislacionInspeccionesComponent } from './inspecciones/legislacion-inspecciones/legislacion-inspecciones.component';
import { BibliografiaInspeccionesComponent } from './inspecciones/bibliografia-inspecciones/bibliografia-inspecciones.component';
import { RecursosAdicionalesInspeccionesComponent } from './inspecciones/recursos-adicionales-inspecciones/recursos-adicionales-inspecciones.component';
import { MecanismosPreviosComponent } from './inspecciones/submodulos-inspecciones/mecanismos-previos/mecanismos-previos.component';
import { MecanismosIntermediosComponent } from './inspecciones/submodulos-inspecciones/mecanismos-intermedios/mecanismos-intermedios.component';
import { PvaComponent } from './inspecciones/submodulos-inspecciones/pva/pva.component';
import { NuevoCasoComponent } from './inspecciones/gestion-casos-inspecciones/nuevo-caso/nuevo-caso.component';
import { ForoInspeccionesComponent } from './inspecciones/foro-inspecciones/foro-inspecciones.component';
import { ForoVerComponent } from './inspecciones/foro-inspecciones/foro-ver/foro-ver.component';
import { FormFichaGeneralComponent } from './inspecciones/form-ficha-general/form-ficha-general.component';
import { ForgotInspeccionesComponent } from './forgot-inspecciones/forgot-inspecciones.component';
import { ResetInspectorComponent } from './reset-inspector/reset-inspector.component';
import { VerCasoComponent } from './inspecciones/gestion-casos-inspecciones/ver-caso/ver-caso.component';
import { MecanismoPrevioComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/mecanismo-previo.component';
import { MecanismoIntermedioComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/mecanismo-intermedio.component';
import { PvaCasoComponent } from './inspecciones/gestion-casos-inspecciones/pva/pva-caso.component';
import { SeguimientoEvaluacionComponent } from './inspecciones/gestion-casos-inspecciones/seguimiento-evaluacion/seguimiento-evaluacion.component';
import { FaseDiagnosticaComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/fase-diagnostica/fase-diagnostica.component';
import { FaseIntervencionComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/fase-intervencion/fase-intervencion.component';
import { FaseSeguimientoComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/fase-seguimiento/fase-seguimiento.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ReporteFinalPrevioComponent } from './inspecciones/gestion-casos-inspecciones/reporte-final-previo/reporte-final-previo.component';
import { CitacionComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/citacion/citacion.component';
import { DatosGeneralesComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/datos-generales/datos-generales.component';
import { DescargosComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/descargos/descargos.component';
import { DecisionRecursosComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/decision-recursos/decision-recursos.component';
import { SeguimientoComponent } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/seguimiento/seguimiento.component';
import { ReporteFinalIntermedioComponent } from './inspecciones/gestion-casos-inspecciones/reporte-final-intermedio/reporte-final-intermedio.component';
import { MecanismoIntermedioService } from './inspecciones/gestion-casos-inspecciones/mecanismo-intermedio/mecanismo-intermedio.service';
import { MecanismoPrevioService } from './inspecciones/gestion-casos-inspecciones/mecanismo-previo/mecanismo-previo.service';
import { CitacionPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/citacion-pva/citacion-pva.component';
import { DatosGeneralesPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/datos-generales-pva/datos-generales-pva.component';
import { DecisionPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/decision-pva/decision-pva.component';
import { DescargosPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/descargos-pva/descargos-pva.component';
import { PruebasPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/pruebas-pva/pruebas-pva.component';
import { SeguimientoPvaComponent } from './inspecciones/gestion-casos-inspecciones/pva/seguimiento-pva/seguimiento-pva.component';
import { FormularioComponent } from './inspecciones/gestion-casos-inspecciones/seguimiento-evaluacion/formulario/formulario.component';
import { S3Service } from './services/s3.service';
import { PvaService } from './inspecciones/gestion-casos-inspecciones/pva/pva.service';
import { GeneralFileService } from './inspecciones/form-ficha-general/general-file.service';
import { UtilsService } from './services/utils.service';
import { SeguimientoEvaluacionService } from './inspecciones/gestion-casos-inspecciones/seguimiento-evaluacion/seguimiento-evaluacion.service';
import { ForoService } from './inspecciones/foro-inspecciones/foro.service';
import { BancoEstrategiasComponent } from './inspecciones/banco-estrategias/banco-estrategias.component';
import { InputNumberDirective } from './directives/input-number.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';

// Others
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { HttpClientModule } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { CalendarioComponent } from './inspecciones/calendario/calendario.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarioService } from './inspecciones/calendario/calendario.service';
import { EstadisticasService } from './inspecciones/estadisticas-inspecciones/estadisticas.service';
import { VistaConsultaComponent } from './inspecciones/vista-consulta/vista-consulta.component';
export function momentAdapterFactory() {
  return adapterFactory(moment);
};


@NgModule({
  declarations: [
    AppComponent,
    LoginInspeccionesComponent,
    InspeccionesInicioComponent,
    GestionCasosInspeccionesComponent,
    BibliotecaInspeccionesComponent,
    SubmodulosInspeccionesComponent,
    EstadisticasInspeccionesComponent,
    HeaderInspeccionesComponent,
    PremisasFundamentalesComponent,
    JurisprudenciaInspeccionesComponent,
    LegislacionInspeccionesComponent,
    BibliografiaInspeccionesComponent,
    RecursosAdicionalesInspeccionesComponent,
    MecanismosPreviosComponent,
    MecanismosIntermediosComponent,
    PvaComponent,
    NuevoCasoComponent,
    ForoInspeccionesComponent,
    ForoVerComponent,
    FormFichaGeneralComponent,
    ForgotInspeccionesComponent,
    ResetInspectorComponent,
    VerCasoComponent,
    MecanismoPrevioComponent,
    MecanismoIntermedioComponent,
    PvaCasoComponent,
    SeguimientoEvaluacionComponent,
    FaseDiagnosticaComponent,
    FaseIntervencionComponent,
    FaseSeguimientoComponent,
    TruncatePipe,
    ReporteFinalPrevioComponent,
    CitacionComponent,
    DatosGeneralesComponent,
    DescargosComponent,
    DecisionRecursosComponent,
    SeguimientoComponent,
    ReporteFinalIntermedioComponent,
    CitacionPvaComponent,
    DatosGeneralesPvaComponent,
    DecisionPvaComponent,
    DescargosPvaComponent,
    PruebasPvaComponent,
    SeguimientoPvaComponent,
    FormularioComponent,
    BancoEstrategiasComponent,
    InputNumberDirective,
    CalendarioComponent,
    VistaConsultaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgbModalModule,
    FlatpickrModule.forRoot()
  ],
  providers: [
    JwtHelperService,
    MainService,
    AuthService,
    AuthGuard,
    LoggedInGuard,
    IpServiceService,
    UtilsService,
    CaptchaService,
    TruncatePipe,
    S3Service,
    GeneralFileService,
    MecanismoPrevioService,
    MecanismoIntermedioService,
    PvaService,
    SeguimientoEvaluacionService,
    CalendarioService,
    EstadisticasService,
    ForoService,
    { provide: JWT_OPTIONS, useValue: { skipWhenExpired: true } },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
