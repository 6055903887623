<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="principal-library">
    <div class="d-flex-start-center">
      <button class="go-back-button" routerLink="/inicio-inspecciones">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title mb-20">Biblioteca</h1>
    </div>
    <div class="links-container">
      <a routerLink="/submodulos-inspecciones" class="link-util-a">
        <div class="link-util">
          <img src="/assets/img/inspecciones/calendario.png" alt="" class="images-link">
          <span class="text-link">Submódulos</span>
        </div>
      </a>
      <a routerLink="/jurisprudencia-inspecciones" class="link-util-a">
        <div  class="link-util">
          <img src="/assets/img/inspecciones/jurisprudencia.png" alt="" class="images-link">
          <span class="text-link">Jurisprudencia</span>
        </div>
      </a>
      <a routerLink="/legislacion-inspecciones" class="link-util-a">
        <div class="link-util">
          <img src="/assets/img/inspecciones/legislacion.png" alt="" class="images-link">
          <span class="text-link">Legislacion</span>
        </div>
      </a>
      <a  routerLink="/bibliografia-inspecciones" class="link-util-a">
        <div class="link-util">
          <img src="/assets/img/inspecciones/bibliografia.png" alt="" class="images-link">
          <span class="text-link">Bibliografía</span>
        </div>
      </a>
      <a routerLink="/recursos-adicionales-inspecciones" class="link-util-a">
        <div class="link-util">
          <img src="/assets/img/inspecciones/recursos-adicionales.png" alt="" class="images-link">
          <span class="text-link">Recursos adicionales</span>
        </div>
      </a>
      <a  routerLink="/foro-inspecciones" class="link-util-a">
        <div class="link-util">
          <img src="/assets/img/inspecciones/foro.png" alt="" class="images-link">
          <span class="text-link">Foro:<br>preguntas y respuestas</span>
        </div>
      </a>
    </div>
  </div>
</main>
