import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MainService } from "./services/main.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";
  buzon = true;
  mensaje = "";

  constructor(public router: Router, private mainService: MainService) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  enviarMensaje() {
    let mensaje = {
      to: "msuarez@ideaspaz.org",
      subject: "Mensaje usuario Ensamble",
      html: this.mensaje,
    };
    this.mainService.post("email/sendEmail", mensaje).subscribe((res) => {
      if (res.success) {
        Swal.fire("Gracias!", "Hemos recibido su mensaje", "success").then(
          (() => this.buzon = true)
        );
      }
    });
  }
}
