import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import Swal from 'sweetalert2';

@Injectable()
export class S3Service {

  private bucket: S3 = new S3({
		accessKeyId: "AKIAUT7IXVIDZ5GPEC2K",
		secretAccessKey: "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",
		region: "us-east-1",
	});

  constructor() { }

  /**
   * Devuelve el bucket para el proyecto
   * @returns El bucket de AWS s3
   */
  getBucket() {
    return this.bucket;
  }

  /**
	 * Genera un ID
	 */
  generateId(){
    const crypto = window.crypto;
    var array = new Uint32Array(1);
    return crypto.getRandomValues(array)[0];
  }

  /**
   * Muestra un mensaje de error
   */
  public showErrorUploading(){
    Swal.fire({
      title: 'Error',
      icon: 'error',
      text: 'Se generó un error al subir el archivo',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
    })
  }

  public showSuccessUploading(mensajeFooter?: string){
    Swal.fire({
      title: 'Éxito',
      icon: 'success',
      text: 'Se subieron los archivos exitosamente',
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      footer: `<span class="red-text">${mensajeFooter || '¡No olvides guardar!'}</span>`
    })
  }

  private getParamsForUpload(baseUrl, nombre, archivoSeleccionado){
    return {
      Bucket: 'fip-recursos',
      ACL: 'public-read',
      Key: `${baseUrl}/${nombre}`,
      ContentType: archivoSeleccionado.type, Body: archivoSeleccionado
    };
  }

  private getOptionsForUpload(){
    return {
      // Part Size of 10mb
      partSize: 10 * 1024 * 1024,
      queueSize: 1
    };
  }

  /**
   * Retorna la información que se necesita para utilizar s3 en subida de documentos.
   * @param baseUrl La url base a donde se subirá
   * @param archivoSeleccionado El archivo que se va a subir, de un input generalmente
   * @returns params y options necesarios para s3
   */
  public getInfoForUpload(baseUrl: string, archivoSeleccionado: File){
    const nombre = this.generateId();
    const params = this.getParamsForUpload(baseUrl, nombre, archivoSeleccionado);
    const options = this.getOptionsForUpload();
    return { params, options }
  }

}
