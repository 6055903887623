import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submodulos-inspecciones',
  templateUrl: './submodulos-inspecciones.component.html',
  styleUrls: ['./submodulos-inspecciones.component.css']
})
export class SubmodulosInspeccionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
