import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";


export interface SuccessResponse<T> {
  success: boolean;
  result: T;
}

export interface ErrorResponse {
  success: boolean;
  error: Error | string;
}

export type DataResponse<T> = SuccessResponse<T> | ErrorResponse;

@Injectable()
export class MainService {
  baseURL: String = "https://develop-fip.imagineapps.co/";
  // baseURL: String = "http://localhost:8080/";
  
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, public router: Router) {
  }

  get<T = any>(object: String): Observable<DataResponse<T>> {
    return this.http.get<DataResponse<T>>(this.baseURL + "" + object, this.httpOptions);
  }

  post<T = any>(object: String, data: any): Observable<DataResponse<T>> {
    return this.http.post<DataResponse<T>>(this.baseURL + "" + object, JSON.stringify(data), this.httpOptions);
  }

  delete<T = any>(object: String): Observable<DataResponse<T>> {
    return this.http.delete<DataResponse<T>>(this.baseURL + "" + object, this.httpOptions);
  }

  put<T = any>(object: String, data: any): Observable<DataResponse<T>> {
    return this.http.put<DataResponse<T>>(this.baseURL + "" + object, JSON.stringify(data), this.httpOptions)
  }

  isSuccess<T>(response: any): response is SuccessResponse<T> {
    return 'result' in response
  }

  onGoTo(page) {
    if (page == "piscc") {
      this.router.navigate(["ingreso"]);
    } else if (page == "formacion") {
      this.router.navigate(["formacion"]);
    } else if (page == "foros") {
      this.router.navigate(["foros"]);
    } else if (page == "perfil") {
      this.router.navigate(["perfil"]);
    } else if (page == "biblioteca") {
      this.router.navigate(["biblioteca"]);
    }
  }

  onGoToHome() {
    this.router.navigate(["piscc-inicio"]);
  }

  public comentarios = [
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 3 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (66.7%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes viene disminuyendo; para 2017 la tasa del municipio fue de 1.8 por CDH,  y estuvo por debajo en 1.1 puntos de la tasa de ANTIOQUIA y por encima en 0.5 puntos de la tasa del país.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en los hombres. Los hombres representan cerca del 85 porciento de las víctimas. En 2014 se presenta el mayor número de casos de mujeres víctimas (6 casos). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, y se presenta una víctima mujer.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 80% de las víctimas. \nEn el primer semestre de 2018, el homicidio se concentró en jóvenes; con el 60% de los casos. También se observa la aparición de una víctima que pertenece al grupo de edad de niños, niñas y adolescentes.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los días jueves con el 50% de los casos.  En el primer semestre de 2018 el delito se concentró en los martes y domingos. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en las franjas de la madrugada y la tarde, con el 80% de los casos. En contraste, en el 2017 el delito se presentó en mayor proporción durante la madrugada (50% de los casos).\nAhora bien, en lo corrido del año, el sábado en la tarde ha presentado el mayor número de casos de homicidio (2 casos).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En el primer semestre de 2018, los victimarios continuaron movilizándose a pie. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 64% de los homicidios se han cometido con arma de fuego.\nEn el primer semestre de 2018 las armas más utilizadas son el arma blanca y el arma de fuego; concentran el 80% de los casos. Ahora bien, el uso del arma blanca durante el primer semestre de 2018 es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDesde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: MONOS, LA GURRIA, LA VIBORA, EL ENCANTO, y  NARANJAL; estos barrios concentraron el 50% de los homicidios. En el primer semestre de 2018, estos barrios concentran el 40% de los casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. No obstante, en 2017 se revierte esta tendencia. En el primer semestre de 2018, el número de víctimas se mantiene estable con respecto al comparativo semestral de 2017 (19 víctimas).\nPor su parte, la tasa de lesiones personales por cada 10.000 habitantes presenta un comportamiento creciente. Para 2017 la tasa del municipio fue de 19.2 por CDH,  y estuvo 2 puntos por debajo en de la tasa de ANTIOQUIA y  3.6 puntos por debajo de la tasa del país.\n\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se han presentado en mayor proporción en los hombres (51%). En 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (43 casos).\nEn el primer semestre de 2018, el número de víctimas mujeres disminuyó cerca del 86% con respecto al comparativo semestral del 2017.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de lesiones personales han sido adultos, seguidos por jóvenes (en conjunto concentran más del 50% de las víctimas). En 2017, el porcentaje de niños, niñas y adolescentes que fue víctima de este delito aumentó de forma significativa. \nEn el primer semestre de 2018, las lesiones personales se concentraron en adultos, con el 47% de los casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron los días lunes, jueves y sábados. En el primer semestre de 2018 se concentró en los domingos con 8 casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la noche con el 42% de los eventos. En 2017, el delito se presentó en mayor proporción durante la noche (40%).\nAhora bien, en lo corrido del año, el domingo en la noche presenta el mayor número de casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 50% de las lesiones personales se han cometido con objetos contundentes.\nEn el primer semestre de 2018  el arma más empleada son los objetos contundentes, con el 74% de los casos; aumentando 5 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en fincas y similares.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, NARANJITO, EL CENTRO, PUEBLO NUEVO, y SECTOR CALLE ESTRECHA; estos barrios concentraron el 35% de las lesiones personales.  En el primer semestre de 2018, el barrio el CENTRO ha registrado el mayor número de casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio; sin embargo, en 2017 se revierte esta tendencia. En el primer semestre de 2018, el número de víctimas aumentó con respecto al comparativo semestral de 2017, pasando de 7 víctimas a 8 (14.3%).\nPor su parte, la tasa de homicidios por cada 10.000 habitantes presenta una disminución en 2017; en particular, pasó de 10 por CDH en 2016 a 8 por CDH en 2017. Sin embargo, esta tasa estuvo por encima de la tasa nacional que se registra en 6.2, y por encima de la tasa departamental que se registra en 1.7 por CDH.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 80% de las víctimas han sido mujeres. Sin embargo, en 2017 se presenta un incremento importante la proporción de hombres que son víctimas de este delito (28%). \nEn el primer semestre de 2018, el delito se sigue presentando con mayor incidencia en mujeres. Por su parte, el porcentaje de hombres víctimas es inferior al promedio de los últimos cuatro años.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en el grupo de edad de niños, niñas y adolescentes, y de jóvenes. Así mismo, se observa que en 2015 y 2016 en la mayoría de casos no se reportó la edad de la víctima.\nEn el primer semestre de 2018, los delitos sexuales se concentraron en niños, niñas y adolescentes  (50% de los casos). El segundo grupo de edad más afectado es el de adultos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los días lunes y jueves (33% de los casos). En el primer semestre de 2018, se concentró en los lunes y domingos. \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se concentraron principalmente en la franja de la mañana (50%). Esta concentración es consistente con la que se presentó en 2017 (61% de los casos ocurrieron en la mañana).\nAhora bien, en lo corrido del año, el día lunes en la mañana ha presentado el mayor número de casos (el 13%).\n\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos tres años, más del 50% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). El segundo tipo de arma más utilizado son los objetos contundentes.\nEn el primer semestre de 2018 en la mitad de los casos (4 casos) se utilizó la intimidación y en la otra mitad no se reportó el tipo de arma empleada.  \n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el 2017 la mayor proporción de casos se presenta en FINCAS y SIMILARES. En el primer semestre de 2018  aumentó la proporción de casos en la zona rural. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: NARANJITO, PUEBLO NUEVO, MONOS, LA POLA, y EL CENTRO; estos barrios concentraron el 61% de los delitos sexuales que se presentaron en el año, y el 50% de los casos que se presentaron en el primer semestre de 2018.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. No obstante, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 15 víctimas en el primer semestre de 2017 a 8 victimas en el 2018 (-46,7%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes disminuyó en 2017. Pasó de 20 casos por CDH a 7 por CDH,  y estuvo 7,5 puntos por debajo de la tasa de ANTIOQUIA y 11,2 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 90% de las víctimas han sido mujeres. \nEn el primer semestre de 2018, el delito se concentra en su totalidad en mujeres.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes (el 58% en promedio). Sin embargo, los adultos también tienen una participación importante en el total de víctimas. Así mismo, en 2017 se observa un incremento en la proporción de niños, niñas y adolescentes que son víctimas. \nEn el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos y jóvenes. No se presentaron casos de niños, niñas y adolescentes víctimas. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (35% de los casos). En el primer semestre de 2018 el delito se concentró en los días lunes y sábados. \nCon respecto a la franja horaria, en el primer semestre de 2018 la violencia intrafamiliar se concentró en la franja de la mañana (el 38% de los casos). En 2017, el delito también se presentó en mayor proporción durante la mañana (47% de los casos).\nAhora bien, en lo corrido del año, los lunes en la noche han registrado el mayor número de casos (el 13%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 52% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018, en la mitad de los casos se utilizaron objetos contundentes y en la otra mitad la intimidación (sin empleo de armas). El uso de la intimidación es inferior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y desde 2017 se presenta en mayor proporción de casas/apartamentos. En años anteriores la mayoría de los casos se presentaron en la vía pública.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: NARANJITO, PUEBLO NUEVO, LA MARIA, y ZACATIN; estos barrios concentran el 82% de los casos. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: PUEBLO NUEVO, SECTOR EL CENTRO, SACATIN, VALLECITOS y las ANIMAS; estos barrios concentraron el 27% de los casos que se registraron.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Sin embargo, en el 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 19 víctimas en el primer semestre de 2017 a 15 victimas en el 2018 (-21,1%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes presentó una disminución en 2017. En particular, pasó 32,4 por CDH a 12,5 por CDH en 2017 (reducción del 61%); así mismo, esta tasa estuvo por debajo en 26,8 puntos de la tasa de ANTIOQUIA y  por debajo 25,2 puntos de la tasa del país.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 55% de las víctimas). Por su parte, la proporción de víctimas mujeres presenta una tendencia decreciente. \nEn el primer semestre de 2018, el número de mujeres víctimas es inferior al promedio de los últimos cuatro años.  ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (en promedio, representan el 75% de las víctimas). En 2017 se presenta un incremento en la proporción de jóvenes que son víctimas de este delito. \nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 67% de los casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes (el 18% de los casos). En el primer semestre de 2018, el delito se concentró en los sábados con el 46% de los casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada con el 53% de los casos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde (54%).\nAhora bien, en lo corrido del año, el sábado en la madrugada ha presentado la mayor concentración de casos. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en motocicleta. \nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 66% de los casos de hurto a personas no se han empleado armas (intimidación).\nEn el primer semestre de 2018 el tipo de arma más empleada es el arma de fuego, con el 53% de los casos. Esta proporción es superior al promedio de los últimos cuatro años. \n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha venido concentrando en la zona urbana del municipio y en la vía pública. En los primeros 6 meses de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, PARQUE PRINCIPAL, PORRO LISO, CANTARRANA y NARANJITO; estos barrios concentraron el 50% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 los barrios con el mayor número de casos fueron: NARANJITO, ALTO DE LA LINEA, PORRO LISO, CARACOLI y LA GRANJA; estos barrios concentran el 30% de los casos que se registraron en el municipio.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se ha presentado dos casos de hurto a automotores (en 2015). En el primer semestre de 2018 se presentó un caso.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres.  En el primer semestre de 2018, la única víctima que se presentó es un hombre.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos. En el primer semestre de 2018, la única víctima que se presentó es un adulto mayor.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el 2015, los dos casos se presentaron ocurrieron el jueves y el sábado. En el primer semestre de 2018 el caso que se presentó ocurrió un miércoles en la tarde.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre del 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 100% de los casos de hurto a automotores se han cometido con arma de fuego. En el primer semestre de 2018 no se emplearon armas.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nEn los últimos cinco años el hurto a automotores se ha venido concentrando en la zona rural del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2015 el delito se concentró en los barrios/veredas: LA ALDEA y SAN MIGUEL. En el primer semestre de 2018, se presentó un caso en el barrio/vereda SAN ANTONIO.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 3 casos en el primer semestre de 2017 a 4 casos en el 2018 (33,3%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 92% de las víctimas). Por su parte, en el primer semestre de 2018 la proporción de mujeres víctimas es superior a la que se presentó en el primer semestre de 2017.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nEn los últimos cinco años la mayor parte de las víctimas de hurto a motocicletas han sido adultos, seguidos por jóvenes. En el primer semestre de 2018 el delito se ha concentrado en los adultos; representan el 75% de las víctimas.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los días jueves y domingo (concentran el 80% de los casos). En el primer semestre de 2018 el delito se distribuyó de forma homogénea entre los días lunes, jueves, sábado y domingo (cada día se presentó un caso).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (75% de los casos). En contraste, en el 2017, el delito se presentó en mayor proporción durante la noche (40%).\nAhora bien, en lo corrido del año, el hurto a motocicletas se ha distribuido de forma homogénea en las madrugadas de los lunes, jueves  y domingo. El sábado en la tarde también presenta un caso.\n\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene esta tendencia. En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 40% de los casos no se han empleado armas (intimidación). El segundo tipo de arma más utilizada es la llave maestra. \nEn el primer semestre de 2018 la distribución de casos por tipo de arma empleada ha sido bastante heterogénea. Se presentó un caso en el que el agresor utilizó un arma de fuego, un caso en el que utilizó una llave maestra, un caso en el que no se reportó el tipo de arma y otro en el que no se utilizaron armas.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUEBLO VIEJO, NARANJITO, EL CENTRO, MONOS y CORDOBA; estos barrios concentran el 100% de los casos de hurto a motocicletas.  En el primer semestre de 2018 los barrios con el mayor número de casos fueron: NARANJITO, PUEBLO VIEJO, EL CENTRO, MONOS y LA GUAYANA; estos barrios concentran el 100% de los casos que se registraron en el municipio. \n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 2 casos en el primer semestre de 2017 a 8 casos en el 2018 (300%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los días miércoles y jueves (cada día concentra el 30% de los casos). En el primer semestre de 2018 se concentró en los martes (37%), seguido por los jueves (25%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (el 63% de los casos). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (40%).\nAhora bien, en lo corrido del año, los martes en la madrugada han presentado el mayor número de casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 35% de los casos de hurto a comercio no se han utilizado armas. El segundo tipo de arma más utilizado son las palancas y los objetos contundentes. En el primer semestre de 2018 se tiene que en el 62% de los casos de hurto a comercio se utilizaron palancas. Así mismo, se presentó un caso en el que el agresor utilizó un arma de fuego.\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, SECTOR EL CENTRO, VEGA MEJIA, MONTEBELLO, y PORRO LISO; estos barrios concentran el 70% de los casos de hurto a comercio durante ese año. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: PUEBLO NUEVO, PARQUE PRINCIPAL, ALTO DE LA VIRGEN, LA ALDEA y PERIFERICO; estos barrios concentran el 62% de los casos que se han registrado.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se presentó un caso de hurto a entidades financieras (en el 2015). En lo corrido del 2018 no se han registrado casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 7 casos en el primer semestre de 2017 a 6 casos en el 2018 (-14,3%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencia según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los jueves (concentra el 24% de los casos). En el primer semestre de 2018 este delito se concentró en los viernes (67%), seguido por los sábados (33%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche (el 67 por ciento de los casos). En el 2017, el delito también se presentó en mayor proporción durante la noche (38%).\nAhora bien, en lo corrido del año, las noches de los sábados y viernes presentan la mayor concentración de casos. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en más del 50% de los casos de hurto a residencias no se han utilizado armas. El segundo tipo de arma más utilizado son las palancas y los objetos contundentes. En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, SECTOR EL CENTRO, VEGA MEJIA, MONTEBELLO, y PORRO LISO; estos barrios concentran el 70% de los casos. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: PUEBLO NUEVO, PARQUE PRINCIPAL, ALTO DE LA VIRGEN, LA ALDEA y PERIFERICO; estos barrios concentran el 84% de los casos que se han registrado.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de amenazas en el municipio. Sin embargo, en el 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 5 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-40%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las amenazas se han concentrado en los hombres (en promedio, representan el 56% de las víctimas). En el 2016 se presenta el mayor número de mujeres víctimas de este delito. Por su parte, en el primer semestre de 2018 el delito se sigue concentrando en los hombres y el número de casos de mujeres víctimas es inferior al promedio de los últimos cuatro años.\n\n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayoría de casos no se reportó la edad de la víctima. El grupo de edad más afectado en 2017 son los jóvenes.  En el primer semestre de 2018 el delito se ha concentrado en los adultos; representan el 67% de las víctimas.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 todos los casos de amenazas se presentaron los miércoles. En el primer semestre de 2018 este delito se distribuyó de forma homogénea entre los lunes, miércoles y domingo (cada día presenta un caso).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana (el 67% de los casos). En el 2017, el delito también se presentó en mayor proporción durante la mañana (el 50% de los casos).\nAhora bien, en lo corrido del año, los tres casos que se han presentado se distribuyen en las mañanas de los miércoles y domingos, y en la noche del lunes. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron:  NARANJITO, LA MARIA,  LA MANGUITA, CORBODA y MANZANILLO; estos barrios concentran el 100 % de los casos de amenazas. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: SECTOR EL CENTRO y PICARDIA; estos barrios concentran el 100% de los casos que se han registrado en el municipio.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nEntre 2013 y 2015 el número de casos de extorsión disminuyó. Sin embargo, en 2017 se revierte esta tendencia y se registra el mayor número de casos de los últimos cinco años.\nEn el primer semestre de 2018 no se registran casos de extorsión. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 73% de las víctimas). En el 2013 se presenta el mayor número de mujeres víctimas de este delito. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de extorsión han sido adultos (con el 61% de los casos). El segundo grupo de edad más afectado son los jóvenes. \nEn el primer semestre de 2018, no se presentaron casos de extorsión.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nDurante el 2017 los casos de extorsión se distribuyeron de forma homogénea entre los días lunes, martes, jueves, viernes y sábado (cada día presentó un caso). \nCon respecto a la franja horaria, en el 2017 este delito se concentró principalmente en la franja de la mañana con el 60% de los casos. \nAhora bien, en lo corrido del año, no se han presentado casos de extorsión. \n",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 50% de los casos). No obstante, en 2017 en la mayoría de casos se utilizó la extorsión directa.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en 2017 la extorsión se concentró en tres barrios/veredas:  EL CENTRO, LA MARIA y SECTOR EL CENTRO; estos barrios concentran el 100% de los casos que se presentaron. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años se ha presentado un caso de piratería terrestre en el municipio (en el 2017). En el primer semestre de 2018 no se registran casos.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años el número de casos de abigeato se ha mantenido relativamente estable (1 caso en promedio por año).  Con respecto primer semestre de 2018, se evidencia un aumento en el número de casos, pasando de 0 casos en el primer semestre de 2017 a 2 casos en el 2018 (200%).",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos de abigeato el agresor se ha movilizado a pie.   \nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 100% de los casos de abigeato emplearon armas. En el primer semestre de 2018 se mantiene este comportamiento. ",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn los últimos tres años los casos de abigeato se han presentado en los barrios/veredas: CHORRITOS y PORRO LISO. En el primer semestre de 2018 se concentran en los barrios/veredas: TINITA y LA VIBORA.",
      codigoMunicipio: "5b5cc9bccf37b117b15d3f85",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de homicidio en el municipio. Sin embargo en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 7 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (-28%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes presenta una disminución en 2017. En 2017 la tasa del municipio fue de 6,8 por CDH,  y estuvo por encima en 3,9 puntos de la tasa de Antiquia y por encima en 4,4 puntos de la tasa del país.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en los hombres (representan el 93% de las víctimas). En 2017 se presentaron dos casos de mujeres víctimas.\nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, y no se presentan casos de mujeres víctimas.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, el homicidio se concentró en jóvenes; con el 60 porciento de los casos.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los miércoles (29%). En el primer semestre de 2018 los homicidios se siguen concentrando los miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche (60%). Durante el 2017 el delito también se presentó en mayor proporción durante la noche, con el 47% de los casos.\nAhora bien, en lo corrido del año, el miércoles en la noche ha presentado el mayor número de casos de homicidio (2 casos).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En lo que va corrido del 2018 los agresores continúan movilizándose a pie. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 84% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en todos los casos se utilizó arma de fuego.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona rural del municipio. No obstante, en el primer semestre de 2018 se presentan más casos en la zona urbana.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA ALDEA, MORADITAS, MIRAFLORES, EL PASO, y SABANITAS; estos barrios concentraron el 65% de los homicidios. En el primer semestre de 2018 el barrio con el mayor número de casos es PEDREGAL; concentra el 40% de los homicidios.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. No obstante, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 49 víctimas en el primer semestre de 2017 a 38 víctimas en el 2018 (-22%).\nPor su parte, la tasa de lesiones personales por cada 10.000 habitantes presenta un comportamiento creciente. Para 2017 la tasa del municipio fue de 36.9 por CDH,  y estuvo por encima en 15,8 puntos de la tasa de ANTIOQUIA y  por encima en 12,2 puntos de la tasa del país.\n\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha presentado en mayor proporción en los hombres (53%). En 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (33 casos).\nEn el primer semestre de 2018, la proporción de mujeres víctimas de homicidio es inferior al al promedio de los últimos cuatro años. ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años se registra una tendencia creciente en la proporción de adultos víctimas de este delito. Por el contrario, la proporción de jóvenes víctimas presenta una tendencia decreciente. En conjunto, estos grupos de edad concentran cerca del 80% de las víctimas.\nEn el primer semestre de 2018, las lesiones personales se concentraron en adultos, con el 58 porciento de las víctimas.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos, seguidos por los martes. En el primer semestre de 2018 las lesiones personales se concentraron en los domingos con el 24% de los casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde con el 32% de los casos. En 2017, el delito también se presentó en mayor proporción durante la tarde con el 33% de los casos.\nAhora bien, en lo corrido del año, el lunes en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 61% de las lesiones personales se han cometido con objetos contundentes.\nEn el primer semestre de 2018 el arma más empleada continúa siendo los objetos contundentes, con el 79% de los casos. El segundo tipo de arma más empleada es el arma blanca, con el 18% de los casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA CORDILLERA, SANTA LUCIA, CATIVO, LLANOS DE BOLIVAR y PARQUE PRINCIPAL; estos barrios concentran el 47% de las lesiones personales que se presentaron en ese año.  En el primer semestre de 2018, el barrio LA CORDILLERA concentra el 50% de los casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio, sin embargo, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se observa una disminución en el número de víctimas, pasando de 13 víctimas en el primer semestre de 2017 a 7 en el 2018 (-46%).\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 8,8. Esta tasa estuvo por encima 7,7 puntos de la tasa de Antioquia, y por encima 2,6 puntos de la tasa del país. ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 86% de las víctimas han sido mujeres. \nEn el primer semestre de 2018, se mantiene este comportamiento.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los grupos de edad de niños, niñas y adolescentes (59%), y de jóvenes(19%). \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los miércoles (32%). En el primer semestre de 2018, se concentró en los lunes y jueves. \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se concentraron principalmente en la franja de la tarde con el 43% de los casos. En contraste, en el 2017 el 50% de los casos se presentó en la madrugada.\nAhora bien, en lo corrido del año, los casos de delitos sexuales se han distribuido de forma homogénea entre las tardes de los lunes, jueves y domingos. Las madrugadas de los jueves y viernes, la mañana del sábado y la noche del lunes. \n\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 68% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). \nEn el primer semestre de 2018 en el 86% de los casos no se reporta el tipo de arma empleada. \n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. Si bien el delito la proporción de casos que se presentan en la vía pública en promedio es la más alta, desde 2017 el delito se ha concentrado en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA CORDILLERA, LA ALDEA, LA MAQUEA, LA CHINCA y CATIVO; estos barrios concentran el 68% de los casos de delitos sexuales que se presentaron en ese año. En el primer semestre de 2018 el barrio LA CORDILLERA concentra el 71% de los casos registrados.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. No obstante, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 20 víctimas en el primer semestre de 2017 a 12 en el 2018 (-40%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue 14.1 y estuvo por debajo en 1 punto de la tasa de ANTIOQUIA y por debajo 4.7 puntos de la tasa del país.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 88% de las víctimas han sido mujeres. \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes y adultos. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes(23%), seguidos por los viernes(15%). En el primer semestre de 2018 se concentró en los días miércoles y domingos. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en las franjas de la mañana y de la tarde (cada franja concentra el 33% de los casos). En contraste, en el 2017 el delito se presentó en mayor proporción durante la madrugada y la tarde.\nAhora bien, en lo corrido del año, el miércoles en la mañana presenta el mayor número de casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 64% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018, los objetos contundentes continúan siendo el tipo de arma más empleada. Sin embargo, su uso es inferior al promedio de los últimos cuatro años. En particular, la proporción de casos en los que se empleó arma blanca o no se reportó el tipo de arma presentan incrementos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana y en las vías públicas del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: BUGA, LLANOS DE BOLIVAR, PARQUE PRINCIPAL, SANTA LUCIA y PALENQUE; estos barrios concentran el 42% de los casos de violencia intrafamiliar que se presentaron en el año. En el primer semestre de 2018 el barrio LA CORDILLA registró el mayor número de casos (25%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a personas en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 35 víctimas en el primer semestre de 2017 a 19 en el 2018 (-45%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes se ha mantenido estable desde 2016. Para 2017 la tasa del municipio fue de 22,1 por CDH, y  estuvo  17,2 puntos por debajo de la tasa de ANTIOQUIA, y 15,6 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 62% de las víctimas). Por su parte, la proporción de víctimas mujeres presenta una tendencia decreciente desde el 2016. \nEn el primer semestre de 2018, la proporción de hombres víctima es superior al promedio de los últimos cuatro años.  ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (en promedio, representan el 67% de las víctimas). En 2017 se presenta un incremento en la proporción de jóvenes que son víctimas de este delito. \nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 79% de los casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes y los sábados (cada día concentra el 24% de los casos). En el primer semestre de 2018, el delito se concentró en los domingos(32%), seguido por los sábados(21%). \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 30 por ciento de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana.\nAhora bien, en lo corrido del año, el domingo en la madrugada presenta la mayor concentración de casos. ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se presentan  casos en los que el agresor se moviliza en motocicleta (4 casos) o en transporte masivo(1 caso).\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 53 porciento de los casos de hurto a personas no se han empleado armas.\nEn el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha venido concentrando en la zona urbana del municipio y en la vía pública. No obstante, en el 2017 aumenta la proporción de casos que se presentan en la zona rural.  En los primeros 6 meses de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA CORDILLERA, PARQUE PRINCIPAL, CENTRO, SABANITAS, y EL PASO; estos barrios concentran el 50% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el barrio/vereda LA CORDILLERA ha presentado el mayor número de casos (33%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se han presentado dos casos de hurto a automotores (uno en 2014 y otro en 2015). En el primer semestre de 2018 no se registraron caos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres. \nEn lo corrido del año no se registran casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos. \nEn lo corrido del año no se han registrado casos.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el 2014 se presentó un caso el miércoles en la noche, y en el 2015, se presentó un caso el martes en la tarde. En el primer semestre de 2018 no se registran casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn el 2014 se presentó un caso en que el agresor se movilizaba en vehículo, mientras que el 2015 se presentó un caso en el agresor se movilizaba a pie. En los dos casos el agresor utilizó un arma de fuego. \nEn el primer semestre del 2018 no se han registrado casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nEn los últimos cinco años el hurto a automotores se ha presentado en su totalidad en la zona rural del municipio. \nEn cuanto a la ubicación, los dos barrios/veredas en los que se presentaron los casos fueron: PEDREGAL y CATIVO.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 12 casos en el primer semestre de 2017 a 5 casos en el 2018 (-58%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 82% de las víctimas). No obstante, en el primer semestre de 2018 la proporción de mujeres víctimas es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos (65%). Sin embargo, desde el 2016 se registra una tendencia creciente en la proporción de jóvenes víctimas. En el primer semestre de 2018 el delito se ha concentrado en los jóvenes(60%), seguido por los adultos (40%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los miércoles, viernes y domingos (cada día concentra el 24% de los casos). En el primer semestre de 2018, el lunes presentó el mayor número de casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 el hurto a motocicletas se presentó en la misma proporción durante la mañana y la madrugada (cada franja concentra el 40% de los casos). En el 2017 la mayoría de los casos se presentaron en la madrugada. \nAhora bien, en lo corrido del año, el hurto a motocicletas se ha distribuido de forma homogénea en las madrugadas de los lunes y sábados, las mañanas de los lunes y domingos, y el martes en la tarde (cada día presenta un caso).\n\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene esta tendencia. En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 64% de los casos se ha utilizado una llave maestra. El segundo tipo de arma más utilizada es el arma de fuego.\nEn el primer semestre de 2018 se mantiene este comportamiento. ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: JESUS, BUGA, OBREGON, MIRAFLORES y CENTRO; estos barrios concentran el 36% de los casos de hurto a motocicletas.  En el primer semestre de 2018 los barrios con el mayor número de casos fueron: LA ESMERALDA, BUGA, LLANOS DE BOLIVAR, CENTRO, y LA CORDILLERA; estos barrios concentran el 100% de los casos. \n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 17 casos en el primer semestre de 2017 a 8 casos en el 2018 (-38%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (28%). En el primer semestre de 2018 el delito se concentró en los martes, miércoles y jueves(cada día concentra el 25% de los casos). \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó principalmente en la franja de la madrugada (37%) y de la tarde(37%). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (40%).\nAhora bien, en lo corrido del año, el martes en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 40% de los casos de hurto a comercio no se han utilizado armas. El segundo tipo de arma más utilizada son las palancas y los objetos contundentes. En el primer semestre de 2018 se tiene que en el 75% de los casos no se utilizaron armas. Así mismo, se presentó un caso en el que el agresor utilizó un arma de fuego.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: BUGA, LLANOS DE BOLIVAR, LA VARIANTE, EL CARMELO y SANTA LUCIA; estos barrios concentran el 50% de los casos de hurto a comercio durante ese año. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: CENTRO, SANTA LUCIA, LA CORDILLERA, y JESUS ; estos barrios concentran el 87% de los casos que se han registrado.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se presentaron dos casos de hurto a entidades financieras (en 2015 y 2016). En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 7 casos en el primer semestre de 2017 a 14 casos en el 2018 (100%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencia según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (concentra el 40% de los casos). En el primer semestre de 2018 este delito se concentró en los lunes, jueves, y domingos; cada día concentra el 22% de los casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (64%). En concordancia, en el 2017, el delito se presentó en mayor proporción durante la madrugada (65%).\nAhora bien, en lo corrido del año, el domingo en la madrugada presenta la mayor concentración de casos. ",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en más del 42% de los casos de hurto a residencias se utilizaron objetos contundentes.  En el primer semestre de 2018 en el 50% de los casos no se utilizaron armas. Por otro lado, aumentó la proporción de casos en los que no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: El ESPINAL, LA CORDILLERA, EL PASO, QUEBRADA SECA, Y LA ESMERALDA; estos barrios concentran el 50% de los casos. En el primer semestre de 2018 el barrio LA CORDILLERA registró el mayor número de casos (29%).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDesde 2016 se registra una tendencia decreciente en el número de víctimas de amenazas en el municipio. Sin embargo, en el primer semestre de 2018 aumentó el número de víctimas con respecto al comparativo semestral de 2017; pasando de 4 víctimas a 5.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDesde 2016 se registra una tendencia creciente en la proporción de mujeres que son víctimas de este delito. Sin embargo, en el primer semestre de 2018 el 80% las víctimas fueron hombres, y la proporción de mujeres víctimas fue inferior al promedio de los últimos cuatro años.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayoría de casos no se reportó la edad de la víctima. El grupo de edad más afectado en 2017 fueron los jóvenes.  En el primer semestre de 2018 el delito se concentró en los adultos; representan el 67% de las víctimas.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción los martes, viernes y domingos. En el primer semestre de 2018 este delito se concentró en los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde (60%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (50%).\nAhora bien, en lo corrido del año, las amenazas se han distribuido de forma homogénea en las tardes de los martes, viernes y domingos, la noche de lunes y la mañana del viernes.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 todos casos se presentaron en el barrio/vereda LA CORDILLERA.  En el primer semestre de 2018 los barrios con el mayor número de casos fueron: LA CORDILLERA, KILOMETRO DOS, CENTRO y CHARRY; estos barrios concentran el 100% de los casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de extorsión. En el primer semestre de 2018 se presenta un caso.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 69% de las víctimas). En 2017 se presenta el mayor número de mujeres víctimas de este delito. \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años los grupos de edad más afectados han sido los jóvenes y los adultos mayores.\nEn el primer semestre de 2018, el delito se ha concentrado en los adultos y jóvenes.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nDurante últimos cuatro años, el domingo ha presentado el mayor número de casos de extorsión. En el primer semestre el mayor número de casos se ha presentado los viernes.\nCon respecto a la franja horaria, en el 2017 este delito se concentró principalmente en la franja de la madrugada. En lo corrido del 2018, el mayor número de casos se ha presentado en la jornada de la tarde.\n",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 47% de los casos). No obstante, en 2017 en la mayoría de casos se extorsionó de forma directa. En el primer semestre de 2018 en el 60% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en 2017 la extorsión se concentró en tres barrios/veredas:  CENTRO, PASO REAL, y ESPINAL; estos barrios concentran el 100% de los casos que se presentaron en ese año. En el primer semestre de 2018, el barrio/vereda OBREGON concentra el 100% de los casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años se presentó un caso de piratería terrestre en el municipio (en el 2014). En el primer semestre de 2018 no se registran casos.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años no se han presentado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años no se han presentado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nDurante los últimos cinco años no se han presentado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años se ha presentado un caso de secuestro en el municipio (en 2014).",
      codigoMunicipio: "5bad41e2ef476d0013582470",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 3 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (33%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 4,6 por CDH,  y estuvo 1,7 puntos por encima de la tasa de ANTIOQUIA y 2,2 puntos por encima de la tasa del país.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en los hombres (representan el 97% de las víctimas). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, y no se presentaron casos de mujeres víctimas.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 80% de las víctimas. Desde 2016 se registra una tendencia creciente en la proporción de jóvenes víctimas.\nEn el primer semestre de 2018, el homicidio se concentró en adultos (100%).\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados (67%). En el primer semestre de 2018 los homicidios se concentraron los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (50%). En contraste, en 2017 el delito se presentó en mayor proporción durante la mañana(100%).\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En el primer semestre de 2018 los agresores continúan movilizándose a pie. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 64% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en la mitad de los casos (2) el agresor utilizó arma de fuego, y en la otra mitad arma blanca (2). ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona rural del municipio.\nEn cuanto a la ubicación, en 2019 los dos barrios/veredas con el mayor número de casos fueron: SINCIERO y CALLE EL CHISPERO; estos barrios concentraron el 100% de los homicidios. En el primer semestre de 2018 el barrio con el mayor número de casos es LA PALMA; concentra el 50% de los homicidios registrados.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. No obstante, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se evidencia que el número de víctimas permanece estable con respecto al primer semestre de 2017; en ambos semestres se registraron 8 víctimas.\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 21.4,  y estuvo 0,3 puntos por encima de la tasa de ANTIOQUIA y  3,3 puntos por debajo  de la tasa del país.\n\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha presentado en mayor proporción en los hombres (63%). Sin embargo, desde 2016 se evidencia una tendencia creciente en la proporción de mujeres víctimas.\nEn el primer semestre de 2018, el delito se distribuye forma homogénea entre mujeres y hombres.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes y adultos. En conjunto, estos grupos de edad concentran más del 80% de las víctimas. En el 2017 se presenta un incremento en la proporción de niños que son víctimas.\nEn el primer semestre de 2018, las lesiones personales se concentraron en adultos; con el 75% de las víctimas. Esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes y los domingos (cada día concentra el 29% de los casos). En el primer semestre de 2018 las lesiones personales se concentraron en los lunes con el 50% de los casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada con el 50% de los casos. En 2017, el delito también se presentó en mayor proporción durante la madrugada con el 57% por ciento de los casos.\nAhora bien, en lo corrido del año, el lunes en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 35% de las lesiones personales se han cometido con objetos contundentes. El segundo tipo de arma más empleada es el arma de fuego.\nEn el primer semestre de 2018 el arma más empleada continúa siendo los objetos contundentes con el 75% de los casos. ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS, PARQUE PRINCIPAL, CALLE EL CHISPERO, LA AZOMADERA,  y LA PALMA; estos barrios concentran el 78% de las lesiones personales que se presentaron en ese año.  En el primer semestre de 2018, el barrio LOS ASIENTOS; concentra el 75% de los casos de registrados.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio, sin embargo, en 2017 se revierte esta tendencia. Con respecto primer semestre de 2018, se tiene que el número de víctimas permanece estable con respecto al primer semestre de 2017; en ambos años se presenta una víctima. \nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 3,1. Esta tasa estuvo 1.3 puntos por encima de la tasa de Antioquia, y 3.1 puntos por debajo de la tasa del país. ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en su totalidad en las mujeres.  \nEn el primer semestre de 2018, se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los grupos de edad de niños, niñas y adolescente (59%), y de jóvenes (33%). \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los los martes(50%) y domingos(50%). En el primer semestre de 2018, se presentó un caso el miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se concentraron principalmente en la franja de la tarde con el 100% de los casos. En contraste, en el 2017 el 100% de los casos se presentó en la madrugada.\nAhora bien, en lo corrido del año, se presentó un caso el miércoles en la tarde.\n\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 82% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). \nEn el primer semestre de 2018 se presentó un caso, pero no se reportó el tipo de arma empleada.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los dos barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS y SABANITAS; estos barrios concentran el 100% de los casos de delitos sexuales que se presentaron. En el primer semestre de 2018 se presentó un caso en el barrio/vereda LOS ASIENTOS.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 5 víctimas en el primer semestre de 2017 a 0 en el 2018 (-100%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue 10,7 y estuvo 4,4 puntos por debajo de la tasa de ANTIOQUIA y 8,1 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en su totalidad en las mujeres.  \nEn el primer semestre de 2018, no se registran víctimas.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes y adultos. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, no se registran víctimas.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes(43%), seguidos por los domingos(25%). \nCon respecto a la franja horaria, en 2017 este delito se presentó en mayor proporción durante la madrugada.\nEn el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 53% de los casos de violencia intrafamiliar se emplearon objetos contundentes.            \nEn el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana y en la vía pública del municipio. Sin embargo, en 2017 la proporción de casos que se presentó en la zona rural del municipio es superior a la de la zona urbana, y la mayoría de los casos se presentó en casas/apartamentos. En cuanto a la ubicación, en 2019 los tres barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS, SOPETRANSITO y LA RONDA; estos barrios concentran el 100% de los casos de violencia intrafamiliar. En el primer semestre de 2018 el barrio con el mayor número de casos es LOS ASIENTOS (60%). ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 4 víctimas en el primer semestre de 2017 a 1 en el 2018 (-75%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 13,8 y  estuvo 25,6 puntos por debajo de la tasa de ANTIOQUIA, y 23,9 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 76% de las víctimas). Por su parte, la proporción de víctimas mujeres presenta una tendencia creciente desde el 2017. \nEn el primer semestre de 2018, la proporción de hombres víctimas es superior al promedio de los últimos cuatro años y no se registran casos de mujeres víctimas.  ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (en promedio, representan el 64% de las víctimas). En 2017 se presenta un incremento en la proporción de jóvenes que son víctimas de este delito. \nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 100% de los casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes (56%). En el primer semestre de 2018, el delito se concentró en los miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra  en la franja de la noche con el 100% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada\nAhora bien, en lo corrido del año, el miércoles en la noche presenta la mayor concentración de casos. ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene esta tendencia.\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 61% de los casos de hurto a personas no se han empleado armas.\nEn el primer semestre de 2018 se presenta un caso en el que el agresor utiliza arma de fuego.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en las vías públicas y se ha venido distribuyendo de forma homogénea entre la zona rural y urbana del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS, PINGURO, PARQUE PRINCIPAL, TABACAL y CALLE ABAJO; estos barrios concentran el 85% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el barrio/vereda PINGURO ha presentado el mayor número de casos (100%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se presentaron dos casos de hurto a automotores (en 2013). En el primer semestre de 2018 no se registraron caos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres. \nEn lo corrido del año no se registran casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos. \nEn lo corrido del año no se han registrado casos.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn los últimos cuatro años no se han registrado casos de hurto a automotores en municipio.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años no se han registrado casos de hurto a automotores en municipio.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nEn los últimos cuatro años no se han registrado casos de hurto a automotores en municipio.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 1 caso en el primer semestre de 2017 a 2 casos en el 2018 (100%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (100%). No obstante, en el primer semestre de 2018 se observa la aparición de un caso de una víctima mujer.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos y jóvenes.  En el primer semestre de 2018 el delito se distribuyó de forma homogénea entre jóvenes y adultos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los miércoles (100%). En el primer semestre de 2018, se presentó un caso el sábado y otro el domingo.\nCon respecto a la franja horaria, en el primer semestre de 2018 un caso se presentó en la mañana y otro en la tarde . En el 2017 la mayoría de los casos se presentó en la mañana. \nAhora bien, en lo corrido del año, el hurto a motocicletas se ha distribuido de forma homogénea en la mañana del sábado y la tarde del domingo(cada día presenta un caso).\n\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene esta tendencia. En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 86% de los casos se ha utilizado  llave maestra. \nEn el primer semestre de 2018, en un caso el agresor utilizó un arma de fuego, y en otro, no utilizó armas.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se presenta un caso en la zona urbana y otro en la zona rural. \nEn cuanto a la ubicación, en 2019 el 100% de los casos de hurto a motocicletas se presentó en EL PARQUE PRINCIPAL.   En el primer semestre de 2018 los barrios con el mayor número de casos fueron: LA AZOMADERA y LOS ASIENTOS; estos barrios concentran el 100% de los casos que se registraron en el municipio. \n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDesde 2016 se registra una tendencia decreciente en el número de casos de hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 5 casos en el primer semestre de 2017 a 1 caso en el 2018 (-80%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 el hurto a comercio se distribuyó de forma homogénea en la semana, exceptuando el domingo. En el primer semestre de 2018 se presentó un caso el jueves.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción en la franja de la mañana. En contraste, en el 2017, el delito se presentó en mayor proporción durante la tarde (50%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 58% de los casos de hurto a comercio no se han utilizado armas. En el primer semestre de 2018 se tiene que en el 100% de los casos se utilizaron palancas.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cuatro barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS, PINGURO, EL EDEN, y SECTOR LOS TANQUES; estos barrios concentran el 100% de los casos de hurto a comercio durante ese año. En el primer semestre de 2018 se presentó un caso en el PARQUE PRINCIPAL.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han presentado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se presentaron seis casos de hurto a residencias en el municipio (todos en el 2017). Con respecto primer semestre de 2018, se evidencia una disminución en el número de casos, pasando de 2 casos en el primer semestre de 2017 a 1 caso en el 2018 (-50%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 se presentó un caso cada día de la semana exceptuando el jueves. En el primer semestre de 2018 se presentó un caso el sábado.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en la mañana. En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (50%).\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 100% de los casos de hurto a residencias se utilizaron objetos contundentes.  En el primer semestre de 2018 no se utilizaron armas. ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS ASIENTOS, CARAUQUIA, PINGURO, EL CALVARIO y LA PALMA; estos barrios concentran el 100% del hurto a residencias. En el primer semestre de 2018 el barrio CALLE ABAJO presentó un caso.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDesde 2016 se registra una tendencia creciente en el número de víctimas de amenazas en el municipio. Sin embargo, en el primer semestre de 2018 se revierte esta tendencia, pasando de 3 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-33%).",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 82% de las víctimas de amenazas han sido hombres. Sin embargo, desde 2017 se registra una tendencia creciente en la proporción de mujeres que son víctimas de este delito. \nEn el primer semestre de 2018 se presenta el mismo número de mujeres y hombres víctimas.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayoría de casos, los adultos han sido las víctimas de este delito. El grupo de edad más afectado en 2017 fueron los jóvenes.  En el primer semestre de 2018 el delito se concentró en los adultos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción los viernes (50%). En el primer semestre de 2018 este delito se concentró en los jueves y sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó principalmente en la franja de la mañana y madrugada. En el 2017, el delito se presentó en mayor proporción durante la madrugada (100%).\nAhora bien, en lo corrido del año, las amenazas se han distribuido de forma homogénea entre la madrugada del sábado y la mañana del jueves.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 todos casos se presentaron en el barrio/vereda LOS ASIENTOS.  En el primer semestre de 2018 los barrios con el mayor número de casos fueron: LOS ASIENTOS y CALLE BOLIVAR; estos barrios concentran el 100% de los casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra en promedio un caso de extorsión al año. En el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 67% de las víctimas). En 2017 se presenta el mayor número de mujeres víctimas de este delito. \nEn el primer semestre de 2018, no se presentaron casos de extorsión.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años los grupos de edad más afectados han sido los jóvenes y los adultos.\nEn el primer semestre de 2018, no se presentaron casos de extorsión.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn 2017 se presentó un caso de extorsión el lunes en la mañana.\nEn el primer semestre de 2018 no registraron casos de extorsión.\n",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 100% de los casos). ",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en el 2017 la extorsión se concentró en un solo barrio/vereda: CALABACERA. En el primer semestre de 2018 no se ha registrado casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años se presentó un caso de piratería terrestre en el municipio (en el 2013). En el primer semestre de 2018 no se registran casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años se presentó un caso de abigeato en el municipio (en el 2015). En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn el caso que se presentó en 2015 el agresor se movilizaba a pie y no utilizó armas.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn el 2015 se presentó un caso en el barrio/vereda: LOS ASIENTOS. En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años no se han registrado casos de secuestro en el municipio.",
      codigoMunicipio: "5ba011e1b873c20013849615",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de homicidio en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 14 víctimas en el primer semestre de 2017 a 16 victimas en el 2018 (14%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 4,4 por CDH,  y estuvo 1,5 puntos por encima de la tasa de ANTIOQUIA y 2 puntos por encima de la tasa del país.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en los hombres (representan el 95% de las víctimas). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, y no se presentaron casos de mujeres víctimas.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 80% de las víctimas. Desde 2015 la proporción de niños víctimas es superior al 10%.\nEn el primer semestre de 2018, el homicidio se concentró en adultos (50%).\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 el mayor número de casos se presentaron los lunes, miércoles y sábados. En el primer semestre de 2018 los homicidios se concentraron los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde (31%). En contraste, en 2017 el delito se presentó en mayor proporción durante la noche(54%).\nAhora bien, en el primer semestre de 2018 el viernes en la tarde presentó el mayor número de homicidios. ",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En el primer semestre de 2018 los agresores continúan movilizándose a pie. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 76% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 88% de los casos el agresor utilizó arma de fuego.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: SAN MARINO, ZUNGO EMBARCADERO, LA CADENA, 12 DE OCTUBRE  y PARAISO; estos barrios concentraron el 50% de los homicidios. En el primer semestre de 2018 el barrio/vereda GAITAN registró el mayor número de casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDesde 2015 se registra una tendencia decreciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 49 víctimas en el primer semestre de 2017 a 26 en el 2018 (-47%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 15,3,  y estuvo 5,8 puntos por debajo de la tasa de ANTIOQUIA y  9,4 puntos por debajo  de la tasa del país.\n\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las distribución de las víctimas de lesiones personales ha sido bastante homogénea entre hombres y mujeres. Sin embargo, desde 2017 se evidencia una tendencia creciente en la proporción de mujeres víctimas.\nEn el primer semestre de 2018, la proporción de mujeres víctimas es superior a la de los hombres, y además, está por encima del promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes y adultos. En conjunto, estos grupos de edad concentran más del 50% de las víctimas. En el 2016 se presenta un incremento en la proporción de niños que son víctimas.\nEn el primer semestre de 2018, las lesiones personales se concentraron en los jóvenes, con el 54% de las víctimas. Esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (27%). En el primer semestre de 2018 la mayoría de los casos se presentaron los lunes y sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró principalmente en la franja de la madrugada (35%). En 2017, el delito también se presentó en mayor proporción durante la madrugada.\nAhora bien, en lo corrido del año, el sábado en la noche presentó el mayor número de casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 57% de las lesiones personales se han cometido con objetos contundentes. El segundo tipo de arma más empleada es el arma blanca.\nEn el primer semestre de 2018, en el 58% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL PLAYON, PUEBLO NUEVO, MARIA CANO, ACAIDANA I, y ZUNGO EMBARCADERO ; estos barrios concentran el 80% de los casos.  En el primer semestre de 2018, el barrio EL PLAYON registró el mayor número de casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018 se presenta una disminución en el número de víctimas, pasando de 17 víctimas en el primer semestre de 2017 a 10 en el 2018 (-41%).\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 4,4. Esta tasa estuvo 2,7 puntos por encima de la tasa de Antioquia, y 1,8 puntos por debajo de la tasa del país. ",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres(95%).  \nEn el primer semestre de 2018, se presenta un incremento en la proporción de hombres víctimas.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (77%).\nEn el primer semestre de 2018, la mayoría de las víctimas fueron adultos, con el 50% de los casos. Este porcentaje es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los los sábados(35%). En el primer semestre de 2018, las lesiones personales se siguen concentrando los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se concentraron principalmente en la franja de la tarde con el 40% de los eventos. En contraste, en el 2017 la mayor proporción de casos se presentó en la madrugada.\nAhora bien, en lo corrido del año, las tardes de los lunes y sábados, las mañanas de los miércoles y las noches del sábado, presentan la mayor concentración de casos.\n\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 72% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). \nEn el primer semestre de 2018 en el 90% de los casos no se reportó el tipo de arma empleada.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana del municipio y desde el 2016 se presenta en mayor proporción en las vías públicas.\nEn cuanto a la ubicación, en 2019 los  barrios/veredas con el mayor número de casos fueron: EL PLAYON, ACAIDANA I, SAN MARINO, y 12 DE OCTUBRE; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 el barrio con el mayor número de casos es EL PLAYON;  concentra el 90% de los casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDesde 2015 se registra una tendencia decreciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 25 víctimas en el primer semestre de 2017 a 8 en el 2018 (-68%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue 4,9 y estuvo 10,2 puntos por debajo de la tasa de ANTIOQUIA y 13,8 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en  las mujeres (84%).  \nEn el primer semestre de 2018, el porcentaje de mujeres víctimas es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes y adultos. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, el 50% de las víctimas fueron niños, niñas y adolescentes.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos(38%). En el primer semestre de 2018 la violencia intrafamiliar se concentró principalmente en los viernes y domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la noche(75%). En contraste, en el 2017 la mayoría de los casos se presentaron en la madrugada (45%). \n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 71% de los casos de violencia intrafamiliar se emplearon objetos contundentes.            \nEn el primer semestre de 2018 en el 50% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana y en la vía pública del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL PLAYON, PUEBLO NUEVO, SAN MARINO, OASIS, y CASA VERDE ; estos barrios concentran el 76% de los casos de violencia intrafamiliar. En el primer semestre de 2018 el barrio con el mayor número de casos es EL PLAYON(50%). ",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 35 víctimas en el primer semestre de 2017 a 23 en el 2018 (-34%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 12,4 y  estuvo 26,9 puntos por debajo de la tasa de ANTIOQUIA, y 25,3 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 51% de las víctimas). Por su parte, la proporción de víctimas mujeres presenta una tendencia creciente desde el 2016. \nEn el primer semestre de 2018, la proporción de hombres víctimas es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (en promedio, representan el 57% de las víctimas). En 2017 se presenta un incremento en la proporción de jóvenes que son víctimas de este delito. \nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 61% de las víctimas.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los miércoles y viernes. En el primer semestre de 2018, el delito se concentró en los lunes y viernes(cada día concentra el 22% de los casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la madrugada y de la mañana . En contraste, en el 2017, el delito se presentó en mayor proporción durante la tarde.\nAhora bien, en lo corrido del año, el viernes en la madrugada presenta la mayor concentración de casos. ",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene esta tendencia, pero la proporción de casos en los que el agresor se movilizó en bicicleta o vehículo es superior al promedio de los últimos cuatro años. \nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 57% de los casos de hurto a personas no se han empleado armas.\nEn el primer semestre de 2018 en el 52% de los casos el agresor no utilizó armas. En el 30% de los casos utilizó arma blanca; esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL PLAYON, OCAMA, PUEBLO NUEVO, MARIA CANO, y LAURELES; estos barrios concentran el 56% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el barrio/vereda: CASA LAURELES ha presentado el mayor número de casos (13%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se presentaron dos casos de hurto a automotores (en 2015 y 2016). En el primer semestre de 2018 no se registraron caos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres. \nEn lo corrido del año no se registran casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos. \nEn lo corrido del año no se han registrado casos.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el 2015 se presentó un caso de hurto a automotores en la mañana del miércoles. En el 2016 se presentó un caso en la mañana del jueves.\nEn el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años en los dos casos que se presentaron, el agresor se movilizaba a pie. En cuanto al tipo de arma se tiene que en ambos casos el agresor utilizó llaves maestras. \nEn lo corrido del año no se han registrado casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nEn los últimos cuatro años el hurto a automotores se ha presentado en la zona urbana del municipio. Los barrios/veredas que concentran el mayor número de casos son: SAN MARINO y LOS PARQUES; estos barrios concentran el 100% de los casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 4 casos en el primer semestre de 2017 a 2 casos en el 2018 (-50%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (63%). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos y jóvenes. En 2017 el 83% de las víctimas fueron jóvenes.  En el primer semestre de 2018 el delito se distribuyó de forma homogénea entre jóvenes y adultos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes y jueves; en conjunto concentran el 67% de los casos. En el primer semestre de 2018, el jueves presenta la mayor concentración de casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 el hurto a motocicletas se presentó en su totalidad en las mañanas(100%). En el 2017 la mayoría de los casos se presentaron en la madrugada (83%). \nAhora bien, en lo corrido del año, el jueves en la jornada de la mañana presenta el mayor número de casos.\n\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre de 2018 se mantiene este comportamiento. En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 62% de los casos se ha utilizado la llave maestra. \nEn el primer semestre de 2018, en ningún caso se utilizaron armas.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se presenta un caso en la zona urbana y otro en la zona rural. \nEn cuanto a la ubicación, en 2019  los barrios/veredas que más han presentando casos son: OCAMA, EL MILAGRO, POLINES DE SAN SEBASTIAN, CASA VERDE, y EL PLAYON; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: CASA VERDE y SAN MARINO; estos barrios concentran el 100% de los casos que se registraron en el municipio. \n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDesde 2016 se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 5 casos en el primer semestre de 2017 a 40 casos en el 2018 (700%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (29%). En el primer semestre de 2018 se siguen concentrando los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana (58%). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (42%).\nAhora bien, en lo corrido del año, el miércoles en la madrugada presenta el mayor número de casos. Las madrugas de los sábados y domingos también presentan una alta concentración de casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 43% de los casos de hurto a comercio no se han utilizado armas. En el primer semestre de 2018 se tiene que en el 45% de los casos no se utilizaron armas. El segundo tipo de arma más utilizada son las palancas (25%).\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUEBLO NUEVO, VDA EL DIAMANTE, LAURELES, OCAMA, y VIJAGUAL; estos barrios concentran el 52% de los casos de hurto a comercio durante ese año. En el primer semestre de 2018 el barrio/vereda que más casos ha presentado es ZUNGO EMBARCADERO; concentra el 33% de los casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han presentado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 13 casos en el primer semestre de 2017 a 11 casos en el 2018 (-15%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron el domingo(26%). En el primer semestre de 2018 el delito se concentró en los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada(55%). En el 2017, el hurto a residencias también se presentó en mayor proporción durante la madrugada (48%).\nAhora bien, en el primer semestre de 2018 la madrugada del miércoles y viernes, y la noche del sábado, presentan la mayor concentración de casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 55% de los casos no se emplearon armas. Sin embargo, en el 2017 se presenta un aumento en el número de casos en los que se utilizaron palancas.  En el primer semestre de 2018 en el 36% de los casos no se emplearon armas, en el 36% de los casos se utilizaron palancas, y en el 27% restante, objetos contundentes.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas el con el mayor número de casos fueron: EL PLAYON, OCAMA, EDEN, LUIS BENITEZ, y SAN MARINO; estos barrios concentran el 50% de los eventos. En el primer semestre de 2018 el barrio que ha presentado el mayor número de casos es COCA COLA (18%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de amenazas en el municipio. Sin embargo, en el primer semestre de 2018 se revierte esta tendencia, pasando de 22 víctimas en el primer semestre de 2017 a 13 victimas en el 2018 (-41%).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 59% de las víctimas de amenazas han sido hombres. Sin embargo, desde 2017 se registra una tendencia creciente en la proporción de mujeres que son víctimas de este delito. \nEn el primer semestre de 2018 aumenta la proporción de hombres víctimas con respecto al comparativo semestral del 2017.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayoría de casos las víctimas de amenazas han sido jóvenes y adultos. En el primer semestre de 2018 aumenta la proporción de adultos mayores que son víctimas de este delito.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción los sábados (29%). En el primer semestre de 2018 este delito se concentró en los martes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada(38%). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (53%).\nAhora bien, en lo corrido del año, las amenazas se han distribuido de forma homogénea entre las madrugadas del lunes y miércoles, la mañana del martes, la tarde del domingo, y la noche del martes (cada día presenta dos casos).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrios/veredas con el mayor número de casos fueron: EL PLAYON, PUEBLO NUEVO, CASA VERDE, SANTILLANA, y VDA EL DIAMANTE; estos barrios concentran el 92% de los casos. \nEn el primer semestre de 2018 todos los casos se presentan en el barrio/vereda EL PLAYON.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registran, en promedio, tres casos de extorsión al año. En el primer semestre de 2018 se registra un caso.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en las mujeres (en promedio, representan el 57% de las víctimas). \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años los grupos de edad más afectados han sido los adultos y los adultos mayores.\nEn el primer semestre de 2018, el delito se concentra en adultos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn 2017 la mayor parte de los casos de extorsión se presentaron el jueves (50%). En el primer semestre de 2018, se presentó un caso el lunes. \nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la noche. En contraste, en el 2017 la extorsión se concentró en las tardes.\nAhora bien, en lo corrido del año el lunes en la noche se presentó un caso.\n",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 33% de los casos). En el primer semestre de 2018 se presenta un caso en el que se extorsiona a través de las redes sociales.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en 2017 la extorsión se concentró en cuatro barrios/veredas:  PUEBLO NUEVO, LOS CEDROS, GAITAN, y EL PRADO. En el primer semestre de 2018 se presentó un caso en EL PLAYON.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años no se han presentado un casos de piratería terrestre en el municipio. ",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años se presentaron tres casos de abigeato en el municipio (en el 2015). En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn los tres casos que se presentaron en 2015 el agresor se movilizaba a pie y utilizó arma de fuego.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn el 2015 el abigeato se concentró en dos barrios/veredas: EL ENCANTO y VDA EL DIAMANTE.",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años se registró un caso de secuestro en el municipio (en el 2015).",
      codigoMunicipio: "5ba011bcb873c20013849611",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 4 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-50%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 4  y estuvo 0,4 puntos por encima de la tasa de CAQUETÁ y 1,6 puntos por encima de la tasa del país.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en los hombres (representan el 90% de las víctimas). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, y no se presentan casos de mujeres víctimas.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 80% de las víctimas. Desde 2014 la proporción de adultos mayores víctimas es superior al 10%.\nEn el primer semestre de 2018, los homicidios se siguen concentrando en los adultos y jóvenes.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 el mayor número de casos se presentó los sábados (44%). En el primer semestre de 2018 se presentó un homicidio el lunes y otro el viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la mañana y otro en la tarde. En contraste, en 2017 el delito se presentó en mayor proporción durante la noche(78%).\nAhora bien, en el primer semestre de 2018, se presentó un caso en la mañana del lunes, y otro en la tarde del viernes.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En el primer semestre de 2018 los agresores continúan movilizándose a pie. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 87% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 se presentó un caso con arma de fuego, y otro con arma blanca.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: BARRIO VILLA COLOMBIA, BARRIO BELALCÁZAR, BELLO HORIZONTE, VDA. EL TRIUNFO, y BARRIO LA LIBERTAD; estos barrios concentraron el 100% de los homicidios. En el primer semestre de 2018 se presentó un caso en la VDA. LAS AMERICAS, y otro en el BARRIO EL CENTRO.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDesde 2015 se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 19 víctimas en el primer semestre de 2017 a 11 en el 2018 (-42%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 16,6  y estuvo 3,4 puntos por debajo de la tasa de CAQUETÁ y  8,1 puntos por debajo  de la tasa del país.\n\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en los hombres. Sin embargo, desde 2017 se evidencia una tendencia creciente en la proporción de mujeres víctimas.\nEn el primer semestre de 2018, la proporción de mujeres víctimas es superior a la de los hombres, y además, está por encima del promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes y adultos. En conjunto, estos grupos de edad concentran más del 80% de las víctimas. En el 2016 se presenta un incremento en la proporción de niños que son víctimas.\nEn el primer semestre de 2018, las lesiones personales se concentraron en los jóvenes, con el 55% de las víctimas. Esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (32%). En el primer semestre de 2018 las lesiones personales se siguen concentrando en los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la noche con el 36% de los casos. En 2017, el delito también se presentó en mayor proporción durante la noche con el 35% de los casos.\nAhora bien, en lo corrido del año, el sábado en la jornada de la noche presenta el mayor número de casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 69% de las lesiones personales se han cometido con objetos contundentes. El segundo tipo de arma más utilizada es el arma de fuego.\nEn el primer semestre de 2018 en el 82% de los casos se utilizaron objetos contundentes.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento, pero aumenta la proporción de casos que presentaron en casas/apartamentos y en bares o cantinas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CENTRO, BARRIO BELALCÁZAR, BARRIO EL CENTRO, BARRIO EL RECREO, y INSPOLICIA PURTO HUNGRIA; estos barrios concentran el 67% de los casos.  En el primer semestre de 2018, el barrio EL CENTRO; concentra el mayor número de casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. Con respecto primer semestre de 2018, se tiene que el número de víctimas disminuyó con respecto al primer semestre de 2017; pasando de 9 víctimas en 2017 a 2 en 2018 (-78%).\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 6,3. Esta tasa estuvo 1,5 puntos por debajo de la tasa de Caquetá, y 0,1 puntos por encima de la tasa del país. ",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres(88%).  \nEn el primer semestre de 2018, no se presentaron hombres víctimas.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (66%). Desde 2016 se ha venido presentando un aumento la proporción de jóvenes víctimas.\nEn el primer semestre de 2018, la proporción jóvenes víctimas es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los lunes(57%). En el primer semestre de 2018, los delitos sexuales se concentraron en los jueves y domingos. \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se presentaron principalmente en la franja de la mañana y de la tarde. En contraste, en el 2017 la mayor proporción de casos se presentó en la noche.\nAhora bien, en lo corrido del año, se presentó un caso la mañana del jueves y otro en la tarde del domingo.\n\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 68% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). \nEn el primer semestre de 2018 en el 100% de los casos se utilizaron objetos contundentes.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana del municipio y desde el 2017 se presenta en mayor proporción en casas/apartamentos. \nEn cuanto a la ubicación, en 2019 los  barrios/veredas con el mayor número de casos fueron: BRISAS DE ANAYA, BARRIO EL CENTRO,VDA. BIRMANIA, VIA A RIONGERO, y BARRIO ABBAS TURBAY; estos barrios concentran el 57% de los casos de delitos sexuales que se presentaron. En el primer semestre de 2018 se presentó un caso en la VDA. ANAYACITO ARRIBA y  otro en el BARRIO ABBAS TURBAY.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 5 víctimas en el primer semestre de 2017 a 4 en el 2018 (-20%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue 3,6 y estuvo 7 puntos por debajo de la tasa de Caquetá y 12,5 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres (91%).  En el primer semestre de 2018 no se presentaron casos de hombres víctimas.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDesde 2014 la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes (el 40%). Los adultos también tienen una participación importante en el total de víctimas. Así mismo, desde 2016 se presenta un tendencia creciente en la proporción de niños, niñas y adolescentes víctimas. \nEn el primer semestre de 2018, el 50% de las víctimas fueron adultos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes(50%). En el primer semestre de 2018 la violencia intrafamiliar se concentró en los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la madrugada(50%). En el 2017 la mayoría de los casos también se presentaron en la madrugada (50%). \nAhora bien, en el primer semestre de 2018 los casos de violencia intrafamiliar se han distribuido de forma homogénea en las madrugadas del sábado y del domingo, en la tarde del domingo, y en la noche del miércoles.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 65% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018 se utilizaron objetos contundentes (50%) y armas blancas(50%).",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana y en la vía pública del municipio. Sin embargo, en 2017 el 75% los casos se presentaron en casas/apartamentos. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: BARRIO BELALCÁZAR, LAS AMERICAS, BARRIO EL RECREO, NUEVO HORIZONTE, y VDA. PUERTO MANRRIQUE ; estos barrios concentran el 75% de los casos de violencia intrafamiliar. En el primer semestre de 2018, el 100% de los casos se presentaron en los barrios/veredas: CENTRO, BARRIO VEINTE DE ABRIL, BARRIO EL CEDRAL, y BARRIO EL RECREO. \n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 6 víctimas en el primer semestre de 2017 a 4 en el 2018 (-33%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 5,4 y estuvo 16,8 puntos por debajo de la tasa de CAQUETÁ y 32,3 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 62% de las víctimas). Por su parte, la proporción de víctimas mujeres presenta una tendencia creciente desde el 2016. \nEn el primer semestre de 2018, en el delito se presenta en igual proporción en hombres y mujeres. ",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos, seguidos por los jóvenes. En particular, desde 2016 se registra una tendencia creciente en la proporción de jóvenes víctimas de este delito. Así mismo, la proporción de niños víctimas presenta un incremento en el 2017.\nEn el primer semestre de 2018, el hurto a personas se concentró en jóvenes con el 50% de las víctimas.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (25%). En el primer semestre de 2018, el delito se concentró en los martes.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentaron dos casos en mañana y dos en la tarde. En contraste, en el 2017, el delito se presentó en mayor proporción durante la mañana (42%).\nAhora bien, en lo corrido del año, los casos de hurto a personas se han presentado en las tardes del martes y miércoles, y en las mañanas de los martes y viernes.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. En 2017 aumenta la proporción de casos en los que el agresor se moviliza en motocicleta. En el primer semestre de 2018 en todos los casos el agresor se movilizó a pie.\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 71% de los casos de hurto a personas no se emplearon armas.\nEn el primer semestre de 2018 en el 50% de los casos el agresor no utilizó armas. Se presentó un caso en el que agresor utilizó arma blanca, y un caso en el que utilizó un objeto contundente.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. Sin embargo, desde 2016 se presenta un incremento en proporción de casos que se presentaron en la zona rural. En particular, en el primer semestre de 2018 este delito se concentró en la zona rural. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VIA AL MUNICIPIO DE PUERTO RICO, CENTRO, BARRIO 1RO DE OCTUBRE, BARRIO BELALCÁZAR, y VDA. SAN PEDRO; estos barrios concentran el 62% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el delito se concentró en los barrios/veredas: VDA. SAN PEDRO, VIA AL MUNICIPIO DE PUERTO RICO, BARRIO BELALCÁZAR, y BARRIO 1RO DE OCTUBRE; estos barrios concentran el 100% de los casos. \n.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se registra una tendencia estable en el número de casos de hurto a automores. En promedio, se ha presentado un caso por año en el municipio. En el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres (75%). \nEn lo corrido del año no se registran casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos(40%). \nEn lo corrido del año no se han registrado casos.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el 2017 se presentó un caso de hurto a automotores en la mañana del viernes.\nEn el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos últimos cuatro se presentó un caso en el que el agresor se movilizaba a pie, y otro en el que se movilizaba en motocicleta. \nEn lo corrido del año no se han registrado casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nEn los últimos cuatro años se presentaron dos casos en la zona rural. Los barrios/veredas que en los que se presentaron estos casos fueron: VIA AL MUNICIPIO DE PUERTO RICO y VDA. PUERTO PACHECO.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 3 casos en el primer semestre de 2017 a 7 casos en el 2018 (133%).",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (75%). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos, seguidos por los jóvenes. En el primer semestre de 2018 el delito se ha concentrado en los jóvenes.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes, sábados y domingos; en conjunto concentran el 82% de los casos. En el primer semestre de 2018, el domingo presenta la mayor concentración de casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 el hurto a motocicletas se distribuyó de forma uniforme en las jornadas de la madrugada, mañana y tarde.  En el 2017 la mayoría de los casos se presentaron en la madrugada y en la tarde.\nAhora bien, en lo corrido del año, el domingo en la madrugada presenta el mayor número de casos.\n\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más utilizado por los agresores es la motocicleta. En el primer semestre de 2018 se mantiene este comportamiento. En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 47% de los casos no se emplearon armas.\nEn el primer semestre de 2018, la proporción de casos en los que no se utilizaron armas es igual a la de los casos en los que se utilizaron llaves maestras.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto a la ubicación, en 2019 los barrios/veredas que más han presentando casos son: BARRIO BELALCÁZAR, VIA AL MUNICIPIO DE PUERTO RICO, BARRIO EL RECREO, VDA. SERRANIA BAJA, y VDA. MANZANARES; estos barrios concentran el 72% de los casos. En el primer semestre de 2018 el barrio con el mayor número de casos fue: BARRIO EL CENTRO; concentra el 43% de los casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio.\nEn el primer semestre de 2018 el número de casos se mantiene estable con respecto al comparativo semestral de 2017.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados (38%). En el primer semestre de 2018 se presentó un caso cada día de la semana exceptuando los sábados y domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franjas de la madrugada y mañana. En contraste, en el 2017 el delito se presentó en mayor proporción durante la tarde (38%).\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 50% de los casos de hurto a comercio no se han utilizado armas. En el primer semestre de 2018 se tiene que en el 60% de los casos no se utilizaron armas. El segundo tipo de arma más utilizada son las palancas (25%).\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: BARRIO BELALCÁZAR, VDA. LA LIBERTAD, BARRIO OLIMPICO, VDA. LORENZO, y VIA AL MUNICIPIO EL PAUJIL; estos barrios concentran el 75% de los casos de hurto a comercio que se presentaron. En el primer semestre de 2018 los cinco barrios/veredas que más han presentado casos son: VDA. EL MESON, BARRIO LA LIBERTAD, VIA AL MUNICIPIO EL PAUJIL, BARRIO EL CENTRO, y VDA. BERLIN; estos barrios concentran el 100% de los casos.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han presentado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 7 casos en el primer semestre de 2017 a 9 casos en el 2018 (28%).",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes, sábados y domingos (cada día concentra el 25% de los casos). En el primer semestre de 2018 el delito se concentró en los lunes, jueves y sábados (cada día concentra el 22% de los casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada(67%). En el 2017, el hurto a residencias también se presentó en mayor proporción durante la madrugada (42%).\nAhora bien, en el primer semestre de 2018 el lunes en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 43% de los casos no se emplearon armas. En el primer semestre de 2018, el tipo de arma más empleada fueron las palancas, seguidas por los objetos contundentes.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LAS AMERICAS, VDA. SAGRADOS CORAZONES, VDA. ANAYACITO ARRIBA, BARRIO BELALCÁZAR, y INSPOLICIA PUERTO HUNGRIA; estos barrios concentran el 43% de los eventos. En el primer semestre de 2018 el barrio que ha presentado el mayor número de casos es el BARRIO EL RECREO (23%).\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDesde 2016 el número de víctimas de amenaza ha venido disminuyendo. Sin embargo, en el primer semestre de 2018 se revierte esta tendencia, pasando de 6 víctimas en el primer semestre de 2017 a 11 en el 2018 (83%).",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 59% de las víctimas de amenazas han sido hombres. \nEn el primer semestre de 2018 aumenta la proporción de mujeres víctimas de este delito; en particular, esta proporción(46%) es superior al promedio de los últimos cuatro años.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayor parte de los casos las víctimas de amenazas han sido adultos(72%). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción durante los lunes y miércoles (en conjunto concentran el 55% de los casos). En el primer semestre de 2018 este delito se concentró en los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (36%). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (55%).\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrios/veredas con el mayor número de casos fueron: VDA. LA PRADERA, BARRIO EL CENTRO, LAS AMERICAS, VDA. SANTA CRUZ, y VDA. JUANCHITO; estos barrios concentran el 81% de los casos. \nEn el primer semestre de 2018 el barrio/vereda con el mayor número de casos es EL CENTRO; concentra el 36% de los casos.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de extorsión. En el primer semestre de 2018 se revierte esta tendencia, pasando de 3 casos en el primer semestre de 2017 a 1 caso en el 2018(-66%). ",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 80% de las víctimas). \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años los grupos de edad más afectados han sido los adultos(80%) y los adultos mayores(16%).\nEn el primer semestre de 2018, el delito se concentra en adultos mayores.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de la semana y franja horaria en que se presenta:\nEn 2017 la distribución de los casos de extorsión durante la semana fue bastante homogénea. En el primer semestre de 2018, se presentó un caso el jueves. \nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la mañana. En 2017, los casos de extorsión también se concentraron en las mañanas(67%).\n\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 67% de los casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en 2017 la extorsión se concentró en cuatro barrios/veredas:  CENTRO, BARRIO PRIMERO DE OCTUBRE, VDA. EL QUEBRANDON MEDIO, BARRIO OLIMPICO, y BARRIO SAN JUDAS; concentran el 100% de los casos.\nEn el primer semestre de 2018 se presentó un caso en EL CENTRO.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años no se han presentado un casos de piratería terrestre en el municipio. ",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años, en promedio, se han presentado dos casos de abigeato por año. En el primer semestre de 2018 se registró un caso.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años, en todos los casos de abigeato el agresor se movilizaba a pie. En el primer semestre de 2018 se mantiene este comportamiento. ",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nDurante los últimos cinco años el abigeato se ha venido concentrando en los barrios/veredas: BARRIO EL CENTRO, VDA.  EL CERNDO, VDA.  LA GALLINETA, VDA. EL CONGRESO, y VIA AL MUNICIPIO DE PUERTO RICO.\n",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años no se han registrado casos de secuestro en el municipio.",
      codigoMunicipio: "5ba01206b873c20013849619",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 21 víctimas en el primer semestre de 2017 a 24 en el 2018 (14%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 4,5  y estuvo 0,8 puntos por encima de la tasa de CAQUETÁ y 2,1 puntos por encima de la tasa del país.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años, los homicidios se han concentrado en los hombres (90%). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 80% de las víctimas. Desde 2014 la proporción de adultos mayores víctimas es superior al 10%.\nEn el primer semestre de 2018, los homicidios se siguen concentrando en adultos y jóvenes.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de casos se presentó los días martes y miércoles (cada día concentra el 22%). En el primer semestre de 2018 el delito se concentró en los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó en la franja horaria de la tarde (38%). En contraste, en 2017 el delito se presentó en mayor proporción durante la noche (34%).\nAhora bien, en el primer semestre de 2018, hubo una concentración de casos el lunes en la mañana y en la noche, el jueves en la tarde, y el domingo en la tarde y en la noche (cada día-jornada concentra el 21% de los casos). ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. Sin embargo, en 2017 la proporción de casos en los que el agresor se movilizaba en motocicleta aumentó al 31%.\nEn el primer semestre de 2018 la proporción de casos en los que el agresor se movilizaba en motocicleta (29%) es superior al promedio de los últimos cuatro años (15%).\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 74% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 79% de los casos el agresor utilizó arma de fuego.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona rural  del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. LA TOLDA, VDA. VILLA LOBOS, VIA PALESTRO, EL CENTRO, y VDA. LA ESTRELLA; estos barrios concentraron el 40% de los homicidios. En el primer semestre de 2018 el barrio/vereda que concentra más casos es la VDA. GIBRALTAR; concentra el 13% de los casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDesde 2015 se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 51 víctimas en el primer semestre de 2017 a 24 en el 2018 (-53%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 13,8  y estuvo 6,3 puntos por debajo de la tasa de CAQUETÁ y  11 puntos por debajo  de la tasa del país.\n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en los hombres (66 %). Sin embargo, desde 2017 se evidencia una tendencia creciente en la proporción de mujeres víctimas.\nEn el primer semestre de 2018, la proporción de mujeres víctimas es superior a la de los hombres, y además, está por encima del promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes y adultos. En conjunto, estos grupos de edad concentran más del 80% de las víctimas. Desde 2016 se presenta un incremento en la proporción de niños, niñas y adolescentes que son víctimas de este delito.\nEn el primer semestre de 2018, las lesiones personales se concentraron en los adultos (50%), seguidos por los jóvenes (33%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (31%). En el primer semestre de 2018 el delito se concentraron en los miércoles(25%), seguidos por los domingos (21%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde (42%) y noche (46%). En 2017, el delito también se presentó en mayor proporción durante la madrugada con el 42% de los casos.\nAhora bien, en lo corrido del año, el miércoles en la jornada de la tarde presenta el mayor número de casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 57% de los casos se han utilizado objetos contundentes. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 96% de los casos se utilizaron objetos contundentes.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento, pero aumenta la proporción de casos que presentan en casas/apartamentos y en otros espacios.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, CIUDAD BOLIVAR, EL JARDIN, CASERIO GUACAMAYAS, y EL PARAISO; estos barrios concentraron el 41% de los casos de lesiones personales.  En el primer semestre de 2018, el barrio EL CENTRO presenta la mayor concentración de casos (29%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018 se presenta una disminución en el número de víctimas con respecto a comparativo semestral del 2017; en particular, pasó de 26 víctimas en el primer semestre de 2017 a 21 en el 2018.\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 6,8. Esta tasa estuvo 1 punto por debajo de la tasa de Caquetá, y 0,6 puntos por encima de la tasa del país. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres (89%).  \nEn el primer semestre de 2018, se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (80%). El segundo grupo de edad más afectado son los jóvenes.\nEn el primer semestre de 2018, se mantiene este comportamiento. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes y miércoles (cada día concentra el 17% de los casos). En el primer semestre de 2018, los delitos sexuales se concentraron en los lunes.  \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se presentaron en igual proporción en la madrugada, mañana y tarde. En contraste, en el 2017 la mayor proporción de casos se presentó en la mañana (52%).\n\n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 68% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). El segundo tipo de arma más empleada son los objetos contundentes.  \nEn el primer semestre de 2018 el tipo de arma más utilizada fueron los objetos contundentes (43%). En el 38% de los casos no se utilizaron armas. \n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nEn los últimos cinco años los delitos sexuales se han concentrando en la zona urbana del municipio. Sin embargo, desde el 2016  el número de casos en la zona rural ha venido aumentando; en particular, en el primer semestre de 2018 el 67% de los casos se presentaron en la zona rural. \nEn cuanto al tipo de sitio, se tiene que en los últimos cinco años, los delitos sexuales se han presentado en igual proporción en la vía pública, casas/apartamentos, y en fincas y similares; cada sitio concentra el 30% de los casos. En el primer semestre de 2018 el 57% de los casos se presentaron en fincas y similares.\nEn cuanto a la ubicación, en 2019 los  barrios/veredas con el mayor número de casos fueron: EL CENTRO, CASERIO GUACAMAYAS, CIUDAD BOLIVAR, VDA. EL CAIMAN, y VDA. PUENTE GUAMO; estos barrios concentran el 33% de los casos. En el primer semestre de 2018 los barrios CASERIO GUACAMAYAS y LA FLORESTA han presentado el mayor número de casos.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 30 víctimas en el primer semestre de 2017 a 9 en el 2018 (-70%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue de 9,7, y estuvo 0,9 puntos por debajo de la tasa de Caquetá y 9,1 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres (92%).  En el primer semestre de 2018 aumenta la proporción de hombres que son víctimas de este delito. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDurante los últimos cinco años, la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes y adultos; estos grupos de edad concentran más del 80% de las víctimas. \nEn el primer semestre de 2018, se presenta un incremento en la proporción de niños, niñas y adolescentes que son víctimas de este delito (22%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (21%), seguidos por los jueves (19%). En el primer semestre de 2018 la violencia intrafamiliar se concentró en los lunes (45%). \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la noche (44%). En contraste, en el 2017 la mayoría de los casos se presentaron en la madrugada y en la mañana (cada franja concentra más del 30% de los casos). \nAhora bien, en el primer semestre de 2018 el lunes en la jornada de noche presenta el mayor número de casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 73% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018, aunque en la mayoría de casos se siguen empleando objetos contundentes,  aumentó la proporción de casos en los que no se utilizaron armas. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y se ha presentado en su mayoría en casas o apartamentos. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, LA CONSOLATA, CIUDAD BOLIVAR, LA VICTORIA, y SANTA ISABEL; estos barrios concentran el 28% de los casos de violencia intrafamiliar. En el primer semestre de 2018 los casos de violencia intrafamiliar se distribuyeron en 9 barrios: VIA A PUERTO RICO, LA PRIMAVERA, LA ORQUIDEA, CIUDAD BOLIVAR, LA VICTORIA, EL CENTRO, VILLAS DEL PALESTRO, EL PARAISO, y PUERTO REDONDO; en cada barrio se presentó un caso.\n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 30 víctimas en el primer semestre de 2017 a 32 en el 2018 (+7%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 7,9 y estuvo 14,3 puntos por debajo de la tasa de CAQUETÁ y 29,8 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 57% de las víctimas). Sin embargo, desde 2015 se registra una tendencia creciente en la proporción de víctimas mujeres.\nEn el primer semestre de 2018, el 59% de las víctimas fueron mujeres.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (64%), seguidos por los jóvenes. \nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 66% de las víctimas.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los miércoles (23%), seguidos por los lunes (20%). En el primer semestre de 2018, el delito se concentró en los lunes (28%).\nCon respecto a la franja horaria, en el primer semestre de 2018 el mayor número de casos se presentó durante la noche (31%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la tarde (38%).\nAhora bien, en lo corrido del año, los casos de hurto a personas se han concentrado los lunes en las jornadas de la mañana, tarde y noche. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. \nEn el primer semestre de 2018 se mantuvo este comportamiento, sin embargo, aumentó la proporción de casos en los que el agresor se movilizó en motocicleta.\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 53% de los casos de hurto a personas no se han empleado armas. El tipo de arma más utilizada es el arma de fuego (30%), seguida por el arma blanca (13%).\nEn el primer semestre de 2018 en el 31% de los casos el agresor utilizó arma blanca, y en el 22% utilizó arma de fuego; estas proporciones son superiores al comparativo semestral de 2017. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, EL LAGUITO, BARRIO EL CAMPIN, VIA VDA. LOS POZOS, y VIA PALESTRO; estos barrios concentran el 50% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el delito se concentró en los barrios/veredas: EL CENTRO, PUERTO REDONDO, PRIMERO DE MARZO, EL JARDIN, y LA VICTORIA; concentran el 53% de los casos. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se registra una tendencia estable en el número de casos de hurto a automores. En promedio, se ha presentado un caso por año en el municipio. En el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres (100%). \nEn lo corrido del año no se registran casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, el hurto a automotores se ha presentado con mayor incidencia en los adultos(73%). Los jóvenes son el segundo grupo de edad más afectado.\nEn lo corrido del año no se han registrado casos.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn los últimos cuatro años la mayor parte del hurto a automotores se presentó los sábados (38%).\nEn cuanto a la franja horaria, la mayoría de los casos se han presentado en la jornada de la mañana (42%).\nEn el primer semestre de 2018 no se han presentado casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años en la mayoría de los casos el agresor se ha movilizado a pie y ha utilizado armas de fuego (67%). \nEn lo corrido del año no se han registrado casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:.\nEn los últimos tres años el hurto a automotores se ha concentrado en la zona urbana del municipio. Los barrios/veredas que en los que se han presentado casos son: EL CENTRO, y VDA. VILLA CARMONA. \n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 28 casos en el primer semestre de 2017 a 46 casos en el 2018 (64%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (82%). Sin embargo, desde el 2015 se registra una tendencia creciente en el número de mujeres víctimas. \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres, pero la proporción mujeres víctimas es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos, seguidos por los jóvenes. En el primer semestre de 2018 el delito se sigue concentrando en los adultos. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (27%), seguidos por los martes (18%). En el primer semestre de 2018, el sábado presenta la mayor concentración de casos (26%).\nCon respecto a la franja horaria, en el primer semestre de 2018 el hurto a motocicletas se presentó en mayor proporción durante la madrugada (35%). En el 2017 la mayoría de los casos también se presentó en la madrugada.\nAhora bien, en lo corrido del año, las madrugadas del jueves y domingo, presentan la mayor concentración de casos. \n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cuatro años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más utilizado por los agresores es la motocicleta. En el primer semestre de 2018, la proporción de casos en los que el agresor se movilizó en motocicleta aumentó de forma significativa (pasó del 7% de los casos al 37%). En cuanto al tipo de arma empleada se observa que en los últimos cuatro años, el arma de fuego y la llave maestra fueron las armas más empleadas (en conjunto concentran el 60% de los casos). Sin embargo, en 2017 en más del 50% de los casos no se emplearon armas.\nEn el primer semestre de 2018, la proporción de casos en los que el agresor utilizó un arma de fuego es superior al promedio de los últimos cuatro años. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nSi bien el delito se ha concentrado en la zona urbana del municipio, desde el 2016 se registra una tendencia creciente en la zona rural. En particular, en el primer semestre de 2018 el 63% de los casos se presentaron en la zona rural. \nEn cuanto a la ubicación, en 2019 los barrios/veredas que más han presentando casos son: EL CENTRO, VDA. SAN VENANCIO, VDA. VILLA LOBOS, VIA A INSP. CAMPO HERMOSO, y VIA A SAN JUAN DE LOZADA ; estos barrios concentran el 27% de los casos. En el primer semestre de 2018 los cinco barrios/veredas con el mayor número de casos fueron: VIA A PUERTO RICO, VDA. MINAS BLANCAS, VIA A SAN JUAN DE LOZADA, EL CENTRO, y VDA. EL GUAYABAL; concentran el 32% de los casos.\n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio.\nEn el primer semestre de 2018 el número de casos se redujo con respecto al comparativo semestral de 2017; pasó de 11 casos en 2017 a 9 en 2018 (-18%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los viernes (20%), seguidos por los martes, miércoles y jueves (cada día concentra el 16%). En el primer semestre de 2018 se registraron más casos el miércoles. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (67%). En el 2017, el delito también se concentró en la franja de la madrugada.\nAhora bien, en el primer semestre de 2018 el miércoles en la jornada de madrugada presenta el mayor número de casos.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 43% de los casos de hurto a comercio no se han utilizado armas. El tipo de arma más utilizada son los objetos contundentes (28%). En el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, HERNANDEZ, VDA. ROVIRA, VDA. EL DIAMANTE, y LA VICTORIA; estos barrios concentran el 60% de los casos de hurto a comercio. En el primer semestre de 2018 el barrio/vereda que ha presentado el mayor número de casos es EL CENTRO; concentra el 33% de los casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  Con respecto primer semestre de 2018, se tiene que no hubo variación en el número de casos con respecto al primer semestre de 2017 (29 casos).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (22%). En el primer semestre de 2018 el delito se concentró en los lunes y sábados (cada día concentra el 24% de los casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada(34%). En contraste, en el 2017 el hurto a residencias se presentó en mayor proporción durante la mañana y la tarde.\nAhora bien, en el primer semestre de 2018 el lunes en la madrugada y en la mañana, y el sábado en la tarde presentan el mayor número de casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 41% de los casos no se emplearon armas. En el primer semestre de 2018, el tipo de arma más empleada fueron las palancas, seguidas por los objetos contundentes. Así mismo, se presentó un incremento en la proporción de casos en los que no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL COLISEO, SANTA ISABEL, LA PRIMAVERA, LA CONSOLATA, y 20 DE JULIO; estos barrios concentran el 28% de los eventos. En el primer semestre de 2018 el barrio que ha registrado el mayor número de casos es el PALMERAS ALTAS (11%).\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente el número de víctimas de amenaza en el municipio. Sin embargo, en el primer semestre de 2018 se revierte esta tendencia, pasando de 43 víctimas en el primer semestre de 2017 a 41 en el 2018 (-5%).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 56% de las víctimas de amenazas han sido hombres. \nEn el primer semestre de 2018 aumenta la proporción de hombres víctimas de este delito (59%).\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayor parte de los casos las víctimas de amenazas han sido adultos(67%). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción durante los martes y miércoles (concentran el 21% y 20% de los casos, respectivamente). En el primer semestre de 2018 este delito se concentró en los miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde (37%). En el 2017, el delito se presentó en mayor proporción durante la mañana (35%).\nAhora bien, en el primer semestre de 2018 el miércoles en la madrugada presenta la mayor concentración de casos.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, CASERIO GUACAMAYAS, HERNANDEZ, CIUDAD BOLIVAR, y LA PRIMAVERA; estos barrios concentran el 48% de los casos. \nEn el primer semestre de 2018 el barrio/vereda con el mayor número de casos es VIA A SAN JUAN DE LOZADA; concentra el 8% de los casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de extorsión. En el primer semestre de 2018 se revierte esta tendencia, pasando de 16 víctimas en el primer semestre de 2017 a 2 casos en el 2018(-87%). ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 61% de las víctimas). En 2017 se presenta el mayor número de casos de mujeres víctimas de este delito (45%), sin embargo, en el primer semestre de 2018 no se presentan casos de mujeres víctimas.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas han sido adultos (78%). En 2017 se presenta un incremento en la proporción de adultos mayores que son víctimas de este delito.\nEn el primer semestre de 2018, todas las víctimas han sido adultos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn 2017 la mayor parte de los casos de extorsión se presentaron el sábado (32%). En el primer semestre de 2018, se presentó un caso el sábado y otro el domingo.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la mañana y otro en la tarde. En contraste, en el 2017 la extorsión se concentró en las tardes (50%).\n\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años la llamada telefónica es el medio más utilizado para extorsionar (en el 64% de los casos). En el primer semestre de 2018 en todos los casos el agresor utilizó llamada telefónica.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en 2017 los cinco barrios/veredas con el mayor número de casos fueron:  EL CENTRO, LA VICTORIA, VILLA LINDA, VDA. PUERTO LOZADA, y VDA. HOLANDA; estos barrios concentran el 55% de los casos. En el primer semestre de 2018 se presentó un caso en el barrio: MIRADOR, y otro en el barrio: JOSE MARIA CAMARGO.\n",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nEn los últimos cinco años, se han presentado 24 casos de piratería terrestre en el municipio (todos se registraron el 2017). ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de abigeato en el municipio. En el primer semestre de 2018 se revierte esta tendencia, pasando de 15 casos en el primer semestre de 2017 a 5 en el 2018. ",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn la mayoría de los casos de abigeato el agresor se movilizaba a pie. En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn el 2017 los cinco barrios/veredas con el mayor número de casos fueron: VIA VDA. LOS POZOS, VDA. LAURELES, VDA. YARUMALES, VDA. SAN JORGE ALTO PATO, y la INSPECCION DE CAMPO HERMOSO; estos barrios concentran el 30% de los casos. En el primer semestre de 2018, la INSPECCION DE CAMPO HERMOSO ha registrado el 40% de los casos.",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años se registró un caso de secuestro en el municipio (en el 2015).",
      codigoMunicipio: "5ba012edb873c20013849622",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 8 víctimas en el primer semestre de 2017 a 17 en el 2018 (112%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 6,2  y estuvo 3,3 puntos por encima de la tasa de Antoquia y 3,8 puntos por encima de la tasa del país.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años, los homicidios se han concentrado en los hombres (90%). \nEn el primer semestre de 2018 el delito se sigue concentrando en los hombres.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, los homicidios se siguen concentrando en los adultos y jóvenes, pero aumenta la proporción de niños, niñas y adolescentes que son víctimas de este delito.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de casos se presentó los días lunes y sábados (cada día concentra el 26% de los casos). En el primer semestre de 2018 el delito se concentró en los domingos, seguidos por los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 los homicidios se presentaron en mayor proporción en la franja de la tarde (41%). En contraste, en 2017 el delito se presentó en mayor proporción durante la madrugada (32%).\nAhora bien, en el primer semestre de 2018, el domingo en la tarde ha presentado el mayor número de casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. \nEn el primer semestre de 2018, en todos los casos el agresor se movilizó a pie.  \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 74% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 94% de los casos el agresor utilizó arma de fuego; este porcentaje es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona rural  del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: SANTA CRUZ, PUENTE ROTO, LA CRUZ, SAMBULLIGALLO, y OTU; estos barrios concentraron el 58% de los homicidios. En el primer semestre de 2018 el barrio/vereda que presenta más casos es OTU; concentra el 24% de los casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDesde 2015 se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 25 víctimas en el primer semestre de 2017 a 14 en el 2018 (-44%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 13,7  y estuvo 7,5 puntos por debajo de la tasa de Antoquia y  11,1 puntos por debajo  de la tasa del país.\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en mujeres (51%). La proporción de hombres víctimas ha venido decreciendo desde el 2015.\nEn el primer semestre de 2018, se mantiene este comportamiento. ",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes y adultos. En conjunto, estos grupos de edad concentran más del 80% de las víctimas.\nEn el primer semestre de 2018, se registra un incremento el número de niños, niñas y adolescentes víctimas de este delito. ",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (29%). En el primer semestre de 2018 el delito se concentró en los martes (36%), seguidos por los lunes (29%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana (36%) y noche (29%). En 2017, el delito se presentó en mayor proporción durante madrugada, tarde y noche (cada franja concentra el 26% de los casos).\nAhora bien, en el primer semestre de 2018 el martes en la mañana y el lunes en la noche presentan la mayor concentración de casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 54% de los casos se han utilizado objetos contundentes. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 93% de los casos se utilizaron objetos contundentes.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, URBANIZACION MONTE BLANCO, BARRIO COLOMBIA, CALLE REAL, y SECTOR LA BOMBA; estos barrios concentran el 50% de los casos de lesiones personales.  En el primer semestre de 2018, los barrios BARRIO NUEVO y URBANIZACION MONTE BLANCO presentan el mayor número de casos (cada barrio concentra el 25% de los casos).",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018 se presenta una disminución en el número de víctimas con respecto a comparativo semestral del 2017; en particular, pasó de 6 víctimas en el primer semestre de 2017 a 4 en el 2018 (-33%).\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 3,9. Esta tasa estuvo 2,2 puntos por encima de la tasa de Antoquia, y 2,3 puntos por debajo de la tasa del país. ",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres (84%).\nEn el primer semestre de 2018, se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (29%). El segundo grupo de edad más efectado son los jóvenes.\nEn el primer semestre de 2018, aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito (50%).",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes y domingos (cada día concentra el 25% de los casos). En el primer semestre de 2018, se presentaron dos casos el martes y dos casos el jueves. \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se presentaron en igual proporción en la madrugada y en la tarde. En contraste, en el 2017 la mayor proporción de casos se presentó en la noche (50%).\n\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, todas las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 58% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). El segundo tipo de arma más empleada son los objetos contundentes.  \nEn el primer semestre de 2018 el tipo de arma más utilizada fueron los objetos contundentes (33%). En el 50% de los casos no se utilizaron armas. \n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nEn los últimos cinco años los delitos sexuales se han concentrando en la zona urbana del municipio. Sin embargo, desde el 2016 el número de casos en la zona rural ha venido aumentando. En el primer semestre de 2018 todos los casos se presentaron en la zona urbana.\nEn cuanto al tipo de sitio, se tiene que en los últimos cinco años, los delitos sexueals se han presentado en mayor proporción en las vías públicas, sin embargo, los casos registrados en fincas y similares registran una tendencia creciente.\nEn cuanto a la ubicación, en 2019 los  barrios/veredas con el mayor número de casos fueron: URBANIZACION MONTE BLANCO, EL CHIQUILLO, LA ARGENTINA, PARQUE PRINCIPAL, y SANTA LUCIA; estos barrios concentran el 83% de los casos. En el primer semestre de 2018 el barrio URBANIZACION MONTE BLANCO han presentado el mayor número de casos (75%).\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 9 víctimas en el primer semestre de 2017 a 8 en el 2018 (-11%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue de 5,7, y estuvo 9,2 puntos por debajo de la tasa de Antioquia y 9,1 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en su totalidad en mujeres.  En el primer semestre de 2018 se registra un caso de un hombre víctima.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDurante los últimos cinco años, la mayor parte de las víctimas de violencia intrafamiliar han sido adultos y jóvenes; estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, se presenta un incremento en la proporción de niños, niñas y adolescentes que son víctimas de este delito (25%).",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (39%), seguidos por los lunes (28%). En el primer semestre de 2018 la violencia intrafamiliar se concentró en los lunes, martes y jueves. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la madrugada (75%). En contraste, en el 2017 la mayoría de los casos se presentaron en la tarde (44%).\nAhora bien, en el primer semestre de 2018 el martes en la jornada de tarde presenta el mayor número de casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 58% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018, en el 50% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y se ha presentado en su mayoría en las vías públicas. Sin embargo, desde 2016 se registra una tendencia creciente en el número de casos en la zona rural.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: URBANIZACION MONTE BLANCO, LA CRUZ, LA LOMA, PIEDRAS BLANCAS, y PALOCABILDO; estos barrios concentran el 39% de los casos de violencia intrafamiliar. En el primer semestre de 2018 el barrio con el mayor número de casos fue la URBANIZACION MONTE BLANCO; concentra el 50% de los casos.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 4 víctimas en el primer semestre de 2017 a 7 en el 2018 (+75%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 2,6 y estuvo 36,7 puntos por debajo de la tasa de Antioquia y 35,1 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 61% de las víctimas). Desde 2016 se registra una tendencia decreciente en el número de mujeres víctimas.\nEn el primer semestre de 2018, el delito se sigue concentrando en los hombres.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (48%), seguidos por los jóvenes (registran una tendencia creciente).\nEn el primer semestre de 2018, el hurto a personas se concentró en jóvenes con el 57% de los casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (38%), seguidos por los sábados (25%). En el primer semestre de 2018, el delito se presentó en mayor proporción los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 el mayor número de casos se presentó durante la tarde (57%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la mañana (50%).\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. \nEn el primer semestre de 2018 se mantuvo este comportamiento, sin embargo, se presentó un caso en el que el agresor se movilizaba en motocicleta y un caso en el que se movilizaba en vehículo. Esta proporción es superior al promedio de los últimos cuatro años.\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 60% de los casos de hurto a personas no se han empleado armas. El tipo de arma más utilizada es el arma de fuego (22%), seguida por objetos contundentes(17%).\nEn el primer semestre de 2018 en el 57% de los casos el agresor utilizó arma de fuego. ",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: URBANIZACION MONTE BLANCO, PUERTO NUEVO, MARTANA, EL RETIRO, y SECTOR CEMENTERIO; estos barrios concentran el 75% de los casos de hurto a personas que se presentaron en el año.  En el primer semestre de 2018 el barrio que ha presentado el mayor número de casos es la URBANIZACION MONTE BLANCO; concentra el 29% de los casos. ",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se presentaron dos casos de hurto a automotores en el municipio (en el 2017). En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nEn el 2017 se presentó en el que la víctima era mujer, y un caso en el que la víctima era hombre.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los dos casos que se presentaron en 2017 las víctimas eran adultos.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nUno de los casos que registraron en 2017 se presentó el jueves en la mañana, y otro el viernes en la tarde.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años en todo los casos el agresor se movilizó a pie y utilizó llaves maestras.\nEn lo corrido del año no se han registrado casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:.\nEn 2017 se registró un caso en el barrio/vereda LAS BRISAS y  otro en el barrio ALTOS DE MANILA. \n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 5 casos en el primer semestre de 2017 a 11 casos en el 2018 (120%).",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (66%). En el primer semestre de 2018 no se han registrado casos de mujeres víctimas.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos (66%), seguidos por los jóvenes. En el primer semestre de 2018 el delito se sigue concentrando en los adultos, y se presenta un incremento en la proporción de adultos mayores que son víctimas.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (27%). En el primer semestre de 2018, el domingo presenta la mayor concentración de casos (4 casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 el hurto a motocicletas se presentó en mayor proporción durante la madrugada (45%). En el 2017 la mayoría de los casos también se presentó en la madrugada.\nAhora bien, en lo corrido del año, el lunes en la madrugada presenta el mayor número de casos.\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cuatro años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más útilizado por los agresores es la motocicleta. En el primer semestre de 2018, en todos los casos el agresor se movilizó a pie. En cuanto al tipo de arma empleada se observa que en los últimos cuatro años, el arma más utilizada ha sido la llave maestra. Sin embargo, la proporción de casos en los que no se reportó el tipo de arma empleada también es significativa. En particular, en el primer semestre de 2018 \nen el 55% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nSi bien el delito se ha concentrado en la zona urbana del municipio, desde el 2015 se registra una tendencia creciente en la zona rural. No obstante, en el primer semestre de 2018 todos los casos se presentaron en la zona urbana.\nEn cuanto a la ubicación, en 2019 los barrios/veredas que más han presentando casos son: LA CRUZ, URBANIZACION MONTE BLANCO, LOS ARHORCADOS, LA CEIBA, y OTU; estos barrios concentran el 72% de los casos. En el primer semestre de 2018 el barrio URBANIZACION MONTE BLANCO presentó el mayor número de casos; concentra el 73% de los casos.\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últmos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. \nEn el primer semestre de 2018 se mantiene esta tendencia, pasando de 5 casos en el primer semestre de 2017 a 6 casos en el 2018.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los lunes (33%). En el primer semestre de 2018 se registraron más casos el martes. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada y noche (cada franja concentra el 33% de los casos). En el 2017, el delito se concentró en la franja de la madrugada (67%).\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 45% de los casos de hurto a comercio no se han utilizado armas. El tipo de arma más utilizada es el arma de fuego (25%). En el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: URBANIZACION MONTE BLANCO, ALTO DE LOS MUERTOS, SAN MATEO, CARRIZAL, y EL TAMAR; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 los cinco barrios/veredas que han presentado el mayor número de casos fueron: LA AVANZADA, PUENTE ROTO, PALOCABILDO, BELEN , y URBANIZACION MONTE BLANCO; concentran el 83% de los casos. \n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia relativamente estable en el número de casos de hurto a residencias en el municipio; cada año se registran 4 casos en promedio.  En el primer semestre de 2018, se registra un incremento en el número de casos con respecto  al primer semestre de 2017; pasando de 0 a 2 casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (40%). En el primer semestre de 2018 se presentó un caso el miércoles, y otro el viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la madrugada(100%). En el 2017 el hurto a residencias también se presentó en mayor proporción durante la madrugada.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 62% de los casos no se emplearon armas. En el primer semestre de 2018 en ninguno de los casos se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cuatro barrios/veredas con el mayor número de casos fueron: LAS BRISAS, PARQUE PRINCIPAL, LLANOS DE CORDOBA, y LA PALMICHALA; estos barrios concentran el 100% de los eventos. En el primer semestre de 2018 todos los casos se registraron en la URBANIZACIÓN MONTE BLANCO.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente el número de víctimas de amenaza en el municipio. Sin embargo, en el primer semestre de 2018 se revierte esta tendencia, pasando de 8 víctimas en el primer semestre de 2017 a 7.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 54% de las víctimas de amenazas han sido hombres. Sin embargo, desde 2016 se registra una tendencia creciente el número de mujeres víctimas.\nEn el primer semestre de 2018 aumenta la proporción de hombres víctimas de este delito (86%).\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, la mayor parte de las víctimas de amenazas han sido adultos(47%), seguidos por los jóvenes (37%). En el primer semestre de 2018 aumentó la proporción de adultos víctimas de este delito y se presentó un caso de niños, niñas y adolescentes.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción los martes (29%), seguidos por los viernes (24%). En el primer semestre de 2018 este delito se concentró en los lunes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana (43%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (59%).\nAhora bien, en el primer semestre de 2018 el lunes en la mañana presenta la mayor concentración de casos.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrios/veredas con el mayor número de casos fueron: URBANIZACION MONTE BLANCO, ALTO DE LOS MUERTOS, SAN MATEO, CARRIZAL, y EL TAMAR; estos barrios concentran el 100% de los casos. \nEn el primer semestre de 2018 los cinco barrios/veredas con el mayor número de casos fueron: LA AVANZADA, PUENTE ROTO, PALOCABILDO, BELEN, y URBANIZACION MONTE BLANCO; concentra el 83% de los casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de extorsión. En el primer semestre de 2018 se revierte esta tendencia, pasando de 3 víctimas en el primer semestre de 2017 a 1 víctima.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 71% de las víctimas). En el primer semestre de 2018 el delito se sigue concentrando en los hombres.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas han sido adultos (63%), seguidos por los adultos mayores.\nEn el primer semestre de 2018, se presentó un caso en el que la víctima es adulto mayor.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn 2017 se presentó un caso de extorsión el lunes, martes, jueves y sábado. En el primer semestre de 2018, se presentó un caso el miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la mañana. En el 2017, la mitad de los casos se presentaron en la mañana, y otra la mitad en la tarde (2 casos).\n\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años el 50% de las víctimas han sido extorsionadas de forma directa.  En el primer semestre de 2018 en todos los casos el agresor utilizó llamada telefónica.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en el 2017 la extorsión se registró en cuatro barrios/veredas: SECTOR CEMENTERIO, SANTA RITA, LA CRUZ, y GALAN; en cada barrio se presentó un caso.\nEn el primer semestre de 2018 se presentó un caso en el barrio: LAS CAMELIAS.\n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nEn 2017 se registró un caso de piratería terrestre en el municipio.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos tres años presentó un caso abigeato por año. Sin embargo, en el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caraterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn todos de los casos de abigeato el agresor se movilizaba a pie. En cuanto al tipo de arma, se tiene que en el 67% de los casos el agresor no utilizó armas y en el 33% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn los últimos tres año el abigeato se ha presentado en los barrios/veredas: URBANIZACION MONTE BLANCO, EL COOPERO, y PLATANALES. \n",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años no se han registrado casos de secuestro en el municipio.",
      codigoMunicipio: "5ba0123ab873c2001384961e",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de cero víctimas en el primer semestre de 2017 a una en el 2018.\nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 0 (no se presentaron casos)  y estuvo 2,9 puntos por debajo de la tasa de Antoquia y 2,4 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años, los homicidios se han concentrado en los hombres (100%). \nEn el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido jóvenes y adultos. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, se presentó un caso en el que la víctima es un jóven.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nEn los últimos cuatro años la mayor parte de los homicidios se presentaron el sábado (25%). En el primer semestre de 2018 se presentó un caso el viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la mañana. En contraste, en los cuatro años anteriores, el delito se concentró en la jornada de la noche (63%). \n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caraterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. \nEn el primer semestre de 2018, se presentó un caso en el que el agresor se movilizaba a pie.  \nEn cuanto al tipo de arma se observa que en los últimos cuatro años, el 71% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 se presentó un caso en el que el agresor utilizó un arma de fuego.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDurante los últimos cinco años, en promedio, se presentó el mismo número de casos en la zona rural y en zona urbana del municipio. En el primer semestre de 2018 se presentó un caso en la zona rural. \nEn cuanto a la ubicación, en los últimos tres años los homicidios se han presentado en los barrios/verdas: EL CARMEN, EL CHUSCAL, LA ACUARELA, LA MARIA, y MADEIRA. En el primer semestre de 2018 se presentó un caso el barrio CHAPINEROS.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nEn los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 23 víctimas en el primer semestre de 2017 a 15 en el 2018 (-35%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 25,6  y estuvo 4,5 puntos por encima de la tasa de Antoquia y  0,9 puntos por encima de la tasa del país.\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en hombres (63%). Sin embargo, la proporción de mujeres víctimas ha venido aumentando desde el 2015.\nEn el primer semestre de 2018, la mayor parte de las víctimas fueron hombres (73%).",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción  en adultos (48%), seguidos por los jóvenes(38%). \nEn el primer semestre de 2018, el delito se registra con mayor incidencia en jóvenes; concentran el 67% de los casos. Esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (26%). En el primer semestre de 2018 el delito se sigue concentrando en los domingos (53%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (47%). En 2017, el delito también se presentó en mayor proporción durante la madrugada (44%).\nAhora bien, en el primer semestre de 2018, el domingo en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 58% de los casos se han utilizado objetos contundentes. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó objetos contundentes (65%).",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CHUSCAL, PARQUE PRINCIPAL, EL PLAN, EL ROSARIO, y TABACAL; estos barrios concentran el 54% de los casos de lesiones personales.  En el primer semestre de 2018 el barrio EL CHUSCAL ha presentado el mayor número de casos (11 casos); concentra el 78% de los casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años el número de víctimas de delitos sexuales se ha mantenido relativamente estable (7 víctimas por año, en promedio). En 2016 se registró el mayor número de víctimas (24 víctimas). En el primer semestre de 2018 el número de víctimas se mantuvo estable con respecto al comparativo semestral de 2017; se registró un caso.\nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 4,6. Esta tasa estuvo 2,9 puntos por encima de la tasa de Antoquia, y 1,6 puntos por debajo de la tasa del país. ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres (85%).\nEn el primer semestre de 2018, se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (60%). El segundo grupo de edad más efectado son los jóvenes.\nEn el primer semestre de 2018, se presentó un caso en el que la víctima es un jóven.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes (44%). En el primer semestre de 2018, se presentó un caso el viernes.  \nCon respecto a la franja horaria, en el primer semestre de 2018 se presentó un caso en la madrugada. En 2017, el delito también se presentó en mayor proporción durante la madrugada (78%).\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, todas las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 70% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). El segundo tipo de arma más empleada son los objetos contundentes.  \nEn el primer semestre de 2018 no se reportó el tipo de arma empleada.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nEn los últimos cinco años los delitos sexuales se han concentrado en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 la mayoría de los casos se presentaron en la zona rural y en casas/apartamentos.\nEn el primer semestre de 2018 se registra un caso en la zona urbana y en la vía pública.\nEn cuanto al tipo de sitio, se tiene que en los últimos cinco años, los delitos sexuales se han presentado en mayor proporción en las vías públicas, sin embargo, en 2017 la mayor parte de los casos se presentó en casas o apartamentos (78%).\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas en los que se registraron casos fueron: EL CHUSCAL, PANTANILLO, LAGOS DEL RETIRO, LA LUZ, y CARDIZAL; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 se presentó un caso en el barrio EL CHUSCAL.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 13 víctimas en el primer semestre de 2017 a 10 en el 2018 (-23%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue de 10,3, y estuvo 4,9 puntos por debajo de la tasa de Antioquia y 8,5 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha registrado con mayor incidencia en mujeres (83%). En el primer semestre de 2018 el delito se sigue concentrando en mujeres. ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDurante los últimos cinco años, la mayor parte de las víctimas de violencia intrafamiliar han sido adultos y jóvenes; estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, aumentó la proporción de adultos que son víctimas de este delito (60%). ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados (30%), seguidos por los viernes (30%). En el primer semestre de 2018 la violencia intrafamiliar se concentró en los lunes, miércoles, jueves y viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la tarde (50%). En contraste, en el 2017 la mayoría de los casos se presentaron en la madrugada (40%).\nAhora bien, en el primer semestre de 2018 las tardes de los lunes y jueves, y la mañana del miércoles presentan el mayor número de casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 55% de los casos de violencia intrafamiliar se han empleado objetos contundentes.            \nEn el primer semestre de 2018, en el 50% de los casos el agresor utilizó objetos contundentes y en el otro 50% no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y se ha presentado en su mayoría en las vías públicas. Sin embargo, en 2017 el mayor número de casos se presentó en fincas y similares (45%). \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CHUSCAL, MADEIRA, CARRIZALES, DON DIEGO, y LA ARGENTINA; estos barrios concentran el 65% de los casos de violencia intrafamiliar. En el primer semestre de 2018 el barrio/vereda con el mayor número de casos fue el EL CHUSCAL; concentra el 50% de los casos.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 30 víctimas en el primer semestre de 2017 a 33 en el 2018 (10%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 31,8 y estuvo 7,6 puntos por debajo de la tasa de Antioquia y 5,9 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 62% de las víctimas). Desde 2015 se registra una tendencia decreciente en la proporción de mujeres víctimas.\nEn el primer semestre de 2018, el delito se sigue concentrando en los hombres.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (64%), seguidos por los jóvenes.\nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 42% de los casos. Sin embargo, la proporción de adultos mayores que es víctima de este delito aumentó de forma significativa; pasó de 2 casos en el primer semestre de 2017 a 10 casos en el 2018.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados y domingos (cada día concentra el 23% de los casos). En el primer semestre de 2018, el delito se presentó en mayor proporción durante los viernes (26%).\nCon respecto a la franja horaria, en el primer semestre de 2018 el mayor número de casos se presentó durante la tarde (36%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (35%).\nAhora bien, en el primer semestre de 2018, el martes en la madrugada y el viernes en la tarde, presentan la mayor concentración de casos.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. \nEn el primer semestre de 2018 se mantuvo este comportamiento, sin embargo, aumentó el número de casos en los que el agresor se movilizaba en motocicleta (18%). \nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 66% de los casos de hurto a personas no se han empleado armas. El tipo de arma más utilizada son los objetos contundentes (15%). \nEn el primer semestre de 2018 en el 57% de los casos el agresor no utilizó armas.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, EL CHUSCAL, RIVERAS DEL RETIRO, CARRIZALES, y LOS SALADOS; estos barrios concentran el 57% de los casos de hurto a personas que se presentaron.  En el primer semestre de 2018 el barrio que ha presentado el mayor número de casos es EL CHUSCAL; concentra el 36% de los casos. ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se han presentado, en promedio, dos casos de hurto a automotores por año.  En el primer semestre de 2018 se registraron dos casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años el delito se presentó en su totalidad en hombres. En el primer semestre de 2018 se presentó un caso de una mujer víctima.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nDurante los últimos cinco años, en más del 50% de los casos las víctimas eran adultos. En el primer semestre de 2018 se presentó un caso en el que la víctima era un adulto mayor y otro en el que la víctima era un joven.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el 2017 se presentó un caso de hurto a automotores un lunes en la madrugada y otro caso un jueves en la tarde. En el primer semestre de 2018 se registraron dos casos el sábado en la madrugada.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años en la mayoría de los casos el agresor se movilizó a pie y utilizó llaves maestras (60%).\nEn el primer semestre de 2018 en un caso el agresor utilizó un arma de fuego, y en el otro, no utilizó armas.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:.\nEn 2017 se registró un caso en la zona rural y un caso en la zona urbana.  En el primer semestre se 2018 se mantiene este comportamiento. \nEn cuanto a la ubicación, en el 2017 los barrios/veredas en los que se presentaron casos fueron: RIBERAS y VILLA ELENA. En el primer semestre de 2018 el delito se presentó en los barrios/veredas: NUEVO RETIRO y LA AMAPOLA.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 2 casos en el primer semestre de 2017 a 1 caso en el 2018.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (96%). En el primer semestre de 2018 el delito se sigue concentrando en  hombres.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos (63%), seguidos por los jóvenes (27%). En el primer semestre de 2018 el delito se concentró en su totalidad en jóvenes.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes, martes y miércoles (cada día concentra el 29% de los casos); así mismo, el hurto a motocicletas se concentró en la jornada de la noche. En el primer semestre de 2018, se presentó un caso un domingo en la madrugada.\n\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cuatro años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más útilizado por los agresores es la motocicleta. En el primer semestre de 2018, en todos los casos el agresor se movilizó a pie. En cuanto al tipo de arma empleada se observa que en los últimos cuatro años, el arma más utilizada ha sido la llave maestra (75%). En el primer semestre de 2018 se presentó un caso en el que no se reportó el tipo de arma empleada por el agresor. ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nSi bien el delito se ha concentrado en la zona rural del municipio, desde el 2017 se registra una tendencia creciente en la zona urbana. En el primer semestre de 2018 se presentó un caso en la zona urbana.\nEn cuanto a la ubicación, en 2019 los barrios/veredas que más han presentando casos son: BARCINO, LAS PLAYAS, LA ALAMEDA, EL PORTENTO, y PARQUE PRINCIPAL; estos barrios concentran el 72% de los casos. En el primer semestre de 2018 se presentó un caso en EL CHUSCAL.\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últmos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. \nEn el primer semestre de 2018 se mantiene esta tendencia, pasando de 11 casos en el primer semestre de 2017 a 16 casos en el 2018.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los martes y viernes (cada día concentra el 21% de los casos). En el primer semestre de 2018 se registraron más casos el miércoles. \nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentra principalmente en la franja de la madrugada (50%). En el 2017, el delito también se concentró en la franja de la madrugada (32%).\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 51% de los casos de hurto a comercio no se han utilizado armas. El tipo de arma más utilizada son los objetos contudentes (20%). En el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, EL CHUSCAL, PARQUE SAN JOSE, LOS SALADOS, y AMANECERES ; estos barrios concentran el 63% de los casos de hurto a comercio que se presentaron. En el primer semestre de 2018 el barrio/vereda EL CHUSCAL ha presentado el mayor número de casos (44%).\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  En el primer semestre de 2018 se evidencia que esta tendencia se revierte, pasando 48 casos en el primer semestre de 2017 a 22 casos en el 2018.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes (20%), seguidos por los domingos (19%). En el primer semestre de 2018 el delito se concentró en los martes y sábados (cada día concentra 5 casos). \nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se presentó en mayor proporción en las madrugadas y en las mañanas (cada franja concentra el 36% de los casos). En el 2017 el hurto a residencias se concentró en mayor proporción en la noche y en la madrugada.\nAhora bien, en el primer semestre de 2018 el martes en la mañana presenta el mayor número de casos. \n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 37% de los casos se utilizaron objetos contundentes. La proporción de casos en los que no se emplearon armas también es bastante alta: 31%. En el primer semestre de 2018 el tipo de arma más empleada fueron las palancas; así mismo, aumentó la proporción de casos en los que no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CHUSCAL, LOS SALADOS, PANTANILLO, CARRIZALES, y EL SALADITO; estos barrios concentran el 49% de los eventos. En el primer semestre de 2018 el barrio/vereda EL CHUSCAL registró el mayor número de casos (55%).\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años el número de víctimas de amenazas se ha mantenido relativamente estable; en promedio, se registran 6 víctimas al año. Sin embargo, en 2017 del número de víctimas se redujo a 3. En el primer semestre de 2018 se presentaron 3 víctimas, este número es superior al comparativo semestral de 2017 (2 víctimas).",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 62% de las víctimas de amenazas han sido hombres. Desde 2015 se registra una tendencia decreciente en el número de mujeres víctimas. \nEn el primer semestre de 2018 aumenta la proporción de hombres víctimas de este delito (73%).\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años en la mayor parte de los casos las víctimas de amenazas han sido adultos (54%), seguidos por adultos mayores. En el primer semestre de 2018 el delito se presentó en mayor proporción en adultos (77%).",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción durante los viernes (20%), seguidos por los domingos (19%). En el primer semestre de 2018 este delito se concentró en los martes y sábados (cada día registra 5 casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en las franjas de la madruga y la mañana (cada franja concentra el 36% de los casos). En constraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada y noche.\nAhora bien, en el primer semestre de 2018 el martes en la mañana registró el mayor número de casos. \n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 todos los casos de amenazas se registraron en el barrio/vereda EL CHUSCAL. En el primer semestre de 2018, las amenazas se siguen concentrando en este barrio.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registraron dos casos de extorsión en el municipio (en 2013 y en 2017). En el primer semestre de 2018 no se han registrado casos. ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en su totalidad en los hombres.  ",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años todas las víctimas de extorsión han sido adultos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn 2017 se presentó un caso de extorsión el jueves en la mañana.\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma, en 2017 se presentó un caso en que la víctima fue amenazada a través de una llamada telefónica.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, en 2019 se presentó un caso en el PARQUE SAN JOSE.\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nEn 2017 se registraron tres casos de piratería terrestre en el municipio. En el primer semestre de 2018 no se han presentado casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nEn el 2017 se presentaron tres casos de abigeato en el municipio. En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caraterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn 2017 en la mayoría de los casos el agresor se movilizaba a pie.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn el 2017 el abigeato se presentó en tres barrios: TABACAL, PUENTE PELAEZ, y LA AMAPOLA.\n\n",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años no se han registrado casos de secuestro en el municipio.",
      codigoMunicipio: "5ba011d1b873c20013849613",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 51 víctimas en el primer semestre de 2017 a 65 en el 2018.\nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 5,1  y estuvo 2,2 puntos por encima de la tasa de Antoquia y 2,6 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años, los homicidios se han concentrado en los hombres (96%). \nEn el primer semestre de 2018 aumentó la proporción de mujeres víctimas.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido jóvenes y adultos. Estos grupos de edad concentran más del 90% de las víctimas. \nEn el primer semestre de 2018, el delito se concentró en jóvenes (46%) y aumentó la proporción de niños, niñas y adolescentes que son víctimas. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nEn los últimos cuatro años la mayor parte de los homicidios se presentaron los domingos (22%). En el primer semestre de 2018 el delito se concentró en los lunes, seguidos por los miércoles.\nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró en la tarde (42%). En contraste, en el 2017, el delito se concentró en la jornada de la noche (38%). \nAhora bien, en el primer semestre de 2018 el mayor número de casos se presentó el jueves en la tarde.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caraterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. \nEn el primer semestre de 2018, aumentó la proporción de casos en los que el agresor se movilizaba en motocicleta.\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, el 81% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 92% de los homicidios el agresor utilizó un arma de fuego. Este porcentaje es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDesde el 2015 los homicidios se han venido concentrando en la zona urbana del municipio. No obstante, la participación de la zona rural también es bastante alta.\nEn cuanto a la ubicación, en el 2017 los cinco barrios/verdas con el mayor número de casos fueron: CGTO. CURRULAO, CGTO. NUEVA COLONIA, SANTA FE, VDA. CASANOVA, y EL BOSQUE; estos barrios concentran el 51% de los casos. En el primer semestre de 2018 el CGTO. CURRULAO presenta el mayor número de casos (13%).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nEn los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 148 víctimas en el primer semestre de 2017 a 35 en el 2018 (-76%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 15,6  y estuvo 5,6 puntos por debajo de la tasa de Antoquia y  9,2 puntos por debajo de la tasa del país.\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en mujeres (53%). \nEn el primer semestre de 2018, aumentó la proporción de hombres víctimas de este delito.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en jóvenes (53%), seguidos por adultos (35%).  \nEn el primer semestre de 2018, se mantiene este comportamiento, pero aumenta la proporción de niños, niñas y adolescentes que son víctimas.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los domingos (26%). En el primer semestre de 2018 el delito se sigue concentrando en los domingos (20%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada (47%). En 2017, el delito también se presentó en mayor proporción durante la madrugada (64%).\nAhora bien, en el primer semestre de 2018, el domingo en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 42% de los casos el agresor utilizó objetos contundentes. El segundo tipo de arma más utilizada es el arma blanca (30%).\nEn el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó objetos contundentes (69%).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, CGTO. CURRULAO, EL BOSQUE, CENTRO, y BUENOS AIRES; estos barrios concentran el 85% de los casos de lesiones personales.  En el primer semestre de 2018 el CGTO. CURRULAO ha presentado el mayor número de casos (5 casos; 15% del total).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018 el número de víctimas disminuyó con respecto al comparativo semestral del 2017; pasó de 32 víctimas a 24. \nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 3,5. Esta tasa estuvo 1,7 puntos por encima de la tasa de Antoquia, y 2,7 puntos por debajo de la tasa del país. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres (93%).\nEn el primer semestre de 2018, se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en los niños, niñas y adolescentes (56%). El segundo grupo de edad más efectado son los jóvenes.\nEn el primer semestre de 2018, aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito (71%).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (33%). En el primer semestre de 2018, se presentaron más casos los martes, seguidos por los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la tarde (46%). En constrante, en el 2017 el delito se presentó en mayor proporción durante la madrugada (81%).\nAhora bien, en el primer semestre de 2018 los martes en la jornada de la mañana y tarde, presentan el mayor el mayor número de casos.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, todas las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 76% de los casos de delitos sexuales no se emplearon armas. El  tipo de arma más empleada son los objetos contundentes.  \nEn el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó arma blanca o arma de fuego.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nEn los últimos cinco años los delitos sexuales se han concentrado en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2018 la mayoría de los casos se presentaron en la zona rural y en casas/apartamentos.  \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, CENTRO, VDA. LOS ENAMORADOS, CGTO. NUEVA ANTIOQUIA, y MANUELA BELTRAN; estos barrios concentran el 96% de los casos. En el primer semestre de 2018 el mayor número de casos se registró en el CGTO. CURRULAO.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDesde 2016 se registra una tendencia decreciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 52 víctimas en el primer semestre de 2017 a 16 en el 2018 (-69%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue de 5,2 y estuvo 9,9 puntos por debajo de la tasa de Antioquia y 13,5 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha registrado con mayor incidencia en mujeres (87%). En el primer semestre de 2018 el delito se sigue concentrando en mujeres. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDurante los últimos cinco años, la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes (51%)\nEn el primer semestre de 2018, aumentó la proporción de adultos que son víctimas de este delito (88%). ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (23%), seguidos por los sábados (19%). En el primer semestre de 2018 la violencia intrafamiliar se concentró en los lunes (37%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la madrugada (31%), y la tarde (31%). En contraste, en el 2017 la mayoría de los casos se presentaron en la madrugada (80%).\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 45% de los casos de violencia intrafamiliar se han empleado objetos contundentes. Sin embargo, la proporción de casos en los que no se utilizaron armas también es significativa (38%).  \nEn el primer semestre de 2018, en la mayoría de los casos el agresor no utilizó armas (48%). No obstante, la proporción de casos en los que se utilizaron armas blancas aumentó.  Esta proporción está por encima del promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y se ha presentado en su mayoría en las vías públicas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, BRISAS DEL MAR, JESUS MORA, EL BOSQUE, y MANUELA BELTRAN; estos barrios concentran el 94% de los casos de violencia intrafamiliar. En el primer semestre de 2018 el barrio/vereda con el mayor número de casos fue el INSTITUTO; concentra el 50% de los casos.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio. En 2016 se presentó el mayor número de víctimas (237 víctimas). Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 52 víctimas en el primer semestre de 2017 a 54 en el 2018 (4%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 31,8 y estuvo 7,6 puntos por debajo de la tasa de Antioquia y 5,9 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, en promedio, el delito se ha concentrado en igual proporción en hombres y mujeres. En el primer semestre de 2018 aumenta levemente la proporción de hombres víctimas.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (55%), seguidos por los jóvenes.\nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 74% de los casos. Este porcentaje es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados (21%), seguidos por los lunes (17%). En el primer semestre de 2018, el delito se presentó en mayor proporción durante los lunes y martes (cada día concentra el 20% de los casos).\nCon respecto a la franja horaria, en el primer semestre de 2018 el mayor número de casos se presentó durante la noche (31%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (50%).\nAhora bien, en el primer semestre de 2018, el lunes en la jornada de la noche, presenta la mayor concentración de casos.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. \nEn el primer semestre de 2018  aumentó la proporción de casos en los que el agresor se movilizaba en motocicleta (28%). \nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 59% de los casos de hurto a personas no se han empleado armas. El tipo de arma más utilizada es el arma blanca (18%). \nEn el primer semestre de 2018 en el 55% de los casos el agresor utilizó un arma de fuego. Este procentaje es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, CENTRO, JESUS MORA, CGTO. CURRULAO, y EL BOSQUE; estos barrios concentran el 83% de los casos. En el primer semestre de 2018 el barrio que ha presentado el mayor número de casos es EL INSTITUTO; concentra el 23% de los casos. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años no se han presentado casos de hurto a automotores en el municipio.  En el primer semestre de 2018 se registraron dos casos.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nEn el primer semestre de 2018, se presentó un caso en el que la víctima era hombre, y un caso en el que la víctima era mujer. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn el primer semestre de 2018, se presentó un caso en el que la víctima era un adulto, y un caso en el que víctima era adulto mayor.\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el primer semestre de 2018 se presentó un caso en la noche del martes, y un caso en la noche del sábado. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn el primer semestre de 2018, en ninguno de los casos se reportó el medio por el cual el agresor se movilizaba. En cuanto al tipo de arma, en un caso el agresor utilizó un arma de fuego, y en otro, no utilizó armas. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:.\nEn el primer semestre de 2018 todos los casos se registraron en la zona urbana del municipio. Los barrios en los que se presentaron los casos fueron: VDA. BEDAS BARRIO OFICIO, e INSTITUTO.\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 10 casos en el primer semestre de 2017 a 38 casos en el 2018 (+280%).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (70%). Sin embargo, desde 2015 se registra una tendencia creciente en el número de mujeres víctimas. \nEn el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos (64%), seguidos por los jóvenes (32%). En el primer semestre de 2018 el delito se sigue concentrando en adultos, pero aumenta la proporción de adultos mayores que son víctimas.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes y sábados (cada día concentra el 24% de los casos). En el primer semestre de 2018 el mayor número de casos se presentó los martes.  \nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró en la madrugada (55% de los casos). En 2017, el delito también se concentró en la madrugada.\nAhora bien, en el primer semestre de 2018 el miércoles en la madrugada registra el mayor número de casos. \n\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cuatro años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más útilizado por los agresores es la motocicleta. En cuanto al tipo de arma empleada se observa que en los últimos cuatro años, el arma más utilizada ha sido la llave maestra (64%). En el primer semestre de 2018 en el 61% de los casos el agresor no utilizó armas; así mismo, disminuyó la proporción de casos en los que el agresor utilizó llaves maestras. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nEn los últimos cinco años, el hurto a motocicletas se ha registrado con mayor incidencia en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas que más han presentando casos son: INSTITUTO, MANUELA BELTRAN, GAITAN, CENTRO, y CGTO. EL TRES; estos barrios concentran el 71% de los casos. En el primer semestre de 2018 el CENTRO y el INSTITUTO han registrado el mayor número de casos (cada barrio concentra el 13% de los casos).\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. \nEn el primer semestre de 2018 se mantiene esta tendencia, pasando de 12 casos en el primer semestre de 2017 a 49 casos en el 2018.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los martes (30%). En el primer semestre de 2018 el mayor número de casos se registró los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada (41%). En contraste, en 2017 el delito se concentró en la franja de la mañana (39%).\nAhora bien, en el primer semestre de 2018 los viernes en la madrugada y en la mañana, registran el mayor número de casos.\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 46% de los casos de hurto a comercio no se han utilizado armas. El tipo de arma más utilizada son los objetos contudentes (25%). En el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó un arma de fuego. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, CENTRO, SANTA FE, OBRERO, y CGTO. NUEVA COLONIA; estos barrios concentran el 87% de los casos de hurto a comercio. En el primer semestre de 2018 el barrio/vereda el CENTRO ha presentado el mayor número de casos (29%).\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  En el primer semestre de 2018 se evidencia que esta tendencia se mantiene, pasando 12 casos en el primer semestre de 2017 a 17 casos en el 2018.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (29%). En el primer semestre de 2018 el delito se concentró en los viernes.\nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se presentó en mayor proporción en las mañanas (concentra 41% de los casos). En el 2017 el hurto a residencias se concentró en mayor proporción en la madrugada (57%).\nAhora bien, en el primer semestre de 2018 el martes en la madrugada y el viernes en la tarde presentan el mayor número de casos. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, en el 67% de los casos de hurto a residencias no se emplearon armas.\nEn el primer semestre de 2018 aumentó la proporción de casos en los que no se utilizaron armas (71%). El tipo de arma más utilizada fueron las palancas (12%).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSTITUTO, OBRERO, BUENOS AIRES, URBANIZACION LA LUCILA, y CGTO. EL DOS; estos barrios concentran el 72% de los casos. En el primer semestre de 2018 la URBANIZACION LA LUCILA registró el mayor número de casos (18%).\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de amenazas en el municipio. Sin embargo, en 2017 se presentó una disminución en el número de víctimas. En el primer semestre de 2018 se presentaron 32 víctimas; este número es superior al comparativo semestral de 2017 en el que se presentaron 28 víctimas.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 63% de las víctimas de amenazas han sido hombres. En el primer semestre de 2018 se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de amenazas han sido adultos (45%), seguidos por jóvenes (27%). En el primer semestre de 2018 aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción los lunes (22%), seguidos por los sábados (20%). En el primer semestre de 2018 este delito se concentró en los sábados (23%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la mañana (31%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (57%).\nAhora bien, en el primer semestre de 2018 el sábado en la mañana registró el mayor número de casos. \n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrio/veredas con el mayor número de casos fueron: INSTITUTO, CGTO. EL TRES, CGTO. EL DOS, CENTRO, y OBRERO; estos barrios concentran el 100% de los casos. En el primer semestre de 2018, los cinco barrios con el mayor númer de casos fueron: INSTITUTO, LA INVASION, EL BOSQUE, CGTO. EL TRES, y VDA. MANUEL CUELLO; concentran el 93% de los casos. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de extorsión en el municipio. En el primer semestre de 2018 se evidencia que esta tendencia se revierte, pasando de 5 casos en el primer semestre de 2017 a 1 caso en el 2018.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (82% de los casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de extorsión han sido adultos. Sin embargo, la proporción de adultos mayores que son víctimas también es bastante significativa.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn el 2017, la mayor parte de los casos de extorsión se presentaron los martes y miércoles en la jornada de la mañana. En el primer semestre de 2018 se presentó un caso el sábado en la noche.\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn los últimos cuatro años en el 76% de los casos de extorsión el agresor utilizó una llamada telefónica. En el primer semestre de 2018 se mantiene este comportamiento. ",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, en 2019 todos los casos de extorsión se concentraron en cuatro barrios/veredas: CENTRO, CGTO. NUEVA COLONIA, VDA. PEQUEMAS, y CGTO. CURRULAO. En el primer semestre de 2018, se presentó un caso en el barrio OBRERO.\n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nEn 2017 se registró un caso de piratería terrestre en el municipio. En el primer semestre de 2018 no se han presentado casos.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nEn los últimos cuatro años, en promedio, se han registrado 2 casos de abigeato por año. En el primer semestre de 2018 no se presentaron casos.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caraterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos cuatro años, en todos los casos de abigeato el agresor se movilizaba a pie. En cuanto al tipo de arma, se tiene que en el 88% de los casos el agresor no utilizó armas.",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn los últimos tres años, el abigeato se ha presentado en los barrios/veredas: INSTITUTO, VDA. NUEVO ORIENTE, y CGTO. NUEVA ANTIOQUIA. Cada barrio/vereda registró un caso. \n\n",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años se registró un caso de secuestro en el municipio (en el 2016).",
      codigoMunicipio: "5ba0120eb873c2001384961a",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de víctimas de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza; se registra el mismo número de víctimas que en el primer semestre de 2017 (6 víctimas).\nPor su parte, la tasa de homicidios por cada 10.000 habitantes en el 2017 fue de 3,1  y estuvo 0,2 puntos por encima de la tasa de Antoquia y 0,7 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años, los homicidios se han concentrado en los hombres (92%). \nEn el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos (60%), seguidos por jóvenes (33%). En el 2017 se registra un aumento en la proporción de niños, niñas y adolescentes que son víctimas de este delito.\nEn el primer semestre de 2018, el delito se concentró en jóvenes (50%). \n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de la semana y franja horaria en que se presenta:\nEn los últimos cuatro años la mayor parte de los homicidios se presentaron los lunes (33%). En el primer semestre de 2018 el delito se concentró en los domingos, seguidos por los lunes.\nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró en la franja de madrugada (50%). En contraste, en el 2017, el delito se concentró en la franja de la tarde (50%). \nAhora bien, en el primer semestre de 2018 el mayor número de casos se presentó el domingo en la madrugada.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caraterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. Así mismo, la proporción de casos en los que el agresor se movilizaba en motocicleta ha venido disminuyendo.\nEn el primer semestre de 2018, se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cuatro años, el 78% de los homicidios se han cometido con arma de fuego. El segundo tipo de arma más utilizada es el arma blanca.\nEn el primer semestre de 2018 en el 67% de los casos el agresor utilizó un arma de fuego. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nEn los últimos cuatro años los homicidios se han venido concentrando en la zona rural del municipio. Sin embargo, desde 2015 la proporción de casos que se presentan en la zona urbana ha venido aumentando.\nEn cuanto a la ubicación, en el 2017 los cinco barrios/verdas con el mayor número de casos fueron: VDA. SANTA CLARA, VDA. LA CABAÑA, LOS NARANJOS, JOSÉ DOMINGO OLIVEROS, y CENTRAL; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 la VDA. PUERTO LOS MANGOS presenta el mayor número de casos (33%).",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nEn los últimos cinco años se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 14 víctimas en el primer semestre de 2017 a 12 en el 2018 (-14%).\nPor su parte, en 2017 la tasa de lesiones personales por cada 10.000 habitantes fue de 11,9  y estuvo 9,3 puntos por debajo de la tasa de Antoquia y  12,9 puntos por debajo de la tasa del país.\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se registran con mayor incidencia en hombres (60%). \nEn el primer semestre de 2018, aumentó la proporción de hombres víctimas de este delito.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha presentado en mayor proporción en adultos (46%), seguidos por jóvenes (37%).  \nEn el primer semestre de 2018, se mantiene este comportamiento, y aumenta la proporción de niños, niñas y adolescentes que son víctimas.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron  los lunes (30%). En el primer semestre de 2018 el delito se concentró en los domingos (50%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana (59%). En 2017, el delito se presentó en mayor proporción durante la madrugada (43%).\nAhora bien, en el primer semestre de 2018 los domingos en la madrugada y en la mañana, registran el mayor número de casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 63% de los casos el agresor utilizó objetos contundentes. El segundo tipo de arma más utilizada es el arma blanca (14%).\nEn el primer semestre de 2018 se mantuvo este comportamiento pero, aumentó la proporción de casos en los que no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas  por zona, clase de sitio y barrio en el municipio:\nDurante los últimos cinco años este delito se ha venido concentrando en la zona urbana y en la vía pública del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. PUERTO TOMAS, CENTRAL, LOS NARANJOS, 23 DE JULIO, y LA ESPAÑOLA; estos barrios concentran el 87% de los casos de lesiones personales.  En el primer semestre de 2018 el barrio CENTRAL ha presentado el mayor número de casos (56% de los casos).",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018 el número de víctimas disminuyó con respecto al comparativo semestral del 2017; pasó de 9 víctimas a 7. \nPor su parte, en 2017 la tasa de delitos sexuales por cada 10.000 habitantes fue de 7,2. Esta tasa estuvo 5,5 puntos por encima de la tasa de Antoquia, y 1 punto por encima de la tasa del país. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en las mujeres (93%).\nEn el primer semestre de 2018, se mantiene este comportamiento. \n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, en más del 40% de los casos no se reportó la edad de la víctima. En el 36% de los casos las víctimas fueron niños, niñas y adolescentes. El segundo grupo de edad más efectado son los jóvenes (20%).\nEn el primer semestre de 2018, aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito (80%).",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes (29%). En el primer semestre de 2018, los domingos registran el mayor número de casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la madrugada (60%). En contraste, en el 2017 el delito se presentó en mayor proporción durante la mañana (43%).\nAhora bien, en el primer semestre de 2018 el domingo en la  madrugada presenta el mayor el mayor número de casos.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, todas las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 92% de los casos de delitos sexuales no se emplearon armas. El tipo de arma más empleada son los objetos contundentes.  \nEn el primer semestre de 2018 en el 80% de los casos no se reportó el tipo de arma empleada por el agresor.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nEn los últimos cinco años los delitos sexuales se han concentrado en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. PUERTO TOMAS, VDA. PUERTO LOS MANGOS, EL PRADO, VDA. BOCAS DEL DON JUAN, y EL PARAISO; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 el mayor número de casos se registró en la VDA. PUERTO TOMAS (80% de los casos).",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nEn los últimos cinco años se registra una tendencia creciente en el número de víctimas de violencia intrafamiliar en el municipio. Sin embargo, en 2017 se revierte esta tendencia. \nEn el primer semestre de 2018, el número de víctimas disminuyó con respecto al comparativo semestral de 2017, pasando de 11 víctimas a 7 (-37%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes en 2017 fue de 11,4 y estuvo 3,8 puntos por debajo de la tasa de Antioquia y 7,4 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha registrado con mayor incidencia en mujeres (87%). En el primer semestre de 2018 el delito se sigue concentrando en mujeres. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nDurante los últimos cinco años, la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes (60%), seguidos por adultos (30%).\nEn el primer semestre de 2018, el delito se sigue concentrando en jóvenes. Sin embargo, aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes (32%), seguidos por los sábados (27%). En el primer semestre de 2018 la violencia intrafamiliar se siguió concentrando en los martes y sabádos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se presentó en mayor proporción durante la mañana (43%), y la tarde (43%). En contraste, en el 2017 la mayoría de los casos se presentaron en la mañana (41% de los casos).\nAhora bien, en el primer semestre de 2018 el mayor número de casos se presentó el martes en la mañana.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años en el 55% de los casos de violencia intrafamiliar se han empleado objetos contundentes. Sin embargo, la proporción de casos en los que no se utilizaron armas también es significativa (34%).  \nEn el primer semestre de 2018, en la mayoría de los casos el agresor utilizó objetos contudentes (57%). Así mismo, aumentó la proporción de casos en los que no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana del municipio, y se ha presentado en su mayoría en las vías públicas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. PUERTO TOMAS, EL PRADO, BRISAS DEL ORIENTE, CENTRAL, y 23 DE JULIO; estos barrios concentran el 64% de los casos de violencia intrafamiliar. En el primer semestre de 2018 la VDA. PUERTO TOMAS ha registrado el mayor número de casos; concentra el 29% de los casos.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 22 víctimas en el primer semestre de 2017 a 17 en el 2018 (-22%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 22,2 y estuvo 17,1 puntos por debajo de la tasa de Antioquia y 15,5 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado en los hombres (73%). En el primer semestre de 2018 aumenta la proporción de mujeres víctimas.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (75%). Desde el 2016 la proporción de jóvenes y adultos mayores que son víctimas ha venido aumentando.\nEn el primer semestre de 2018, el hurto a personas se concentró en adultos con el 65% de los casos. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (26%). En el primer semestre de 2018, el delito se presentó en mayor proporción durante los sábados y jueves. \nCon respecto a la franja horaria, en el primer semestre de 2018 el mayor número de casos se presentó durante la noche (35%). En contraste, en el 2017, el delito se presentó en mayor proporción durante la mañana (33%).\nAhora bien, en el primer semestre de 2018, el jueves en la tarde presenta el mayor número de casos de casos.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie. \nEn el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 50% de los casos de hurto a personas no se han empleado armas. El tipo de arma más utilizada es el arma de fuego (42% de los casos). \nEn el primer semestre de 2018 en el 47% de los casos el agresor utilizó un arma de fuego. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha concentrado en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 el mayor número de casos se presentó en la zona rural. \nEn el primer semestre de 2018 el delito se presentó en mayor proporción en la zona urbana.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. PUERTO TOMAS, CENTRAL, VDA. X-10, VDA. LA CONDOR, y CGTO. SAN MIGUEL DEL TIGRE; estos barrios concentran el 58% de los casos de hurto a personas que se presentaron.  En el primer semestre de 2018 el barrio/vereda que ha presentado el mayor número de casos es la VDA. PUERTO TOMAS; concentra el 44% de los casos. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDesde el 2016, en promedio, se viene presentando un caso de hurto a automotores en el municipio.  En el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos dos años el 100% de las víctimas han sido hombres.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos dos años se presentaron dos casos en los que la víctima era un adulto, y un caso en el que la víctima era un adulto mayor.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn 2017 se presentó un caso el jueves en la noche. En el primer semestre de 2018 no se han registrado casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn en el 2017 se presentó un caso en el que el agresor se movilizaba a pie y utilizó una llave maestra.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:.\nEn el 2017 se presentó un caso en la zona rural. Se presentó en la VDA. EL DIQUE.\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de un caso en el primer semestre de 2017 a siete casos en el 2018.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado principalmente en los hombres (86%). En el primer semestre de 2018, se mantiene este comportamiento. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nDurante los últimos cinco años, la mayor parte de las víctimas de hurto a motocicletas han sido adultos (55%), seguidos por los jóvenes (45%). En el primer semestre de 2018 aumenta la proporción de adultos víctima. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 se presentó un caso el lunes, viernes, sábado y domingo. En el primer semestre de 2018 el delito se presentó en mayor proporción los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró en la madrugada (57% de los casos). En el 2017, el delito también se concentró en la madrugada.\n\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cuatro años, en la mayoría de los casos el agresor se ha movilizado a pie. El segundo medio más útilizado por los agresores es la motocicleta. En el primer semestre de 2018 aumentó la proporción de casos en los que el agresor se movilizaba en motocicleta. \nEn cuanto al tipo de arma empleada se observa que en los últimos cuatro años, el arma más utilizada ha sido la llave maestra (50%). En el primer semestre de 2018 aumentó la proporción de casos en los que el agresor no utilizó armas. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nEn el 2017, el hurto a motocicletas se ha registró con mayor incidencia en la zona rural del municipio. En el primer semestre de 2018 el delito se concentró en la zona urbana.\nEn cuanto a la ubicación, en 2019 los tres barrios/veredas que más han presentando casos son: CGTO. SAN MIGUEL DEL TIGRE, CENTRAL, y VDA. LA ROMPIDA 1; estos barrios concentran el 100% de los casos. En el primer semestre de 2018 los barrios/veredas: VDA. PUERTO TOMAS, CENTRAL, VDA. LA CONDOR, VDA. CAÑO BLANCO, y EL PRADO concentraron todos los casos de hurto a motocicletas.\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últmos cinco años se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. \nEn el primer semestre de 2018 se mantiene esta tendencia, pasando de 9 casos en el primer semestre de 2017 a 11 casos en el 2018.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentó los viernes (27%). En el primer semestre de 2018 el mayor número de casos se registró los miércoles, seguidos por los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada (55%). En 2017 el delito también se concentró en la franja de la madrugada (47%).\nAhora bien, en el primer semestre de 2018 el sábado en la madrugada registró el mayor número de casos.\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 45% de los casos de hurto a comercio no se han utilizado armas. El tipo de arma más utilizada son los objetos contudentes (17%). En el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó armas de fuego. \n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. LA CONDOR, VDA. X-10, CGTO. SAN MIGUEL DEL TIGRE, EL PROGRESO, y VDA. PUERTO TOMAS; estos barrios concentran el 73% de los casos de hurto a comercio. En el primer semestre de 2018 la VDA. PUERTO TOMAS presentó el mayor número de casos (45%).\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años se no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDurante los últimos tres años se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio.  En el primer semestre de 2018 se evidencia que esta tendencia se mantiene, pasando 7 casos en el primer semestre de 2017 a 15 casos en el 2018.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los martes y viernes (cada día concentró el 27% de los casos). En el primer semestre de 2018 el delito se presentó en mayor proporción los lunes, sábados y domingos. \nCon respecto a la franja horaria, en el primer semestre de 2018 el delito se presentó en mayor proporción en las madrugadas y en las noches. En 2017, el delito se concentró en las madrugadas.\nAhora bien, en el primer semestre de 2018 el domingo en la madrugada presenta el mayor número de casos. \n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos tres años, en el 40% de los casos de hurto a residencias no se emplearon armas.\nEn el primer semestre de 2018 aumentó la proporción de casos en los que se utilizaron palancas.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: 23 DE JULIO, VDA. LA CONDOR, VDA. EL TOTUMO, VDA. EL DIQUE, y EL PROGRESO; estos barrios concentran el 55% de los casos. En el primer semestre de 2018 la VDA. PUERTO TOMAS registró el mayor número de casos (40%).\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de amenazas en el municipio. En el primer semestre de 2018 se presentaron 10 víctimas; este número es inferior al comparativo semestral de 2017 en el que se presentaron 16 víctimas.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el 63% de las víctimas de amenazas han sido hombres. En el primer semestre de 2018 aumentó la proporción de mujeres víctimas.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nDesde el 2017 la proporción de adultos víctimas ha venido aumentando. En el primer semestre de 2018, el 70% de las víctimas fueron adultos. El segundo grupo de edad más afectado han sido los jóvenes.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 las amenazas se presentaron en mayor proporción durante los jueves y sábados (cada día concentra el 22% de los casos). En el primer semestre de 2018 se presentaron más casos los domingos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la madrugada (50%). En 2017 el delito también se presentó en mayor proporción durante la madrugada.\nAhora bien, en el primer semestre de 2018 el domingo en la madrugada registró el mayor número de casos. \n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el 2017 los cinco barrio/veredas con el mayor número de casos fueron: VDA. PUERTO TOMAS, CENTRAL, LA ESPAÑOLA, EL PARAISO, y EL PRADO; estos barrios concentran el 100% de los casos. En el primer semestre de 2018, la VDA. PUERTO TOMAS registró el mayor número de casos (50%).",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nEn los últimos tres años, en promedio, se han registrado 3 casos de extorsión por año. En el primer semestre de 2018 no se registraron casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos tres años, la extorsión se ha concentrado en los hombres (73% de los casos). En el primer semestre no se registraron casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de extorsión han sido adultos. Sin embargo, la proporción de adultos mayores que son víctimas también es bastante significativa.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nEn los últimos tres años, la mayor parte de los casos de extorsión se presentó los miércoles en la noche.\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn los últimos tres años en el 54% el agresor extorsionó de forma directa. El segundo medio más utilizado fueron las llamadas telefónicas. ",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, en 2019 los casos de extorsión se concentraron en tres barrios/veredas: VDA. KM CINCO, VDA. LA RINCONADA, y CENTRAL. En el primer semestre de 2018 no se han registrado casos.\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nEn 2017 se registraron dos casos de piratería terrestre en el municipio. En el primer semestre de 2018 no se han presentado casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDesde 2015 el número de casos de abigeato ha venido disminuyendo. En el primer semestre de 2018 se mantiene este comportamiento, pasando de 2 casos en el primer semestre de 2017 a 1 caso en el 2018.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Caraterización del abigeato según características de movilidad del agresor y tipo de arma empleada:\nEn los últimos tres años, en la mayor parte de los casos de abigeato el agresor se movilizaba a pie. En cuanto al tipo de arma, se tiene que en ninguno de los casos el agresor útilizó armas.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del abigeato por ubicación:\nEn 2017 el abigeato se concentró en los barrios/veredas: VDA. SARDINATA y VDA. CAÑO BLANCO. En el primer semestre de 2018 se presentó un caso en la VDA. SAN LUIS BELTRAN.\n\n\n",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del secuestro en el municipio:\nEn los últimos cinco años no se han registrado casos de secuestro en el municipio. En el primer semestre de 2018 no se presentaron casos.",
      codigoMunicipio: "5ba011d9b873c20013849614",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 29 víctimas en el primer semestre de 2017 a 17 victimas en el 2018 (-41.4%).\nPor su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución. Para 2017 la tasa del municipio fue de 3.1 por CDH,  y estuvo por debajo en 0.6 puntos de la tasa de CAQUETÁ y por encima en 0.7 puntos de la tasa del país.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 90% en promedio. En 2013 y 2015 se presenta el mayor número de casos de mujeres víctimas de este delito (9 casos). En el primer semestre de 2018, disminuyó en 20 % los casos de victimas mujeres,  frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 5 casos en 2015 a 4 casos en el 2018.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de Homicidio han sido adultos, con el 60% de los casos; se observan tendencias crecientes de este delito en  niños, niñas y adolescentes. En el primer semestre de 2018, el homicidio se concentró en Adultos, con el 65% de los casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos con el 20% de los casos. En el primer semestre de 2018 el delito se concentró en los domingos, con 5  casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 47 % de los eventos. En el 2017, el delito se presentó en mayor proporción durante la noche (41%). Ahora bien, en lo corrido del año, el domingo en la madrugada ha presentado el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en Motocicleta. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma más empleado, con el 53% de los casos, aumentando 11 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CIUDADELA HABITACIONAL SIGLO XXI, VIA MORELIA, LOS ANDES, LA AMAZONIA, ANGEL RICARDO ACOSTA; estos barrios concentran el 29 % de los casos de 2017 y el 12% de los casos del 2018.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 313 víctimas en el primer semestre de 2017 a 219 victimas en el 2018 (-30%). Por su parte, la tasa de homicidios por cada 10.000 habitantes ha venido disminuyendo. En 2017 la tasa del municipio fue de 34.9 por CDH,  y estuvo por encima en 14.9 puntos de la tasa de Caquetá y por encima en 10.1 puntos de la tasa del país.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los años (2013 - 2017), este delito se ha concentrado en los hombres, con el 60% en promedio. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (265 casos). En el primer semestre de 2018, disminuyó en 22 % los casos de victimas mujeres, pasando de 123 casos en 2017 a 96 casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el 45% de los casos. En el primer semestre de 2018, las lesiones personales se siguen concentrando en adultos, con el 51 % de los casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos (18%). En el primer semestre de 2018 el mayor número de casos se presentó los Jueves (40 casos). Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana con el 27 % de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada (32%).  En el primer semestre de 2018, los lunes en la mañana presentaron el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 72 % de las lesiones personales se ha cometido objetos contundentes.  En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 74% de los casos, disminuyendo 63 casos frente al mismo período del año anterior.                    ",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio, en el primer semestre de 2018 esta por debajo del promedio  (2014 - 2017).En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, JUAN XXIII, CIUDADELA HABITACIONAL SIGLO XXI, EL RAICERO, VIA MORELI; estos barrios concentran el 28% de los casos de lesiones personales de 2017 y el 28% de los casos del 2018.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 108 víctimas en el primer semestre de 2017 a 55 victimas en el 2018 (-49.1%). \nPor su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución. Para 2017 la tasa del municipio fue de 10.9 por CDH,  y estuvo por encima en 3.1 puntos de la tasa de Caquetá y por encima en 4.7 puntos de la tasa del país.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres (el 88 % de las víctimas). En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (178 casos). En el primer semestre de 2018, el delito se sigue concentrando en mujeres.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, niñas y adolescentes (74 % de los casos). Se observan tendencias crecientes de víctimas jóvenes. En el primer semestre de 2018, el delito se sigue concentrando en niños, niñas y adolescentes.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos (21%). En el primer semestre de 2018 se concentró en los viernes y sábados. Con respecto a la franja horaria, en el primer semestre de 2018 el delito se concentró principalmente en la franja de la tarde (35% de los casos). En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada (56% de los casos).\nAhora bien, en lo corrido del año, el viernes en la tarde se presentó el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017) la mayoría de las víctimas de delitos sexuales se movilizaban a pie. En el primer semestre de 2018 se mantiene este comportamiento. En cuanto al tipo de arma se observa que en los últimos cuatro años, en el 67% de los casos el agresor no utilizó armas. En el primer semestre de 2018 el arma más utilizada fueron los objetos contundentes.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. Así mismo, se ha presentado en mayor proporción en casas/apartamentos. En el primer semestre de 2018 se mantiene este comportamiento. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CIUDADELA HABITACIONAL SIGLO XXI, SAN JUDAS, VDA. VILLA DEL RIO, JUAN XXIII, LA GLORIA; estos barrios concentran el 26 % de los casos. En el primer semestre de 2018, los cinco barrios/veredas con el mayor número de casos fueron:LA GLORIA, CIUDADELA HABITACIONAL SIGLO XXI, BRISAS ALTAS, LOS ANDES, y SAN JUDAS; concentran el 25% de los casos.\n",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco año, se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 163 víctimas en el primer semestre de 2017 a 173 victimas en el 2018 (6.1%), superando el promedio de los últimos cuatro años. \nPor su parte, en 2017 la tasa de violencia intrafamiliar fue de 17.2 por CDH,  y estuvo por encima en 6.7 puntos de la tasa de CAQUETÁ y  por debajo 1.6 puntos de la tasa del país.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años, este delito se ha concentrado en las mujeres (87%). En 2015 se presentó el mayor número de casos de mujeres víctimas (432 casos). En el primer semestre de 2018, la proporción de hombres víctimas fue del 19%; esta proporción es superior al promedio de los últimos cuatros años. ",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos (50% de los casos). El segundo grupo de edad más afectado son los jóvenes (40%). \nEn el primer semestre de 2018, la  violencia intrafamiliar se concentró en los jóvenes, con el 46 % de los casos.\t\t\t\t\t\t\t\t Así mismo, aumentó la proporción de niños, niñas y adolescentes que son víctimas de este delito.\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los  lunes y domingos (33%).  En el primer semestre de 2018 se concentró en los martes con 37 casos.\t\t\t\t\t\t\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde y noche (en conjunto concentran el 58% de los casos). En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada (29%).\t Ahora bien, en lo corrido del año, los martes en la tarde han presentado el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68 % de la violencia intrafamiliar se comente con objetos contundentes.En el primer semestre de 2018 se presenta el uso de objetos contundentes como el medio más empleado, con el 71% de los casos, aumentando 11 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. Así mismo, el mayor número de casos se ha presentado en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CIUDADELA HABITACIONAL SIGLO XXI, ABBAS TURBAY, NUEVA COLOMBIA, VDA. VILLA DEL RIO, EL RAICERO; estos barrrios concentran el 21% de los casos de 2017 y el 8% de los casos del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 487 víctimas en el primer semestre de 2017 a 419 victimas en el 2018 (-14%).\nPor su parte, la tasa de hurtos por cada 10.000 habitantes para 2017 fue de 53.6 por CDH,  y estuvo por encima en 31.4 puntos de la tasa de CAQUETÁ y por encima en 15.9 puntos de la tasa del país\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 53 % en promedio. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (512 casos).\t\t\t\t\t\nEn el primer semestre de 2018, disminuyó la proporción de mujeres víctimas.\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos, con el 57% de los casos. El segundo grupo de edad más afectado han sido los jóvenes.\nEn el primer semestre de 2018, el hurto a personas se concentró en adultos, con el 56 % de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los miércoles (17%). En el primer semestre de 2018 el delito se concentró en los sábados.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana con el 35% de los eventos. En condordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana con el 29 % de los casos.\t\t\t\t\t\t\t\t\t\nAhora bien, en lo corrido del año, los jueves en la mañana han presentado el mayor número de casos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), en la mayoría de los casos el agresor se movilizaba a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se movilizaba en motocicleta.\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\nEn cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 52% de los casos de hurto a personas no se emplearon armas. \nEn el primer semestre de 2018 aumentó la proporcion de casos en los que el agresor no utilizó armas. ",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 se mantiene este comportamiento. \t\t\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, JUAN XXIII, LAS AVENIDAS, EL RAICERO, CIUDADELA HABITACIONAL SIGLO XX; estos barrios concentraron el 30 % del hurto a personas de 2017. En el primer semestre de 2018, el barrio EL CENTRO registró el mayor número de casos (15%).\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se  estabiliza, presentando el mismo número de casos que en el primer semestre de 2017 (1 caso)\t\t\t\t\t\t\t\t\t\t.\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres (83%). En 2013 y 2016 se presenta el mayor número de casos de mujeres afectadas por este delito (2 casos).\t\t\t\t\t\t\t\nEn el primer semestre de 2018, no se han presentado casos de mujeres víctimas.\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos, con el 94% de los casos; se observan tendencias crecientes de este delito en la población Jóven.\t\t\t\t\t\nEn el primer semestre de 2018, el hurto a automotores se concentró en los adultos (el 100% de los casos).\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los sábados y domingos. En el primer semestre de 2018 se presentó un caso el sábado. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró en la franja de la tarde. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada y la mañana. \t\n\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), en la mayoría de los casos el agresor se ha movilizado a pie. En el primer semestre del 2018 se mantiene este comportamiento.\t\t\nEn cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53% de los casos de hurto a automotores se comenten con arma de fuego.       \nEn el primer semestre de 2018 se presenta un caso pero no se describe el tipo de arma empleada.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "En cuanto a la ubicación, los barrios/veredas con el mayor número de casos fueron: Vía a Neiva en el 2017 (1 caso)  y vereda Villa del Río en el 2018 (1 caso)\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 111 víctimas en el primer semestre de 2017 a 89 victimas en el 2018 (-19.8%), superando el promedio del 2016. \t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 75% en promedio.  En 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (81 casos). \t\t\t\t\nEn el primer semestre de 2018, aumentó la proporción de casos de hombres víctimas.\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de los afectados por hurto a motocicletas han sido adultos (58% de los casos). \nEn el primer semestre de 2018, el hurto a motocicletas se concentró en jóvenes con el 46 % de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los miércoles (17%). En el primer semestre de 2018 se concentró en los martes con 17 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde y noche con el 63% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 31% de los casos.\t Ahora bien, en lo corrido del año, los dias martes en la jornada noche han registrado el mayor número de casos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), en la mayoría de los casos el agresor se movilizaba a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 42% de hurto a motocicletas se cometen con intimidación (sin empleo de armas). \tEn el primer semestre de 2018 se presenta el uso de llave maestra como el medio más empleado (con el 51% de los casos, aumentando 2 casos frente al mismo período del año anterior). \t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, EL RAICERO, LA CONSOLATA, CIUDADELA HABITACIONAL SIGLO XXI, NUEVA FLORENCIA; estos barrios concentran el 26% del hurto a motocicletas del 2017 y el 17% de los casos del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 95 casos en el primer semestre de 2017 a 82 casos en el 2018 (-13.7%).\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los miércoles y jueves con el 34% de los casos.  En el primer semestre de 2018 se concentró en los días viernes con 15 casos.\t\t\t\t\t\t\t\t\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana con el 44% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada con el 38% de los casos. Ahora bien, en lo corrido del año, los viernes y domingos en la jornada madrugada y mañana han presentado el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 58% de los casos no se emplearon armas. En el primer semestre de 2018 mantiene este comportamiento.\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, LA LIBERTAD, SIETE DE AGOSTO, NUEVA FLORENCIA, LAS AVENIDAS; estos barrios concentran el 46% de hurto a comercio de 2017 y  el 50% de los casos del 2018.\t\t\t\t\t\t\t\t\n \t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "No se reportan casos de hurto a entidaes financieras para los últimos cinco años.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de hurto a residencias en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene relativamente estable, pasando de 157 casos en el primer semestre de 2017 a 153 en el 2018 (-2.5%).\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos con el 18%, en el primer semestre de 2018 se concentró en los lunes con 27 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró  principalmente en la franja de la madrugada con el 42% de los eventos. En condordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada con el  41% de los casos. Ahora bien, en lo corrido del año, los sábados en la jornada madrugada han presentado el mayor número de casos.",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 42% de hurto a residencias se comenten con objetos contundentes .  En el primer semestre de 2018 se presenta el uso de  palancas, como el medio más empleado, con el 37% de los casos, aumentando 23 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio, en el primer semestre de 2018 está por debajo del promedio  (2014 - 2017). En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: NUEVA COLOMBIA, CIUDADELA HABITACIONAL SIGLO XXI, LOS PINOS, JUAN XXIII, ABBAS TURBAY estos concentran el 18 % del hurto a residencias y el 5% de los casos del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 205 víctimas en el primer semestre de 2017 a 147 victimas en el 2018 (-28.3%). \t\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres (56%).  En 2017 se presenta el mayor número de casos de mujeres víctimas de este delito (199 casos). En el primer semestre de 2018, disminuyó en 27% los casos de victimas mujeres,  frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 110 casos en 2017 a 80 casos el 2018.\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos, con el 61% de los caso. \tEn el primer semestre de 2018, las amenazas se concentraron en la población de adultos, con el 61% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los  jueves, con el 17%. En el primer semestre de 2018 el delito también se concentró en los jueves con 29  casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana, con el 36% de los casos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada con el  39% de los casos. Ahora bien, en lo corrido del año, los lunes en la jornada de la mañana han presentado el mayor número de casos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 76%  de amenazas se comenten con intimidación (sin empleo de armas) . En el primer semestre de 2018  la intimidación es el medio más empleado por los agresores, con el 69% de los casos, aumentando 54 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio.  En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. VILLA DEL RIO, CIUDADELA HABITACIONAL SIGLO XXI, EL CENTRO, NUEVA COLOMBIA, TORASSO;  estos barrios concentran el 39% de los casos de 2017 y el 24% de los casos del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de extorsión en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 24 víctimas en el primer semestre de 2017 a 10 victimas en el 2018 (-58.3%). \n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 61% en promedio.  En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (28 casos). En el primer semestre de 2018, disminuyó en 75% los casos de victimas mujeres,  frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 16 casos en 2015 a 4 casos en el presente año.\t\t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos, con el 70% de los casos; se observan tendencias crecientes de este delito en jóvenes. En el primer semestre de 2018, los casos de extorsión se concentraron en adultos, con el 90% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias  martes con el 20%. En el primer semestre de 2018 se concentró en los  días sábado con 3 casos.Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana con el 70% de los eventos; En condordancia, con el año anterior, este delito se presentó en mayor proporción durante la mañana, con el 59% de los casos. Ahora bien, en lo corrido del año, los dias lunes, miércoles y sábado, en la jornada mañana se han presentado el mayor número de eventos de extorsión con el 30%  de estos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 75% de los casos de extorsión se comenten con llamada telefonica . En el primer semestre de 2018 se presenta el uso de  llamada telefónica como el medio más empleado, con el 70% de los casos, aumentando 11 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL CENTRO, CIUDADELA HABITACIONAL SIGLO XXI, JUAN XXIII, LAS AVENIDAS, KENEDY; estosbarrios concentran el 42% de los casos de extorsión. \t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de pirateria terrestre en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 7 casos en el primer semestre de 2017 a 0 casos en el 2018 (-100%).\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Abigeato en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 7 casos en el primer semestre de 2017 a 6 casos en el 2018 (-14.3%).\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), en la mayoría de los casos el agresor se ha movilizado a pie. \n\t En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 76% de los casos de abigeato se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 en el 50% de los casos no se utilizaron armas (aumentando 3 casos frente al mismo período del año anterior\t\t\t\t\t\t\t\t\t\t).\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VIA MORELIA, VDA. LA ARENOSA, VDA.  SANTANDER, JUAN XXIII, VDA. MACAGUAL; estos barrios concentran el 63% de los casos.\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de secuestro en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).\t\t\t\t\t\t\t\t\t\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba011ffb873c20013849618",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 4 víctimas en el primer semestre de 2017 a 15 víctimas en el 2018 (275%), \t\t\t\t\t\t\t\t\t\t\nPor su parte, la tasa de homicidios por cada 10.000 habitantes para 2017 fue de 2.1 por CDH,  y estuvo por debajo en 1.6 puntos de la tasa de CAQUETÁ y  por debajo 0.3 puntos de la tasa del país\t\t\t\t\t\t\t\t\t\t\t\t\t.\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 94%. En 2014 se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos).\t\t\t\nEn el primer semestre de 2018, el delito se sigue concentrando en hombres.\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de Homicidio han sido jóvenes con el 50% de los casos.  En el primer semestre de 2018, el homicidio se concentró en adultos, con el 73% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los jueves y domingos con el 80% de los casos. En el primer semestre de 2018 se concentro en los jueves con 6  casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana con el 53% de los casos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche. Ahora bien, en lo corrido del año, los dias jueves en la jornada de la mañana han presentado el mayor número de homicidios.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 83 % de los homicidios se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 67% de los casos, aumentando 7 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. \tEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. BAJO PALESTINA, VDA. ALTO RIO CHIQUITO, VDA. PALMA AZUL COCONUCO, INSPOLICIA LA UNION PENEYA, VDA. MATEGUADUA;  estos barrios concentran el 100%  de homicidios de 2017 y el 20% de los homicidios del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Lesiones Personales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se se mantiene, pasando de 7 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-42.9%). Por su parte, la tasa de homicidios por cada 10.000 habitantes para 2017 fue de 5 por CDH,  y estuvo por debajo en 15.1 puntos de la tasa de CAQUETÁ y  por debajo 19.8 puntos de la tasa del país\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres (79% de las víctimas). En 2017 se presenta el mayor número de casos de mujeres víctimas de este delito (7 casos). En el primer semestre de 2018, el delito se sigue concentrando en los hombres.\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido jóvenes, con el 51% de los casos; se observan tendencias crecientes de este delito en adultos mayores. En el primer semestre de 2018, las lesiones personales se concentraron en  los jóvenes con el 75% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los  domingos con el 42% de los casos.  En el primer semestre de 2018 se concentró en los días miércoles con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde, con el 50% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada y la mañana con el 67% de los casos. Ahora bien, en lo corrido del año, los dias miércoles en la jornada de la tarde han presentado el mayor número de casos.",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 37%  de las lesiones personales se cometieron con objetos contundentes. En 2018 se presenta el uso de objetos contundentes, como medio más empleado, con el 100% de los casos, disminuyendo 1 caso frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural y en las vías públicas del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CENTRAL DE MONTAÑITA, CENTRO, VDA. EL DIAMANTE, URBANIZACION SIMON BOLIVAR, VIA A FLORENCIA; estos barrios concentran el 83% de los casos de lesiones personales. En el primer semestre de 2018 el barrio GUILLERMO ESCOBAR ha presentado el mayor númer de casos.\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 8 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-62.5%), superando el promedio de los años  2016 y 2017.  Por su parte, la tasa de homicidios por cada 10.000 habitantes para 2017 fue de 4.2 por CDH, y estuvo por debajo en 3.6 puntos de la tasa de CAQUETÁ y  por debajo 2 puntos de la tasa del país\t\t\t\t\t\t\t\t\t\t\t\t\t.\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 97% en promedio. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (10 casos). En el primer semestre de 2018, disminuyó en 63% los casos de victimas mujeres,  frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 8 casos en 2017 a 3 casos en el presente año.\t\t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños con el 87% de los casos. Se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, los delitos sexuales se concentraron en niños con el 67% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los miércoles con el 30% de los casos. En el primer semestre de 2018 el delito se concentró en los lunes, miércoles y sábados. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada con el 67% de los eventos. En condordancia con el año anterior, el delito se presentó en mayor proporción durante la madrugada con el 70% de los casos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017) en la mayoría de los casos las víctimas se movilizaban a pie. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68% de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presentó la intimidación (sin empleo de armas), como el medio más empleado, con el 100% de los casos.\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural y en las vías públicas del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSPOLICIA LA UNION PENEYA, CENTRO, VDA. IGLESIAS BAJAS, VDA. LA GAITANA, VIA A FLORENCIA;  estos barrios concentran el 80 % de los delitos sexuales.\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 1 víctima en el primer semestre de 2017 a 9 víctimas en el 2018 (800%). Por su parte, la tasa de homicidios por cada 10.000 habitantes para el 2017 fue de 2.1 por CDH, y estuvo por debajo en 8.5 puntos de la tasa de CAQUETÁ y  por debajo 16.7 puntos de la tasa del país.\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 94% en promedio.  En 2015 Y 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (5 casos). En el primer semestre de 2018, se ha aumentado en 133% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017) , pasando de 3 casos en 2014 a 7 casos en el 2018.\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos, con el 66% % de los casos; se observan tendencias crecientes de este delito en jóvenes. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos, con el 44% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias  jueves con el 40%. \tCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 33% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada y noche con el 80% de los casos. \t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 47%  de los casos de violencia intrafamiliar se han cometido con objetos contundentes.  En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 100% de los casos, aumentando 8 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio y se ha presentado principalmente en casas/apartamentos. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: INSPOLICIA LA UNION PENEYA, VDA. LA FLORIDA, VIA A MILAN, VDA. LOS ANDES, VDA. AGUA LINDA. \t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se  mantiene, pasando de 2 víctimas en el primer semestre de 2017 a 2 victimas en el 2018.  Por su parte, la tasa de homicidios por cada 10.000 habitantes para 2017 fue de 2.9 por CDH, y estuvo por debajo en 19.3 puntos de la tasa de CAQUETÁ y por debajo 34.8 puntos de la tasa del país.\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 62% en promedio. En el primer semestre de 2018, se aumentó en 100% los casos de mujeres víctimas, frente al valor más alto de los últimos cuatro años (2014-2017). \n\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos, con el 81% de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, el hurto a personas se concentró en adultos y en adultos mayores con el 100% de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los jueves con el 57%.  En el primer semestre de 2018 se concentró en los viernes y domingos con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada con el  43% de los casos. Ahora bien, en lo corrido del año, los dias viernes y domingo en la jornada mañana se han presentado el mayor número de eventos de hurto a personas con el 100% de estos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie.  Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 92% de hurto a personas se comenten con arma de fuego e intimidación (sin empleo de armas) . En el primer semestre de 2018 se presentó el uso de arma de fuego y la intimidación (sin empleo de armas),  como el medio más empleado. \t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural y en la vía pública del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VDA. LOS ANDES, VDA. LOS MORROS, CENTRO, VDA. ALTO JORDAN, VDA. BERLIN; estos barrios concentran el 86 % de los casos del hurto a personas. \t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de hurto a automotores en el municipio. \t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 100 %. No se presentan casos de mujeres víctimas de este delito. \tEn el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.\t\t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos, con el 60% de los casos. En el primer semestre de 2018, no se presentaron casos.\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "No se presentaron casos de hurto a automotores en el primer semestre de 2018.",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "No se presentaron casos de hurto a automotores en el primer semestre de 2018.",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 3 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-66.7%).\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 3 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-66.7%).\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 83% en promedio. En 2015 se presentó el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victima.\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos, con el 61% de los casos. Se observan tendencias crecientes de este delito en niños, jóvenes, adultos y adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en jóvenes.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los martes, jueves y sábados, con el 86% de los casos. En el primer semestre de 2018 se presentó un caso el domingo. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana, con el 100 % de los casos. En condordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana con el  43% de los casos. Ahora bien, en lo corrido del año, los domingos en la jornada de la mañana han presentado el mayor número de casos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 55% del hurto a motocicletas se comenten con  arma de fuego.   En el primer semestre de 2018 se presenta el uso de llave maestra como el medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARCELAS SEMILLAS DE PAZ, VDA. EL TRIUNFO, VIA AL PAUJIL, LAS BRISAS, VDA. LA PATAGONIA; concentran el 86% de los casos.\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 1 víctima en el primer semestre de 2017 a 2 victimas en el 2018 (100%).\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias viernes y domingo con el 100%. En el primer semestre de 2018 se concentró en los días lunes y miércoles con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana. En contraste con el 2017 que se presentó mayoritariamente durante la madrugada y tarde. \nAhora bien, en lo corrido del año, los dias lunes y miércoles en la jornada de la mañana han presentado el mayor número de eventos.\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie.\t En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50% de hurto a comercio se comenten con  objetos contundentes. En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas),  como el medio más empleado, con el 100% de los casos.\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 y 2018 los barrios/veredas con el mayor número de casos fueron: VDA. AGUA BLANCA e INSPECCIÓN POLICIA UNIÓN PENEYA.\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion: "No se presentaron casos de hurto a entidades financieras. ",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 1 casos en el primer semestre de 2017 a 2 casos en el 2018 (100%). \t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos con el 100% de los casos. En el primer semestre de 2018 se concentró en los martes y viernes con 2  casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada y la mañana.  En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada. Ahora bien, en el primer semestre del 2018, los dias martes y viernes en la jornada de la  madrugada y la mañana presentan el mayor número de casos.",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 82% del hurto a residencias se ha cometido con objetos contundentes. En el primer semestre de 2018 se presenta el uso de palanca e  intimidación (sin empleo de armas)como el medio más empleado.\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 y 2018  los  barrios/veredas con el mayor número de casos fueron: VDA. ALTO PORVENIR QUEBRADON y VDA. ALTO ARENOSO. \t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se incrementa, pasando de 9 víctimas en el primer semestre de 2017 a 14 victimas en el 2018 (55.6%).\t\t\t\n\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 56% en promedio. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (11 casos). En el primer semestre de 2018, se ha aumentado en 17% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017) , pasando de 6 casos en 2017 a 7 casos en el presente año.\t\t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos, con el (42) % de los casos. En el primer semestre de 2018, las amenazas se concentraron en la población de adultos, con el 57%  de los casos.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves con el 26%, en el primer semestre de 2018 se concentró en los martes con 5  casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana con el 43% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana y la tarde, con el  63% de los casos. \n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 80%  de las amenazas se comenten con  intimidación (sin empleo de armas). En el primer semestre de 2018 se presentó la intimidación (sin empleo de armas),  como el medio más empleado, con el 57% de los casos.\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CENTRO, VDA. LOS ANDES, URBANIZACION SIMON BOLIVAR, INSPOLICIA LA UNION PENEYA, VDA. LA FLORIDA; estos barrios concentran el 74%  de amenazasde 2017 y el 43% de los casos del 2018.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de extorsión en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 0 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (200%). \n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en los hombres con el 59% en promedio. En 2013, se presentó el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito\t\t\t\t\t\t\n\t\t\t\t\t\t\n\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos, con el 80% de los casos. Se observan tendencias crecientes de este delito en jóvenes. En el primer semestre de 2018, los casos de extorsión se concentraron en adultos y adultos mayores.\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves y sábado, con el 100 %. En el primer semestre de 2018 se concentró en los jueves y domingos  con 2  casos. con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la madrugada y la mañana. En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el  100% de los casos.\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "\tEn cuanto al medio se observa que en los últimos cuatro años (2014 - 2017), el 42%  de los casos extorsión se comenten con llamada telefónica.  En el primer semestre de 2018 se presenta también el uso de la llamada telefónica como medio más empleado,  aumentando 2 casos frente al mismo período del año anterior.\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2018 el delito se presentó en INSPOLICIA LA UNION PENEYA.\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "En los últimos cuatro años, no se han registrado casos de piratería terrestre en el municipio.",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Abigeato en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 0 casos en el primer semestre de 2017 a 3  en el 2018 (300%).\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie. Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan  casos en los que el victimario se moviliza en motocicleta y vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 75%  se comenten con  intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta la intimidación (sin empleo de armas) como el medio más empleado.\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. \tEn cuanto a la ubicación, en 2018 se presentaron los casos en las siguientes  barrios/veredas: las Magaritas e Itarca.\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 1 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%). \n\t\t\t\t\t\t\t\t\t\t\t\t\t",
      codigoMunicipio: "5ba01218b873c2001384961b",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio.  \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL APORRIADO, 7 DE AGOSTO, LA PAZ, GALAN, y CENTENARIO; estos concentran el 45% porciento de los casos. En el primer semestre de 2018, el barrio/vereda EL APORRIADO ha registrado el mayor número de casos (77%).",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana del municipio. En cuanto al tipo se sitio, el delito se ha presentado en igual proporción en las vías públicas y en casas/apartamentos. Sin embargo, en el primer semestre de 2018 la mayor parte de los casos se presentó en las vías públicas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CHARRY, PALMICHAL, CALLE CALIENTE, EL MORRON, y LA TRAVESIA; estos concentran el 69% porciento de los casos. En el primer semestre de 2018, todos los casos se registraron en el barrio/vereda CHARRY.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 la proporción de casos que se presentaron en casas/apartamentos fue bastante alta (47%).\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA PLAYA, CARACOLI, PUEBLO NUEVO, VILLA URIBE, y CENTRO; estos barrios concentraron el 43% de los casos. En el primer semestre de 2018, los cinco barrios/veredas con el mayor número de casos fueron: LA PLAYA, VILLA ARABIA, EL TRIANGULO, PUEBLO NUEVO, y LA ESPERANZA; registraron el 80% de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 la proporción de casos que se presentaron en casas/apartamentos fue bastante alta (44%).\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS LLANITOS, PARQUE PRINCIPAL, ROBLALITO A, LA CALZADA, y LA FRANCIA; estos barrios concentraron el 47% de los casos. En el primer semestre de 2018, el barrio/vereda LOS LLANITOS registró el mayor número de casos (77%).",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2015 el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el primer semestre de 2018 la mayor parte de los casos se presentó en casas/apartamentos. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: OBRERO, UNION VICTORIA, BRISAS DEL AEROPUERTO, AVENIDA FERREA, y LA CIUDADELA; estos barrios concentraron el 70% de los casos. En el primer semestre de 2018, el barrio/vereda OBRERO registró el mayor número de casos (38%).",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, este delito se ha venido concentrando en la zona urbana del municipio. En el 2017 el mayor número de casos se registró en casas/apartamentos. En el primer semestre de 2018, aumentó la proporción de casos que se presentaron en fincas y en otros lugares. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA FLORESTA, PUERTO OSPINA, LA VIZCAYA, VALDIVIA, SEVILLA; estos concentran el 80% de los casos. En el primer semestre de 2018, el barrio/vereda PUERTO OSPINA registró el mayor número de casos (67%).",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, este delito se ha venido concentrando en la zona urbana del municipio. En el 2017 el mayor número de casos se registró en casas/apartamentos. En el primer semestre de 2018, todos los casos se presentaron en las vías públicas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUERTO ANTIOQUIA, PALMA BONITA, MESETAS, EDUARDO CORREA, y NUEVO MILENIO; estos concentran el 65% de los casos. En el primer semestre de 2018, el barrio/vereda PUERTO ANTIOQUIA registró el mayor número de casos (50%).",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 el mayor número de casos se presentó en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL APORRIADO, SAN BARTOLO, GALAN, 7 DE AGOSTO, y LA PAZ; estos barrios concentran el 45% de los casos. En el primer semestre de 2018, el barrio/vereda EL APORRIADO ha registrado el mayor número de casos (80%).",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 el mayor número de casos se presentó en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PALMICHAL, LAS AURAS, EL TURCO, CHARRY, y MORAVIA; estos barrios concentran el 56% de los casos. En el primer semestre de 2018, todos los casos se registraron en el barrio/vereda CHARRY.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 el mayor número de casos se presentó en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA PLAYA, EL CAMELLO, PUEBLO NUEVO, EL TRIANGULO, y CARACOLI; estos barrios concentraron el 62% de los casos. En el primer semestre de 2018, el barrio/vereda LA PLAYA registró el mayor número de casos (30%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 el mayor número de casos se registró en la zona rural y en fincas/casas. En el primer semestre de 2018 todos los casos se presentaron en la vía pública.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS LLANITOS, LA CALZADA, RIO VERDE DE LOS HENAO, LA LINDA, y SANTO DOMINGO; estos barrios concentraron el 44% de los casos. En el primer semestre de 2018, el barrio/vereda LOS LLANITOS registró el mayor número de casos (57%).",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el primer semestre de 2018 aumentó de forma significativa el número de casos que se registraron en la zona rural y en fincas/casas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: OBRERO, LA CIUDADELA, PORVENIR, CALLE DEL COMERCIO, y NUEVO HORIZONTE; estos barrios concentraron el 78% de los casos. En el primer semestre de 2018, el barrio/vereda OBRERO registró el mayor número de casos (24%).",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUERTO OSPINA, LA BOMBA, SECTOR LA VARIANTE, SANTA BARBARA, Y SANTA INES; estos barrios concentraron el 78% de los casos. En el primer semestre de 2018, el barrio/vereda PUERTO OSPINA registró todos los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el 2017 la proporción de casos que se presentó en casas/fincas fue bastante alta (47%).\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PAVAS, LA CABAÑA, PUERTO ANTIOQUIA, BARRO BLANCO, y SECTOR TENERIFE; estos barrios concentraron el 65% de los casos. En el primer semestre de 2018, el barrio/vereda PUERTO OSPINA registró todos los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el primer semestre de 2018 el mayor número de casos se presentó en la zona rural y en el lugar de trabajo de las víctimas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CENTENARIO, SEVILLA, EL TIGRITO, PORROS, y LAS DELICIAS; estos barrios concentraron el 37% de los casos. En el primer semestre de 2018, el barrio/vereda EL APORRIADO registró el mayor número de casos (43%).",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2017 el delito se ha venido concentrando en la zona rural y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cuatro barrios/veredas con el mayor número de casos fueron: LA QUIEBRA, CHARRY, ALTO DEL CHIRI, y CHORRILLOS; estos barrios concentraron el 100% de los casos. En el primer semestre de 2018, los barrios/veredas OREJON y CHARRY registraron todos los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA PLAYA, PUEBLO NUEVO, CENTRO, PARQUE PRINCIPAL, y LOMA FRESCA; estos barrios concentraron el 37% de los casos. En el primer semestre de 2018, el barrio/vereda LA PLAYA registró el mayor número de casos (37%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en 2017 aumentó la proporción de casos que se presentaron en la zona rural.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, CHAGUALITO, LA CALZADA, RIO ARRIBA, y BARRIO CENTRO; estos barrios concentraron el 49% de los casos. En el primer semestre de 2018, el barrio/vereda LOS LLANITOS registró el mayor número de casos (45%).",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CALLE DEL COMERCIO, CORREGIMIENTO LLORENTE, EL MORRO, AVENIDA LA PLAYA, y PARQUE COLON; estos barrios concentraron el 30% de los casos. En el primer semestre de 2018, el barrio/vereda EL MORRO registró el mayor número de casos (12%).",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona rural y en las vías públicas del municipio. En el primer semestre de 2018 la mayor parte de los casos se presentó en la zona urbana. Así mismo, aumentó la proporción de casos que se presentó en colegios/escuelas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, SEVILLA, PUERTO OSPINA, CRISTALINA, y VALDIVIA; estos barrios concentraron el 75% de los casos. En el primer semestre de 2018, el barrio/vereda PUERTO OSPINA registró el mayor número de casos (33%).",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2016, el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018, el 50% de los casos se registró en la vía pública, y el otro 50% en empresas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUERTO OSPINA, PUERTO ANTIOQUIA, LA AVENIDA, BUENOS AIRES, y EL DOCE; estos barrios concentraron el 87% de los casos. En el primer semestre de 2018, el barrio/vereda SAN NICOLÁS registró todos los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se han venido concentrando en la zona rural y en la vía pública del municipio.  \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: EL APORRIADO, LIBORIO BATALLER, CALLE REAL, PARQUE PRINCIPAL, y BATACLAN; estos concentran el 48% porciento de los casos. En el primer semestre de 2018, el barrio/vereda EL APORRIADO ha registrado el mayor número de casos (37%).",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2017 este delito se ha venido concentrando en la zona urbana del municipio. Así mismo, desde 2015 se registra una tendencia creciente en la proporción de casos que se presentan en la vía pública; no obstante, la proporción de casos que se presentan en Fincas y Similares también es bastante representativa.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: ALTO DEL CHIRI, CENTRAL, EL MORRON, PARQUE PRINCIPAL, y CALLE CALIENTE; estos barrios concentran el 54% de los casos. En el primer semestre de 2018, el barrio CHARRY registró el mayor número de casos (67%).",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, el delito se ha concentrado en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en la zona rural. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LA PLAYA, PUEBLO NUEVO, EL PAJONAL, VILLA ARABIA, y EL CAMELLO; estos barrios concentran el 33% de los casos. En el primer semestre de 2018 el barrio/vereda LA PLAYA registró el mayor número de casos (38%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, el delito se ha concentrado en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en la zona rural. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: LOS LLANITOS, PARQUE PRINCIPAL, BARRIO CENTRO, CALLE CALIENTE, y EL CEDRO; estos barrios concentran el 52% de los casos. En el primer semestre de 2018 los cinco barrios/veredas con el mayor número de casos fueron: BARRIO CENTRO, LOS LLANITOS, CALLE CALIENTE, CHAVERRAS, y JERUSALEN; concentran el 51% de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, el delito se ha concentrado en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CORREGIMIENTO LLORENTE, OBRERO, CALLE MOSQUERA, SAN JUDAS, y EXPORCOL; estos barrios concentran el 21% de los casos. En el primer semestre de 2018 los cinco barrios/veredas con el mayor número de casos fueron: MARIA AUXILIADORA, VEREDA EL CHAJAL, CORREGIMIENTO LLORENTE, ONCE DE NOVIEMBRE, y CORREGIMIENTO CHILVI; concentran el 21% de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos tres años, el delito se ha distribuido de forma similar en la zona urbana y rural del municipio. En el cuanto al tipo de sitio, la mayoría de los casos se han presentado en las vías públicas. No obstante, en el primer semestre de 2018 aumentó la proporción de casos que se presentaron en la zona rural y en fincas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PARQUE PRINCIPAL, PUERTO OSPINA, LA BOMBA, EL TEMPLETE, y BUENOS AIRES; estos barrios concentran el 78% de los casos. En el primer semestre de 2018 todos los casos se presentaron en los barrios/veredas: PUERTO OSPINA, EL CATORCE, y SECTOR EL CEMENTERIO.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, el delito se ha concentrado en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumentó la proporción de casos que se presentaron en bares o cantinas (12%); esta proporción es superior al promedio de los últimos cuatro años.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: PUERTO ANTIOQUIA, KILOMETRO 3 AL 10, LA AVENIDA, PUERTO OSPINA, y PIEDRAS; estos barrios concentran el 45% de los casos. En el primer semestre de 2018 el barrio/vereda PUERTO ANTIOQUIA registró el mayor número de casos (50%).",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 24 víctimas en el primer semestre de 2017 a 14 victimas en el 2018 (-41, 7%). Por su parte, la tasa de homicidios por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 10 por CDH, y estuvo por debajo en 5, 2 puntos de la tasa de ANTIOQUIA y por debajo 8, 8 puntos de la tasa del país.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 93% en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (38 casos). En el primer semestre de 2018, disminuyó en 50% los casos de víctimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 24 casos en 2017 a 12 casos en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos con el 47% de los casos. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 57 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los domingos con el 32 porciento. En el primer semestre de 2018 el delito se concentró en los domingos con 5 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana, con el 64% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 34% de los casos. Ahora bien, en lo corrido del año, los jueves y domingos en la mañana y noche  han presentado el mayor número de casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 86% de los casos de violencia intrafamiliar se han utilizado objetos contundentes. ",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 12 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-75%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 23 por CDH, y estuvo por encima en 7, 9 puntos de la tasa de ANTIOQUIA y por encima en 4, 2 puntos de la tasa del país.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 94% de los casos. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (18 casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes con el (39) porciento de los casos. En el primer semestre de 2018, la violencia intrafamiliar se concentró en Jóvenes, con el 67% de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los lunes, con el 26% de los casos. En el primer semestre de 2018 se concentró en los martes, sábados, y domingos con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 67 por ciento de los eventos. En condordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 53 por ciento de los casos. Ahora bien, en lo corrido del año, los días martes en la jornada de la mañana han presentado el mayor número de casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 47 porciento de los casos de violencia intrafamiliar se han cometido con intimidación (sin empleo de armas). En el primer semestre de 2018 en ninguno de los casos se reportó el tipo de arma empleada.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 81 víctima en el primer semestre de 2017 a 34 victimas en el 2018 (-58%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 10, 5 por CDH, y estuvo por debajo en 4, 7 puntos de la tasa de ANTIOQUIA y por debajo 8, 3 puntos de la tasa del país.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 87% de los casos. En 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (144 casos). En el primer semestre de 2018, aumentó en 55% los casos de mujeres víctimas.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos con el 55% de los casos. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 65% de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los sábados con el 25% de los casos. En el primer semestre de 2018 se concentró en los sábados con 11 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la tarde/noche con el 67% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 32% de los casos. Ahora bien, en lo corrido del año, los domingos en la jornada tarde, han presentado el mayor número de casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 67% de los casos de violencia intrafamiliar se utilizaron a objetos contundentes. En el primer semestre de 2018, en el 59% de los casos no se reportó el tipo de arma empleada.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 16 víctimas en el primer semestre de 2017 a 13 victimas en el 2018 (-18, 8%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 8, 7 por CDH, y estuvo por debajo en 6, 5 puntos de la tasa de ANTIOQUIA y por debajo 10, 1 punto de la tasa del país.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres con el 96% de los casos. En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (28 casos). En el primer semestre de 2018, disminuyó en 13 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 15 casos en 2017 a 13 casos en el presente año.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos con el 53% de los casos. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 85% de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los martes y domingos con el 47% de los casos. En el primer semestre de 2018 se concentró en los lunes con 5 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 85 por ciento de los eventos. En condordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 73 por ciento de los casos. Ahora bien, en lo corrido del año, los lunes en la madrugada  han presentado el mayor número de casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 64% de los casos de violencia intrafamiliar se utilizaron objetos contundentes. En el primer semestre de 2018 en ningún caso se reportó el tipo de arma empleada.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene, pasando de 89 víctimas en el primer semestre de 2017 a 40 victimas en el 2018 (-55, 1%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes para 2017 fue de 7,7 por CDH, y estuvo por debajo en 6,3 puntos de la tasa de NARIÑO y por debajo 11,1 puntos de la tasa del país.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres (87%). En el 2014, se presenta el mayor número de casos de mujeres víctimas de este delito (169 casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos (46%); se observan tendencias crecientes de este delito en jóvenes. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los lunes (20%). En el primer semestre de 2018  el delito se concentró en los martes y miércoles, con 18 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana, con el 38 por ciento de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 70 por ciento de los casos. Ahora bien, en lo corrido del año, los  domingos en la jornada de noche han presentado el mayor número de casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 55% de los casos de violencia intrafamiliar se utilizaron objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 55% de los casos, aumentando 5 casos frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 7 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-57, 1%). Por su parte, la tasa de homicidios por cada 10.000 habitantes para 2017 fue de 4,3 por CDH, y estuvo por debajo en 10,8 puntos de la tasa de ANTIOQUIA y por debajo 14,5 puntos de la tasa del país.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres (88%). En 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (9 casos). En el primer semestre de 2018, aumentó en 50% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2017 a 3 casos en el presente año.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos con el (39) porciento de los casos. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 60 porciento, en el primer semestre de 2018 se concentro en los viernes, sábado, domingo con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, tarde/noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 70% de los casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de violencia intrafamiliar con el 4 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 76 porciento de violencia intrafamiliar se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 67% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 16 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-75%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 4, 7 por CDH, y estuvo por debajo en 10, 5 puntos de la tasa de ANTIOQUIA y por debajo 14, 1 punto de la tasa del país.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 90 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (26 casos). En el primer semestre de 2018, se ha disminuido en 20 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 5 casos en 2016, a 4 casos en el presente año.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido jóvenes con el (37) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, el violencia intrafamiliar se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 35 porciento, en el primer semestre de 2018 se concentro en los martes, jueves, viernes, domingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 50% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 40% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada mañana, se han presentado el mayor número de eventos de violencia intrafamiliar con el 1 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60 porciento de violencia intrafamiliar se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 75% de los casos, aumentando 3 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 14 víctimas en el primer semestre de 2017 a 20 victimas en el 2018 (42, 9%), superando el promedio de los últimos cinco años en el (los) año(s) de 2013, 2015, 2016, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 9, 2 por CDH, y estuvo por encima en 6, 3 puntos de la tasa de ANTIOQUIA y por encima en 6, 8 puntos de la tasa del país.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 89 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (9 casoss). En el primer semestre de 2018, se ha disminuido en 67 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 3 caso en 2015, a 1 caso en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido jóvenes con el (56) porciento de los casos; se observan tendencias crecientes de este delito en niños. En el primer semestre de 2018, el homicidio se concentró en jóvenes, con el 60 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 21 porciento, en el primer semestre de 2018 se concentro en los domingos con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 35% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 37% de los casos. Ahora bien, en lo corrido del año, los dias, jueves, domingo en la jornada madrugada, tarde/noche se han presentado el mayor número de eventos de homicidio con el 20 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 65 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 67% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 6 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-33, 3%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 23 por CDH, y estuvo por encima en 20, 1 punto de la tasa de ANTIOQUIA y por encima en 20, 6 puntos de la tasa del país.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 93 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; se observan tendencias crecientes de este delito en niños. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, con el 35 porciento, en el primer semestre de 2018 se concentro en los sábados con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 50% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias jueves, en la jornada tarde, se han presentado el mayor número de eventos de homicidio con el 1 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 83 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 100% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 27 víctimas en el primer semestre de 2017 a 88 victimas en el 2018 (225, 9%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 4, 6 por CDH, y estuvo por encima en 1, 7 puntos de la tasa de ANTIOQUIA y por encima en 2, 2 puntos de la tasa del país.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 92 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (7 casos). En el primer semestre de 2018, se ha disminuido en 72 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 36 caso en 2014, a 10 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 21 porciento, en el primer semestre de 2018 se concentro en los Jueves con 18 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 38% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 30% de los casos. Ahora bien, en lo corrido del año, los dias, miércoles, Sábado, en la jornada, noche se han presentado el mayor número de eventos de homicidio con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 90 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 89% de los casos, aumentando 54 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 3 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-33, 3%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2 por CDH, y estuvo por debajo en 0, 9 puntos de la tasa de ANTIOQUIA y por debajo 0, 4 puntos de la tasa del país.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 91 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos).",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido jóvenes con el (57) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, el homicidio se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias martes, domingo con el 57 porciento, en el primer semestre de 2018 se concentro en los MartesMiércoles con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 43% de los casos. Ahora bien, en lo corrido del año, los dias, martes, miércoles, en la jornada madrugada, mañana, se han presentado el mayor número de eventos de homicidio con el 100 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 80 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 91 víctima en el primer semestre de 2017 a 144 victimas en el 2018 (58, 2%) Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 10, 1 por CDH, y estuvo por encima en 7, 1 punto de la tasa de NARIÑO y por encima en 7, 6 puntos de la tasa del país.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 94 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (13 casos). En el primer semestre de 2018, se ha disminuido en 88 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 114 caso en 2014, a 14 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 27 porciento, en el primer semestre de 2018 se concentro en los sábados con 32 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 29% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 31% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, noche se han presentado el mayor número de eventos de homicidio con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 81 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 90% de los casos, aumentando 49 casos frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 11 víctima en el primer semestre de 2017 a 17 victimas en el 2018 (54, 5%), superando el promedio de los últimos cinco años en el (los) año(s) de 2013, 2015, 2016, 2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 11, 1 por CDH, y estuvo por encima en 8, 2 puntos de la tasa de ANTIOQUIA y por encima en 8, 7 puntos de la tasa del país.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 91 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, se ha disminuido en 93 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 14 caso en 2014, a 1 caso en el presente año.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; se observan tendencias crecientes de este delito en niños. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, jueves, viernes, sábado, con el 77 porciento, en el primer semestre de 2018 se concentro en los Martes con 5 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 33% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 46% de los casos. Ahora bien, en lo corrido del año, los dias, miércoles, en la jornada, mañana, se han presentado el mayor número de eventos de homicidio con el 1 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 89 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 88% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 20 víctimas en el primer semestre de 2017 a 71 victima en el 2018 (255%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 7, 1 por CDH, y estuvo por encima en 4, 2 puntos de la tasa de ANTIOQUIA y por encima en 4, 7 puntos de la tasa del país.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 89 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (5 casos). En el primer semestre de 2018, se ha disminuido en 65 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 23 caso en 2014, a 8 casos en el presente año.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 28 porciento, en el primer semestre de 2018 se concentro en los lunes con 16 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 36% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 38% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada tarde, se han presentado el mayor número de eventos de homicidio con el 0 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 99% de los casos, aumentando 59 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion: "no ha presentados casos",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion: "no ha presentados casos",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion: "no ha presentados casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion: "no ha presentados casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%). ",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se mueve como conductor de taxi. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 73 porciento de homicidio se comenten por medio de intimidación (sin uso de armas). En el primer semestre de 2018 no se han presentado casos de abigeato",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de Secuestro se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de Secuestro se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de Secuestro se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de Secuestro se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 16 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (-56, 3%). ",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 66 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (12 casos). En el primer semestre de 2018, se ha disminuido en 63 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 8 caso en 2016, a 3 casos en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido jóvenes con el (34) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las amenazas se concentró en adultos con el 86 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 2 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (200%), superando el promedio de los últimos cinco años en el (los) año(s) de 2015, 2016, en lo que va corrido del año ya superó este promedio.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 63 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, se ha aumentado en 100 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 1 caso en 2016, a 2 casos en el presente año.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido jóvenes con el (33) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, las amenazas se concentró en adultos con el 67 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 27 víctimas en el primer semestre de 2017 a 30 victimas en el 2018 (11, 1%), superando el promedio de los últimos cinco años en el (los) año(s) de 2014, 2015, 2016, en lo que va corrido del año no ha superado este promedio.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 50 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (47 casos). En el primer semestre de 2018, se ha disminuido en 67 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 33 caso en 2016, a 11 caso en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (52) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, las amenazas se concentró en adultos con el 70 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%), superando el promedio de los últimos cinco años en el (los) año(s) de 2014, 2016, en lo que va corrido del año no ha superado este promedio.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 59 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (10 casos). En el primer semestre de 2018, se ha disminuido en 50 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2014, a 1 caso en el presente año.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (49) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las amenazas se concentró en jóvenes, con el 100 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 111 víctima en el primer semestre de 2017 a 192 victimas en el 2018 (73%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 55 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (120 casos). En el primer semestre de 2018, se ha aumentado en 66 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 73 caso en 2016, a 121 caso en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (60) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos. En el primer semestre de 2018, las amenazas se concentró en adultos con el 65 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 5 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-40%).",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 65 porciento en promedio en 2016, 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (38) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, las amenazas se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 4 víctimas en el primer semestre de 2017 a 12 victimas en el 2018 (200%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 64 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (4) que en 2016.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (48) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, las amenazas se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 8 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (-37, 5%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 4, 9 por CDH, y estuvo por encima en 3, 1 punto de la tasa de ANTIOQUIA y por debajo 1, 3 puntos de la tasa del país.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 88 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (27 casos). En el primer semestre de 2018, se ha disminuido en 71 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 14 caso en 2015, a 4 casos en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (64) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, los delitos sexuales se concentró en niños, con el 60 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 25 porciento, en el primer semestre de 2018 se concentro en los viernes, sábado con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 40% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 40% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, sábado, domingo en la jornada madrugada, mañana, tarde/noche se han presentado el mayor número de eventos de delitos sexuales con el 100 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53 porciento de delitos sexuales se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 50% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 7 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-42, 9%) Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 10, 4 por CDH, y estuvo por encima en 8, 6 puntos de la tasa de ANTIOQUIA y por encima en 4, 2 puntos de la tasa del país.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 93 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, se ha aumentado en 300 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 1 caso en 2016, a 4 casos en el presente año.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (51) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos. En el primer semestre de 2018, los delitos sexuales se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, domingo con el 67 porciento, en el primer semestre de 2018 se concentro en los Martes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 75% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 56% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada, mañana, se han presentado el mayor número de eventos de delitos sexuales con el 5 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 30 víctimas en el primer semestre de 2017 a 20 victimas en el 2018 (-33, 3%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 5, 3 por CDH, y estuvo por encima en 3, 5 puntos de la tasa de ANTIOQUIA y por debajo 0, 9 puntos de la tasa del país.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 87 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (56 casos). En el primer semestre de 2018, se ha aumentado en 200 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 5 caso en 2017 a 15 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (47) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, los delitos sexuales se concentró en niños, con el 65 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 21 porciento, en el primer semestre de 2018 se concentro en los lunes, martes con 10 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 45% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 52% de los casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de delitos sexuales con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 58 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 80% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 17 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (-58, 8%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 6, 6 por CDH, y estuvo por encima en 4, 9 puntos de la tasa de ANTIOQUIA y por encima en 0, 4 puntos de la tasa del país.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 87 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (22 casos). En el primer semestre de 2018, se ha disminuido en 60 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 15 caso en 2017 a 6 casos en el presente año.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (56) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, los delitos sexuales se concentró en niños, con el 71 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 22 porciento, en el primer semestre de 2018 se concentro en los Jueves con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 86% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 70% de los casos. Ahora bien, en lo corrido del año, los dias jueves, en la jornada madrugada, se han presentado el mayor número de eventos de delitos sexuales con el 14 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de delitos sexuales se comenten con arma de fuego escopolamina llave maestra. En el primer semestre de 2018 se presenta el uso de arma de fuego, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 43 víctimas en el primer semestre de 2017 a 17 victimas en el 2018 (-60, 5%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 3, 3 por CDH, y estuvo por debajo en 1, 1 punto de la tasa de NARIÑO y por debajo 2, 9 puntos de la tasa del país.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 88 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (75 casos). En el primer semestre de 2018, se ha aumentado en 75 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 12 casos en 2016, a 21 caso en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (52) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos mayores. En el primer semestre de 2018, los delitos sexuales se concentró en adultos con el 41 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 19 porciento, en el primer semestre de 2018 se concentro en los domingos con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 39% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 71% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada madrugada, se han presentado el mayor número de eventos de delitos sexuales con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 72 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 41% de los casos, aumentando 7 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 4 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-50%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 3, 9 por CDH, y estuvo por encima en 2, 1 punto de la tasa de ANTIOQUIA y por debajo 2, 3 puntos de la tasa del país.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 74 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (1) que en 2014, ",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (80) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, los delitos sexuales se concentró en niños, adultos, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, sábado, domingo con el 67 porciento, en el primer semestre de 2018 se concentro en los lunesSábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 44% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, en la jornada madrugada, se han presentado el mayor número de eventos de delitos sexuales con el 4 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 11 víctima en el primer semestre de 2017 a 2 victimas en el 2018 (-81, 8%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 3, 8 por CDH, y estuvo por encima en 2 puntos de la tasa de ANTIOQUIA y por debajo 2, 4 puntos de la tasa del país.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 94 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (15 casos).",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (66) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, los delitos sexuales se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 29 porciento, en el primer semestre de 2018 se concentro en los lunes, domingo con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 65% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, en la jornada madrugada, se han presentado el mayor número de eventos de delitos sexuales con el 2 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 66 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 5 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-80%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 71 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, se ha disminuido en 50 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2016, a 1 caso en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (65) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el extorsión se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60 porciento de extorsión se comenten con arma de fuego llamada telefonica. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 100% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 0 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (100%)",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 100 porciento en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (40) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el extorsión se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, llamada telefónica, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 5 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (40%)",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 71 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, se ha disminuido en 85 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 13 caso en 2016, a 2 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (76) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el extorsión se concentró en adultos con el 57 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 57 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, directa, llamada telefónica, como el arma/medio más empleado, con el 86% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 0 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (100%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 67 porciento en promedio, en 2013, 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (70) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el extorsión se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de extorsión se comenten con arma de fuego llamada telefonica llave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 20 víctimas en el primer semestre de 2017 a 24 victimas en el 2018 (20%), superando el promedio de los últimos cinco años en el (los) año(s) de 2014, 2016, en lo que va corrido del año no ha superado este promedio.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 64 porciento en promedio en 2014, se presenta el mayor número de casos de mujeres víctimas de este delito (20 casos). En el primer semestre de 2018, se ha disminuido en 81 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 26 caso en 2016, a 5 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (83) porciento de los casos; se observan tendencias crecientes de este delito en adultos. En el primer semestre de 2018, el extorsión se concentró en adultos con el 67 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, llamada telefónica, como el arma/medio más empleado, con el 54% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 1 víctima en el primer semestre de 2017 a 3 victimas en el 2018 (200%)",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 67 porciento en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (30) porciento de los casos; se observan tendencias crecientes de este delito en adultos, adultos mayores. En el primer semestre de 2018, el extorsión se concentró en adultos con el 67 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, llamada telefónica, como el arma/medio más empleado, con el 67% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 0 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (400%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 78 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (1 caso).",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (40) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, el extorsión se concentró en adultos y adultos mayores con el 100 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, directa, como el arma/medio más empleado, con el 75% de los casos, aumentando 3 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 100 porciento en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (60) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el no hubo victimas de hurto a automotores",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie Motocicleta, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 57 porciento de hurto a automotores se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, arma de fuego como el arma/medio más empleado, con el 100% de los casos, aumentando 3 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 2 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (0%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 89 porciento en promedio, en 2013, se presenta el mayor número de casos de mujeres víctimas de este delito (1 caso). En el primer semestre de 2018, se ha aumentado en 100 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 1 caso en 2016, a 2 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (80) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el hurto a automotores se concentró en adultos y adultos mayores con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, sábado, con el 100 porciento, en el primer semestre de 2018 se concentro en los MartesSábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, Tarde, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, tarde, con el 100% de los casos. Ahora bien, en lo corrido del año, los dias, miércoles, viernes, en la jornada, mañana, tarde, se han presentado el mayor número de eventos de hurto a automotores con el 17 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie Motocicleta, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de hurto a automotores se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 100% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 0 víctimas en el primer semestre de 2017 a 1 victimas en el 2018.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado igual en hombres y mujeres porciento en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido niños, jóvenes y adultos, adultos mayores con el (0) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el no hubo victimas de hurto a automotores",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el primer semestre del 2018 el total de los casos se presentó el día Sábado. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada. No se puede hacer contraste con el 2017 ya que en este año no hubo casos ",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "En lo corrido del 2018 los victimarios se movilizan pie en todos los casos. En el primer semestre de 2018 se presenta como medio más usado la intimidación (no uso de armas). En los años anteriores no se han presentado casos de hurto a automores a excepción del 2012, caso que no reportó movil ni arma empleada.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 1 víctima en el primer semestre de 2017 a 4 victimas en el 2018 (300%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 68 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, se ha aumentado en 50 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2017 a 3 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (83) porciento de los casos; se observan tendencias crecientes de este delito en adultos, adultos mayores. En el primer semestre de 2018, el hurto a automotores se concentró en adultos con el 75 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 50 porciento, en el primer semestre de 2018 se concentro en los sábados con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, mañana, tarde/noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias lunes, martes, en la jornada, mañana, tarde, se han presentado el mayor número de eventos de hurto a automotores con el 7 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 43 porciento de hurto a automotores se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de arma de fuego, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 3 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 80 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (1 caso). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (50) porciento de los casos; se observan tendencias crecientes de este delito en adultos. En el primer semestre de 2018, el no hubo victimas de hurto a automotores",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los dias, miércoles, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a automotores con el 17 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido Motocicleta, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 80 porciento de hurto a automotores se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 100 porciento en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (20) porciento de los casos; se observan tendencias crecientes de este delito en adultos. En el primer semestre de 2018, el no hubo victimas de hurto a automotores",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, martes, miércoles, jueves, viernes, sábado, domingo con el 0 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de hurto a automotores con el 100 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido Motocicleta, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de hurto a automotores se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 8 víctimas en el primer semestre de 2017 a 9 victimas en el 2018 (12, 5%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, con el 25 porciento, en el primer semestre de 2018 se concentro en los Miércoles con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 44% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias, miércoles, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a comercio con el 11 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Taxi. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de hurto a comercio se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias jueves, sábado, con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los dias, viernes, domingo en la jornada, mañana, se han presentado el mayor número de eventos de hurto a comercio con el 100 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 43 víctimas en el primer semestre de 2017 a 28 victimas en el 2018 (-34, 9%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 20 porciento, en el primer semestre de 2018 se concentro en los Miércoles con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 43% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 39% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a comercio con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicletaNo reportadoTaxiVehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 46 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 46% de los casos, aumentando 6 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 5 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (40%). ",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 36 porciento, en el primer semestre de 2018 se concentro en los lunes, martes con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 71% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 55% de los casos. Ahora bien, en lo corrido del año, los dias, martes, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a comercio con el 14 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en No reportado. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de hurto a comercio se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 20 víctimas en el primer semestre de 2017 a 33 victimas en el 2018 (65%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 23 porciento, en el primer semestre de 2018 se concentro en los lunes con 8 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 32% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 37% de los casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de hurto a comercio con el 1 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 48% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 3 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-66, 7%).",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 38 porciento, en el primer semestre de 2018 se concentro en los Martes con 1 caso. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 63% de los casos. Ahora bien, en lo corrido del año, los dias, miércoles, en la jornada, mañana, se han presentado el mayor número de eventos de hurto a comercio con el 7 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 6 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 38 porciento, en el primer semestre de 2018 se concentro en los lunesJueves con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 50% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 63% de los casos. Ahora bien, en lo corrido del año, los dias, martes, jueves, viernes, en la jornada madrugada, mañana, se han presentado el mayor número de eventos de hurto a comercio con el 13 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 59 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 50% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a entidades financieras en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a entidades financieras en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 21 víctima en el primer semestre de 2017 a 25 victimas en el 2018 (19%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 72 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (11 caso). En el primer semestre de 2018, se ha disminuido en 63 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 8 caso en 2017 a 3 casos en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (48) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en jóvenes, con el 64 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 28 porciento, en el primer semestre de 2018 se concentro en los domingos con 9 casoss. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 44% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 38% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada tarde, se han presentado el mayor número de eventos de hurto a motocicletas con el 4 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 88 porciento de hurto a motocicletas se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 86 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (1 caso). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido jóvenes con el (22) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, el no hubo victimas de hurto a motocicletas",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, martes, jueves, sábado, con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los dias lunes, martes, miércoles, viernes, sábado, domingo en la jornada madrugada, mañana, tarde/noche se han presentado el mayor número de eventos de hurto a motocicletas con el 100 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 71 porciento de hurto a motocicletas se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 44 víctimas en el primer semestre de 2017 a 31 victima en el 2018 (-29, 5%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 73 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (21 caso). En el primer semestre de 2018, se ha disminuido en 36 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 11 caso en 2016, a 7 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (64) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en adultos con el 68 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 23 porciento, en el primer semestre de 2018 se concentro en los viernes, sábado con 16 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, Tarde, con el 65% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 29% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada madrugada, se han presentado el mayor número de eventos de hurto a motocicletas con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 40 porciento de hurto a motocicletas se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 48% de los casos, aumentando 6 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 3 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (0%). ",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 90 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos).",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (67) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, el hurto a motocicletas se concentró en jóvenes y adultos, adultos mayores con el 100 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 43 porciento, en el primer semestre de 2018 se concentro en los MartesJuevesSábado con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, tarde/noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 43% de los casos. Ahora bien, en lo corrido del año, los dias, martes, jueves, sábado, en la jornada, mañana, tarde/noche se han presentado el mayor número de eventos de hurto a motocicletas con el 100 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicletaNo reportado. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de hurto a motocicletas se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 33 víctimas en el primer semestre de 2017 a 43 victimas en el 2018 (30, 3%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 74 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (24 casos). En el primer semestre de 2018, se ha disminuido en 43 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 14 caso en 2016, a 8 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (71) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en adultos con el 70 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 23 porciento, en el primer semestre de 2018 se concentro en los sábados con 9 casoss. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 36% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la noche con el 45% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, noche se han presentado el mayor número de eventos de hurto a motocicletas con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 39 porciento de hurto a motocicletas se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 58% de los casos, aumentando 13 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 4 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-50%), superando el promedio de los últimos cinco años en el (los) año(s) de, 2017, en lo que va corrido del año ya superó este promedio.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 57 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido jóvenes con el (33) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos. En el primer semestre de 2018, el hurto a motocicletas se concentró en jóvenes y adultos, con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, sábado, con el 67 porciento, en el primer semestre de 2018 se concentro en los MiércolesViernes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 67% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias, martes, miércoles, en la jornada tarde, se han presentado el mayor número de eventos de hurto a motocicletas con el 20 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 57 porciento de hurto a motocicletas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 6 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (0%). ",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 88 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, se ha disminuido en 80 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 5 caso en 2017 a 1 caso en el presente año.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (66) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, domingo con el 67 porciento, en el primer semestre de 2018 se concentro en los Miércoles con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 67% de los casos. Ahora bien, en lo corrido del año, los dias jueves, en la jornada tarde, se han presentado el mayor número de eventos de hurto a motocicletas con el 2 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67 porciento de hurto a motocicletas se comenten con llave maestra intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 67% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 3 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (133, 3%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 4, 6 por CDH, y estuvo por debajo en 34, 7 puntos de la tasa de ANTIOQUIA y por debajo 33, 1 punto de la tasa del país.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 65 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (11 caso). En el primer semestre de 2018, se ha disminuido en 86 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 7 caso en 2016, a 1 caso en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (80) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 86 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 26 porciento, en el primer semestre de 2018 se concentro en los lunes, domingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 57% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 42% de los casos. Ahora bien, en lo corrido del año, los dias lunes, miércoles, jueves, viernes, domingo en la jornada madrugada, mañana, Noche se han presentado el mayor número de eventos de hurto a personas con el 100 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 40 porciento de hurto a personas se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 2 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (0%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 4, 6 por CDH, y estuvo por debajo en 34, 7 puntos de la tasa de ANTIOQUIA y por debajo 33, 1 punto de la tasa del país.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 60 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (45) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, miércoles, viernes, sábado, con el 100 porciento, en el primer semestre de 2018 se concentro en los MartesDomingo con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias, martes, viernes, en la jornada tarde, se han presentado el mayor número de eventos de hurto a personas con el 17 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de hurto a personas se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de objetos contundentes, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 82 víctimas en el primer semestre de 2017 a 49 victimas en el 2018 (-40, 2%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 12, 2 por CDH, y estuvo por debajo en 27, 2 puntos de la tasa de ANTIOQUIA y por debajo 25, 6 puntos de la tasa del país.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 51 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (84 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (22) que en, 2017",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (55) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 73 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 19 porciento, en el primer semestre de 2018 se concentro en los MartesMiércoles con 20 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 39% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 32% de los casos. Ahora bien, en lo corrido del año, los dias jueves, en la jornada, mañana, se han presentado el mayor número de eventos de hurto a personas con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 48 porciento de hurto a personas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 55% de los casos, aumentando 21 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 16 víctimas en el primer semestre de 2017 a 11 victima en el 2018 (-31, 3%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 9, 5 por CDH, y estuvo por debajo en 29, 8 puntos de la tasa de ANTIOQUIA y por debajo 28, 2 puntos de la tasa del país.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 59 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (22 casos). En el primer semestre de 2018, se ha disminuido en 73 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 11 caso en 2016, a 3 casos en el presente año.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (66) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 91 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 24 porciento, en el primer semestre de 2018 se concentro en los sábados con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 45% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 36% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, en la jornada tarde, se han presentado el mayor número de eventos de hurto a personas con el 9 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 38 porciento de hurto a personas se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 50 víctimas en el primer semestre de 2017 a 84 victimas en el 2018 (68%) Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 5, 2 por CDH, y estuvo por debajo en 26, 5 puntos de la tasa de NARIÑO y por debajo 32, 5 puntos de la tasa del país.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 62 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (78 casos). En el primer semestre de 2018, se ha disminuido en 28 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 36 caso en 2016, a 26 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (71) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 70 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 18 porciento, en el primer semestre de 2018 se concentro en los Viernes con 16 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 38% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 29% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada mañana, se han presentado el mayor número de eventos de hurto a personas con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 66 porciento de hurto a personas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 54% de los casos, aumentando 11 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 4 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (50%) Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 3, 4 por CDH, y estuvo por debajo en 35, 9 puntos de la tasa de ANTIOQUIA y por debajo 34, 3 puntos de la tasa del país.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 72 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (6 casos). En el primer semestre de 2018, se ha aumentado en 100 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 1 caso en 2016, 2017a 2 casos en el presente año.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (72) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 83 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 38 porciento, en el primer semestre de 2018 se concentro en los sábados con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 57% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 38% de los casos. Ahora bien, en lo corrido del año, los dias jueves, en la jornada, mañana, se han presentado el mayor número de eventos de hurto a personas con el 2 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 69 porciento de hurto a personas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de arma de fuego, no reportado, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 1, 8 por CDH, y estuvo por debajo en 37, 6 puntos de la tasa de ANTIOQUIA y por debajo 35, 9 puntos de la tasa del país.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 72 porciento en promedio, en, 2015, 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, se ha disminuido en 50 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2017 a 1 caso en el presente año.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (79) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 38 porciento, en el primer semestre de 2018 se concentro en los lunes con 1 caso. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, mañana, con el 75% de los casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de hurto a personas con el 2 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 42 porciento de hurto a personas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 8 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-75%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 24 porciento, en el primer semestre de 2018 se concentro en los JuevesSábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, tarde, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 34% de los casos. Ahora bien, en lo corrido del año, los dias jueves, sábado, en la jornada, mañana, tarde, se han presentado el mayor número de eventos de hurto a residencias con el 100 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67 porciento de hurto a residencias se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los dias, martes, viernes, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a residencias con el 100 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100 porciento de hurto a residencias se comenten con objetos contundentes intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 29 víctimas en el primer semestre de 2017 a 23 victimas en el 2018 (-20, 7%).",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, jueves, domingo con el 47 porciento, en el primer semestre de 2018 se concentro en los sábados con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 44% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 37% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a residencias con el 1 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 49 porciento de hurto a residencias se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 39% de los casos, aumentando 7 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 7 víctimas en el primer semestre de 2017 a 10 victimas en el 2018 (42, 9%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, jueves, domingo con el 69 porciento, en el primer semestre de 2018 se concentro en los Martes con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 50% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la tarde, con el 38% de los casos. Ahora bien, en lo corrido del año, los dias, martes, en la jornada, noche se han presentado el mayor número de eventos de hurto a residencias con el 10 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 86 porciento de hurto a residencias se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 16 víctimas en el primer semestre de 2017 a 25 victimas en el 2018 (56, 3%).",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, sábado, con el 44 porciento, en el primer semestre de 2018 se concentro en los Viernes con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 48% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 39% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, mañana, se han presentado el mayor número de eventos de hurto a residencias con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 69 porciento de hurto a residencias se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 48% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 0 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (100%)",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias martes, domingo con el 100 porciento, en el primer semestre de 2018 se concentro en los Jueves con 1 caso. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 100% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, en la jornada madrugada, se han presentado el mayor número de eventos de hurto a residencias con el 13 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60 porciento de hurto a residencias se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 3 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, domingo con el 100 porciento, en el primer semestre de 2018 se concentro en los lunes, martes, miércoles, jueves, viernes, sábado y domingo con 0 casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada madrugada, se han presentado el mayor número de eventos de hurto a residencias con el 14 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 57 porciento de hurto a residencias se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 52 víctimas en el primer semestre de 2017 a 27 victimas en el 2018 (-48, 1%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 24, 8 por CDH, y estuvo por encima en 3, 6 puntos de la tasa de ANTIOQUIA y por debajo de la tasa del país.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 57 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (62 casos). En el primer semestre de 2018, se ha disminuido en 61 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 36 caso en 2017 a 14 casos en el presente año.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido jóvenes con el (48) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 52 porciento de los casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 21 porciento, en el primer semestre de 2018 se concentro en los sábados con 10 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 37% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 31% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada tarde, se han presentado el mayor número de eventos de lesiones personales con el 4 porciento de estos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 49 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 2 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (200%) Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 15 por CDH, y estuvo por debajo en 6, 2 puntos de la tasa de ANTIOQUIA y por debajo 9, 8 puntos de la tasa del país.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 80 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (6 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (3) que en 2014, 2016, ",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (48) porciento de los casos; se observan tendencias crecientes de este delito en adultos mayores. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 83 porciento de los casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 38 porciento, en el primer semestre de 2018 se concentro en los sábados con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, Tarde, con el 67% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 46% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada, noche se han presentado el mayor número de eventos de lesiones personales con el 1 porciento de estos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 62 porciento de lesiones personales se comenten con arma blanca arma de fuego. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 83% de los casos, aumentando 5 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 128 víctimas en el primer semestre de 2017 a 100 victimas en el 2018 (-21, 9%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 20, 4 por CDH, y estuvo por debajo en 0, 8 puntos de la tasa de ANTIOQUIA y por debajo 4, 4 puntos de la tasa del país.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 57 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (145 casos). En el primer semestre de 2018, se ha aumentado en 85 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 34 caso en 2017 a 63 casos en el presente año.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (52) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 62 porciento de los casos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 23 porciento, en el primer semestre de 2018 se concentro en los Viernes con 23 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 38% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la noche con el 33% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada, noche se han presentado el mayor número de eventos de lesiones personales con el 0 porciento de estos.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 69 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 52% de los casos, disminuyendo 39 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 36 víctimas en el primer semestre de 2017 a 39 victimas en el 2018 (8, 3%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 20, 2 por CDH, y estuvo por debajo en 1 punto de la tasa de ANTIOQUIA y por debajo 4, 6 puntos de la tasa del país.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 58 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (54 casos). En el primer semestre de 2018, se ha disminuido en 59 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 29 casos en 2016, a 12 casos en el presente año.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (46) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 59 porciento de los casos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 31 porciento, en el primer semestre de 2018 se concentro en los domingos con 12 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 41% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 34% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada madrugada, se han presentado el mayor número de eventos de lesiones personales con el 3 porciento de estos.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, aumentando 0 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 140 víctimas en el primer semestre de 2017 a 181 victima en el 2018 (29, 3%), superando el promedio de los últimos cinco años en el (los) año(s) de 2013, 2014, 2016, 2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 14, 7 por CDH, y estuvo por debajo en 7, 6 puntos de la tasa de NARIÑO y por debajo 10 puntos de la tasa del país.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 53 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (185 casos). En el primer semestre de 2018, se ha aumentado en 7 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 96 casos en 2016, a 103 casos en el presente año.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (46) porciento de los casos; se observan tendencias crecientes de este delito en niños, adultos, adultos mayores. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 26 porciento, en el primer semestre de 2018 se concentro en los sábados y domingos con 74 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 30% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 31% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada, noche se han presentado el mayor número de eventos de lesiones personales con el 0 porciento de estos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 64 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 54% de los casos, aumentando 11 caso frente al mismo período del año anterior.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 8 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (-37, 5%) Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 7, 7 por CDH, y estuvo por debajo en 13, 5 puntos de la tasa de ANTIOQUIA y por debajo 17, 1 punto de la tasa del país.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 72 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (11 caso). En el primer semestre de 2018, se ha disminuido en 75 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 4 caso en 2017 a 1 caso en el presente año.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido jóvenes con el (46) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes, adultos mayores. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 100 porciento de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, con el 28 porciento, en el primer semestre de 2018 se concentro en los Martes con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 83% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 39% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada madrugada, se han presentado el mayor número de eventos de lesiones personales con el 1 porciento de estos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Vehículo. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 44 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 60% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 20 víctimas en el primer semestre de 2017 a 24 victimas en el 2018 (20%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 8, 7 por CDH, y estuvo por debajo en 12, 5 puntos de la tasa de ANTIOQUIA y por debajo 16, 1 punto de la tasa del país.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 55 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (22 casos). En el primer semestre de 2018, se ha aumentado en 29 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 7 caso en 2017 a 9 casoss en el presente año.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (53) porciento de los casos; se observan tendencias crecientes de este delito en en todos los grupos etarios. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 58 porciento de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 24 porciento, en el primer semestre de 2018 se concentro en los Martes con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 32% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 34% de los casos. Ahora bien, en lo corrido del año, los dias, viernes, en la jornada, mañana, se han presentado el mayor número de eventos de lesiones personales con el 0 porciento de estos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 52 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 50% de los casos, aumentando 9 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%). ",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%). ",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%)",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 4 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-75%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2,6 por CDH, y estuvo por encima en 0,4 puntos de la tasa de TOLIMA y por encima en 0,2 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se ha concentrado en los hombres con el 95% de los casos en promedio, en 2015 y 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (1 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Homicidio han sido Adultos, con el (59)% de los casos; se observan tendencias crecientes de este delito en Niños. En el primer semestre de 2018, el Homicidio se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 50%, en el primer semestre de 2018 se concentro en los Lunes con 1 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Noche con el 50% de los casos. Ahora bien en lo corrido del año, los dias Lunes en la jornada Mañana se han presentado el mayor número de eventos de Homicidio con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 58% de Homicidio se cometen con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleada, con el 100% de los casos, reduciendose en 2 casos frente al mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL PROGRESO, VRDA. SANTA RITA, VRDA. MIROLINDO, VRDA. CAMPO HERMOSO (SANTIAGO PEREZ) y CENTRO, estos concentraron el 100% de Homicidios del 2017 y el 0% de los casos del 2018.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 2 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-50%).",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 71% de Abigeato se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas) como el arma/medio más empleado, con el 100% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MESA DE POLE, VRDA. AGUA FRIA, VRDA. ANAPE, VRDA. EL PROGRESO, estos concentraron el 100% del abigeato del 2017 y el 100% de los casos del 2018 los tiene la VRDA. SANTIAGO PEREZ.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 5 víctimas en el primer semestre de 2017 a 11 victimas en el 2018 (120%). Por su parte, la tasa de amenazas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 8,8 por CDH, y estuvo por encima en 8,8 puntos de la tasa de TOLIMA y por encima en 8,8 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 67% de los casos en promedio, en 2016 y 2017 se presenta el mayor número de casos de mujeres víctimas de este delito (7 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (3) que en 2015.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Amenazas han sido Adultos, con el (38)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, las amenazas se concentraron en Adultos, con el 64% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. SAN TIAGO PEREZ concentra el 100% de amenazas de 2017 y el 100% de los casos del 2018 los concentra la misma vereda y el CENTRO.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 11 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-81,8%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 5,3 por CDH, y estuvo por debajo en 4,1 puntos de la tasa de TOLIMA y por debajo 0,9 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 97% de los casos en promedio, en20162017, se presenta el mayor número de casos de mujeres víctimas de este delito (12 casos). En el primer semestre de 2018, se ha disminuido en 82% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 11 caso en 2017 a 2 casos en el presente año.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Delitos sexuales han sidoNiños, con el (64)% de los casos; se observan tendencias crecientes de este delito en Niños, Adultos,Adultos Mayores. En el primer semestre de 2018, el Delitos sexuales se concentró en Niños, con el 100% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves , con el 33%, en el primer semestre de 2018 se concentro en los JuevesSábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 67% de los casos. Ahora bien en lo corrido del año, los días jueves , Sábado, en la jornada,Mañana, se han presentado el mayor número de eventos de Delitos sexuales con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 77% de Delitos sexuales se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de objetos contundentes, no reportado como el arma/medio más empleado, con el 100% de los casos, disminuyendo 3 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y DISCOTECAS, representando el 95% de los casos en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. SAN TIAGO PEREZ, VRDA. EL CAIRO, VRDA. SAN PEDRO, LA CRUZ, VRDA. BETANIA, estos concentraron el 100% de Delitos sexuales de 2017 y el 100% de los casos del 2018 están en el CENTRO y la VRDA. SANTIAGO PEREZ.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%), superando el promedio de los últimos años en el (los) año(s) de 2014,2015, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de extorsión por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 0,9 por CDH, y estuvo por encima en 0,9 puntos de la tasa de TOLIMA y por encima en 0,9 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 77% de los casos en promedio, en 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (5 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Extorsión han sido Adultos, con el (75)% de los casos; se observan tendencias crecientes de este delito en,Jóvenes. En el primer semestre de 2018, el no hubo victimas de Extorsión",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 76% de Extorsión se cometen con llamada telefonica. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MONTE LORO y VRDA. PALESTINA, estos concentraron el 100% de extorsión de 2017 y en el 2018 no se han presentado casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 1 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (100%), superando el promedio de los últimos años en el (los) año(s) de 2013 en lo que va corrido del año no ha superado este promedio. ",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Miércoles, Viernes,Sábado, con el 100%, en el primer semestre de 2018 se concentro en los SábadoDomingo con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 67% de los casos. Ahora bien en lo corrido del año, los dias Sábado,Domingo en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a comercio con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 58% de Hurto a comercio se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, . ",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO y VRDA. BETANIA concentran el 100% de Hurto a comercio del 2017 y el 100% de los casos del 2018 los concentra el CENTRO y la VRDA. EL PAUJIL.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia estable en el número de casos de Hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-50%), superando el promedio de los últimos años en el (los) año(s) de 2014,2015, en lo que va corrido del año no ha superado este promedio. ",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 73% de los casos en promedio, en 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a motocicletas han sido Adultos, con el (58)% de los casos; se observan tendencias crecientes de este delito en Adultos. En el primer semestre de 2018, el Hurto a motocicletas se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves , con el 67%, en el primer semestre de 2018 se concentro en los Miércoles con 1 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de laTarde con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada,Mañana, Noche con el 100% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles, en la jornada Tarde se han presentado el mayor número de eventos de Hurto a motocicletas con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 86% de Hurto a motocicletas se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de llave maestra como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LAS ACASIAS, VRDA. EL PROGRESO y VRDA. MONTE LORO, estos concentraron el 100% de Hurto a motocicletas de 2017 y el 100% de los casos del 2018 los tiene la VRDA. SANTA ROSA .",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 7 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-42,9%), superando el promedio de los últimos años en el (los) año(s) de 2013,2014, 2016,2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 5,3 por CDH, y estuvo por debajo en 27,7 puntos de la tasa de TOLIMA y por debajo 32,4 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 66% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (6 casos). En el primer semestre de 2018, se ha disminuido en 40% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 5 caso en 2016,a 3 casos en el presente año.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a Personas han sido Adultos, con el (52)% de los casos; se observan tendencias crecientes de este delito en Adultos y Adultos Mayores. En el primer semestre de 2018, el Hurto a Personas se concentró en Adultos, con el 75% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Viernes,Sábado, con el 67%, en el primer semestre de 2018 se concentro en los Sábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana,Tarde con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante laTarde con el 58% de los casos. Ahora bien en lo corrido del año, los días jueves , Sábado,Domingo en la jornada,Mañana,Tarde se han presentado el mayor número de eventos de Hurto a Personas con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 70% de Hurto a Personas se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CAJEROS AUTOMATICOS Y ALMACENES, representando el 95% de los casos en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, ADOLFO LEON ALVAREZ, LAS BRISAS, VRDA. CAMPO HERMOSO (SANTIAGO PEREZ), VRDA. EL CONDOR, estos concentraron el 83% de Hurto a Personas de 2017 y el 100 % de los casos del 2018 los concentra el centro y la VRDA. SANTIGO PEREZ.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 9 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-66,7%).",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves , con el 40%, en el primer semestre de 2018 se concentro en los Martes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, Tarde Noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 47% de los casos. Ahora bien en lo corrido del año, los dias Lunes,Martes en la jornada Madrugada, Tarde Noche se han presentado el mayor número de eventos de Hurto a residencias con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 73% de Hurto a residencias se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 67% de los casos, . ",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. CANOAS CAPOTE, VILLA ALEJANDRA, LAS BRISAS, VRDA. EL CONDOR, estos concentraron el 53% de Hurto a residencias de 2017 y el 100% de los casos del 2018 se concentran en la VRDA. CANOAS CAPOTE y en la VRDA. SANTIAGO PEREZ.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Lesiones Personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 22 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (-68,2%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 18,1 por CDH, y estuvo por debajo en 21,8 puntos de la tasa de TOLIMA y por debajo 6,7 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 72% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (15 casos). En el primer semestre de 2018, se ha disminuido en 70% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 10 caso en 2016,a 3 casos en el presente año.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el (51)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Lesiones Personales se concentró en,Jóvenes,Adultos, con el 86% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 46%, en el primer semestre de 2018 se concentro en los Domingo con 5 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 43% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 46% de los casos. Ahora bien en lo corrido del año, los dias, Domingo en la jornada Madrugada,Noche se han presentado el mayor número de eventos de Lesiones Personales con el 29% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 66% de Lesiones Personales se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes, como el arma/medio más empleado, con el 71% de los casos, disminuyendo 12 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue ESTABLECIMIENTO DE COMERCIO Y GALLERA representando el 100% de los casos en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. SAN TIAGO PEREZ, PORVENIR, LA INVASION, EL BOLSILLO, estos concentraron el 63% de Lesiones Personales de 2017 y el100% de los casos del 2018 están en la VRDA. PALESTINA, CENTRO, VRDA. BETANIA, EL BOLSILLO Y VRDA. CASA VERDE.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 3 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (0%), superando el promedio de los últimos años en el (los) año(s) de 2014,2015, 2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 4,4 por CDH, y estuvo por debajo en 11,7 puntos de la tasa de TOLIMA y por debajo 14,4 puntos de la tasa del país",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 100% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (10 casos). En el primer semestre de 2018, se ha disminuido en 67% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 6 caso en 2014,a 2 casos en el presente año.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Violencia intrafamiliar han sido,Jóvenes, con el (51)% de los casos; se observan tendencias crecientes de este delito en niños, jóvenes y adultos mayores. En el primer semestre de 2018, el Violencia intrafamiliar se concentró en Niños, Adultos y Adultos Mayores con el 100% de los casos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Viernes, con el 60%, en el primer semestre de 2018 se concentro en los Viernes, Sábado y Domingo con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 100% de los casos. Ahora bien en lo corrido del año 2018 los días viernes, sábado y domingo en la jornada Madrugada y Tarde se han presentado el mayor número de eventos de Violencia intrafamiliar con el 100% de estos.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68% de Violencia intrafamiliar se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 67% de los casos, . ",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN, representando el 100% de los casos. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. SAN TIAGO PEREZ concentra el 100% de Violencia intrafamiliar del 2017 y el 100% de los casos del 2018.",
      codigoMunicipio: "5ba00725b873c2001384960f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: EL MORRON, CALLE CALIENTE, PUEBLO NUEVO, EL RESPALDO Y ALTO DEL CHIRI, estos concentraron el 45% de Homicidios de 2017 y el 100% de los casos del 2018 sucedieron en TRAVESIAS, ESPIRITU SANTO Y LA MESETA .",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "En el 2014 y 2015 los únicos años donde hubo abigeato sucedieron en la zona rural. En los dos ultimos años no se han presentado casos de abigeato en el municipio, en el 2014 y 2015 se concentro el la VRDA EL RESPALDO Y LA AGUADA.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron:, estos concentraron el 0% de Amenazas de 2017 y el 0% de los casos del 2018.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "En el 2017 no se presentando casos de extorsión, en lo corrido del 2018 se ha presentado un caso, ubicado en la VRDA. EL PESCADO",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PARQUE PRINCIPAL Y CENTRAL, estos concentraron el 100% de Hurto a comercio de 2017 y en el 2018 no se han dado casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SECTOR CEMENTERIO, PALMICHAL, LA QUIEBRA Y TRAVERISAS, estos concentraron el 100% de Hurto a motocicletas de 2017 y en el 2018 no hay casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 el barrio/vereda que concentró todos los casos de hurto a residencias fue LA QUIEBRA, en el 2018 no se han presentado casos.",
      codigoMunicipio: "5bad4152ef476d001358246d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PUEBLO NUEVO, LA ESPERANZA, LA YE, EL FERRY, EL PALMAR, estos concentraron el 47% de Homicidios de 2017 y el 20% de los casos del 2018 están en LOMA FRESCA, EL FERRY, EL TRIANGULO, EL CAMELLO y LAS VILLAS",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "En los últimos años no se han presentado casos de abigeato. EL último que aconteció fue en el 2013, un único caso y se concentro en el barrio/vereda corrales negros.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LA PLAYA, CENTENARIO, LA YE, CLEMENTE ARRIETA y EL FERRY concentran el 100% de amenazas de 2017 y el 82% de los casos del 2018 los concentra LA PLAYA, CENTRO, BARRANQUILLITA, PUEBLO NUEVO y ASOVIVIENDA.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, PUEBLO NUEVO, EL PAJONAL, EL AGUILA, VIA PRINCIPAL, estos concentraron el 81% de extorsión de 2017 y el 100% de los casos del 2018 se concentran en el CENTRO, LOS MANGUITOS, LA PAZ, LA GARRAPATA Y KILOMETRO 18.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PUERTO NUEVO Y KILOMETRO 18, estos concentraron el 100% de Hurto a automotores de 2017 y LA PLAYA concentra el 100% de los casos del 2018.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LAS GAVIOTAS, PUEBLO NUEVO, CENTRO, EL TRIANGULO y KENNEDY, estos concentraron el 38 % de Hurto a comercio de 2017 y en el 2018 los barrios/veredas con mayor número son: CENTRO, LA PLAYA, EL TRIANGULO, LA YE y PUEBLO NUEVO, concentrando estos el 66% de los casos del 2018.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SANTA ELENA, PUEBLO NUEVO, LA PLAYA, EL TRIANGULO, VIA PRINCIPAL, estos concentraron el 28% de Hurto a motocicletas de 2017 y el 55% de los casos del 2018.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: EL TRIANGULO, PUEBLO NUEVO, ASOVIVIENDA, LA PLAYA, SANTA ELENA, estos concentraron el 46% de Hurto a residencias de 2017 y el 68% de los casos del 2018 los concentra: EL TRIANGULO, LA PLAYA EL PARAISO, ASOVIVIENDA Y PUEBLO NUEVO.",
      codigoMunicipio: "5bad4123ef476d001358246c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 7 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-42,9%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución. Para 2017 la tasa del municipio fue de 3 por CDH, y estuvo por encima en 0,7 puntos de la tasa de TOLIMA y por encima en 0,5 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) este delito se han concentrado en los hombres con el 96% de los casos en promedio. En 2014 se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Homicidio han sido Adultos, con el (46)% de los casos; no se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el Homicidio se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes, con el 43%, en el primer semestre de 2018 se concentro en los sábados con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 36% de los casos. Ahora bien en lo corrido del año, los dias Sábado en la jornada de la mañana se han presentado el mayor número de eventos de Homicidio con el 25% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60% del Homicidio se comete con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 50% de los casos, reduciendose en un 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL LIMON, VRDA. EL SALADO, PANDO, VRDA. SANTA BARBARA, AMBEIMA, estos concentraron el 50% de Homicidios del 2017 y en el 2018 el principal barrios/veredas fue EL SECTOR SANTA ANA, CENTRO, LOS CAUCHOS Y VRDA PALMERA concentrando el 100% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 11 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (-54,5%).",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 88% de Abigeato se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 60% de los casos, bajando en 4 casos frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. GUAYABAL, VRDA. PROVIDENCIA, VRDA. LAS CRUCES, CENTRO, VRDA. ESPIRITU SANTO, estos concentraron el 38% del abigeato del 2017 y el 100% de los casos del 2018 los concentran los siguientes: VRDA. LAS TAPIAS, VRDA SANTA JOSE DE LAS HERMOSAS Y VERSALLES.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 35 víctimas en el primer semestre de 2017 a 63 victimas en el 2018 (80%), superando el promedio de los últimos años en el (los) año(s) de 2015, 2016 y 2017 en lo que va corrido del año ya superó este promedio. Por su parte, la tasa de amenazas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 9,9 por CDH, y estuvo por encima en 9,9 puntos de la tasa de TOLIMA y por encima en 9,9 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 59% de los casos en promedio, en 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (31 casos). En el primer semestre de 2018, se ha aumentado en 59% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 17 casos en 2016 a 27 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Amenazas han sido Adultos con el (32)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, las amenazas se concentraron en Adultos, con el 59% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LAS TAPIAS con el 100% de amenazas de 2017 y el 100% de los casos del 2018 lo tuvo el CENTRO, BELTRAN, VRDA LAS TAPIAS, DIVINO NIÑO Y LAS AMÉRICAS.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 32 víctimas en el primer semestre de 2017 a 27 victimas en el 2018 (-15,6%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 13,7 por CDH, y estuvo por encima en 4,4 puntos de la tasa de TOLIMA y por encima en 7,6 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 88% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (57 casos). En el primer semestre de 2018, se ha disminuido en 26% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 31 caso en 2017 a 23 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Delitos sexuales han sidoNiños, con el (60)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Delitos sexuales se concentró en Niños, con el 52% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 26%, en el primer semestre de 2018 se concentro en los LunesViernesDomingo con 18 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 37% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 75% de los casos. Ahora bien en lo corrido del año, los dias, Viernes, en la jornada,Mañana, se han presentado el mayor número de eventos de Delitos sexuales con el 4% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 76% de Delitos sexuales se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 70% de los casos, aumentando 19 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y ESCUELAS O COLEGIOS representando el 98% de los casos. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LAS TAPIAS, CENTRO, JOSE MARIA MELO, DIVINO NIÑO, TULUNI, estos concentraron el 84% de Delitos sexuales de 2017 y el 92% de los casos del 2018 estuvieron concentrados en: VRDA. LAS TAPIAS, BELTRAN, ALGODONES, CASTAÑAL Y CENTRO",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 1 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (200%), superando el promedio de los últimos años en el (los) año(s) de 2013,2014,2015, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de extorsión por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 0,8 por CDH, y estuvo por encima en 0,8 puntos de la tasa de TOLIMA y por encima en 0,8 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 64% de los casos en promedio, en 2013,2014, se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, se ha disminuido en 67% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 3 caso en,2015, a 1 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Extorsión han sido Adultos, con el (75)% de los casos;No se observan tendencias crecientes en los diferentesgrupos de edad. En el primer semestre de 2018, el Extorsión se concentró en adultos Mayores con el 67% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 71% de Extorsión se cometen con llamada telefonica. En el primer semestre de 2018 se presenta el uso de llamada telefónica, como el arma/medio más empleado, con el 67% de los casos, aumentando 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. SAN ANDRES, VRDA. AMOYA, CASTAÑAL, VRDA. SANTA BARBARA, estos concentraron el 100% de extorsión de 2017 y en el 2018 los casos están concentrado en: EL CAMELLON, VRDA. GUACALI Y VRDA. CALARMA, estos con el 100% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 1 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (200%), superando el promedio de los últimos años en el (los) año(s) de 2013,2014,20152016 en lo que va corrido del año ya superó este promedio. ",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 56% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (1) que en,20152016",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a automotores han sido Adultos, con el (70)% de los casos; se observan tendencias crecientes de este delito en adultos Mayores. En el primer semestre de 2018, el Hurto a automotores se concentró en Adultos, con el 67% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Miércoles, con el 100%, en el primer semestre de 2018 se concentro en los Jueves con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, Tarde Noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 100% de los casos. Ahora bien en lo corrido del año, los dias Lunes,Jueves, en la jornada Madrugada, Tarde Noche se han presentado el mayor número de eventos de Hurto a automotores con el 100% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 57% de Hurto a automotores se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 67% de los casos, . ",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTROestos concentran el 100% de Hurto a automotores de 2017 y el 100% de los casos del 2018 están concentrados en: VILLA DEL ROCIO, VILLA DEL ROCIO Y VRDA. YAGUARA .",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 29 víctimas en el primer semestre de 2017 a 30 victimas en el 2018 (3,4%). ",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes, con el 19%, en el primer semestre de 2018 se concentro en los MiércolesViernes con 12 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 40% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 54% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles, en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a comercio con el 3% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 49% de Hurto a comercio se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 43% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VERSALLES, VRDA. LAS TAPIAS, LOS FUNDADORES, DIVINO NIÑO, estos concentraron el 59% de Hurto a comercio deL 2017 y el 76% de los casos del 2018 se concentran en: VRDA. LAS TAPIDAS, EL CENTRO, OBRERO, SAN JUAN BAUTISTA Y VRDA. GUACALI.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 18 víctimas en el primer semestre de 2017 a 16 victimas en el 2018 (-11,1%). ",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 83% de los casos en promedio, en 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (6 casos). En el primer semestre de 2018, se ha disminuido en 25% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 4 caso en,2015, a 3 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a motocicletas han sido Adultos, con el (69)% de los casos; se observan tendencias crecientes de este delito en,Jóvenes,Adultos,Adultos Mayores. En el primer semestre de 2018, el Hurto a motocicletas se concentró en Adultos, con el 75% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 28%, en el primer semestre de 2018 se concentro en los Domingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, Noche con el 63% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 34% de los casos. Ahora bien en lo corrido del año, los días jueves , Sábado,Domingo en la jornada Madrugada,Mañana, Noche se han presentado el mayor número de eventos de Hurto a motocicletas con el 19% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 74% de Hurto a motocicletas se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 44% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, LA LOMA, CARMENZA ROCHA, VRDA. TULUNI, VRDA. EL LIMON, estos concentraron el 52% de Hurto a motocicletas de 2017 y en el 2018 los casos se concentran en: VRDA. SEÑORITAS, VRDA. LAS TAPIAS, CENTRO, 1o DE MAYO Y VRDA. YAGUARA con el 44%.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 71 víctimas en el primer semestre de 2017 a 70 victimas en el 2018 (-1,4%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 23,9 por CDH, y estuvo por debajo en 9,1 puntos de la tasa de TOLIMA y por debajo 13,8 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 62% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (61 casos). En el primer semestre de 2018, se ha disminuido en 4% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 28 caso en 2016,a 27 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a Personas han sido Adultos, con el (55)% de los casos; se observan tendencias crecientes de este delito en,Jóvenes,Adultos,Adultos Mayores. En el primer semestre de 2018, el Hurto a Personas se concentró en Adultos, con el 67% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes, con el 19%, en el primer semestre de 2018 se concentro en los Viernes con 14 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana,Tarde con el 60% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 30% de los casos. Ahora bien en lo corrido del año, los dias, Viernes, en la jornada,Mañana, Noche se han presentado el mayor número de eventos de Hurto a Personas con el 3% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 64% de Hurto a Personas se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 30% de los casos, aumentando 17 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CAJEROS AUTOMATICAS Y CASAS DE HABITACIÓN, representando el 88% de los casos, esto con respecto al 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. LAS TAPIAS, CARMENZA ROCHA, OBRERO, LIBERTADOR, estos concentraron el 58% de Hurto a Personasde 2017 y los casos del 2018 se han concentrado en: CENTRO, VRDA. LAS TAPIAS, LA LOMA, VRDA. AMOYA Y TULUNI, concentrando estos el 70% de lo casos .",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 47 víctimas en el primer semestre de 2017 a 42 victimas en el 2018 (-10,6%). ",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 19%, en el primer semestre de 2018 se concentro en los LunesMartes con 18 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 38% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 47% de los casos. Ahora bien en lo corrido del año, los dias Lunes, en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a residencias con el 2% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 61% de Hurto a residencias se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 48% de los casos, aumentando 20 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LAS TAPIAS, SAN JUAN BAUTISTA, LA LOMA, VRDA.MARINA, CENTRO, estos concentraron el 36% de Hurto a residencias de 2017 y los casos del 2018 se concentraron en: VRDA. LAS TAPIAS, CENTRO, VILLA ESPERANZA, DIVINO NIÑO y LOS FUNDADORES, estos concentraron el 67% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Lesiones Personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 156 víctimas en el primer semestre de 2017 a 92 victimas en el 2018 (-41%), superando el promedio de los últimos años en el (los) año(s) de 2016 y 2017 en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de lesiones personales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 62 por CDH, y estuvo por encima en 22,1 puntos de la tasa de TOLIMA y por encima en 37,2 puntos de la tasa del país",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 59% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (129 casos). En el primer semestre de 2018, se ha disminuido en 39% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 69 caso en 2017 a 42 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el (48)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Lesiones Personales se concentró en Adultos, con el 49% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 29%, en el primer semestre de 2018 se concentro en los Miércoles con 19 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 33% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 44% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles, en la jornada,Mañana, se han presentado el mayor número de eventos de Lesiones Personales con el 1% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67% de Lesiones Personales se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes, como el arma/medio más empleado, con el 61% de los casos, disminuyendo 37 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y BARES CANTINAS Y SIMILARES, representando el 92% de los casos registrados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LAS TAPIAS, CENTRO, EL ROCIO, LA LOMA, LIBERTADOR, estos concentraron el 63% de Lesiones Personalesde 2017 y los casos del 2018 se concentraron en: EL CENTRO, VRDA LAS TAPIAS, DIVINO NIÑO, CARMENZA ROCHA y EL PARAISO, concentrando el 62%",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 11 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Solo en el 2014 se presentó un caso de secuestro, desde ese año no se ha presentado un caso de secuestro en el municipio.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 52 víctimas en el primer semestre de 2017 a 57 victimas en el 2018 (9,6%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes permanece en equilibrio. Para 2017 la tasa del municipio fue de 21,6 por CDH, y estuvo por encima en 5,5 puntos de la tasa de TOLIMA y por encima en 2,8 puntos de la tasa del país.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 81% de los casos en promedio, en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (105 casos). En el primer semestre de 2018, se ha disminuido en 29% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 63 caso en 2016 a 45 casos en el presente año.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Violencia intrafamiliar han sido Adultos, con el (41)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Violencia intrafamiliar se concentró en Adultos, con el 42% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 31%, en el primer semestre de 2018 se concentro en los Lunes con 13 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 32% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 75% de los casos. Ahora bien en lo corrido del año, los dias Lunes en la jornada Madrugada, se han presentado el mayor número de eventos de Violencia intrafamiliar con el 2% de estos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 58% de Violencia intrafamiliar se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 81% de los casos, aumentando 46 casos frente al mismo período del año anterior",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y FINCAS Y SIMILARES, representando el 100% de los casos. presentados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LAS TAPIAS, TULUNI, CENTRO, EDEN y SAN JUAN BAUTISTA, estos concentraron el 89% de Violencia intrafamiliar de 2017 y en el 2018 los casos estuvieron principalmente en VRDA. LAS TAPIAS, EL CAMELLON, CENTRO, LAS AMERICAS y el LIBERTADOR con el 88% de los casos.",
      codigoMunicipio: "5ba012dbb873c20013849621",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 2 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-50%). Por su parte, la tasa de homicidios por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 2,8 por CDH, y estuvo por encima en 0,6 puntos de la tasa de TOLIMA y por encima en 0,3 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 90% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (1 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Homicidio han sido Adultos, con el (50)% de los casos. Se observan tendencias crecientes de este delito en Niños y Adultos Mayores. En el primer semestre de 2018, el Homicidio se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes, Miércoles y Domingo con el 100%, en el primer semestre de 2018 se concentró en los Sábado con 1 caso. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, Tarde y Noche con el 100% de los casos. Ahora bien, en lo corrido del año los dias Sábado, en la jornada Noche se han presentado el mayor número de eventos de Homicidio con el 100% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67% de Homicidio se cometen con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 100% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SECTOR RIO NEGRO, PUEBLO NUEVO, VRDA MUNDO NUEVO Y SECTOR RIO NEGRO, estos concentraron el 100% de Homicidios del 2017 y el 100% de los casos del 2018 los concentra la VRDA GUAMITOS.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 1 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (0%). ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Abigeato se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas) como el arma/medio más empleado, con el 100% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio, en el primer semestre de 2018 esta igual que promedio anual (2014 - 2017). En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. DOS QUEBRADAS concentra el 100% del abigeato del 2017 y el 100% de los casos del 2018 se presentó en la VRDA CHAPARRO.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 5 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-80%). Por su parte, la tasa de amenazas por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 8,3 por CDH, y estuvo por encima en 8,3 puntos de la tasa de TOLIMA y por encima en 8,3 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 53% de los casos en promedio, en2016 y 2017 se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Amenazas han sido Adultos, con el (42)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, las Amenazas se concentraron en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SANTUARIO concentró el 100% de amenazas de 2017 y el 100% de los casos del 2018 estuvo en VRDA. GUAMITOS.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 5 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (20%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 16,7 por CDH, y estuvo por encima en 7,3 puntos de la tasa de TOLIMA y por encima en 10,5 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 88% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (15 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (6) que en 2016,",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Delitos sexuales han sidoNiños, con el (54)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Delitos sexuales se concentró en Niños, con el 50% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días martes con el 33%, en el primer semestre de 2018 se concentro en los MiércolesDomingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 94% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles, Domingo en la jornada Madrugada, Tarde se han presentado el mayor número de eventos de Delitos sexuales con el 33% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 97% de Delitos sexuales se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 6 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS junto con fincas y similares, representando el 100% de los casos presentados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SANTUARIO, CENTRO, VRDA. EL MEZON, LA MARICHUELA E-5, VRDA. CHAPARRO, estos concentraron el 100% de Delitos sexuales de 2017 y el 100% de los casos del 2018 los concentran: VRDA. EL SANTUARIO y .VRDA.LA FILA.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 1 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (0%), superando el promedio de los últimos años en el (los) año(s) de 2013,2014,2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de extorsión por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2,8 por CDH, y estuvo por encima en 2,8 puntos de la tasa de TOLIMA y por encima en 2,8 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 58% de los casos en promedio, en,2014, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (1) que en 2014,2015, ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Extorsión han sido Adultos, con el (70)% de los casos; se observan tendencias crecientes de este delito en,Jóvenes. En el primer semestre de 2018, el Extorsión se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67% de Extorsión se cometen con llamada telefonica. En el primer semestre de 2018 se presenta el uso de directa como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, estos concentraron el 100% de Extorsión de 2017 y la VRDA. MONTECRISTO concentra el 100% de los casos del 2018.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 0 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (200%), superando el promedio de los últimos años en el (los) año(s) de 20152016 en lo que va corrido del año ya superó este promedio. ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 100% de los casos en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a automotores han sido Adultos,Adultos Mayores con el (20)% de los casos; se observan tendencias crecientes de este delito en Adultos y Adultos Mayores. En el primer semestre de 2018, el Hurto a automotores se concentró en adultos Mayores con el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes,Martes,Miércoles,Jueves,Viernes,Sábado,Domingo con el 0%, en el primer semestre de 2018 se concentro en los Martes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada,Mañana,Tarde Noche con el 0% de los casos. Ahora bien en lo corrido del año, los días martes en la jornada,Mañana, se han presentado el mayor número de eventos de Hurto a automotores con el 50% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67% de Hurto a automotores se cometen conarma blanca. En el primer semestre de 2018 se presenta el uso de no reportado intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, . ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 no se presentaron casos, pero en el 2018 el 100% de los casos se concentró en: LA PALMA y VRDA. EL SANTUARIO",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 1 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (300%), superando el promedio de los últimos años en el (los) año(s) de 2013, 2015, 2017, en lo que va corrido del año ya superó este promedio. ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días martes con el 50%, en el primer semestre de 2018 se concentro en los MiércolesJuevesViernesDomingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada,Mañana,Tarde Noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 100% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles,Jueves,Viernes, Domingo en la jornada Madrugada,Mañana,Tarde Noche se han presentado el mayor número de eventos de Hurto a comercio con el 100% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 40% de Hurto a comercio se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 75% de los casos, aumentando 3 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. LA Fila, CENTRO, LA MACARENA, VRDA. BASCONTA Y CENTRO, estos concentraron el 100% de Hurto a comercio de 2017 y el 100% de los casos del 2018 se comentieron en: CENTRO, LA PALMA Y VRDA. LA Fila .",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%). ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 100% de los casos en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a motocicletas han sido Adultos, con el (20)% de los casos;No se observan tendencias crecientes en los diferentesgrupos de edad. En el primer semestre de 2018, el no hubo victimas de Hurto a motocicletas",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes,Martes,Miércoles,Jueves,Viernes,Sábado,Domingo con el 0%, en el primer semestre de 2018 se concentro en los LunesMartesMiércolesJuevesViernesSábadoDomingo con 0 casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Hurto a motocicletas se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 y 2018 no se han presentado casos de este delito, años anteriores se concentro en el CENTRO y en la VRDA. LA PALMA.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 5 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-80%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 7,4 por CDH, y estuvo por debajo en 25,6 puntos de la tasa de TOLIMA y por debajo 30,3 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 62% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a Personas han sido Adultos, con el (75)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Hurto a Personas se concentró en,Jóvenes, con el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 38%, en el primer semestre de 2018 se concentro en los Domingo con 1 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 38% de los casos. Ahora bien en lo corrido del año, los dias, Domingo en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a Personas con el 100% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 79% de Hurto a Personas se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, . ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS junto con cajeros automáticos, representando el 100% de los casos presentados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, ALTO DE LA VIRGen VRDA. EL SANTUARIO y VRDA. CHAPARRO, estos concentraron el 100% deL hurto a Personas del 2017 y el 100% de los casos del 2018 los concentra el CENTRO.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 2 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (200%).",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves , con el 50%, en el primer semestre de 2018 se concentro en los SábadoDomingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de laTarde con el 50% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 50% de los casos. Ahora bien en lo corrido del año, los dias, Miércoles, Viernes,Sábado,Domingo en la jornada Madrugada,Mañana,Tarde se han presentado el mayor número de eventos de Hurto a residencias con el 100% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 30% de Hurto a residencias se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 67% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SANTUARIO, VRDA. MONTE CRISTO Y VRDA LA LAJA, estos concentraron el 100% de Hurto a residencias de 2017 y el 83% de los casos del 2018 se concentra en: VRDA. EL SANTUARIO, LA CAMPIÑA, CENTRO, ALTO DE LA VIRGEN Y VRDA. PARAMITOS ",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Lesiones Personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 14 víctimas en el primer semestre de 2017 a 18 victimas en el 2018 (28,6%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 27,8 por CDH, y estuvo por debajo en 12,1 puntos de la tasa de TOLIMA y por encima en 3 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 60% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (15 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (7) que en 2016,2017",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el (58)% de los casos; se observan tendencias crecientes de este delito en Niños y Jóvenes. En el primer semestre de 2018, el Lesiones Personales se concentró en,Jóvenes, con el 39% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 30%, en el primer semestre de 2018 se concentro en los Domingo con 5 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de laTarde con el 39% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 50% de los casos. Ahora bien en lo corrido del año, los dias Lunes, Domingo en la jornada Tarde Noche se han presentado el mayor número de eventos de Lesiones Personales con el 11% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 67% de Lesiones Personales se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes, como el arma/medio más empleado, con el 39% de los casos, manteniendo 0 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y fincas y similares, representando el 96% de los casos registrados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SANTUARIO, CENTRO, LA MACARENA, LA PALMA, VRDA. LA ESPERANZA, estos concentraron el 80% de Lesiones Personalesde 2017 y el 76% de los casos del 2018 los concentran: VRDA. EL SANTUARIO, CENTRO, VRDA LA FILA, VRDA CUBA y VRDA LA CAFRERIA.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Solo en el 2017 se presentó un caso de piratería terrestre, ha sido en el unico año desde el 2010 que se ha presentado un caso de este delito.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 18 víctimas en el primer semestre de 2017 a 6 victimas en el 2018 (-66,7%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 37 por CDH, y estuvo por encima en 21 puntos de la tasa de TOLIMA y por encima en 18,2 puntos de la tasa del país",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 88% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (62 casos). En el primer semestre de 2018, se ha disminuido en 83% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 36 caso en 2016,a 6 casos en el presente año.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Violencia intrafamiliar han sido Adultos, con el (47)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Violencia intrafamiliar se concentró en Adultos, con el 67% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes, Viernes, con el 40%, en el primer semestre de 2018 se concentro en los Lunes y Jueves con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 50% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 80% de los casos. Ahora bien en lo corrido del año los dias Jueves en la jornada Madrugada se han presentado el mayor número de eventos de Violencia intrafamiliar con el 17% de estos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 92% de Violencia intrafamiliar se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el no reporte de armas como lo más frecuente con el 67% de los casos, a este lo sigue el arma contundente con 1 caso, uno más que el año anterior.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y CAFETERIAS, representando el 97% de los casos registrados en el 2017. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SANTUARIO, CENTRO, VRDA MUNDO NUEVO, VRDA. PIE DE CUESTA ALTO, estos concentraron el 100% de Violencia intrafamiliar de 2017 y en el 2018 la VRDA EL SANTUARIO, OBRERO y VRDA. LA LAJA han concentrado el 100% de los casos.",
      codigoMunicipio: "5ba01244b873c2001384961f",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 7 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-57,1%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 3,3 por CDH, y estuvo por encima en 1,1 puntos de la tasa de TOLIMA y por encima en 0,9 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 92% de los casos en promedio, en 2014 se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Homicidio han sido Adultos, con el (56)% de los casos; se observan tendencias crecientes de este delito en Adultos Mayores. En el primer semestre de 2018, el Homicidio se concentró en Jóvenes, con el 67% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 40%, en el primer semestre de 2018 se concentro en los Sábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 50% de los casos. Ahora bien en lo corrido del año, los dias Lunes y Sábado, en la jornada Madrugada y Noche se han presentado el mayor número de eventos de Homicidio con el 100% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 61% de los Homicidios se cometen con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 67% de los casos, reduciendose en 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio, en el primer semestre de 2018 está por debajo del promedio (2014 - 2017). En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, OSORIO, LA FLORESTA, VRDA.LA ALDEA, VRDA. LOS MANGOS, estos concentraron el 80% de Homicidios del 2017 y el 100% de los casos del 2018 se ha concentrado en: AVENIDA, VRDA. LOS ANDES y la VRDA. ESMERALDAS.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia estable en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%).",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 86% del Abigeato se cometió con intimidación (sin empleo de armas). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. EL SILENCIO y VRDA. SILENCIO, estos concentraron el 100% del abigeato del 2017, en el 2018 no se han presentado casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 3 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (133,3%). Por su parte, la tasa de amenazas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 3,3 por CDH, y estuvo por encima en 3,3 puntos de la tasa de TOLIMA y por encima en 3,3 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 65% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (12 casos). En el primer semestre de 2018, se ha disminuido en 33% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 6 caso en 2016,a 4 casos en el presente año.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Amenazas han sido Adultos, con el (39)% de los casos; se observan tendencias crecientes de este delito en Jóvenes, Adultos y Adultos Mayores. En el primer semestre de 2018, las amenazas se concentraron en Adultos, con el 86% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MARQUETALIA concentra el 100% de Amenazas de 2017 y el 100% de los casos del 2018.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 16 víctimas en el primer semestre de 2017 a 16 victimas en el 2018 (0%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 9 por CDH, y estuvo por debajo en 0,4 puntos de la tasa de TOLIMA y por encima en 2,8 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 97% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (26 casos). En el primer semestre de 2018, se ha disminuido en 13% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 16 caso en 2017 a 14 casos en el presente año.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Delitos sexuales han sidoNiños, con el (55)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Delitos sexuales se concentró en Adultos, con el 69% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Miércoles, con el 22%, en el primer semestre de 2018 se concentro en los Viernes con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 81% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 100% de los casos. Ahora bien en lo corrido del año, los dias, Viernes, en la jornada Madrugada, se han presentado el mayor número de eventos de Delitos sexuales con el 6% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 88% de Delitos sexuales se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 75% de los casos, aumentando 12 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue CASAS DE HABITACIÓN Y ESTABLECIMIENTO EDUCATIVO, representando el 97% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MARQUETALIA, VRDA. LA CUMBRE, CENTRO y LOS PINOS, estos concentraron el 100% de Delitos sexuales de 2017 y el 100% de los casos del 2018 los concentran la: VRDA. MARQUETALIA, CENTRO, LOS MANGOS y DIVINO NIÑO",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 1 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (0%). Por su parte, la tasa de extorsión por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 1 por CDH, y estuvo por encima en 1 puntos de la tasa de TOLIMA y por encima en 1 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 80% de los casos en promedio, en 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Extorsión han sido Adultos, con el (82)% de los casos;No se observan tendencias crecientes en los diferentesgrupos de edad. En el primer semestre de 2018, el Extorsión se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 80% de Extorsión se cometen con llamada telefonica. En el primer semestre de 2018 se presenta el uso de llamada telefónica, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. SAN JOAQUIN, VRDA. BILBAO, estos concentraron el 100% de Extorsión de 2017 y el único caso para el 2018 se presentó en la VRDA. EL JARDIN.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 100% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (1 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a automotores han sido,Adultos Mayores con el (20)% de los casos; se observan tendencias crecientes de este delito en adultos Mayores. En el primer semestre de 2018, el no hubo victimas de Hurto a automotores",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes,Martes,Miércoles,Jueves,Viernes,Sábado,Domingo con el 0%, en el primer semestre de 2018 se concentro en los LunesMartesMiércolesJuevesViernesSábadoDomingo con 0 casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Hurto a automotores se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En 2017 y en el 2018 no se han presentado casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 7 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-57,1%). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Viernes, con el 36%, en el primer semestre de 2018 se concentro en los Domingo con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 64% de los casos. Ahora bien en lo corrido del año, los dias, Viernes, Domingo en la jornada Madrugada, Tarde se han presentado el mayor número de eventos de Hurto a comercio con el 100% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 47% de Hurto a comercio se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de palancas, como el arma/medio más empleado, con el 67% de los casos, . ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. BILBAO, AVENIDA, VRDA. LA CUMBRE, VRDA. LOS ANDES, estos concentraron el 82% de Hurto a comercio de 2017 y el 100% de los casos del 2018 se concentraron en EL CENTRO y en la VRDA. MARQUETALIA.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a entidades financieras en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 0 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (0%). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 4 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (25%). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 92% de los casos en promedio, en 2013,2014, 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (1 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a motocicletas han sido Adultos, con el (71)% de los casos; se observan tendencias crecientes de este delito en Niños, Adultos,Adultos Mayores. En el primer semestre de 2018, el Hurto a motocicletas se concentró en Adultos, con el 60% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 40%, en el primer semestre de 2018 se concentro en los Domingo con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 60% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 100% de los casos. Ahora bien en lo corrido del año, los dias, Domingo en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a motocicletas con el 20% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 72% de Hurto a motocicletas se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de llave maestra no reportado como el arma/medio más empleado, con el 80% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. BILBAO, LOS PINOS, VRDA. LA ESTRELLA. CENTRO y VRDA. PUERTO LIMON, estos concentraron el 100% de Hurto a motocicletas de 2017 y el 100% de los casos del 2018 se han concentrado en: CENTRO, CIUDAD JARDIN, VRDA. MARQUETALIA y VRDA SAN PABLO.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 6 víctimas en el primer semestre de 2017 a 5 victimas en el 2018 (-16,7%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 3,7 por CDH, y estuvo por debajo en 29,3 puntos de la tasa de TOLIMA y por debajo 34,1 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 63% de los casos en promedio, en 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (8 casos). En el primer semestre de 2018, se ha disminuido en 50% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 4 caso en 2016,a 2 casos en el presente año.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a Personas han sido Adultos, con el (67)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Hurto a Personas se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves , con el 36%, en el primer semestre de 2018 se concentro en los Sábado con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 60% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 64% de los casos. Ahora bien en lo corrido del año, los días martes,Viernes,Sábado,Domingo en la jornada Madrugada,Mañana, Noche se han presentado el mayor número de eventos de Hurto a Personas con el 100% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 78% de Hurto a Personas se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 80% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue fincas y similares, y cajero automático, representando el 78% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MARQUETALIA, CENTRO, VRDA. EL DESIERTO, VRDA. MAQUINCAL, VRDA. LA ESTRELLA, estos concentraron el 82% de Hurto a Personas del 2017 y el 100% de los casos del 2018 se concentran en la VRDA. MARQUETALIA y VRDA. SAN MIGUEL.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 9 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (-55,6%). ",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días jueves ,Domingo con el 50%, en el primer semestre de 2018 se concentro en los Viernes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 100% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 50% de los casos. Ahora bien en lo corrido del año, los dias, Viernes, en la jornada Madrugada, se han presentado el mayor número de eventos de Hurto a residencias con el 25% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 54% de Hurto a residencias se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 75% de los casos, aumentando 3 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, LA FLORESTA, AEROPUERTO, VRDA. EL CORAZON, VRDA. EL CAIMAN, estos concentraron el 58% de Hurto a residencias de 2017 y el % de los casos del 2018 se concentraron en: VRDA. MARQUETALIA, EL PORVENIR y CIUDAD JARDÍN.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Lesiones Personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 39 víctimas en el primer semestre de 2017 a 37 victimas en el 2018 (-5,1%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 22 por CDH, y estuvo por debajo en 17,9 puntos de la tasa de TOLIMA y por debajo 2,8 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 55% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (35 casos). En el primer semestre de 2018, se ha aumentado en 6% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 18 caso en 2017 a 19 casos en el presente año.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el (49)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Lesiones Personales se concentró en Adultos, con el 70% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 30%, en el primer semestre de 2018 se concentro en los LunesDomingo con 20 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 73% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 64% de los casos. Ahora bien en lo corrido del año, los dias Lunes, en la jornada Madrugada, se han presentado el mayor número de eventos de Lesiones Personales con el 3% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en Motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60% de Lesiones Personales se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes, como el arma/medio más empleado, con el 54% de los casos, disminuyendo 3 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue fincas y similares, y bares, cantinas o similares; representando el 88% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, VRDA. MARQUETALIA, VRDA. SAN MIGUEL, VRDA. LOS ANDES, LA PISTA, estos concentraron el 79% de Lesiones Personales de 2017 y el 73% de los casos del 2018 se concentraron en: VRDA. MARQUETALIA, VRDA. BILBAO, CENTRO, VRDA. LA AURORA y AVENIDA.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 0 victimas en el 2018 (-100%). Desde 2010 no se presentaba un caso de piratería terrestre, fue en el 2017 cuando volvieron a aparecer este delito con 2 casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Solo en el 2015 se presentó un caso de secuestro, desde ese año no se ha presentado un caso de secuestro en el municipio.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 10 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (-30%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes viene en aumento, para 2017 la tasa del municipio fue de 7 por CDH, y estuvo por debajo en 9,1 puntos de la tasa de TOLIMA y por debajo 11,8 puntos de la tasa del país",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se ha concentrado en las mujeres con el 91% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (19 casos). En el primer semestre de 2018, se ha disminuido en 33% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 9 casos en 2017a 6 casos en el presente año.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Violencia intrafamiliar han sido Adultos, con el (55)% de los casos; se observan tendencias crecientes de este delito en Niños, Jóvenes y Adultos. En el primer semestre de 2018, el Violencia intrafamiliar se concentró en Adultos, con el 86% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, Viernes, con el 48%, en el primer semestre de 2018 se concentro en los Lunes con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 86% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 90% de los casos. Ahora bien en lo corrido del año, los dias Lunes en la jornada Madrugada, se han presentado el mayor número de eventos de Violencia intrafamiliar con el 14% de estos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 61% de Violencia intrafamiliar se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de la intimidación como el medio más usado con 7 casos menos que el año anterior y recoge el 14% de los casos.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue en importancia las casas de habitación y los establecimiento educativo, representando el 100% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. MARQUETALIA, VILLA ESPERANZA, 9 DE FEBRERO, estos concentraron el 100% de Violencia intrafamiliar de 2017 y el 100% de los casos del 2018 los concentra la VRDA. MARQUETALIA, VRDA. SAN ISIDRO Y CENTRO.",
      codigoMunicipio: "5ba011c8b873c20013849612",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 5 víctimas en el primer semestre de 2017 a 2 victimas en el 2018 (-60%). Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2,9 por CDH, y estuvo por encima en 0,7 puntos de la tasa de TOLIMA y por encima en 0,4 puntos de la tasa del país",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 85% de los casos en promedio, en 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (3 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Homicidio han sido Jóvenes con el (49)% de los casos; se observan tendencias crecientes de este delito en Adultos Mayores. En el primer semestre de 2018, el Homicidio se concentró en Adultos y Adultos Mayores con el 100% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 86%, en el primer semestre de 2018 se concentró en los Martes y viernes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Tarde y Noche con el 100% de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 43% de los casos. Ahora bien en lo corrido del año, los dias Martes y Viernes en la jornada Tarde y Noche se han presentado el mayor número de eventos de Homicidio con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 48% de Homicidio se cometen con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, arma de fuego como el arma/medio más empleado, con el 100% de los casos, reduciendose frente al mismo período del año anterior en 3 casos en el arma blanca y manteniendose en el arma de fuego con un caso.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LA HERRERA, VRDA. MARACAIBO, SITIO BOCAS, CORREGIMMIENTO LA HERRERA, VRDA. LAS MERCEDES, estos concentraron el 86% de Homicidios del 2017.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia estable en el número de casos de Abigeato en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, manteniendo en 0 casos en el 2018. ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Abigeato se cometen con intimidación (sin empleo de armas). ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO que concentró el 100% del abigeato del 2017.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 8 víctimas en el primer semestre de 2017 a 12 victimas en el 2018 (50%). ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 53% de los casos en promedio, en,2014,2017, se presenta el mayor número de casos de mujeres víctimas de este delito (7 casos). En el primer semestre de 2018, se ha aumentado en 29% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 7 caso en 2014,a 9 casos en el presente año.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Amenazas han sido Adultos, con el (30)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, las amenazas se concentraron en,Jóvenes,Adultos, con el 83% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO concentró el 100% de Amenazas del 2017 y el 100% de los casos del 2018 los concentra la VRDA. CAMPO HERMOSO, VRDA. BOQUERON y CENTRO .",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 6 víctimas en el primer semestre de 2017 a 7 victimas en el 2018 (16,7%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes permanece en equilibrio, para 2017 la tasa del municipio fue de 6,6 por CDH, y estuvo por debajo en 2,8 puntos de la tasa de TOLIMA y por encima en 0,4 puntos de la tasa del país",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 98% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (16 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (6) que en,2017",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Delitos sexuales han sidoNiños, con el (57)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Delitos sexuales se concentró en Niños, con el 100% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Sábado con el 44%, en el primer semestre de 2018 se concentro en los Lunes y Miércoles con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 57% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 88% de los casos. Ahora bien en lo corrido del año, los dias Lunes, Miércoles, Viernes,Sábado y Domingo en la jornada Madrugada,Mañana y Tarde se han presentado el mayor número de eventos de Delitos sexuales con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 84% de Delitos sexuales se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 43% de los casos, aumentando 3 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue casas de habitación y fincas y similares, representando el 97% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO, CENTRO, estos concentraron el 100% de Delitos sexuales de 2017 y el 100% de los casos del 2018 lo concentran las mismas veredas agregando los barrios/veredas de: VRDA. LIMONES Y SAN JORGE .",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 3 víctimas en el primer semestre de 2017 a 1 victimas en el 2018 (-66,7%). ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 67% de los casos en promedio, en 2015 y 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (2 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Extorsión han sido Adultos, con el (100)% de los casos; se observan tendencias crecientes de este delito en Adultos. En el primer semestre de 2018, el Extorsión se concentró en adultos Mayores con el 100% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 56% de Extorsión se cometen con llamada telefonica. En el primer semestre de 2018 se presenta el uso de llamada telefónica, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CORREGIMIENTO LA HERRERA y CENTRO, estos concentraron el 100% de Extorsión de 2017 y el 100% de los casos del 2018 se concentran unicamente en el CENTRO.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza ya que no hay cambio en el numero de casos",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 100% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (1 casos). En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a automotores han sido Adultos, con el (20)% de los casos; se observan tendencias crecientes de este delito en Adultos. En el primer semestre de 2018, el no hubo victimas de Hurto a automotores",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias Lunes,Martes,Miércoles,Jueves,Viernes,Sábado,Domingo con el 0%, en el primer semestre de 2018 se concentro en los LunesMartesMiércolesJuevesViernesSábadoDomingo con 0 casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Hurto a automotores se cometen conllave maestra. En el primer semestre de 2018 se presenta el uso de como el arma/medio más empleado, con el 0% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 y 2018 no se han presentado casos de hurto a automotores, sin embargo en 2016 caundo se presentó el unico caso sucedió en el centro.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 4 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (0%), superando el promedio de los últimos años en el (los) año(s) de 2013,2014, 2016,2017, en lo que va corrido del año ya superó este promedio. ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días martes con el 75%, en el primer semestre de 2018 se concentro en los Martes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 75% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 100% de los casos. Ahora bien en lo corrido del año, los días martes,Miércoles, Viernes, en la jornada Madrugada,Mañana, se han presentado el mayor número de eventos de Hurto a comercio con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53% de Hurto a comercio se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 50% de los casos, . ",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio, en el primer semestre de 2018 esta igual que promedio anual (2014 - 2017). En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO, VRDA. SAN JORGUE y EL CENTRO, estos concentraron el 100% de Hurto a comercio de 2017 y el 100% de los casos del 2018 los ha concentrado: VRDA. VISTA HERMOSA, VRDA. CAMPO HERMOSO, VRDA. PUERTO SALDAÑA y LAS BRISAS.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia decreciente en el número de casos de Hurto a motocicletas en el municipio. En los dos ultimos años no se han presentado casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 100% de los casos en promedio, no se presentan casos de mujeres víctimas de este delito. En el primer semestre de 2018, no se han presentado casos de mujeres victimas de este delito.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a motocicletas han sido Adultos, con el (43)% de los casos; se observan tendencias crecientes de este delito en Adultos Mayores. En el primer semestre de 2018, el no hubo victimas de Hurto a motocicletas",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion: "En el 2017 no se presentaron casos de hurto a motocicletas",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 100% de Hurto a motocicletas se cometen conllave maestra.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 y 2018 no se han presentado casos de este delito, en el 2016 que se presentó el último caso fue en le VRDA. CAMPOHERMOSO",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a Personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se mantiene estable, pasando de 3 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (33,3%). Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2,1 por CDH, y estuvo por debajo en 30,9 puntos de la tasa de TOLIMA y por debajo 35,7 puntos de la tasa del país",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 61% de los casos en promedio, en2016 se presenta el mayor número de casos de mujeres víctimas de este delito (7 casos). En el primer semestre de 2018, se ha aumentado en 100% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 1 caso en 2016,2017a 2 casos en el presente año.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Hurto a Personas han sido Adultos, con el (64)% de los casos; se observan tendencias crecientes de este delito en todos los grupos etarios. En el primer semestre de 2018, el Hurto a Personas se concentró en Adultos, con el 100% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias martes con el 40%, en el primer semestre de 2018 se concentro en los Viernes con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada,Mañana,Tarde Noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la Mañana, con el 60% de los casos. Ahora bien en lo corrido del año, los dias martes,Viernes y Sábado, en la jornada madrugada, Mañana, Tarde y Noche se han presentado el mayor número de eventos de Hurto a Personas con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 68% de Hurto a Personas se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de intimidación (sin empleo de armas), como el arma/medio más empleado, con el 50% de los casos, manteniendose igual que el mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, seguido por oficinas y bares cantinas o similares; representando el 97% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, LA ESPERANZA, VRDA. EL DIAMANTE, LAS BRISAS, estos concentraron el 100% de Hurto a Personasde 2017 y el 100% de los casos del 2018 se ha concentrado en: VRDA. EL DIAMANTE VRDA. CAMPOHERMOSO, VRDA. BELLAVISTA y BELLO HORIZONTE.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 9 víctimas en el primer semestre de 2017 a 3 victimas en el 2018 (-66,7%).",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días martes y miercoles con el 60%, en el primer semestre de 2018 se concentro en los JuevesViernesSábado con 3 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 67% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 70% de los casos. Ahora bien en lo corrido del año, los días jueves ,Viernes,Sábado, en la jornada Madrugada,Noche se han presentado el mayor número de eventos de Hurto a residencias con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 58% de Hurto a residencias se cometen con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 67% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: BELLO HORIZONTE, LAS BRISAS, VRDA. LA PRIMAVERA, LA HERRERA, VRDA. QUEBRADON, estos concentraron el 90% de Hurto a residencias de 2017 y el 100% de los casos del 2018 se han concentrado en: VRDA. CAMPOHERMOSO, VRDA. LA MARMAJA Y VRDA. BOQUERON.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia estable en el número de casos de Lesiones Personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 24 víctimas en el primer semestre de 2017 a 26 victimas en el 2018 (8,3%). Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 18,5 por CDH, y estuvo por debajo en 21,4 puntos de la tasa de TOLIMA y por debajo 6,3 puntos de la tasa del país",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en los hombres con el 69% de los casos en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (16 casos). En el primer semestre de 2018, se ha aumentado en 17% los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 6 caso en 2014 y 2015 a 7 casos en el presente año.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Lesiones Personales han sido Adultos, con el (51)% de los casos; se observan tendencias crecientes de este delito en Adultos y Adultos Mayores. En el primer semestre de 2018, el Lesiones Personales se concentró en Adultos, con el 58% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 38%, en el primer semestre de 2018 se concentro en los Domingo con 8 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 58% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 53% de los casos. Ahora bien en lo corrido del año, los dias, Domingo en la jornada Madrugada, se han presentado el mayor número de eventos de Lesiones Personales con el 4% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 69% de Lesiones Personales se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes, como el arma/medio más empleado, con el 69% de los casos, reduciendose en 4 casos frente al mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue fincas y similares, y bares, cantinas y similares; representando el 91% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO, CENTRO, CORREGIMMIENTO LA HERRERA, VRDA. EL DIAMANTE, VRDA. LA BRECHA, estos concentraron el 76% de Lesiones Personalesde 2017 y el 85% de los casos del 2018 se concentra en: EL CENTRO, CORREGIMMIENTO LA HERRERA, VRDA. CAMPO HERMOSO, VRDA. LA ESMERALDA y VRDA. ALTO PALICHAL",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Solo en el 2017 se presentó un caso de piratería terrestre, es el único evento desde el 2010 que se ha presentado un caso de este delito.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion: "No se han presentado casos",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017) se registra una tendencia creciente en el número de casos de Violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se estabiliza, pasando de 4 víctimas en el primer semestre de 2017 a 4 victimas en el 2018 (0%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 2,9 por CDH, y estuvo por debajo en 13,2 puntos de la tasa de TOLIMA y por debajo 15,9 puntos de la tasa del país",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos años (2013 - 2017), este delito se han concentrado en las mujeres con el 87% de los casos en promedio, en 2015 y 2016 se presenta el mayor número de casos de mujeres víctimas de este delito (6 casos). En el primer semestre de 2018, se han presentado el mismo número de casos (4) que en 2014,",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos años (2013-2017) la mayor parte de las víctimas de Violencia intrafamiliar han sido,Jóvenes, con el (46)% de los casos; se observan tendencias crecientes de este delito en Jóvenes y Adultos. En el primer semestre de 2018, el Violencia intrafamiliar se concentró en Adultos, con el 75% de los casos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los días Domingo con el 57%, en el primer semestre de 2018 se concentro en los Lunes, Jueves, Sábado y Domingo con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la Madrugada, con el 100% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la Madrugada, con el 86% de los casos. Ahora bien en lo corrido del año, los dias Lunes, Jueves, Sábado y Domingo en la jornada Madrugada, se han presentado el mayor número de eventos de Violencia intrafamiliar con el 100% de estos.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 44% de Violencia intrafamiliar se cometen con objetos contundentes. En el primer semestre de 2018 se presenta el uso de no reportado como el arma/medio más empleado, con el 100% de los casos, aumentando 4 caso frente al mismo período del año anterior",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "El principal tipo de sitio de ocurrencia de este delito ha sido las VIAS PÚBLICAS, lo sigue fincas y similares, y casas de habitación; representando el 97% de los casos registrados desde el 2010. Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VRDA. CAMPO HERMOSO y VRDA. QUEBRADON, estos concentraron el 100% de Violencia intrafamiliar de 2017 y el 100% de los casos del 2018 los concentró la VRDA. CAMPO HERMOSO y el CENTRO.",
      codigoMunicipio: "5ba01229b873c2001384961c",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: GALAN, LA BANCA, BUENOS AIRES, LA FLORESTA, MANZANILLO Y SANTA MARTA, estos concentraron el 42% de homicidios de 2017 y el 38% de los casos del 2018 los homicidios se concentraron en: SANTA MARTA, CAMPOALEGRE, LA MADRE, PUERTO CALAVERA y LIBORIO BATALLER, los cuales fueron los principales barrios. ",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion: "no ha presentados casos",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: APORRIADO, GALAN Y EL CASTILLO, estos concentraron el 100%% de Amenazas de 2017 y el 100% de los casos del 2018 los concentra el APORRIADO.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PARQUE PRINCIPAL, SAN BARTOLO, CENTENARIO, BUENOS AIRES Y GAITAN, estos concentraron el 63% de extorsión de 2017 y CENTENARIO concentra el 100% de los casos del 2018.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SANTA ISABEL concentra el 100% de Hurto a automotores de 2017. En el 2018 no se han presentado casos.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LA ARBOLEDA, MARQUETALIA , CAMPOALEGRE, APORRIADO Y LA ESMERALDA, estos concentraron el 45% de hurto a comercios de 2017 y APORRIADO, EL TEJAR, 13 DE MAYO, EL POLVERO y EL MANZANILLO concentra el 100% de los casos del 2018.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CUATRO ESQUINAS, APORRIADO, GALAN, BOLIVAR Y EL TIGRITO, estos concentraron el 40% de hurto a motocicletas de 2017 y los barrios/veredas de: EL APORRIADO, EL TIGRITO, BATACLAN, LOS ANGELES Y FRAGUAS concentra el 52% de los casos del 2018.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CUATRO ESQUINAS, EL APORRIADO, CRISTALINA, LA REINA, VILLANUEVA, estos concentraron el 38% de Hurto a residencias de 2017. Por otra parte, el 100% de los casos del 2018 acontecieron en EL APORRIADO y en EL BOLSILLO.",
      codigoMunicipio: "5ba01231b873c2001384961d",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: EL LLANO CAÑAVERAL, PALERMO Y ALTO DE SABANAS, ALTO DE RAYO Y RIO ARRIBA, estos concentraron el 71% de Homicidios de 2017 y el 100% de los casos del 2018 han acontecido en: LA FRANCIA y LA CIENAGA.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio, en el primer semestre de 2018 esta por debajo del promedio (2014 - 2017). En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron:LOS LLANITOS Y LA HONDA; estos concentraron el 100% de abigeato de 2017. Por otra parte, LA QUIEBRA SAN PABLO concentra el 100% de los casos del 2018.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LOS LLANITOS Y BARRIO COLOMBIA, estos concentraron el 100% de amenazas de 2017 y el 100% de los casos del 2018 se concentran en: SAN FRANCISCO.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "En cuanto a la ubicación, en 2019 no se presetaron casos de extorsión, pero en 2018 se concentraron el 100% de los casos en el barrio centro.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando de igual forma en la zona rural y en la zona urbana del municipio. En cuanto a la ubicación, en 2019 no se presentaron casos,pero en el 2018 el único caso que se presentó sucedió el el barrio/vereda LOS LLANITOS.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PARQUE PRINCIPAL, LOS LLANITOS, SIRGUITA, EL TRIGAL Y LOS MEDIOS; estos concentraron el 60% de Hurto a comercio de 2017. Por otra parte, el 100% de los casos del 2018 se han presentado en: LOS LLANITOS, GUAYABAL RIO ARRIBA, ALTO DE SABANAS, BARRIO CENTRO y VENTIADEROS",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LA HONDA, LLANADAS ARRIBA, LIMONES, BARRIO CENTRO Y SAN ANTONIO, estos concentraron el 71% de Hurto a motocicletas de 2017 y el 100% de los casos del 2018 se han presentado en LA MONA, MANZANARES ABAJO y SAN JOSÉ.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: LA CALZADA, MANZANARES ABAJO, LIMONES, ALTO DE SABANAS Y TAPETE, estos concentraron el 54% de Hurto a residencias de 2017 y el 60% de los casos del 2018 ha estado  concentrado en: MANZANARES ABAJO, SALOMÉ, LA CAPILLA, COUPEDEN y NARRANJAL ARRIBA.",
      codigoMunicipio: "5ba011f9b873c20013849617",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: EL DOCE, LAS ACACIAS, EL PARAISO, LA CAUCADA y ALTO DE POPALES; estos concentraron el 40% de homicidios de 2017. Respecto al 2018, 36% de los casos se concentraron en: PIEDRAS, PUERTO ANTIOQUIA, LAS PALMAS, EL RAYO y PAVAS.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En 2017 no se presentaron casos de abigeato, pero respecto a la ubicación en el 2018 se concentro en PUERTO OSPINA, KILOMETRO 3 AL 10 Y TESORITO, concentrando el 100% de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 el barrio/vereda que concentró todos los casos fue PUERTO ANTIOQUIA, con el 100% de las amenazas. El 100% de los casos del 2018 han sido en PUERTO ANTIOQUIA y en VILLA DEL LAGO.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VILLA DEL LAGO, CENTRO, estos concentraron el 100% de Extorsión de 2017 y el 100% de los casos del 2018 se concentra en PARQUE PRINCIPAL, SECTOR TENERIFE, ABEJAL Y EL RETIRO.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en el 2017 y en el 2018 no se han presentado casos, sin embargo en el 2016 el caso único que aconteció se dio en el barrio/vereda EL DOCE.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SAN NICOLAS, VILLA DEL LAGO, BEJUQUILLO , EDUARDO CORREA y el PURY; estos concentraron el 75% de Hurto a comercio de 2017. En el 2018 se ha concentrado en los barrios/veredas de: PUERTO OSPINA,PAVAS, VISTA HERMOSA, CURUMANA y PUERTO ANTIOQUIA, estos tienen el 100% de los casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: MARIA GAID, SAN NICOLAS, LA AVENIDA, SAN AGUSTIN y LA CABAÑA; estos concentraron el 67% de Hurto a motocicletas de 2017. Respecto a los casos del 2018 se han concentrado en EL DOCE, KILOMETRO 3 AL 10, NUEVO MILENIO y LAS PALMAS, con el 100% de lo casos.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VILLA DEL LAGO, PUERTO ANTIOQUIA, GARZON y LA LUCHA; estos concentraron el 100% de Hurto a residencias de 2017. En el 2018 no se han presentado casos de hurto a residencias.",
      codigoMunicipio: "5bad41a4ef476d001358246f",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, CORREGIMIENTO LLORENTE, VRDA. TANDIR, CORREGIMIENTO LA GUAYACANA, UNION VICTORIA, CORREGIMIENTO CHILVI, estos concentraron el 15% de homicidios de 2017. Respecto al 2018, el 24% de los casos de homicidios se han concentrado en: CORREGIMIENTO LLORENTE, EL MORRITO, CORREGIMIENTO LA GUAYACANA, PANAMA Y VRDA VALLENATO.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 el barrio/ vereda que concentro todos los casos de abigeato fue el de AVENIDA FERREA. En el 2018 no se han presentado casos",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: OBRERO, CORREGIMIENTO LLORENTE, AVENIDA LA PLAYA, UNION VICTORIA, CALLE MOSQUERA, estos concentraron el 35% de amenazas de 2017 y el 26% de los casos del 2018 se han presentado en: OBRERO, CORREGIMIENTO LLORENTE, LA CIUDADELA, CALLE DEL COMERCIO y LA AVENIDA LA PLAYA.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CORREGIMIENTO LLORENTE, URBANIZACION MIRAMAR, UNION VICTORIA, BRISAS DEL MAR y EL BAJITO, estos concentraron el 16% de extorsión del 2017. Respecto al 2018, el 46% de los casos se han presentado en LA CATORCE, VRDA. CHINCHAJO, AVD. LA PLAYA, LAS MERCEDES y LA PLAYITA.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: UNION VICTORIA, AIRE LIBRE y el CORREGIMIENTO LLORENTE, estos concentran el 100% de Hurto a automotores de 2017 y el 100% de los casos del 2018 se ha concentrado en VRDA CANDELILLAS, LA GUAYACANA, CIUDAD 2000 y OBRERO.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CALLE MOSQUERA, CALLE SUCRE, CALLE NUEVA CREACION, VRDA CANDELILLAS y la CALLE DEL COMERCIO, estos concentraron el 34% del Hurto a comercio de 2017. En el 2018 estuvo concentrado en un 38% en los barrios/veredas de: AVENIDA DE LOS ESTUDIANTES, CALLE DEL COMERCIO, CORREGIMIENTO LLORENTE, OBRERO y SAN JUDAS.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CORREGIMIENTO LLORENTE, AVENIDA LOS ESTUDIANTES, OBRERO, LA CARBONERA, SAN JUDAS, estos concentraron el 39% de Hurto a motocicletas de 2017. Por su parte en el 2018 se concentró en: AVENIDA LOS ESTUDIANTES, AVENIDA LA PLAYA, SAN JUDAS, CORREGIMIENTO LLORENTE y OBRERO, estos concentraron el 34% de los casos en lo corrido de los primeros 6 meses.",
      codigoMunicipio: "#N/A",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: OBRERO, PUENTE LAS FLORES, UNION VICTORIA, AVENIDA LA PLAYA, PUENTE DEL PROGRESO, estos concentraron el 30% de Hurto a residencias de 2017 y en el 2018 se ha venido concentrado en: AVENIDA DE LOS ESTUDIANTES, OBRERO, CALLE DEL COMERCIO, ANZOATEGUI y PUENTE DE LAS FLORES, estos concentraron el 28% de los casos.",
      codigoMunicipio: "#N/A",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PUERTO RAUDAL, LA PAULINA, CHAVELINO, SAN FERMINO y LA YE, los cuales concentran el 54% de homicidios del 2017 y en el 2018 se ha concentrado en los barrios/veredas de: PESCADO, ZORRAS, LA PAULINA, EL QUINCE y LA YE, estos tuvieron el 50% de los casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 el único barrio/vereda que presento todos los casos fue SAN FERMIN el cual concentró el 100% de abigeato del 2017, en el 2018 no se han presentados casos de este delito.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 el barrio/vereda PUERTO OSPINA concentró el 100% de amenazas de 2017 y el 100% de los casos del 2018 se concentran en: SANTA INES, NEVADO y PARQUE PRINCIPAL .",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: VALDIVIA y PARQUE PRINCIPAL, estos concentraron el 100% de casos de extorsión del 2017 y el 100% de los casos del 2018 se concentraron junto con VALDIVIA en: EL CENTENARIO y CHORRILLOS.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 el SOCORRO concentró el 100% de Hurto a automotores y en 2018 no se han presentado casos.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: SANTA INES, PUERTO RAUDAL, VALDIVIA, EL QUINCE, SAN FERMIN, estos concentraron el 63% de casos de hurto a comercio del 2017 y PUERTO OSPINA ha concentrado el 100% de los casos del 2018.",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PUERTO RAUDAL, PESCADO, PUERTO OSPINA, LA PAULINA, LA BOMBA, estos concentraron el 100% de Hurto a motocicletas de 2017 y el 100% de los casos del 2018 se concentraron en las veredas/barrios de: PESCADO, NEVADO y VALDIVIA",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: PALENQUEROS y LA ALEMANIA, estos concentraron el 100% de Hurto a residencias de 2017 y el 100% de los casos del 2018 se concentro en las veredas/barrios de: PUERTO OSPINA y LAS AMERICAS ",
      codigoMunicipio: "5bad4171ef476d001358246e",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del homicidio en el municipio: \nDesde el 2016 se registra una tendencia creciente en el número de víctimas de homicidio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 25 víctimas en el primer semestre de 2017 a 32 victimas en el 2018 (+28%). \nPor su parte, en 2017, la tasa de homicidios por cada 10.000 habitantes fue de 2.9. Esta tasa es consistente con la tasa de homicidios de ANTIOQUIA, sin embargo, está 0.5 puntos por encima de la tasa del país.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del homicidio por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en los hombres. Los hombres representan cerca del 90% de las víctimas. \nEn el primer semestre de 2018 se mantiene este comportamiento.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del homicidio por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de homicidio han sido adultos y jóvenes. Estos grupos de edad concentran más del 60% de las víctimas. \nEn el primer semestre de 2018, el homicidio se concentró en jóvenes; con el 50% de los casos. En 2017 aumentó de forma significativa la proporción de niños, niñas y adolescentes que son víctimas de este delito.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del homicidio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los viernes con el 24% de los casos.  En el primer semestre de 2018 el delito se concentró en los martes y sábados. \nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la tarde, con el 35% de los casos. En contraste, en el 2017 el delito se presentó en mayor proporción durante noche (31%).\nAhora bien, en lo corrido del año, las tardes de los domingos y miércoles presentan la mayor concentración de casos (3 casos).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del homicidio según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, la mayoría de los homicidios se han cometido a pie. En el primer semestre de 2018, aumenta la proporción de casos en los que el agresor es pasajero de motocicleta.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 70% de los homicidios se han cometido con arma de fuego.\nEn el primer semestre de 2018 las armas más utilizadas fueron el arma de fuego y el arma blanca; concentran el 90% de los casos. ",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del homicidio por zona y barrio en el municipio:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: OBRERO, POLICARPA, SALCIPUEDES, CUATRO DE JUNIO, y SERRANIAS; estos barrios concentraron el 40% de los homicidios. En el primer semestre de 2018, los barrios que presentaron el mayor número de casos fueron: OBRERO, SALCIPUEDES, FUNDADORES, VENITE DE ENERO y el CGTO. SAN JOSÉ DE APARTADÓ (concentran el 51% de los casos).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las lesiones personales en el municipio:\nDesde el 2015 se registra una tendencia creciente en el número de víctimas de lesiones personales en el municipio. No obstante, en 2017 se revierte esta tendencia. En el primer semestre de 2018, el número de víctimas disminuye con respecto al comparativo semestral de 2017: pasando de 149 víctimas a 104 (-30%).\nPor su parte, la tasa de lesiones personales por cada 10.000 habitantes en 2017 fue de 14.7,  y estuvo 6.4 puntos por debajo en de la tasa de ANTIOQUIA y  10 puntos por debajo de la tasa del país.\n\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las lesiones personales se han presentado en mayor proporción en mujeres (53%). \nEn el primer semestre de 2018, la proporción de hombres que son víctimas aumenta al 51%. Esta proporción es superior al promedio de los últimos cuatro años.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de lesiones personales han sido adultos, seguidos por jóvenes (en conjunto concentran más del 60% de las víctimas). En 2017, el porcentaje de niños, niñas y adolescentes que fue víctima de este delito aumentó de forma significativa. \nEn el primer semestre de 2018, las lesiones personales se concentraron en adultos, con el 66% de los casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales según día de semana y franja horaria en que se presentan:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos. En el primer semestre de 2018 el delito se concentró en los domingos (23%), seguido por los lunes (21%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la madrugada con el 42% de los casos. En 2017, el delito también se presentó en mayor proporción durante la noche (61%).\nAhora bien, en lo corrido del año, el domingo en la madrugada presenta el mayor número de casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las lesiones personales por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, el 60% de las lesiones personales se han cometido con objetos contundentes.\nEn el primer semestre de 2018,  el arma más empleada continúan siendo son los objetos contundentes, con el 54% de los casos; el segundo tipo de arma más empleada son las armas blancas (13%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de las lesiones personas por zona, clase de sitio y barrio en el municipio:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en establecimientos educativos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, OBRERO, FUNDADORES, ALFONSO LOPEZ, y PUEBLO NUEVO; estos barrios concentraron el 79% de las lesiones personales. En el primer semestre de 2018, el barrio VILLA DEL RIO CASAS BLANCAS ha registrado el mayor número de casos (29%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de los delitos sexuales en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de víctimas de delitos sexuales en el municipio. En el primer semestre de 2018, el número de víctimas disminuyó con respecto al comparativo semestral de 2017, pasando de 53 víctimas a 34 (35%).\nPor su parte, la tasa de homicidios por cada 10.000 habitantes en 2017 fue de 6; esta tasa estuvo 0.04 puntos por debajo de la tasa nacional, y 0.06 puntos por debajo de la tasa departamental.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 88% de las víctimas han sido mujeres. \nEn el primer semestre de 2018, el delito se sigue concentrando en mujeres pero, la proporción de hombres víctimas aumenta al 15%. Esta proporción es superior al promedio de los últimos cuatro años.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años, los delitos sexuales se han venido concentrando en niños, niñas y adolescentes (70%).\nEn el primer semestre de 2018, los delitos sexuales se concentraron en niños, niñas y adolescentes (45% ). El segundo grupo de edad más afectado es el de adultos (40%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los jueves y viernes. En el primer semestre de 2018, se concentró en los jueves (25%) y sábados (23%). \nCon respecto a la franja horaria, en el primer semestre de 2018 los delitos sexuales se concentraron principalmente en la franja de la madrugada (37%), seguida por la franja de la tarde (28%). Esta concentración es consistente con la que se presentó en 2017 (73% de los casos ocurrieron en la madrugada).\nAhora bien, en lo corrido del año, el día sábado en la tarde ha presentado el mayor número de casos.\n\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según características de movilidad de la víctima y tipo de arma:\nDurante los últimos cinco años, la mayoría de las víctimas de delitos sexuales se movilizaban pie. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto al tipo de arma se observa que en los últimos cinco años, más del 70% de los casos de delitos sexuales se cometieron con intimidación (sin empleo de armas). El segundo tipo de arma más utilizado son los objetos contundentes.\nEn el primer semestre de 2018 aumentó la proporción de casos en los que se utilizaron objetos contundentes (29% de los casos).\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de los delitos sexuales según sitio, zona y barrio en que se presenta:\nDesde el 2014 este delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 el barrio VILLA DEL RIO CASAS BLANCAS registró el mayor número de casos; concentró el 86% de los casos (94 casos). En el primer semestre de 2018, los cinco barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, OBRERO, EL SALVADOR, ALFONSO LOPEZ, y EL AMPARO; estos barrios concentran el 69% de los casos.\n\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la violencia intrafamiliar en el municipio:\nDesde el 2016 se registra una tendencia decreciente en el número de víctimas de violencia intrafamiliar en el municipio. Con respecto primer semestre de 2018, se evidencia que este tendencia se mantiene, pasando de 128 víctimas en el primer semestre de 2017 a 23 victimas en el 2018 (-82%).\nPor su parte, la tasa de violencia intrafamiliar por cada 10.000 en 2017 fue de 12, y estuvo 3.3 puntos por debajo de la tasa de ANTIOQUIA y 7.11 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por sexo de la víctima en el municipio:\nDurante los últimos cinco años este delito se ha concentrado principalmente en las mujeres.  En promedio, el 88% de las víctimas han sido mujeres. \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de violencia intrafamiliar han sido adultos (39%). Sin embargo, los jóvenes también tienen una participación importante en el total de víctimas. Así mismo, en 2017 se observa un incremento en la proporción de niños, niñas y adolescentes que son víctimas. \nEn el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos (67%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los lunes (22%). En el primer semestre de 2018 el delito se concentró en los domingos (22%), seguidos por los martes (20%).\nCon respecto a la franja horaria, en el primer semestre de 2018 la violencia intrafamiliar se concentró en la franja de la mañana (el 33% de los casos). En 2017, el delito  se presentó en mayor proporción durante la madrugada (72% de los casos).\nAhora bien, en lo corrido del año, el martes en la noche ha registrado el mayor número de casos. ",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en el 45% de los casos de violencia intrafamiliar no se emplearon armas. El arma más utilizada son los objetos contundentes (40%).  \nEn el primer semestre de 2018, en el 56% de los casos se utilizaron objetos contundentes. ",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la violencia intrafamiliar según sitio, zona y barrio en que se presenta:\nDurante los últimos cinco años, la violencia intrafamiliar se ha venido concentrando en la zona urbana y en las vías públicas del municipio.\nEn cuanto a la ubicación, en 2019 el barrio VILLA DEL RIO CASAS BLANCAS concentró el mayor número de casos; concentró el 68% de los casos. En el primer semestre de 2018 los barrios con el mayor número de casos fueron:VILLA DEL RIO CASAS BLANCAS, OBRERO, ALFONSO LOPEZ, CGTO. SAN JOSE DE APARTADO, y CONCEJO; estos barrios concentraron el 65% de los casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a personas en el municipio:\nDurante los últimos tres años se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto primer semestre de 2018, se evidencia una disminución en el número de víctimas, pasando de 179 víctimas en el primer semestre de 2017 a 144 en el 2018 (-20%).\nPor su parte, la tasa de hurto a personas por cada 10.000 habitantes en 2017 fue de 16. Esta tasa estuvo 22 puntos por debajo de la tasa de ANTIOQUIA y 21 puntos por debajo de la tasa del país.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el delito se ha distribuido de forma similar entre hombres y mujeres. En el primer semestre de 2018, aumenta la proporción de hombres víctimas.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a personas por grupo de edad en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de hurto a personas han sido adultos (en promedio, representan el 52% de las víctimas). \nEn el primer semestre de 2018, se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los jueves (el 20% de los casos). En el primer semestre de 2018, el delito se concentró en los lunes con el 23% de los casos.\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana con el 32% de los casos. En contraste, en el 2017, el delito se presentó en mayor proporción durante la madrugada (56%).\nAhora bien, en lo corrido del año, el lunes en la mañana registra el mayor número de casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie.\nEn cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 52% de los casos no se han empleado armas (intimidación). El tipo de arma más empleada son las armas blancas.\nEn el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a personas según zona, sitio y barrio en que se presenta:\nDurante los últimos cinco años, el hurto a personas se ha venido concentrando en la zona urbana del municipio y en la vía pública. En los primeros 6 meses de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en 2019 el barrio VILLA DEL RIO CASAS BLANCAS registró el mayor número de casos (44%).  En el primer semestre de 2018 los cinco barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, OBRERO, CENTRO, ORTIZ, y ALFONSO LOPEZ; estos barrios concentraron el 62% de los casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a automotores en el municipio:\nDurante los últimos cinco años se han presentado tres casos de hurto a automotores (en 2014, 2015 y en 2016). En el primer semestre de 2018 se registró un caso.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por sexo de la víctima en el municipio:\nDurante los últimos cinco años, el hurto a automotores se registra con mayor incidencia en los hombres.  En el primer semestre de 2018, la única víctima que se presentó es un hombre.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por grupo de edad en el municipio: \nEn los últimos cinco años, todas las víctimas de hurto a automotores han sido adultos.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nEn el primer semestre de 2018 se presentó un caso el viernes en la noche.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según características de movilidad del agresor y tipo de arma empleada:\nEn el primer semestre de 2018, en el único caso que se presentó el agresor se movilizaba a pie y utilizó un arma de fuego.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores por zona y barrio en el municipio:\nTodos los casos que se han registrado han sido en la zona urbana y en la vía pública. En el primer semestre de 2018, se registró un caso en el barrio: VILLA DEL RIO CASAS BLANCAS.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a motocicletas en el municipio:\nDurante los últimos cinco años se registra una tendencia creciente en el número de casos de hurto a motocicletas en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 36 casos en el primer semestre de 2017 a 84 casos en el 2018 (+133%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, este delito se ha concentrado en los hombres (en promedio, representan el 74% de las víctimas). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por grupo de edad en el municipio: \nEn los últimos cinco años la mayor parte de las víctimas de hurto a motocicletas han sido adultos, seguidos por jóvenes. En el primer semestre de 2018 el delito se ha concentrado en los adultos; representan el 81% de las víctimas.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización del hurto a automotores según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los sábados (19%). En el primer semestre de 2018 el delito se concentró en los martes (21%), seguidos por los domingos (17%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (36% de los casos). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (50%).\nAhora bien, en lo corrido del año, el domingo en la mañana ha registro el mayor número de casos.\n\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas según características de movilidad del agresor y tipo de arma empleada:\nDurante los últimos cinco años, en la mayoría de los casos el agresor se ha movilizado a pie.  En cuanto al tipo de arma empleada se observa que en los últimos cinco años, en el 41% de los casos no se han empleado armas (intimidación). El segundo tipo de arma más utilizada es la llave maestra. \nEn el primer semestre de 2018 se mantiene este comportamiento.\nEn el primer semestre de 2018 ",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a motocicletas por zona y barrio en el municipio:\nDesde el 2016 este delito se ha venido concentrando en la zona urbana del municipio. En el primer semestre de 2018 se mantiene este comportamiento.\nEn cuanto a la ubicación, en el primer semestre de 2018 los cinco barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, OBRERO, ORTIZ, ALFONSO LOPEZ, y CENTRO; estos barrios concentraron el 59% de los casos.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a comercio en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de hurto a comercio en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se revierte, pasando de 19 casos en el primer semestre de 2017 a 76 casos en el 2018 (300%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los días lunes y sábados. En el primer semestre de 2018, el delito se concentró en los sábados (21%), seguido por los martes (19%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (el 36% de los casos). En el 2017, el delito  se presentó en mayor proporción durante la mañana (41%).\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en cerca del 53% de los casos de hurto a comercio no se han utilizado armas. \nEn el primer semestre de 2018 aumentó la proporción de casos en los que el agresor utilizó arma de fuego y arma blanca.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a comercio por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, CGTO. EL REPOSO, EL AMPARO, CENTRO, y NUEVO APARTADO; estos barrios concentraron el 78% de los casos. En el primer semestre de 2018 los cinco barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, VELEZ, OBRERO, EL AMPARO, y ALFONSO LOPEZ; estos barrios concentran el 54% de los casos que se han registrado.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a entidades financieras en el municipio:\nEn los últimos cinco años no se han registrado casos de hurto a entidades financieras en el municipio.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del hurto a residencias en el municipio:\nDesde el 2016 se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto primer semestre de 2018, se evidencia que esta tendencia se mantiene, pasando de 31 casos en el primer semestre de 2017 a 56 casos en el 2018 (+80%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización del hurto a residencia según día de semana y franja horaria en que se presenta:\nDurante el 2017 la mayor parte de los casos se presentaron los domingos (concentra el 20% de los casos). En el primer semestre de 2018 este delito se concentró en los jueves (18%), seguido por los miércoles (16%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada (39%). En el 2017, el delito también se presentó en mayor proporción durante la madrugada (59%).\nAhora bien, en lo corrido del año, el domingo en la tarde ha registrado el mayor número de casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años, en más del 40% de los casos de hurto a residencias no se han utilizado armas. El segundo tipo de arma más utilizado son las palancas y los objetos contundentes. En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización del hurto a residencias por ubicación:\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, CENTRO, OBRERO, VEINTE DE ENERO, y VELEZ; estos barrios concentran el 55% de los casos. En el primer semestre de 2018 los barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, OBRERO, MANZANAREZ, CGTO. EL REPOSO, y VDA.EL OSITO; estos barrios concentran el 63% de los casos que se han registrado.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de las amenazas en el municipio:\nDesde el 2016 se registra una tendencia decreciente en el número de víctimas de amenazas en el municipio. Sin embargo, en el primer semestre de 2018, se revierte esta tendencia pasando de 40 víctimas en el primer semestre de 2017 a 52 (+30%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de las amenazas por sexo de la víctima en el municipio:\nDurante los últimos cinco años, las amenazas se han concentrado en los hombres (en promedio, representan el 57% de las víctimas). En el primer semestre de 2018 el delito se sigue concentrando en los hombres.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de las amenazas por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayoría de las víctimas han sido adultos. Sin embargo, en 2017 el delito se concentró en niños, niñas y adolescentes.\nEn el primer semestre de 2018 el delito se concentró en adultos (85%).",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de las amenazas según día de semana y franja horaria en que se presenta:\nDurante el 2017 el delito se concentró en los viernes. En el primer semestre de 2018, las amenazas se presentaron en mayor proporción los miércoles (18%).\nCon respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde (31%). En el 2017, el delito se concentró en las madrugadas (66%).\nAhora bien, en lo corrido del año, el viernes en la tarde ha registrado el mayor número de casos. ",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de las amenazas por ubicación:\nEn cuanto a la ubicación, en el primer semestre de 2018 los barrios con el mayor número de casos fueron: VILLA DEL RIO CASAS BLANCAS, VDA.EL OSITO, CGTO. SAN JOSE DE APARTADO,  CGTO. EL REPOSO, y el CENTRO; estos barrios concentran el 100% de los casos que se han registrado en el municipio.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la extorsión en el municipio:\nDurante los últimos cinco años se registra una tendencia decreciente en el número de casos de extorsión en el municipio. En el primer semestre de 2018 se registraron 4 casos; en contraste, en el primer semestre de 2017 se registraron 3 casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Caracterización de la extorsión por sexo de la víctima en el municipio:\nDurante los últimos cinco años, la extorsión se ha concentrado en los hombres (en promedio, representan el 56% de las víctimas). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "Caracterización de la extorsión por grupo de edad de la víctima en el municipio:\nEn los últimos cinco años la mayor parte de las víctimas de extorsión han sido adultos (con el 70% de los casos). El segundo grupo de edad más afectado son los jóvenes. \nEn el primer semestre de 2018, todas las víctimas fueron adultos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Caracterización de la extorsión según día de semana y franja horaria en que se presenta:\nDurante el 2017 el delito se concentró en los jueves. En el primer semestre el delito se presentó en mayor proporción los lunes (60%).\nCon respecto a la franja horaria, en el primer semestre de 2018, el delito se concentró en la mañana. En el 2017 se concentró en las tardes.\n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 4,
    },
    {
      descripcion:
        "Caracterización de la extorsión por tipo de arma empleada:\nEn cuanto al tipo de arma se observa que en los últimos cinco años la llamada telefónica es el medio más utilizado para extorsionar (en el 78% de los casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Caracterización de la extorsión por ubicación:\nEn cuanto a la ubicación, se tiene que en el primer semestre de 2018 los barrios que más registraron casos fueron: CENTRO, BAJO DEL OSO, VILLA DEL RIO CASAS BLANCAS, y LAS BRISAS. Estos barrios concentran el 100% de los casos registrados. \n",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Descripción del comportamiento de la piratería terrestre en el municipio:\nDurante los últimos cinco años se ha presentado un caso de piratería terrestre en el municipio (en el 2017). En el primer semestre de 2018 no se registran casos.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cuatro años no se han registrado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cuatro años no se han registrado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Descripción del comportamiento del abigeato en el municipio:\nDurante los últimos cuatro años no se han registrado casos de abigeato en el municipio.",
      codigoMunicipio: "5bad40dbef476d001358246b",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2015 el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el primer semestre de 2018 la mayor parte de los casos se presentó en casas/apartamentos. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: OBRERO, UNION VICTORIA, BRISAS DEL AEROPUERTO, AVENIDA FERREA, y LA CIUDADELA; estos barrios concentraron el 70% de los casos. En el primer semestre de 2018, el barrio/vereda OBRERO registró el mayor número de casos (38%).",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. Sin embargo, en el primer semestre de 2018 aumentó de forma significativa el número de casos que se registraron en la zona rural y en fincas/casas.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: OBRERO, LA CIUDADELA, PORVENIR, CALLE DEL COMERCIO, y NUEVO HORIZONTE; estos barrios concentraron el 78% de los casos. En el primer semestre de 2018, el barrio/vereda OBRERO registró el mayor número de casos (24%).",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años el delito se ha venido concentrando en la zona urbana y en las vías públicas del municipio. \nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CALLE DEL COMERCIO, CORREGIMIENTO LLORENTE, EL MORRO, AVENIDA LA PLAYA, y PARQUE COLON; estos barrios concentraron el 30% de los casos. En el primer semestre de 2018, el barrio/vereda EL MORRO registró el mayor número de casos (12%).",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años, el delito se ha concentrado en la zona urbana y en las vías públicas del municipio. En el primer semestre de 2018 aumenta la proporción de casos que se presentan en casas/apartamentos.\nEn cuanto a la ubicación, en 2019 los cinco barrios/veredas con el mayor número de casos fueron: CORREGIMIENTO LLORENTE, OBRERO, CALLE MOSQUERA, SAN JUDAS, y EXPORCOL; estos barrios concentran el 21% de los casos. En el primer semestre de 2018 los cinco barrios/veredas con el mayor número de casos fueron: MARIA AUXILIADORA, VEREDA EL CHAJAL, CORREGIMIENTO LLORENTE, ONCE DE NOVIEMBRE, y CORREGIMIENTO CHILVI; concentran el 21% de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 6,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia decreciente en el número de casos de violencia intrafamiliar en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene, pasando de 89 víctimas en el primer semestre de 2017 a 40 victimas en el 2018 (-55, 1%). Por su parte, la tasa de violencia intrafamiliar por cada 10.000 habitantes para 2017 fue de 7,7 por CDH, y estuvo por debajo en 6,3 puntos de la tasa de NARIÑO y por debajo 11,1 puntos de la tasa del país.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se ha concentrado en las mujeres (87%). En el 2014, se presenta el mayor número de casos de mujeres víctimas de este delito (169 casos). En el primer semestre de 2018 se mantiene este comportamiento.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de violencia intrafamiliar han sido adultos (46%); se observan tendencias crecientes de este delito en jóvenes. En el primer semestre de 2018, la violencia intrafamiliar se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los lunes (20%). En el primer semestre de 2018  el delito se concentró en los martes y miércoles, con 18 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentró principalmente en la franja de la mañana, con el 38 por ciento de los eventos. En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 70 por ciento de los casos. Ahora bien, en lo corrido del año, los  domingos en la jornada de noche han presentado el mayor número de casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 4,
    },
    {
      descripcion:
        "En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), en el 55% de los casos de violencia intrafamiliar se utilizaron objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 55% de los casos, aumentando 5 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60881b10b6a58943660999",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de homicidio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 91 víctima en el primer semestre de 2017 a 144 victimas en el 2018 (58, 2%) Por su parte, la tasa de homicidios por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 10, 1 por CDH, y estuvo por encima en 7, 1 punto de la tasa de NARIÑO y por encima en 7, 6 puntos de la tasa del país.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 94 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (13 casos). En el primer semestre de 2018, se ha disminuido en 88 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 114 caso en 2014, a 14 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de homicidio han sido niños, con el (100) porciento de los casos; No se observan tendencias crecientes en los diferentes grupos de edad. En el primer semestre de 2018, el homicidio se concentró en niños, con el 100 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 27 porciento, en el primer semestre de 2018 se concentro en los sábados con 32 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 29% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 31% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, noche se han presentado el mayor número de eventos de homicidio con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 81 porciento de homicidio se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma de fuego como el arma/medio más empleado, con el 90% de los casos, aumentando 49 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 50 porciento de Secuestro se comenten con arma de fuego. En el primer semestre de 2018 se presenta el uso de arma blanca, como el arma/medio más empleado, con el 100% de los casos, aumentando 1 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de amenazas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 111 víctima en el primer semestre de 2017 a 192 victimas en el 2018 (73%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 55 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (120 casos). En el primer semestre de 2018, se ha aumentado en 66 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 73 caso en 2016, a 121 caso en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de amenazas han sido adultos con el (60) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos. En el primer semestre de 2018, las amenazas se concentró en adultos con el 65 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de delitos sexuales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 43 víctimas en el primer semestre de 2017 a 17 victimas en el 2018 (-60, 5%). Por su parte, la tasa de delitos sexuales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 3, 3 por CDH, y estuvo por debajo en 1, 1 punto de la tasa de NARIÑO y por debajo 2, 9 puntos de la tasa del país.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 88 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (75 casos). En el primer semestre de 2018, se ha aumentado en 75 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 12 casos en 2016, a 21 caso en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de delitos sexuales han sido niños, con el (52) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos mayores. En el primer semestre de 2018, los delitos sexuales se concentró en adultos con el 41 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 19 porciento, en el primer semestre de 2018 se concentro en los domingos con 4 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 39% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 71% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada madrugada, se han presentado el mayor número de eventos de delitos sexuales con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 72 porciento de delitos sexuales se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso deno reportado como el arma/medio más empleado, con el 41% de los casos, aumentando 7 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60871210b6a58943660988",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de extorsión en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 20 víctimas en el primer semestre de 2017 a 24 victimas en el 2018 (20%), superando el promedio de los últimos cinco años en el (los) año(s) de 2014, 2016, en lo que va corrido del año no ha superado este promedio.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 64 porciento en promedio en 2014, se presenta el mayor número de casos de mujeres víctimas de este delito (20 casos). En el primer semestre de 2018, se ha disminuido en 81 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 26 caso en 2016, a 5 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de extorsión han sido adultos con el (83) porciento de los casos; se observan tendencias crecientes de este delito en adultos. En el primer semestre de 2018, el extorsión se concentró en adultos con el 67 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 3,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 53 porciento de extorsión se comenten con llamada telefonica. En el primer semestre de 2018 se presenta el uso de, llamada telefónica, como el arma/medio más empleado, con el 54% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a automotores en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 1 víctima en el primer semestre de 2017 a 4 victimas en el 2018 (300%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 68 porciento en promedio, en, 2015, se presenta el mayor número de casos de mujeres víctimas de este delito (4 casos). En el primer semestre de 2018, se ha aumentado en 50 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 2 casos en 2017 a 3 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a automotores han sido adultos con el (83) porciento de los casos; se observan tendencias crecientes de este delito en adultos, adultos mayores. En el primer semestre de 2018, el hurto a automotores se concentró en adultos con el 75 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias lunes, con el 50 porciento, en el primer semestre de 2018 se concentro en los sábados con 2 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, mañana, tarde/noche con el 100% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la madrugada, con el 50% de los casos. Ahora bien, en lo corrido del año, los dias lunes, martes, en la jornada, mañana, tarde, se han presentado el mayor número de eventos de hurto a automotores con el 7 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 43 porciento de hurto a automotores se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de arma de fuego, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 100% de los casos, aumentando 3 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a comercio en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 20 víctimas en el primer semestre de 2017 a 33 victimas en el 2018 (65%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, miércoles, con el 23 porciento, en el primer semestre de 2018 se concentro en los lunes con 8 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la tarde, con el 32% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 37% de los casos. Ahora bien, en lo corrido del año, los días de martes en la jornada, mañana se han presentado el mayor número de eventos de hurto a comercio con el 1 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie, igualmente, en lo que va corrido del 2018 los victimarios se movilizan de la misma manera. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 60 porciento de hurto a comercio se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 48% de los casos, aumentando 2 casos frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de hurto a motocicletas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 33 víctimas en el primer semestre de 2017 a 43 victimas en el 2018 (30, 3%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 74 porciento en promedio, en 2017, se presenta el mayor número de casos de mujeres víctimas de este delito (24 casos). En el primer semestre de 2018, se ha disminuido en 43 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 14 caso en 2016, a 8 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a motocicletas han sido adultos con el (71) porciento de los casos; se observan tendencias crecientes de este delito en jóvenes y adultos mayores. En el primer semestre de 2018, el hurto a motocicletas se concentró en adultos con el 70 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, domingo con el 23 porciento, en el primer semestre de 2018 se concentro en los sábados con 9 casoss. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la noche con el 36% de los eventos; en concordancia, con el año anterior, el delito se presentó en mayor proporción durante la noche con el 45% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, noche se han presentado el mayor número de eventos de hurto a motocicletas con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 39 porciento de hurto a motocicletas se comenten con llave maestra. En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 58% de los casos, aumentando 13 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a personas en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 50 víctimas en el primer semestre de 2017 a 84 victimas en el 2018 (68%) Por su parte, la tasa de hurto a personas por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 5, 2 por CDH, y estuvo por debajo en 26, 5 puntos de la tasa de NARIÑO y por debajo 32, 5 puntos de la tasa del país.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en los hombres con el 62 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (78 casos). En el primer semestre de 2018, se ha disminuido en 28 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 36 caso en 2016, a 26 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de hurto a personas han sido adultos con el (71) porciento de los casos; se observan tendencias crecientes de este delito en niños, jóvenes, adultos. En el primer semestre de 2018, el hurto a personas se concentró en adultos con el 70 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, martes, con el 18 porciento, en el primer semestre de 2018 se concentro en los Viernes con 16 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 38% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 29% de los casos. Ahora bien, en lo corrido del año, los dias lunes, en la jornada mañana, se han presentado el mayor número de eventos de hurto a personas con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 66 porciento de hurto a personas se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 54% de los casos, aumentando 11 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60877b10b6a5894366098e",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de hurto a residencias en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se se mantiene estable, pasando de 16 víctimas en el primer semestre de 2017 a 25 victimas en el 2018 (56, 3%).",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 1,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias, viernes, sábado, con el 44 porciento, en el primer semestre de 2018 se concentro en los Viernes con 6 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la madrugada, con el 48% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la mañana, con el 39% de los casos. Ahora bien, en lo corrido del año, los dias sábado, en la jornada, mañana, se han presentado el mayor número de eventos de hurto a residencias con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 69 porciento de hurto a residencias se comenten con intimidación (sin empleo de armas). En el primer semestre de 2018 se presenta el uso de, intimidación (sin empleo de armas), como el arma/medio más empleado, con el 48% de los casos, aumentando 4 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia estable en el número de casos de lesiones personales en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a creciente, pasando de 140 víctimas en el primer semestre de 2017 a 181 victima en el 2018 (29, 3%), superando el promedio de los últimos cinco años en el (los) año(s) de 2013, 2014, 2016, 2017, en lo que va corrido del año no ha superado este promedio. Por su parte, la tasa de lesiones personales por cada 10.000 habitantes viene en disminución, para 2017 la tasa del municipio fue de 14, 7 por CDH, y estuvo por debajo en 7, 6 puntos de la tasa de NARIÑO y por debajo 10 puntos de la tasa del país.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017), este delito se han concentrado en las mujeres con el 53 porciento en promedio en 2016, se presenta el mayor número de casos de mujeres víctimas de este delito (185 casos). En el primer semestre de 2018, se ha aumentado en 7 porciento los casos de victimas mujeres, frente al valor más alto de los últimos cuatro años (2014-2017), pasando de 96 casos en 2016, a 103 casos en el presente año.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 2,
    },
    {
      descripcion:
        "En los últimos cinco años (2013-2017) la mayor parte de las víctimas de lesiones personales han sido adultos con el (46) porciento de los casos; se observan tendencias crecientes de este delito en niños, adultos, adultos mayores. En el primer semestre de 2018, las lesiones personales se concentró en adultos con el 50 porciento de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 3,
    },
    {
      descripcion:
        "Durante el 2017 la mayor parte de los casos se presentaron los dias sábado, con el 26 porciento, en el primer semestre de 2018 se concentro en los sábados y domingos con 74 casos. Con respecto a la franja horaria, en el primer semestre de 2018 este delito se concentra principalmente en la franja de la mañana, con el 30% de los eventos; En contraste con el año anterior, el delito se presentó en mayor proporción durante la noche con el 31% de los casos. Ahora bien, en lo corrido del año, los dias domingo en la jornada, noche se han presentado el mayor número de eventos de lesiones personales con el 0 porciento de estos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 4,
    },
    {
      descripcion:
        "Durante los últimos cuatro años (2014 - 2017), la mayoría de los casos se han cometido a pie; Si bien en el primer semestre de 2018 se mantiene esta tendencia, se observan casos en los que el victimario se moviliza en motocicleta. En cuanto al tipo de arma se observa que en los últimos cuatro años (2014 - 2017), el 64 porciento de lesiones personales se comenten con objetos contundentes. En el primer semestre de 2018 se presenta el uso de objetos contundentes como el arma/medio más empleado, con el 54% de los casos, aumentando 11 caso frente al mismo período del año anterior.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087a510b6a58943660991",
      indice: 5,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Pirateria terrestre en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 1 víctima en el primer semestre de 2017 a 0 victimas en el 2018 (-100%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6087d810b6a58943660994",
      indice: 1,
    },
    {
      descripcion:
        "Durante los últimos cinco años (2013 - 2017) se registra una tendencia creciente en el número de casos de Secuestro en el municipio. Con respecto al primer semestre de 2018, se evidencia que esta tendencia se cambia a decreciente, pasando de 2 víctimas en el primer semestre de 2017 a 1 victima en el 2018 (-50%)",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60880f10b6a58943660998",
      indice: 1,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CENTRO, CORREGIMIENTO LLORENTE, VRDA. TANDIR, CORREGIMIENTO LA GUAYACANA, UNION VICTORIA, CORREGIMIENTO CHILVI, estos concentraron el 15% de homicidios de 2017. Respecto al 2018, el 24% de los casos de homicidios se han concentrado en: CORREGIMIENTO LLORENTE, EL MORRITO, CORREGIMIENTO LA GUAYACANA, PANAMA Y VRDA VALLENATO.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60875b10b6a5894366098c",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona rural del municipio. En cuanto a la ubicación, en 2019 el barrio/ vereda que concentro todos los casos de abigeato fue el de AVENIDA FERREA. En el 2018 no se han presentado casos",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086d710b6a58943660985",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: OBRERO, CORREGIMIENTO LLORENTE, AVENIDA LA PLAYA, UNION VICTORIA, CALLE MOSQUERA, estos concentraron el 35% de amenazas de 2017 y el 26% de los casos del 2018 se han presentado en: OBRERO, CORREGIMIENTO LLORENTE, LA CIUDADELA, CALLE DEL COMERCIO y LA AVENIDA LA PLAYA.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b6086ec10b6a58943660986",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CORREGIMIENTO LLORENTE, URBANIZACION MIRAMAR, UNION VICTORIA, BRISAS DEL MAR y EL BAJITO, estos concentraron el 16% de extorsión del 2017. Respecto al 2018, el 46% de los casos se han presentado en LA CATORCE, VRDA. CHINCHAJO, AVD. LA PLAYA, LAS MERCEDES y LA PLAYITA.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60874a10b6a5894366098b",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: UNION VICTORIA, AIRE LIBRE y el CORREGIMIENTO LLORENTE, estos concentran el 100% de Hurto a automotores de 2017 y el 100% de los casos del 2018 se ha concentrado en VRDA CANDELILLAS, LA GUAYACANA, CIUDAD 2000 y OBRERO.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60879910b6a58943660990",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CALLE MOSQUERA, CALLE SUCRE, CALLE NUEVA CREACION, VRDA CANDELILLAS y la CALLE DEL COMERCIO, estos concentraron el 34% del Hurto a comercio de 2017. En el 2018 estuvo concentrado en un 38% en los barrios/veredas de: AVENIDA DE LOS ESTUDIANTES, CALLE DEL COMERCIO, CORREGIMIENTO LLORENTE, OBRERO y SAN JUDAS.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60876910b6a5894366098d",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: CORREGIMIENTO LLORENTE, AVENIDA LOS ESTUDIANTES, OBRERO, LA CARBONERA, SAN JUDAS, estos concentraron el 39% de Hurto a motocicletas de 2017. Por su parte en el 2018 se concentró en: AVENIDA LOS ESTUDIANTES, AVENIDA LA PLAYA, SAN JUDAS, CORREGIMIENTO LLORENTE y OBRERO, estos concentraron el 34% de los casos en lo corrido de los primeros 6 meses.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "0",
      indice: 6,
    },
    {
      descripcion:
        "Desde el 2014 este delito se ha venido concentrando en la zona urbana del municipio. En cuanto a la ubicación, en 2019 los barrios/veredas con mayor número de casos fueron: OBRERO, PUENTE LAS FLORES, UNION VICTORIA, AVENIDA LA PLAYA, PUENTE DEL PROGRESO, estos concentraron el 30% de Hurto a residencias de 2017 y en el 2018 se ha venido concentrado en: AVENIDA DE LOS ESTUDIANTES, OBRERO, CALLE DEL COMERCIO, ANZOATEGUI y PUENTE DE LAS FLORES, estos concentraron el 28% de los casos.",
      codigoMunicipio: "5be05aae0c886e00133fea79",
      codigoDelito: "5b60878a10b6a5894366098f",
      indice: 6,
    },
  ];
}
