import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IpServiceService } from "./ip-service.service";

@Injectable()
export class CaptchaService {
  constructor(private http: HttpClient, private ipService: IpServiceService) {}

  public getCaptchaResponse({ ip, token }: { ip: string, token: string }) {
    return this.http.get(
      `http://www.google.com/recaptcha/api/siteverify?secret=6LdlyeMeAAAAAAAG_ZpdSg1buBUTIqgSE033i1jJ&response=${token}&remoteip=${ip}`
    );
  }
}
