import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banco-estrategias',
  templateUrl: './banco-estrategias.component.html',
  styleUrls: ['./banco-estrategias.component.css']
})
export class BancoEstrategiasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
