<div class="login-fondo">
  <div class="container">
    <img
      src="./assets/img/v2/logo-ensamble-con-texto.png"
      alt="Logo ensamble"
      style="
        width: 50%;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      "
    />
    <h1 class="login-title">Inspecciones de policía</h1>
    <form class="form-group" (ngSubmit)="onLogin(formLogin)" #formLogin="ngForm">
      <label for="email" class="label" required>Ingrese su correo</label>
      <input
        id="emailPiscc"
        type="email"
        email
        name="emailPiscc"
        class="form-control custom-input mb-5"
        ngModel
        [(ngModel)]="user.email"
        #emailPiscc="ngModel"
        aria-describedby="basic-addon1"
        placeholder="Escriba aquí su correo"
        required>
      <div class="invalid-input mb-10" *ngIf="emailPiscc.invalid && (emailPiscc.dirty || emailPiscc.touched)">
        <div *ngIf="emailPiscc.errors?.required">El campo es requerido</div>
        <div *ngIf="emailPiscc.errors?.email">El campo debe ser de tipo email</div>
      </div>
      <label for="passPiscc" class="label">Ingrese su contraseña</label>
      <div class="password-container">
        <input
          [type]="fieldTextType ? 'text': 'password'"
          name="passPiscc"
          id="passPiscc"
          class="form-control custom-input"
          aria-describedby="basic-addon1"
          ngModel
          [(ngModel)]="user.password"
          #passPiscc="ngModel"
          placeholder="Escriba aquí su contraseña"
          required>
        <span class="show-hide" (click)="toggleFieldType()"></span>
      </div>
      <div class="invalid-input mb-10" *ngIf="passPiscc.invalid && (passPiscc.dirty || passPiscc.touched)">
        <div *ngIf="passPiscc.errors?.required">El campo es requerido</div>
      </div>
      <button type="submit" class="button button-login" nbSubmit>Ingresar</button>
    </form>
    <button type="button" class="button" (click)="onResetPassword()">Olvidé mi contraseña</button>
    <button type="button" class="button" (click)="onConsultaExterna()">Consulta externa</button>
  </div>
</div>
