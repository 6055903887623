import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-inspecciones',
  templateUrl: './header-inspecciones.component.html',
  styleUrls: ['./header-inspecciones.component.css']
})
export class HeaderInspeccionesComponent implements OnInit {

  @Input('opcionElegida') opcionElegida: number;

  constructor() { }

  ngOnInit() {
  }

}
