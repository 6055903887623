import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
// import { LocalDataSource, Ng2SmartTableModule } from 'ng2-smart-table';
import Swal from 'sweetalert2';
import { MainService } from '../../services/main.service';
import { Caso, FichaGeneral } from '../inspecciones.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FichaGeneralTableData } from '../inspecciones.interface';

@Component({
  selector: 'app-gestion-casos-inspecciones',
  templateUrl: './gestion-casos-inspecciones.component.html',
  styleUrls: ['./gestion-casos-inspecciones.component.css']
})
export class GestionCasosInspeccionesComponent implements OnInit, AfterViewInit {

  dataSource: MatTableDataSource<FichaGeneralTableData, any>
  displayedColumns: string[] = ['actions','tipoComportamiento', 'numCaso', 'peticionariosNombres', 'infractoresNombres', 'fechaHechos'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private mainService: MainService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.getCasesByInspector();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getCasesByInspector(){
    const inspector = JSON.parse(localStorage.getItem('inspector'));
    if(inspector.cargo === 'Inspector'){
      this.mainService.get<FichaGeneral[]>(`api/ficha-general/gestion-fichas/${inspector._id}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral[]>(response)){
          const fichasGeneralesEncontradas: FichaGeneral[] = response.result;
          const encontrados = fichasGeneralesEncontradas.filter(ficha => {
            return ficha.caso.inspector._id === inspector._id && !ficha.caso.anulado
          })
          const fixed: FichaGeneralTableData[] = encontrados.map(ficha => {
            const { nombresCompletosPeticionarios } = this.getPetitioners(ficha.peticionarios);
            const { nombresCompletosInfractores } = this.getInfractors(ficha.infractores);
            return ({
              tipoComportamiento: ficha.CCC.length > 0 ? ficha.CCC[0].tipo : 'No asignado',
              numCaso: ficha.caso.numCaso,
              numInspeccion: ficha.caso.numInspeccion,
              fechaHechos: moment(ficha.fechaHechos).locale('es-CO').format('LL'),
              _id: ficha.caso._id,
              peticionariosNombres: nombresCompletosPeticionarios,
              infractoresNombres: nombresCompletosInfractores
            })
          });

          this.dataSource = new MatTableDataSource(fixed);
          this.dataSource.paginator = this.paginator;
        }
      })
    }
  }

  /**
   * Aplica un filtro en la tabla que hace una búsqueda en todos los casos
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Devuelve la información de los peticionarios para una ficha general
   * @param infractores Lista de infractores asociados a una ficha general
   * @returns {string} nombresCompletosPeticionarios
   */
  getPetitioners(peticionarios: any[]): { nombresCompletosPeticionarios: string } {
    const nombresCompletosPeticionarios = peticionarios.map(petitioner => petitioner.nombrePeticionario).join(', ');
    return { nombresCompletosPeticionarios }
  }

  /**
   * Devuelve la información de los infractores para una ficha general
   * @param infractores Lista de infractores asociados a una ficha general
   * @returns {string} nombresCompletosInfractores
   */
  getInfractors(infractores: any[]): { nombresCompletosInfractores: string } {
    const infractors = []
    for(let infractor of infractores){
      let nombreCompleto
      if(infractor.nombreInfractor && infractor.apellidoInfractor){
        nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
      } else if (infractor.nombreInfractor){
        nombreCompleto = infractor.nombreInfractor;
      } else if (infractor.apellidoInfractor){
        nombreCompleto = infractor.apellidoInfractor;
      } else continue
      infractors.push({
        nombreCompleto,
        tipoIdentificacion: infractor.tipoIdentificacionInfractor,
        identificacion: infractor.identificacionInfractor,
        correo: infractor.correoInfractor,
        telefono: infractor.telefonoInfractor,
        direccion: infractor.direccionInfractor,
        nombre: infractor.nombreInfractor,
        apellido: infractor.apellidoInfractor,
        tipo: 'infractor'
      })
    }
    const nombresCompletosInfractores = infractors.map(infractor => infractor.nombreCompleto).join(', ');
    return { nombresCompletosInfractores };
  }

  /**
   * Manages the actions involved in the table
   * @param data The table element data
   * @param action The type of action
   */
  onCustom(data: FichaGeneralTableData, action: string): void {
    switch (action) {
      case 'edit':
        this.router.navigate([`gestion-casos-inspecciones/ver-caso/${data._id}`]);
        break;
      case 'block':
        this.blockCase(data._id, data.numCaso);
        break;
      default: return;
    }
  }

  /**
   * Bloquea un caso
   * @param caseId El _id del caso
   * @param numCaso El número asignado del caso
   */
  blockCase(caseId: string, numCaso: string): void {
    Swal.fire({
      title: 'Anular',
      html: `<p>Anulando caso Nº${numCaso}, esta acción no se podrá deshacer. El permiso para borrar o eliminar caso sólo está asignado al perfil de Inspector de Policía</p>`,
      icon: 'warning',
      confirmButtonText: 'Continuar',
      iconColor: '#C1211A',
      confirmButtonColor: '#C1211A',
      cancelButtonText: 'Cancelar',
      showConfirmButton: true,
      showCancelButton: true
    }).then(response => {
      if(response.isConfirmed){
        this.mainService.put<Caso>(`api/caso/${caseId}`, { anulado: true }).subscribe(response => {
          if(this.mainService.isSuccess<Caso>(response)){
            Swal.fire({
              title: 'Éxito',
              text: 'Se ha anulado el caso exitosamente',
              icon: 'success'
            });
            this.getCasesByInspector();
          } else {
            Swal.fire({
              title: 'Error',
              text: `${response.error}`,
              icon: 'error'
            });
          }
        })
      }
    });
  }
}
