import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { NgForm } from '@angular/forms'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login-inspecciones',
  templateUrl: './login-inspecciones.component.html',
  styleUrls: ['./login-inspecciones.component.css']
})
export class LoginInspeccionesComponent implements OnInit {
  public user: any;
  public fieldTextType: boolean = false;

  constructor(
    private mainService: MainService,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.user = {};
  }

  public onLogin(f: NgForm) {
    if(!f.valid || !f.value){
      Swal.fire(
        'Error',
        'No se han completado todos los campos adecuadamente',
        'error'
      )
      return
    }
    const userSent = {
      isInspector: true,
      email: f.value.emailPiscc,
      password: f.value.passPiscc
    }
    const obj = this
    this.authService.authenticateUser(userSent).subscribe((response) => {
      if (response.success) {
        obj.authService.storeUserDataInspections(response.token, response.user);
        obj.router.navigate(["inicio-inspecciones"])
      } else {
        Swal.fire(
          'Error',
          'Contraseña incorrecta',
          'error'
        )
      }
    })
  }

  public onResetPassword() {
    this.router.navigate(["contrasena-olvidada-inspecciones"]);
  }

  public onConsultaExterna(){
    this.router.navigate(["consulta-externa"]);
  }

  public toggleFieldType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
