<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="premisas-container">
    <div class="d-flex-between">
      <div class="left-side">
        <button class="go-back-button" routerLink="/biblioteca-inspecciones">
          <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
        </button>
        <h1 class="principal-title mb-30">Bibliografía</h1>
      </div>
    </div>
    <main class="main-prev-mec">
      <section>
      <div class = "container-cards">
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>Convivencia cultura y ciudadanía: Dos pilares fundamentales del derecho policivo</h4>
            <a (click) = "openModal(1)">Leer</a>
          </div>
        </div>
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>La película de la seguridad: Lecciones internacionales</h4>
            <a (click) = "openModal(2)">Leer</a>
          </div>
        </div>
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>Código de policía en Colombia: ¿Seguridad civil o abuso de autoridad y coerción de
              los derechos fundamentales?</h4>
            <a (click) = "openModal(3)">Leer</a>
          </div>
        </div>
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>Uso excesivo de la fuerza en el PVI en el proceso verbal inmediato y en la aprehensión con fin

              judicial en Colombia, una problemática latente</h4>
            <a (click) = "openModal(4)">Leer</a>
          </div>
        </div>
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>Incidencia en la declaración de ilegalidad en procedimientos policivos en el municipio de

              Cartago Valle del Cauca durante los años 2019-2020</h4>
            <a (click) = "openModal(5)">Leer</a>
          </div>
        </div>
        <div class = "pdf-card">
          <div class = "container-card">
            <img src = "/assets/img/inspecciones/pdf.png">
            <h4>El control urbano a raíz de la expedición del código nacional de seguridad y convivencia ciudadana</h4>
            <a (click) = "openModal(6)">Leer</a>
          </div>
        </div>
      </div>

      </section>
      <div class = "modal-bibliografy-container" id = "modal-bibliografy-container">
        <div class = "modal-bibliografy">
          <a class = "button">
            <label (click) = "closeModal()">
              X
            </label>
          </a>
          <div class = "pdf" id = "pdf">
          </div>
        </div>
      </div>
    </main>
  </div>
</main>
