import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../services/main.service';
import Swal from 'sweetalert2';
import {JwtServicesService} from "../services/jwt-services/jwt-services.service";

;

@Component({
  selector: 'app-reset-inspector',
  templateUrl: './reset-inspector.component.html',
  styleUrls: ['./reset-inspector.component.css']
})
export class ResetInspectorComponent implements OnInit {
  data: any = {}
  email: string = '';
  jwt: string = '';
  constructor(
    private mainService: MainService,
    private jwtService: JwtServicesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // this.email = this.route.snapshot.params['email'];
    let sub = this.route.queryParamMap
      .subscribe(params => {
        this.jwt = params.get('token').replace("'", "");
        console.log(this.jwt);
      });
    // this.jwt = this.route.snapshot.params['token']
    this.decodeJwt()
  }

  decodeJwt() {
    this.jwtService.decodedJwt(this.jwt).then((res: any) => {
      this.email = res.content;
    })
  }

  onChangePassword(form: NgForm){
    if (!form.valid) {
      Swal.fire(
        'Error',
        'No se han llenado los campos correctamente',
        'warning'
      )
      return
    }
    if(form.value.newPassword === form.value.newConfirmedPassword){
      this.mainService.post<any>('users/reset', { email: this.email, password: form.value.newPassword, isInspector: true }).subscribe((response: any) => {
        if(!response.success){
          Swal.fire(
            'Error',
            response.msg,
            'warning'
          )
          return
        } else {
          Swal.fire(
            'Éxito',
            response.msg,
            'success'
          )
          this.router.navigate(['login-inspecciones']);
        }
      })
    }
  }

}
