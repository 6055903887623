import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms'
import { formatDate } from '@angular/common';
import { ChartData, ChartOptions } from 'chart.js';
import { Inspector } from '../inspecciones.interface';
import { EstadisticasService } from './estadisticas.service';
import 'chartjs-adapter-moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-estadisticas-inspecciones',
  templateUrl: './estadisticas-inspecciones.component.html',
  styleUrls: ['./estadisticas-inspecciones.component.css'],
})
export class EstadisticasInspeccionesComponent implements OnInit {
  // Chart data variables
  barChartData!: ChartData<'bar'>;
  barChartYears!: ChartData<'bar'>;
  barChartPVAYears!: ChartData<'bar'>;
  barChartStudies!: ChartData<'bar'>;
  barChartPVAStudies!: ChartData<'bar'>;
  barChartJobs!: ChartData<'bar'>;
  barChartPVAJobs!: ChartData<'bar'>;
  barChartVulnerableGroups!: ChartData<'bar'>;
  barChartPVAVulnerableGroups!: ChartData<'bar'>;
  barChartAccomplishment!: ChartData<'bar'>;
  barChartPoliceIntermediary!: ChartData<'bar'>;
  barChartIntermediateMonitoring!: ChartData<'bar'>;
  barPvaMonitoring!: ChartData<'bar'>;
  barPvaType!: ChartData<'bar'>;
  barChartTimeData!: ChartData<'line'>;

  barPoliceInfoCCCs!: ChartData<'bar'>;
  barPoliceInfoPlaces!: ChartData<'bar'>;
  barPoliceActions!: ChartData<'bar'>;

  barChartOptions: ChartOptions = {
    // Se removió la animación debido a que causa retraso en la pagina
    animation: false,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        suggestedMax: 10,
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const chartTitle = tooltipItem.dataset.label
            const label = chartTitle ? chartTitle + '' : '';
            const qty = tooltipItem.parsed.x;
            const total = tooltipItem.dataset.data.reduce((curr, val: any) => (curr + val.x), 0)
            if(total){
              const perc = Number(qty * 100 / Number(total)).toFixed(2);
              return `${label}: ${qty} (${perc}%)`;
            }
            return `${label}: ${qty}`;
          },
        }
      }
    }
  };
  barChartOptionsX: ChartOptions = {
    animation: false,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      y: {
        suggestedMax: 10,
        beginAtZero: true,
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const chartTitle = tooltipItem.dataset.label
            const label = chartTitle ? chartTitle + '' : '';
            const qty = tooltipItem.parsed.y;
            const total = tooltipItem.dataset.data.reduce((curr, val: any) => (curr + val.y), 0)
            if(total){
              const perc = Number(qty * 100 / Number(total)).toFixed(2);
              return `${label}: ${qty} (${perc}%)`;
            }
            return `${label}: ${qty}`;
          },
        }
      }
    }
  };
  barChartTimeOptions: ChartOptions = {
    animation: false,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
        ticks: {
          autoSkip: true,
          source: 'auto',
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };

  // Gráfico con información parcial de CCCs por clase de comportamiento
  barChartClaseComportamiento!: ChartData<'bar'>;
  // Información del gráfico CCCs por conducta especifica
  barChartConductaEspecifica!: ChartData<'bar'>;
  // Información del gráfico por medida correctiva impuesta de CCC
  barChartCorrectiveMeasures!: ChartData<'bar'>;
  // Información del gráfico por medida correctiva impuesta en PVA
  barChartPVACorrectiveMeasures!: ChartData<'bar'>;
  // Información del gráfico por fecha de decisión PVA
  barChartPVADecisionDate!: ChartData<'line'>;
  // Información del gráfico por tipo de actividad en mecanismos previos
  barChartMecPrevActivities!: ChartData<'bar'>;
  // Información del gráfico por compromisos adquiridos en mecanismos previos
  barChartMecPrevCommitments!: ChartData<'bar'>;
  // Información del gráfico por cumplimiento de objetivos en mecanismos previos
  barChartMecPrevAccomplishment!: ChartData<'bar'>;

  tiposGraficos: {
    // Información completa del gráfico CCCs por clase de comportamiento
    claseComportamientoCompleto: (number | [number, number])[],
    // Información completa del gráfico CCCs por clase de comportamiento
    conductaEspecificaCompleta: (number | [number, number])[]
  } = {
    claseComportamientoCompleto: null,
    conductaEspecificaCompleta: null
  }

  timeChartOptions: ChartOptions = {
    animation: false,
    parsing: false,
    scales: {
      x: {
        type: 'time',
        ticks: {
          source: 'auto',
          maxRotation: 0,
          autoSkip: true,
        },
      },
    },
  };

  // Chart section triggers
  moduloInspectores: boolean = false;
  moduloPolicia: boolean = false;

  comportamientoSection: boolean = false;
  medidasCorrectivasSection: boolean = false;
  accionesAdelantasSection: boolean = false;

  comportamientoPoliceSection: boolean = false;
  medidasCorrectivasPoliceSection: boolean = false;
  accionesAdelantasPoliceSection: boolean = false;

  startDate: Date;
  endDate: Date;

  policeMunicipalities: any[] = [];
  policeInfo = {};

  readonly config = {
    displayKey: 'nombre',
    search: true,
    placeholder: 'Seleccione',
    noResultsFound: "No se encontraron resultados",
  };

  selectedMunicipality: any;

  constructor(
    private statisticsService: EstadisticasService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getStatistics(null);
  }

  /** Get information on click chart */
  onChartClick(tipoGrafico: string, event: any, chartElements: any[]) {
    // TO DO
    if (this.tiposGraficos[tipoGrafico]) {
      console.log(this.tiposGraficos[tipoGrafico][chartElements[0].index])
    }
  }

  /** Get all the statistics for the charts */
  getStatistics(dates?: { startDate: Date, endDate: Date }) {
    const inspector: Inspector = JSON.parse(localStorage.getItem('inspector'));
    this.statisticsService.getInfo(inspector, dates).then((data: any) => {
      const lugaresHechos = [];
      const cccPorFecha = [];
      // Get CCC Infractors
      let concatInfractors: any[] = [];
      let mapInfractors: any[] = data.result.map((ficha) => ficha.infractores);
      mapInfractors.forEach((infractor) => {
        concatInfractors = concatInfractors.concat(infractor);
      });

      // Get PVA Infractors
      let pvaConcatInfractors: any[] = [];
      let pvaMapInfractors: any[] = data.pvaResult.map(pva => pva.infractores);
      pvaMapInfractors.forEach((infractor) => {
        pvaConcatInfractors = pvaConcatInfractors.concat(infractor);
      });

      // Get PVA corrective measures
      let pvaConcatMeasures: any[] = [];
      let pvaMapMeasures: any[] = data.pvaResult.map(pva => pva.medidasCorrectivas);
      pvaMapMeasures.forEach((measure) => {
        pvaConcatMeasures = pvaConcatMeasures.concat(measure);
      });

      this.getCCCStatistics(data);
      this.getStatisticsInfractors(concatInfractors, pvaConcatInfractors);
      this.getStaticsInfractorsStudies(concatInfractors, pvaConcatInfractors);
      this.getStaticsInfractorsJobs(concatInfractors, pvaConcatInfractors);
      this.getStaticsVulnerableGroups(concatInfractors, pvaConcatInfractors);
      this.getPVAStatistics(data);
      this.getPVACorrectiveMeasure(pvaConcatMeasures);
      this.getAccomplishmentStatistics(data.seguimientosEvaluacionesResult);
      this.getPoliceIntermediary(data.medioPoliciaIntermedio);
      this.getIntermediateMonitoring(data.mecIntSeguimientos);
      this.getPvaMonitoring(data.pvaSeguimientos);
      this.getPvaType(data.pvaTipoMedida);
      this.getMecPrevStatistics(data.mecanismosPreviosResult);
      this.getPoliceStatistics(data.infoPolicia);
      this.generatePoliceCharts();

      data.result.forEach((ficha) => {
        const indexEncontrado = lugaresHechos.findIndex((lugar) => lugar.y == ficha.lugarHechos);
        if (indexEncontrado != -1) {
          lugaresHechos[indexEncontrado].x += 1;
        } else {
          lugaresHechos.push({
            y: ficha.lugarHechos,
            x: 1,
          });
        }
        if (ficha.fechaHechos) {
          const indexEncontrado = cccPorFecha.findIndex((ccc) => new Date(ccc.x) == new Date(ficha.fechaHechos));
          if (indexEncontrado != -1) {
            cccPorFecha[indexEncontrado].y += 1;
          } else {
            cccPorFecha.push({
              x: new Date(ficha.fechaHechos),
              y: 1,
            });
          }
        }
      });
      this.barChartData = {
        datasets: [
          {
            backgroundColor: '#3167AF',
            label: 'Por lugar de los hechos',
            data: lugaresHechos,
          },
        ],
      };
      this.barChartTimeData = {
        datasets: [
          {
            backgroundColor: '#3167AF',
            label: 'Por fecha de los hechos',
            data: cccPorFecha,
          },
        ],
      };
    });
  }

  /** Get statistics related to CCC */
  getCCCStatistics(data: {
    claseComportamiento: number[],
    claseComportamientoCompleto: (number | [number, number])[],
    conductaEspecifica: number[],
    conductaEspecificaInfoCompleta: (number | [number, number])[],
    medidaCorrectiva: number[],
    medidaCorrectivaCompleta: (number | [number, number])[],
  }) {
    this.getCCCByClass(data.claseComportamiento);
    this.setCCCsByClassFullInfo(data.claseComportamientoCompleto);
    this.getCCCByBehaviour(data.conductaEspecifica);
    this.setCCCsByBehaviourFullInfo(data.conductaEspecificaInfoCompleta);
    this.getCCCByMeasure(data.medidaCorrectiva);
    this.setCCCsByMeasureFullInfo(data.medidaCorrectivaCompleta)
  }

  /** Get CCC chart data by behavior class  */
  getCCCByClass(claseComportamiento: (number)[]) {
    this.barChartClaseComportamiento = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por clase de comportamiento',
          data: claseComportamiento
        },
      ],
    }
  }

  /** Set CCC behavior class chart full information */
  setCCCsByClassFullInfo(claseComportamientoCompleto: (number | [number, number])[]) {
    this.tiposGraficos['claseComportamientoCompleto'] = claseComportamientoCompleto;
  }

  /** Get CCC chart data by specific behavior  */
  getCCCByBehaviour(conductaEspecifica: number[]) {
    this.barChartConductaEspecifica = {
      datasets: [
        {
          label: 'Comportamientos contrarios a la convivencia por conducta específica',
          data: conductaEspecifica,
          backgroundColor: '#3167AF'
        }
      ]
    }
  }

  getByRange(){
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: 'fit-content',
      data: {startDate: this.startDate, endDate: this.endDate}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result) return;
      const { startDate, endDate } = result;
      if(!startDate || !endDate) return;
      this.startDate = startDate;
      this.endDate = endDate;
      this.getStatistics(result);
    });
  }

  /** Set CCC specific behavior chart full information */
  setCCCsByBehaviourFullInfo(conductaEspecificaCompleta) {
    this.tiposGraficos['conductaEspecificaCompleta'] = conductaEspecificaCompleta;
  }

  /** Get CCC chart data by corrective measure */
  getCCCByMeasure(medidaCorrectiva: number[]) {
    this.barChartCorrectiveMeasures = {
      datasets: [
        {
          label: 'Medidas correctivas impuestas',
          data: medidaCorrectiva,
          backgroundColor: '#3167AF'
        }
      ]
    }
  }

  /** Set CCC corrective measure chart full information */
  setCCCsByMeasureFullInfo(medidaCorrectivaCompleta) {
    this.tiposGraficos['medidaCorrectivaCompleta'] = medidaCorrectivaCompleta;
  }

  /** Get statistics related to PVA */
  getPVAStatistics(data: {
    pvaFechaDecision: number[],
    pvaFechaDecisionCompleta: (number | [number, number])[],
  }) {
    this.getPVAByDecisionDate(data.pvaFechaDecision);
    this.setPVAByDecisionDateFullInfo(data.pvaFechaDecisionCompleta);
  }

  /** Get PVA chart data by decision date */
  getPVAByDecisionDate(pvaFechaDecision: number[]) {
    pvaFechaDecision.forEach((decision: any) => {
      decision.x = new Date(decision.x);
    })
    this.barChartPVADecisionDate = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          borderColor: '#26529F',
          pointBackgroundColor: '#3167AF',
          pointBorderColor: '#26529F',
          label: 'Por fecha de decisión del PVA',
          data: pvaFechaDecision
        }
      ]
    }
  }

  /** Set PVA decision date chart full information */
  setPVAByDecisionDateFullInfo(pvaFechaDecisionCompleta) {
    this.tiposGraficos['pvaFechaDecisionCompleta'] = pvaFechaDecisionCompleta;
  }

  /** Get infractors age data for charts */
  getStatisticsInfractors(infractors: any[], pvaInfractors: any[]) {
    const ranges = [[0, 18], [18, 30], [31, 40], [41, 50], [51, 60], [61, 999]];

    let data = infractors.filter(infractor => infractor.edadInfractor).map((infractor) => infractor.edadInfractor)
    let dataSet: any[] = ranges.map(range => {
      let ageData: any[] = [];
      ageData = [...data].filter((age) => {
        return age >= range[0] && age <= range[1];
      });
      return {
        x: `${range[0]}${range[1] >= 999 ? '+' : '-' + range[1]}`,
        y: ageData.length
      }
    })
    this.barChartYears = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por rango de edad del presunto infractor',
          data: dataSet
        },
      ],
    };

    // PVA data
    let pvaData = pvaInfractors.filter(infractor => infractor.edadInfractor).map((infractor) => infractor.edadInfractor);
    let pvaDataSet: any[] = ranges.map(range => {
      let ageData: any[] = [];
      ageData = [...pvaData].filter((age) => {
        return age >= range[0] && age <= range[1];
      });
      return {
        x: `${range[0]}${range[1] === 999 ? '+' : '-' + range[1]}`,
        y: ageData.length
      }
    });
    this.barChartPVAYears = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por rango de Edad',
          data: pvaDataSet
        },
      ],
    };
  }

  /** Get infractors studies level data for charts */
  getStaticsInfractorsStudies(infractors: any[], pvaInfractors: any[]) {
    // Get CCC studies data
    let dataStudies = infractors.map((infractor) => infractor.nivelEducativoInfractor || 'No especificado');
    const countsStudies = dataStudies.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetStudies: any[] = Object.entries(countsStudies).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartStudies = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por nivel educativo del presunto infractor',
          data: dataSetStudies
        },
      ],
    };

    // Get PVA studies data
    let pvaDataStudies = pvaInfractors.map((infractor) => infractor.nivelEducativoInfractor || 'No especificado');
    const pvaCountsStudies = pvaDataStudies.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let pvaDataSetStudies: any[] = Object.entries(pvaCountsStudies).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartPVAStudies = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por nivel educativo del infractor',
          data: pvaDataSetStudies
        },
      ],
    };
  }

  /** Get infractors current job data for charts */
  getStaticsInfractorsJobs(infractors: any[], pvaInfractors: any[]) {
    // Get CCC jobs data
    let dataJobs = infractors.map((infractor) => infractor.ocupacionInfractor || 'No especificado');
    const countsStudies = dataJobs.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetJobs: any[] = Object.entries(countsStudies).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartJobs = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por ocupación del presunto infractor',
          data: dataSetJobs
        },
      ],
    };

    let pvaDataJobs = pvaInfractors.map((infractor) => infractor.ocupacionInfractor || 'No especificado');
    const countsJobs = pvaDataJobs.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let pvaDataSetJobs: any[] = Object.entries(countsJobs).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartPVAJobs = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por ocupación del infractor',
          data: pvaDataSetJobs
        },
      ],
    };
  }

  /** Get infractors vulnerable groups data for charts */
  getStaticsVulnerableGroups(infractors: any[], pvaInfractors: any[]) {
    let dataVulnerableGroups = infractors.map((infractor) => infractor.tipoVulnerabilidadInfractor || 'No especificado');
    const countsVulnerableGroups = dataVulnerableGroups.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetVulnerableGroups: any[] = Object.entries(countsVulnerableGroups).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartVulnerableGroups = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por tipo de población vulnerable',
          data: dataSetVulnerableGroups
        },
      ]
    }

    let pvaDataVulnerableGroups = pvaInfractors.map((infractor) => infractor.tipoVulnerabilidadInfractor || 'No especificado');
    const pvaCountsVulnerableGroups = pvaDataVulnerableGroups.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let pvaDataSetVulnerableGroups: any[] = Object.entries(pvaCountsVulnerableGroups).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartPVAVulnerableGroups = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por tipo de población vulnerable',
          data: pvaDataSetVulnerableGroups
        },
      ]
    }
  }

  /** Get PVA corrective measures related data */
  getPVACorrectiveMeasure(correctiveMeasures: any[]) {
    let dataMeasures = correctiveMeasures.map((medida) => medida.tipoMedidaCorrectiva || 'No especificado');
    const countsMeasure = dataMeasures.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetCorrectiveMeasures: any[] = Object.entries(countsMeasure).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartPVACorrectiveMeasures = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Por medidas correctivas impuestas del PVA',
          data: dataSetCorrectiveMeasures
        },
      ]
    }
  }

  /** Get all the accomplishment related data for charts */
  getAccomplishmentStatistics(evaluations: any[]) {
    let dataAccomplishment = evaluations.map((evaluation) => evaluation.cumplimientoConvivencia || 'No especificado');
    const countsAccomplishment = dataAccomplishment.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetAccomplishment: any[] = Object.entries(countsAccomplishment).map(([num, count]) => ({
      y: `${this.getAccomplishmentLabel(num)}`,
      x: count,
    }));
    this.barChartAccomplishment = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Cumplimiento de las medidas impuestas en el PVA por parte de los infractores',
          data: dataSetAccomplishment
        },
      ]
    }
  }
  /**Obtains data for the police means graph
   * @medioPoliciaIntermedio is data of backend to x and y **/
  getPoliceIntermediary(medioPoliciaIntermedio: any) {
    this.barChartPoliceIntermediary = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Mecanismo intermedio Por tipo de mecanismo',
          data: medioPoliciaIntermedio
        }
      ]
    }
  }
  /**Intermediate Mechanism graph For effectiveness or achievement of objectives
   * @mecIntSeguimientos is data of backend to x and y **/
  getIntermediateMonitoring(mecIntSeguimientos: any) {
    if(Array.isArray(mecIntSeguimientos)){
      mecIntSeguimientos.forEach((mecanismo) => {
        mecanismo.y = this.getAccomplishmentLabelInterMediary(mecanismo.y);
      });
      this.barChartIntermediateMonitoring = {
        datasets: [
          {
            backgroundColor: '#3167AF',
            label: 'Mecanismo intermedio Por eficacia o cumplimiento de objetivos ',
            data: mecIntSeguimientos
          }
        ]
      }
    }
  }
  /**graph of Abbreviated verbal process With corrective measure imposed or without measure
   * @pvaSeguimientos is data of backend to x and y **/
  getPvaMonitoring(pvaSeguimientos: any) {
    this.barPvaMonitoring = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Proceso verbal abreviado Con medida correctiva impuesta o sin medida',
          data: pvaSeguimientos
        }
      ]
    }
  }

  /**Abbreviated verbal process graph Type of measure
   * @pvaTipoMedida is data of backend to x and y **/
  getPvaType(pvaTipoMedida: any) {
    this.barPvaType = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Proceso verbal abreviado Por tipo de medida',
          data: pvaTipoMedida
        }
      ]
    }
  }

  /** Get all mec prev related data for charts */
  getMecPrevStatistics(mecPrevs: any[]) {
    this.getMecPrevActivitiesType(mecPrevs);
    this.getMecPrevCommitments(mecPrevs);
    this.getMecPrevObjectivesAccomplishment(mecPrevs);
  }

  /** Get activities data from previous mechanisms */
  getMecPrevActivitiesType(mecPrevs: any[]) {
    let mecPrevConcatActivities: any[] = [];
    let mecPrevMapActivities: any[] = [...mecPrevs].map(mp => mp.actividadesFaseIntervencion);
    mecPrevMapActivities.forEach((activity) => {
      mecPrevConcatActivities = mecPrevConcatActivities.concat(activity);
    });

    let dataMecPrevActivities = mecPrevConcatActivities.map((activity) => activity.tipoActividad || 'No especificado');
    const countsMecPrevActivities = dataMecPrevActivities.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetMecPrevActivities: any[] = Object.entries(countsMecPrevActivities).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartMecPrevActivities = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Mecanismos previos por tipo de actividad',
          data: dataSetMecPrevActivities
        },
      ]
    }
  }


  /** Get commitments data from previous mechanisms */
  getMecPrevCommitments(mecPrevs: any[]) {
    let mecPrevConcatCommitments: any[] = [];
    let mecPrevMapCommitments: any[] = [...mecPrevs].map(mp => mp.compromisos);
    mecPrevMapCommitments.forEach((commit) => {
      mecPrevConcatCommitments = mecPrevConcatCommitments.concat(commit);
    });

    let dataMecPrevCommitments = mecPrevConcatCommitments.map((commit) => `${commit.cumplido === true ? 'Sí' : (commit.cumplido === false ? 'No' : 'No especificado')}`);
    const countsMecPrevCommitments = dataMecPrevCommitments.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetMecPrevCommitments: any[] = Object.entries(countsMecPrevCommitments).map(([num, count]) => ({
      y: `${num}`,
      x: count,
    }));
    this.barChartMecPrevCommitments = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Mecanismos previos por compromisos adquiridos',
          data: dataSetMecPrevCommitments
        },
      ]
    }
  }

  /** Get objetives accomplishment data from previous mechanisms */
  getMecPrevObjectivesAccomplishment(mecPrevs: any[]) {
    let dataMecPrevAccomplishment = [...mecPrevs].map((mp) => mp.cumplimientoObjetivos || 'No especificado');
    const countsMecPrevAccomplishment = dataMecPrevAccomplishment.reduce((acc, num) => {
      if (num) acc[num] = (acc[num] || 0) + 1;
      return acc;
    }, Object.create(null));
    let dataSetMecPrevAccomplishment: any[] = Object.entries(countsMecPrevAccomplishment).map(([num, count]) => ({
      y: `${this.getAccomplishmentLabel(num)}`,
      x: count,
    }));
    this.barChartMecPrevAccomplishment = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Mecanismos previos por cumplimiento de objetivos',
          data: dataSetMecPrevAccomplishment
        },
      ]
    }
  }

  getPoliceStatistics(policeInfo){
    this.policeMunicipalities = Object.keys(policeInfo);
    this.policeInfo = policeInfo;
  }

  selectMunicipality(){
    if(!this.selectedMunicipality || Array.isArray(this.selectedMunicipality) || !this.policeInfo[this.selectedMunicipality]) {
      Swal.fire('Error', 'Seleccione un valor de la lista', 'error');
      return;
    };
    this.generatePoliceCharts()
  }

  generatePoliceCharts(){
    if(!this.selectedMunicipality || Array.isArray(this.selectedMunicipality) || !this.policeInfo[this.selectedMunicipality]) {
      return;
    };
    this.barPoliceInfoCCCs = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Comportamientos contrarios a la convivencia por municipio',
          data: this.policeInfo[this.selectedMunicipality].comportamientos
        },
      ]
    }
    this.barPoliceInfoPlaces = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Lugares de los hechos por municipio',
          data: this.policeInfo[this.selectedMunicipality].lugaresHechos
        },
      ]
    }
    this.barPoliceActions = {
      datasets: [
        {
          backgroundColor: '#3167AF',
          label: 'Medidas correctivas por municipio',
          data: this.policeInfo[this.selectedMunicipality].medidas
        },
      ]
    }
  }

  /** Get accomplishment label name for chart */
  getAccomplishmentLabel(accomplishment: string) {
    switch (accomplishment) {
      case 'yes':
        return 'Sí';
      case 'no':
        return 'No';
      case 'partially':
        return 'Parcialmente';
      default:
        return 'No especificado'
    }
  }

  /** Get accomplishment label name for chart */
  getAccomplishmentLabelInterMediary(accomplishment: string) {
    switch (accomplishment) {
      case 'yes':
        return 'Totalmente';
      case 'no':
        return 'No';
      case 'partially':
        return 'Parcialmente';
      default:
        return 'No especificado'
    }
  }
}

export interface DialogData {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'select-dates-dialog',
  templateUrl: 'select-dates-dialog.html',
  styleUrls: ['./select-dates/select-dates-dialog.css'],
})
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  startDate = new FormControl(null, [Validators.required]);
  endDate = new FormControl(null, [Validators.required]);

  onNoClick(): void {
    this.dialogRef.close();
  }

  saveInfo(){
    if(this.startDate.invalid || this.endDate.invalid){
      Swal.fire('Error', 'Rellene todos los campos', 'error');
      return;
    }
    this.data = { startDate: new Date(this.startDate.value), endDate: new Date(this.endDate.value) };
    this.dialogRef.close(this.data);
  }

  onDateChanged(event, type){
    if(!event.target.value) return;
    if(type === 'start'){
      this.startDate.setValue(new Date(event.target.value));
    } else {
      this.endDate.setValue(new Date(event.target.value));
    }
  }

}
