<app-header-inspecciones></app-header-inspecciones>
<main class="module-principal">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <div class="d-flex flex-row w-100 align-items-center">
      <button class="go-back-button" routerLink="/inicio-inspecciones">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title m-0">Estadísticas</h1>
    </div>
    <div class="d-flex flex-row">
      <button class="yellow-button text-nowrap">Generar gráfica</button>
      <button (click)="getByRange()" class="blue-button text-nowrap">Rango de fecha<mat-icon
          aria-label="Elegir rango de fecha" fontIcon="calendar_today" class="boton-calendario"></mat-icon></button>
    </div>
  </div>
  <div class="d-flex flex-column statistics">
    <div class="dropdown">
      <div class="dropdown__trigger cursor-pointer" (click)="moduloInspectores = !moduloInspectores">
        <h2 class="dropdown__title">Estadísticas Módulo Inspectores de Policía<br>(Ensamble)</h2>
        <img class="dropdown__arrow" [ngClass]="{ 'rotate': moduloInspectores }"
          src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
      </div>
      <div class="dropdown__content" *ngIf="moduloInspectores">
        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer" (click)="comportamientoSection = !comportamientoSection">
            <h3 class="sub-dropdown__title">Estadísticas según comportamientos contrarios a la convivencia</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': comportamientoSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo azul">
          </div>
          <div class="sub-dropdown__content" *ngIf="comportamientoSection">
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por lugar de comisión:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por características del presunto infractor:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartJobs" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartStudies" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartYears" [options]="barChartOptionsX" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartVulnerableGroups" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por tipo de comportamiento:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartClaseComportamiento" [options]="barChartOptions" [type]="'bar'"
                        (chartClick)="onChartClick('claseComportamientoCompleto', $event, $event.active)">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartConductaEspecifica" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer"
            (click)="accionesAdelantasSection = !accionesAdelantasSection">
            <h3 class="sub-dropdown__title">Estadísticas según acciones adelantadas</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': accionesAdelantasSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo">
          </div>
          <div class="sub-dropdown__content" *ngIf="accionesAdelantasSection">
            <div class="row mt-2 g-4">
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartAccomplishment" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartPoliceIntermediary" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartIntermediateMonitoring" [options]="barChartOptions"
                      [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barPvaMonitoring" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barPvaType" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartMecPrevActivities" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartMecPrevCommitments" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="chart-card">
                  <div class="chart-container">
                    <canvas baseChart [data]="barChartMecPrevAccomplishment" [options]="barChartOptions" [type]="'bar'">
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer"
            (click)="medidasCorrectivasSection = !medidasCorrectivasSection">
            <h3 class="sub-dropdown__title">Estadísticas según medidas correctivas</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': medidasCorrectivasSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo">
          </div>
          <div class="sub-dropdown__content" *ngIf="medidasCorrectivasSection">
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por características del infractor:</h3>
              <div class="row mt-2 g-4">
                <div class="col-6">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVAStudies" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVAYears" [options]="barChartOptionsX" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVAJobs" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVAVulnerableGroups" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por medidas correctivas:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartCorrectiveMeasures" [options]="barChartOptions" [type]="'bar'"
                        (chartClick)="onChartClick('medidaCorrectivaCompleta', $event, $event.active)">
                        >
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVADecisionDate" [options]="barChartTimeOptions"
                        [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barChartPVACorrectiveMeasures" [options]="barChartOptions"
                        [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dropdown">
      <div class="dropdown__trigger cursor-pointer" (click)="moduloPolicia = !moduloPolicia">
        <h2 class="dropdown__title">Estadísticas Policía Nacional<br>(Externas)</h2>
        <img class="dropdown__arrow" [ngClass]="{ 'rotate': moduloPolicia }"
          src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
      </div>
      <div class="dropdown__content" *ngIf="moduloPolicia">
        <div class="d-flex pt-20 pb-20">
          <ngx-select-dropdown class="inspections-select mr-20 min-200" name="tipoVulnerabilidadInfractor"
            [(ngModel)]="selectedMunicipality" [config]="config" [options]="policeMunicipalities">
          </ngx-select-dropdown>
          <button (click)="selectMunicipality()" class="search-button text-nowrap">Buscar</button>
        </div>
        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer" (click)="comportamientoPoliceSection = !comportamientoPoliceSection">
            <h3 class="sub-dropdown__title">Estadísticas según comportamientos contrarios a la convivencia</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': comportamientoPoliceSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo azul">
          </div>
          <div class="sub-dropdown__content" *ngIf="comportamientoPoliceSection">
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por comportamiento contrario a la convivencia (Artículo):</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barPoliceInfoCCCs" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer" (click)="medidasCorrectivasPoliceSection = !medidasCorrectivasPoliceSection">
            <h3 class="sub-dropdown__title">Estadísticas según lugar de los hechos</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': medidasCorrectivasPoliceSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo azul">
          </div>
          <div class="sub-dropdown__content" *ngIf="medidasCorrectivasPoliceSection">
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por lugar de comisión:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barPoliceInfoPlaces" [options]="barChartOptionsX" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-dropdown">
          <div class="sub-dropdown__trigger cursor-pointer" (click)="accionesAdelantasPoliceSection = !accionesAdelantasPoliceSection">
            <h3 class="sub-dropdown__title">Estadísticas según medidas correctivas</h3>
            <img class="sub-dropdown__arrow" [ngClass]="{ 'rotate': accionesAdelantasPoliceSection }"
              src="/assets/img/inspecciones/arrow-down-blue.png" alt="Flecha abajo azul">
          </div>
          <div class="sub-dropdown__content" *ngIf="accionesAdelantasPoliceSection">
            <div class="statistics__section">
              <h3 class="statistics__subtitle">Por medidas correctivas:</h3>
              <div class="row mt-2 g-4">
                <div class="col-12">
                  <div class="chart-card">
                    <div class="chart-container">
                      <canvas baseChart [data]="barPoliceActions" [options]="barChartOptions" [type]="'bar'">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
