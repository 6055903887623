import { Injectable } from '@angular/core';
import {MainService} from "../main.service";

@Injectable({
  providedIn: 'root'
})
export class JwtServicesService {

  constructor(
    private mainService: MainService,
  ) { }

  decodedJwt(jwt: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.mainService.post('users/decode-jwt', {jwt: jwt}).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
