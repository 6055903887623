<div class="form-container">
  <div class="form-header">
    <h2 class="form-title">Intervención</h2>
    <button *ngIf="actividadSeleccionada" class="cancel-button mr-10" (click)="cancelActivity()">Salir de {{ wantEditActivity ? 'edición' : 'creación' }}</button>
  </div>
  <p class="form-description">Teniendo en cuenta lo previsto en el numeral 13 del artículo 8 de la Ley 1801 de 2016 (Código Nacional de Seguridad y Convivencia) en el sentido que “las autoridades de Policía solo podrán adoptar los medios y medidas rigurosamente necesarias e idóneas para la preservación y restablecimiento del orden público cuando la aplicación de otros mecanismos de protección, restauración, educación o de prevención resulte ineficaz para alcanzar el fin propuesto”, esta Inspección de Policía, con fundamento en las fichas genéricas de los casos arriba referenciados, estima necesario adelantar la(s) siguiente(s) actuaciones a fin de identificar la problemática que se presenta:</p>
  <section>
    <div class="form-row">
      <div class="form-group full-width-group mb-0">
        <h3 class="font18 bold">Compromisos asumidos</h3>
      </div>
    </div>
    <div class="list" *ngIf="compromisos.length > 0">
      <div class="list-row" *ngFor="let comp of compromisos; index as i">
        <div class="list-detail fit-height">
          <span>{{ comp.descripcion | truncate:200 }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editCompromise(comp)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="Edit icon"></button>
            <button class="selectable-button" (click)="deleteCompromise(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="Delete icon"></button>
          </div>
        </div>
      </div>
    </div>
    <h4 class="font16 bold">{{ wantEditCompromise ? 'Compromiso en edición' : 'Nuevo compromiso' }}</h4>
    <div class="form-row max-70per">
      <div class="form-group full-width-group">
        <input
        type="text"
        placeholder="Escribe aquí"
        name="compromisos"
        class="inspections-input"
        id="compromisos"
        [(ngModel)]="compromiso.descripcion">
      </div>
    </div>
    <button *ngIf="!wantEditCompromise" class="aggregate-button mb-20" (click)="addCompromise()">+Agregar compromiso asumido</button>
    <button *ngIf="wantEditCompromise" class="aggregate-button mb-20" (click)="saveCompromise()">Guardar compromiso asumido</button>
  </section>
  <h3 class="font18 bold mt-0">Lista de actividades</h3>
  <div *ngIf="actividadesDisponibles.length > 0; else noActividades" class="form-group d-flex-l">
    <button class="selectable-button" *ngFor="let actividad of actividadesDisponibles; index as i" (click)="editActivity(i)">{{ 'Actividad ' + (i+1) + ' / ' + formatDate(actividad.fechaActividad) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar"></button>
  </div>
  <ng-template #noActividades>
    <div class="width-70">
      <p class="text">
        No tienes ninguna actividad creada
      </p>
    </div>
  </ng-template>
  <button *ngIf="!wantEditActivity && !actividadSeleccionada" class="aggregate-button mb-20" (click)="startActivity()">+Nueva actividad</button>
  <h3 *ngIf="actividadSeleccionada" class="font18 bold mt-0">{{ wantEditActivity ? 'Editar actividad ' + (indexActividadSeleccionada+1) : 'Añadir actividad' }}</h3>
  <div>
    <form class="phase-form" #intervention="ngForm">
      <div *ngIf="actividadSeleccionada">
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label class="inspections-label" for="tipoActividad">Tipo de actividad*</label>
            <ngx-select-dropdown [disabled]="esOtraActividad" class="inspections-select" name="tipoActividad" [(ngModel)]="tipoActividad" [config]="config" [options]="tiposActividades">
            </ngx-select-dropdown>
          </div>
          <div class="form-group half-width-group">
            <div class="d-flex-between">
              <div class="d-flex-start checkbox-container">
                <input class="checkbox width-30" name="esOtraActividad" [(ngModel)]="esOtraActividad" (change)="changeActivityType($event)" type="checkbox">
                <label class="inspections-label" for="esOtraActividad">¿Otra?</label>
              </div>
              <div class="width-70">
                <label class="inspections-label" for="otroTipoActividad">¿Cuál?</label>
                <input
                  [readonly]="!esOtraActividad"
                  class="inspections-input width-100"
                  placeholder="Escribe aquí"
                  type="text"
                  name="otroTipoActividad"
                  id="otroTipoActividad"
                  [(ngModel)]="otroTipoActividad">
              </div>
            </div>
          </div>
        </div>
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label for="modalidadActividad" class="inspections-label">Modalidad de la actividad*</label>
            <ngx-select-dropdown class="inspections-select" name="modalidadActividad" [(ngModel)]="modalidadActividad" [config]="config" [options]="modalidadesActividad">
            </ngx-select-dropdown>
          </div>
          <div class="form-group half-width-group">
            <label for="lugarActividad" class="inspections-label">Lugar de la actividad*</label>
            <input
              type="text"
              placeholder="Escribe aquí"
              name="lugarActividad"
              class="inspections-input"
              id="lugarActividad"
              [(ngModel)]="intervencion.lugarActividad">
          </div>
        </div>
        <div class="form-row d-flex-between bottom-line">
          <div class="form-group half-width-group">
            <label for="fechaActividad" class="inspections-label">Fecha*</label>
            <input
              class="inspections-input calendar-input"
              placeholder="dd/mm/aaaa"
              type="date"
              name="fechaActividad"
              id="fechaActividad"
              [max]="todayDate"
              [(ngModel)]="intervencion.fechaActividad">
          </div>
          <div class="form-group half-width-group">
            <label for="horaActividad" class="inspections-label">Hora</label>
            <input
              type="time"
              placeholder="00:00"
              name="horaActividad"
              class="inspections-input time-input"
              id="horaActividad"
              [(ngModel)]="intervencion.horaActividad">
          </div>
        </div>
        <section class="bottom-line">
          <div class="form-row">
            <div class="form-group full-width-group mb-0">
              <label for="convocados" class="inspections-label">Personas/Entidades convocadas</label>
            </div>
          </div>
          <div class="list" *ngIf="convocados.length > 0">
            <div class="list-row" *ngFor="let convocado of convocados; index as i">
              <div class="list-detail min-100per">
                <span>{{ convocado | truncate:40 }}</span>
                <div class="list-buttons right-double">
                  <button class="selectable-button" (click)="editSummoned(convocado, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="Edit icon"></button>
                  <button class="selectable-button" (click)="deleteSummoned(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="Delete icon"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group full-width-group">
              <input
              type="text"
              placeholder="Escribe aquí"
              name="convocados"
              class="inspections-input"
              id="convocados"
              [(ngModel)]="convocado">
            </div>
          </div>
          <button *ngIf="!wantEditSummoned" class="aggregate-button" (click)="addSummoned()">+Agregar</button>
          <button *ngIf="wantEditSummoned" class="cancel-small-button" (click)="cancelSummoned()">Cancelar</button>
          <button *ngIf="wantEditSummoned" class="aggregate-button" (click)="saveSummoned()">Guardar</button>
        </section>

        <div class="form-row bottom-line">
          <div class="form-group full-width-group">
            <label for="agendaActividad" class="inspections-label">Agenda de la actividad</label>
            <textarea class="textarea mb-20" placeholder="Escribe aquí" name="agendaActividad" id="agendaActividad" [(ngModel)]="intervencion.agendaActividad" cols="30" rows="10"></textarea>
          </div>
        </div>

        <section class="bottom-line">
          <h3 class="inspections-label">Asistentes</h3>
          <div class="list" *ngIf="asistentes.length > 0">
            <div class="list-row" *ngFor="let asistente of asistentes; index as i">
              <div class="list-detail min-100per">
                <span>{{ asistente.nombreCompleto | truncate:40 }}</span>
                <div class="list-buttons right-double">
                  <button class="selectable-button" (click)="editAssistant(asistente, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="Edit icon"></button>
                  <button class="selectable-button" (click)="deleteAssistant(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="Delete icon"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row d-flex-between">
            <div class="form-group half-width-group">
              <label for="nombreCompleto" class="inspections-label">Nombre</label>
              <ngx-select-dropdown class="inspections-select" name="nombreCompleto" [(ngModel)]="asistente.nombreCompleto" (change)="findAssistantId(asistente.nombreCompleto)" [config]="config" [options]="availableAssistants">
              </ngx-select-dropdown>
            </div>
            <div class="form-group half-width-group">
              <label for="identificacion" class="inspections-label">Identificación</label>
              <span class="id-span">{{ asistente.identificacion || 'Seleccione un asistente' }}</span>
            </div>
          </div>
          <button *ngIf="!wantEditAssistant" class="aggregate-button mb-20" (click)="addAssistant()">+Agregar</button>
          <button *ngIf="wantEditAssistant" class="cancel-small-button" (click)="cancelAssistant()">Cancelar</button>
          <button *ngIf="wantEditAssistant" class="aggregate-button mb-20" (click)="saveAssistant()">Guardar</button>
        </section>

        <div class="form-row">
          <div class="form-group full-width-group">
            <label for="resumenActividad" class="inspections-label">Resumen de la actividad desarrollada</label>
            <textarea class="textarea mb-20" placeholder="Escribe aquí" name="resumenActividad" id="resumenActividad" [(ngModel)]="intervencion.resumenActividad" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div class="form-row">
          <label for="registroFotograficoActividadDesarrollada" class="inspections-label">Registro fotográfico</label>
          <label for="registroFotograficoActividadDesarrollada" class="file-container mb-20">
            <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
            <p>Subir archivo</p>
            <input
              class="file-input"
              type="file"
              id="registroFotograficoActividadDesarrollada"
              name="registroFotograficoActividadDesarrollada"
              accept="image/*,application/*"
              multiple
              (change)="onFileChange($event)">
          </label>
          <div *ngIf="intervencion.registroFotografico.length > 0" class="mb-20">
            <h4>Registros</h4>
            <div class="p-relative-registros mr-20" *ngFor="let photoLink of intervencion.registroFotografico; index as i">
              <a [href]="photoLink" target="_blank"><img class="uploaded-image" [src]="getImageFromTypeFile(photoLink)" [alt]="'Registro número ' + (i+1)"></a>
              <img class="delete-uploaded-image" src="assets/img/inspecciones/arrow-down.png" alt="X" (click)="deletePhoto(i)">
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <label for="observaciones" class="inspections-label">Observaciones</label>
            <textarea class="textarea mb-20" placeholder="Escribe aquí" name="observaciones" id="observaciones" [(ngModel)]="intervencion.observaciones" cols="30" rows="10"></textarea>
          </div>
        </div>
        <button *ngIf="!wantEditActivity" class="aggregate-button mb-20" (click)="addActivity(intervention)">+Agregar actividad</button>
        <button *ngIf="wantEditActivity" class="aggregate-button mb-20" (click)="saveActivity(intervention)">Guardar actividad</button>
      </div>
    </form>
    <div>
      <button class="submit-button" (click)="updateIntervention(intervention)">Guardar</button>
    </div>
  </div>
</div>
