import { Component, Input, OnInit } from '@angular/core';
import { Audiencia, FichaGeneral, FichaGeneralExtraData, MecanismoIntermedio, MecanismoPrevio } from '../../../inspecciones.interface';
import { MecanismoIntermedioService } from '../mecanismo-intermedio.service';
import { jsPDF } from 'jspdf';
import { UtilsService } from '../../../../services/utils.service';
import { GeneralFileService } from '../../../form-ficha-general/general-file.service';
import { ActivatedRoute } from '@angular/router';
import { MecanismoPrevioService } from '../../mecanismo-previo/mecanismo-previo.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-decision-recursos',
  templateUrl: './decision-recursos.component.html',
  styleUrls: ['./decision-recursos.component.css']
})
export class DecisionRecursosComponent implements OnInit {
  todayDate = new Date().toISOString().split("T")[0]
  finalY: number = 15;
  caseId: string;
  doc: jsPDF;

  mecPrevio: MecanismoPrevio

  fichaGeneral: FichaGeneral;

  interponeReposicion: boolean = false
  interponeApelacion: boolean = false

  readonly config = {
    noResultsFound: "No se encontraron resultados",
    search: true,
    placeholder: "Seleccione"
  }

  readonly tiposMediosPolicia = [
    'Orden de Policía',
    'Permiso excepcional',
    'Reglamento',
    'Autorización',
    'Mediación policial',
    'Traslado por protección',
    'Retiro del sitio',
    'Traslado para procedimiento policivo',
    'Registro',
    'Registro a persona',
    'Registro a medios de transporte',
    'Suspensión inmediata de actividad',
    'Ingreso a inmueble con orden escrita',
    'Ingreso a inmueble sin orden escrita',
    'Incautación',
    'Incautación de armas de fuego, no convencionales, municiones y explosivos',
    'Uso de la fuerza',
    'Aprehensión con fin judicial',
    'Apoyo urgente de los particulares',
    'Asistencia militar'
  ]

  mecInt: MecanismoIntermedio = {
    caso: undefined
  };

  @Input() mecIntId: string;

  constructor(
    private mecIntService: MecanismoIntermedioService,
    private generalFileService: GeneralFileService,
    private mecPrevService: MecanismoPrevioService,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.mecInt.fechaDecision = this.todayDate;
    this.caseId = this.route.snapshot.params['id'];
    this.getData();
  }

  getData(){
    this.mecIntService.getAllInfo(this.mecIntId).then(response => {
      const { fechaDecision, horaDecision, consideracionesInspector, medioPolicia, detalleOrdenPolicia, plazo, lugar, autoridadResuelve, interponeReposicion, interponeApelacion, sustentacionRecurso, decisionSobreRecursos, caso, audiencias } = response;
      console.log(response);
      this.mecInt = {
        fechaDecision: (<string>fechaDecision).split("T")[0],
        horaDecision,
        consideracionesInspector,
        medioPolicia,
        detalleOrdenPolicia,
        plazo,
        lugar,
        autoridadResuelve,
        sustentacionRecurso,
        decisionSobreRecursos,
        caso,
        audiencias
      }
      this.interponeApelacion = interponeApelacion;
      this.interponeReposicion = interponeReposicion;
    }).catch(err => {

    })
  }

  formatDate(date: Date | string, separator?: string): string {
    return this.utilsService.formatDate(date, separator);
  }

  changeAccomplishment(status){
    if(
      (status && !this.interponeReposicion) ||
      (!status && this.interponeReposicion) ||
      (status && this.interponeReposicion === undefined) ||
      (!status && this.interponeReposicion === undefined)
    ){
      this.interponeReposicion = status;
    } else {
      this.interponeReposicion = undefined;
    }
  }

  changeAccomplishmentApelacion(status){
    if(
      (status && !this.interponeApelacion) ||
      (!status && this.interponeApelacion) ||
      (status && this.interponeApelacion === undefined) ||
      (!status && this.interponeApelacion === undefined)
    ){
      this.interponeApelacion = status;
    } else {
      this.interponeApelacion = undefined;
    }
  }

  onSaveInfo(){
    this.mecInt.interponeReposicion = this.interponeReposicion;
    this.mecInt.interponeApelacion = this.interponeApelacion;
    this.mecInt.medioPolicia = Array.isArray(this.mecInt.medioPolicia) ? '' : this.mecInt.medioPolicia;
    console.log(this.mecInt)
    this.mecIntService.saveInfo(this.mecIntId, this.mecInt).catch(err => {
      console.log(err);
    })
  }

  async downloadPDF(){
    try {
      this.doc = new jsPDF('portrait','mm','a4');
      this.finalY = 15;
      this.fichaGeneral = await this.generalFileService.getGeneralInfo(this.caseId);
      this.mecPrevio = await this.mecPrevService.getAllInfo(this.caseId);
      this.setPrincipalTitle();
      this.doc.text(`Decisión y recursos mecanismo intermedio - caso ${this.fichaGeneral.caso.numCaso}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 15;
      this.getCCCs();
      this.getGeneralFileInfo();
      for(let sesion of this.mecInt.audiencias){
        let indice = this.mecInt.audiencias.indexOf(sesion);
        this.setPrincipalTitle();
        this.doc.text(`Sesión número ${indice+1}`, 15, this.finalY);
        this.finalY += 15;
        this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        this.setNormalStyle();
        this.getGeneralDataInfo(sesion);
        await this.getStatementsFromSesion(sesion);
      }
      this.getDecision();
      this.doc.save(`Decisión mecanismo intermedio - caso ${this.fichaGeneral.caso.numCaso}.pdf`);
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al descargar el PDF ' + err,
        icon: 'error'
      });
    }
  }

  getCCCs(): void {
    this.setTitleStyle();
    const rows = [
      [
        {
          nombre: 'titulo',
          nombreArreglado: 'Título',
        },
        {
          nombre: 'capitulo',
          nombreArreglado: 'Capítulo',
        }
      ],
      [
        {
          nombre: 'articulo',
          nombreArreglado: 'Artículo',
        },
        {
          nombre: 'numeral',
          nombreArreglado: 'Numeral',
        }
      ],
      [
        {
          nombre: 'literal',
          nombreArreglado: 'Literal',
        },
      ],
      [
        {
          nombre: 'descripcionComportamiento',
          nombreArreglado: 'Descripción del comportamiento',
        }
      ],
    ]
    this.setPrincipalTitle();
    this.doc.text('Comportamientos contrarios a la convivencia relacionados', 15, this.finalY);
    this.finalY += 8;
    for(let ccc of this.fichaGeneral.CCC){
      this.setTitleStyle()
      this.doc.text(`Comportamiento contrario a la convivencia #${this.fichaGeneral.CCC.findIndex(c => c == ccc)+1}`, 15, this.finalY);
      this.setNormalStyle()
      for(let row of rows){
        let j = 0;
        this.finalY = this.verifyEndOfPage({doc: this.doc, value: this.finalY}) + 12;
        for(let field of row){
          let i = 0;
          this.setTitleStyle()
          this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
          i++;
          this.setNormalStyle()
          if(ccc[field.nombre]){
            if(ccc[field.nombre].length > 140){
              this.doc.text(String(ccc[field.nombre].substring(0, 140) + '...'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
            } else {
              this.doc.text(String(ccc[field.nombre].substring(0, ccc[field.nombre].length)), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
            }
          } else {
            this.doc.text(String('No asignado'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
          }
          j++;
        }
        this.finalY += this.getMoreHeightFromTextHeight(ccc, row);
      }
      this.finalY += 10;
    }
  }



  getGeneralFileInfo(): void {
    this.setTitleStyle();
    this.doc.text('Hechos', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.fichaGeneral.resumenHechos || 'No existe un resumen de los hechos', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(`Lo anterior se dió en el lugar con la siguiente información: ${this.fichaGeneral.detalleLugarHechos || 'No existe información del detalle'}`, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Dirección de los hechos', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.fichaGeneral.direccionHechos || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Localidad / comuna / vereda / barrio', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.fichaGeneral.nombreLugarHechos || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Lugar de los hechos', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.fichaGeneral.lugarHechos || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Detalle del lugar de los hechos', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.fichaGeneral.detalleLugarHechos || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.setTitleStyle();
    this.doc.text('¿Se utilizó mecanismo previo?', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecPrevio.faseIntervencion?.actividades.length > 0  ? 'Sí': 'No', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Tipos de mecanismos previos utilizados', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    for(let mecanismo of this.mecPrevio.faseIntervencion.actividades){
      this.doc.text(`${this.formatDate(mecanismo.fechaActividad)} - ${mecanismo.tipoActividad}`, 15, this.finalY);
      this.finalY += 5;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
  }

  getGeneralDataInfo(sesion: Audiencia): void {
    this.setTitleStyle();
    this.doc.text('Fecha', 15, this.finalY);
    this.doc.text('Hora', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.formatDate(sesion.fechaAudiencia) || 'No definido', 15, this.finalY);
    this.doc.text(sesion.horaAudiencia || 'No definido', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Desarrollo de la audiencia', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    const bigText = `En el municipio de ${this.fichaGeneral.municipio || 'NO DEFINIDO'} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha ${sesion.autoFecha ? this.formatDate(sesion.autoFecha) : 'NO DEFINIDO'} y previas las comunicaciones de rigor, el Inspector, ${this.fichaGeneral.caso.inspector.codigoInspector || 'NO DEFINIDO'} da inicio a la Audiencia Pública.`
    this.doc.text(bigText, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 20;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('Observaciones (descripción detallada del caso)', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(sesion.observaciones || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('Otros medios utilizados por la Policía', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(sesion.otrosMediosUtilizados || 'No definido', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text('¿Asiste el (los) citado?', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    for(let citado of sesion.citados){
      this.doc.text(`${citado.nombreCompleto || (citado.nombre || '' + citado.apellido || '')}: ${citado.asiste ? 'Sí' : 'No'}`, 15, this.finalY, { maxWidth: 180 });
      this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      if(citado.asiste === false){
        this.doc.text(citado.justificaInasistencia ? 'Sí justifica inasistencia' : 'No justifica inasistencia', 15, this.finalY);
        this.finalY += 7;
      }
    }
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle()
    this.doc.text('¿Asiste Agente del Ministerio Público?', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(sesion.asisteAgente ? `Sí, ${sesion.nombreAgente}` : 'No', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
  }

  async getStatementsFromSesion(sesion: Audiencia){
    this.setTitleStyle();
    this.doc.text('Declaraciones realizadas en la audiencia', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    const informacionAdicional = await this.getAdditionalPDFFields(sesion.descargos.map(s => s.nombreCitado), sesion.descargos.map(s => s.identificacion));
    console.log(sesion.descargos);
    if(sesion.descargos.length === 0){
      this.doc.text('Esta audiencia no tiene declaraciones', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
    for(let descargo of sesion.descargos){
      let index = sesion.descargos.indexOf(descargo);
      this.setTitleStyle();
      this.doc.text(`Declaración realizada por ${descargo.nombreCitado}`, 15, this.finalY);
      this.finalY += 15;
      this.setNormalStyle();
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Tipo de identificación', 15, this.finalY);
      this.doc.text('Identificación', 105, this.finalY);
      this.setNormalStyle()
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text(descargo.tipoIdentificacion || 'No definido', 15, this.finalY);
      this.doc.text(descargo.identificacion || 'No definido', 105, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Correo', 15, this.finalY, { maxWidth: 90 });
      this.doc.text('Dirección', 105, this.finalY, { maxWidth: 90 });
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(informacionAdicional[index].correo || 'No definido', 15, this.finalY, { maxWidth: 90 });
      this.doc.text(informacionAdicional[index].direccion || 'No definido', 105, this.finalY, { maxWidth: 90 });
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Teléfono', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(informacionAdicional[index].telefono ? String(informacionAdicional[index].telefono) : 'No definido', 15, this.finalY)
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Declaración', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(descargo.declaracion || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += 25;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
  }

  getDecision(){
    this.setPrincipalTitle();
    this.doc.text('Decisión y recursos', 15, this.finalY);
    this.setTitleStyle();
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Fecha', 15, this.finalY);
    this.doc.text('Hora', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text(this.mecInt.fechaDecision.toString() || 'No definido', 15, this.finalY);
    this.doc.text(this.mecInt.horaDecision || 'No definido', 105, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Teniendo en cuenta la anterior información, relativa a los datos generales, descargos y otras declaraciones, y previas las siguientes consideraciones de hecho y de derecho:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Consideraciones del inspector', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.consideracionesInspector || 'No definido', 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.mecInt.consideracionesInspector || 'No definido');
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Se impone el siguiente medio de Policía', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.medioPolicia || 'No definido', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.detalleOrdenPolicia || 'No definido', 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(this.mecInt.detalleOrdenPolicia || 'No definido')
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Plazo', 15, this.finalY);
    this.doc.text('Lugar', 105, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.plazo || 'No definido', 15, this.finalY, { maxWidth: 90 });
    this.doc.text(this.mecInt.lugar || 'No definido', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Esta decisión queda notificada en estrados y se informa al destinatario que contra la misma procede recurso de reposición ante esta Inspección de Policía y de apelación ante:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 20;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('Autoridad que resuelve el recurso', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.autoridadResuelve, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 5;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text('recurso que debe sustentar en este momento', 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setTitleStyle();
    this.doc.text(`¿Interpone reposición? ${this.interponeReposicion === true ? 'Sí' : ''}${this.interponeReposicion === false ? 'No' : ''}${this.interponeReposicion === undefined ? 'No definido' : ''}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text(`¿Interpone reposición? ${this.interponeApelacion === true ? 'Sí' : ''}${this.interponeApelacion === false ? 'No' : ''}${this.interponeApelacion === undefined ? 'No definido' : ''}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.setNormalStyle();
    this.doc.text('Sustentación del recurso', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.sustentacionRecurso, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('Decisión sobre los recursos interpuestos', 15, this.finalY);
    this.finalY += 10;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.mecInt.decisionSobreRecursos, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    this.doc.text('La decisión sobre los recursos interpuestos queda notificada en estrados. Como quiera que se interpuso y se concedió apelación, se remite ante:', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(`Autoridad que resuelve el recurso : Oficina jurídica de la alcaldía municipal, a donde se enviará la actuación para que lo resuelva`, 15, this.finalY, { maxWidth: 180 });
    this.finalY += 15;
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
  }

  async getAdditionalPDFFields(nombresCompletos: string[], identificaciones: string[]): Promise<{ correo: string, direccion: string, telefono: string }[]> {
    const informacionOtros = nombresCompletos.filter(nombre => nombre === 'Otro').map(() => {
      return ({
        correo: 'No definido',
        direccion: 'No definido',
        telefono: 'No definido'
      })
    });
    const quoted = await this.mecIntService.findMultipleQuoted(this.caseId, identificaciones)
    const informacionEncontrados = quoted.map(q => {
      return ({
        correo: q.correo,
        direccion: q.direccion,
        telefono: String(q.telefono)
      })
    })
    return(informacionOtros.concat(informacionEncontrados))
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  /**
   * Gets the height to add from certain numbers of characters, because with more characters, more lines, this works with half-width texts
   * @param {any} object The object that is being reviewed
   * @param {{ nombre: string, nombreArreglado: string }[]} row The current row (of the pdf)
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeight(object, row: { nombre: string, nombreArreglado: string }[]): number {
    const biggestChainLength = this.findLongestChainLength(row.map(r => object[r.nombre]));
    if(biggestChainLength > 140){
      return 18;
    } else if (biggestChainLength <= 140 && biggestChainLength > 100){
      return 15;
    } else if (biggestChainLength <= 100){
      return 12;
    }
    return 12;
  }

  /**
   * Get the longest string from an array of strings;
   * @param {string[]} strings El array
   * @returns {number} the string length
   */
  findLongestChainLength(strings: string[]): number {
    const longest = strings.reduce((longestString, currentString) => {
      return currentString && currentString.length > longestString.length ? currentString : longestString;
    });
    return longest ? longest.length : 0;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    const length = string.length - 160;
    console.log(length);
    if(length/80 < 0){
      return 12;
    } else {
      return 12 + 3 * Math.ceil(length/80)
    }
  }

  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }
}
