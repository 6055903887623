<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h1 class="form-title">Seguimiento y evaluación</h1>
      <div class="tooltip-container">
        <img class="more-info-img" src="/assets/img/inspecciones/conocer-mas.png" alt="Más información"><span class="tooltip-text">En esta sección se puede registrar un seguimiento general a las acciones adelantadas en relación con un mismo caso. También se puede hacer una retroalimentación del uso de la plataforma</span>
      </div>
    </div>
    <button class="download-button"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png"
        alt="Flecha descargar"></button>
  </div>
  <form class="general-file-form" name="fichaGeneralForm" #fichaGeneralForm="ngForm" *ngIf="seguimientoEvaluacion">
    <div class="form-row">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="fechaSeguimiento">Fecha de diligenciamiento</label>
        <input
          class="inspections-input calendar-input"
          [ngModel]="seguimientoEvaluacion.fechaDiligenciamiento | date:'yyyy-MM-dd'"
          (ngModelChange)="formatSentDate($event)"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaSeguimiento"
          id="fechaSeguimiento">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label mb-20" for="casoCulminado">¿El caso fue culminado?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox" [checked]="seguimientoEvaluacion.culminado" (change)="changeAccomplishmentCasoCulminado(true)">
            <label class="inspections-label" for="si">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="!seguimientoEvaluacion.culminado && seguimientoEvaluacion.culminado !== undefined" (change)="changeAccomplishmentCasoCulminado(false)" >
            <label class="inspections-label" for="no">No</label>
          </div>
        </section>
      </div>
    </div>

    <h3 class="font18 bold">Mecanismos implementados</h3>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="mecanismosImplementado" class="inspections-label">Tipo de mecanismo implementado</label>
        <ngx-select-dropdown class="inspections-select" name="mecanismosImplementado" [(ngModel)]="mecanismoImplementado" [config]="configMecanismos" [options]="mecanismosUtilizados">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label for="detalleMecanismoImplementado" class="inspections-label">Detalle del mecanismo implementado</label>
        <input class="inspections-input" placeholder="Escribe aquí" type="text" [(ngModel)]="detalleMecanismoImplementado" name="detalleMecanismoImplementado"
          id="detalleMecanismoImplementado">
      </div>
    </div>
    <button class="aggregate-button mb-20" *ngIf="!editingMechanism" (click)="onAggregateMechanism()">+Agregar</button>
    <button class="aggregate-button mb-20" *ngIf="editingMechanism" (click)="onSaveMechanism()">Guardar</button>
    <div class="list" *ngIf="seguimientoEvaluacion.mecanismosImplementados.length > 0">
      <div class="list-row" *ngFor="let mecanismo of seguimientoEvaluacion.mecanismosImplementados; index as i">
        <div class="list-detail fit-height">
          <span>{{ mecanismo.nombre + ": " + mecanismo.detalle | truncate:200 }}</span>
          <div class="list-buttons right-double">
            <button class="selectable-button" (click)="editMechanism(i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt="Edit icon"></button>
            <button class="selectable-button" (click)="deleteMechanism(i)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="Delete icon"></button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label mb-20" for="cumplieronObjetivos">¿Se cumplieron los objetivos de convivencia?</label>
        <section class="d-flex-between-check">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si"  type="checkbox"  [checked]="seguimientoEvaluacion.cumplimientoConvivencia === 'yes'" (change)="changeAccomplishmentCumplieronObjetivos('yes')">
            <label class="inspections-label" for="si">Totalmente</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="parcialmente"  type="checkbox" [checked]="seguimientoEvaluacion.cumplimientoConvivencia === 'partially'" (change)="changeAccomplishmentCumplieronObjetivos('partially')">
            <label class="inspections-label" for="parcialmente">Parcialmente</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no"  type="checkbox" [checked]="seguimientoEvaluacion.cumplimientoConvivencia === 'no'" (change)="changeAccomplishmentCumplieronObjetivos('no')">
            <label class="inspections-label" for="no">No se cumplió</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row mb-40" *ngIf="cumplieronObjetivos">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="factoresSeguimiento">Factores que contribuyeron al cumplimiento de los objetivos convivencia</label>
        <textarea class="textarea" name="factoresSeguimiento" id="factoresSeguimiento" placeholder="Escribe aquí…" cols="30"></textarea>
      </div>
    </div>

    <div class="form-row mb-40" *ngIf="!cumplieronObjetivos && cumplieronObjetivos !== undefined">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="afectaronSeguimiento">Situaciones que afectaron el cumplimiento de los objetivos de convivencia</label>
        <textarea class="textarea" name="afectaronSeguimiento" id="afectaronSeguimiento" placeholder="Escribe aquí…"
          cols="30"></textarea>
      </div>
    </div>

    <div class="form-row mb-40">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="aspectosMejorar">Aspectos a mejorar frente a futuros casos similares</label>
        <textarea class="textarea" name="aspectosMejorar" [(ngModel)]="seguimientoEvaluacion.aspectosMejorar" id="aspectosMejorar" placeholder="Escribe aquí…"
          cols="30"></textarea>
      </div>
    </div>

    <div class="form-row mb-40">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="observacionesSeguimiento">Observaciones</label>
        <textarea class="textarea" name="observacionesSeguimiento" id="observacionesSeguimiento" [(ngModel)]="seguimientoEvaluacion.observaciones" placeholder="Escribe aquí…"
          cols="30"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label mb-20" for="utilPlataforma">¿Fue útil el uso de la plataforma Ensamble / módulo Inspecciones de Policía?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="si" type="checkbox" [checked]="seguimientoEvaluacion.plataformaUtil" (change)="changeAccomplishmentUtilPlataforma(true)">
            <label class="inspections-label" for="si">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="no" type="checkbox" [checked]="!seguimientoEvaluacion.plataformaUtil && seguimientoEvaluacion.plataformaUtil !== undefined" (change)="changeAccomplishmentUtilPlataforma(false)" >
            <label class="inspections-label" for="no">No</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row mb-40">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="facilidadesPlataforma">Aspectos de la plataforma que facilitaron su gestión como Inspector de Policía en el manejo del caso</label>
        <textarea class="textarea" [(ngModel)]="seguimientoEvaluacion.facilidadesPlataforma" name="facilidadesPlataforma" id="facilidadesPlataforma" placeholder="Escribe aquí…"
          cols="30"></textarea>
      </div>
    </div>

    <div class="form-row mb-40">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="aspectosMejorarPlataforma">Aspectos de la plataforma que se pueden mejorar. (recomendaciones)</label>
        <textarea class="textarea" name="aspectosMejorarPlataforma" id="aspectosMejorarPlataforma" [(ngModel)]="seguimientoEvaluacion.aspectosMejorarPlataforma" placeholder="Escribe aquí…"
          cols="30"></textarea>
      </div>
    </div>

    <label class="inspections-label mb-20" for="calificacion">Calificación global de la plataforma</label>
    <label class="inspections-label mb-20" for="unoDiez">(1 calificación más baja, 5 calificación más alta) </label>
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <ngx-select-dropdown
          class="inspections-select"
          name="calificacionNumero"
          [config]="config"
          [options]="estrellasPlataforma"
          [(ngModel)]="seguimientoEvaluacion.calificacionPlataforma"
          >
        </ngx-select-dropdown>
      </div>
    </div>
  </form>
  <div class="d-flex-between">
    <button class="submit-button" (click)="saveInfo()">Guardar</button>
  </div>
</div>
