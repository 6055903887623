import { Injectable } from '@angular/core';
import { PvaService } from '../inspecciones/gestion-casos-inspecciones/pva/pva.service';
import { jsPDF } from 'jspdf';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Archivo, FichaGeneral, Inspector } from '../inspecciones/inspecciones.interface';
import { UtilsService } from './utils.service';
import Swal from 'sweetalert2';
import * as QRCode from 'qrcode';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  private doc: jsPDF;
  private finalY: number;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private pvaService: PvaService
  ) { }

  async getHeadingInfo({ doc, fichaGeneralData, finalY }: { doc: jsPDF, fichaGeneralData: FichaGeneral, finalY: number }) {
    this.doc = doc;
    this.finalY = finalY;
    const rows = [
      [{
          nombre: 'createdAt',
          nombreArreglado: 'Fecha de creación del caso',
          tipo: 'NORMAL-FECHA'
        }
      ],[
        {
          nombre: 'documentoOrigen',
          nombreArreglado: 'Documento de origen de la información',
          tipo: 'NORMAL'
        }
      ],
      [
        {
          nombre: 'archivosDocumentoOrigen',
          nombreArreglado: 'Archivos de origen de la información',
          tipo: 'MULTIPLEQR'
        }
      ],[
        {
          nombre: 'fechaDocumentoOrigen',
          nombreArreglado: 'Fecha del documento de origen',
          tipo: 'NORMAL-FECHA'
        },
        {
          nombre: 'radicado',
          nombreArreglado: 'Radicado',
          tipo: 'NORMAL'
        }
      ],[
        {
          nombre: 'otrosDocumentos',
          nombreArreglado: 'Otros documentos',
          tipo: 'MULTIPLEQR'
        }
      ],[
        {
          nombre: 'departamento',
          nombreArreglado: 'Departamento',
          tipo: 'NORMAL'
        },
        {
          nombre: 'municipio',
          nombreArreglado: 'Municipio/Ciudad',
          tipo: 'NORMAL'
        }
      ]
    ];

    for(let row of rows){
      let j = 0;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
      for(let field of row){
        let i = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
        if(field.tipo === 'NORMAL'){
          this.setTitleStyle();
          this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
          i++;
          this.setNormalStyle();
          this.doc.text(fichaGeneralData[field.nombre], 15+(j*90), this.finalY+(i*10));
          j++;
        } else if (field.tipo === 'NORMAL-FECHA') {
          this.setTitleStyle();
          this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
          i++;
          this.setNormalStyle();
          this.doc.text(this.utilsService.formatDate(fichaGeneralData[field.nombre]), 15+(j*90), this.finalY+(i*10));
          j++;
        } else if (field.tipo === 'MULTIPLEQR'){
          this.setTitleStyle();
          this.doc.text(field.nombreArreglado, 15, this.finalY+(i*10));
          this.setNormalStyle();
          const multipleFileArray: Archivo[] = fichaGeneralData[field.nombre];
          let right = 0;
          for(let file of multipleFileArray){
            const url = await QRCode.toDataURL(file.url);
            if(right > 6){
              right = 0;
              this.finalY += 20;
              this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
              this.doc.addImage(url, 'JPEG', 15+(right*25),this.finalY+5, 20, 20);
            } else {
              this.doc.addImage(url, 'JPEG', 15+(right*25), this.finalY+5, 20, 20);
            }
            right++;
          }
          this.finalY += 15;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
        }
      }
      this.finalY += 20;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
    }
    return { finalY: this.finalY, doc: this.doc }
  }

  async getLastGeneralFileInfo({ doc, fichaGeneralData, finalY }: { doc: jsPDF, fichaGeneralData: FichaGeneral, finalY: number }): Promise<{ doc: jsPDF, finalY: number }>  {
    this.doc = doc;
    this.finalY = finalY;
    this.setTitleStyle();
    this.doc.text('Resumen de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.resumenHechos, 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(fichaGeneralData.resumenHechos);
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });

    this.setTitleStyle();
    this.doc.text('Fecha', 15, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.fechaHechos.toString() || 'No definido', 15, this.finalY+10, { maxWidth: 90 });
    this.setTitleStyle();
    this.doc.text('Hora', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.horaHechos, 105, this.finalY+10, { maxWidth: 90 });
    this.finalY += 20;
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });

    this.setTitleStyle();
    this.doc.text(`Tipo de localidad de los hechos: ${fichaGeneralData.tipoLocalidadHechos}`, 15, this.finalY);
    this.setNormalStyle();

    this.finalY += 15;
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });

    this.setTitleStyle();
    this.doc.text('Lugar de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.lugarHechos, 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });

    this.setTitleStyle();
    this.doc.text('Dirección de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.direccionHechos, 15, this.finalY);
    this.finalY += 15;
    this.finalY =await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
    console.log('final0', this.finalY);
    this.setTitleStyle();
    this.doc.text('Detalle del lugar de los hechos', 15, this.finalY);
    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
    console.log('final1', this.finalY);
    this.setNormalStyle();
    this.doc.text(fichaGeneralData.detalleLugarHechos, 15, this.finalY);
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(fichaGeneralData.detalleLugarHechos);
    console.log('final2', this.finalY);
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
    console.log('final3', this.finalY);
    return { doc: this.doc, finalY: this.finalY };
  }

  async getCCCsInfo({ doc, fichaGeneralData, finalY }: { doc: jsPDF, fichaGeneralData: FichaGeneral, finalY: number }): Promise<{ doc: jsPDF, finalY: number }> {
    this.doc = doc;
    this.finalY = finalY;
    console.log('el final aqui es', this.finalY);
    const rows = [
      [
        {
          nombre: 'titulo',
          nombreArreglado: 'Título',
        },
        {
          nombre: 'capitulo',
          nombreArreglado: 'Capítulo',
        }
      ],
      [
        {
          nombre: 'articulo',
          nombreArreglado: 'Artículo',
        },
        {
          nombre: 'numeral',
          nombreArreglado: 'Numeral',
        }
      ],
      [
        {
          nombre: 'literal',
          nombreArreglado: 'Literal',
        },
      ],
      [
        {
          nombre: 'descripcionComportamiento',
          nombreArreglado: 'Descripción del comportamiento',
        }
      ],
    ]

    for(let ccc of fichaGeneralData.CCC){
      this.setTitleStyle();
      this.doc.text(`Comportamiento contrario a la convivencia #${fichaGeneralData.CCC.findIndex(c => c == ccc)+1}`, 15, this.finalY);
      this.finalY += 15;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
      this.setNormalStyle();
      for(let row of rows){
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
        for(let field of row){
          let i = 0;
          if(row.length > 1){
            this.setTitleStyle();
            this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
            i++;
            this.setNormalStyle();
            if(ccc[field.nombre]){
              if(ccc[field.nombre].length > 140){
                this.doc.text(String(ccc[field.nombre].substring(0, 140) + '...'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
              } else {
                this.doc.text(String(ccc[field.nombre].substring(0, ccc[field.nombre].length)), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
              }
            } else {
              this.doc.text(String('No asignado'), 15+(j*90), this.finalY+(i*10), { maxWidth: 90 });
            }
            j++;
          }
        }
        this.finalY += 30;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });;
      }
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });;
    }
    return { doc: this.doc, finalY: this.finalY };
  }

  async getPeopleInfo({ doc, fichaGeneralData, finalY }: { doc: jsPDF, fichaGeneralData: FichaGeneral, finalY: number }): Promise<{ doc: jsPDF, finalY: number }> {
    this.doc = doc;
    this.finalY = finalY;
    const rowsInfractors = [
      [
        {
          nombre: 'nombreInfractor',
          nombreArreglado: 'Nombres',
        },
        {
          nombre: 'apellidoInfractor',
          nombreArreglado: 'Apellidos',
        },
      ],
      [
        {
          nombre: 'tipoIdentificacionInfractor',
          nombreArreglado: 'Tipo de identificación',
        },
        {
          nombre: 'identificacionInfractor',
          nombreArreglado: 'Identificación',
        },
      ],
      [
        {
          nombre: 'nivelEducativoInfractor',
          nombreArreglado: 'Nivel educativo',
        },
        {
          nombre: 'ocupacionInfractor',
          nombreArreglado: 'Ocupación',
        },
      ],
      [
        {
          nombre: 'tipoVulnerabilidadInfractor',
          nombreArreglado: 'Tipo de población vulnerable',
        },
        {
          nombre: 'edadInfractor',
          nombreArreglado: 'Edad',
        },
      ],
      [
        {
          nombre: 'direccionInfractor',
          nombreArreglado: 'Dirección residencia',
        },
        {
          nombre: 'telefonoInfractor',
          nombreArreglado: 'Teléfono',
        },
      ],
      [
        {
          nombre: 'correoInfractor',
          nombreArreglado: 'Correo'
        }
      ]
    ]
    const rowsPetitioners = [
      [
        {
          nombre: 'nombrePeticionario',
          nombreArreglado: 'Nombres',
        },
        {
          nombre: 'apellidoPeticionario',
          nombreArreglado: 'Apellidos',
        },
      ],
      [
        {
          nombre: 'tipoIdentificacionPeticionario',
          nombreArreglado: 'Tipo de identificación',
        },
        {
          nombre: 'identificacionPeticionario',
          nombreArreglado: 'Identificación',
        },
      ],
      [
        {
          nombre: 'nivelEducativoPeticionario',
          nombreArreglado: 'Nivel educativo',
        },
        {
          nombre: 'ocupacionPeticionario',
          nombreArreglado: 'Ocupación',
        },
      ],
      [
        {
          nombre: 'tipoVulnerabilidadPeticionario',
          nombreArreglado: 'Tipo de población vulnerable',
        },
        {
          nombre: 'edadPeticionario',
          nombreArreglado: 'Edad',
        },
      ],
      [
        {
          nombre: 'direccionPeticionario',
          nombreArreglado: 'Dirección residencia',
        },
        {
          nombre: 'telefonoPeticionario',
          nombreArreglado: 'Teléfono',
        },
      ],
      [
        {
          nombre: 'correoPeticionario',
          nombreArreglado: 'Correo'
        }
      ]
    ]
    for(let infractor of fichaGeneralData.infractores){
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
      this.setTitleStyle();
      this.doc.text(`Información del infractor ${fichaGeneralData.infractores.indexOf(infractor) + 1}`, 15, this.finalY);
      this.finalY += 10;
      this.setNormalStyle();
      for(let row of rowsInfractors){
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
        for(let field of row){
          let i = 0;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
          this.setTitleStyle();
          this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
          i++;
          this.setNormalStyle();
          this.doc.text(infractor[field.nombre] ? String(infractor[field.nombre]) : 'No definido', 15+(j*90), this.finalY+(i*10));
          j++;
        }
        this.finalY += 20;
      }
    }

    for(let peticionario of fichaGeneralData.peticionarios){
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
      this.setTitleStyle();
      this.doc.text(`Información del peticionario ${fichaGeneralData.peticionarios.indexOf(peticionario) + 1}`, 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
      this.setNormalStyle();
      for(let row of rowsPetitioners){
        let j = 0;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
        for(let field of row){
          let i = 0;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, value: this.finalY, numCaso: fichaGeneralData.caso.numCaso, numInspeccion: fichaGeneralData.caso.numInspeccion });
          this.setTitleStyle();
          this.doc.text(field.nombreArreglado, 15+(j*90), this.finalY+(i*10));
          i++;
          this.setNormalStyle();
          this.doc.text(peticionario[field.nombre] ? String(peticionario[field.nombre]) : 'No definido', 15+(j*90), this.finalY+(i*10));
          j++;
        }
        this.finalY += 20;
      }
    }
    return { doc: this.doc, finalY: this.finalY };
  }

  async getPVAAudience({ doc, idCaso, numInspeccion, numCaso, fichaGeneral, inspector, finalY }: { doc: jsPDF, idCaso: string, numInspeccion: string, numCaso: string, fichaGeneral: FichaGeneral, inspector: Inspector, finalY: number }): Promise<{ doc: jsPDF, finalY: number }> {
    const pva = await this.pvaService.getAllInfoByCase(idCaso);
    this.doc = doc || new jsPDF('portrait', 'mm', 'a4');
    this.finalY = finalY;
    this.setTitleStyle();
    this.doc.text('Datos generales de la audiencia pública', 15, this.finalY);
    this.finalY += 10;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
    for(let audiencia of pva.audiencias){
      this.setTitleStyle();
      this.doc.text('Fecha', 15, this.finalY);
      this.doc.text('Hora', 105, this.finalY);
      this.setNormalStyle();
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text(audiencia.fechaAudiencia ? this.utilsService.formatDate(audiencia.fechaAudiencia) : 'No definido', 15, this.finalY);
      this.doc.text(audiencia.horaAudiencia || 'No definido', 105, this.finalY, { maxWidth: 180 });
      this.finalY += 15;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setTitleStyle();
      this.doc.text('Lugar', 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text(audiencia.direccionAudiencia || 'No definido', 15, this.finalY, { maxWidth: 180, align: 'justify' });
      this.finalY += 15;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setTitleStyle();
      this.doc.text('Desarrollo de la audiencia', 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      const longText = `En el municipio de ${fichaGeneral.municipio || 'SIN DEFINIR'} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha ${audiencia.autoFecha ? this.utilsService.formatDate(audiencia.autoFecha) : 'SIN DEFINIR'} y previas las comunicaciones de rigor, el Inspector, ${inspector?.codigoInspector || 'SIN DEFINIR'} da inicio a la Audiencia Pública del Proceso Verbal Abreviado del expediente de la referencia, con el propósito de agotar el trámite previsto en el artículo 223 de la Ley 1801 de 2016.`
      this.doc.text(longText, 15, this.finalY, { maxWidth: 180, align: 'justify' });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(longText);
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      // this.setTitleStyle();
      // this.doc.text('Hechos', 15, this.finalY);
      // this.setNormalStyle();
      // this.finalY += 10;
      // this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setTitleStyle();
      this.doc.text('¿Asiste el (los) citado(s)?', 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setNormalStyle();
      for(let citado of audiencia.citados){
        this.doc.text(`${citado.nombreCompleto}: ${citado.asiste ? 'Sí' : 'No'}`, 15, this.finalY);
        this.finalY += 7;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
        if(citado.asiste === false){
          this.finalY -= 2;
          this.doc.text(`¿Justifica inasistencia? ${citado.justificaInasistencia ? 'Sí' : 'No'}`, 15, this.finalY);
          this.finalY += 7;
          this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
        }
      }
      this.setTitleStyle();
      this.doc.text('¿Asiste agente del Ministerio Público?', 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setNormalStyle();
      this.doc.text(`${audiencia.asisteAgente ? 'Sí, ' + audiencia.nombreAgente : 'No'}`, 15, this.finalY);
      this.finalY += 15;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
    }
    this.setTitleStyle();
    this.doc.text(`¿Es querella civil? ${fichaGeneral.esQuerellaCivil ? 'Sí' : 'No'}`, 15, this.finalY);
    this.finalY += 15;
    this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
    if(fichaGeneral.esQuerellaCivil){
      this.doc.text('Conciliación', 15, this.finalY);
      this.finalY += 15;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text('Teniendo en cuenta que se trata de una querella Civil y dada las características y naturaleza del asunto, el Inspector de conocimiento, previas las formalidades legales, invita a las partes a llegar a un acuerdo respecto del conflicto objeto de querella, frente a lo cual, se hacen las siguientes manifestaciones:', 15, this.finalY, { maxWidth: 180, align: 'justify' });
      this.finalY += 30;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setNormalStyle();
      this.doc.text('Por parte del (los) querellantes', 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text(fichaGeneral.manifestacionesQuerellantes || 'No definido', 15, this.finalY);
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(fichaGeneral.manifestacionesQuerellantes || 'No definido');
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text('Por parte del (los) querellado(s):', 15, this.finalY);
      this.finalY += 10;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.doc.text(fichaGeneral.manifestacionesQuerellados || 'No definido', 15, this.finalY);
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(fichaGeneral.manifestacionesQuerellados || 'No definido');
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      this.setTitleStyle();
      this.doc.text(`Frente a lo anterior y como quiera que se ha manifestado la voluntad de: ${fichaGeneral.haceConciliacion ? 'Sí conciliar' : 'No conciliar'}`, 15, this.finalY, { maxWidth: 180, align: 'justify' });
      this.finalY += 20;
      this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
      if(fichaGeneral.haceConciliacion){
        this.doc.text('Luego de escuchar a las partes y sus respectivas propuestas, se ha llegado al siguiente acuerdo:', 15, this.finalY, { maxWidth: 180, align: 'justify' });
        this.setNormalStyle();
        this.finalY += 20;
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
        this.doc.text(fichaGeneral.acuerdo || 'No definido', 15, this.finalY, { maxWidth: 180, align: 'justify' });
        this.finalY += this.getMoreHeightFromTextHeightFullWidth(fichaGeneral.acuerdo || 'No definido');
        this.finalY = await this.verifyEndOfPage({ doc: this.doc, numInspeccion, numCaso, value: this.finalY });
        this.doc.text('Se deja constancia que el presente acuerdo fue logrado por la libre voluntad de las partes y se imprimen copias del acta para cada una de las partes, la cual presta mérito ejecutivo y hace tránsito a cosa juzgada.', 15, this.finalY, { maxWidth: 180, align: 'justify' });
      } else if (fichaGeneral.haceConciliacion === false){
        this.doc.text('Como se ha informado el interés de no conciliar por ambas o alguna de las dos partes, o no se ha llegado a un acuerdo, se da continuación a la audiencia pública.', 15, this.finalY, { maxWidth: 180, align: 'justify' });
      }
    }
    return { doc: this.doc, finalY: this.finalY };
  }

  /**
   * Gets the height to add from certain numbers of characters, because with more characters, more lines, this works with half-width texts
   * @param {any} object The object that is being reviewed
   * @param {{ nombre: string, nombreArreglado: string }[]} row The current row (of the pdf)
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeight(object, row: { nombre: string, nombreArreglado: string }[]): number {
    const biggestChainLength = this.findLongestChainLength(row.map(r => object[r.nombre]));
    if(biggestChainLength > 140){
      return 18;
    } else if (biggestChainLength <= 140 && biggestChainLength > 100){
      return 15;
    } else if (biggestChainLength <= 100){
      return 12;
    }
    return 12;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    let additional = 12;
    const stringLength = string.length - 160;
    if(stringLength > 0){
      additional += Math.floor(stringLength / 15);
      return additional;
    }
    return additional;
  }

  /**
   * Get the longest string from an array of strings;
   * @param {string[]} strings El array
   * @returns {number} the string length
   */
  findLongestChainLength(strings: string[]): number {
    const longest = strings.reduce((longestString, currentString) => {
      return currentString && currentString.length > longestString.length ? currentString : longestString;
    });
    return longest ? longest.length : 0;
  }

  /**
   * Le da estilo al texto del PDF para títulos principales
   */
  setTitleStyle(){
    this.doc.setFont('Helvetica', 'normal', 'bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Le da estilo al texto del PDF para texto normal
   */
  setNormalStyle(){
    this.doc.setFont('Helvetica', 'normal', 'normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  async verifyEndOfPage({ doc, numCaso, numInspeccion, value }: { doc: jsPDF; numCaso: string, numInspeccion: string, value: number }): Promise<number> {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      this.doc.addPage();
      this.doc = await this.getPDFHeader({doc, numCaso, numInspeccion });
      return 40;
    } else {
      return value;
    }
  }

  /**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo
   * @returns la url
   */
  getImageFromFileType(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  /**
   * Obtiene la imagen en base64 a partir de un archivo
   * @param imagePath El path de la imagen en el proyecto
   * @returns Un observable de la respuesta en base64
   */
  getBase64Image(imagePath: string): Observable<any> {
    return this.http.get(imagePath, { responseType: 'blob' }).pipe(
        switchMap((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            return new Observable((observer) => {
                reader.onload = () => {
                  const base64 = reader.result;
                  observer.next({ success: true, data: base64 });
                  observer.complete();
                };
                reader.onerror = (error) => {
                  observer.error({ success: false, error });
                };
            });
        }),
    );
  }


  /**
  Obtiene el header del PDF con los datos de la inspección
  @param doc - Instancia del documento PDF
  @param numCaso - Número de caso de la inspección
  @param numInspeccion - Número de la inspección
  @returns - Promesa con la instancia del documento PDF con el header agregado
  */
  getPDFHeader({doc, numCaso, numInspeccion}: { doc: jsPDF, numCaso: string, numInspeccion: string }): Promise<jsPDF> {
    return new Promise((resolve, reject) => {
      this.getBase64Image('/assets/img/inspecciones/logo-ensamble.png')
      .subscribe((response: { success: boolean, data?: string | ArrayBuffer, error?: ProgressEvent<FileReader> }) => {
        if(response.error){
          Swal.fire({
            title: '¡Error!',
            text: `Hubo un error al generar el PDF: ${response.error}`,
            icon: 'error'
          });
          reject(response.error);
        }
        doc.setFont('normal');
        doc.setFontSize(13);
        doc.setTextColor('#332F2E');
        doc.addImage(response.data.toString(), 'JPEG', 15, 0, 40, 40);
        doc.text(`Número caso: ${numCaso || 'No definido'}`, 105, 15);
        doc.text(`Número inspección: ${numInspeccion || 'No definido'}`, 105, 20);
        doc.text(`Fecha de descarga: ${this.utilsService.formatDate(new Date())}`, 105, 25);
        resolve(doc);
      })
    })
  }
}
