
<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<main class="inspecciones-principal">
  <button class="go-back-button mt-20" routerLink="/inicio-inspecciones">
    <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
  </button>
  <div class="d-flex-between">

    <h1 class="principal-title">Gestión de casos</h1>
    <button class="button active" routerLink="/gestion-casos-inspecciones/nuevo-caso">Nuevo caso +</button>
  </div>
  <div class="gestion-principal">
    <div class="d-flex-between mb-20 pb-20 light-border">
      <h2 class="secondary-title">Histórico de casos</h2>
      <!-- <button class="button active padding-0" (click)="filtrarCasos()"><img class="filter-img" src="../../../assets/img/inspecciones/filtro.png" alt="filtro"></button> -->
    </div>
    <mat-form-field class="width-100">
      <mat-label>Filtrar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Escribe aquí el valor que quieres buscar" #input>
    </mat-form-field>
    <table mat-table aria-label="Una tabla para la gestión de casos" [dataSource]="dataSource">
      <ng-container matColumnDef="actions">
        <th aria-label="Columna de acciones" mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let element">
          <div class="icons-container">
            <mat-icon aria-label="Editar registro" class="gray-icon" fontIcon="edit" (click)="onCustom(element, 'edit')"></mat-icon>
            <mat-icon aria-label="Bloquear registro" class="gray-icon" fontIcon="block" (click)="onCustom(element, 'block')"></mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="tipoComportamiento">
        <th aria-label="Columna de tipos de comportamiento" mat-header-cell *matHeaderCellDef> Tipo de comportamiento</th>
        <td mat-cell *matCellDef="let element"> {{element.tipoComportamiento}} </td>
      </ng-container>
      <ng-container matColumnDef="numCaso">
        <th aria-label="Columna de número de caso" mat-header-cell *matHeaderCellDef> No. de caso </th>
        <td mat-cell *matCellDef="let element"> {{element.numCaso}} </td>
      </ng-container>
      <ng-container matColumnDef="peticionariosNombres">
        <th aria-label="Columna de peticionarios" mat-header-cell *matHeaderCellDef> Peticionarios </th>
        <td mat-cell *matCellDef="let element"> {{element.peticionariosNombres}} </td>
      </ng-container>
      <ng-container matColumnDef="infractoresNombres">
        <th aria-label="Columna de infractores" mat-header-cell *matHeaderCellDef> Infractores </th>
        <td mat-cell *matCellDef="let element"> {{element.infractoresNombres}} </td>
      </ng-container>
      <ng-container matColumnDef="fechaHechos">
        <th aria-label="Columna de fecha de los hechos" mat-header-cell *matHeaderCellDef> Fecha de los hechos </th>
        <td mat-cell *matCellDef="let element"> {{element.fechaHechos}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Selecciona una página de la lista de casos"
    ></mat-paginator>
  </div>
</main>
