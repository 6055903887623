import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { S3 } from 'aws-sdk';
import { MainService } from '../../../../services/main.service';
import { MecanismoPrevioService } from '../mecanismo-previo.service';
import Swal from 'sweetalert2';
import { ActividadDesarrollada, Archivo, Caso, FichaGeneral, MecanismoPrevio } from '../../../inspecciones.interface';
import { UtilsService } from '../../../../services/utils.service';
@Component({
  selector: 'app-fase-diagnostica',
  templateUrl: './fase-diagnostica.component.html',
  styleUrls: ['./fase-diagnostica.component.css']
})
export class FaseDiagnosticaComponent implements OnInit {
  user: any = {};
  readonly config = {
    search:true,
    placeholder:'Seleccione',
    noResultsFound: "No se encontraron resultados"
  };
  readonly configCasosAsociados = {
    placeholder: "Seleccione",
    search: true,
    noResultsFound: "No se encontraron resultados",
    displayFn: (item) => {
      return item.numCaso;
    }
  }
  caseId: string = '';
  mecPrevId: string = '';
  // ACTIVITIES
  savedActivity: any;
  activity: ActividadDesarrollada = {
    fecha: undefined,
    hora: '',
    direccion: '',
    barrio: '',
    observaciones: '',
    registroFotografico: [],
  }

  cccs: any[] = [];
  tiposCasos = [];
  casosAsociados: Caso[] = [];
  casosAsociadosSeleccionados: Caso[] = [];
  tipoCaso: string = '';

  activities: ActividadDesarrollada[] = [];
  wantEditActivity: boolean = false;
  isNewActivity: boolean = false;

  infractors = [];

  todayDate = new Date().toISOString().split("T")[0]

  /** S3 */
  bucket: S3
  constructor(
    private route: ActivatedRoute,
    private mainService: MainService,
    private mecPrevService: MecanismoPrevioService,
    private utilsService: UtilsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.user = JSON.parse(localStorage.getItem('inspector'));
    this.getCaseInfo();
    this.getPrevMec();
  }

  formatDate(date: Date){
    return this.utilsService.formatDate(date);
  }

  newActivity(){
    this.isNewActivity = true;
  }

  /**
   * Get the general information, principally for the infractors and CCCs
   */
  getCaseInfo(){
    this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<FichaGeneral>(response)){
        this.infractors = response.result.infractores
        this.cccs = response.result.CCC;
        this.configCaseType()
      } else {
        console.error(response.error);
      }
    });
  }

  /**
   * Get the diagnostic phase information
   */
  getPrevMec(){
    this.mainService.get<MecanismoPrevio>(`api/mec-previo-caso/caso/${this.caseId}`).subscribe((response) => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        const resp: MecanismoPrevio = response.result;
        if(!resp){
          Swal.fire({
            title: 'Error',
            text: 'Hubo un error al cargar la información de este caso',
            icon: 'error'
          });
          this.router.navigate(['/gestion-casos-inspecciones'])
          return;
        }
        this.mecPrevId = resp._id;
        this.activities = resp.faseDiagnostica.actividadesDesarrolladas;
        console.log(resp.faseDiagnostica);
        this.tipoCaso = resp.faseDiagnostica.tipoCaso;
        const casosAsociados = resp.faseDiagnostica.casosAsociados;
        this.getAssociatedCases(casosAsociados);
      }
    })
  }

  /**
   * Searches for the case types of the CCCs that are in the general file
   */
  configCaseType(){
    for(let caso of this.cccs){
      this.tiposCasos = [...this.tiposCasos, caso.tipo];
    }
    this.tiposCasos = this.tiposCasos.filter((value, index) => {
      return this.tiposCasos.indexOf(value) === index;
    });
  }

  getAssociatedCases(casosAsociados: Caso[]){
    this.mainService.get<Caso[]>(`api/caso`).subscribe(response => {
      if(this.mainService.isSuccess<Caso[]>(response)){
        const casos: Caso[] = response.result;
        if(casosAsociados.length > 0){
        this.casosAsociadosSeleccionados = casos.filter(caso => {
          return (
            caso.inspector === this.user._id &&
            casosAsociados.find(casoAsociado => casoAsociado._id === caso._id)
          );
        });
        }
        this.casosAsociados = casos.filter(caso => caso.inspector === this.user._id);
      }
    })
  }

  onFileChange(event){
    const files: File[] = Object.values(event.target.files);
    console.log(files);
    const result = Promise.all(files.map((file) => this.mecPrevService.saveInS3(file)));
    result.then((res: string[]) => {
      const photosLinks: string[] = res;
      this.mecPrevService.showS3UploadSuccess();
      for(let i = 0; i < photosLinks.length; i++){
        this.activity.registroFotografico.push({
          url: photosLinks[i],
          tipo: files[i].type
        });
      }
    }).catch(err => {
      this.mecPrevService.showS3UploadError();
      console.error(err)
    })
  }

  /**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo
   * @returns la url
   */
  getImageFromTypeFile(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  deletePhoto(index){
    Swal.fire({
      title: 'Borrar registro',
      text: '¿Deseas eliminar este registro?',
      icon: 'question',
      confirmButtonText: 'Sí, borrar',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar'
    }).then(value => {
      if(value){
        this.activity.registroFotografico.splice(index, 1);
      }
    })
  }

  /**
   * Clean only the activity inputs, for saving and creating
   */
  cleanActivityInputs(){
    this.activity = {
      fecha: undefined,
      hora: '',
      direccion: '',
      barrio: '',
      observaciones: '',
      registroFotografico: []
    }
  }

  /**
   * Saves the edited activity in the activities array
   * @param f the form
   */
  saveActivity(f: NgForm){
    const form = f.value;
    if(form.fechaActividadDesarrollada != undefined && form.fechaActividadDesarrollada != '' && form.observacionesActividadDesarrollada != undefined && form.observacionesActividadDesarrollada != ''){
      const actividadDesarrollada = {
        fecha: form.fechaActividadDesarrollada,
        hora: form.horaActividadDesarrollada,
        direccion: form.direccionActividadDesarrollada,
        barrio: form.barrioActividadDesarrollada,
        observaciones: form.observacionesActividadDesarrollada,
        registroFotografico: this.activity.registroFotografico
      }
      const activity2ChangeId = this.activities.findIndex(activity => {
        return (activity.fecha === this.savedActivity.fecha && activity.observaciones === this.savedActivity.observaciones);
      })
      this.activities[activity2ChangeId] = actividadDesarrollada;
      this.cleanActivityInputs();
      this.isNewActivity = false;
      this.wantEditActivity = false;
    } else {
      Swal.fire(
        'Error',
        'No se han llenado los campos necesarios',
        'error'
      )
      return;
    }
  }

  /**
   * Saves a new activity to the activities array
   */
  addActivity(f: NgForm): void {
    const form = f.value;
    if(form.fechaActividadDesarrollada != undefined && form.fechaActividadDesarrollada != '' && form.observacionesActividadDesarrollada != undefined && form.observacionesActividadDesarrollada != ''){
      const actividadDesarrollada = {
        fecha: form.fechaActividadDesarrollada,
        hora: form.horaActividadDesarrollada,
        direccion: form.direccionActividadDesarrollada,
        barrio: form.barrioActividadDesarrollada,
        observaciones: form.observacionesActividadDesarrollada,
        registroFotografico: this.activity.registroFotografico
      }
      this.activities.push(actividadDesarrollada);
      this.isNewActivity = false;
      this.cleanActivityInputs();
    } else {
      Swal.fire(
        'Error',
        'No se han llenado los campos necesarios',
        'error'
      );
      return;
    }
  }

  /**
   * Puts the activity information in the form
   * @param activity the current info in the form
   */
  editActivity(activity: ActividadDesarrollada): void {
    this.wantEditActivity = true;
    this.activity = activity;
    this.activity.fecha = activity.fecha ? this.utilsService.formatInputDate(activity.fecha) : undefined;
    this.savedActivity = activity;
  }

  /**
   * Cancels edition and cleans inputs
   */
  cancelEdit(){
    this.wantEditActivity = false;
    this.isNewActivity = false;
    this.cleanActivityInputs();
    this.savedActivity = undefined;
  }

  /**
   * Searches the activity in the list and deletes it
   * @param act The row activity
   */
  deleteActivity(index: number): void {
    if(this.wantEditActivity){
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar esta actividad?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.activities.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'La actividad se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Updates the diagnostic phase
   * @param f The form
   */
  updateDiagnostic(f: NgForm){
    const updateDate = {
      faseDiagnostica: {
        tipoCaso:  this.tipoCaso,
        casosAsociados: this.casosAsociadosSeleccionados.map(caso => caso._id),
        actividadesDesarrolladas: this.activities,
      }
    }
    console.log(updateDate);
    this.mainService.put<MecanismoPrevio>(`api/mec-previo-caso/${this.mecPrevId}`, updateDate).subscribe(response => {
      console.log(response);
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        Swal.fire(
          'Éxito',
          'Fase diagnóstica actualizada con éxito',
          'success'
        );
      } else {
        Swal.fire(
          'Error',
          'Hubo un problema al actualizar la fase diagnóstica',
          'error'
        );
      }
    })
  }

}
