import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MecanismoIntermedioService } from '../mecanismo-intermedio/mecanismo-intermedio.service';

@Component({
  selector: 'app-reporte-final-intermedio',
  templateUrl: './reporte-final-intermedio.component.html',
  styleUrls: ['./reporte-final-intermedio.component.css']
})
export class ReporteFinalIntermedioComponent implements OnInit {

  caseId: string;
  mecInt: any = {};
  inspector: any = {};
  seguimientos: any[] = [];
  audiencias: any[] = [];

  constructor(
    private mecIntService: MecanismoIntermedioService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getData();
  }

  onGoBack(){
    this.router.navigateByUrl(`/gestion-casos-inspecciones/ver-caso/mecanismo-intermedio/${this.caseId}`);
  }

  getData(){
    this.mecIntService.getAllInfoByCase(this.caseId).then(result => {
      this.mecInt = result;
      this.audiencias = result.audiencias;
      this.seguimientos = result.seguimientos;
    }).catch(err => {
      console.error(err);
    });
    this.mecIntService.getGeneralFileInfo(this.caseId).then(result => {
      this.inspector = result.caso.inspector;
    }).catch(err => {
      console.error(err);
    })
  }

  onFileChange(){

  }

  saveFinalReport(){

  }
}
