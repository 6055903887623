import { Injectable } from '@angular/core';
import * as moment from "moment";
import { Archivo } from '../inspecciones/inspecciones.interface';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';

@Injectable()
export class UtilsService {

  constructor(
    private http: HttpClient
  ) { }

  readonly meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  /**
   * Revisa si el email tiene un formato adecuado
   * @param {string} email El email a validar
   * @returns {boolean} Si es válido o no
   */
  checkEmailValidation(email: string): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  }

  /**
   * Formatea la fecha antes de pasarla a un input
   * @param date La fecha
   * @returns La fecha en formato adecuado para el input
   */
  formatInputDate(date: Date | string): string {
    console.log('la fecha', date)
    return (<string>date).split("T")[0];
  }

  /**
   * Formatea la fecha para devolverla en el formato adecuado
   * @param date La fecha a formatear
   * @param separator El separador de los números
   * @returns La fecha en formato dd-mm-aaaa
   */
  formatDate(date: Date | string, separator?: string): string {
    date = new Date(date);
    const dia = date.getUTCDate();
    const numMes = date.getUTCMonth();
    const anio = date.getUTCFullYear();

    return `${dia < 10 ? '0'+dia : dia}${separator || '-'}${this.meses[numMes]}${separator || '-'}${anio}`;
  }

  /**
   * Devuelve una fecha en formato largo ejemplo: viernes, 14 de octubre de 2022 16:43
   * @param date La fecha a transformar
   * @returns La fecha transformada
   */
  formatVisualDate(date: Date | string): string {
    return moment(date).locale('es-CO').format('LLLL');
  }

  /**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo 
   * @returns la url
   */
  getImageFromFileType(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  /**
   * Obtiene la imagen en base64 a partir de un archivo
   * @param imagePath El path de la imagen en el proyecto
   * @returns Un observable de la respuesta en base64
   */
  getBase64Image(imagePath: string): Observable<any> {
    return this.http.get(imagePath, { responseType: 'blob' }).pipe(
        switchMap((data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            return new Observable((observer) => {
                reader.onload = () => {
                  const base64 = reader.result;
                  observer.next({ success: true, data: base64 });
                  observer.complete();
                };
                reader.onerror = (error) => {
                  observer.error({ success: false, error });
                };
            });
        }),
    );
  }

 /**
  Obtiene el header del PDF con los datos de la inspección
  @param doc - Instancia del documento PDF
  @param numCaso - Número de caso de la inspección
  @param numInspeccion - Número de la inspección
  @returns - Promesa con la instancia del documento PDF con el header agregado
  */
  getPDFHeader({doc, numCaso, numInspeccion}: { doc: any, numCaso: string, numInspeccion: string }): Promise<jsPDF> {
    return new Promise((resolve, reject) => {
      this.getBase64Image('/assets/img/inspecciones/logo-ensamble.png')
      .subscribe((response: { success: boolean, data?: string | ArrayBuffer, error?: ProgressEvent<FileReader> }) => {
        if(!response.success){
          Swal.fire({
            title: '¡Error!',
            text: `Hubo un error al generar el PDF: ${response.error}`,
            icon: 'error'
          });
          reject(response.error);
        }
        doc.setFont('Helvetica');
        doc.setFontSize(13);
        doc.setTextColor('#332F2E');
        doc.addImage(response.data, 'JPEG', 15, 0, 40, 40);
        doc.text(`Número caso: ${numCaso || 'No definido'}`, 105, 15);
        doc.text(`Número inspección: ${numInspeccion || 'No definido'}`, 105, 20);
        doc.text(`Fecha: ${this.formatDate(new Date())}`, 105, 25);
        resolve(doc);
      })
    })
  }

}
