<app-header-inspecciones></app-header-inspecciones>
<main class="module-principal">
  <div class="colores-container">
    <div>
      <div class="circulo morado"></div>
      Audiencia
    </div>
    <div>
      <div class="circulo gris"></div>
      Prueba
    </div>
    <div>
      <div class="circulo rosado"></div>
      Otras diligencias
    </div>
    <div>
      <div class="circulo verde"></div>
      Seguimiento
    </div>
  </div>
  <div class="calendar-container">
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Anterior
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Hoy
          </div>
          <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Siguiente
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary" (click)="setView(calendarView.Month)"
            [class.active]="view === calendarView.Month">
            Mes
          </div>
          <div class="btn btn-primary" (click)="setView(calendarView.Week)" [class.active]="view === calendarView.Week">
            Semana
          </div>
          <div class="btn btn-primary" (click)="setView(calendarView.Day)" [class.active]="view === calendarView.Day">
            Día
          </div>
        </div>
      </div>
    </div>
    <br />
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="calendarView.Month" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="calendarView.Week" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="calendarView.Day" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view>
    </div>
  </div>

  <!--
      <h3>
        Edit events
        <button class="btn btn-primary float-end" (click)="addEvent()">
          Add new
        </button>
        <div class="clearfix"></div>
      </h3>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>Primary color</th>
              <th>Secondary + text color</th>
              <th>Starts at</th>
              <th>Ends at</th>
              <th>Remove</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let event of events">
              <td>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="event.title"
                  (keyup)="refresh.next()"
                />
              </td>
              <td>
                <input
                  type="color"
                  [(ngModel)]="event.color.primary"
                  (change)="refresh.next()"
                />
              </td>
              <td>
                <input
                  type="color"
                  [(ngModel)]="event.color.secondary"
                  (change)="refresh.next()"
                />
                <input
                  type="color"
                  [ngModel]="event.color.secondaryText ?? '#1e90ff'"
                  (ngModelChange)="event.color.secondaryText = $event"
                  (change)="refresh.next()"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  [(ngModel)]="event.start"
                  (ngModelChange)="refresh.next()"
                  [altInput]="true"
                  [convertModelValue]="true"
                  [enableTime]="true"
                  dateFormat="Y-m-dTH:i"
                  altFormat="F j, Y H:i"
                  placeholder="Not set"
                />
              </td>
              <td>
                <input
                  class="form-control"
                  type="text"
                  mwlFlatpickr
                  [(ngModel)]="event.end"
                  (ngModelChange)="refresh.next()"
                  [altInput]="true"
                  [convertModelValue]="true"
                  [enableTime]="true"
                  dateFormat="Y-m-dTH:i"
                  altFormat="F j, Y H:i"
                  placeholder="Not set"
                />
              </td>
              <td>
                <button class="btn btn-danger" (click)="deleteEvent(event)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">{{ modalData.event.title }}</h5>
      <mat-icon aria-label="Cerrar modal" class="close-icon" fontIcon="close" (click)="close()"></mat-icon>
    </div>
    <div class="modal-body modal-data">
      <!-- PARA OTRAS DILIGENCIAS -->
      <ng-container *ngIf="modalData.event.meta.tipo === 'otra-diligencia'">
        <span><strong>Fecha:</strong> {{ modalData.event.meta.fechaActividad ? formatDate(modalData.event.meta.fechaActividad) :
          'Sin fecha' }} {{ modalData.event.meta.horaActividad || 'Sin hora' }}</span>
        <span><strong>Lugar:</strong> {{ modalData.event.meta.lugarActividad }}</span>
        <span><strong>Modalidad:</strong> {{ modalData.event.meta.modalidadActividad || 'No definido' }}</span>
        <ng-container *ngIf="modalData.event.meta.convocados.length > 0">
          <h4>Lista de convocados</h4>
          <ng-container *ngFor="let convocado of modalData.event.meta.convocados; index as i">
            <span><strong>Convocado {{ i+1 }}</strong></span>
            <span>{{ convocado }}</span>
            <br>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="modalData.event.meta.asistentes.length > 0">
          <h4>Lista de asistentes</h4>
          <ng-container *ngFor="let asistente of modalData.event.meta.asistentes; index as i">
            <span><strong>Asistente {{ i+1 }}</strong></span>
            <span><strong>Identificación:</strong> {{ asistente.identificacion }}</span>
            <span><strong>Nombre completo:</strong> {{ asistente.nombreCompleto }}</span>
            <br>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- PARA AUDIENCIAS -->
      <ng-container *ngIf="modalData.event.meta.tipo === 'audiencia'">
        <span><strong>Fecha:</strong> {{ modalData.event.meta.fecha ? formatDate(modalData.event.meta.fecha) :
          'Sin fecha' }} {{ modalData.event.meta.hora || 'Sin hora' }}</span>
        <span><strong>Dirección:</strong> {{ modalData.event.meta.direccion }}</span>
        <ng-container *ngIf="modalData.event.meta.citados.length > 0">
          <h4>Lista de citados</h4>
          <ng-container *ngFor="let citado of modalData.event.meta.citados; index as i">
            <span><strong>Citado {{ i+1 }}</strong></span>
            <span><strong>Identificación:</strong> {{ citado.identificacion || 'No definido' }}</span>
            <span><strong>Nombre completo:</strong> {{ citado.nombreCompleto || 'No definido' }}</span>
            <span><strong>Dirección:</strong> {{ citado.direccion || 'No definido' }}</span>
            <span><strong>Teléfono:</strong> {{ citado.telefono || 'No definido' }}</span>
            <span><strong>Correo:</strong> {{ citado.correo || 'No definido' }}</span>
            <br>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- PARA PRUEBAS -->
      <ng-container *ngIf="modalData.event.meta.tipo === 'prueba'">
        <span><strong>Fecha:</strong> {{ modalData.event.meta.fecha ? formatDate(modalData.event.meta.fecha) : 'No definido'
          }}</span>
        <span
          *ngIf="modalData.event.meta.tipoPrueba === 'solicitada-audiencia' || modalData.event.meta.tipoPrueba === 'aportada-audiencia' || modalData.event.meta.tipoPrueba === 'aportada-general'"><strong>Nombre
            persona:</strong> {{ modalData.event.meta.nombrePersona || 'No definido' }}</span>
        <span><strong>Tipo de prueba:</strong> {{ tiposPruebas[modalData.event.meta.tipoPrueba] || 'No definido' }}</span>
        <span *ngIf="modalData.event.meta.tipoPrueba !== 'solicitada-audiencia'"><strong>Detalle:</strong> {{
          modalData.event.meta.detalle || 'No definido' }}</span>
        <ng-container
          *ngIf="modalData.event.meta.tipoPrueba === 'aportada-audiencia' || modalData.event.meta.tipoPrueba === 'solicitada-audiencia' || modalData.event.meta.tipoPrueba === 'aportada-general'">
          <span><strong>Aceptada:</strong> {{ modalData.event.meta.aceptada !== undefined ? (modalData.event.meta.aceptada ? 'Sí'
            : 'No') : 'No definido' }}</span>
          <span *ngIf="modalData.event.meta.aceptada === false"><strong>Motivo de rechazo:</strong> {{
            modalData.event.meta.motivoRechazo || 'No definido' }}</span>
        </ng-container>
      </ng-container>
      <!-- PARA SEGUIMIENTOS -->
      <ng-container *ngIf="modalData.event.meta.tipo === 'seguimiento'">
        <span><strong>Fecha:</strong> {{ modalData.event.meta.fecha ? formatDate(modalData.event.meta.fecha) :
          'Sin fecha' }} {{ modalData.event.meta.hora || 'Sin hora' }}</span>
        <span><strong>Descripción:</strong> {{ modalData.event.meta.descripcion || 'No definido' }}</span>
      </ng-container>
    </div>
    <div class="modal-footer">
      <a *ngIf="modalData.event.meta.tipo === 'prueba' && modalData.event.meta.url" [href]="modalData.event.meta.url"
        target="_blank">Ver prueba</a>
      <button (click)="close()" (click)="redirectToCase(modalData.event.meta.pantalla, modalData.event.meta.idCaso)">Ir
        al caso</button>
    </div>
  </ng-template>
</main>
