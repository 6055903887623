<div class="form-container">
  <div class="form-header go-top">
    <div class="left-side mr-10">
      <h2 class="form-title align-left">Descargos, declaraciones y conciliación</h2>
    </div>
    <div class="d-flex-start">
      <button *ngIf="oldHearing || newHearing" class="cancel-button mr-10" (click)="cancelHearing()">Salir de audiencia</button>
      <!-- <button class="download-button" (click)="downloadPDF()">
        <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
      </button> -->
    </div>
  </div>

  <div class="mb-20">
    <div class="form-group full-width-group width-70">
      <p>
        Respecto de lo anterior se le otorga el uso de la palabra al citado para que se refiera a los hechos y a las
        demás circunstancias relacionadas con el presente asunto, frente a lo cual manifestó lo siguiente:
      </p>
    </div>
  </div>

  <!-- LISTA DE AUDIENCIAS -->
  <div *ngIf="audienciasDisponibles?.length > 0; else noAudiencias" class="form-group d-flex-l">
    <button class="selectable-button" *ngFor="let audiencia of audienciasDisponibles; index as i" (click)="editHearing(audiencia)">
      <div *ngIf="audiencia.activo !== false">
        {{ 'Audiencia ' + (i+1) + ' / ' + formatDate(audiencia?.fechaAudiencia) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar">
      </div>
    </button>
  </div>
  <ng-template #noAudiencias>
    <ng-container *ngIf="step === 1">
      <div class="width-70">
        <p class="text">
          No tienes ninguna citación creada, crea una para acceder a todas las funcionalidades del proceso verbal abreviado.
        </p>
      </div>
    </ng-container>
  </ng-template>

  <h3 class="font18 bold" *ngIf="(newHearing || oldHearing)">Agregar nuevo declarante</h3>
  <br>
  <form class="phase-form" #descargoForm="ngForm">
    <ng-container *ngIf="(newHearing || oldHearing)">
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <label class="inspections-label" for="nombreCitado">Citado</label>
          <ngx-select-dropdown class="inspections-select" name="nombreCitado" [(ngModel)]="descargo.nombreCitado" (change)="reviewSelectedName($event)" [config]="quotedConfig" [options]="citados">
          </ngx-select-dropdown>
        </div>
        <div class="form-group half-width-group">
          <label class="inspections-label" for="tipoAsistente">Tipo de asistente</label>
          <ngx-select-dropdown class="inspections-select" name="tipoAsistente" [config]="config" [(ngModel)]="descargo.tipoAsistente" [options]="tiposAsistentes">
          </ngx-select-dropdown>
        </div>
      </div>
      <div *ngIf="anotherSelected">
        <h3 class="font16 bold">Información del asistente</h3>
        <div class="form-row" >
          <div class="form-group full-width-group">
            <label for="nombreOtroCitado" class="inspections-label">Nombre completo del asistente</label>
            <input type="text" name="nombreOtroCitado" class="inspections-input" placeholder="Escribe aquí" [(ngModel)]="descargo.nombreOtroCitado"/>
          </div>
        </div>
        <div class="form-row d-flex-between mb-20">
          <div class="form-group half-width-group">
            <label for="tipoIdentificacion" class="inspections-label">Tipo de identificación</label>
            <ngx-select-dropdown class="inspections-select" name="tipoIdentificacion" [config]="config" [(ngModel)]="descargo.tipoIdentificacion" [options]="tiposIdentificacion">
            </ngx-select-dropdown>
          </div>
          <div class="form-group half-width-group">
            <label for="identificacion" class="inspections-label">Identificación</label>
            <input type="text" name="identificacion" class="inspections-input" placeholder="Escriba aquí" [(ngModel)]="descargo.identificacion"/>
          </div>
        </div>
      </div>

      <div *ngIf="!anotherSelected">
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="nombre">Nombre</h4>
            <p>{{ quotedFound.nombre || 'No asignado'}}</p>
          </div>
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="apellido">Apellido</h4>
            <p>{{ quotedFound.apellido || 'No asignado'}}</p>
          </div>
        </div>

        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="tipoIdentificacion">Tipo de identificación</h4>
            <p>{{ quotedFound.tipoIdentificacion || 'No asignado'}}</p>
          </div>
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="identificacion">Identificación</h4>
            <p>{{ quotedFound.identificacion || 'No asignado'}}</p>
          </div>
        </div>

        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="nivelEducativo">Nivel educativo</h4>
            <p>{{ quotedFound.nivelEducativo || 'No asignado'}}</p>
          </div>
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="ocupacion">Ocupación</h4>
            <p>{{ quotedFound.ocupacion || 'No asignado'}}</p>
          </div>
        </div>

        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="tipoPoblacion">Tipo de población vulnerable</h4>
            <p>{{ quotedFound.tipoVulnerabilidad || 'No asignado'}}</p>
          </div>
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="direccion">Dirección</h4>
            <p>{{ quotedFound.direccion || 'No asignado'}}</p>
          </div>
        </div>

        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="telefono">Teléfono</h4>
            <p>{{ quotedFound.telefono || 'No asignado'}}</p>
          </div>
          <div class="form-group half-width-group">
            <h4 class="inspections-label" for="correoElectronico">Correo electrónico</h4>
            <p>{{ quotedFound.correo || 'No asignado'}}</p>
          </div>
        </div>
        <br>
      </div>

      <div class="form-row">
        <div class="form-group full-width-group">
          <label class="inspections-label" for="declaracion">Declaración</label>
          <textarea
            class="textarea"
            name="declaracion"
            id="declaracion"
            [(ngModel)]="descargo.declaracion"
            placeholder="Escribe aquí… (se anota lo que tenga que decir el citado y se hacen las preguntas pertinentes)"
            cols="10"></textarea>
        </div>
      </div>

      <button class="aggregate-button mb-20" (click)="addStatement(true)" *ngIf="!wantEditStatement">+Agregar declaración</button>
      <button class="aggregate-button mb-20" (click)="addStatement(false)" *ngIf="wantEditStatement">Guardar declaración</button>

      <div class="list mb-10" *ngIf="newHearing || oldHearing">
        <h4 class="subtitle-text bold">Declaraciones ya rendidas</h4>
        <div *ngIf="descargos.length > 0; else noDescargos">
          <div class="list-row" *ngFor="let descargo of descargos; index as i">
            <div class="list-detail width-100">
              <span>{{ descargo.identificacion + ' - ' + descargo.nombreCitado + ': ' + descargo.declaracion | truncate:120 }}</span>
              <div class="list-buttons right-double">
                <!--           <button *ngIf="wantEditStatement" class="edit-item" (click)="cancelStatement()">Cancelar</button> -->
                <button class="selectable-button" (click)="editStatement(descargo, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt=""></button>
                <button class="selectable-button" (click)="downloadStatement(descargo)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon descargar"></button>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noDescargos>
          <div class="width-70">
            <p class="text">
              No tienes descargos ni declaraciones creados, crea uno para empezar.
            </p>
          </div>
        </ng-template>
      </div>

      <button class="aggregate-button mb-20" (click)="saveHearing()">Guardar audiencia</button>
    </ng-container>

    <ng-container *ngIf="fichaGeneralData.esQuerellaCivil">
      <h3 class="font18 bold">Conciliación</h3>
      <p class="font16">Teniendo en cuenta que se trata de una querella Civil y dada las características y naturaleza del asunto, el Inspector de conocimiento, previas las formalidades legales, invita a las partes a llegar a un acuerdo respecto del conflicto objeto de querella, frente a lo cual, se hacen las siguientes manifestaciones:</p>

      <div class="form-row">
        <div class="form-group full-width-group">
          <label class="inspections-label" for="manifestacionesQuerellantes">Por parte del (los) querellante(s):</label>
          <input
            class="inspections-input"
            placeholder="Escribe aquí la manifestación del querellante"
            type="text"
            name="manifestacionesQuerellantes"
            id="manifestacionesQuerellantes"
            [(ngModel)]="fichaGeneralData.manifestacionesQuerellantes">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group full-width-group">
          <label class="inspections-label" for="manifestacionesQuerellados">Por parte del (los) querellado(s):</label>
          <input
            class="inspections-input"
            placeholder="Escribe aquí la manifestación del querellado"
            type="text"
            name="manifestacionesQuerellados"
            id="manifestacionesQuerellados"
            [(ngModel)]="fichaGeneralData.manifestacionesQuerellados">
        </div>
      </div>

      <div class="mb-20">
        <div class="form-group full-width-group d-flex-start">
          <label for="haceConciliacion" class="font18 bold mt-0">Frente a lo anterior y como quiera que se ha manifestado la voluntad de</label>
          <section class="d-flex-between">
            <div class="d-flex-start checkbox-container">
              <input class="checkbox" name="haceConciliacion" [checked]="fichaGeneralData.haceConciliacion === true" (change)="changeReconcile(true)" type="checkbox">
              <label class="inspections-label" for="haceConciliacion">Sí conciliar</label>
            </div>
            <div class="d-flex-start checkbox-container">
              <input class="checkbox" name="tipoLocalidadHechosRural" [checked]="fichaGeneralData.haceConciliacion === false" (change)="changeReconcile(false)" type="checkbox">
              <label class="inspections-label" for="tipoLocalidadHechosRural">No conciliar</label>
            </div>
          </section>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="fichaGeneralData.haceConciliacion">
      <div class="form-group full-width-group d-flex-start">
        <label for="acuerdo" class="font18 bold mt-0">Luego de escuchar a las partes y sus respectivas propuestas, se ha llegado al siguiente acuerdo:</label>
        <textarea class="textarea" name="acuerdo" id="acuerdo" placeholder="Escribe aquí el acuerdo realizado" [(ngModel)]="fichaGeneralData.acuerdo" cols="10" rows="10"></textarea>
      </div>
      <p class="font16">Se deja constancia que el presente acuerdo fue logrado por la libre voluntad de las partes y se imprimen copias del acta para cada una de las partes, la cual presta mérito ejecutivo y hace tránsito a cosa juzgada.</p>
      <button class="download-button mb-20"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" (click)="descargarPDFConPVA()"></button>
    </ng-container>
    <ng-container *ngIf="fichaGeneralData.haceConciliacion === false">
      <p class="font16">Como se ha informado el interés de no conciliar por ambas o alguna de las dos partes, o no se ha llegado a un acuerdo, se da continuación a la audiencia pública.</p>
    </ng-container>

    <div>
      <button type="submit" class="submit-button" (click)="onSaveInfo(descargoForm)">Guardar</button>
    </div>

  </form>
</div>
