import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-mecanismos-intermedios',
  templateUrl: './mecanismos-intermedios.component.html',
  styleUrls: ['./mecanismos-intermedios.component.css']
})
export class MecanismosIntermediosComponent implements OnInit {
  firstPreviousMechanismIntermediate = false;
  secondPreviousMechanismIntermediate = false;
  thirdPreviousMechanismIntermediate = false;
  fourthPreviousMechanismIntermediate = false;
  fifthPreviousMechanismIntermediate = false;

  private queryParams: Params;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  showPreviousMechanism(number){
    switch (number) {
      case 1:
        this.firstPreviousMechanismIntermediate = !this.firstPreviousMechanismIntermediate;
        break;
      case 2:
        this.secondPreviousMechanismIntermediate = !this.secondPreviousMechanismIntermediate;
        break;
      case 3:
        this.thirdPreviousMechanismIntermediate = !this.thirdPreviousMechanismIntermediate;
        break;
      case 4:
        this.fourthPreviousMechanismIntermediate = !this.fourthPreviousMechanismIntermediate;
        break;
      case 5:
        this.fifthPreviousMechanismIntermediate = !this.fifthPreviousMechanismIntermediate;
    }
  }

  /**
   * Va a la pantalla anterior de acuerdo de donde venga
   */
  goToPreviousPage(){
    if(this.queryParams['previousPage'] && this.queryParams['caseId']){
      this.router.navigateByUrl(
        this.router.createUrlTree([`/gestion-casos-inspecciones/ver-caso/mecanismo-intermedio/${this.queryParams['caseId']}`], { queryParams: {previousPage: 'ficha-general'}})
      );
    } else {
      this.router.navigateByUrl('/submodulos-inspecciones');
    }
  }

}
