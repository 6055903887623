import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, length?: number): any {
    if(value){
      if(!length) {
        length = 40
      }
      if( value.length > (length)){
          let retorna = value.substring(0, length) + '...';
          return retorna;
      }
      return value;
    }
    return;
  }

}
