<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="module-principal">
  <div class="previous-premises-container">
    <div class="premisas-container">
      <div class="d-flex-between">
        <div class="left-side">
          <button class="go-back-button" routerLink="/biblioteca-inspecciones">
            <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
          </button>
          <h1 class="principal-title">Legislación</h1>
        </div>
      </div>
      <main class="main-prev-mec">
        <div class="gestion-principal mb-30">
          <mat-form-field class="width-100">
            <mat-label>Filtrar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Escribe aquí el valor que quieres buscar" #input>
          </mat-form-field>
          <table mat-table aria-label="Tabla de la legislación asociada al módulo" [dataSource]="dataSource">
            <ng-container matColumnDef="referencia">
              <th aria-label="Columna de referencia" mat-header-cell *matHeaderCellDef>Referencia</th>
              <td mat-cell *matCellDef="let element"> {{element.referencia}} </td>
            </ng-container>
            <ng-container matColumnDef="asunto">
              <th aria-label="Columna de asunto" mat-header-cell *matHeaderCellDef>Asunto</th>
              <td mat-cell *matCellDef="let element"> {{element.asunto}} </td>
            </ng-container>
            <ng-container matColumnDef="observacion">
              <th aria-label="Columna de observación" mat-header-cell *matHeaderCellDef>Observación</th>
              <td mat-cell *matCellDef="let element"> {{element.observacion}} </td>
            </ng-container>
            <ng-container matColumnDef="enlaces">
              <th aria-label="Columna de enlaces" mat-header-cell *matHeaderCellDef>Enlaces</th>
              <td mat-cell *matCellDef="let element" (click)="goToPage(element.enlaces)" class="link">{{ element.enlaces.join(', ') }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Selecciona una página de la lista de casos"
          ></mat-paginator>
        </div>
      </main>
      <h5 class="nota"><span>Nota:</span> Si Usted considera que existen otros pronunciamientos de altas cortes y tribunales, conceptos relevantes, que daban ser incluidos en este listado, comuníquese con el administrador del sitio al correo…</h5>
    </div>
  </div>
</main>

