<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="home-superior">
    <button class="go-back-button" routerLink="/foro-inspecciones">
      <img src="/assets/img/flecha-izquierda.png" class="go-back-image" alt="boton atras" />
      Volver
    </button>
    <h1 class="home-titulo">{{ foro.nombre }}</h1>
  </div>

  <img [src]="foro.imagen || 'https://blog.lemontech.com/wp-content/uploads/2021/01/frases-de-abogados.jpg'" alt="imagen detalle del foro" class="image-foro-ver" />

  <div class="home-contenido-principal">
    <aside class="aside-foros">
      <article class="cards-submodulos">
        <h2 class="cards-titulo">Otros foros</h2>
      </article>
      <article *ngFor="let foro of foros" class="cards-submodulos">
        <img [src]="foro.imagen || '/assets/img/preguntas-y-respuestas-5.png'" class="cards-imagen" alt="submodulo" />
        <h2 class="cards-titulo">{{ foro.nombre }}</h2>
        <p class="foro-text">
          {{ foro.descripcion | truncate:150 }}
        </p>
      </article>
    </aside>
    <section class="home-casos-calendario-foro">
      <p>{{ foro.descripcion }}</p>
      <h3 class="title-foro">Foro</h3>
      <div class="top-foro">
        <img src="/assets/img/circulo.png" alt="biñeta naranja" width="30px" class="img-biñeta" />
        <div>
          <p class="text-question">
            {{ foro.preguntaOrientadora }}
          </p>
          <p class="font16 mb-0">Actualizado {{ formatDate(foro.createdAt) }}</p>
          <em class="font16 mb-0">{{ foro.autor?.nombres }}</em>
        </div>
      </div>
      <section class="comentarios-foro">
        <h3 class="font18 bold">Comentarios</h3>
        <div *ngIf="foro.comentarios.length > 0; else noComentarios">
          <div class="comentario-container" *ngFor="let comentario of foro.comentarios; index as i">
            <div *ngIf="comentario.activo">
              <h4>Comentario <em>{{ (comentario.autor?.nombre + ' ' || '') + (comentario.autor?.apellido || '') }}</em></h4>
              <p>{{ comentario.descripcion }}</p>
              <button class="comentario-respuestas-button" *ngIf="comentario.respuestas.length > 0" (click)="seeCommentAnswers(i)"><span class="comentario-respuestas-cantidad">{{ comentariosVisibles[i] ? 'Ocultar' : 'Ver' }} {{ comentario.respuestas.length }} respuestas</span>Última respuesta hace {{ getLastResponseTime(comentario.respuestas) }}</button>
            </div>
            <div *ngIf="comentariosVisibles[i]" class="respuestas-container">
              <div *ngFor="let respuesta of comentario.respuestas">
                <h4>Respuesta <em>{{ (respuesta.autor?.nombre + ' ' || '') + (respuesta.autor?.apellido || '') }}</em> {{ getResponseTime(respuesta) }}</h4>
                <p>{{ respuesta.descripcion }}</p>
              </div>
            </div>
            <button class="comentario-add-answer" (click)="addAnswer(i, comentario)">Añadir respuesta</button>
          </div>
        </div>
        <ng-template #noComentarios>
          <p>No existe ningún comentario en este foro ¡Te invitamos a participar de primeras!</p>
        </ng-template>
      </section>
      <div class="botones-casos top-line pt-30" *ngIf="!wantAnswer">
        <label for="hacerComentario" class="font18">Hacer comentario</label>
        <div>
          <input
            type="search"
            class="input-search"
            [(ngModel)]="comentario.descripcion"
            name="hacerComentario"
            placeholder="Haz un comentario (fecha, nota, conductor)" />
          <button class="button-search">
            <img src="/assets/img/send.png" class="img-send" alt="busqueda" (click)="addComment()"/>
          </button>
        </div>
      </div>
      <div class="botones-casos top-line pt-30" *ngIf="wantAnswer">
        <label for="hacerComentario" class="font18">Responder comentario</label>
        <div>
          <input
            type="search"
            class="input-search"
            [(ngModel)]="comentario.descripcion"
            name="hacerComentario"
            placeholder="Respuesta" />
          <button class="button-search">
            <img src="/assets/img/send.png" class="img-send" alt="busqueda" (click)="createAnswer()"/>
          </button>
        </div>
      </div>
    </section>
  </div>
</main>
