import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../../services/utils.service';
import { Archivo, PruebaAportadaSolicitante } from '../../../inspecciones.interface';
import { MecanismoIntermedioService } from '../../mecanismo-intermedio/mecanismo-intermedio.service';
import { MecanismoPrevioService } from '../../mecanismo-previo/mecanismo-previo.service';
import { PvaService } from '../pva.service';

@Component({
  selector: 'app-datos-generales-pva',
  templateUrl: './datos-generales-pva.component.html',
  styleUrls: ['./datos-generales-pva.component.css']
})
export class DatosGeneralesPvaComponent implements OnInit {
  todayDate = new Date().toISOString().split('T')[0];

  caseId = '';
  audiencia: any = {
    asisteAgente: undefined,
  };
  fichaGeneral: any = {};
  mecIntermedio: any = {};
  mecPrevio: any = {};
  inspector: any = {};

  newHearing: boolean = false;
  oldHearing: boolean = false;

  showContinueOption: boolean = false;

  hearingIndex: string | number;
  seeingEvidence: boolean = false;

  /** Pruebas */
  readonly config = {
    search:true,
    noResultsFound: "No se encontraron resultados",
    placeholder:'Seleccione'
  };

  tipoPrueba: string;
  detallePrueba: string;

  citadosSinAsistir: any[] = [];

  @Input() pvaId: string;
  @Input() selectedHearing: any;
  @Input() isSelected: boolean;
  @Input() audienciasDisponibles: any[];

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private pvaService: PvaService,
    private mecPrevService: MecanismoPrevioService,
    private mecIntService: MecanismoIntermedioService,
    private utilsService: UtilsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getGeneralData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      this.audiencia.horaAudiencia = this.audiencia.horaAudiencia.split('T')[0];
      this.oldHearing = true;
      this.hearingIndex = this.audienciasDisponibles.findIndex(audiencia => {
        return audiencia === changes['selectedHearing'].currentValue
      });
      for(let citado of this.audiencia.citados){
        if(!citado.asiste && citado.asiste !== undefined){
          this.citadosSinAsistir.push(citado);
        }
      }
    }
  }

  editHearing(hearing){
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    console.log(this.audiencia);
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia.split("T")[0];
  }

  cancelHearing(){
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  getGeneralData(){
    this.getPreviousMechanism();
    this.getIntermediateMechanism();
    this.getGeneralFileInfo();
  }

  getPreviousMechanism(){
    this.mecPrevService.getAllInfo(this.caseId).then(mecPrevioData => {
      this.mecPrevio = mecPrevioData;
    })
  }

  getIntermediateMechanism(){
    this.mecIntService.getAllInfoByCase(this.caseId).then(mecIntData => {
      this.mecIntermedio = mecIntData;
    })
  }


  getGeneralFileInfo(){
    this.pvaService.getGeneralFileInfo(this.caseId).then(generalFileData => {
      const {
        municipio,
        detalleLugarHechos,
        resumenHechos,
        direccionHechos,
        nombreLugarHechos,
        tipoLocalidadHechos,
        lugarHechos,
        nombreEstablecimientoHechos,
        nitEstablecimientoHechos,
        actividadEconomicaEstablecimientoHechos,
        CCC,
      } = generalFileData
      this.inspector = generalFileData.caso.inspector;

      this.fichaGeneral = {
        municipio,
        detalleLugarHechos,
        resumenHechos,
        nombreLugarHechos,
        tipoLocalidadHechos,
        direccionHechos,
        lugarHechos,
        nombreEstablecimientoHechos,
        nitEstablecimientoHechos,
        actividadEconomicaEstablecimientoHechos,
        CCC
      }
    });
  }

  changeAssistanceStatus(citado, status: boolean, index: number){
    if (
      (status && citado.asiste === undefined) ||
      (!status && citado.asiste === undefined) ||
      (status && !citado.asiste) ||
      (!status && citado.asiste)
    ) {
      citado.asiste = status;
      this.audiencia.citados[index].asiste = status;
      if(status === false){
        this.citadosSinAsistir[index] = citado;
      } else {
        this.citadosSinAsistir.splice(index, 1);
        this.audiencia.citados[index].justificaInasistencia = undefined;
      }
    } else {
      citado.asiste = undefined;
      this.audiencia.citados[index].asiste = undefined;
    }
  }

  getImageFromFileType(archivo: Archivo): string {
    return this.utilsService.getImageFromFileType(archivo);
  }

  changeJustificationStatus(citado, status: boolean, index: number){
    if (
      (status && citado.justificaInasistencia === undefined) ||
      (!status && citado.justificaInasistencia === undefined) ||
      (status && !citado.justificaInasistencia) ||
      (!status && citado.justificaInasistencia)
    ) {
      citado.justificaInasistencia = status;
      this.audiencia.citados[index].justificaInasistencia = status;
    } else {
      citado.justificaInasistencia = undefined;
      this.audiencia.citados[index].justificaInasistencia = undefined;
    }
  }

  changeContinuationStatus(status) {
    if (
      (status && this.audiencia.suspendida === undefined) ||
      (!status && this.audiencia.suspendida === undefined) ||
      (status && !this.audiencia.suspendida) ||
      (!status && this.audiencia.suspendida)
    ) {
      this.audiencia.suspendida = status;
    } else {
      this.audiencia.suspendida = undefined;
    }
  }

  changeAgentAssistance(status: boolean){
    if (
      (status && this.audiencia.asisteAgente === undefined) ||
      (!status && this.audiencia.asisteAgente === undefined) ||
      (status && !this.audiencia.asisteAgente) ||
      (!status && this.audiencia.asisteAgente)
    ) {
      this.audiencia.asisteAgente = status;
      if(status === false){
        this.audiencia.nombreAgente = '';
      }
    } else {
      this.audiencia.asisteAgente = undefined;
    }
  }

  formatDate(date){
    return this.utilsService.formatDate(date);
  }

  onGuardar(f: NgForm){
    const { direccionAudiencia, fechaAudiencia, horaAudiencia, observaciones, otrosMediosUtilizados, nombreAgente } = f.form.value;
    const audiencia = this.audienciasDisponibles[this.hearingIndex];
    Object.assign(audiencia, {
      direccionAudiencia,
      fechaAudiencia,
      horaAudiencia,
      observaciones,
      otrosMediosUtilizados,
      nombreAgente,
      asisteAgente: this.audiencia.asisteAgente,
    })
    this.audienciasDisponibles[this.hearingIndex] = audiencia;
    const data = {
      audiencias: this.audienciasDisponibles,
    }
    this.pvaService.saveInfo(this.pvaId, data)
  }

}
