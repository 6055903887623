<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="final-report-container">
    <aside class="aside-final-report">
      <button class="go-back-button" (click)="onGoBack()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda"> Volver</button>
      <h1 class="aside-title">Fase 2 <span class="info-text"><img class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> Conoce más <img class="info-image" src="/assets/img/inspecciones/flecha.png" alt="Fecha entrar"></span> <br>Mecanismos Intermedios</h1>
    </aside>
    <main class="form-container width-70">
      <form class="phase-form full-width" #finalReport="ngForm">
        <div class="form-header">
          <h2 class="form-title">Informe final</h2>
          <button class="download-button"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar"></button>
        </div>
        <p class="font18 bold">Se realizan las siguientes audiencias públicas</p>
          <div *ngFor="let audiencia of audiencias; index as i" class="mb-30">
            <h2 class="font16 bold">Audiencia número {{ i+1 }}</h2>
            <p class="font16">El {{ audiencia.fechaAudiencia?.split("T")[0] }} a las {{ audiencia.horaAudiencia }}, en {{ audiencia.direccionAudiencia }}</p>
            <h3 class="font18 bold" *ngIf="audiencia.descargos.length > 0">Descargos:</h3>
            <p class="font16" *ngFor="let descargo of audiencia.descargos">
              {{ descargo.nombreCitado }} ({{ descargo.tipoAsistente }}) menciona: {{ descargo.declaracion }}
            </p>
          </div>
          <h3 class="font18 bold">Con base en esto se toman las siguientes decisiones y se interponen los recursos correspondientes, así:</h3>
          <div>
            <p class="font16"><strong>Fecha de la decision:</strong> {{ mecInt.fechaDecision?.split("T")[0] }} a las {{ mecInt.horaDecision }}</p>
            <p class="font16"><strong>Consideraciones del inspector:</strong> {{ mecInt.consideracionesInspector }}</p>
            <p class="font16"><strong>Orden de policía:</strong> {{ mecInt.detalleOrdenPolicia }}</p>
            <p class="font16"><strong>Plazo:</strong> {{ mecInt.plazo }}</p>
            <p class="font16"><strong>Lugar:</strong> {{ mecInt.lugar }}</p>
            <p class="font16" *ngIf="mecInt.interponeReposicion !== undefined"><strong>¿Se interpone reposición?</strong> {{ mecInt.interponeReposicion ? 'Sí' : 'No' }}</p>
            <p class="font16" *ngIf="mecInt.interponeApelacion !== undefined"><strong>¿Se interpone apelación?</strong> {{ mecInt.interponeApelacion ? 'Sí' : 'No' }}</p>
          </div>
          <h3 class="font18 bold mt-30">A partir de lo anterior, se realizan los siguientes seguimientos:</h3>
          <div *ngFor="let seguimiento of seguimientos; index as i" class="mb-30">
            <p class="font18 bold">Seguimiento número {{ i+1 }}</p>
            <p class="font16"><strong>Fecha del seguimiento:</strong> {{ seguimiento.fechaSeguimiento?.split("T")[0] }} a las {{ seguimiento.horaSeguimiento }}</p>
            <p class="font16"><strong>Descripción:</strong> {{ seguimiento.descripcionSeguimiento }}</p>
            <p class="font16" *ngIf="seguimiento.ordenYCompromisosCumplidos !== undefined"><strong>¿Se cumple la orden de policía y compromisos?</strong> {{ seguimiento.ordenYCompromisosCumplidos ? 'Sí' : 'No' }}</p>
            <p class="font16"><strong>La justificación de lo anterior es:</strong> {{ seguimiento.justificacionCumplimiento || 'No definida' }}</p>
            <p class="font16"><strong>Se realizan las siguientes observaciones:</strong> {{ seguimiento.observaciones || 'No definidas' }}</p>
          </div>
  
  
          <div class="form-row d-flex-between">
            <div class="form-group half-width-group">
              <label class="inspections-label text-bold" for="nombreAuxAdm">Nombre Auxiliar Administrativo</label>
              <input
                type="text"
                placeholder="Escribe aquí"
                name="nombreAuxAdm"
                class="inspections-input"
                id="nombreAuxAdm"
                [(ngModel)]="mecInt.nombreAuxAdm">
            </div>
            <div class="form-group half-width-group">
              <div class="form-row">
                <label class="inspections-label text-bold" for="firmaAuxAdm">Firma Auxiliar Administrativo</label>
                <label for="firmaAuxAdm" class="file-container file-container-45 font16 b-20 d-flex-between">
                  <p class="font16">Adjuntar firma</p>
                  <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
                  <input
                    class="file-input"
                    type="file"
                    id="firmaAuxAdm"
                    name="firmaAuxAdm"
                    (change)="onFileChange()">
                </label>
              </div>
            </div>
          </div>
          <div class="form-row d-flex-between">
            <div class="form-group half-width-group">
              <label class="inspections-label text-bold" for="nombreInspector">Nombre Inspector de Policía</label>
              <input
                type="text"
                placeholder="Escribe aquí"
                disabled="true"
                name="nombreInspector"
                class="inspections-input"
                id="nombreInspector"
                [(ngModel)]="inspector.nombre">
            </div>
            <div class="form-group half-width-group">
              <div class="form-row">
                <label class="inspections-label text-bold" for="firmaInspector">Firma Inspector de policía</label>
                <label for="firmaInspector" class="file-container file-container-45 font16 b-20 d-flex-between">
                  <p class="font16">Adjuntar firma</p>
                  <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
                  <input
                    class="file-input"
                    type="file"
                    id="firmaInspector"
                    name="firmaInspector"
                    (change)="onFileChange()">
                </label>
              </div>
            </div>
          </div>
      </form>
      <button class="submit-button" (click)="saveFinalReport()">Guardar</button>
    </main>
  </div>
</div>