import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
} from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { CalendarioService } from './calendario.service';
import { CalendarEventInfo, Inspector } from '../inspecciones.interface';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/utils.service';
import { Router } from '@angular/router';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendario',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css'],
})
export class CalendarioComponent implements OnInit {
  activeDayIsOpen: boolean = false;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();

  calendarView = CalendarView;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  readonly tiposPruebas = {
    'aportada-audiencia': 'Aportada en la audiencia',
    'solicitada-audiencia': 'Solicitada en la audiencia',
    'decretada-audiencia': 'Decretada en la audiencia',
    'aportada-general': 'Aportada general',
    'practicada-general': 'Practicada general'
  }

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    // {
    //   label: '<i class="fas fa-fw fa-trash-alt"></i>',
    //   a11yLabel: 'Delete',
    //   onClick: ({ event }: { event: CalendarEvent }): void => {
    //     this.events = this.events.filter((iEvent) => iEvent !== event);
    //     this.handleEvent('Deleted', event);
    //   },
    // },
  ];

  events: CalendarEvent<CalendarEventInfo>[]

  constructor(
    private calendarService: CalendarioService,
    private modal: NgbModal,
    private utilsService: UtilsService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.getEvents();
  }

  getEvents(){
    const inspector: Inspector = JSON.parse(localStorage.getItem('inspector'));
    console.log(inspector);
    this.calendarService.getEvents(inspector._id)
      .then(eventos => {
        console.log('los eventosss', eventos);
        const eventosFormateados = eventos.map(evento => {
          return ({
            ...evento,
            start: new Date(evento.start),
            end: new Date(evento.end)
          })
        })
        console.log(eventosFormateados);
        this.events = eventosFormateados;
        console.log(this.events.filter(ev => ev.meta.tipo === 'prueba' && ev.meta.tipoPrueba === 'aportada-audiencia'))
      })
      .catch(error => {
        console.error(error);
        Swal.fire({
          title: '¡Error!',
          text: `Ha ocurrido un error al obtener los eventos - ${error}`,
          icon: 'error'
        });
      });
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    console.log(this.modalData);
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const momentViewDate = moment(this.viewDate);
    const momentDate = moment(date);
    if (momentViewDate.isSame(momentDate, 'month')) {
      if (
        (momentViewDate.isSame(momentDate, 'day') &&
          this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  formatDate(date: string | Date): string {
    return this.utilsService.formatDate(date);
  }

  /**
   * Redirecciona al mecanismo previo que es de donde salen otras diligencias
   * @param caseNumber
   */
  redirectToCase(page: string, caseNumber: string): void {
    this.router.navigate([`/gestion-casos-inspecciones/ver-caso/${page}/${caseNumber}`]);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: moment(newStart).toDate(),
          end: moment(newEnd).toDate(),
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
        color: colors['red'],
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
}
