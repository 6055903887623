<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="int-mec-container">
    <button class="go-back-button" (click)="goBackButton()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver</button>
  </div>
  <div class="int-mec-container">
    <aside class="aside-int-mec">
      <h1 class="aside-title">Fase 2 <span class="info-text" (click)="goToMoreInfo()"><img class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> Conoce más <img class="info-image" src="/assets/img/inspecciones/flecha.png" alt="Fecha entrar"></span> <br>Mecanismos Intermedios</h1>
      <span class="aside-description">(Medios de policía)</span>
      <h2 class="aside-data">Datos generales</h2>
      <h3 class="aside-subtitle">Caso No.</h3>
      <p class="aside-p">Nº{{ caseNumber }}</p>
      <h3 class="aside-subtitle">Inspección de Policía No.</h3>
      <p class="aside-p">Nº{{ inspectionNumber }}</p>
      <h3 class="aside-subtitle">Presuntos infractores</h3>
      <p *ngFor="let infractor of presuntosInfractores" class="aside-p">
        {{ infractor.nombreInfractor }} {{ infractor.apellidoInfractor }}
      </p>
      <h3 class="aside-subtitle">Descripción del CCC</h3>
      <p *ngFor="let case of cases; index as i" class="aside-p">{{ (i+1) + ': ' + case.descripcionComportamiento }}</p>
    </aside>
    <main class="main-prev-mec">
      <nav class="navigation">
        <ul class="navigation-list">
          <li class="navigation-item" [class]="selectedPhase === 1 ? 'selected navigation-item mr-10 mb-10': 'no-selected navigation-item mr-10 mb-10'" (click)="selectedPhase = 1">Citación</li>
          <div class="navigation-container">
            <h2 class="navigation-subtitle">Audiencia Pública</h2>
            <div class="items-container">
              <li class="navigation-item" [class]="selectedPhase === 2 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 2">Datos generales</li>
              <li class="navigation-item" [class]="selectedPhase === 3 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 3">Descargos y declaraciones</li>
              <li class="navigation-item" [class]="selectedPhase === 4 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 4">Decisión y recursos</li>
            </div>
          </div>
          <li class="navigation-item" [class]="selectedPhase === 5 ? 'selected navigation-item mb-10': 'no-selected navigation-item mb-10'" (click)="selectedPhase = 5">Seguimiento</li>
        </ul>
      </nav>
      <app-citacion
        *ngIf="selectedPhase === 1"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [mecIntId]="mecIntId"
        [correosEnviados]="correosEnviados"
        [selectedHearing]="selectedHearing"
        [isSelected]="isSelected"
        (onSelectHearing)="hearingSelected($event)"
      ></app-citacion>
      <app-datos-generales
        *ngIf="selectedPhase === 2"
        [audienciasDisponibles]="audienciasDisponibles"
        [isSelected]="isSelected"
        [mecIntId]="mecIntId"
        [selectedHearing]="selectedHearing"
        (onSelectHearing)="hearingSelected($event)"
      ></app-datos-generales>
      <app-descargos
        *ngIf="selectedPhase === 3"
        [isSelected]="isSelected"
        [mecIntId]="mecIntId"
        [selectedHearing]="selectedHearing"
        [audienciasDisponibles]="audienciasDisponibles"
        (onSelectHearing)="hearingSelected($event)"
      ></app-descargos>
      <app-decision-recursos
        *ngIf="selectedPhase === 4"
        [mecIntId]="mecIntId"
      ></app-decision-recursos>
      <app-seguimiento
        *ngIf="selectedPhase === 5"
        [mecIntId]="mecIntId"
      ></app-seguimiento>
    </main>
  </div>
</div>
