<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="premisas-container">
    <div class="d-flex-between">
      <div class="left-side mb-30 mt-30">
        <button class="go-back-button" routerLink="/submodulos-inspecciones">
          <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
        </button>
        <h1 class="principal-title">Premisas fundamentales</h1>
        <span class="info-text" routerLink="" (mouseover)="showFloatingCard()" (mouseout)="hideFloatingCard()"><img
            class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> </span>
        <div class="floating-card" id="floating-card">
          <label>En este primer momento nos vamos a referir a las habilidades que el inspector de Policía debe
            esforzarse por poner en práctica en la cotidianidad
            de sus labores, a fin de lograr resultados favorables en la gestión de los asuntos a su cargo. Aquí nos
            vamos a centrar en las habilidades sociales y en las habilidades técnicas:
          </label>
        </div>
      </div>

    </div>
    <main class="main-prev-mec">
      <nav class="navigation">
        <div class="navigation-list">
          <div class="navigation-item"
            [class]="selectedPhase === 1 ? 'selected navigation-item': 'no-selected navigation-item'"
            (click)="selectedPhase = 1">Habilidades sociales</div>
          <div class="navigation-item"
            [class]="selectedPhase === 2 ? 'selected navigation-item': 'no-selected navigation-item'"
            (click)="selectedPhase = 2">Habilidades técnicas</div>
          <div class="navigation-item"
            [class]="selectedPhase === 3 ? 'selected navigation-item': 'no-selected navigation-item'"
            (click)="selectedPhase = 3">Glosario</div>
        </div>
      </nav>
      <section class="" *ngIf="selectedPhase === 1">
        <div>
          <h2 class="title-section">Habilidades sociales</h2>
          <div class="mb-20">
            <p class="p-section">Son las habilidades de las personas para relacionarse mejor. Se refieren a “las
              capacidades para regular los pensamientos, las emociones y la conducta del individuo” (OCDE, 2015).
              También determinan qué tan bien se adapta la gente a su entorno y cuánto logran en sus vidas. Se enfocan
              en cómo las personas manejan sus emociones, se perciben a sí mismas, se involucran con otras y son
              habilidades que influyen en una amplia gama de resultados personales y sociales.</p>
          </div>
        </div>
        <div>
          <h2 class="title-section">Competencias Socioemocionales</h2>
          <div class="mb-40">
            <p class="p-section">
              Las habilidades sociales comprenden las competencias socioemocionales. Las competencias socioemocionales
              pueden dividirse en los siguientes tipos: comunicativas, cognitivas, emocionales e integradoras.
            </p>

            <div class="container-gap">
              <div class="card-numbers">
                <h1 class="number-tittle">1</h1>
                <h5 class="tittle-number">Comunicativas</h5>
                <p class="p-number">
                  Permiten una forma más eficiente de comunicación y potenciar las relaciones sociales
                </p>
              </div>
              <div class="card-numbers">
                <h1 class="number-tittle">2</h1>
                <h5 class="tittle-number">Emocionales</h5>
                <p class="p-number">
                  Necesarias para identificar y responder constructivamente ante las emociones propias y las de los
                  demás
                </p>
              </div>
            </div>
            <div class="container-gap">
              <div class="card-numbers">
                <h1 class="number-tittle">3</h1>
                <h5 class="tittle-number">Cognitivas</h5>
                <p class="p-number">
                  Cruciales para realizar diversos procesos mentales
                </p>
              </div>
              <div class="card-numbers">
                <h1 class="number-tittle">4</h1>
                <h5 class="tittle-number">Integradoras</h5>
                <p class="p-number">
                  Articulan los conocimientos y las competencias cognitivas, emocionales o comunicativas
                </p>
              </div>
            </div>

            <p class="p-section">
              A continuación, encontrarás cinco videos que profundizan sobre cinco de estas capacidades
              socioemocionales, para construir aprendizajes y mejorar tu desempeño como inspector o inspectora de policía.
            </p>
          </div>
        </div>
        <div class="habsoc-sect-1">
          <h3 class="title-section title-habilidad">Habilidades comunicativas:</h3>
          <div class="sect-1-container">
            <div class="text-container">
              <p class="p-section2">
                Escucha activa y empatía. Es la primera habilidad que se pone en práctica en la relación con otros.
                Antes
                que empezar a emitir opiniones o juicios, es necesario escuchar y comprender el mensaje que se está
                transmitiendo. La escucha activa implica que se está prestando total atención al hablante, que estamos
                reflexionando en lo que dice mientras habla y le demostramos que sí estamos escuchando. Sólo cuando
                termine la idea o ya la haya expresado con claridad, podemos intervenir bien sea para preguntar o para
                expresar nuestra opinión. La empatía ayuda a establecer un diálogo basado en el respeto por el otro,
                aprender a ponernos en sus zapatos y comprender su situación no sólo desde el punto de vista formal y
                legal, sino también social, anímico, emocional, etc.
              </p>
            </div>
            <div class="images-container-flex">
              <div class="container-big-image">
                <img class="big-image" src="/assets/img/inspecciones/habilidades-comunicativas-1.png" alt="Habilidades comunicativas imagen">
                <!-- <video controls class = "video-big-image"> <source src="/assets/videos/inspecciones/Comunicación eficaz.mp4" type="video/mp4"> </video> -->
              </div>
              <div class="container-short-image">
                <img class="short-image-1" src="/assets/img/inspecciones/habilidades-comunicativas-2.png" alt="Habilidades comunicativas imagen 2">
                <img class="short-image-2" src="/assets/img/inspecciones/habilidades-comunicativas-3.png" alt="Habilidades comunicativas imagen 3">
              </div>
            </div>
          </div>
          <div class="sect-info-container">
            <div class="container-first-info">

              <h5 class="title-section-info">Asertividad</h5>
              <video controls class="video-sect-info" preload="none"
                poster="/assets/img/inspecciones/Asertividad Background.png">
                <source src="https://fip-recursos.s3.amazonaws.com/videos/NXGSiRYRJDOigfq" type="video/mp4">
              </video>
              <p><span>¡Un gusto verte de nuevo!</span> Este video explicará brevemente qué es la asertividad, por qué
                es importante para tu quehacer, y te dará algunas recomendaciones prácticas para fortalecerla en tu día
                a día. </p>
              <a (click)="openModal('A')" class="ver-mas">Ver más</a>
              <!-- <p> La empatía es la capacidad para sentir lo que otras personas sienten, o sentir algo compatible con la situación que otra persona esté viviendo. Por ejemplo, alguien demuestra empatía si se siente mal cuando ve sufrir a otras personas o si se alegra con lo bueno que le pasa a alguien. En cambio, alguien demuestra falta de empatía cuando no siente nada, o incluso se alegra, al ver a otras personas sufrir. </p> -->
            </div>
            <div class="container-second-info">
              <h5 class="title-section-info">Empatía</h5>
              <video controls class="video-sect-info" preload="none"
                poster="/assets/img/inspecciones/portada-empatia.png">
                <source src="https://fip-recursos.s3.amazonaws.com/videos/Ndki7fvOgn6yBf1" type="video/mp4">
              </video>

              <p><span>¡Hola!</span> En este video te explicaremos qué es la empatía y por qué es relevante para tu
                labor, y brindaremos algunas recomendaciones sencillas para fortalecerla en tu actividad como inspector.
              <p>
                <a (click)="openModal('B')" class="ver-mas">Ver más</a>
                <!-- <p>La empatía es la capacidad para sentir lo que otras personas sienten, o sentir algo compatible con la situación que otra persona esté viviendo. Por ejemplo, alguien demuestra empatía si se siente mal cuando ve sufrir a otras personas o si se alegra con lo bueno que le pasa a alguien. En cambio, alguien demuestra falta de empatía cuando no siente nada, o incluso se alegra, al ver a otras personas sufrir. </p> -->
            </div>
          </div>

          <div class="sect-3-container">
            <h5 class="title-section3">Trabajo en equipo</h5>
            <video controls class="image-sect-3" preload="none"
              poster="/assets/img/inspecciones/portada-trabajo-en-equipo.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/wZMT2HPGtJ2f4U0" type="video/mp4">
            </video>

            <p><span>¡Hola!</span> Aquí estaremos explicando qué es el trabajo en equipo, su importancia para tu labor,
              y algunas recomendaciones sencillas que puedes seguir en tu día a día para fortalecerlo.</p>
            <br>
            <!-- <p>El trabajo en equipo es la capacidad de trabajar de manera constructiva con otras personas, partiendo de interesarse genuinamente por conocer las diferentes formas de trabajo, posiciones y visiones para realizar una tarea, reconociendo la diversidad y teniendo la disposición de formular una acción conjunta.</p> -->
            <a style="float: left; margin-left: 20%; color: #26529f;
              text-align: center;
              cursor: pointer;" (click)="openModal('C')" class="ver-mas">Ver más</a>
          </div>

          <div class="sect-info-container">
            <div class="container-first-info">
              <h5 class="title-section-info">Toma de decisiones</h5>
              <video controls class="video-sect-info" preload="none"
                poster="/assets/img/inspecciones/portada-toma-de-decisiones.png">
                <source src="https://fip-recursos.s3.amazonaws.com/videos/6edbMqtZkr19CAd" type="video/mp4">
              </video>
              <p class="font16"><span>¡Saludos!</span> Este video te guiará sobre la toma de decisiones, empezando por explicar qué es,
                <br> y luego mostrando su importancia para la labor de...
              </p>
              <!-- <p>La toma de decisiones es la habilidad para elegir dentro de diferentes opciones, definiendo y analizando la situación, tomando perspectiva, generando opciones y considerando consecuencias, para actuar y luego evaluar lo ocurrido.</p> -->
              <a (click)="openModal('D')" class="ver-mas">Ver más</a>

            </div>
            <div class="container-second-info">
              <h5 class="title-section-info">Liderazgo</h5>
              <video controls class="video-sect-info" preload="none"
                poster="/assets/img/inspecciones/portada-liderazgo.png">
                <source src="https://fip-recursos.s3.amazonaws.com/videos/ShP0VPLIG0cjNHh" type="video/mp4">
              </video>
              <p><span>¡Un gusto verte!</span> En este video explicaremos cómo mejor definir el liderazgo, por qué es
                importante para un inspector de policía, y cómo mejorarlo en tu día a día.
              <p>

                <!-- <p>El liderazgo es la influencia interpersonal ejercida en una situación, dirigida a través del proceso de comunicación humana, a la consecución de uno o diversos objetivos específicos. Aunque ha sido definido de diversas maneras, se destaca  la  capacidad del  líder de guiar  <br> de forma natural a su equipo,  así como de conseguir los objetivos comunes o mejorar el rendimiento de las personas.</p> -->
              <a (click)="openModal('E')" class="ver-mas">Ver más</a>

            </div>
          </div>

          <div class="sect-info-container">
            <div class="container-first-info">
              <h5 class="title-section-info">Parafraseo</h5>
              <img src="/assets/img/inspecciones/parafraseo.png" alt="parafraseo imagen" class="image-sect-info">
              <p>Es la habilidad de expresar el mensaje que nos han transmitido, de forma breve, concreta y en nuestras
                propias palabras, se utiliza sobre todo...</p>
            </div>
            <div class="container-second-info">
              <h5 class="title-section-info">Capacidad de expresión de ideas</h5>
              <img src="/assets/img/inspecciones/expresion-de-ideas.png" alt="expresion de ideas imagen" class="image-sect-info">
              <p>Escuchar activamente y comprender lo que se lee es necesario e importante, pero también lo es podemos
                comunicar con el otro de forma que...
              <p>
            </div>
          </div>

          <div class="sect-info-container last-info">
            <div class="container-first-info">
              <h5 class="title-section-info">Comprensión de lectura</h5>
              <img src="/assets/img/inspecciones/comprension-de-lectura.png" alt="comprension de lectura imagen"
                class="image-sect-info last-image-sect-info">
              <p>Sucede con bastante frecuencia que cuando se reciben mensajes escritos formales (radicados, memoriales,
                peticiones, etc..)</p>
            </div>
            <div class="container-second-info last-second-info">
              <h5 class="title-section-info">Capacidad de expresión de ideas</h5>
              <img src="/assets/img/inspecciones/trabajo-bajo-presion.png" alt="trabajo bajo presión imagen" class="image-sect-info">
              <p>Es bien sabido que la naturaleza del trabajo de los inspectores radica en estar constantemente
                expuestos a conflictos, usuarios, abogados...
              <p>
            </div>
          </div>

          <!-- <div class="habsoc-sect-2">
            <div class="habsoc-card">
              <img src="" alt="">
              <h4>Comprensión de lectura</h4>
              <p>Sucede con bastante frecuencia que cuando se reciben mensajes escritos formales (radicados, memoriales,
                peticiones, etc.)</p>
            </div>
            <div class="habsoc-card">
              <img src="" alt="">
              <h4>Parafraseo</h4>
              <p>Es la habilidad de expresar el mensaje que nos han transmitido, de forma breve, concreta y en nuestras
                propias palabras; se utiliza sobre todo…</p>
            </div>
            <div class="habsoc-card">
              <img src="" alt="">
              <h4>Capacidad de expresión de ideas</h4>
              <p>Escuchar activamente y comprender lo que se lee es necesario e importante, pero también lo es podernos
                comunicar con el otro de forma que…</p>
            </div>
            <div class="habsoc-card">
              <img src="" alt="">
              <h4>Comprensión de lectura</h4>
              <p>Sucede con bastante frecuencia que cuando se reciben mensajes escritos formales (radicados, memoriales,
                peticiones, etc.)</p>
            </div>
          </div> -->
        </div>
      </section>
      <section *ngIf="selectedPhase === 2">
        <div>
          <h2 class="title-section">Habilidades técnicas</h2>
          <div style="align-items: center;">

            <p class="p-section">Estas habilidades son aquellas orientadas para que las personas puedan desempeñarse
              mejor en sus actividades laborales. Hacen referencia al uso de herramientas y técnicas que una persona
              domina y que son necesarias para ejercer su trabajo. Se asocian a una serie de métodos sistemáticos para
              la gestión de procesos, aplicación de conocimientos y puesta en práctica de la experiencia (Kanz & Lam,
              1996). Según el artículo 62 del Decreto 2852 de 2013, las competencias técnicas se relacionan con
              competencias laborales específicas, es decir: “conocimientos, destrezas y habilidades relacionados con una
              ocupación en particular o un grupo de ocupaciones”.
            </p>
          </div>
        </div>

        <!-- <div>
          <p class="p-section-tecnicas">
            A continuación, encontrarás un video que profundiza sobre dos habilidades técnicas esenciales.
          </p>
        </div>

        <div class = "contenedor-video-tecnicas">
          <video controls width = "500" height = "500"> <source src="/assets/videos/inspecciones/mecanismos-accion-medios-orden.mp4" type="video/mp4"> </video>
        </div>
        -->

        <h3 class="title-section title-habilidad" style="margin-top: 10%;">Competencias legales:</h3>
        <div class="sect-1-container container-competencias">
          <div class="text-container">
            <p class="p-section2">
              Identificar con claridad cuáles asuntos competen al Inspector, a la policía uniformada y a otras
              autoridades, así como las funcionaes compartidas
              con los alcaldes municipales y otras autoridades locales, conocimiento y manejo de aspectos sustanciales y
              procesales del régimen de policía:
              principios constitucionales y legales, conceptos generales, normas de procedimiento.
            </p>
            <p class="p-section2">
              Como aporte al conocimiento
              se integra al presente módulo un glossario de nociones básicas y fundamentales del régimen de policía como
              herramienta práctica de trabajo y que pueda
              ser enriquecido progresivamente. Se describen a grandes rasgos las nociones básicas que todo Inspector de
              Policía, abogado o no, debe conocer y manejar para desempeñar adecuadamente su labor.
            </p>
          </div>



          <div class="images-container-flex">
            <div class="container-short-image">
              <img class="short-image-1" src="/assets/img/inspecciones/competencias-legales-1.png" alt="Competencias legales">
              <img class="short-image-2" src="/assets/img/inspecciones/competencias-legales-2.png" alt="Competencias legales">
            </div>
            <div class="container-big-image2">
              <img class="big-image second-big-image" src="/assets/img/inspecciones/competencias-legales-3.png" alt="Competencias legales">
            </div>
          </div>
        </div>

        <div class="sect-info-container sec2">
          <div class="container-first-info">
            <h5 class="title-section-info font16">Comunicación eficaz (oral y escrita), sostenibilidad y compromiso
              social</h5>
            <video controls class="video-sect-info" preload="none"
              poster="/assets/img/inspecciones/portada-comunicacion-eficaz.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/k0CM5jptihqoWyu" type="video/mp4">
            </video>
            <p><span>¡Hola!</span> A través del siguiente video, exploraremos brevemente dos habilidades técnicas
              cruciales para desempeñar la labor de inspector de policía. </p>
            <a (click)="openModal(1)" class="ver-mas">Ver más</a>

          </div>
          <div class="container-second-info sec2">
            <h5 class="title-section-info font16">Objetivos, principios, deberes de autoridades de policía y
              atribuciones de inspectores</h5>
            <video controls class="video-sect-info" preload="none"
              poster="/assets/img/inspecciones/portada-objetivos-principios.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/JZ1tkqPtBhSFha5" type="video/mp4">
            </video>
            <p><span>¡Bienvenidos!</span> Empezaremos por delinear los objetivos y principios consignados en el Código
              Nacional de Seguridad y Convivencia Ciudadana, <br> como también los deberes de las autoridades de policía
              y las atribuciones de los inspectores urbanos, rurales y corregidores. </p>
            <a (click)="openModal(2)" class="ver-mas">Ver más</a>
          </div>
        </div>

        <div class="sect-info-container sec2">
          <div class="container-first-info ">
            <h5 class="title-section-info font16">Régimen, poder, función y actividad de policía</h5>
            <video controls class="video-sect-info content-prev" preload="none"
              poster="/assets/img/inspecciones/portada-regimen-poder-funcion.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/JZ1tkqPtBhSFha5" type="video/mp4">
            </video>
            <!-- <p>¡Bienvenidos nuevamente! En este video, introduciremos las expresiones de régimen, poder, función y actividad de policía. </p>
            <a>Ver más</a> -->
            <p><span>¡Bienvenidos nuevamente!</span> En este video, introduciremos las expresiones de régimen, poder,
              función y actividad de policía.
            </p>
            <a (click)="openModal(3)" class="ver-mas">Ver más</a>
          </div>
          <div class="container-second-info">
            <h5 class="title-section-info font16">Orden público, convivencia, comportamientos contrarios a la convivencia y asuntos civiles de policía.</h5>
            <video controls class="video-sect-info" preload="none"
              poster="/assets/img/inspecciones/portada-orden-publico.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/sf18YAttCcCp0Qu" type="video/mp4">
            </video>
            <!-- <p>¡Bienvenidos! Explicaremos brevemente qué es orden público, convivencia, <br> y qué   son  los comportamientos contrarios a la convivencia y los asuntos civiles de policía! </p>
            <a>Ver más</a> -->
            <p><span>¡Bienvenidos! </span> Explicaremos brevemente qué es orden público, convivencia, y qué son los
              comportamientos contrarios a la convivencia y los asuntos civiles de policía! </p>
            <a (click)="openModal(4)" class="ver-mas">Ver más</a>
          </div>
        </div>

        <div class="sect-info-container sec2">
          <div class="container-first-info">
            <h5 class="title-section-info font16">Mecanismos, acción, medios y orden de policía</h5>

            <video controls class="video-sect-info" preload="none"
              poster="/assets/img/inspecciones/portada-mecanismos-accion.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/Ds1PJxDbfK0Z0c6" type="video/mp4">
            </video>
            <!-- <p>¡Un gusto verlos de nuevo! Estaremos explorando brevemente qué son los mecanismos, acción, medios y orden de policía. </p>
            <a>Ver más</a> -->
            <p><span>¡Un gusto verlos de nuevo!</span> Estaremos explorando brevemente qué son los mecanismos, acción,
              medios y orden de policía.</p>
            <a (click)="openModal(5)" class="ver-mas">Ver más</a>
          </div>
          <div class="container-second-info">
            <h5 class="title-section-info font16">Medidas correctivas, orden de comparendo, Procedimiento Verbal
              Inmediato, Procedimiento Verbal Abreviado y mecanismos alternativos</h5>
            <video controls class="video-sect-info" preload="none"
              poster="/assets/img/inspecciones/portada-medidas-correctivas.png">
              <source src="https://fip-recursos.s3.amazonaws.com/videos/XdRhpNMEeYjC5CM" type="video/mp4">
            </video>
            <p><span>¡Hola!</span> Estaremos revisando qué son las medidas correctivas, órdenes de comparendo <br> y los
              mecanismos alternativos.</p>
            <a (click)="openModal(6)" class="ver-mas">Ver más</a>
          </div>
        </div>
        <div class="section-tecnicas-footer">
          <h3 class="title-section-footer">Glosario</h3>
          <a class="button-tecnicas-footer" (click)="selectedPhase = 3">Visítalo</a>
        </div>
      </section>
      <section *ngIf="selectedPhase === 3">
        <div class="glossary-search-engine">
          <input type="text" name="" id="input-search-letters" (keyup)="searchFromText($event.target.value)"
            class="search-section3" placeholder="Ingresa tu búsqueda">
        </div>
        <div class="letter-search-engine">
          <div class="letra" *ngFor="let letter of alphabet; index as i">
            <span class="letter-not-selected" (click)="searchByLetter(i)" [class.letter-selected]="letter.selected">{{
              letter.letter | uppercase}}</span>
          </div>
        </div>
        <div class="definitions-container">
          <div class="result-by-letter" *ngFor="let letter of glosario">
            <ng-container *ngIf="letter.show">
              <hr>
              <a class="result-letter">{{ letter.letter }}</a>
              <ng-container *ngFor="let definition of letter.definitions">
                <p class="definition-title" (click)="definition.show = !definition.show">{{ definition.title }}<a
                    class="icon-container"><mat-icon class="black-icon" fontIcon="chevron_right"></mat-icon></a></p>
                <div [innerHTML]="definition.definition" class="definition" [class.show]="definition.show"></div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </section>
    </main>
    <div class="modal-bibliografy-container" id="modal-bibliografy-container">
      <div class="modal-bibliografy">
        <a class="button">
          <label (click)="closeModal()">
            X
          </label>
        </a>
        <div class="pdf" id="pdf">
        </div>
      </div>
    </div>
  </div>
</main>
