import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { Foro } from '../inspecciones.interface';
import { ForoService } from './foro.service';

@Component({
  selector: 'app-foro-inspecciones',
  templateUrl: './foro-inspecciones.component.html',
  styleUrls: ['./foro-inspecciones.component.css']
})
export class ForoInspeccionesComponent implements OnInit {

  public foros: Foro[] = [];
  public allForos: Foro[] = [];

  constructor(
    private utilsService: UtilsService,
    private forumService: ForoService
  ) { }

  ngOnInit() {
    this.getForums();
  }

  /**
   * Busca los foros relacionados mediante su nombre por la búsqueda que realice el usuario
   * @param value El valor a buscar en el nombre
   */
  getRelatedForums(value: string){
    this.foros = this.allForos.filter(foro => {
      return foro.nombre.toLowerCase().includes(value.toLowerCase())
    });
  }

  /**
   * Obtiene todos los foros de la base de datos
   */
  getForums(): void {
    this.forumService.getAllForums().then(forums => {
      this.allForos = forums;
      this.foros = forums;
    })
  }

  /**
   * Actualiza la fecha en formato largo, ejemplo: viernes, 16 de octubre de 2022 16:23
   * @param date La fecha a formatear
   * @returns La fecha formateada
   */
  formatDate(date: Date | string): string {
    return this.utilsService.formatVisualDate(date);
  }


}
