import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../services/main.service';
import { FichaGeneral } from '../../inspecciones.interface';
import { MecanismoIntermedioService } from './mecanismo-intermedio.service';

@Component({
  selector: 'app-mecanismo-intermedio',
  templateUrl: './mecanismo-intermedio.component.html',
  styleUrls: ['./mecanismo-intermedio.component.css']
})
export class MecanismoIntermedioComponent implements OnInit {
  selectedPhase: number = 1;
  caseId: string = '';
  caseNumber: string = '';
  inspectionNumber: string = '';
  presuntosInfractores: any = [];
  cases: any = [];

  @Input() selectedHearing: any = {};

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  audienciasDisponibles: any[];
  mecIntId: string;
  correosEnviados: any[];
  isSelected: boolean = false;

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private mecIntService: MecanismoIntermedioService
  ) { }

  ngOnInit() {
    this.getCaseInfo();
    this.getMecPrevInfo();
  }

  getCaseInfo(){
    this.caseId = this.route.snapshot.params['id'];
    this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<FichaGeneral>(response)){
        this.presuntosInfractores = response.result.infractores;
        this.caseNumber = response.result.caso.numCaso;
        this.inspectionNumber = response.result.caso.numInspeccion;
        this.cases = response.result.CCC;
      } else {
        console.error(response.error);
      }
    })
  }

  getMecPrevInfo(){
    this.mecIntService.getAudienceInfo(this.caseId).then(response => {
      this.audienciasDisponibles = response.audienciasDisponibles;
      this.mecIntId = response.mecIntId;
      this.correosEnviados = response.correosEnviados;
    })
  }

  goBackButton(){
    this.router.navigate([`/gestion-casos-inspecciones/ver-caso/${this.caseId}`]);
  }

  /**
   * Va a la pantalla de más información
   */
  goToMoreInfo(){
    this.router.navigateByUrl(
      this.router.createUrlTree(['/submodulos-inspecciones/mecanismos-intermedios'], { queryParams: { previousPage: 'mecanismo-intermedio', caseId: this.caseId } })
    )
  }

  hearingSelected({hearing, isSelected}){
    if(!isSelected){
      this.selectedHearing = {};
      this.isSelected = false;
    } else {
      this.selectedHearing = hearing;
      this.isSelected = isSelected
    }
  }
}
