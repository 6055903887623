import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../services/main.service';
import { FichaGeneral } from '../../inspecciones.interface';

@Component({
  selector: 'app-mecanismo-previo',
  templateUrl: './mecanismo-previo.component.html',
  styleUrls: ['./mecanismo-previo.component.css']
})
export class MecanismoPrevioComponent implements OnInit {
  selectedPhase: number = 1;
  caseId: string = '';
  caseNumber: string = '';
  inspectionNumber: string = '';
  presuntosInfractores = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
  ) { }

  ngOnInit() {
    this.getCaseInfo();
  }

  getCaseInfo(){
    this.caseId = this.route.snapshot.params['id'];
    this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<FichaGeneral>(response)){
        this.presuntosInfractores = response.result.infractores;
        this.caseNumber = response.result.caso.numCaso;
        this.inspectionNumber = response.result.caso.numInspeccion;
      }
    })
  }

  goBackButton(){
    this.router.navigate([`/gestion-casos-inspecciones/ver-caso/${this.caseId}`]);
  }

  goToStrategies(){
    this.router.navigate([`/foro-inspecciones/foro/63a0ab108235077b322a5e01`]);
  }

  /**
   * Va a la pantalla de más información
   */
  goToMoreInfo(){
    this.router.navigateByUrl(
      this.router.createUrlTree(['/submodulos-inspecciones/mecanismos-previos'], { queryParams: { previousPage: 'mecanismo-previo', caseId: this.caseId } })
    );  
  }
}
