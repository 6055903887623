<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="d-flex-between">
    <div class="left-side">
      <button class="go-back-button" (click)="goToPreviousPage()">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title mb-30">Proceso verbal abreviado</h1>
    </div>
    <!-- <button class="button active" routerLink="/gestion-casos-inspecciones/nuevo-caso">Nuevo caso +</button> -->
  </div>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(1)">
    <h2 class="previous-mechanism-title">Descripción general</h2>
    <img [class]="firstPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="firstPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      El PVA es la herramienta jurídica con la que cuentan los Inspectores de Policía para imponer medios de policía y medidas correctivas cuando quiera que lleguen a su conocimiento hechos que puedan configurar un comportamiento contrario a la convivencia, en el ámbito de sus competencias. Como todo proceso legal consta de unas etapas que deben ser adelantadas en el orden señalado en la norma, a fin de garantizar el debido proceso y el derecho de defensa de la persona investigada. Se deben tener en cuenta los principios del proceso único de policía señalados en el artículo 213 del CNSCC: oralidad, gratuidad, inmediatez, la oportunidad, celeridad, eficacia, transparencia y buena fe. La oralidad implica que las diligencias se deben adelantar en audiencia, de modo que el Inspector esté presente durante todo el desarrollo de la misma y practique directamente las pruebas. La inmediatez, oportunidad, celeridad y eficacia están relacionados con la prontitud para desarrollar el proceso, garantizando que las medidas que se adopten tengan un efecto práctico rápido en materia de convivencia. A continuación se hará referencia a los presupuestos procesales, es decir, los requisitos que se deben tener en cuenta para iniciar y adelantar la actuación, sin los cuales se podrían incurrir en irregularidades que afecten los derechos de las personas investigadas. Luego se detallarán cada una de las etapas del proceso explicarán los presupuestos fácticos y jurídicos para la imposición de la medida correctiva, que son aquéllos aspectos relacionados con los hechos y con la norma que deben estar debidamente demostrados para que la decisión sea totalmente legal.
    </p>

  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(2)">
    <h2 class="previous-mechanism-title">Presupuestos procesales</h2>
    <img [class]="secondPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="secondPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      * Cumplimiento del principio de necesidad. El PVA solo debe iniciarse siempre y cuando la aplicación de otros mecanismos para alcanzar el fin señalado no sea posible, como a los que aquí se ha hecho referencia en la etapa previa e intermedia, en aplicación del principio de necesidad consagrado en el numeral 13 del artículo 8 del CNSCC. Esto fue reiterado en el artículo 223 A literal A de la Ley 2197 de 2022. <br><br>
      * Inspector sea competente para conocer el CCC y para imponer la medida correctiva asociada al mismo. <br><br>
      * Que no haya operado la caducidad en los casos civiles y en los CC a la integridad urbanística, (arts. 80 y 138 del CNSCC). La acción de policía en los casos civiles de protección a la tenencia, posesión y servidumbres caduca a los 4 meses (art. 80). La acción en los casos de protección a la integridad urbanística, cuando se trate de parcelar, urbanizar e intervenir en terrenos aptos para estas actuaciones, caduca a los 3 años, contados a partir de la ocurrencia del hecho (art. 138), salvo en los casos de intervenciones a bienes de interés cultural (art. 226).
    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(3)">
    <h2 class="previous-mechanism-title">Iniciación de la acción</h2>
    <img [class]="thirdPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="thirdPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      El CNSCC señala que la acción puede iniciarse de oficio o a petición de la persona que tenga interés en la aplicación del régimen de policía. Ahora bien, al analizar el código de forma integral, vemos que esa iniciación se puede dar en la práctica de las siguientes maneras: <br><br>
      <strong>* De oficio:</strong> <br> Informe final de mecanismos previos / intermedios no eficaces. Este informe es el documento final que se ha comentado en los submódulos de Mecanismos Previos y Mecanismos Intermedios. Da lugar al inicio del PVA cuando las acciones adelantadas por el propio Inspector o por una autoridad uniformada no resultan eficaces. Por orden de comparendo remitido a los Inspectores de Policía. Comprende la flagrancia. Sobre esta forma de inicio nos detendremos a continuación. Por informe de policía o de otra autoridad civil. Puede haber situaciones que afectan a la convivencia que lleguen al conocimiento directo de las autoridades uniformadas o civiles y que adelanten alguna actuación oficiosa, ej. Una visita de verificación, que al identificar hechos que no sean de su competencia, remitan a las Inspecciones de Policía. <br><br>
      <strong>* A petición de parte:</strong> <br>Por derecho de petición. Pueden ser peticiones escritas, verbales o radicadas a través de los sistemas de PQR (peticiones, quejas, reclamos) establecidos en las páginas web de las Alcaldías o dependencias de estas. La petición puede ser presentada por cualquier ciudadano, afectado o no con el CCC. Aun cuando existe un término general de 15 días para dar respuesta a estas peticiones, pueden dar lugar al inicio de un P.V.A. Para efectos de la respuesta a la petición es necesario tener en cuenta los artículos 4 y siguientes del Código de Procedimiento Administrativo y de lo Contencioso Administrativo (CPACA) y la Ley 1755 de 2015.<br><br>
      <strong>* Por querella. </strong><br>A diferencia de la petición, que tiene un carácter general, la querella se refiere a la solicitud que hace la persona afectada con un CCC relacionados con la protección a la posesión, tenencia y servidumbres de bienes inmuebles, como señalan los artículos 79 y 82 del CNSCC.
    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(4)">
    <h2 class="previous-mechanism-title">La Orden de Comparendo como forma de inicio del P.V.A.</h2>
    <img [class]="fourthPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="fourthPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      Consideramos necesario hacer mención especial a este documento por cuanto en la actualidad la mayoría de procesos de competencia de los Inspectores son iniciados por esta vía. Es sumamente elevado el número de comparendos que diariamente imponen los agentes uniformados de la policía y que luego le son repartidos a los Inspectores de Policía. Además, es importante porque existen unas nuevas reglas introducidas por la Ley 2197 de 2022 que deben ser tomadas en cuenta. Como se mencionó en el glosario, una orden de comparendo es una especie de informe policial en el que se deja constancia de la situación presentada, datos del presunto responsable y la citación para comparecer a la autoridad de policía competente. Si bien allí se pueden señalar los medios de policía utilizados y las medidas correctivas a las que da lugar el CCC, no constituye un acta de imposición de medida correctiva tratándose de comportamientos cuya competencia está asignada a los Inspectores de Policía. En este caso, la OC constituye una simple citación para que la persona comparezca ante el funcionario competente. <br><br>
      No obstante, la práctica que se ha tenido hasta el momento por parte de las autoridades uniformadas de policía y el diseño del formato hoy vigente, adoptado mediante Resolución No. 3253 del 12 de julio de 2017 del Director General de la Policía Nacional de Colombia, genera cierta confusión para la persona presuntamente incursa en el comportamiento, pues se utiliza el mismo formulario con un doble propósito: como orden de comparendo en estricto sentido y como acta de imposición de medios de policía y medidas correctivas. <br><br>
      Partes del formulario actual de orden de comparendo, según la Resolución 3253 de 2017 de la Policía Nacional: <br><br>
      <strong>*</strong> Número de incidente y de comparendo <br>
      <strong>*</strong> Fecha y hora <br>
      <strong>*</strong> Lugar de ocurrencia del CCC (dirección, municipio, localidad) <br>
      <strong>*</strong> Datos del presunto infractor (identificación, dirección, teléfono, correo electrónico) <br>
      <strong>*</strong> Datos de quien ejerce la custodia o patria potestad, si el implicado es menor de 18 años. <br>
      <strong>*</strong> Datos del lugar donde se desarrolla la actividad económica (razón social, actividad, Nit, dirección), si es control a establecimiento de comercio. <br>
      <strong>*</strong> Medios de policía utilizados (lista de selección múltiple) <br>
      <strong>*</strong> Descripción del CCC <br>
      <strong>*</strong> Descargos <br>
      <strong>*</strong> Norma presuntamente vulnerada del CNSCC <br>
      <strong>*</strong> Medida correctiva (lisa de selección múltiple) <br>
      <strong>*</strong> Procedencia de recurso de apelación (selección sí/no) <br>
      <strong>*</strong> Autoridad competente donde se remite la orden de comparendo <br>
      <strong>*</strong> Datos del funcionario de policía que elabora la orden <br>
      <strong>*</strong> Firmas y huella del presunto infractor o del adulto responsable <br><br>
      Se reitera, si se trata de un CCC cuya competencia es del Inspector de Policía, el comparendo sólo puede ser utilizado como informe / citación que da lugar al inicio de un P.V.A., no como imposición de medida correctiva, ya que el agente uniformado no es competente para imponerla. <br><br>
      Sin embargo, la ley 2197 de 2022, que entró en vigencia el 25 de enero de 2022, atribuye al señalamiento de multa hecho en la OC el alcance de medida correctiva impuesta y en firme cuando no se objeta en un término de tres días. <br><br>
      El efecto que esto tiene para las competencias del Inspector de Policía es que no se puede iniciar el PVA, luego, en teoría, se puede disminuir el número de actuaciones que llegan a conocimiento del Inspector por la falta de objeción. <br><br>
      En resumen, si una OC que no ha sido objetada por el presunto infractor es expedida a partir del 25 de enero de 2022 y repartida después a un Inspector de Policía, este no debe conocerla ni iniciar el PVA, debe devolverla al remitente. Pero si la OC es objetada y repartida al Inspector, este debe conocerla e iniciar un PVA formalmente. Dicho de otra manera, a partir del 25 de enero de 2022, sólo da lugar al inicio del PVA una orden de comparendo objetada. <br><br>
      Otro aspecto a considerar es si la OC constituye una prueba del CCC, asunto que se analizará en la etapa de Pruebas de la Audiencia Pública.
    </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(5)">
    <h2 class="previous-mechanism-title">Citación</h2>
    <img [class]="fifthPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="fifthPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      Señala el numeral 2 del artículo 223 del CNSCC que a los cinco (5) días siguientes de conocida la querella o el comportamiento contrario a la convivencia, en caso de que no hubiera sido posible iniciar la audiencia de manera inmediata, citará a audiencia pública al quejoso y al presunto infractor, mediante comunicación escrita, correo certificado, medio electrónico, medio de comunicación del que disponga, o por el medio más expedito o idóneo, donde se señale dicho comportamiento. <br><br>
      Esta es una regla de procedimiento sencilla, pero debe cumplirse de forma rigurosa, pues una citación mal elaborada, bien sea por errores en los datos del destinatario, nombre, dirección, correo electrónico, etc. Implica que la audiencia pública, que es el siguiente paso, no se puede llevar a cabo, y si la audiencia se realiza en esas condiciones, sin presencia del citado, se encuentra viciada de nulidad lo mismo que la decisión allí adoptada. <br><br>
      Actualmente, la citación a audiencia puede realizarse a través del correo electrónico suministrado por el investigado al momento de la elaboración de la orden de comparendo, o en el que haya anotado en algún registro o documento ante la Inspección de Policía. <br><br>
      Si es una citación tradicional, debe hacerse a través de los canales institucionales dispuestos para tal efecto. <br><br>
      No olvidar que cuando se trate de querellas o haya una petición, se debe citar también al quejoso.
    </p>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(6)">
    <h2 class="previous-mechanism-title">Audiencia Pública</h2>
    <img [class]="sixPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="sixPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      Esta es la etapa más importante del PVA, pues en esta diligencia se da a conocer al presunto infractor el informe policía, la OC, las pruebas, tiene la oportunidad de ejercer su derecho de defensa, y se toma la decisión por parte del Inspector. <br><br>
      <strong>* Lugar</strong><br>
      La audiencia se puede desarrollar de forma presencial en el lugar de los hechos, en la oficina de la Inspección de Policía, o de forma virtual. En este caso, la citación debe incluir el enlace para que la persona se pueda conectar en la fecha y hora señalada. <br><br>
      <strong>*Registro</strong><br>
      Teniendo en cuenta que debe darse aplicación al principio de oralidad, la audiencia debe grabarse a través de los medios tecnológicos dispuestos para tal efecto. Si se realiza de forma presencial en el lugar de los hechos o en la oficina de la Inspección, debe disponerse de una cámara para grabar, en un lugar sin interferencias o ruidos y con suficiente iluminación, y almacenarse en un medio que permita su archivo digital para posterior consulta. Si se hace virtual, el programa o la aplicación deben contar con el sistema de grabación habilitado. Es viable dejar un acta en la que se resuma la audiencia, pero no es obligatorio. Lo ideal es contar con un sistema electrónico de registro de la actuación llevada a cabo, de modo que no sea necesario imprimir documentos ni armar expedientes físicos como aún se hace en algunas oficinas públicas. El acta sólo está prevista en el Código para registrar cierto tipo de actuaciones, como algunos medios de policía por parte del personal uniformado de la policía, la mediación y la conciliación (art. 233) <br><br>
      <strong>* Desarrollo</strong><br>
      Una vez instalados en el lugar donde se va a desarrollar y dispuestos los medios de grabación, el Inspector debe declarar iniciada la audiencia, mencionando la fecha, la hora, el lugar, el número de caso o radicado y el objeto de la misma; deja constancia de las personas presentes; en relación con el investigado, anotar su identificación y generales de ley (edad, profesión u oficio, nivel de estudios) y a continuación se le pone de presente la OC, el informe de policía y los medios de prueba para que se pronuncie sobre ellos, para lo cual dispone de 20 minutos. Si está presente el quejoso, también se le otorgan 20 minutos. El Inspector puede realizar las preguntas que estime necesarias para aclarar la situación. Si al final de las intervenciones el Inspector considera que aún no están claros los hechos, puede disponer la práctica de pruebas y pronunciarse sobre las solicitadas por el presunto infractor, las cuales deben practicarse en un término máximo de 5 días, siempre que sean pertinentes y conducentes. Una vez escuchado al citado y practicadas las pruebas decretadas, se reanuda la audiencia, se le ponen de presente las nuevas pruebas al presunto infractor y procede a tomar la decisión. <br><br>
      <strong>*Decisión y recursos</strong><br>
      El Inspector debe hacer una valoración fáctica y jurídica, haciendo un estudio de las pruebas practicadas y de las normas aplicables. Esta sustentación debe incluir de forma obligada un análisis del cumplimiento del principio de necesidad, que se debe cumplir en la forma que ha sido mostrada en los Submódulos Mecanismos Previos y Mecanismos Intermedios. <br><br>
      Dependiendo de la decisión adoptada, procede el recurso de reposición o el de reposición ante el mismo Inspector y en subsidio el de apelación: si se trata de un proceso de única instancia, sólo procede reposición ante el mismo Inspector (art. 206 num. 5). Si es proceso de primera instancia, procede reposición ante el Inspector y reposición y en subsidio apelación ante la autoridad administrativa especial de policía o el Alcalde (art. 206, núm. 6). La apelación se puede conceder en el efecto devolutivo. Estos recursos deben sustentarse en la misma diligencia y ante el superior de ser el caso, dentro de los dos días siguientes al recibo por parte de la autoridad de segunda instancia. En los casos de CC a la integridad urbanística, la apelación se concede en el efecto suspensivo. <br><br>
      La decisión que tome el Inspector puede ser: 1. imponer o abstenerse de imponer una medida correctiva; 2. imponer una orden de policía u otro medio de policía.
    </p>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(7)">
    <h2 class="previous-mechanism-title">Situación Audiencia</h2>
    <img [class]="sevenPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="sevenPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      <strong>* Citado no comparezca </strong> <br>
      Causa: <br>
      Errores en la citación. <br>
      Datos del citado: nombre de la persona o del establecimiento de comercio, dirección física o electrónica. <br>
      Datos de la Inspección: dirección física, enlace o link para conexión virtual, fecha de la diligencia. <br><br>
      Acciones a desarrollar: <br>
      1. Revisar citación. <br>
      2. Reprogramar diligencia y corregir errores en la citación. <br><br>
      Causa: <br>
      Citado no comparece por razón justificable <br><br>
      Acciones a desarrollar: <br>
      1. Suspender la audiencia por un término máximo de 3 días, para que el presunto infractor entregue prueba siquiera sumaria de una justa causa de inasistencia. <br>
      2. Revisar en la correspondencia física y correo electrónico de la Inspección, si obra alguna comunicación del citado. <br>
      3. Si el citado allega excusa y se encuentra admisible la justificación, se debe reprogramar la audiencia para continuarla. <br><br>
      Causa: <br>
      Citado no comparece y no justifica su inasistencia <br><br>
      Acciones a desarrollar: <br>
      La audiencia debe continuar y desarrollarse sin la presencia del citado. <br>
      En este caso, según el parágrafo 1 del art. 223, se tendrán por ciertos los hechos que dieron lugar al CCC.
    </p>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(8)">
    <h2 class="previous-mechanism-title">Cumplimiento o ejecución de la orden de policía o de la medida correctiva</h2>
    <img [class]="eightPreviousMechanismVerbal === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="eightPreviousMechanismVerbal">
    <p class="previous-mechanism-p">
      Según el numeral 5 del artículo 223, una vez ejecutoriada la decisión que contenga una orden de Policía o una medida correctiva, ésta se cumplirá en un término máximo de 5 días. <br><br>
      El término de 5 días es válido para la persona que debe cumplir la medida, y así se debe indicar por parte del Inspector al momento de adoptar la decisión en diligencia. Sin embargo, si se presenta incumplimiento por parte del infractor, la ejecución oficiosa o el cobro puede demorarse más tiempo dependiendo del tipo de decisión. Ejemplos: <br><br>
      La orden de policía, la suspensión definitiva de actividad y la recuperación de bienes comunales o de uso público pueden ejecutarse muy pronto con o sin la cooperación del personal uniformado de la policía (generalmente se busca el apoyo de la policía uniformada por razones de seguridad de los funcionarios). <br>
      Las demoliciones y suspensiones de obra requiere de una logística especial, normalmente contratada por los alcaldes municipales. En el caso de cobro de multas, existen dependencias de las alcaldías encargadas de los cobros coactivos.
    </p>
  </article>
</main>
