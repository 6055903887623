<app-header-inspecciones  [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="new-case-container">
    <aside class="aside-new-case">
      <h2 class="header-biblioteca-titulo">
        <button class="volver-button" (click)="volver()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda"> Volver</button>
      </h2>
      <h1 class="aside-title">Nuevo caso</h1>
      <div class="phases-container">
        <div class="phase-container">
          <p class="phase-title">Fase 1</p>
          <p class="phase-description">Mecanismo Previo</p>
        </div>
        <div class="phase-container">
          <p class="phase-title">Fase 2</p>
          <p class="phase-description">Mecanismo Intermedio</p>
        </div>
        <div class="phase-container">
          <p class="phase-title">Fase 3</p>
          <p class="phase-description">Proceso verbal abreviado</p>
        </div>
        <div class="phase-container">
          <p class="phase-title">Fase 4</p>
          <p class="phase-description">Seguimiento y evaluación</p>
        </div>
      </div>
    </aside>
    <main class="main-new-case">
      <app-form-ficha-general [isEditing]="false"></app-form-ficha-general>
    </main>
  </div>
</div>
