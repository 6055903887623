<app-header-inspecciones [opcionElegida]="3"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="submodules-container">
    <div class="d-flex-start-center">
      <button class="go-back-button" routerLink="/biblioteca-inspecciones">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title mb-20">Submódulos información</h1>
    </div>
    <div class="links-container">
      <a class="link-util-a" routerLink="/submodulos-inspecciones/premisas-fundamentales"><div class="link-util">
        <img src="/assets/img/inspecciones/premisas-fundamentales.png" class="link-imagen" alt="Logo calendario">
        <span class="link-texto">Premisas Fundamentales</span>
      </div></a>
      <a class="link-util-a" routerLink="/submodulos-inspecciones/mecanismos-previos"><div class="link-util">
        <img src="/assets/img/inspecciones/mecanismos-previos-1.png" class="link-imagen" alt="Logo calendario">
        <span class="link-texto">Mecanismos Previos</span>
      </div></a>
      <a class="link-util-a" routerLink="/submodulos-inspecciones/mecanismos-intermedios"><div class="link-util">
        <img src="/assets/img/inspecciones/mecanismos-intermedios-1.png" class="link-imagen" alt="Logo calendario">
        <span class="link-texto">Mecanismos Intermedios</span>
      </div></a>
      <a class="link-util-a" routerLink="/submodulos-inspecciones/pva"><div class="link-util">
        <img src="/assets/img/inspecciones/proceso-verbal-abreviado-1.png" class="link-imagen" alt="Logo calendario">
        <span class="link-texto">Proceso Verbal Abreviado</span>
      </div></a>
    </div>
  </div>
</main>
