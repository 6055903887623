import { Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import { MainService } from 'src/app/services/main.service';
import { CalendarEventInfo } from '../inspecciones.interface';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(
    private mainService: MainService
  ) { }

  getEvents(idInspector: string): Promise<CalendarEvent<CalendarEventInfo>[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get<CalendarEvent<CalendarEventInfo>[]>(`api/ficha-general/eventos-calendario/${idInspector}`).subscribe(eventos => {
        if(this.mainService.isSuccess(eventos)){
          resolve(eventos.result);
        } else {
          reject(eventos.error);
        }
      })
    })
  }
}
