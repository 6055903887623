import { Injectable } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Comentario, Foro, RespuestaComentario } from '../inspecciones.interface';

@Injectable()
export class ForoService {

  constructor(
    private mainService: MainService
  ) { }

  /**
   * Obtiene todos los foros de la base de datos
   * @returns {Promise<Foro[]>} La lista de foros
   */
  getAllForums(): Promise<Foro[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Foro[]>('api/foro-inspecciones').subscribe(response => {
        if(this.mainService.isSuccess<Foro[]>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene un foro mediante su id
   * @param forumId El id del foro
   * @returns {Promise<Foro>} El foro
   */
  getForumById(forumId: string): Promise<Foro> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Foro>(`api/foro-inspecciones/${forumId}`).subscribe(response => {
        if(this.mainService.isSuccess<Foro>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Añade un comentario a un foro
   * @param forumId El id del foro
   * @param comentario El objeto con la información a agregar del comentario
   * @returns {Promise<Foro>} El foro actualizado
   */
  postComment(forumId: string, comentario: Comentario): Promise<Foro> {
    return new Promise((resolve, reject) => {
      this.mainService.put<Foro>(`api/foro-inspecciones/nuevo-comentario/${forumId}`, {comentario}).subscribe(response => {
        if(this.mainService.isSuccess<Foro>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Añade una respuesta a un comentario del foro
   * @param {string} forumId El id del foro
   * @param {string} comentarioId El id del comentario
   * @param {RespuestaComentario} respuesta El objeto con la información a agregar de la respuesta
   * @returns {Promise<Foro>} El foro actualizado
   */
  postAnswer(forumId: string, comentarioId: string, respuesta: RespuestaComentario): Promise<Foro> {
    return new Promise((resolve, reject) => {
      this.mainService.put<Foro>(`api/foro-inspecciones/responder-comentario/${forumId}/comentario/${comentarioId}`, {respuesta}).subscribe(response => {
        if(this.mainService.isSuccess<Foro>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

}
