<main class="module-principal">
  <div class="container">
    <img
      src="./assets/img/v2/logo-ensamble-con-texto.png"
      alt="Logo ensamble"
      style="
        width: 50%;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      "
    />
    <h1 class="login-title">Olvidé mi contraseña</h1>
    <form class="form-group" (ngSubmit)="onChangePassword(changeForm)" #changeForm="ngForm">
      <label for="newPassword" class="label mb-10">Nueva contraseña</label>
      <input
        type="password"
        name="newPassword"
        id="newPassword"
        ngModel
        #newPassword="ngModel"
        [(ngModel)]="data.newPassword"
        class="form-control custom-input mb-10"
        aria-describedby="basic-addon1"
        placeholder="Escribe tu nueva contraseña"
        required
        >
      <div class="invalid-input mb-10" *ngIf="newPassword.dirty || newPassword.touched">
        <div *ngIf="newPassword.errors?.required">El campo es requerido</div>
      </div>
        <label for="newConfirmedPassword" class="label mb-10">Repetir contraseña</label>
      <input
        type="password"
        name="newConfirmedPassword"
        id="newConfirmedPassword"
        ngModel
        #newConfirmedPassword="ngModel"
        [(ngModel)]="data.newConfirmedPassword"
        class="form-control custom-input mb-10"
        aria-describedby="basic-addon1"
        placeholder="Repite tu nueva contraseña"
        required
        >
      <div class="invalid-input mb-20" *ngIf="newConfirmedPassword.dirty || newConfirmedPassword.touched">
        <div *ngIf="newConfirmedPassword.errors?.required">El campo es requerido</div>
        <div *ngIf="newConfirmedPassword.value !== newPassword.value">Las contraseñas deben ser iguales</div>
      </div>
      <button type="submit" class="button button-login" nbSubmit>Cambiar contraseña</button>
    </form>
  </div>
</main>
