import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RecursosAdicionalesTableData } from '../inspecciones.interface';

@Component({
  selector: 'app-recursos-adicionales-inspecciones',
  templateUrl: './recursos-adicionales-inspecciones.component.html',
  styleUrls: ['./recursos-adicionales-inspecciones.component.css'],
})
export class RecursosAdicionalesInspeccionesComponent implements OnInit {
  settings: any = {};
  constructor() {
    this.dataSource = new MatTableDataSource([]);
  }

  displayedColumns: string[] = ['tipoRecurso', 'descripcionGeneral', 'enlaces'];

  dataSource: MatTableDataSource<RecursosAdicionalesTableData, any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  getData() {
    const data = [
      {
        id: 1,
        tipoRecurso: 'Página web',
        descripcionGeneral: 'Fundación Ideas para la Paz FIP. Documentos del área de Seguridad y Política Criminal',
        enlaces: [
          'https://www.ideaspaz.org/',
          'https://www.ideaspaz.org/foundation/work-areas/6',
          'https://www.ideaspaz.org/publications/posts/1730',
        ],
      },
      {
        id: 2,
        tipoRecurso: 'Video Youtube',
        descripcionGeneral: 'Conferencia de Anibal Torres Rico sobre el comparendo de policía',
        enlaces: ['https://www.youtube.com/watch?v=kGVot27vpVA&feature=youtu.be'],
      },
      {
        id: 3,
        tipoRecurso: 'Página web',
        descripcionGeneral: 'Sentencias de la Corte Constitucional relacionadas con el CNSCC',
        enlaces: ['https://www.corteconstitucional.gov.co/relatoria/Codigo%20de%20Policia.php'],
      },
      {
        id: 4,
        tipoRecurso: 'Página web',
        descripcionGeneral:
          'Programa Conexión Justicia.  Recurso del Ministerio de Justicia para Inspectores de Policía',
        enlaces: ['https://www.minjusticia.gov.co/programas-co/conexion-justicia'],
      },
      {
        id: 5,
        tipoRecurso: 'Video Youtube',
        descripcionGeneral: 'Comentarios a la reforma que la Ley 2197-22 introdujo al CNSCC',
        enlaces: ['https://youtu.be/w7D19VISiag'],
      },
      {
        id: 6,
        tipoRecurso: 'Video Youtube',
        descripcionGeneral: 'Análisis de la reforma al PVA con la Ley 2197-22.',
        enlaces: ['https://youtu.be/w7D19VISiag', 'https://www.youtube.com/watch?v=qWaSrtIKoeY'],
      },
    ];
    const dataFixed = data.map((da) => {
      return {
        tipoRecurso: da.tipoRecurso,
        descripcionGeneral: da.descripcionGeneral,
        enlaces: da.enlaces,
      };
    });
    this.dataSource = new MatTableDataSource(dataFixed);
  }

  /**
   * Aplica un filtro en la tabla que hace una búsqueda en todos los casos
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToPage(links: string[]){
    if(links.length > 0){
      links.forEach(link => window.open(link, '_blank'));
    }
  }
}
