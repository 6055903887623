import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
@Injectable()
export class AuthService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public authToken: any;
  public user: any;
  public baseURL: String = "https://develop-fip.imagineapps.co/"
  // public baseURL: String = "http://localhost:8080/";

  constructor(
    private jwtHelper: JwtHelperService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public http: HttpClient
  ) {
    this.user = this.storage.get('user') || {};
  }

  public register(user) {
    return this.http.post<any>(this.baseURL + "users/register", JSON.stringify(user), this.httpOptions);
  }

  public authenticateUser(user) {
      return this.http.post<any>(this.baseURL+"users/authenticate", JSON.stringify(user), this.httpOptions);
  }

  public storeUserData(token, user) {
    localStorage.setItem('id_token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public storeUserDataInspections(token, user) {
    localStorage.setItem('inspections_token', token);
    localStorage.setItem('inspector', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  public loadToken() {
    const token = localStorage.getItem('id_token');
    this.authToken = token;
  }

  public logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  public getToken() {
    this.loadToken();
    return this.authToken;
  }

  public loggedIn() {
    const token = localStorage.getItem('id_token');
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  public loggedInInspections(){
    const token = localStorage.getItem('inspections_token');
    return token && !this.jwtHelper.isTokenExpired(token);
  }

  public getUser() {
    return this.user;
  }

}
