import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../../../services/main.service';
import { UtilsService } from '../../../../services/utils.service';
import Swal from 'sweetalert2';import { MecanismoPrevio, Seguimiento, SeguimientoMecanismoPrevio } from '../../../inspecciones.interface';
;
@Component({
  selector: 'app-fase-seguimiento',
  templateUrl: './fase-seguimiento.component.html',
  styleUrls: ['./fase-seguimiento.component.css']
})
export class FaseSeguimientoComponent implements OnInit {
  todayDate = new Date().toISOString().split("T")[0]

  caseId: string = '';
  mecPrevId: string = '';

  wantEditFollowUp: boolean = false;
  isNewFollowUp: boolean = false;
  followUpIndex: number;

  seguimiento: SeguimientoMecanismoPrevio = {
    fechaSeguimiento: undefined,
    descripcionSeguimiento: '',
    horaSeguimiento: '',
  };

  seguimientos: SeguimientoMecanismoPrevio[] = [];
  compromisosAsignados = [];

  observaciones: string = '';
  comentariosRecomendaciones: string = '';

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getPrevMec();
  }

  startFollowUp(){
    this.isNewFollowUp = true;
  }

  /**
   * Formatea la fecha para los botones
   * @param date La fecha a formatear
   * @returns La fecha en el formato adecuado
   */
  formatDate(date: Date): string{
    return this.utilsService.formatDate(date);
  }

  getPrevMec(){
    this.mainService.get<MecanismoPrevio>(`api/mec-previo-caso/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        const bd = response.result;
        this.mecPrevId = bd._id
        this.compromisosAsignados = bd.compromisos;
        this.seguimientos = bd.faseSeguimiento.seguimientos;
        this.observaciones = bd.faseSeguimiento.observaciones;
        this.comentariosRecomendaciones = bd.faseSeguimiento.observaciones;
      }
    })
  }

  changeActivityStatus(compromiso, status, index){
    // PARA QUE SE ASIGNE UN STATUS SOLO SI LE MARCAN Y NO VIENE CON INFORMACION O SI VIENE CON INFORMACIÓN OPUESTA A LO QUE SE QUIERE MARCAR
    if (
      (status && compromiso.cumplido === undefined) ||
      (!status && compromiso.cumplido === undefined) ||
      (status && !compromiso.cumplido) ||
      (!status && compromiso.cumplido)
    ) {
      compromiso.cumplido = status;
      this.compromisosAsignados[index].cumplido = status;
    } else {
      compromiso.cumplido = undefined;
      this.compromisosAsignados[index].cumplido = undefined;
    }
  }

  validationFollowUp(): boolean {
    return (
      this.seguimiento.fechaSeguimiento != '' && this.seguimiento.fechaSeguimiento != undefined &&
      this.seguimiento.descripcionSeguimiento != '' && this.seguimiento.descripcionSeguimiento != undefined &&
      this.seguimiento.horaSeguimiento != '' && this.seguimiento.horaSeguimiento != undefined
    )
  }

  addFollowUp(){
    const validation = this.validationFollowUp();
    if(validation){
      this.seguimientos.push(this.seguimiento)
      this.cleanFollowUpInputs()
    } else {
      Swal.fire(
        'Error',
        'No se han completado todos los campos',
        'warning'
      )
    }
  }

  cancelFollowUp(){
    this.cleanFollowUpInputs();
  }

  editFollowUp(followup){
    this.wantEditFollowUp = true;
    this.followUpIndex =  this.seguimientos.indexOf(followup)
    this.seguimiento = {
      fechaSeguimiento: followup.fechaSeguimiento ? this.utilsService.formatInputDate(followup.fechaSeguimiento) : null,
      descripcionSeguimiento: followup.descripcionSeguimiento,
      horaSeguimiento: followup.horaSeguimiento,
    };
  }

  saveFollowUp(){
    const validation = this.validationFollowUp();
    if(validation){
      this.seguimientos[this.followUpIndex] = this.seguimiento;
      this.seguimientos[this.followUpIndex].fechaSeguimiento = (<string>this.seguimiento.fechaSeguimiento).split("T")[0];
      this.wantEditFollowUp = false;
      this.cleanFollowUpInputs()
    } else {
      Swal.fire(
        'Error',
        'No se han completado todos los campos',
        'warning'
      )
    }
  }

  cleanFollowUpInputs(){
    this.isNewFollowUp = false;
    this.wantEditFollowUp = false;
    this.seguimiento = {
      fechaSeguimiento: '',
      descripcionSeguimiento: '',
      horaSeguimiento: '',
    };
  }

  updateFollowUp(){
    const updateObj = {
      faseSeguimiento: {
        seguimientos: this.seguimientos,
        observaciones: this.observaciones,
      },
      compromisos: this.compromisosAsignados
    }
    this.mainService.put<MecanismoPrevio>(`api/mec-previo-caso/${this.mecPrevId}`, updateObj).subscribe(response => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        Swal.fire(
          'Éxito',
          'Fase de seguimiento actualizada con éxito',
          'success'
        )
      } else {
        Swal.fire(
          'Error',
          'No se pudo actualizar la fase de seguimiento',
          'warning'
        )
      }
    })
  }

  generateFinalReport(){
    this.router.navigate(['gestion-casos-inspecciones/ver-caso/mecanismo-previo/informe-final/' + this.caseId]);
  }
}
