import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../../../services/main.service';
import { MecanismoIntermedioService } from '../mecanismo-intermedio.service';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import * as QRCode from 'qrcode';
import { UtilsService } from '../../../../services/utils.service';
import { Archivo, Audiencia, Caso, CCC, Citado, Correo, FichaGeneral, Inspector, MecanismoIntermedio } from '../../../inspecciones.interface';
@Component({
  selector: 'app-citacion',
  templateUrl: './citacion.component.html',
  styleUrls: ['./citacion.component.css']
})
export class CitacionComponent implements OnInit, OnChanges {
  todayDate = new Date().toISOString().split('T')[0];
  caseId: string = '';
  pasos = [1,2,3];
  step: number = 1;
  finalY: number = 15;

  cccs: CCC[] = [];
  readonly config = {
    noResultsFound: "No se encontraron resultados",
    displayFn: (item: any) => {
      return item.nombreCompleto;
    },
    search:true,
    placeholder:'Seleccione'
  };

  posiblesCitados: Citado[] = [];
  citado: Citado = {
    nombreCompleto: '',
    tipoIdentificacion: '',
    identificacion: '',
    correo: '',
    telefono: undefined,
    direccion: '',
    tipo: 'infractor'
  };
  quotedIndex: number;

  wantEditQuoted: boolean = false;

  audiencia: Audiencia = {
    citados: [],
    fechaAudiencia: undefined,
    horaAudiencia: '',
    direccionAudiencia: '',
    firmaInspectorPolicia: '',
    nombreFuncionario: '',
    firmaFuncionario: '',
    suspendida: undefined,
    asisteAgente: undefined,
    nombreAgente: '',
    fechaConstanciaAsistencia: undefined,
    horaConstanciaAsistencia: '',
    lugarConstanciaAsistencia: ''
  }

  correo: Correo = {
    registroCorreo: undefined,
    fechaEnvioCorreo: undefined,
    horaEnvioCorreo: '',
    notaRecibidoCorreo: '',
    nombreFuncionarioResponsable: '',
    firmaFuncionarioResponsable: ''
  }

  newHearing: boolean;
  oldHearing: boolean;
  hearingIndex: number;

  fichaGeneral: FichaGeneral = {
    caso: undefined,
    createdAt: '',
    documentoOrigen: '',
    fechaDocumentoOrigen: '',
    archivosDocumentoOrigen: [],
    otrosDocumentos: [],
    radicado: '',
    departamento: '',
    municipio: undefined,
    modeloMunicipio: '',
    peticionarios: [],
    infractores: [],
    resumenHechos: '',
    fechaHechos: '',
    horaHechos: '',
    nombreLugarHechos: '',
    tipoLocalidadHechos: '',
    lugarHechos: '',
    detalleLugarHechos: '',
    esQuerellaCivil: undefined
  };
  caso: Caso = {
    inspector: undefined,
    numCaso: '',
    numInspeccion: ''
  };
  inspeccion: any = {};
  inspector: Inspector = {
    nombre: '',
    apellido: '',
    email: '',
    password: '',
    telefonoInspector: '',
    telefonoOficina: '',
    aceptado: false,
    tipoDocumento: '',
    numDocumento: 0,
    codigoInspector: '',
    municipio: undefined
  };
  horaConstanciaAsistencia: string;
  showContinueOption: boolean = false;

  @Input() selectedHearing: any;
  @Input() audienciasDisponibles: Audiencia[] = [];
  @Input() mecIntId: string;
  @Input() correosEnviados: any[] = [];
  @Input() isSelected: boolean;

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mainService: MainService,
    private mecIntService: MecanismoIntermedioService,
    private route: ActivatedRoute,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getGeneralFileInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      for(let citado of this.audiencia.citados){
        if(citado.asiste === false){
          this.showContinueOption = true;
        }
      }
      this.oldHearing = true;
    }
  }

  /**
   * Formatea la fecha para los botones
   * @param date La fecha a formatear
   * @returns La fecha en el formato adecuado
   */
  formatDate(date: Date | string, separator?: string): string{
    return this.utilsService.formatDate(date, separator);
  }

  /**
   * Obtiene la información relacionada a la ficha general
   */
  getGeneralFileInfo(): void {
    this.mecIntService.getQuoted(this.caseId).then(quoted => {
      this.posiblesCitados = quoted;
    })
    this.mecIntService.getGeneralFileInfo(this.caseId).then(response => {
    
      this.cccs = response.CCC;
      this.fichaGeneral.nombreLugarHechos = response.nombreLugarHechos;
      this.fichaGeneral.municipio = response.municipio;
      this.caso = response.caso;
      this.inspector = response.caso.inspector;
    })
  }

  /**
   * Solicita la edición de una citación
   * @param {Audiencia} hearing La citación a editar
   */
  editHearing(hearing: Audiencia): void {
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaConstanciaAsistencia ? (<string>hearing.fechaAudiencia).split("T")[0] : null;
    this.audiencia.fechaConstanciaAsistencia = hearing.fechaConstanciaAsistencia ? (<string>hearing.fechaConstanciaAsistencia).split("T")[0] : null;
    this.audiencia.autoFecha = hearing.autoFecha ? (<string>hearing.autoFecha).split("T")[0] : null;
    this.audiencia.fechaReanudacion = hearing.fechaReanudacion ?  (<string>hearing.fechaReanudacion).split("T")[0] : null;
  }

  /**
   * Solicita la eliminación de una citación
   */
  deleteHearing(index: number): void {
    if(this.isSelected || this.newHearing || this.oldHearing){
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar una audiencia en estado de edición',
        icon: 'error'
      });
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar esta citación?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.audienciasDisponibles[index].activo = false;
        Swal.fire({
          title: 'Éxito',
          text: 'La citación se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Cancela la edición de una citación
   */
  cancelHearing(){
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    this.step = 1;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  /**
   * Solicita una nueva citación
   */
  startHearing(){
    this.newHearing = true;
    this.oldHearing = false;
    this.resetForm1();
  }

  resetForm1(){
    this.audiencia= {
      citados: [],
      fechaAudiencia: undefined,
      horaAudiencia: '',
      direccionAudiencia: '',
      firmaInspectorPolicia: '',
      nombreFuncionario: '',
      firmaFuncionario: '',
      suspendida: undefined,
      asisteAgente: undefined,
      nombreAgente: ''
    }
  }

  /**
   * Agrega un nuevo citado
   */
  onAggregateQuoted(){
    if(
      this.citado.nombreCompleto !== undefined && this.citado.nombreCompleto !== ''
    ){
      if(this.audiencia.citados.find(citado => citado.nombreCompleto === this.citado.nombreCompleto) == undefined){
        this.audiencia.citados = [...this.audiencia.citados, this.citado];
        this.resetcitadoInfo();
      } else {
        Swal.fire({
          title: 'Error',
          text: 'El citado ya se encuentra en la lista',
          icon: 'error'
        });
      }
    } else {
      Swal.fire(
        'Error',
        'El citado debe contar con nombre y correo electrónico',
        'warning'
      )
    }
  }

  /**
   * Resetea la información del citado
   */
  resetcitadoInfo(): void {
    this.citado = {
      nombreCompleto: '',
      tipoIdentificacion: '',
      identificacion: '',
      correo: '',
      telefono: undefined,
      direccion: '',
      tipo: 'infractor'
    };
    this.wantEditQuoted = false;
  }

  /**
   * Cancela la edición del citado
   */
  cancelQuoted(): void {
    this.wantEditQuoted = false;
    this.resetcitadoInfo();
  }

  /**
   * Solicita la edición del citado
   * @param quoted El citado
   */
  editQuoted(quoted: Citado): void {
    this.citado = quoted;
    this.wantEditQuoted = true;
    this.quotedIndex = this.audiencia.citados.indexOf(quoted);
  }

  /**
   * Modifica el citado
   */
  onModifyQuoted(): void {
    if(
      this.citado.nombreCompleto !== undefined && this.citado.nombreCompleto !== '' &&
      this.citado.correo !== undefined && this.citado.correo !== ''
    ){
      if(this.quotedIndex !== -1){
        this.audiencia.citados[this.quotedIndex] = this.citado;
      }
      this.resetcitadoInfo();
    } else {
      Swal.fire(
        'Error',
        'El citado debe contar con nombre y correo electrónico',
        'warning'
      )
    }
  }

  /**
   * Elimina el citado
   * @param index El indice del citado
   */
  deleteQuoted(index: number): void {
    if(this.wantEditQuoted){
      Swal.fire(
        'Érror',
        'No se puede eliminar en estado de edición',
        'error'
      );
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este citado?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.audiencia.citados.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El citado se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Guarda una imagen en S3 y le asigna la url del archivo almacenado a donde se requiera.
   * @param event El evento del input donde se guardan los archivos
   * @param tipoFirma El campo en donde se va a guardar.
   */
  onFileChange(event, tipoFirma: string){
    const file: File = event.target.files[0];
    this.mecIntService.saveInS3(file).then(archivoSubido => {
      this.mecIntService.showS3UploadSuccess();
      if(tipoFirma === 'firma-inspector'){
        this.audiencia.firmaInspectorPolicia = archivoSubido;
      } else if (tipoFirma === 'firma-funcionario-responsable') {
        this.correo.firmaFuncionarioResponsable = archivoSubido
      } else if (tipoFirma === 'registro-correo') {
        this.correo.registroCorreo = {
          tipo: file.type,
          url: archivoSubido
        };
      } else {
        this.audiencia.firmaFuncionario = archivoSubido;
      }
    }).catch(err => {
      this.mecIntService.showS3UploadError();
      console.error(err);
    })
  }

  /**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo 
   * @returns la url
   */
  getImageFromTypeFile(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  /**
   * Elimina la url del registro del correo
   */
  deleteRegister(){
    this.correo.registroCorreo = undefined;
  }

  /**
   * Elimina una firma
   * @param signature
   */
  deleteSignature(signature: 'inspector' | 'funcionario' | 'funcionario-responsable'){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de borrar esta firma?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        if(signature === 'inspector'){
          this.audiencia.firmaInspectorPolicia = undefined;
        } else if (signature === 'funcionario'){
          this.audiencia.firmaFuncionario = undefined;
        } else if (signature === 'funcionario-responsable'){
          this.audiencia.correos[0].firmaFuncionarioResponsable = undefined;
        }
        Swal.fire({
          title: 'Éxito',
          text: 'La firma se ha eliminado',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  sendEmail(){
    return
  }

  /**
   * Cambia la pantalla que se muestra en la citación
   * @param number
   */
  changeStep(number: number){
    this.step = number
  }

  /**
   * Cambia el estado de asistencia entre sí, no y no definido
   * @param citado El citado al que se le va a asignar el estado
   * @param status El estado
   * @param index El indice del citado en la lista de citados
   */
  changeAssistanceStatus(citado, status, index): void {
    if (
      (status && citado.asiste === undefined) ||
      (!status && citado.asiste === undefined) ||
      (status && !citado.asiste) ||
      (!status && citado.asiste)
    ) {
      citado.asiste = status;
      this.audiencia.citados[index].asiste = status;
      if(this.audiencia.citados.filter(citado => citado.asiste || citado.asiste === undefined).length === this.audiencia.citados.length){
        this.showContinueOption = false;
        this.audiencia.suspendida = false;
      } else {
        this.showContinueOption = true;
      }
    } else {
      citado.asiste = undefined;
      this.audiencia.citados[index].asiste = undefined;
    }
  }

  /**
   * Cambia el estado de la continuación de la audiencia
   * @param status El estado
   */
  changeContinuationStatus(status): void {
    if (
      (status && this.audiencia.suspendida === undefined) ||
      (!status && this.audiencia.suspendida === undefined) ||
      (status && !this.audiencia.suspendida) ||
      (!status && this.audiencia.suspendida)
    ) {
      this.audiencia.suspendida = status;
    } else {
      this.audiencia.suspendida = undefined;
    }
  }

  /**
   * Cambia el estado de la asistencia del agente
   * @param status El estado
   */
  changeAgentAssistance(status): void {
    if (
      (status && this.audiencia.asisteAgente === undefined) ||
      (!status && this.audiencia.asisteAgente === undefined) ||
      (status && !this.audiencia.asisteAgente) ||
      (!status && this.audiencia.asisteAgente)
    ) {
      this.audiencia.asisteAgente = status;
      if(status === false){
        this.audiencia.nombreAgente = '';
      }
    } else {
      this.audiencia.asisteAgente = undefined;
    }
  }

  async downloadPDF(){
    let doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    doc = this.setTitleStyle(doc);
    doc.text('Fecha de constancia', 15, this.finalY);
    doc.text('Hora', 105, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    doc.text(this.audiencia.fechaConstanciaAsistencia.toString() || 'No definido', 15, this.finalY, { maxWidth: 90 });
    doc.text(this.audiencia.horaConstanciaAsistencia || 'No definido', 105, this.finalY, { maxWidth: 90 });
    this.finalY += 15;

    doc = this.setTitleStyle(doc);
    doc.text('Lugar', 15, this.finalY, { maxWidth: 180 });
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    doc.text(this.audiencia.lugarConstanciaAsistencia || 'No definido', 15, this.finalY, { maxWidth: 90 });
    this.finalY += 15;
    
    doc = this.setTitleStyle(doc);
    doc.text('Desarrollo de la audiencia', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 10;
    doc = this.setNormalStyle(doc);
    const bigText = `En el municipio de ${this.fichaGeneral.municipio || 'NO DEFINIDO'} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha ${this.audiencia.autoFecha || 'NO DEFINIDO'} y previas las comunicaciones de rigor, el Inspector, ${this.inspector.codigoInspector || 'NO DEFINIDO'} da inicio a la Audiencia Pública.`
    doc.text(bigText, 15, this.finalY, { maxWidth: 180 });
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(bigText);
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });

    if(this.audiencia.citados && this.audiencia.citados.length > 0){
      doc = this.getQuotedAssistance(doc);
    }
    doc = this.setTitleStyle(doc);
    doc.text('Municipio', 15, this.finalY);
    doc.text('Inspección No.', 105, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 10;

    doc.text(this.fichaGeneral.municipio || 'No definido', 15, this.finalY);
    doc.text(this.caso.numInspeccion || 'No definido', 105, this.finalY);
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 15;

    doc = await this.getSignatures(doc);
    doc.save(`Citación fecha ${this.formatDate(this.audiencia.fechaAudiencia, '_')} caso ${this.caso.numCaso}.pdf`);
  }

  getQuotedAssistance(doc: jsPDF){
    doc = this.setTitleStyle(doc);
    doc.text('Asiste el (los) citados', 15, this.finalY);
    doc = this.setNormalStyle(doc);
    this.finalY += 10;
    for(let citado of this.audiencia.citados){
      this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
      doc.text(`${citado.nombreCompleto}: ${citado.asiste ? 'Sí':'No'}`, 15, this.finalY);
      this.finalY += 5;
    }
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 10;
    return doc;
  }

  async getSignatures(doc){
    doc = this.setTitleStyle(doc);
    doc.text('Nombre Inspector de Policía', 15, this.finalY, { maxWidth: 90 });
    doc.text('Firma Inspector de Policía', 105, this.finalY, { maxWidth: 90 });
    doc = this.setNormalStyle(doc);
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 10;
    doc.text(`${(this.inspector.nombre ? this.inspector.nombre + ' ' : '') + (this.inspector.apellido ? this.inspector.apellido : '') || 'No asignado'}`, 15, this.finalY);
    if(this.audiencia.firmaInspectorPolicia){
      const url = await QRCode.toDataURL(this.audiencia.firmaInspectorPolicia);
      doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      doc.text('No asignado', 105, this.finalY);
    }
    this.finalY += 15;

    doc = this.setTitleStyle(doc);
    doc.text('Nombre funcionario', 15, this.finalY, { maxWidth: 90 });
    doc.text('Firma funcionario', 105, this.finalY, { maxWidth: 90 });
    doc = this.setNormalStyle(doc);
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 10;
    doc.text(`${this.audiencia.nombreFuncionario || 'No asignado'}`, 15, this.finalY);
    if(this.audiencia.firmaFuncionario){
      const url = await QRCode.toDataURL(this.audiencia.firmaFuncionario);
      doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      doc.text('No asignado', 105, this.finalY);
    }
    this.finalY = this.verifyEndOfPage({ doc, value: this.finalY });
    this.finalY += 15;

    return doc;
  }

  setTitleStyle(doc){
    doc.setFont('bold');
    doc.setFontSize(14);
    doc.setTextColor('#332F2E');
    return doc;
  }

  setNormalStyle(doc){
    doc.setFont('normal');
    doc.setFontSize(13);
    doc.setTextColor('#332F2E');
    return doc;
  }

  /**
   * Gets the height to add from certain numbers of characters, because with more characters, more lines, this works with half-width texts
   * @param {any} object The object that is being reviewed
   * @param {{ nombre: string, nombreArreglado: string }[]} row The current row (of the pdf)
   * @returns {number} the number that must increase the height from the top
   */
  getMoreHeightFromTextHeight(object, row: { nombre: string, nombreArreglado: string }[]): number {
    const biggestChainLength = this.findLongestChainLength(row.map(r => object[r.nombre]));
    if(biggestChainLength > 140){
      return 18;
    } else if (biggestChainLength <= 140 && biggestChainLength > 100){
      return 15;
    } else if (biggestChainLength <= 100){
      return 12;
    }
    return 12;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text 
   * @returns {number} the number that must increase the height from the top 
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    if(string.length > 320){
      return 22;
    } else if (string.length > 240){
      return 18;
    } else if (string.length > 160){
      return 15;
    } else if (string.length <= 160){
      return 12;
    }
    return 12;
  }

  /**
   * Get the longest string from an array of strings;
   * @param {string[]} strings El array 
   * @returns {number} the string length
   */
  findLongestChainLength(strings: string[]): number {
    const longest = strings.reduce((longestString, currentString) => {
      return currentString && currentString.length > longestString.length ? currentString : longestString;
    });
    return longest ? longest.length : 0;
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  /**
   * Guarda la información en la base de datos
   */
  saveInfo(): void {
    if(this.newHearing){
      this.audienciasDisponibles.push(this.audiencia);
    } else {
      this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
    }
    this.saveToDB();
  }

  saveToDB(){
    const data = {
      audiencias: this.audienciasDisponibles
    }
    this.mainService.put<MecanismoIntermedio>(`api/mec-intermedio-caso/${this.mecIntId}`, data).subscribe(response => {
      if(this.mainService.isSuccess<MecanismoIntermedio>(response)){
        if(this.newHearing){
          Swal.fire(
            'Éxito',
            'Audiencia agregada al mecanismo intermedio',
            'success'
          );
        } else {
          Swal.fire(
            'Éxito',
            'Audiencia actualizada',
            'success'
          );
        }
        this.cancelHearing();
      } else {
        Swal.fire(
          'Error',
          'No se pudo agregar la audiencia al mecanismo intermedio',
          'error'
        );
      }
    })
  }

}
