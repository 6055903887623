import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { MainService } from '../../../../services/main.service';
import { UtilsService } from '../../../../services/utils.service';
import { Archivo, Compromiso, FichaGeneral, MecanismoPrevio } from '../../../inspecciones.interface';
import { MecanismoPrevioService } from '../mecanismo-previo.service';

@Component({
  selector: 'app-fase-intervencion',
  templateUrl: './fase-intervencion.component.html',
  styleUrls: ['./fase-intervencion.component.css']
})
export class FaseIntervencionComponent implements OnInit {
  caseId: string = '';
  mecPrevId: string = '';

  config = {
    displayKey:'nombre',
    search:true,
    noResultsFound: "No se encontraron resultados",
    placeholder:'Seleccione'
  };
  tiposActividades = [
    'Visita de verificación',
    'Brigada',
    'Taller',
    'Reunión / grupo focal',
    'Entrevista',
    'Conciliación / mediación'
  ];
  modalidadesActividad = [
    'Presencial',
    'Virtual',
    'Mixta'
  ];

  actividadesDisponibles = [];
  actividadSeleccionada: boolean = false;
  indexActividadSeleccionada: any = '';
  wantEditActivity: boolean = false

  intervencion: any = {
    lugarActividad: '',
    fechaActividad: '',
    horaActividad: '',
    agendaActividad: '',
    resumenActividad: '',
    registroFotografico: [],
    observaciones: ''
  }
  // SUMMONED
  convocados = [];
  convocado = '';
  wantEditSummoned: boolean = false;
  wantDeleteSummoned: boolean = false;
  savedSummonedIndex: any;

  // ASSISTANTS
  availableAssistants = [];
  assistants = [];
  asistentes = [];
  savedAssistantIndex;
  asistente = {
    nombreCompleto: '',
    identificacion: ''
  }
  wantEditAssistant: boolean = false;
  wantDeleteAssistant: boolean = false;

  // COMPROMISES
  compromisos: Compromiso[] = [];
  compromiso: Compromiso = {
    descripcion: '',
    cumplido: undefined,
  };
  savedCompromiseIndex: number;
  wantEditCompromise: boolean = false;
  wantDeleteCompromise: boolean = false;

  esOtraActividad: boolean = false;
  tipoActividad: string = '';
  otroTipoActividad: string = '';
  modalidadActividad: string = '';
  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private mecPrevService: MecanismoPrevioService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getAssistants();
    this.getPrevMec();
  }

  /**
   * Formatea la fecha para los botones
   * @param date La fecha a formatear
   * @returns La fecha en el formato adecuado
   */
  formatDate(date: Date): string{
    return this.utilsService.formatDate(date);
  }

  cancelActivity(){
    this.actividadSeleccionada = false;
    this.wantEditActivity = false;
    this.resetActivityFields();
  }

  getAssistants(){
    this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<FichaGeneral>(response)){
        this.assistants = response.result.infractores;
        for(let infractor of response.result.infractores){
          let nombreMostrar
          (infractor.nombreInfractor && infractor.apellidoInfractor) ? nombreMostrar = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor : nombreMostrar = infractor.nombreInfractor || infractor.apellidoInfractor;
          this.availableAssistants = [...this.availableAssistants, nombreMostrar];
        }
      }
    })
  }

  /**
   * Obtiene la información del mecanismo previo
   */
  getPrevMec(){
    this.mainService.get<MecanismoPrevio>(`api/mec-previo-caso/caso/${this.caseId}`).subscribe(response => {
      if(this.mainService.isSuccess<MecanismoPrevio>(response)){
        const resp = response.result;

        this.mecPrevId = resp._id;
        const bd = response.result.faseIntervencion;
        this.compromisos = response.result.compromisos;

        this.actividadesDisponibles = bd.actividades;
      } else {
        console.error(response.error)
      }
    })
  }

  /**
   * Edita la actividad de la lista
   * @param index El indice
   */
  editActivity(index: number){
    this.wantEditActivity = true;
    this.actividadSeleccionada = true;
    const actividadEncontrada = this.actividadesDisponibles[index];
    this.intervencion = {
      lugarActividad: actividadEncontrada.lugarActividad,
      fechaActividad: this.utilsService.formatInputDate(actividadEncontrada.fechaActividad),
      horaActividad: actividadEncontrada.horaActividad,
      agendaActividad: actividadEncontrada.agendaActividad,
      resumenActividad: actividadEncontrada.resumenActividad,
      observaciones: actividadEncontrada.observaciones,
      registroFotografico: actividadEncontrada.registroFotografico
    }
    this.tipoActividad = actividadEncontrada.tipoActividad;
    this.modalidadActividad = actividadEncontrada.modalidadActividad;
    this.esOtraActividad = actividadEncontrada.esOtraActividad;
    this.otroTipoActividad = actividadEncontrada.otroTipoActividad;
    this.convocados = actividadEncontrada.convocados;
    this.asistentes = actividadEncontrada.asistentes;
  }

  startActivity(){
    this.wantEditActivity = false;
    this.resetActivityFields();
    this.actividadSeleccionada = true;
  }

  resetActivityFields(){
    this.actividadSeleccionada = false;
    this.wantEditActivity = false;
    this.indexActividadSeleccionada = undefined;
    this.intervencion = {
      lugarActividad: '',
      fechaActividad: '',
      horaActividad: '',
      agendaActividad: '',
      resumenActividad: '',
      registroFotografico: [],
      observaciones: ''
    }
    this.tipoActividad = undefined;
    this.modalidadActividad = undefined;
    this.esOtraActividad = undefined;
    this.otroTipoActividad = undefined;
    this.convocados = [];
    this.asistentes = [];
  }

  // FOR SUMMONED
  addSummoned(){
    if(this.convocado != undefined && this.convocado != ''){
      this.convocados.push(this.convocado);
      this.convocado = '';
      Swal.fire({
        title: 'Éxito',
        text: 'Convocado agregado con éxito',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se ha escrito ningún convocado',
        icon: 'error'
      });
    }
  }

  saveSummoned(){
    if(this.convocado != undefined && this.convocado != ''){
      this.convocados[this.savedSummonedIndex] = this.convocado;
      this.convocado = '';
      this.wantEditSummoned = false;
      this.savedSummonedIndex = undefined;
      Swal.fire(
        'Éxito',
        'Convocado actualizado con éxito',
        'success'
      );
    } else {
      Swal.fire(
        'Error',
        'No se ha escrito ningún convocado',
        'warning'
      );
    }
  }

  cancelSummoned(){
    this.convocado = '';
    this.wantEditSummoned = false;
    this.savedSummonedIndex = undefined;
  }

  editSummoned(summoned){
    this.convocado = summoned;
    this.savedSummonedIndex = this.convocados.findIndex(convocado => {
      return (convocado === summoned)
    });
    this.wantEditSummoned = true;
  }

  deleteSummoned(index){
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este convocado?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.convocados.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El convocado se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  // ASSISTANTS

  findAssistantId(name){
    for(let assistant of this.assistants){
      if(name.includes(assistant.nombreInfractor) && name.includes(assistant.apellidoInfractor)){
        this.asistente.identificacion = assistant.identificacionInfractor ? assistant.identificacionInfractor : 'No asignada';
      }
    }
  }

  addAssistant(){
    if(this.asistente.nombreCompleto != undefined && this.asistente.nombreCompleto != ''){
      const asistenteSubir = {
        nombreCompleto: this.asistente.nombreCompleto,
        identificacion: this.asistente.identificacion
      }
      this.asistentes = [...this.asistentes, asistenteSubir];
      this.cleanAssistant();
      Swal.fire(
        'Éxito',
        'Asistente agregado con éxito',
        'success'
      );
    } else {
      Swal.fire(
        'Error',
        'No se ha elegido a ningún asistente',
        'warning'
      );
    }
  }

  saveAssistant(){
    if(this.asistente.nombreCompleto != undefined && this.asistente.nombreCompleto != ''){
      const asistenteSubir = {
        nombreCompleto: this.asistente.nombreCompleto,
        identificacion: this.asistente.identificacion,
      }
      this.asistentes[this.savedAssistantIndex] = asistenteSubir;
      this.wantEditAssistant = false;
      Swal.fire(
        'Éxito',
        'Asistente guardado con éxito',
        'success'
      );
    } else {
      Swal.fire(
        'Error',
        'No se ha elegido a ningún asistente',
        'warning'
      );
    }
  }

  cancelAssistant(){
    this.cleanAssistant();
    this.wantEditAssistant = false;
  }

  cleanAssistant(){
    this.asistente = {
      nombreCompleto: '',
      identificacion: ''
    }
  }

  editAssistant(assistant){
    this.asistente = assistant;
    this.wantEditAssistant = true;
    this.savedAssistantIndex = this.asistentes.findIndex(asistente => {
      return (asistente === assistant)
    });
  }

  deleteAssistant(index){
    if(this.wantEditAssistant){
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar un asistente en estado de edición',
        icon: 'error'
      });
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar este asistente?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.asistentes.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El asistente se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  onFileChange(event): void{
    const files: File[] = Object.values(event.target.files);
    const result = Promise.all(files.map((file) => this.mecPrevService.saveInS3(file)));
    result.then(res => {
      const photosLinks = res;
      this.mecPrevService.showS3UploadSuccess();
      for(let i = 0; i < photosLinks.length; i++){
        this.intervencion.registroFotografico.push({
          url: photosLinks[i],
          tipo: files[i].type
        });
      }
    }).catch(err => {
      this.mecPrevService.showS3UploadError();
      console.error(err)
    })
  }

  /**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo
   * @returns la url
   */
  getImageFromTypeFile(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  // COMPROMISES
  addCompromise(){
    if(this.compromiso.descripcion != undefined && this.compromiso.descripcion != ''){
      const compromiso = {
        descripcion: this.compromiso.descripcion,
        cumplido: this.compromiso.cumplido
      }
      this.compromisos.push(compromiso);
      this.compromiso.descripcion = ''
      Swal.fire(
        'Éxito',
        'Compromiso agregado correctamente',
        'success'
      );
    } else {
      Swal.fire(
        'Error',
        'No se puede agregar un campo vacío',
        'error'
      );
    }
  }

  saveCompromise(){
    if(this.compromiso.descripcion != undefined && this.compromiso.descripcion != ''){
      const compromiso = {
        descripcion: this.compromiso.descripcion,
        cumplido: this.compromiso.cumplido
      }
      this.compromisos[this.savedCompromiseIndex] = compromiso;
      this.compromiso.descripcion = '';
      this.wantEditCompromise = false;
      Swal.fire(
        'Éxito',
        'Compromiso actualizado correctamente',
        'success'
      )
    } else {
      Swal.fire(
        'Error',
        'No se puede guardar un campo vacío',
        'warning'
      );
    }
  }

  /**
   * Cancela la edición del compromiso actual
   */
  cancelCompromise(){
    this.wantEditCompromise = false;
    this.wantDeleteCompromise = false;
    this.compromiso.descripcion = '';
  }

  /**
   * Edita un compromiso
   * @param comp
   */
  editCompromise(comp){
    this.wantEditCompromise = true;
    this.compromiso.descripcion = comp.descripcion;
    this.savedCompromiseIndex = this.compromisos.findIndex(compromiso => {
      return (compromiso.descripcion === comp.descripcion);
    });
  }

  deleteCompromise(){
    this.wantDeleteCompromise = true;
  }

  deleteCompromiseAccept(comp){
    this.savedCompromiseIndex = this.compromisos.findIndex(compromiso => {
      return (compromiso.descripcion === comp.descripcion)
    });
    this.compromisos.splice(this.savedCompromiseIndex, 1);
    this.wantDeleteCompromise = false;
    Swal.fire(
      'Éxito',
      'Compromiso borrado exitosamente',
      'success'
    );
  }

  changeActivityType(event){
    if(event.target.checked){
      this.tipoActividad = '';
    } else {
      this.otroTipoActividad = '';
    }
  }

  addActivity(f: NgForm){
    const form = f.value;
    if(
      (Array.isArray(form.tipoActividad) && !form.otraActividad) ||
      Array.isArray(form.modalidadActividad) ||
      !form.lugarActividad ||
      !form.fechaActividad
    ){
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos requeridos',
        icon: 'error'
      });
      return;
    }
    this.actividadesDisponibles.push({
      tipoActividad: form.tipoActividad,
      esOtraActividad: form.esOtraActividad,
      otraActividad: this.otroTipoActividad,
      modalidadActividad: form.modalidadActividad,
      lugarActividad: form.lugarActividad,
      fechaActividad: form.fechaActividad,
      horaActividad: form.horaActividad,
      convocados: this.convocados,
      agendaActividad: form.agendaActividad,
      asistentes: this.asistentes,
      resumenActividad: form.resumenActividad,
      registroFotografico: this.intervencion.registroFotografico,
      observaciones: form.observaciones
    });
    this.resetActivityFields();
  }

  saveActivity(f: NgForm){
    const form = f.value;
    const actividadNueva = {
      tipoActividad: form.tipoActividad,
      esOtraActividad: form.esOtraActividad,
      otraActividad: this.otroTipoActividad,
      modalidadActividad: form.modalidadActividad,
      lugarActividad: form.lugarActividad,
      fechaActividad: form.fechaActividad,
      horaActividad: form.horaActividad,
      convocados: this.convocados,
      agendaActividad: form.agendaActividad,
      asistentes: this.asistentes,
      resumenActividad: form.resumenActividad,
      registroFotografico: this.intervencion.registroFotografico,
      observaciones: form.observaciones
    }
    this.actividadesDisponibles[this.indexActividadSeleccionada] = actividadNueva;
    this.resetActivityFields();
  }

  // UPDATE ALL
  updateIntervention(f: NgForm){
    const form = f.value;
    form.modalidadActividad = Array.isArray(form.modalidadActividad) ? '' : form.modalidadActividad;
    form.tipoActividad = Array.isArray(form.tipoActividad) ? '' : form.tipoActividad;
    const interventionObject = {
      faseIntervencion: {
        actividades: this.actividadesDisponibles
      },
      compromisos: this.compromisos
    }
    this.mainService.put<MecanismoPrevio>(`api/mec-previo-caso/${this.mecPrevId}`, interventionObject).subscribe(response => {
      console.log(response);
      if (this.mainService.isSuccess<MecanismoPrevio>(response)) {
        Swal.fire(
          'Éxito',
          'Fase de intervención actualizada con éxito',
          'success'
        )
      } else {
        Swal.fire(
          'Error',
          'Algo salió mal al actualizar la fase de intervención',
          'warning'
        );
      }
    })
  }

}
