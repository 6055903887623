<app-header-inspecciones [opcionElegida]="1"></app-header-inspecciones>
<main class="module-principal">
  <div class="home-superior">
    <img src="/assets/img/inspecciones/logo-inspecciones.png" alt="Logo inspecciones fip" class="link-imagen">
    <h1 class="home-titulo">Módulo Inspecciones de Policía</h1>
  </div>
  <p class="home-explicacion">En este módulo encontrará información y recursos útiles para el ejercicio de las funciones por parte de los Inspectores de Policía y demás funcionarios con acceso a la plataforma. Si es la primera vez que accede <a routerLink="/biblioteca-inspecciones">inicie aquí</a></p>
  <div class="home-contenido-principal">
    <aside class="home-submodulos">
      <article class="cards-submodulos">
        <div class="video-placeholder">
          <video #video class="cards-imagen card-poster" poster="/assets/img/inspecciones/como-usar-pc-imagen.jpg" controls>
            <source src="/assets/img/inspecciones/videos/plataforma.mp4" type="video/mp4">
          </video>
        </div>
        <h2 class="cards-titulo">¿Cómo usar la plataforma?</h2>
        <a href="https://fip-recursos.s3.amazonaws.com/pdfs/Manual%20de%20uso%20FIP" target="_blank"  rel="noopener" class="cards-download text-decoration-none">
          Descargar manual <img src="/assets/img/inspecciones/arrow-down.png" class="descargar-flecha" alt="Descargar">
        </a>
      </article>
      <div class="cards-container">
        <h3 class="titulo-formacion">Formación/información</h3>
        <article class="cards-submodulos" *ngFor="let submodulo of submodulos">
          <h2 class="cards-titulo">{{ submodulo.nombre }}</h2>
          <a [routerLink]="'/submodulos-inspecciones/'+submodulo.path" class="cards-info-a text-decoration-none">
            <div class="cards-info">
              <img src="/assets/img/inspecciones/conocer-mas.png" class="info-icono" alt="i de información">
              <span class="info-texto">Conoce más</span>
              <img src="/assets/img/inspecciones/flecha.png" class="info-flecha"  alt="flecha derecha">
            </div>
          </a>
        </article>
      </div>
      <button (click)="onLogout()" class="exit-button">Salir</button>
    </aside>
    <section class="home-casos-calendario-foro">
      <div class="links-utiles">
        <a href="" class="link-util-a" routerLink="/calendario-inspecciones"><div class="link-util">
          <img src="/assets/img/inspecciones/calendario.png" class="link-imagen" alt="Logo calendario">
          <span class="link-texto">Calendario</span>
        </div></a>
        <a class="link-util-a" routerLink="../foro-inspecciones"><div class="link-util">
          <img src="/assets/img/inspecciones/foro.png" class="link-imagen" alt="Logo Preguntas y Respuestas">
          <span class="link-texto">Foro:<br> preguntas y respuestas</span>
        </div></a>
      </div>
      <div class="ultimos-casos">
        <div class="header-casos">
          <h2 class="ultimos-casos-titulo">Últimos casos gestionados</h2>
          <!-- <img src="/assets/img/inspecciones/filtro.png" class="ultimos-casos-imagen" alt="ultimos casos imagen"> -->
        </div>
        <div class="caso" *ngFor="let caso of casosGestionados">
          <!-- <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQum8cYMiBKWoW6ExOeefWcYAaO78e2yhZZMw&usqp=CAU" class="caso-tipo-logo" alt="Sa"> -->
          <div [class]="'caso-tipo-logo ' + caso.tipo?.toLocaleLowerCase()">
            <span>{{ caso.tipo.slice(0, ( isThreeLetters(caso.tipo) ? 3 : 2 )) }}</span>
          </div>
          <div class="informacion-caso">
            <h3 class="caso-tipo">{{ caso.tipo }}</h3>
            <span class="caso-numero">Caso Nº {{ caso.numero }}</span>
          </div>
          <div class="caso-etapa" [ngClass]="{
            'caso-etapa-azul': caso.etapa === 'Mecanismo previo',
            'caso-etapa-rosado': caso.etapa === 'Mecanismo intermedio',
            'caso-etapa-amarillo': caso.etapa === 'Proceso verbal abreviado',
            'caso-etapa-verde': caso.etapa === 'Seguimiento/evaluacion'
          }">
            <div class="elipse" [ngClass]="{
              'elipse-azul': caso.etapa === 'Mecanismo previo',
              'elipse-rosado': caso.etapa === 'Mecanismo intermedio',
              'elipse-amarillo': caso.etapa === 'Proceso verbal abreviado',
              'elipse-verde': caso.etapa === 'Seguimiento / evaluacion'
            }"></div>
            <span class="caso-etapa-nombre">{{ caso.etapa }}</span>
          </div>
        </div>
      </div>
      <div class="botones-casos">
        <button type="button" id="boton-ver-caso" routerLink="/gestion-casos-inspecciones">Ver casos</button>
        <button type="button" id="boton-caso" routerLink="/gestion-casos-inspecciones/nuevo-caso">Nuevo caso +</button>
      </div>
    </section>
  </div>
</main>
