<main class="module-principal">
  <img
      src="./assets/img/v2/logo-ensamble-con-texto.png"
      style="
        width: 50%;
        max-width: 300px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      "
      alt="Logo ensamble"
    />
  <h1 class="text-center">Consulta externa de casos</h1>
  <h2 class="mb-10">Elige un criterio de búsqueda y busca un caso que se encuentre en la plataforma de Ensamble</h2>
  <div>
    <form [formGroup]="form" (ngSubmit)="onSearch()" class="form">
      <mat-form-field class="mr-20">
        <mat-label for="numCaso">Por número de caso</mat-label>
        <input matInput type="text" id="numCaso" formControlName="numCaso" placeholder="Número de caso" maxlength="20" minlength="6">
      </mat-form-field>
      <mat-form-field class="mr-20">
        <mat-label for="cedula">Por cédula</mat-label>
        <input matInput type="text" id="cedula" formControlName="cedula" placeholder="Número de identificacion" maxlength="20" minlength="6">
      </mat-form-field>
      <button class="submit-button">Buscar</button>
    </form>
  </div>

  <div class="gestion-principal mb-30">
    <mat-form-field class="width-100">
      <mat-label>Filtrar</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Escribe aquí el valor que quieres buscar" #input>
    </mat-form-field>
    <table mat-table aria-label="Tabla de consulta externa" [dataSource]="dataSource">
      <ng-container matColumnDef="fecha">
        <th scope="row" aria-label="Columna de fecha de los hechos" mat-header-cell *matHeaderCellDef>Fecha de los hechos</th>
        <td mat-cell *matCellDef="let element"> {{element.fecha}} </td>
      </ng-container>
      <ng-container matColumnDef="numCaso">
        <th scope="row" aria-label="Columna de número de caso" mat-header-cell *matHeaderCellDef>Número de caso</th>
        <td mat-cell *matCellDef="let element"> {{element.numCaso}} </td>
      </ng-container>
      <ng-container matColumnDef="peticionarios">
        <th scope="row" aria-label="Columna de peticionarios" mat-header-cell *matHeaderCellDef>Peticionarios</th>
        <td mat-cell *matCellDef="let element"> {{element.peticionarios}} </td>
      </ng-container>
      <ng-container matColumnDef="presuntosInfractores">
        <th scope="row" aria-label="Columna de presuntos infractores" mat-header-cell *matHeaderCellDef>Presuntos infractores</th>
        <td mat-cell *matCellDef="let element"> {{element.presuntosInfractores}} </td>
      </ng-container>
      <ng-container matColumnDef="comportamientosDetalle">
        <th scope="row" aria-label="Columna de descripción de los comportamientos contrarios a la convivencia" mat-header-cell *matHeaderCellDef>Descripción de los comportamientos contrarios a la convivencia</th>
        <td mat-cell *matCellDef="let element"> {{element.comportamientosDetalle}} </td>
      </ng-container>
      <ng-container matColumnDef="accionesAdelantadas">
        <th scope="row" aria-label="Columna de acciones adelantadas" mat-header-cell *matHeaderCellDef>Acciones adelantadas</th>
        <td mat-cell *matCellDef="let element"> {{element.accionesAdelantadas}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Selecciona una página de la lista de casos"
    ></mat-paginator>
  </div>
</main>
