<div class="form-container">
  <div class="form-header">
    <h2 class="form-title">Datos generales</h2>
    <div class="d-flex-start">
      <button *ngIf="oldHearing || newHearing" class="cancel-button mr-10" (click)="cancelHearing()">Salir de {{ oldHearing ? 'edición' : 'creación' }}</button>
      <button class="download-button"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar"></button>
    </div>
  </div>

  <!-- LISTA DE AUDIENCIAS -->
  <div *ngIf="audienciasDisponibles?.length > 0; else noAudiencias" class="form-group d-flex-l">
    <button class="selectable-button" *ngFor="let audiencia of audienciasDisponibles; index as i" (click)="editHearing(audiencia)">
      <div *ngIf="audiencia.activo !== false">
        {{ 'Citación ' + (i+1) + ' / ' + formatDate(audiencia?.fechaAudiencia) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar">
      </div>
    </button>
  </div>
  <ng-template #noAudiencias>
    <div class="width-70">
      <p class="text">
        No tienes ninguna citación creada, crea una para acceder a todas las funcionalidades del mecanismo intermedio.
      </p>
    </div>
  </ng-template>

  <form class="phase-form" #citacion="ngForm" *ngIf="(newHearing || oldHearing)" (ngSubmit)="onGuardar(citacion)">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaAudiencia" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaAudiencia"
          id="fechaAudiencia"
          [(ngModel)]="audiencia.fechaAudiencia">
      </div>
      <div class="form-group half-width-group">
        <label for="horaAudiencia" class="inspections-label">Hora</label>
        <input
          class="inspections-input time-input"
          placeholder="dd/mm/aaaa"
          type="time"
          name="horaAudiencia"
          id="horaAudiencia"
          [(ngModel)]="audiencia.horaAudiencia">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="direccionAudiencia" class="inspections-label">Lugar</label>
        <input
          type="text"
          placeholder="Escribe aquí"
          name="direccionAudiencia"
          class="inspections-input"
          id="direccionAudiencia"
          [(ngModel)]="audiencia.direccionAudiencia">
      </div>
    </div>
    <h3 class="font16 bold">Desarrollo de la audiencia</h3>
    <p>En el municipio de {{ fichaGeneral.municipio || 'SIN DEFINIR' }} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha {{ formatDate(audiencia.autoFecha) }} y previas las comunicaciones de rigor, el Inspector, {{ inspector?.codigoInspector || 'SIN DEFINIR' }} da inicio a la Audiencia Pública del Proceso Verbal Abreviado del expediente de la referencia, con el propósito de agotar el trámite previsto en el artículo 223 de la Ley 1801 de 2016.</p>
    <h3 class="font16 bold">Hechos</h3>
    <p class="font16 mb-20">{{ fichaGeneral.resumenHechos + '.'}}<br>{{ 'Lo anterior se dió en el lugar con la siguiente información: '+ fichaGeneral.detalleLugarHechos }}</p>
    <div class="form-row mb-20">
      <div class="form-group half-width-group">
        <h4 class="font16 mb-10">Dirección de los hechos</h4>
        <p>{{ fichaGeneral.direccionHechos }}</p>
      </div>
    </div>
    <div class="form-row mb-20">
      <div class="form-group full-width-group">
        <h4 class="font16 mt-0">Localidad / comuna / vereda / barrio</h4>
        <p class="mb-0">{{ fichaGeneral.nombreLugarHechos }}</p>
      </div>
    </div>
    <h3 class="font16 bold">Lugar de los hechos</h3>
    <p class="font16 gray-text mb-20">{{ fichaGeneral.lugarHechos }}</p>
    <div class="form-row mb-20" *ngIf="fichaGeneral.lugarHechos === 'Establecimiento de comercio'">
      <div class="form-group half-width-group">
        <h4 class="font16">Nombre o razón social</h4>
        <p>{{ fichaGeneral.nombreEstablecimientoHechos }}</p>
      </div>
      <div class="form-group half-width-group">
        <h4 class="font16">NIT</h4>
        <p>{{ fichaGeneral.nitEstablecimientoHechos }}</p>
      </div>
    </div>
    <div class="form-row mb-20" *ngIf="fichaGeneral.lugarHechos === 'Establecimiento de comercio'">
      <div class="form-group half-width-group">
        <h4 class="font16">Actividad económica</h4>
        <p>{{ fichaGeneral.actividadEconomicaEstablecimientoHechos }}</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <h4 class="font16 mt-0">Detalle del lugar de los hechos</h4>
        <p>{{ fichaGeneral.detalleLugarHechos }}</p>
      </div>
    </div>
    <h3 class="font18 bold">Comportamientos contrarios a la convivencia (según CNSCC)</h3>
    <div *ngFor="let caso of fichaGeneral.CCC; index as i" class="mb-20">
      <h3 class="font18">Comportamiento contrario a la convivencia #{{i+1}}</h3>
      <div class="form-row align-start mb-0">
        <div class="form-group full-width-group">
          <h4 class="font18">Título</h4>
          <p>{{ caso.titulo }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group full-width-group">
          <h4 class="font18">Capítulo</h4>
          <p>{{ caso.capitulo }}</p>
        </div>
      </div>
      <div class="form-row d-flex-between ">
        <div class="form-group full-width-group">
          <h4 class="font18">Artículo</h4>
          <p>{{ caso.articulo }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group full-width-group">
          <h4 class="font18">Numeral</h4>
          <p>{{ caso.numeral || 'No asignado' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group full-width-group">
          <h4 class="font18">Literal</h4>
          <p>{{ caso.literal || 'No asignado' }}</p>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group full-width-group">
          <h4 class="font18">Descripción del comportamiento</h4>
          <p>{{ caso.descripcionComportamiento }}</p>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="observaciones" class="inspections-label bold">Observaciones (descripción detallada del caso)</label>
        <textarea class="textarea font16" name="observaciones" id="observaciones" placeholder="Escribe aquí" [(ngModel)]="mecIntermedio.observaciones" cols="10"></textarea>
      </div>
    </div>


    <!-- SI SE USA MECANISMO PREVIO -->
    <div class="form-row mb-20">
      <div class="form-group full-width-group">
        <h4 class="font18 bold mb-20">¿Se utilizó mecanismo previo?</h4>
        <p>{{ mecPrevio.faseIntervencion?.actividades.length > 0  ? 'Sí': 'No' }}</p>
      </div>
    </div>
    <h3 class="font18 mb-10">Tipos de mecanismos previos utilizados</h3>
    <ul class="activities mb-20" *ngIf="mecPrevio.faseIntervencion?.actividades?.length > 0">
      <li *ngFor="let actividad of mecPrevio.faseIntervencion?.actividades" class="font16">{{ formatDate(actividad.fechaActividad) + ' - ' + actividad.tipoActividad }}</li>
    </ul>

    <!-- SI SE USA MECANISMO INTERMEDIO -->
    <div class="form-row mb-20">
      <div class="form-group full-width-group">
        <h4 class="font18 bold mb-20">¿Se utilizó mecanismo intermedio?</h4>
        <p class="font16">{{ mecIntermedio.medioPolicia && mecIntermedio.medioPolicia !== '' ? 'Sí' : 'No' }}</p>
      </div>
    </div>
    <div class="form-row" *ngIf="mecIntermedio.medioPolicia && mecIntermedio.medioPolicia !== ''">
      <div class="form-group full-width-group">
        <h4 class="font18 mb-10">Tipo de mecanismo intermedio utilizado</h4>
        <p class="font16">{{  mecIntermedio.medioPolicia }}</p>
      </div>
    </div>

    <!-- ASISTENCIA DE CITADOS -->
    <div class="list" *ngIf="audiencia.citados?.length > 0; else sinCitados">
      <h4 class="font16 bold">¿Asiste el (los) citado(s)?</h4>
      <div class="list-row d-flex-between mb-20" *ngFor="let citado of audiencia.citados; index as i">
        <span class="font16">{{ citado.nombreCompleto | truncate:150 }}</span>
        <div class="checkslist">
          <div class="d-flex-around ml-20">
            <input class="checkbox" name="asiste" [checked]="citado.asiste" (change)="changeAssistanceStatus(citado, true, i)" type="checkbox">
            <label class="inspections-label mb-0" for="asiste">Sí</label>
          </div>
          <div class="d-flex-around ml-10">
            <input class="checkbox" name="noAsiste" [checked]="citado.asiste === false" (change)="changeAssistanceStatus(citado, false, i)" type="checkbox">
            <label class="inspections-label mb-0" for="noAsiste">No</label>
          </div>
        </div>
      </div>
      <div *ngIf="citadosSinAsistir.length > 0">
        <h4 class="font16 bold">Se justificó inasistencia</h4>
        <div class="list-row d-flex-between mb-10" *ngFor="let citado of citadosSinAsistir; index as i">
          <span class="font16" *ngIf="citado.asiste === false">{{ citado.nombreCompleto | truncate:150 }}</span>
          <div class="checkslist" *ngIf="citado.asiste === false">
            <div class="d-flex-around ml-20">
              <input class="checkbox width-30" name="justifica" [checked]="citado.justificaInasistencia" (change)="changeJustificationStatus(citado, true, i)" type="checkbox">
              <label class="inspections-label mb-0" for="asiste">Sí</label>
            </div>
            <div class="d-flex-around ml-10">
              <input class="checkbox width-30" name="noJustifica" [checked]="!citado.justificaInasistencia && citado.justificaInasistencia !== undefined" (change)="changeJustificationStatus(citado, false, i)" type="checkbox">
              <label class="inspections-label mb-0" for="noAsiste">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #sinCitados>
      <div>
        <p class="font16">No se han agregado citados</p>
      </div>
    </ng-template>

    <!-- AGENTE MIN PUBLICO -->
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="asisteAgente" class="inspections-label bold">¿Asiste Agente del Ministerio Público?</label>
        <div class="d-flex-start-center">
          <div class="d-flex-around mr-10">
            <input class="checkbox" name="asisteAgente" [checked]="audiencia.asisteAgente" (change)="changeAgentAssistance(true)" type="checkbox">
            <label class="inspections-label mb-0" for="asisteAgente">Sí</label>
          </div>
          <input
            type="text"
            [disabled]="!audiencia.asisteAgente"
            placeholder="Escribe aquí"
            name="nombreAgente"
            class="inspections-input"
            id="nombreAgente"
            [(ngModel)]="audiencia.nombreAgente">
          <div class="d-flex-around ml-10">
            <input class="checkbox" name="noAsisteAgente" [checked]="!audiencia.asisteAgente && audiencia.asisteAgente !== undefined" (change)="changeAgentAssistance(false)" type="checkbox">
            <label class="inspections-label mb-0" for="noAsisteAgente">No</label>
          </div>
        </div>
      </div>
    </div>
    <button class="submit-button mr-20" nbButton type="submit">Guardar</button>
  </form>
</div>
