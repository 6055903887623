import { Injectable } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { S3 } from 'aws-sdk';
import { S3Service } from '../../../services/s3.service';
import Swal from 'sweetalert2';
import { Caso, Citado, CitadoInfo, FichaGeneral, MecanismoIntermedio } from '../../inspecciones.interface';
@Injectable()
export class MecanismoIntermedioService {

  bucket: S3;

  constructor(
    private mainService: MainService,
    private s3Service: S3Service,
  ) {
    this.bucket = this.s3Service.getBucket();
  }

  getAudienceInfo(caseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.mainService.get<MecanismoIntermedio>(`api/mec-intermedio-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<MecanismoIntermedio>(response)){
          const bd = response.result;
          const audienciasDisponibles = bd.audiencias;
          const mecIntId = bd._id;
          // const correosEnviados = bd.correos;
          resolve({
            audienciasDisponibles,
            mecIntId,
            // correosEnviados
          })
        }
      })
    })
  }

  getQuoted(caseId: string): Promise<Citado[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const quoted = [];
          const { peticionarios, infractores } = response.result
          for(let peticionario of peticionarios){
            quoted.push({
              nombreCompleto: peticionario.nombrePeticionario,
              tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
              identificacion: peticionario.identificacionPeticionario,
              correo: peticionario.correoPeticionario,
              telefono: peticionario.telefonoPeticionario,
              direccion: peticionario.direccionPeticionario,
              tipo: 'peticionario'
            });
          }
          for(let infractor of infractores){
            let nombreCompleto
            if(infractor.nombreInfractor && infractor.apellidoInfractor){
              nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
            } else if (infractor.nombreInfractor){
              nombreCompleto = infractor.nombreInfractor;
            } else if (infractor.apellidoInfractor){
              nombreCompleto = infractor.apellidoInfractor;
            } else {
              return
            }
            quoted.push({
              nombreCompleto,
              tipoIdentificacion: infractor.tipoIdentificacionInfractor,
              identificacion: infractor.identificacionInfractor,
              correo: infractor.correoInfractor,
              telefono: infractor.telefonoInfractor,
              direccion: infractor.direccionInfractor,
              nombre: infractor.nombreInfractor,
              apellido: infractor.apellidoInfractor,
              tipo: 'infractor'
            })
          }
          resolve(quoted)
        } else {
          throw response.error;
        }
      })
    })
  }

  getGeneralFileInfo(caseId: string): Promise<Pick<FichaGeneral, 'CCC' | '_id' | 'infractores' | 'nombreLugarHechos' | 'municipio' | 'caso' | 'direccionHechos' | 'detalleLugarHechos' | 'resumenHechos' | 'tipoLocalidadHechos' | 'lugarHechos' | 'nombreEstablecimientoHechos' | 'nitEstablecimientoHechos' | 'actividadEconomicaEstablecimientoHechos'>> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          const { detalleLugarHechos, resumenHechos, nombreLugarHechos, tipoLocalidadHechos, lugarHechos, nombreEstablecimientoHechos, nitEstablecimientoHechos, direccionHechos, actividadEconomicaEstablecimientoHechos, CCC, infractores, municipio } = bd;
          const caso = bd.caso;
          resolve({
            infractores,
            CCC,
            nombreLugarHechos,
            municipio,
            caso,
            detalleLugarHechos,
            resumenHechos,
            tipoLocalidadHechos,
            direccionHechos,
            lugarHechos,
            nombreEstablecimientoHechos,
            nitEstablecimientoHechos,
            actividadEconomicaEstablecimientoHechos
          })
        }
      })
    })
  }

  findQuoted(caseId: string, identificacion: string): Promise<CitadoInfo> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          const { infractores, peticionarios } = bd;
          for(let infractor of infractores){
            if(infractor.identificacionInfractor === identificacion){
              let nombreCompleto
              if(infractor.nombreInfractor && infractor.apellidoInfractor){
                nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
              } else if (infractor.nombreInfractor){
                nombreCompleto = infractor.nombreInfractor;
              } else if (infractor.apellidoInfractor){
                nombreCompleto = infractor.apellidoInfractor;
              } else {
                return
              }
              resolve({
                nombreCompleto,
                nombre: infractor.nombreInfractor,
                apellido: infractor.apellidoInfractor,
                tipoIdentificacion: infractor.tipoIdentificacionInfractor,
                identificacion: infractor.identificacionInfractor,
                nivelEducativo: infractor.nivelEducativoInfractor,
                ocupacion: infractor.ocupacionInfractor,
                tipoVulnerabilidad: infractor.tipoVulnerabilidadInfractor,
                direccion: infractor.direccionInfractor,
                telefono: infractor.telefonoInfractor,
                correo: infractor.correoInfractor
              });
            }
          }
          for(let peticionario of peticionarios){
            if(peticionario.identificacionPeticionario === identificacion){
              resolve({
                nombreCompleto: peticionario.nombrePeticionario,
                nombre: peticionario.nombrePeticionario,
                tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
                identificacion: peticionario.identificacionPeticionario,
                direccion: peticionario.direccionPeticionario,
                telefono: peticionario.telefonoPeticionario,
                correo: peticionario.correoPeticionario,
              });
            }
          }
        }
      })
    })
  }

  findMultipleQuoted(caseId: string, identificaciones: string[]): Promise<CitadoInfo[]> {
    return new Promise((resolve, reject) => {
      this.mainService.get<FichaGeneral>(`api/ficha-general/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<FichaGeneral>(response)){
          const bd = response.result;
          const { infractores, peticionarios } = bd;
          const citados: CitadoInfo[] = [];
          for(let identificacion of identificaciones){
            for(let infractor of infractores){
              if(infractor.identificacionInfractor === identificacion){
                let nombreCompleto;
                if(infractor.nombreInfractor && infractor.apellidoInfractor){
                  nombreCompleto = infractor.nombreInfractor + ' ' + infractor.apellidoInfractor
                } else if (infractor.nombreInfractor){
                  nombreCompleto = infractor.nombreInfractor;
                } else if (infractor.apellidoInfractor){
                  nombreCompleto = infractor.apellidoInfractor;
                } else {
                  return
                }
                citados.push({
                  nombreCompleto,
                  nombre: infractor.nombreInfractor,
                  apellido: infractor.apellidoInfractor,
                  tipoIdentificacion: infractor.tipoIdentificacionInfractor,
                  identificacion: infractor.identificacionInfractor,
                  nivelEducativo: infractor.nivelEducativoInfractor,
                  ocupacion: infractor.ocupacionInfractor,
                  tipoVulnerabilidad: infractor.tipoVulnerabilidadInfractor,
                  direccion: infractor.direccionInfractor,
                  telefono: infractor.telefonoInfractor,
                  correo: infractor.correoInfractor
                });
              }
            }
            for(let peticionario of peticionarios){
              if(peticionario.identificacionPeticionario === identificacion){
                citados.push({
                  nombreCompleto: peticionario.nombrePeticionario,
                  nombre: peticionario.nombrePeticionario,
                  tipoIdentificacion: peticionario.tipoIdentificacionPeticionario,
                  identificacion: peticionario.identificacionPeticionario,
                  direccion: peticionario.direccionPeticionario,
                  telefono: peticionario.telefonoPeticionario,
                  correo: peticionario.correoPeticionario,
                });
              }
            }
          }
          resolve(citados);
        }
      })
    })
  }

  /**
   * Obtiene toda la información del mecanismo intermedio a partir de su id
   * @param id El id del mecanismo intermedio
   * @returns El mecanismo intermedio
   */
  getAllInfo(id): Promise<MecanismoIntermedio> {
    return new Promise((resolve, reject) => {
      this.mainService.get<MecanismoIntermedio>(`api/mec-intermedio-caso/${id}`).subscribe(response => {
        if(this.mainService.isSuccess<MecanismoIntermedio>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene toda la información del mecanismo intermedio a partir del número de caso
   * @param caseId El número de caso asociado
   * @returns El mecanismo intermedio
   */
  getAllInfoByCase(caseId): Promise<MecanismoIntermedio> {
    return new Promise((resolve, reject) => {
      this.mainService.get<MecanismoIntermedio>(`api/mec-intermedio-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<MecanismoIntermedio>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  /**
   * Obtiene la información de un caso a partir de su id
   * @param {string} caseId El id del caso
   * @returns {Promise<Caso>} El caso encontrado
   */
  getCaseInfo(caseId: string): Promise<Caso> {
    return new Promise((resolve, reject) => {
      this.mainService.get<Caso>(`api/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<Caso>(response)){
          resolve(response.result);
        } else {
          reject(response.error);
        }
      })
    })
  }

  saveInfo(id, data): Promise<MecanismoIntermedio> {
    return new Promise((resolve, reject) => {
      console.log('la data')
      this.mainService.put<MecanismoIntermedio>(`api/mec-intermedio-caso/${id}`, data).subscribe(response => {
        console.log(response);
        if(this.mainService.isSuccess<MecanismoIntermedio>(response)){
          Swal.fire({
            title: 'Éxito',
            text: 'Mecanismo intermedio actualizado con éxito',
            icon: 'success'
          });
          resolve(response.result);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo actualizar el mecanismo intermedio',
            icon: 'error'
          })
          reject(response.error);
        }
      })
    })
  }

  /**
   * Guarda el archivo en el servidor de S3
   * @param file El archivo que se va a subir, tipo file
   * @returns el link donde se guardó el archivo
   */
  saveInS3 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (file !== undefined) {
        // Obtener la información necesaria
        let { params, options } = this.s3Service.getInfoForUpload('mecanismo-intermedio',file);
        // Subir a s3
        this.bucket.upload(params, options, (err, data) => {
          if(err){
            reject(err);
          } else {
            const archivo = data.Location
            resolve(archivo);
          }
        });
      }
    });
  }

  /**
   * Muestra un mensaje de éxito al subir una imagen
   */
  showS3UploadError(): void {
    this.s3Service.showErrorUploading();
  }

  /**
   * Muestra un mensaje de error al subir una imagen
   */
  showS3UploadSuccess(): void {
    this.s3Service.showSuccessUploading();
  }

}
