import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../../../services/main.service';
import { PvaService } from '../pva.service';
import Swal from 'sweetalert2';
import { jsPDF } from 'jspdf';
import * as QRCode from 'qrcode';
import { Archivo, Audiencia, AudienciaPVA, Caso, Citado, Correo, FichaGeneral, Inspector } from '../../../inspecciones.interface';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-citacion-pva',
  templateUrl: './citacion-pva.component.html',
  styleUrls: ['./citacion-pva.component.css']
})
export class CitacionPvaComponent implements OnInit {
  todayDate = new Date().toISOString().split('T')[0];
  caseId: string = '';
  pasos = [1,2,3];
  step: number = 1;

  cccs: any = [];
  config = {
    displayFn: (item: any) => {
      return item.nombreCompleto;
    },
    search:true,
    noResultsFound: "No se encontraron resultados",
    placeholder:'Seleccione'
  };

  posiblesCitados: Citado[] = [];
  citado: Citado = {};
  quotedIndex: number;

  wantEditQuoted: boolean = false;
  wantDeleteQuoted: boolean = false;

  audiencia: AudienciaPVA = {
    citados: [],
    fechaAudiencia: '',
    horaAudiencia: '',
    direccionAudiencia: '',
    firmaInspectorPolicia: '',
    nombreFuncionario: '',
    firmaFuncionario: '',
    asisteAgente: undefined,
    nombreAgente: '',
    fechaConstanciaAsistencia: '',
    horaConstanciaAsistencia: '',
    lugarConstanciaAsistencia: '',
    pruebasAportadas: [],
    pruebasSolicitadas: [],
    pruebasInspector: [],
    audienciaSuspendida: false,
    fechaReanudacion: undefined,
    horaReanudacion: ''
  }

  correo: Correo = {
    registroCorreo: undefined,
    fechaEnvioCorreo: undefined,
    horaEnvioCorreo: '',
    notaRecibidoCorreo: '',
    nombreFuncionarioResponsable: '',
    firmaFuncionarioResponsable: ''
  }

  newHearing: boolean;
  oldHearing: boolean;
  hearingIndex: number;

  fichaGeneral: FichaGeneral = {
    caso: undefined,
    createdAt: '',
    documentoOrigen: '',
    fechaDocumentoOrigen: '',
    archivosDocumentoOrigen: [],
    otrosDocumentos: [],
    radicado: '',
    departamento: '',
    municipio: undefined,
    modeloMunicipio: '',
    peticionarios: [],
    infractores: [],
    resumenHechos: '',
    fechaHechos: '',
    horaHechos: '',
    nombreLugarHechos: '',
    tipoLocalidadHechos: '',
    lugarHechos: '',
    detalleLugarHechos: '',
    CCC: [],
    esQuerellaCivil: undefined
  };
  caso: Caso = {
    inspector: undefined,
    numCaso: '',
    numInspeccion: ''
  };
  inspeccion: any = {};
  inspector: Inspector = {
    nombre: '',
    apellido: '',
    email: '',
    password: '',
    telefonoInspector: '',
    telefonoOficina: '',
    aceptado: false,
    tipoDocumento: '',
    numDocumento: 0,
    codigoInspector: '',
    municipio: undefined
  };

  showContinueOption: boolean = false;

  doc: jsPDF;
  finalY: number = 15;

  @Input() selectedHearing: any;
  @Input() audienciasDisponibles: AudienciaPVA[];
  @Input() pvaId: string;
  @Input() correosEnviados: any[];
  @Input() isSelected: boolean;

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private mainService: MainService,
    private pvaService: PvaService,
    private route: ActivatedRoute,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getGeneralFileInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      for(let citado of this.audiencia.citados){
        if(!citado.asiste){
          this.showContinueOption = true;
        }
      }
      this.oldHearing = true;
    }
  }

  /**
   * Obtiene la información que necesita de la ficha general
   */
  getGeneralFileInfo(): void {
    this.pvaService.getQuoted(this.caseId).then(quoted => {
      this.posiblesCitados = quoted;
    })
    this.pvaService.getGeneralFileInfo(this.caseId).then(response => {
      this.fichaGeneral.nombreLugarHechos = response.nombreLugarHechos;
      this.fichaGeneral.municipio = response.municipio;
      this.fichaGeneral.CCC = response.CCC
      this.caso = response.caso;
      this.inspector = response.caso.inspector;
    })
  }

  /**
   * Solicita la edición de una audiencia
   * @param hearing La audiencia
   */
  editHearing(hearing: AudienciaPVA): void {
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia ? (<string>hearing.fechaAudiencia).split("T")[0] : null;
    this.audiencia.autoFecha = hearing.autoFecha ? (<string>hearing.autoFecha).split("T")[0] : null;
    this.audiencia.fechaConstanciaAsistencia = hearing.fechaConstanciaAsistencia ? (<string>hearing.fechaConstanciaAsistencia).split("T")[0] : null;
    this.audiencia.fechaReanudacion = hearing.fechaReanudacion ? (<string>hearing.fechaReanudacion).split("T")[0] : null;
    console.log(hearing);
  }

  cancelHearing(): void {
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    this.step = 1;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  /**
   * Formatea la fecha para los botones
   * @param date La fecha a formatear
   * @returns La fecha en el formato adecuado
   */
  formatDate(date: Date | string, separator?: string): string {
    return this.utilsService.formatDate(date, separator);
  }

  /**
   * Formatea la fecha para los botones
   * @param date La fecha a formatear
   * @returns La fecha en el formato adecuado
   */
  formatVisualDate(date: Date): string {
    return this.utilsService.formatVisualDate(date);
  }

  startHearing(): void {
    this.newHearing = true;
    this.oldHearing = false;
    this.resetForm1();
  }

  /**
   * Solicita la eliminación de una citación
   */
  deleteHearing(index: number): void {
    if(this.isSelected || this.newHearing || this.oldHearing){
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar una audiencia en estado de edición',
        icon: 'error'
      });
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar esta citación?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, cancelar',
      showConfirmButton: true,
      confirmButtonText: 'Sí, eliminar'
    }).then(response => {
      if(response.isConfirmed){
        this.audienciasDisponibles[index].activo = false;
        Swal.fire({
          title: 'Éxito',
          text: 'La citación se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  resetForm1(){
    this.audiencia = {
      citados: [],
      fechaAudiencia: '',
      horaAudiencia: '',
      direccionAudiencia: '',
      firmaInspectorPolicia: '',
      nombreFuncionario: '',
      firmaFuncionario: '',
      asisteAgente: undefined,
      nombreAgente: '',
      fechaConstanciaAsistencia: '',
      horaConstanciaAsistencia: '',
      lugarConstanciaAsistencia: '',
      pruebasAportadas: [],
      pruebasSolicitadas: [],
      pruebasInspector: [],
      audienciaSuspendida: false,
      fechaReanudacion: undefined,
      horaReanudacion: '',
      activo: undefined
    }
  }

  /**
   * Agrega un nuevo citado a la audiencia
   */
  onAggregateQuoted(){
    if(
      this.citado.nombreCompleto !== undefined && this.citado.nombreCompleto !== '' &&
      this.citado.correo !== undefined && this.citado.correo !== ''
    ){
      const citadoObj = this.citado
      this.audiencia.citados = [...this.audiencia.citados, citadoObj];
      console.log(this.audiencia);
      this.resetcitadoInfo();
    } else {
      Swal.fire({
        title: 'Error',
        text: 'El citado debe contar con un correo electrónico',
        icon: 'error'
      });
    }
  }

  resetcitadoInfo(){
    this.citado = {};
  }

  cancelQuoted(){
    this.wantEditQuoted = false;
    this.wantDeleteQuoted = false;
    this.resetcitadoInfo();
  }

  editQuoted(quoted){
    this.citado = quoted;
    this.wantEditQuoted = true;
    this.quotedIndex = this.audiencia.citados.indexOf(quoted);
  }

  onModifyQuoted(){
    if(this.quotedIndex !== -1){
      this.audiencia.citados = [];
    }
  }

  /**
   * Elimina el citado de la lista de citados a una audiencia
   * @param index El indice del citado en la lista
   */
  deleteQuoted(index: number): void {
    if(this.wantEditQuoted){
      Swal.fire({
        title: 'Error',
        text: 'No se puede eliminar en estado de edición',
        icon: 'error'
      });
      return;
    }
    Swal.fire({
      title: '¡Espera!',
      text: '¿Estás seguro de que quieres borrar un citado?',
      icon: 'warning',
      cancelButtonText: 'No, cancelar',
      confirmButtonText: 'Sí, borrar',
      showCancelButton: true,
      showConfirmButton: true
    }).then((response) => {
      if(response.isConfirmed){
        this.audiencia.citados.splice(index, 1);
        Swal.fire({
          title: 'Éxito',
          text: 'El citado se ha eliminado exitosamente',
          icon: 'success',
          footer: `<span class="red-text">¡No olvides guardar!</span>`
        });
      }
    })
  }

  /**
   * Confirma la eliminación del citado
   * @param quoted El citado
   */
  deleteQuotedAccept(quoted: Citado){
    this.quotedIndex = this.audiencia.citados.indexOf(quoted);
    if(this.quotedIndex === -1){
      Swal.fire({
        title: 'Error',
        text: 'Hubo un error al borrar el citado',
        icon: 'error'
      });
    } else {

      this.wantDeleteQuoted = false
      this.quotedIndex = -1;
    }
  }

  /**
   * Guarda una imagen en S3 y le asigna la url del archivo almacenado a donde se requiera.
   * @param event El evento del input donde se guardan los archivos
   * @param tipoFirma El campo en donde se va a guardar.
   */
  onFileChange(event, tipoFirma: string){
    const file = event.target.files[0];
    this.pvaService.saveInS3(file).then(archivoSubido => {
      this.pvaService.showS3UploadSuccess();
      if(tipoFirma === 'firma-inspector'){
        this.audiencia.firmaInspectorPolicia = archivoSubido;
      } else if (tipoFirma === 'firma-funcionario-responsable') {
        this.correo.firmaFuncionarioResponsable = archivoSubido
      } else if (tipoFirma === 'registro-correo') {
        this.correo.registroCorreo = {
          tipo: file.type,
          url: archivoSubido
        };
      } else {
        this.audiencia.firmaFuncionario = archivoSubido;
      }
    }).catch(err => {
      this.pvaService.showS3UploadError();
      console.error(err);
    })
  }

/**
   * Obtiene la imagen a mostrar a partir del tipo de archivo, si es imagen la muestra
   * @param {Archivo} archivo el archivo 
   * @returns la url
   */
  getImageFromTypeFile(archivo: Archivo): string {
    const urlBase = '/assets/img/inspecciones/iconos-archivos/';
    const tiposArchivos = {
      'application/pdf': `${urlBase}pdf.png`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': `${urlBase}excel.png`,
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': `${urlBase}doc.png`
    }
    if(archivo.tipo && archivo.tipo.includes('image/')){
      return archivo.url;
    }
    if(tiposArchivos[archivo.tipo]){
      return tiposArchivos[archivo.tipo];
    } else {
      return `${urlBase}texto.png`;
    }
  }

  /**
   * Elimina la url del registro del correo
   */
  deleteRegister(){
    this.correo.registroCorreo = undefined;
  }

  sendEmail(){
    return
  }

  /**
   * Cambia el paso de la citación
   * @param {number} number El # de paso 
   */
  changeStep(number){
    this.step = number
  }

  /**
   * Cambia el estatus de la confirmación de asistencia de un citado
   * @param citado El citado que se le cambia el status
   * @param {boolean} status 
   * @param index El indice en la lista de asistentes
   */
  changeAssistanceStatus(citado, status, index){
    // PARA QUE SE ASIGNE UN STATUS SOLO SI LE MARCAN Y NO VIENE CON INFORMACION O SI VIENE CON INFORMACIÓN OPUESTA A LO QUE SE QUIERE MARCAR
    if (
      (status && citado.asiste === undefined) ||
      (!status && citado.asiste === undefined) ||
      (status && !citado.asiste) ||
      (!status && citado.asiste)
    ) {
      citado.asiste = status;
      this.audiencia.citados[index].asiste = status;
      if(status === false){
        this.showContinueOption = true;
      } else {
        this.showContinueOption = false;
      }
    } else {
      citado.asiste = undefined;
      this.audiencia.citados[index].asiste = undefined;
    }
  }

  /**
   * Cambia el estatus de la continuación de la audiencia
   * @param {boolean} status El status a cambiar 
   */
  changeContinuationStatus(status){
    if (
      (status && this.audiencia.suspendida === undefined) ||
      (!status && this.audiencia.suspendida === undefined) ||
      (status && !this.audiencia.suspendida) ||
      (!status && this.audiencia.suspendida)
    ) {
      this.audiencia.suspendida = status;
    } else {
      this.audiencia.suspendida = undefined;
    }
  }

  /**
   * Cambia el estatus de la asistencia del agente
   * @param {boolean} status El status a cambiar 
   */
  changeAgentAssistance(status){
    if (
      (status && this.audiencia.asisteAgente === undefined) ||
      (!status && this.audiencia.asisteAgente === undefined) ||
      (status && !this.audiencia.asisteAgente) ||
      (!status && this.audiencia.asisteAgente)
    ) {
      this.audiencia.asisteAgente = status;
      if(status === false){
        this.audiencia.nombreAgente = '';
      }
    } else {
      this.audiencia.asisteAgente = undefined;
    }
  }

  /**
   * Descarga el PDF de la citación
   */
  async downloadPDF(){
    this.doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    this.setPrincipalTitle();
    this.doc.text(`Citación proceso verbal abreviado - caso ${this.caso.numCaso || 'No definido'}`, 15, this.finalY);
    this.finalY += 20;
    this.setTitleStyle();
    this.doc.text('Fecha', 15, this.finalY);
    this.doc.text('Hora', 105, this.finalY);
    this.finalY += 10;
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.doc.text(this.audiencia.fechaConstanciaAsistencia.toString() || 'No definido', 15, this.finalY);
    this.doc.text(this.audiencia.horaConstanciaAsistencia || 'No definido', 105, this.finalY);

    this.finalY += 15;

    this.setTitleStyle()
    this.doc.text('Lugar', 15, this.finalY, { maxWidth: 180 });
    this.setNormalStyle();
    this.finalY += 10;
    this.doc.text(this.audiencia.lugarConstanciaAsistencia || 'No definido', 15, this.finalY, { maxWidth: 90 });
    this.finalY += 15;
    
    this.setTitleStyle()
    this.doc.text('Desarrollo de la audiencia', 15, this.finalY, { maxWidth: 180 });
    this.finalY += 10;
    this.setNormalStyle();
    const bigText = `En el municipio de ${this.fichaGeneral.municipio || 'NO DEFINIDO'} siendo la fecha y hora antes anotada, y conforme a lo dispuesto en auto de fecha ${this.audiencia.autoFecha || 'NO DEFINIDO'} y previas las comunicaciones de rigor, el Inspector, ${this.inspector.codigoInspector || 'NO DEFINIDO'} da inicio a la Audiencia Pública.`
    this.doc.text(bigText, 15, this.finalY, { maxWidth: 180 });
    this.finalY += this.getMoreHeightFromTextHeightFullWidth(bigText);
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

    if(this.audiencia.citados && this.audiencia.citados.length > 0){
      this.getQuotedAssistance();
    }
    this.setTitleStyle()
    this.doc.text('Municipio', 15, this.finalY);
    this.doc.text('Inspección No.', 105, this.finalY);
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;

    this.doc.text(this.fichaGeneral.municipio || 'No definido', 15, this.finalY);
    this.doc.text(this.caso.numInspeccion || 'No definido', 105, this.finalY);
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 15;

    await this.getSignatures();
    this.doc.save(`Citación fecha ${this.formatDate(this.audiencia.fechaAudiencia, '_')} caso ${this.caso.numCaso}.pdf`);
  }

  /**
   * Obtiene la información de la asistencia de los citados
   */
  getQuotedAssistance(){
    this.setTitleStyle();
    this.doc.text('Asiste el (los) citados', 15, this.finalY);
    this.setNormalStyle();
    this.finalY += 10;
    for(let citado of this.audiencia.citados){
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(`${citado.nombreCompleto}: ${citado.asiste ? 'Sí':'No'}`, 15, this.finalY);
      this.finalY += 5;
    }
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;

  }

  /**
   * Obtiene las firmas y las transforma a un código QR
   */
  async getSignatures(){
    this.setTitleStyle();
    this.doc.text('Nombre Inspector de Policía', 15, this.finalY, { maxWidth: 90 });
    this.doc.text('Firma Inspector de Policía', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;
    this.doc.text(`${(this.inspector.nombre ? this.inspector.nombre + ' ' : '') + (this.inspector.apellido ? this.inspector.apellido : '') || 'No asignado'}`, 15, this.finalY);
    if(this.audiencia.firmaInspectorPolicia){
      const url = await QRCode.toDataURL(this.audiencia.firmaInspectorPolicia);
      this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      this.doc.text('No asignado', 105, this.finalY);
    }
    this.finalY += 15;

    this.setTitleStyle();
    this.doc.text('Nombre funcionario', 15, this.finalY, { maxWidth: 90 });
    this.doc.text('Firma funcionario', 105, this.finalY, { maxWidth: 90 });
    this.setNormalStyle();
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 10;
    this.doc.text(`${this.audiencia.nombreFuncionario || 'No asignado'}`, 15, this.finalY);
    if(this.audiencia.firmaFuncionario){
      const url = await QRCode.toDataURL(this.audiencia.firmaFuncionario);
      this.doc.addImage(url, 'JPEG', 105, this.finalY-5, 20, 20);
      this.finalY += 5;
    } else {
      this.doc.text('No asignado', 105, this.finalY);
    }
    this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    this.finalY += 15;
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text 
   * @returns {number} the number that must increase the height from the top 
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    const length = string.length - 160;
    if(length/80 < 0){
      return 12;
    } else {
      return 15 + 3 * Math.ceil(length/80)
    }
  }

  /**
   * Set de los estilos del título del PDF
   */
  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set de los estilos negrillas del PDF
   */
  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Set de los estilos texto normal del PDF
   */
  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  /**
   * Guarda la información si pasa las validaciones
   */
  saveInfo(){
    if(this.audiencia.fechaAudiencia && this.audiencia.horaAudiencia && this.audiencia.direccionAudiencia){
      if(this.newHearing){
        this.audienciasDisponibles.push(this.audiencia);
      } else {
        this.audienciasDisponibles[this.hearingIndex] = this.audiencia;
      }
      const data = {
        audiencias: this.audienciasDisponibles
      }
      this.pvaService.saveInfo(this.pvaId, data).then()
    } else {
      Swal.fire({
        title: 'Error',
        text: 'No se puede crear una citación sin sus datos',
        icon: 'error'
      });
    }
  }
}
