<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title">Decisión y recursos</h2>
    </div>
    <button class="download-button" (click)="downloadPDF()">
      <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
    </button>
  </div>

  <form class="phase-form">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaDecision" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaDecision"
          [(ngModel)]="mecInt.fechaDecision"
          id="fechaDecision"
          [min]="todayDate" />
      </div>
      <div class="form-group half-width-group">
        <label for="horaDecision" class="inspections-label">Hora</label>
        <input type="time" placeholder="00:00" name="horaDecision" [(ngModel)]="mecInt.horaDecision" class="inspections-input time-input" id="horaDecision" />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <p class="font16" for="cuenta">
          Teniendo en cuenta la anterior información, relativa a los datos generales, descargos y otras declaraciones, y previas las siguientes consideraciones de hecho y de derecho:
        </p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <label class="inspections-label" for="consideracionesInspector">Consideraciones del inspector</label>
        <textarea class="textarea" name="consideracionesInspector" id="consideracionesInspector" placeholder="Escribe aquí…" [(ngModel)]="mecInt.consideracionesInspector" cols="20" rows="10"></textarea>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <label class="inspections-label" for="detalleOrdenPolicia">Se impone el siguiente medio de policía:</label>
        <ngx-select-dropdown
          class="inspections-select mb-10"
          name="medioPolicia"
          [(ngModel)]="mecInt.medioPolicia"
          [config]="config"
          [options]="tiposMediosPolicia"
        >
        </ngx-select-dropdown>
        <textarea class="textarea" name="detalleOrdenPolicia" id="detalleOrdenPolicia" [(ngModel)]="mecInt.detalleOrdenPolicia"
        placeholder="Descripción detallada del medio de policía señalando la forma específica en que debe cumplirse…" cols="20" rows="10"></textarea>
      </div>
    </div>

    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="plazo">Plazo</label>
        <input
          class="inspections-input"
          type="text"
          name="plazo"
          id="plazo"
          [(ngModel)]="mecInt.plazo"
          placeholder="Escribe aquí">
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="lugar">Lugar</label>
        <input
          class="inspections-input"
          type="text"
          name="lugar"
          id="lugar"
          [(ngModel)]="mecInt.lugar"
          placeholder="Escribe aquí">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <p class="font16" for="decision">
          Esta decisión queda notificada en estrados y se informa al destinatario que contra la misma procede recurso de reposición ante esta Inspección de Policía y de apelación ante:
        </p>
      </div>
    </div>
    <br>

    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label font16" for="autoridadResuelve">Autoridad que resuelve el recurso</label>
        <input
          class="inspections-input"
          type="text"
          name="autoridadResuelve"
          id="autoridadResuelve"
          [(ngModel)]="mecInt.autoridadResuelve"
          placeholder="Escribe aquí">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group" >
        <p class="font16" for="decision">
          recurso que debe sustentar en este momento
        </p>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="interponeRepocision">¿Interpone reposición?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="siReposicion" id="siReposicion" type="checkbox" [checked]="interponeReposicion" (change)="changeAccomplishment(true)">
            <label class="inspections-label" for="siReposicion">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="noReposicion" id="noReposicion" type="checkbox" [checked]="!interponeReposicion && interponeReposicion != undefined" (change)="changeAccomplishment(false)" >
            <label class="inspections-label" for="noReposicion">No</label>
          </div>
        </section>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group d-flex-start">
        <label class="inspections-label bold mb-20" for="interponeApelacion">¿Interpone apelación?</label>
        <section class="d-flex-between">
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="siApelacion" id="siApelacion" type="checkbox" [checked]="interponeApelacion" (change)="changeAccomplishmentApelacion(true)">
            <label class="inspections-label" for="siApelacion">Si</label>
          </div>
          <div class="d-flex-start checkbox-container">
            <input class="checkbox" name="noApelacion" id="noApelacion" type="checkbox" [checked]="!interponeApelacion && interponeApelacion != undefined" (change)="changeAccomplishmentApelacion(false)" >
            <label class="inspections-label" for="noApelacion">No</label>
          </div>
        </section>
      </div>
    </div>

    <div *ngIf="interponeApelacion || interponeReposicion">
      <div class="form-row">
        <div class="form-group full-width-group" >
          <label class="inspections-label " for="sustentacionRecurso">Sustentación del recurso</label>
          <textarea class="textarea" name="sustentacionRecurso" id="sustentacionRecurso" [(ngModel)]="mecInt.sustentacionRecurso" placeholder="Escribe el detalle de la sustentación del recurso" cols="20" rows="10"></textarea>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group full-width-group" >
          <label class="inspections-label" for="decisionSobreRecursos">Decisión sobre los recursos interpuestos</label>
          <textarea class="textarea" name="decisionSobreRecursos" id="decisionSobreRecursos" [(ngModel)]="mecInt.decisionSobreRecursos" placeholder="Escribe el detalle de la decisión sobre los recursos interpuestos" cols="20" rows="10"></textarea>
        </div>
      </div>
  
      <div class="form-row" >
        <div class="form-group full-width-group" >
          <p class="font16" for="decisionInfo">
            La decisión sobre los recursos interpuestos queda notificada en estrados. Como quiera que se interpuso y se concedió apelación, se remite ante:
          </p>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group full-width-group" >
          <p class="inspections-label">
            Autoridad que resuelve el recurso : Oficia jurídica de la alcaldía municipal
          </p>
        </div>
      </div>
  
      <div class="form-row">
        <div class="form-group full-width-group" >
          <p class="font16" for="enviaraActuacion">
            A donde se enviará la actuación para que lo resuelva.
          </p>
        </div>
      </div>
    </div>
    <p *ngIf="!interponeApelacion && !interponeReposicion" class="font16">Teniendo en cuenta que no se interpusieron recursos, la decisión tomada queda en firme.</p>
  </form>

  <button class="submit-button" (click)="onSaveInfo()">Guardar</button>
</div>
