import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import {
  IJurisprudencia,
  JURISPRUDENCIA_LIST,
} from './jurisprudencia-meta-data';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
// import { LocalDataSource } from 'ng2-smart-table';

interface jurisprudenciaTableData {
  id: number;
  referencia: string;
  temaGeneral: string;
  subTema: string;
  decision: string;
  enlaces: string[];
}

@Component({
  selector: 'app-jurisprudencia-inspecciones',
  templateUrl: './jurisprudencia-inspecciones.component.html',
  styleUrls: ['./jurisprudencia-inspecciones.component.css'],
})
export class JurisprudenciaInspeccionesComponent implements OnInit, AfterViewInit {
  settings: any = {};

  dataSource: MatTableDataSource<jurisprudenciaTableData, any>;

  displayedColumns: string[] = ['referencia', 'temaGeneral', 'subTema', 'decision', 'enlaces'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  data: jurisprudenciaTableData[] = [
    {
      id: 1,
      referencia: 'C-211-17',
      temaGeneral:
        'Se demanda num. 4, parágrafos 2 y 3 del art. 140 del CNSCC por violación de la proporcionalidad de la medida correctia',
      subTema:
        'Razonabilidad y proporcionalidad en restitución de Bienes de Uso Público',
      decision:
        'Declara exequibles las normas demandadas “en el entendido que cuando se trate de personas en situaciones de debilidad manifiesta o pertenecientes a grupos de especial protección que de acuerdo con la jurisprudencia constitucional se encuentren protegidas por el principio de confianza legítima, no se les aplicarán las medidas correccionales de multa, decomiso o destrucción, hasta tanto se les haya ofrecido por las autoridades competentes programas de reubicación o alternativas de trabajo formal, en garantía de los derechos a la dignidad humana, mínimo vital y trabajo',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2017/C-211-17.htm'],
    },
    {
      id: 2,
      referencia: 'C-225-17',
      temaGeneral:
        'Se demanda la presunción de dolo y culpa contenida en el artículo 220 del CNSCC',
      subTema:
        'La presunción de culpa en materia ambiental, patrimonio ecológico o salud pública es válida. Sin embargo, no se puede atribuir al investigado la carga total de la prueba de ausencia de responsabilidad.',
      decision:
        'Declara exequible la norma demandada salvo la expresión “a quien le corresponde probar que no está incurso en el comportamiento contrario a la convivencia correspondiente”',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2017/C-225-17.htm'],
    },
    {
      id: 3,
      referencia: 'C-282-17',
      temaGeneral:
        'Se demanda parágrafo 1 del art. 222 del CNSCC, en cuanto al efecto devolutivo del recurso de apelación.',
      subTema:
        'El efecto devolutivo no vulnera el derecho de contradicción y defensa. Se debe adelantar un debido proceso. La medida impuesta debe ser razonable, proporcional y necesaria.',
      decision:
        'Corte declaró exequible la expresión “el cual se concederá en el efecto devolutivo”, contenida en el artículo demandado.',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2017/C-282-17.htm'],
    },
    {
      id: 4,
      referencia: 'C-349-17',
      temaGeneral: 'Se demanda parágrafo 1 del art. 223 del CNSCC',
      subTema:
        'Proceso Verbal Abreviado \n Efectos de la inasistencia a la audiencia pública \n Carácter sancionador de la multa como medida correctiva. \n Aplicación de las garantías penales en las actuaciones policivas \n Efectos de la presunción de inocencia en PVA: inversión de la carga de la prueba.',
      decision:
        'Declara exequible la norma en el entendido que en caso de inasistencia a la audiencia, el procedimiento se suspenderá por un término máximo de tres días, dentro de los cuales el presunto infractor deberá aportar prueba siquiera sumaria de una justa causa de inasistencia.',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2017/C-349-17.htm'],
    },
    {
      id: 5,
      referencia: 'C-391-17',
      temaGeneral:
        'Se demanda parcialmente el art. 150 en cuanto a la indeterminación del plazo de la conminación.',
      subTema:
        'Naturaleza y alcance de la conminación como modalidad de orden de policía.',
      decision:
        'Declara exequible la norma toda vez que no se vulnera el debido proceso, la autoridad debe motivar y actuar con base en los valores y principios, especialmente los de proporcionalidad, razonabilidad y necesidad.',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2017/C-349-17.htm'],
    },
    {
      id: 6,
      referencia: 'T-385-19',
      temaGeneral:
        'Se demanda protección del debido proceso por señalamiento de multa en comparendo por no portar documento de identificación.',
      subTema:
        'Debido proceso en PVI, legalidad del PVI y de la medida correctiva',
      decision:
        'Se ampara y se revoca comparendo porque no portar cédula no consituye conducta sancionable. Autoridades deben poder identificar a las personas.',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2019/T-385-19.htm'],
    },
    {
      id: 7,
      referencia: 'C-600-19',
      temaGeneral:
        'Se demanda parcialmente arts. 35 y 150 multa por incumplir, desacatar, desconocer e impedir la función u orden de policía.',
      subTema:
        'Sanción por incumplir, desacatar, impedir función de policía. Se afecta dignidad humana,',
      decision:
        'Declaró exequible la disposición por cuanto las órdenes de policía son de obligatorio cumplimeinto. En su consagración se respetaron los principios de proporcionalidad, necesidad e igualdad.',
      enlaces: ['https://www.corteconstitucional.gov.co/relatoria/2019/C-600-19.htm'],
    },
    {
      id: 8,
      referencia: 'C-054-19 \n C-093-20',
      temaGeneral:
        'Se demanda parcialmente arts. 92 y 183 en cuanto a las consecuencias por no pagar multas.',
      subTema:
        'Restricciones a los derechos económicos, sociales y culturales. Violación del non bis in ídem, legalidad, tipicidad, razonabilidad y proporcionalidad.',
      decision:
        'Declara exequibles algunos numerales de los artículos demandados porque las consecuencias por no pago de multa no desconocen los principios señalados en la demanda.',
      enlaces:['https://www.corteconstitucional.gov.co/relatoria/2019/C-054-19.htm', 'https://www.corteconstitucional.gov.co/relatoria/2020/C-093-20.htm'],
    },
    {
      id: 9,
      referencia: 'SP2016-2020 Rad. 57.279 Corte Suprema de Justiciad',
      temaGeneral:
        'Demanda de casación contra sentencia condenatoria por delito de fraude a resolución judicial o administrativa de policía. Art. 47 Ley 1453/11',
      subTema:
        'Orden de cesar actos perturbatorios constituye sentencia judicial. Su incumplimiento configura el delito de fraude a resolución judicial',
      decision: 'Confirma sentencia condenatoria',
      enlaces: [],
    },
  ];

  constructor() {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Aplica un filtro en la tabla que hace una búsqueda en todos los casos
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goToPage(links: string[]){
    if(links.length > 0){
      links.forEach(link => window.open(link, '_blank'));
    }
  }

}
