<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="prev-mec-container">
    <aside class="aside-prev-mec">
      <button class="go-back-button" (click)="goBackButton()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver</button>
      <h1 class="aside-title">Fase 4<br>Seguimiento y evaluación</h1>
      <h2 class="aside-data">Datos generales</h2>
      <h3 class="aside-subtitle">Caso No.</h3>
      <p class="aside-p">Nº{{ caseNumber }}</p>
      <h3 class="aside-subtitle">Inspección de Policía No.</h3>
      <p class="aside-p">Nº{{ inspectionNumber }}</p>
      <h3 class="aside-subtitle">Presuntos infractores</h3>
      <p *ngFor="let infractor of presuntosInfractores" class="aside-p">
        {{ infractor.nombreInfractor }} {{ infractor.apellidoInfractor }}
      </p>
      <h3 class="aside-subtitle">Descripción del CCC</h3>
      <p *ngFor="let case of cases; index as i" class="aside-p">{{ (i+1) + ': ' + case.descripcionComportamiento }}</p>
    </aside>
    <main class="main-new-case">
      <app-formulario></app-formulario>
    </main>
  </div>
</div>
