<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<main class="inspecciones-principal">
  <div class="d-flex-between">
    <div class="left-side">
      <button class="go-back-button" (click)="goToPreviousPage()">
        <img class="go-back-image" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver
      </button>
      <h1 class="principal-title mb-30">Mecanismos previos</h1>
    </div>
    <!-- <button class="button active" routerLink="/gestion-casos-inspecciones/nuevo-caso">Nuevo caso +</button> -->
  </div>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(1)">
    <h2 class="previous-mechanism-title">¿Qué es un mecanismo previo?</h2>
    <img [class]="firstPreviousMechanism === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="firstPreviousMechanism">
    <p class="previous-mechanism-p">Está claro que todas las actuaciones de las autoridades de policía se enmarcan en una filosofía preventiva, es decir, evitar que los habitantes de los territorios incurran en comportamientos o conductas que alteren las condiciones normales de convivencia, o categorías como las denomina el CNSCC: seguridad, tranquilidad, ambiente y salud pública. Por tanto, la intervención de las autoridades de policía no se limita a reaccionar cuando se ya se han alterado esas condiciones, como tampoco se pueden restringir a los mecanismos expresamente establecidos en el código, salvo en el caso de las medidas correctivas de policía, que sí se encuentran limitadas a aquéllas señaladas por el legislador, por virtud de la reserva legal.<br><br>
      Antes de seguir adelante, es conveniente que revisemos brevemente el marco legal que soporta lo antes mencionado:<br>
      Según el principio de necesidad consagrado en el artículo 8 del CNSCC y al que ya se ha hecho referencia en la introducción al módulo, “las autoridades de Policía solo podrán adoptar los medios y medidas rigurosamente necesarias e idóneas para la preservación y restablecimiento del orden público cuando la aplicación de otros mecanismos de protección, restauración, educación o de prevención resulte ineficaz para alcanzar el fin propuesto”. De dicho texto se deriva la posibilidad de aplicación de mecanismos distintos a los medios y medidas establecidos taxativamente en ese estatuto.<br><br>
      En ese sentido, son dos los requisitos que según el texto legal deben reunir tales mecanismos:<br><br>
      1. Que sean aplicados previamente a los medios y medidas de policía.<br><br>
      2.Que estén orientados a la preservación del orden público, es decir, de las condiciones de seguridad, tranquilidad, salud pública y ambiente que hacen posible la convivencia.<br><br>
      Ahora bien, dada la múltiple tipología de comportamientos a prevenir, las acciones previas pueden ser ejercidas directamente por el Inspector de Policía o en coordinación con otros funcionarios y autoridades de la Alcaldía, Policía Nacional y otras dependiendo de la especialidad del tema.<br><br>
      Con el propósito de definir el tipo de acción, el personal de apoyo, la logística necesaria, es importante contar con información sobre los comportamientos más frecuentes en el municipio: tipos de comportamiento, lugares de ocurrencia, personas involucradas, para lo cual es necesario acudir a las estadísticas generadas por las Inspecciones de Policía, la Alcaldía y la Estación de Policía sobre comparendos y procesos adelantados<br><br>
      </p>

  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(2)">
    <h2 class="previous-mechanism-title">Fase diagnóstica</h2>
    <img [class]="secondPreviousMechanism === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="secondPreviousMechanism">
    <p class="previous-mechanism-p">Aquí se debe tener en cuenta que en muchas ocasiones las problemáticas de convivencia se presentan como simples conflictos individuales que se abordan caso a caso. Sin embargo, la presentación de varias o muchas quejas asociadas a una misma situación de convivencia, puede ser el síntoma de una problemática mayor. Por eso, es necesario que se preste atención a las quejas y peticiones relacionadas con un mismo asunto y que se adelanten actividades para observar y medir su existencia real y las afectaciones que genera. Acciones posibles:<br><br>
      Brigadas para identificación de lugares; realización de censo, registro de datos. Instrumento: formularios prediseñados con datos para llenar a mano o de forma digital en equipos electrónicos (celular, tablet): dirección, barrio, nombres de las personas responsables, identificación, número de teléfono, registro fotográfico, afectaciones que se generan, personas dependientes de la actividad, observaciones, etc. En esta oportunidad no se deben imponer comparendos ni amenazar con sanciones o medidas correctivas, solo anunciar que se van a adelantar acciones educativas y de prevención.<br><br>
      </p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(3)">
    <h2 class="previous-mechanism-title">Fase de intervención</h2>
    <img [class]="thirdPreviousMechanism === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="thirdPreviousMechanism">
    <p class="previous-mechanism-p">Algunas ideas que pueden surgir del análisis realizado en la fase diagnóstica, son las siguientes: <br>
    Realización de talleres pedagógicos focalizados en la población objetivo y en la problemática detectada. Se trata de actividades educativas en las que se pueda brindar información relevante sobre las normas sanitarias, normas de uso del suelo, pautas para la recolección y disposición de residuos (orgánicos y no orgánicos), entre otras, y a su vez generar un espacio de reflexión para que presenten sus necesidades, inquietudes y/o propuestas. Como taller, la actividad no se puede limitar a una exposición teórica ni a una lectura de las normas de policía y convivencia aplicables. Es necesario incentivar la participación de los asistentes con dinámicas pertinentes . Por ejemplo, un análisis de casos similares en los que los participantes generen ideas para la solución de los problemas “hipotéticos” allí planteados. <br><br>
    Esta actividad puede ser aprovechada para lograr compromisos por parte de las personas responsables y por parte de las autoridades tendientes a afrontar la problemática conjuntamente. Los compromisos de las autoridades (Alcaldía) puede ser la de buscar una fórmula para la reubicación de los criaderos a lugares del municipio donde lo permitan las normas de uso del suelo, o buscar el apoyo de entes privados para tecnificarlos, con mejores instalaciones, condiciones de aseo y limpieza, tratamiento de aguas residuales, etc., ajustándose a las normas sanitarias y de uso del suelo.
En el caso que la cantidad de criaderos (o cualquier otra problemática) esté generando una situación de conflictividad mayor o ‘desborde’ las capacidades administrativas y financieras de los involucrados, la Alcaldía y demás entidades participantes pueden promover programas y proyectos en el ámbito de su PISCC y de su Plan de Desarrollo, para proveer recursos económicos; también se puede estudiar la posibilidad de hacer cambios a las normas locales (reglamentos) para ajustarlas a la realidad, generando reglas que sean de posible cumplimiento.<br><br>

    Es probable que, para dar respuesta a las inquietudes de los participantes y para concretar los compromisos logrados, se deban realizar reuniones posteriores en las que se defina con mucho más detalle cuáles son los pasos a seguir y cuál es la responsabilidad de cada uno en este proceso. En todo caso, no es conveniente que se dilate mucho esta fase ni que se programen muchas reuniones, estas deben ser muy ejecutivas y concretas. <br><br>
    El taller es una forma sencilla y práctica de intervención educativa porque involucra directamente a las personas interesadas, pero no es la única. También pueden organizarse otras actividades como mesas de trabajo, encuentros virtuales, conferencias. En esta fase se pueden invitar a organizaciones comunitarias y a entes privados, empresas, grandes comerciantes, asociaciones, fundaciones sin ánimo de lucro, establecimientos educativos, etc., para que aporten con ideas y recursos en la solución de la problemática.</p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(4)">
    <h2 class="previous-mechanism-title">Fase de seguimiento y evaluación</h2>
    <img [class]="fourthPreviousMechanism === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container paragraph-image-container" *ngIf="fourthPreviousMechanism">
    <p class="previous-mechanism-p">Luego de haberse realizado las actividades de intervención, es fundamental hacer un seguimiento a los compromisos logrados, lo que puede implicar la realización de reuniones institucionales, visitas a los criaderos o audiencias con las personas responsables, y evaluar el cumplimiento de los objetivos propuestos y la eficacia de las acciones adelantadas. De todo ello se dejará constancia en la base de datos. Elaborar un informe final de acciones previas en el que se indique si las acciones cumplieron su objetivo o fueron ineficaces.</p>
    <div class="images-container">
      <img src="" alt="">
      <img src="" alt="">
      <span></span>
    </div>
  </article>
  <div class="previous-mechanism-dropdown" (click)="showPreviousMechanism(5)">
    <h2 class="previous-mechanism-title">Dificultades o cuellos de botella en la etapa de Mecanismos Previos</h2>
    <img [class]="fifthPreviousMechanism === true? 'arrow-down-image rotate': 'arrow-down-image'" src="/assets/img/inspecciones/arrow-down.png" alt="Flecha abajo">
  </div>
  <article class="previous-mechanism-container" *ngIf="fifthPreviousMechanism">
    <p class="previous-mechanism-p">Inicio de las actuaciones por iniciativa de la policía uniformada y generación de comparendos como forma de intervención y control policivo. La falta de comprensión del régimen de policía y de las finalidades de este, ha generado una situación caótica y anacrónica en el sistema de aplicación del CNSCC. La generación desordenada e indiscriminada de comparendos y el inicio de PVI y PVA, entendida como un esquema único de intervención y de control por parte de las autoridades de policía, sobrepasa la capacidad administrativa, de personal, logística y de las herramientas informáticas, principalmente en las ciudades y en los municipios grandes e intermedios, dado el volumen de quejas y peticiones que a diario se presentan, sumados a los operativos policiales permanentes, centrados en la elaboración de comparendos. <br><br>
    Otra dificultad que puede resultar relevante en esta fase previa se relaciona con las deficiencias administrativas, logística y de personal, en la medida en que la mayoría de las oficinas gubernamentales cuentan con una planta fija o con personal de apoyo (contratistas) con unas funciones previamente establecidas. En un estado ideal de cosas, resultaría conveniente vincular a un profesional por parte de la Alcaldía con el conocimiento y experiencia para coordinar las actividades que aquí se han señalado, algo así como un referente de convivencia para el CNSCC. <br><br>
    Sin embargo, dado que esto no siempre es posible o puede estar condicionado a trámites extensos, existen otras alternativas, como es la programación de horarios a la semana o al mes para estas actividades, sin que se llegue a afectar el normal desarrollo de las otras tareas y funciones. <br><br>
    En cuanto a lo administrativo y logístico, mientras se va organizando mejor esta actividad, se debe trabajar con los elementos con los que se cuenta, dado que es una variable que escapa al control de los Inspectores de Policía. <br><br>
    Es importante tener claro que en ningún caso el Inspector se encuentra actuando por fuera del marco legal o destinando tiempo a tareas irrelevantes, y por eso en la introducción se ha destacado el cumplimiento de los principios de proporcionalidad, razonabilidad y necesidad, pues según este último los Inspectores sólo pueden adelantar un P.V.A. luego de haber intentado otros mecanismos de protección, restauración, educación o de prevención, y éstos resulten ineficaces. De manera que las actividades aquí sugeridas buscan dar alcance a ese postulado. <br><br>
    La coordinación con la Policía Nacional en esta etapa reviste mucha trascendencia, toda vez que se busca evitar el inicio de actuaciones formales de tipo sancionador. <br>
    Sistemas de calificación de funcionarios. A los uniformados se les evalúa por comparendo y a los Inspectores por medida correctiva impuesta.</p>
  </article>
</main>
