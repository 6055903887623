<div class="module-principal">
  <div class="container">
    <img
      src="./assets/img/v2/logo-ensamble-con-texto.png"
      style="
        width: 50%;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      "
      alt="Logo ensamble"
    />
    <h1 class="login-title">Olvidé mi contraseña</h1>
    <form class="form-group" (ngSubmit)="onForgot(forgotForm)" #forgotForm="ngForm">
      <label for="email" class="label mb-10">Ingrese su correo electrónico registrado en la plataforma</label>
      <input
        type="email"
        name="email"
        email
        ngModel
        class="form-control custom-input mb-20 height-40"
        #forgotEmail="ngModel"
        [(ngModel)]="data.forgotEmail"
        placeholder="micorreodeinspector@correo.com"
        id="email"
        required
      >
      <button type="submit" class="button button-login" nbSubmit>Recuperar contraseña</button>
    </form>
  </div>
</div>
