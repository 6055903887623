<div class="form-container">
  <div class="form-header">
    <div class="left-side">
      <h2 class="form-title">Descargos y otras declaraciones</h2>
    </div>
    <div>
      <button *ngIf="oldHearing || newHearing" class="cancel-button" (click)="cancelHearing()">Salir de {{ oldHearing ? 'edición' : 'creación' }}</button>
      <button class="download-button">
        <img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" />
      </button>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group full-width-group width-70">
      <p class="color-sub" for="descargosInfo">
        Respecto de lo anterior, se le otorga el uso de la palabra a los asistentes, primero al (los) quejoso (s) y luego al (los) presunto (s) infractor (es), para que se refiera (n) a los hechos…
      </p>
    </div>
  </div>

  <!-- LISTA DE AUDIENCIAS -->
  <div class="bottom-line">
    <h3 class="font18 bold">Selecciona una citación</h3>
    <div *ngIf="audienciasDisponibles?.length > 0; else noAudiencias" class="form-group d-flex-l">
      <button class="selectable-button" *ngFor="let audiencia of audienciasDisponibles; index as i" (click)="editHearing(audiencia)">
        <div *ngIf="audiencia.activo !== false">
          {{ 'Sesión ' + (i+1) + ' / ' + formatDate(audiencia.fechaAudiencia) }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar">
        </div>
      </button>
    </div>
    <ng-template #noAudiencias>
      <div class="width-70">
        <p class="text">
          No tienes ninguna citación creada, crea una para acceder a todas las funcionalidades del mecanismo intermedio.
        </p>
      </div>
    </ng-template>
  </div>

  <p class="font18" *ngIf="(newHearing || oldHearing)">Estás viendo la audiencia con fecha {{ formatDate(audiencia.fechaAudiencia) }} y hora {{ audiencia.horaAudiencia }}</p>

  <h3 class="font18 bold" *ngIf="(newHearing || oldHearing)">Asistentes</h3>
  <br>
  <form class="phase-form" #descargoForm="ngForm" *ngIf="(newHearing || oldHearing)">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label class="inspections-label" for="nombreCitado">Citado</label>
        <p *ngIf="anotherSelected && wantEditStatement">Otro</p>
        <ngx-select-dropdown *ngIf="!anotherSelected || !wantEditStatement" class="inspections-select" name="nombreCitado" [(ngModel)]="descargo.nombreCitado" (change)="reviewSelectedName($event)" [config]="quotedConfig" [options]="citados">
        </ngx-select-dropdown>
      </div>
      <div class="form-group half-width-group">
        <label class="inspections-label" for="tipoAsistente">Tipo de asistente</label>
        <ngx-select-dropdown class="inspections-select" name="tipoAsistente" [config]="config" [(ngModel)]="descargo.tipoAsistente" [options]="tiposAsistentes">
        </ngx-select-dropdown>
      </div>
    </div>
    <div *ngIf="anotherSelected">
      <h3 class="font16 bold">Información del asistente</h3>
      <div class="form-row" >
        <div class="form-group full-width-group">
          <label for="nombreOtroCitado" class="inspections-label">Nombre completo del asistente</label>
          <input type="text" name="nombreOtroCitado" class="inspections-input" placeholder="Escribe aquí" [(ngModel)]="nombreOtroCitado"/>
        </div>
      </div>
      <div class="form-row d-flex-between mb-20">
        <div class="form-group half-width-group">
          <label for="tipoIdentificacion" class="inspections-label">Tipo de identificación</label>
          <ngx-select-dropdown class="inspections-select" name="tipoIdentificacion" [config]="config" [(ngModel)]="descargo.tipoIdentificacion" [options]="tiposIdentificacion">
          </ngx-select-dropdown>
        </div>
        <div class="form-group half-width-group">
          <label for="identificacion" class="inspections-label">Identificación</label>
          <input type="text" name="identificacion" class="inspections-input" placeholder="Escriba aquí" [(ngModel)]="descargo.identificacion"/>
        </div>
      </div>
    </div>

    <div *ngIf="!anotherSelected">
      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="nombre">Nombre</h4>
          <p>{{ quotedFound.nombre || 'No asignado'}}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="apellido">Apellido</h4>
          <p>{{ quotedFound.apellido || 'No asignado'}}</p>
        </div>
      </div>

      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="tipoIdentificacion">Tipo de identificación</h4>
          <p>{{ quotedFound.tipoIdentificacion || 'No asignado'}}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="identificacion">Identificación</h4>
          <p>{{ quotedFound.identificacion || 'No asignado'}}</p>
        </div>
      </div>

      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="nivelEducativo">Nivel educativo</h4>
          <p>{{ quotedFound.nivelEducativo || 'No asignado'}}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="ocupacion">Ocupación</h4>
          <p>{{ quotedFound.ocupacion || 'No asignado'}}</p>
        </div>
      </div>

      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="tipoPoblacion">Tipo de población vulnerable</h4>
          <p>{{ quotedFound.tipoVulnerabilidad || 'No asignado'}}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="direccion">Dirección</h4>
          <p>{{ quotedFound.direccion || 'No asignado'}}</p>
        </div>
      </div>

      <div class="form-row d-flex-between">
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="telefono">Teléfono</h4>
          <p>{{ quotedFound.telefono || 'No asignado'}}</p>
        </div>
        <div class="form-group half-width-group">
          <h4 class="inspections-label" for="correoElectronico">Correo electrónico</h4>
          <p>{{ quotedFound.correo || 'No asignado'}}</p>
        </div>
      </div>
      <br>
    </div>

    <div class="form-row">
      <div class="form-group full-width-group">
        <label class="inspections-label" for="declaracion">Declaración</label>
        <textarea
          class="textarea"
          name="declaracion"
          id="declaracion"
          [(ngModel)]="descargo.declaracion"
          placeholder="Escribe aquí… (se anota lo que tenga que decir el citado y se hacen las preguntas pertinentes)"
          cols="10"></textarea>
      </div>
    </div>
    <button *ngIf="!wantEditStatement" class="aggregate-button mb-20" (click)="addStatement()">+Agregar</button>
    <button *ngIf="wantEditStatement" class="cancel-small-button mb-20" (click)="cancelStatement()">Cancelar</button>

    <div class="list mb-10" *ngIf="newHearing || oldHearing">
      <h4 class="subtitle-text bold">Declaraciones ya rendidas</h4>
      <div *ngIf="descargos.length > 0; else noDescargos">
        <div class="list-row" *ngFor="let descargo of descargos; index as i">
          <div class="list-detail width-100">
            <span>{{ descargo.identificacion + ' - ' + descargo.nombreCitado + ': ' + descargo.declaracion | truncate:120 }}</span>
            <div class="list-buttons right-double">
              <!--           <button *ngIf="wantEditStatement" class="edit-item" (click)="cancelStatement()">Cancelar</button> -->
              <button class="selectable-button" (click)="editStatement(descargo, i)"><img class="edit-icon" src="assets/img/inspecciones/editar.png" alt=""></button>
              <button class="selectable-button" (click)="downloadStatement(descargo)"><img class="delete-icon" src="assets/img/inspecciones/eliminar.png" alt="icon descargar"></button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noDescargos>
        <div>
          <p class="text">
            No tienes descargos ni declaraciones creados, crea uno para empezar.
          </p>
        </div>
      </ng-template>
    </div>

    <div>
      <button type="submit" class="submit-button" (click)="onSaveInfo(descargoForm)">Guardar</button>
    </div>
  </form>
</div>
