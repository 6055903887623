import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PvaService } from '../pva.service';
import { jsPDF } from 'jspdf';
import Swal from 'sweetalert2';
import { UtilsService } from '../../../../services/utils.service';
import { Caso, SeguimientoPVA } from '../../../inspecciones.interface';

@Component({
  selector: 'app-seguimiento-pva',
  templateUrl: './seguimiento-pva.component.html',
  styleUrls: ['./seguimiento-pva.component.css']
})
export class SeguimientoPvaComponent implements OnInit {
  // Fecha de hoy
  todayDate = new Date().toISOString().split("T")[0];
  // Id del pva
  @Input() pvaId: string;
  // Id del caso
  caseId: string;
  // Campos del seguimiento
  seguimiento: SeguimientoPVA = {
    fecha: undefined,
    hora: undefined,
    descripcion: undefined,
    verificacionCumplimiento: undefined,
    responsable: undefined,
    cumpleMedida: undefined,
    razonIncumplimiento: undefined,
    observaciones: undefined
  }
  // Lista de seguimientos
  seguimientos: SeguimientoPVA[] = [];
  // Indica si se est{a añadiendo un nuevo seguimiento o editando uno viejo
  newFollow: boolean = false;
  oldFollow: boolean = false;
  // Índice del seguimiento
  followUpIndex: number;
  // Documento de impresión
  doc: jsPDF;
  // Final del documento de impresión
  finalY: number = 15;

  constructor(
    private route: ActivatedRoute,
    private pvaService: PvaService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getFollowUps()
  }

  /**
   * Obtiene la información de los seguimiento del proceso verbal abreviado
   */
  getFollowUps(){
    this.pvaService.getAllInfo(this.pvaId).then(response => {
      this.seguimientos = response.seguimientos;
    }).catch(err => console.error(err));
  }

  formatDate(date: Date | string){
    return this.utilsService.formatDate(date);
  }

  /**
   * Le indica al programa que quiere crear un nuevo seguimiento
   */
  newFollowUp(){
    this.oldFollow = false;
    this.newFollow = true;
  }

  /**
   *
   * @param followUp El seguimiento de la lista de seguimientos
   * @param index El indice del seguimiento
   */
  editFollowUp(followUp, index){
    this.seguimiento = followUp;
    this.seguimiento.fecha = followUp.fecha ? this.utilsService.formatInputDate(followUp.fecha) : null;
    this.followUpIndex = index;
    this.oldFollow = true;
    this.newFollow = false;
  }

  /**
   * Actualiza la información del seguimiento en la lista de seguimientos
   */
  saveFollowUp(){
    if(
      !this.seguimiento.fecha ||
      !this.seguimiento.hora ||
      !this.seguimiento.descripcion ||
      !this.seguimiento.verificacionCumplimiento ||
      !this.seguimiento.responsable ||
      !this.seguimiento.observaciones
    ) {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos del seguimiento',
        icon: 'error'
      });
    } else {
      this.seguimientos[this.followUpIndex] = this.seguimiento;
      this.cleanFollowUpFields()
      Swal.fire({
        title: 'Éxito',
        text: 'El seguimiento se ha modificado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    }
  }

  /**
   * Añade un nuevo seguimiento
   */
  addFollowUp(){
    if(
      !this.seguimiento.fecha ||
      !this.seguimiento.hora ||
      !this.seguimiento.descripcion ||
      !this.seguimiento.verificacionCumplimiento ||
      !this.seguimiento.responsable ||
      !this.seguimiento.observaciones
    ) {
      Swal.fire({
        title: 'Error',
        text: 'No se han completado todos los campos del seguimiento',
        icon: 'error'
      });
    } else {
      this.seguimientos.push(this.seguimiento);
      this.cleanFollowUpFields()
      Swal.fire({
        title: 'Éxito',
        text: 'El seguimiento se ha agregado exitosamente',
        icon: 'success',
        footer: `<span class="red-text">¡No olvides guardar!</span>`
      })
    }
  }

  /**
   * Cancela la edición o agregación de un seguimiento
   */
  cancelFollowUp(){
    this.cleanFollowUpFields();
  }

  /**
   * Limpia todo lo relacionado al seguimiento
   */
  cleanFollowUpFields(){
    this.seguimiento = {
      fecha: undefined,
      hora: undefined,
      descripcion: undefined,
      verificacionCumplimiento: undefined,
      responsable: undefined,
      cumpleMedida: undefined,
      razonIncumplimiento: undefined,
      observaciones: undefined
    }
    this.followUpIndex = undefined;
    this.oldFollow = false;
    this.newFollow = false;
  }

  /**
   * Cambia el estado de "cumple medida"
   * @param status El estado al cual se va a cambiar
   */
  changeAccomplishment(status: 'yes' | 'no' | 'partially'){
    if(
      (status === 'yes' && this.seguimiento.cumpleMedida !== 'yes') ||
      (status === 'no' && this.seguimiento.cumpleMedida !== 'no') ||
      (status === 'partially' && this.seguimiento.cumpleMedida !== 'partially') ||
      (!status && this.seguimiento.cumpleMedida === undefined)
    ){
      this.seguimiento.cumpleMedida = status;
    } else {
      this.seguimiento.cumpleMedida = undefined;
    }
  }

  async downloadPDF(){
    this.doc = new jsPDF('portrait','mm','a4');
    this.finalY = 15;
    const caso: Caso = await this.pvaService.getCaseInfo(this.caseId);
    this.setPrincipalTitle();
    this.doc.text(`Seguimientos proceso verbal abreviado - caso ${caso.numCaso}`, 15, this.finalY);
    this.finalY += 20;
    for(let seguimiento of this.seguimientos){
      this.setTitleStyle();
      this.doc.text(`Seguimiento ${this.seguimientos.indexOf(seguimiento) + 1}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 18;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text('Fecha', 15, this.finalY);
      this.doc.text('Hora', 105, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.fecha ? this.formatDate(seguimiento.fecha) : 'No definido', 15, this.finalY);
      this.doc.text(seguimiento.hora || 'No definido', 105, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Descripción del seguimiento', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.descripcion || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.descripcion || 'No definido');
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      
      this.doc.text('Verificación del cumplimiento de la Medida correctiva impuesta y demás órdenes libradas', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.verificacionCumplimiento || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.verificacionCumplimiento || 'No definido');
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      this.doc.text('Entidad / dependencia / persona responsable de ejecución', 15, this.finalY);
      this.finalY += 10;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.responsable || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      const cumplimientosTransformar = {
        yes: 'Sí',
        partially: 'Parcialmente',
        no: 'No'
      }
      this.setTitleStyle();
      console.log(seguimiento.cumpleMedida);
      this.doc.text(`¿Se cumplió medida? ${seguimiento.cumpleMedida ? cumplimientosTransformar[seguimiento.cumpleMedida] : 'No definido'}`, 15, this.finalY);
      this.setNormalStyle();
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });

      if(seguimiento.cumpleMedida === 'no' || seguimiento.cumpleMedida === 'partially'){
        this.doc.text('Razón del incumplimiento', 15, this.finalY);
        this.finalY += 10;
        this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
        this.doc.text(seguimiento.razonIncumplimiento || 'No definido', 15, this.finalY);
        this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.verificacionCumplimiento || 'No definido');
        this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      }

      this.doc.text('Observaciones', 15, this.finalY);
      this.finalY += 15;
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
      this.doc.text(seguimiento.observaciones || 'No definido', 15, this.finalY, { maxWidth: 180 });
      this.finalY += this.getMoreHeightFromTextHeightFullWidth(seguimiento.observaciones || 'No definido');
      this.finalY = this.verifyEndOfPage({ doc: this.doc, value: this.finalY });
    }
    this.doc.save(`Seguimientos proceso verbal abreviado - caso ${caso.numCaso}.pdf`);
  }

  /**
   * Gets the height to add from certain number of characters
   * @param {string} string the text 
   * @returns {number} the number that must increase the height from the top 
   */
  getMoreHeightFromTextHeightFullWidth(string: string): number {
    const length = string.length - 160;
    if(length/80 < 0){
      return 12;
    } else {
      return 12 + 3 * Math.ceil(length/80)
    }
  }

  /**
   * Verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }

  setPrincipalTitle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(16);
    this.doc.setTextColor('#332F2E');
  }

  setTitleStyle(){
    this.doc.setFont('bold');
    this.doc.setFontSize(14);
    this.doc.setTextColor('#332F2E');
  }

  setNormalStyle(){
    this.doc.setFont('normal');
    this.doc.setFontSize(13);
    this.doc.setTextColor('#332F2E');
  }

  /**
   * Guarda la información de los seguimientos en el proceso verbal abreviado.
   */
  onSaveInfo(){
    this.pvaService.saveInfo(this.pvaId, {seguimientos: this.seguimientos}).catch(err => console.error(err));
  }
}
