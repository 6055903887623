<app-header-inspecciones [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="prev-mec-container">
    <aside class="aside-prev-mec">
      <button class="go-back-button" (click)="goBackButton()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda">Volver</button>
      <h1 class="aside-title">Fase 1 <span class="info-text" (click)="goToMoreInfo()"><img class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> Conoce más <img class="info-image" src="/assets/img/inspecciones/flecha.png" alt="Fecha entrar"></span> <br>Mecanismos Previos</h1>
      <h2 class="aside-data">Datos generales</h2>
      <h3 class="aside-subtitle">Caso No.</h3>
      <p class="aside-p">Nº{{ caseNumber }}</p>
      <h3 class="aside-subtitle">Inspección de Policía No.</h3>
      <p class="aside-p">Nº{{ inspectionNumber }}</p>
      <h3 class="aside-subtitle">Presuntos infractores</h3>
      <p *ngFor="let infractor of presuntosInfractores" class="aside-p">
        {{ infractor.nombreInfractor }} {{ infractor.apellidoInfractor }}
      </p>
      <button class="strategies-button mt-30" (click)="goToStrategies()">Ver banco de estrategias</button>
    </aside>
    <main class="main-prev-mec">
      <nav class="navigation">
        <ul class="navigation-list">
          <li class="navigation-item" [class]="selectedPhase === 1 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 1">Diagnóstico</li>
          <li class="navigation-item" [class]="selectedPhase === 2 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 2">Intervención</li>
          <li class="navigation-item" [class]="selectedPhase === 3 ? 'selected navigation-item': 'no-selected navigation-item'" (click)="selectedPhase = 3">Seguimiento</li>
        </ul>
      </nav>
      <app-fase-diagnostica *ngIf="selectedPhase === 1"></app-fase-diagnostica>
      <app-fase-intervencion *ngIf="selectedPhase === 2"></app-fase-intervencion>
      <app-fase-seguimiento *ngIf="selectedPhase === 3"></app-fase-seguimiento>
    </main>
  </div>
</div>
