import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { MainService } from '../../../services/main.service';
import { SeguimientoEvaluacion } from '../../inspecciones.interface';

@Injectable()
export class SeguimientoEvaluacionService {

  constructor(
    private mainService: MainService
  ) { }

  /**
   * Obtiene toda la información del seguimiento y evaluación
   * @param {string} caseId El id del caso
   * @returns {Promise<SeguimientoEvaluacion>} Promesa con los datos del seguimiento - evaluación o error
   */
  getAllInfo(caseId): Promise<SeguimientoEvaluacion> {
    return new Promise((resolve, reject) => {
      this.mainService.get<SeguimientoEvaluacion>(`api/seg-eva-caso/caso/${caseId}`).subscribe(response => {
        if(this.mainService.isSuccess<SeguimientoEvaluacion>(response)){
          resolve(response.result);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Hubo un error al obtener el seguimiento',
            icon: 'error'
          });
          reject(response.error);
        }
      })
    })
  }

  /**
   * Guarda la información del seguimiento - evaluación
   * @param {string} id El id del seguimiento - evaluación
   * @param {SeguimientoEvaluacion} data Los datos a actualizar
   * @returns {Promise<any>} Promesa con respuesta o error
   */
  saveInfo(id: string, data: SeguimientoEvaluacion): Promise<SeguimientoEvaluacion> {
    return new Promise((resolve, reject) => {
      this.mainService.put<SeguimientoEvaluacion>(`api/seg-eva-caso/${id}`, data).subscribe(response => {
        if(this.mainService.isSuccess<SeguimientoEvaluacion>(response)){
          Swal.fire({
            title: 'Éxito',
            text: 'Seguimiento y evaluación actualizado con éxito',
            icon: 'success'
          });
          resolve(response.result);
        } else {
          Swal.fire({
            title: 'Error',
            text: 'No se pudo actualizar el seguimiento y evaluación',
            icon: 'error'
          })
          reject(response.error);
        }
      })
    })
  }
}
