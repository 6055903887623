import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bibliografia-inspecciones',
  templateUrl: './bibliografia-inspecciones.component.html',
  styleUrls: ['./bibliografia-inspecciones.component.css']
})
export class BibliografiaInspeccionesComponent implements OnInit {

  constructor() { }


  ngOnInit() {
  }

  openModal(id){
    console.log(id);
    let pdf = document.getElementById('pdf');
    let txt = '';
    pdf.innerHTML = txt;
    if(id == 1){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2005_Illera.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    else if(id == 2){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2011_Ruiz-Vasquez.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    else if(id == 3){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2018_Isaza-Morales_Quintero-Sanchez.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    else if(id == 4){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2021_Alarcon-Aguirre_et-al.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    else if(id == 5){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2021_Bueno-Moreno_et-al.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    else if(id == 6){
      txt = `<iframe id="iframepdf" src="/assets/pdf/inspecciones/2021_Rivera-Pimienta.pdf" width = "100%" height = "100%"></iframe>`;
      pdf.innerHTML = txt;
    }
    let modal_container = document.getElementById('modal-bibliografy-container');
    let close = document.getElementById('close');

    modal_container.classList.add('show');
  }

  closeModal(){
    console.log("Hola");
    let modal_container = document.getElementById('modal-bibliografy-container');
    let close = document.getElementById('close');

    modal_container.classList.remove('show');
  }


}
