<div class="form-container">
  <div class="form-header">
    <h2 class="form-title">Seguimiento</h2>
    <button *ngIf="wantEditFollowUp || isNewFollowUp" class="cancel-button" (click)="cancelFollowUp()">Salir de {{ isNewFollowUp ? 'creación' : 'edición' }}</button>
  </div>
  <section class="width-70per">
    <h3 class="inspections-subtitle">Verificación del cumplimiento de compromisos, ¿se cumplieron?</h3>
    <div class="list" *ngIf="compromisosAsignados.length > 0; else sinCompromisos">
      <div class="list-row d-flex-between mb-10" *ngFor="let compromiso of compromisosAsignados; index as i">
        <span class="list-detail fit-height">{{ compromiso.descripcion | truncate:150 }}</span>
        <div class="checkslist">
          <div class="d-flex-around ml-20">
            <input class="checkbox width-30" name="cumplido" [checked]="compromiso.cumplido" (change)="changeActivityStatus(compromiso, true, i)" type="checkbox">
            <label class="inspections-label mb-0" for="cumplido">Sí</label>
          </div>
          <div class="d-flex-around ml-10">
            <input class="checkbox width-30" name="noCumplido" [checked]="!compromiso.cumplido && compromiso.cumplido != undefined" (change)="changeActivityStatus(compromiso, false, i)" type="checkbox">
            <label class="inspections-label mb-0" for="noCumplido">No</label>
          </div>
        </div>
      </div>
    </div>
    <ng-template #sinCompromisos>
      <div>
        <p class="font16">No se ha asignado ningún compromiso a este mecanismo previo</p>
      </div>
    </ng-template>
  </section>
  <section class="width-70per">
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="comentariosRecomendaciones" class="inspections-label">Comentarios y recomendaciones</label>
        <textarea class="textarea mb-20" placeholder="Escribe aquí los comentarios y recomendaciones" name="comentariosRecomendaciones" id="comentariosRecomendaciones" [(ngModel)]="comentariosRecomendaciones" rows="5"></textarea>
      </div>
    </div>
  </section>
  <section class="width-70per">
    <h3 class="font18 bold">Lista de seguimientos</h3>
    <div class="list mb-20" *ngIf="seguimientos.length > 0; else noSeguimientos">
      <div class="list-row" *ngFor="let seg of seguimientos; index as i">
        <button class="selectable-button" (click)="editFollowUp(seg)">{{ ('Seguimiento ' + (i+1) + ' / ' + formatDate(seg.fechaSeguimiento) + ': ' + seg.descripcionSeguimiento) | truncate:150 }}<img src="assets/img/inspecciones/right-arrow.png" class="right-arrow" alt="Flecha ingresar"></button>
      </div>
    </div>
    <ng-template #noSeguimientos>
      <div class="width-70 mb-20">
        <p class="text">
          No tienes seguimientos creados.
        </p>
      </div>
    </ng-template>
    <button *ngIf="!wantEditFollowUp && !isNewFollowUp" class="aggregate-button mb-20" (click)="startFollowUp()">+Nuevo seguimiento</button>
  </section>
  <form class="phase-form top-line mt-20" #followup="ngForm" *ngIf="wantEditFollowUp || isNewFollowUp">
    <div class="form-row d-flex-between">
      <div class="form-group half-width-group">
        <label for="fechaSeguimiento" class="inspections-label">Fecha</label>
        <input
          class="inspections-input calendar-input"
          placeholder="dd/mm/aaaa"
          type="date"
          name="fechaSeguimiento"
          id="fechaSeguimiento"
          [(ngModel)]="seguimiento.fechaSeguimiento">
      </div>
      <div class="form-group half-width-group">
        <label for="horaSeguimiento" class="inspections-label">Hora</label>
        <input
          type="time"
          placeholder="00:00"
          name="horaSeguimiento"
          class="inspections-input time-input"
          id="horaSeguimiento"
          [(ngModel)]="seguimiento.horaSeguimiento">
      </div>
    </div>
    <div class="form-row">
      <label for="descripcionSeguimiento" class="inspections-label">Descripción del seguimiento</label>
      <textarea class="textarea mb-20" placeholder="Escribe aquí" name="descripcionSeguimiento" id="descripcionSeguimiento" [(ngModel)]="seguimiento.descripcionSeguimiento" rows="5"></textarea>
    </div>
    <div class="form-row">
      <div class="form-group full-width-group">
        <label for="observaciones" class="inspections-label">Observaciones</label>
        <textarea class="textarea" placeholder="Escribe aquí" name="observaciones" id="observaciones" [(ngModel)]="observaciones" rows="5"></textarea>
      </div>
    </div>
    <button *ngIf="!wantEditFollowUp" class="aggregate-button mb-20" (click)="addFollowUp()">+Agregar seguimiento</button>
    <button *ngIf="wantEditFollowUp" class="aggregate-button mb-20" (click)="saveFollowUp()">Guardar seguimiento</button>
  </form>
  <div class="buttons">
    <button class="submit-button mr-20" (click)="updateFollowUp()">Guardar</button>
    <button class="submit-button report-button" (click)="generateFinalReport()">Generar informe final</button>
  </div>
</div>
