import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
import { FichaGeneral, MecanismoPrevio, MecanismoIntermedio, Pva, SeguimientoEvaluacion } from '../inspecciones.interface';

type request = { fichasGenerales: FichaGeneral[], mecPrevios: MecanismoPrevio[], mecIntermedios: MecanismoIntermedio[], pvas: Pva[], seguimientosEvaluacions: SeguimientoEvaluacion[] };
@Component({
  selector: 'app-inspecciones-inicio',
  templateUrl: './inspecciones-inicio.component.html',
  styleUrls: ['./inspecciones-inicio.component.css']
})
export class InspeccionesInicioComponent implements OnInit {
  submodulos = [
    {
      nombre: 'Premisas Fundamentales',
      imagenP: 'https://www.ctilogistics.com/wp-content/uploads/2012/10/500x3004.gif',
      descripcion: 'Lorem ipsum, dolor sit amet consectetur  adipisicing elit. Ducimus fugiat facilis non natus, inventore hic laudantium ut amet nisi tempora vitae quis. Voluptatum suscipit possimus sapiente perspiciatis deserunt debitis tempora.',
      path: 'premisas-fundamentales'
    },
    {
      nombre: 'Mecanismos Previos',
      imagenP: 'https://www.ctilogistics.com/wp-content/uploads/2012/10/500x3004.gif',
      descripcion: 'Está claro que todas las actuaciones de las autoridades de policia se enmarcan en una filosofía preventiva, es decir, evitar que los habitantes de los territorios incurran en comportamientos o acciones delictivas',
      path: 'mecanismos-previos'
    },
    {
      nombre: 'Mecanismos Intermedios',
      imagenP: 'https://www.ctilogistics.com/wp-content/uploads/2012/10/500x3004.gif',
      descripcion: 'Hemos utilizado la expresión "mecanismos intermedios", porque, a diferencia de los previos, sin embargo, no llegan a tener el carácter o alcance de una medida correctiva lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
      path: 'mecanismos-intermedios'
    },
    {
      nombre: 'Proceso Verbal Abreviado',
      imagenP: 'https://www.ctilogistics.com/wp-content/uploads/2012/10/500x3004.gif',
      descripcion: 'El PVA es la herramienta jurídica con la que cuentan los inspectores de Policía para imponer medios de policia y medidas correctivas cuando quieres lorem ipsum lorem ipsumlorem ipsumlorem ipsum',
      path: 'pva'
    }
  ]
  casosGestionados: { tipo: string, numero: string, etapa: string}[] = [];

  mecPrevios: MecanismoPrevio[] = [];
  mecIntermedios: MecanismoIntermedio[] = [];
  pvas: Pva[] = [];
  seguimientosEvaluacions: SeguimientoEvaluacion[] = [];

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getCases()
  }

  onLogout(){
    this.authService.logout();
    this.router.navigate(['login-inspecciones']);
  }

  /**
   * Obtiene los casos de la BBDD
   */
  getCases(){
    const inspector = JSON.parse(localStorage.getItem('inspector'));
    if(inspector.cargo !== 'Inspector'){
      this.router.navigate(['login-inspecciones']);
      return;
    }
    const idInspector = inspector._id;
    if(!idInspector){
      this.router.navigate(['login-inspecciones']);
      return;
    }
    this.mainService.get<request>(`api/ficha-general/gestion/${idInspector}`).subscribe(response => {
      if(this.mainService.isSuccess<request>(response)){
        const fichasGenerales = response.result.fichasGenerales;
        this.mecPrevios = response.result.mecPrevios;
        this.mecIntermedios = response.result.mecIntermedios;
        this.pvas = response.result.pvas;
        this.seguimientosEvaluacions = response.result.seguimientosEvaluacions;
        const fichasGeneralesOrdenadas = this.organizeByDate(fichasGenerales);
        const primeras = fichasGeneralesOrdenadas.splice(0, 4);
        this.casosGestionados = this.getInterestFields(primeras);
      }
    })
  }

  /**
   * Organiza las fichas por fecha de los hecjos
   * @param fichasGenerales Las fichas
   * @returns Fichas ordenadas
   */
  organizeByDate(fichasGenerales: FichaGeneral[]): FichaGeneral[] {
    const fechaActual = new Date().getTime();
    const fichasGeneralesOrdenadas = fichasGenerales.sort((fichaAnterior, fichaPosterior) => {
      const tiempoA = Math.abs(fechaActual - new Date(fichaAnterior.fechaHechos).getTime());
      const tiempoB = Math.abs(fechaActual - new Date(fichaPosterior.fechaHechos).getTime());
      return tiempoA - tiempoB;
    });
    return fichasGeneralesOrdenadas;
  }

  /**
   * Obtiene la información a mostrar
   * @param fichasGenerales La lista de fichas generales ordenadas de más reciente a más lejadas
   * @returns Lista de las fichas con los campos a mostrar
   */
  getInterestFields(fichasGenerales: FichaGeneral[]): { tipo: string, numero: string, etapa: string }[] {
    return fichasGenerales.map(fichaGeneral => {
      console.log('la ficha general', fichaGeneral);
      const etapa = this.getStage(fichaGeneral);
      return ({
        tipo: fichaGeneral.CCC && fichaGeneral.CCC.length > 0 ? fichaGeneral.CCC[0].tipo : 'No definido',
        numero: fichaGeneral.caso.numCaso,
        etapa
      })
    })
  }

  /**
   * Obtiene la fase actual del caso
   * @param fichaGeneral La información de la ficha
   * @returns La fase actual
   */
  getStage(fichaGeneral: FichaGeneral): string {
    let accion = '';
    const mecPrevioEncontrado = this.mecPrevios.find(mecPrevio => String(mecPrevio.caso) === String(fichaGeneral.caso._id));
    const mecIntermedioEncontrado = this.mecIntermedios.find(mecIntermedio => String(mecIntermedio.caso) === String(fichaGeneral.caso._id));
    const pvaEncontrado = this.pvas.find(pva => String(pva.caso) === String(fichaGeneral.caso._id));
    const seguimiento = this.seguimientosEvaluacions.find(seg => String(seg.caso) === String(fichaGeneral.caso._id));

    console.log(fichaGeneral.caso._id);

    console.log(mecPrevioEncontrado);

    if(mecPrevioEncontrado){
      if(mecPrevioEncontrado.faseIntervencion.actividades.length > 0){
        accion = 'Mecanismo previo'
      }
    }
    if(mecIntermedioEncontrado){
      if(mecIntermedioEncontrado.audiencias.length > 0){
        accion = 'Mecanismo intermedio'
      }
    }
    if(pvaEncontrado){
      if(pvaEncontrado.audiencias.length > 0){
        accion = 'Proceso verbal abreviado';
      }
    }
    if(seguimiento){
      if(seguimiento.fechaDiligenciamiento){
        accion = 'Seguimiento/evaluacion';
      }
    }
    if(accion == ''){
      accion = 'No definido';
    }
    return accion;
  }

  /**
   * Indica si el caso debe llevar en su icono 3 letras
   * @param tipo El tipo de caso
   * @returns boolean
   */
  isThreeLetters(tipo: string){
    const threeLetters = {
      'Violencia': true,
      'Tranquilidad': true,
      'Respeto': true,
    }
    return threeLetters[tipo] || false;
  }

}
