import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../../../../services/main.service';
import { UtilsService } from '../../../../services/utils.service';
import { Audiencia, Citado, FichaGeneral, MecanismoIntermedio, MecanismoPrevio } from '../../../inspecciones.interface';
import { MecanismoPrevioService } from '../../mecanismo-previo/mecanismo-previo.service';
import { MecanismoIntermedioService } from '../mecanismo-intermedio.service';

@Component({
  selector: 'app-datos-generales',
  templateUrl: './datos-generales.component.html',
  styleUrls: ['./datos-generales.component.css']
})
export class DatosGeneralesComponent implements OnInit, OnChanges {
  todayDate = new Date().toISOString().split('T')[0];

  caseId = '';
  audiencia: Audiencia = {};
  fichaGeneral: Pick<FichaGeneral, 'CCC' | '_id' | 'infractores' | 'nombreLugarHechos' | 'municipio' | 'caso' | 'detalleLugarHechos' | 'resumenHechos' | 'tipoLocalidadHechos' | 'lugarHechos' | 'direccionHechos' | 'nombreEstablecimientoHechos' | 'nitEstablecimientoHechos' | 'actividadEconomicaEstablecimientoHechos'> = {
    infractores: [],
    nombreLugarHechos: '',
    municipio: undefined,
    caso: undefined,
    detalleLugarHechos: '',
    resumenHechos: '',
    tipoLocalidadHechos: '',
    lugarHechos: ''
  };
  mecIntermedio: Partial<MecanismoIntermedio> = {};
  mecPrevio: MecanismoPrevio = {
    createdAt: undefined,
    caso: '',
    compromisos: undefined
  };
  inspector: any = {};

  newHearing: boolean = false;
  oldHearing: boolean = false;

  showContinueOption: boolean = false;

  hearingIndex: string | number;

  @Input() mecIntId: string;
  @Input() selectedHearing: any;
  @Input() isSelected: boolean;
  @Input() audienciasDisponibles: any[];

  @Output() onSelectHearing: EventEmitter<any> = new EventEmitter<any>();

  citadosSinAsistir: Citado[] = [];

  constructor(
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private mecPrevService: MecanismoPrevioService,
    private mecIntService: MecanismoIntermedioService,
  ) { }

  ngOnInit() {
    this.caseId = this.route.snapshot.params['id'];
    this.getGeneralData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedHearing'] && Object.entries(changes['selectedHearing'].currentValue).length !== 0){
      this.audiencia = changes['selectedHearing'].currentValue;
      this.audiencia.horaAudiencia = this.audiencia.horaAudiencia.split('T')[0];
      this.oldHearing = true;
      this.hearingIndex = this.audienciasDisponibles.findIndex(audiencia => {
        return audiencia === changes['selectedHearing'].currentValue
      });
      for(let citado of this.audiencia.citados){
        if(!citado.asiste && citado.asiste !== undefined){
          this.citadosSinAsistir.push(citado);
        }
      }
    }
  }

  /**
   * Formatea la fecha en un formato visual
   * @param date La fecha
   * @returns La fecha formateada
   */
  formatDate(date: Date): string {
    return this.utilsService.formatDate(date);
  }

  editHearing(hearing){
    this.newHearing = false;
    this.isSelected = true
    this.onSelectHearing.emit({hearing, isSelected: this.isSelected});
    this.oldHearing = true;
    this.hearingIndex = this.audienciasDisponibles.indexOf(hearing);
    this.audiencia = hearing;
    this.audiencia.fechaAudiencia = hearing.fechaAudiencia.split("T")[0];
  }

  cancelHearing(){
    this.newHearing = false;
    this.oldHearing = false;
    this.isSelected = false;
    const seleccionada = {}
    this.onSelectHearing.emit({seleccionada, isSelected: this.isSelected});
  }

  getGeneralData(){
    this.mecPrevService.getAllInfo(this.caseId).then(mecPrevioData => {
      this.mecPrevio = mecPrevioData;
    })
    this.mecIntService.getGeneralFileInfo(this.caseId).then(generalFileData => {
      this.fichaGeneral = generalFileData
      console.log(this.fichaGeneral);
      this.inspector = this.fichaGeneral.caso.inspector;
    });
  }

  changeAssistanceStatus(citado: Citado, status: boolean, index: number){
    if (
      (status && citado.asiste === undefined) ||
      (!status && citado.asiste === undefined) ||
      (status && !citado.asiste) ||
      (!status && citado.asiste)
    ) {
      citado.asiste = status;
      this.audiencia.citados[index].asiste = status;
      if(status === false){
        this.citadosSinAsistir.push(citado);
      } else {
        const indiceSinAsistir = this.citadosSinAsistir.findIndex(cit => cit.nombreCompleto == citado.nombreCompleto);
        if(indiceSinAsistir !== -1){
          this.citadosSinAsistir.splice(indiceSinAsistir, 1);
          this.audiencia.citados[indiceSinAsistir].justificaInasistencia = undefined;
        }
      }
    } else {
      citado.asiste = undefined;
      this.audiencia.citados[index].asiste = undefined;
    }
  }

  changeJustificationStatus(citado, status: boolean, index: number){
    if (
      (status && citado.justificaInasistencia === undefined) ||
      (!status && citado.justificaInasistencia === undefined) ||
      (status && !citado.justificaInasistencia) ||
      (!status && citado.justificaInasistencia)
    ) {
      citado.justificaInasistencia = status;
      this.audiencia.citados[index].justificaInasistencia = status;
    } else {
      citado.justificaInasistencia = undefined;
      this.audiencia.citados[index].justificaInasistencia = undefined;
    }
  }

  changeContinuationStatus(status) {
    if (
      (status && this.audiencia.suspendida === undefined) ||
      (!status && this.audiencia.suspendida === undefined) ||
      (status && !this.audiencia.suspendida) ||
      (!status && this.audiencia.suspendida)
    ) {
      this.audiencia.suspendida = status;
    } else {
      this.audiencia.suspendida = undefined;
    }
  }

  changeAgentAssistance(status: boolean){
    if (
      (status && this.audiencia.asisteAgente === undefined) ||
      (!status && this.audiencia.asisteAgente === undefined) ||
      (status && !this.audiencia.asisteAgente) ||
      (!status && this.audiencia.asisteAgente)
    ) {
      this.audiencia.asisteAgente = status;
      if(status === false){
        this.audiencia.nombreAgente = '';
      }
    } else {
      this.audiencia.asisteAgente = undefined;
    }
  }

  onGuardar(f: NgForm){
    const { direccionAudiencia, fechaAudiencia, horaAudiencia, observaciones, otrosMediosUtilizados, nombreAgente } = f.form.value;
    const audiencia = this.audienciasDisponibles[this.hearingIndex];
    Object.assign(audiencia, {
      direccionAudiencia,
      fechaAudiencia,
      horaAudiencia,
      otrosMediosUtilizados,
      observaciones,
      nombreAgente,
      asisteAgente: this.audiencia.asisteAgente
    })
    this.audienciasDisponibles[this.hearingIndex] = audiencia;
    const data = {
      audiencias: this.audienciasDisponibles,
    }
    this.mecIntService.saveInfo(this.mecIntId, data)
  }
}
