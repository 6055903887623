import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
// import { LocalDataSource } from 'ng2-smart-table';

export interface JurisprudenciaTableData {
  id: number;
  referencia: string;
  asunto: string;
  observacion: string;
  enlaces: string[];
}

@Component({
  selector: 'app-legislacion-inspecciones',
  templateUrl: './legislacion-inspecciones.component.html',
  styleUrls: ['./legislacion-inspecciones.component.css']
})
export class LegislacionInspeccionesComponent implements OnInit {

  settings: any = {}

  dataSource: MatTableDataSource<JurisprudenciaTableData, any>;

  displayedColumns: string[] = ['referencia', 'asunto', 'observacion', 'enlaces'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() { }

  data: JurisprudenciaTableData[] = [
    {
      id: 1,
      referencia: "Ley 84-1989",
      asunto: "Estatuto nacional de protección de animales",
      observacion: "Vigente",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/1628319']
    },
    {
      id: 2,
      referencia: "Constitución Política de 1991",
      asunto: "Constitución Política",
      observacion: "",
      enlaces: [environment.secretariaSenado]
    },
    {
      id: 3,
      referencia: "Ley 1098-06",
      asunto: "Código de Infancia y Adolescencia",
      observacion: "Se debe tener en cuenta en los CCC que afectan a los niños, niñas y adolescentes.",
      enlaces: ['https://www.alcaldiabogota.gov.co/sisjur/normas/Norma1.jsp?i=66661']
    }
    ,
    {
      id: 4,
      referencia: "Decreto 1469-10",
      asunto: "Reglamenta licencias urbanísticas,  reconocimiento de edificaciones, curadurías urbanas",
      observacion: "Vigente",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Decretos/1863388']
    }
    ,
    {
      id: 5,
      referencia: "Ley 1437-11",
      asunto: "Código de Procedimiento Administrativo y de lo Contencioso Administrativo",
      observacion: "La primera parte regula el derecho de petición, el procedimiento administrativo general y el procedimiento sancionador",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/1680117']
    }
    ,
    {
      id: 6,
      referencia: "Ley 1755-15",
      asunto: "Regula el derecho de petición",
      observacion: "",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/30043679']
    }
    ,
    {
      id: 7,
      referencia: "Ley 1801-16",
      asunto: "Código Nacional de Seguridad y Convivencia Ciudadana",
      observacion: "Regula los CCC, medios de policía, medidas correctivas, PVA, PVI",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/30021736']
    }
    ,
    {
      id: 8,
      referencia: "Decreto 1284-17",
      asunto: "Reglamenta parcialmente el CNSCC",
      observacion: "Espacios para recepción de PQR, Consejos de seguridad y convivencia, Registro Nacional de Medidas Correctivas, cobro de multas, Centros de traslado por protección, medida de aparticipación en programa comunitario o actividad pedagógica de convivencia, acceso al RUES, actividad de policía en aguas jurisdiccionales de Colombia.",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Decretos/30032813']
    }
    ,
    {
      id: 9,
      referencia: "Ley 2197-22",
      asunto: "",
      observacion: "Modifica arts. 27, 155, 180, 183 y 205 y a adiciona arts. 185A, 185B, 185C, 223A, 237B, del CNSCC",
      enlaces: ['https://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/30043812']
    }
  ]

  /**
   * Aplica un filtro en la tabla que hace una búsqueda en todos los casos
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  goToPage(links: string[]){
    if(links.length > 0){
      links.forEach(link => window.open(link, '_blank'));
    }
  }

}
