<app-header-inspecciones  [opcionElegida]="2"></app-header-inspecciones>
<div class="module-principal">
  <div class="final-report-container">
    <aside class="aside-final-report">
      <button class="go-back-button" (click)="onGoBack()"><img class="left-arrow" src="/assets/img/inspecciones/arrow-left.png" alt="Flecha izquierda"> Volver</button>
      <h1 class="aside-title">Fase 1 <span class="info-text"><img class="info-image" src="/assets/img/inspecciones/conocer-mas.png" alt="Conocer más icono"> Conoce más <img class="info-image" src="/assets/img/inspecciones/flecha.png" alt="Fecha entrar"></span> <br>Mecanismos Previos</h1>
    </aside>
    <main class="form-container width-70">
      <div class="form-header full-width">
        <h2 class="form-title">Informe final</h2>
        <button class="download-button"><img class="download-image" src="/assets/img/inspecciones/flecha-blanca.png" alt="Flecha descargar" (click)="descargarPDF()"></button>
      </div>
      <form class="phase-form" #finalReport="ngForm">
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <h3 for="tipoCaso" class="inspections-label text-bold">Tipo de caso</h3>
            <p class="font16">{{ diagnostico.tipoCaso || 'No asignado' }}</p>
          </div>
          <div class="form-group half-width-group">
            <h3 for="fechaSeguimiento" class="inspections-label text-bold">Casos asociados</h3>
            <p class="font16">{{ getAssociatedCases(diagnostico.casosAsociados) || 'No asignados' }}</p>
          </div>
        </div>
        <h3 class="section-text text-bold">Presunto(s) infractor(es)</h3>
        <div class="form-row d-flex-between">
          <p class="half-width-group font16">Nombre</p>
          <p class="half-width-group font16">Apellido</p>
        </div>
        <div class="mb-5">
          <div *ngFor="let infractor of infractores" class="form-row">
            <div class="form-group d-flex-between mb-0">
              <p class="half-width-group font16 mb-5">{{ infractor.nombreInfractor || 'No asignado' }}</p>
              <p class="half-width-group font16 mb-5">{{ infractor.apellidoInfractor || 'No asignado' }}</p>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Tipos de actividades realizadas</h3>
            <p class="font16" *ngFor="let actividad of tiposActividades">{{ actividad }}</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Resumen de la actividad desarrollada</h3>
            <p class="font16" *ngFor="let actividad of actividades; ">{{ actividad.fechaActividad.split("T")[0] + ': ' + (actividad.resumenActividad || 'No existe un resumen para esta actividad') }}</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Descripción del seguimiento</h3>
            <p class="font16" *ngFor="let seguimiento of seguimientos">{{ seguimiento.fechaSeguimiento.split("T")[0] + ': ' + seguimiento.descripcionSeguimiento }}</p>
          </div>
        </div>
        <!-- AQUI VAN LAS IMAGENES -->
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Verificación del cumplimiento de objetivos</h3>
            <div class="list">
              <div class="list-row d-flex-start align-top mb-5" *ngFor="let compromiso of compromisos">
                <span class="font16 mr-10">{{ compromiso.descripcion }}</span>
                <div class="checkbox-container width-10p">
                  <div class="d-flex-start">
                    <input class="checkbox width-30" name="cumplido" [checked]="compromiso.cumplido" type="checkbox" [disabled]="true">
                    <label class="inspections-label mb-0" for="cumplido">{{ compromiso.cumplido ? 'Sí' : 'No' }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">¿Acciones cumplieron con su objetivo?</h3>
            <div class="d-flex-start">
              <div class="d-flex-around mr-20">
                <input class="checkbox width-30" name="cumplido" [checked]="mecPrevio.cumplimientoObjetivos === 'yes'" (change)="changeAccomplishment('yes')" type="checkbox">
                <label class="inspections-label mb-0" for="cumplido">Sí</label>
              </div>
              <div class="d-flex-around mr-20">
                <input class="checkbox width-30" name="noCumplido" [checked]="mecPrevio.cumplimientoObjetivos === 'no'" (change)="changeAccomplishment('no')" type="checkbox">
                <label class="inspections-label mb-0" for="noCumplido">No</label>
              </div>
              <div class="d-flex-around">
                <input class="checkbox width-30" name="noCumplido" [checked]="mecPrevio.cumplimientoObjetivos === 'partially'" (change)="changeAccomplishment('partially')" type="checkbox">
                <label class="inspections-label mb-0" for="noCumplido">Parcialmente</label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="mecPrevio.cumplimientoObjetivos == 'no'">
          <div class="form-group full-width-group">
            <label class="inspections-label text-bold" for="razonIncumplimientoObjetivos">Razón de incumplimiento de objetivos</label>
            <textarea class="textarea mb-20" placeholder="Escribe aquí" name="razonIncumplimientoObjetivos" id="razonIncumplimientoObjetivos" [(ngModel)]="mecPrevio.razonIncumplimientoObjetivos" cols="30" rows="5"></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Observaciones</h3>
            <p class="font16">{{ observaciones || 'No asignadas' }}</p>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group full-width-group">
            <h3 class="inspections-label text-bold">Comentarios y recomendaciones</h3>
            <textarea class="textarea mb-20" placeholder="Escribe aquí" name="razonIncumplimientoObjetivos" id="razonIncumplimientoObjetivos" [(ngModel)]="mecPrevio.comentariosRecomendaciones" cols="30" rows="5"></textarea>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group d-flex-start">
            <div class="mr-50">
              <h3 class="inspections-label text-bold">Municipio</h3>
              <p class="font16">{{ fichaGeneral.municipio }}</p>
            </div>
            <div>
              <h3 class="inspections-label text-bold">Inspección No.</h3>
              <p class="font16">{{ fichaGeneral.numCaso }}</p>
            </div>
          </div>
        </div>
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label class="inspections-label text-bold" for="nombreAuxAdm">Nombre Auxiliar Administrativo</label>
            <input
              type="text"
              placeholder="Escribe aquí"
              name="nombreAuxAdm"
              class="inspections-input"
              id="nombreAuxAdm"
              [(ngModel)]="mecPrevio.nombreAuxAdm">
          </div>
          <div class="form-group half-width-group">
            <div class="form-row">
              <label class="inspections-label text-bold" for="firmaAuxAdm">Firma Auxiliar Administrativo</label>
              <label for="firmaAuxAdm" class="file-container file-container-45 font16 b-20 d-flex-between">
                <p class="font16">Adjuntar firma</p>
                <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
                <input
                  class="file-input"
                  type="file"
                  id="firmaAuxAdm"
                  name="firmaAuxAdm"
                  (change)="onFileChange($event, 'firma-aux-adm')">
              </label>
              <a *ngIf="mecPrevio.firmaAuxAdm?.url" [href]="mecPrevio.firmaAuxAdm.url"  target="_blank">Ver firma</a>
            </div>
          </div>
        </div>
        <div class="form-row d-flex-between">
          <div class="form-group half-width-group">
            <label class="inspections-label text-bold" for="nombreInspector">Nombre Inspector de Policía</label>
            <input
              type="text"
              placeholder="Escribe aquí"
              disabled="true"
              name="nombreInspector"
              class="inspections-input"
              id="nombreInspector"
              [(ngModel)]="inspector.nombre">
          </div>
          <div class="form-group half-width-group">
            <div class="form-row">
              <label class="inspections-label text-bold" for="firmaInspector">Firma Inspector de policía</label>
              <label for="firmaInspector" class="file-container file-container-45 font16 b-20 d-flex-between">
                <p class="font16">Adjuntar firma</p>
                <img src="/assets/img/inspecciones/upload.png" alt="Subir" class="upload-image">
                <input
                  class="file-input"
                  type="file"
                  id="firmaInspector"
                  name="firmaInspector"
                  (change)="onFileChange($event, 'firma-inspector')">
              </label>
              <a *ngIf="mecPrevio.firmaInspector?.url" [href]="mecPrevio.firmaInspector.url"  target="_blank">Ver firma</a>
            </div>
          </div>
        </div>
      </form>
      <button class="submit-button" (click)="saveFinalReport(finalReport)">Guardar</button>
    </main>
  </div>
</div>
